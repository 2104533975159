import { connect } from 'react-redux';

import { getPermissionState } from 'selectors/roomSettings';
import { setAvailableDevices, setEnumerateDeviceError, setDeviceSettings } from 'actions/roomSettings';
import DeviceListener from './index';

const mapStateToProps = (state) => ({
    permissionState: getPermissionState(state)
});

const mapDispatchToProps = dispatch => ({
    setDeviceSettings: (devices) => dispatch(setDeviceSettings(devices)),
    setAvailableDevices: (devices) => dispatch(setAvailableDevices(devices)),
    setEnumerateDeviceError: (devices) => dispatch(setEnumerateDeviceError(devices)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListener);

