import styled from 'styled-components';
import { rgba } from 'polished';

export const StyleDropdownMenu = styled.div`
    background-color: ${props => props.theme.white};
    box-shadow: 0 2px 4px ${props => rgba(props.theme.black, 0.15)};
    padding: 5px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .item{
        display: flex;
        flex-direction: row;
        padding: 5px;
        border-radius: 4px;
        transition: all 250ms ease;
        cursor: pointer;
        align-items: center;
        flex: 1;
        min-height: 0;
        min-width: 0;
        width: 100%;
        border-bottom: 1px solid ${props => rgba(props.theme.black, 0.05)};
        &:last-child{
            border-bottom: none;
        }
        &:hover{
            background-color: ${props => rgba(props.theme.black, 0.05)};
        }
        .label{
            color: ${props => props.theme.black};
            font-size: 14px;
        }
    }
`;

export const StyledFastReply = styled.div`
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;
`;
