import { selfieSegmentationCdnUrl } from "./constants";

export const stopVideoTracks = (stream) => {
    stream.getVideoTracks().forEach(track => track.stop());
};

export const copyMediaStream = (originalStream) => {
    const newStream = new MediaStream();
    originalStream.getTracks().forEach(track => newStream.addTrack(track.clone()));
    return newStream;
};

export const waitForVideoReady = (video) => new Promise<void>((resolve) => {
    const checkVideoReady = () => {
        if (video.readyState >= video.HAVE_ENOUGH_DATA && video.videoWidth > 0) {
            resolve();
        } else {
            video.addEventListener('loadeddata', () => {
                if (video.videoWidth > 0) {
                    resolve();
                }
            }, { once: true });
        }
    };

    if (video.readyState >= video.HAVE_ENOUGH_DATA) {
        checkVideoReady();
    } else {
        video.addEventListener('loadeddata', checkVideoReady, { once: true });
    }
});

export const isWebGLSupported = () => {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    return !!gl;
};

export const removeSegmentation = () => {
    document.querySelector(`script[src*="${selfieSegmentationCdnUrl}"]`)?.remove();
};
