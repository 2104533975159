import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Logger from 'services/debug/logger';
import differenceBy from 'lodash/differenceBy';
import DeviceManager from 'components/Room/Helpers/DeviceManager';

const logger = new Logger('component:deviceListener');

const diffBySlug = (a) => `${a?.kind}-${a?.deviceId}-${a?.groupId}-${a?.label}`;

const DeviceListener = (props) => {
    const { setAvailableDevices, setEnumerateDeviceError, permissionState } = props;
    const previousDevices = useRef();
    const mounted = useRef();
    let intervalId;
    let timeoutId;

    const refreshDevices = () => {
        mounted.current = true;

        const onChange = () => DeviceManager
            .enumerateDevices()
            .then(list => {
                if (mounted.current) {
                    if (!previousDevices.current || differenceBy(list, previousDevices.current, diffBySlug).length 
                        || differenceBy(previousDevices.current, list, diffBySlug).length) {
                        logger.log('DeviceListener:refreshDevices', list);
                        setAvailableDevices(list);
                        previousDevices.current = list;
                    }
                    
                    if ((list.length > 0 && list?.[0]?.label)) {
                        if (intervalId) {
                            clearInterval(intervalId);
                            intervalId = undefined;
                        } 
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                            timeoutId = undefined;
                        } 
                    }
                }
            })
            .catch(e => {
                setEnumerateDeviceError(e);
                setAvailableDevices([]);

                if (intervalId) {
                    clearInterval(intervalId);
                    intervalId = undefined;
                } 
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = undefined;
                } 
            });

        if (navigator?.mediaDevices?.addEventListener) {
            navigator.mediaDevices.addEventListener('devicechange', onChange);
        }

        onChange();
        intervalId = setInterval(onChange, 1000);
        timeoutId = setTimeout(() => {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = undefined;
            }
            timeoutId = undefined;
        }, 10000);

        return () => {
            if (navigator?.mediaDevices?.removeEventListener) {
                navigator.mediaDevices.removeEventListener('devicechange', onChange);
            }
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = undefined;
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = undefined;
            }
        };
    };

    useEffect(refreshDevices, [permissionState]);

    useEffect(() => () => {
        mounted.current = false;
    }, []);

    return null;
}

DeviceListener.propTypes = {
    permissionState: PropTypes.object,
    setAvailableDevices: PropTypes.func.isRequired,
    setEnumerateDeviceError: PropTypes.func.isRequired
};

DeviceListener.defaultProps = {
    permissionState: undefined
};

export default (DeviceListener);
