import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Select, Alert, Typography } from 'antd';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { injectIntl } from 'react-intl';
import { StyledSelect } from 'components/Shared/Forms/styles';

const { Text } = Typography;
const { Option } = Select;
const labelStyle = { fontSize: 10, textTransform: 'uppercase' };

const getError = (kind, cameraError, microphoneError, speakerError) => {
    if (kind === 'videoinput') {
        return cameraError;
    }
    if (kind === 'audioinput') {
        return microphoneError;
    }
    if (kind === 'audiooutput') {
        return speakerError;
    }
    return '';
}

const DeviceSelector = (props) => {
    const { intl, onChange, inputLabel, kind, value, devices: rawDevices, mediaDeviceError } = props;
    const [devices, setDevices] = useState();

    useWhyDidYouUpdate('component:room:settings:DeviceSelector', { ...props, rawDevices, devices, mediaDeviceError });

    useEffect(() => {
        if (rawDevices !== undefined) {
            setDevices(rawDevices
                ?.filter(d => d.kind === kind)
                .map((device, index) => ({ key: device.deviceId, value: device.label || `Device ${index}`, kind: device.kind }))
            );
        }
    }, [rawDevices]);

    useEffect(() => {
        if (value !== undefined) {
            onChange(value);
        }
    }, [value]);

    return (
        <div className="group-input">
            {/* eslint-disable-next-line */}
            <Text
                strong
                style={labelStyle}
            >
                {inputLabel}
            </Text>
            {devices !== undefined && !devices.length && (
                <Alert
                    type="error"
                    showIcon
                    message={intl.formatMessage({ id: 
                        getError(kind, 'rtc.error.devices.camera.NotFoundError', 'rtc.error.devices.microphone.NotFoundError', 'rtc.error.devices.speaker.NotFoundError')
                    })}
                />
            )}

            {mediaDeviceError && (
                <Alert
                    type="error"
                    showIcon
                    message={mediaDeviceError}
                />
            )}
            {!!devices?.length && (
                <StyledSelect
                    className="select"
                    // defaultValue={value}
                    onChange={onChange}
                    value={value}
                    notFoundContent={<Text>{intl.formatMessage({ id: 'room.error.noDeviceConnected.title' })}</Text>}
                >
                    {
                        devices.map(({ key, value: val } = {}) => (
                            <Option key={key}>{val}</Option>
                        ))
                    }
                </StyledSelect>
            )}
        </div>
    );
}

DeviceSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputLabel: PropTypes.any,
    kind: PropTypes.oneOf(['audioinput', 'audiooutput', 'videoinput']).isRequired,
    intl: PropTypes.any.isRequired
};

DeviceSelector.defaultProps = {
    value: undefined,
    inputLabel: null
};

export default injectIntl(memo(DeviceSelector));
