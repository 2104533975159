import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import styled from 'styled-components';

const Style = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .ant-spin-lg .ant-spin-dot i {
        width: 30px;
        height: 30px;
    }

    .ant-spin-lg .ant-spin-dot {
        font-size: 80px;
    }
`;

const Spinner = ({ size }) => (
    <Style>
        <Spin size={size} />
    </Style>
);

Spinner.propTypes = {
    size: PropTypes.string,
};

Spinner.defaultProps = {
    size: 'large'
};

export default Spinner;
