import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import Button from 'components/Shared/Common/Button';
import { MEMBER_STATUS } from 'const/feed';

import { getUrl } from 'utils/url';
import  { actions } from '@amplement/backend-connector';

import NotificationLayout from 'components/Shared/Notification/Layout';

const getLabelFromStatus = (subscriptionStatus) => {
    switch (subscriptionStatus) {
        case MEMBER_STATUS.DECLINED:
            return 'notification.feed.incoming.denied';
        case MEMBER_STATUS.ACCEPTED:
            return 'notification.feed.incoming.accepted';
        case MEMBER_STATUS.PENDING:
        default:
            return 'notification.feed.incoming.asked';
    }
}

const Feed = ({
    intl,
    onConfirm,
    onDeny,
    onCancel,
    user: {
        id: _user, avatarUri, title, firstname, lastname
    },
    feed: {
        name
    },
    subscriptionStatus,
    createdAt
}) => {
    const btnActions = [];

    if (subscriptionStatus === MEMBER_STATUS.PENDING ) {
        btnActions.push(
            <Fragment  key="pending">
                <div className="item">
                    <Button
                        onClick={onDeny}
                        color="secondary"
                    >
                        {intl.formatMessage({ id: 'global.button.deny' })}
                    </Button>
                </div>
                <div className="item">
                    <Button
                        onClick={onConfirm}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.accept' })}
                    </Button>
                </div>
            </Fragment>
        );
    }
    if (subscriptionStatus === MEMBER_STATUS.DECLINED) {
        btnActions.push(
            <div className="item" key="decline">
                <Button
                    onClick={onCancel}
                    color="secondary"
                >
                    {intl.formatMessage({ id: 'global.button.cancel' })}
                </Button>
            </div>
        );
    }

    return (
        <NotificationLayout
            imageSrc={avatarUri}
            imageHref={getUrl('profile', { _user })}
            imageAlt={`${firstname} ${lastname}`}
            date={createdAt}
            actions={btnActions?.length ? btnActions : null}
        >
            <div>
                {intl.formatMessage({ id: getLabelFromStatus(subscriptionStatus) }, { 
                    b: chunks => <b>{chunks}</b>,
                    feedname: name,
                    username: intl.formatMessage({ id: 'user.username' }, { firstname, lastname }),
                    title
                })}
            </div>
        </NotificationLayout>
    );
};

Feed.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onDeny: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        avatarUri: PropTypes.string,
        title: PropTypes.string.isRequired,
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
    }).isRequired,
    feed: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    createdAt: PropTypes.string.isRequired,
};

Feed.defaultProps = {
};

const Component = memo(injectIntl(Feed));

export default Component;

const mapDispatchToProps = (dispatch, { feed: { id: _feed } = {} }) => ({
    onConfirm: () => dispatch(
        actions.members.acceptMemberInvitationIntoFeed(_feed)
    ),
    onDeny: () => dispatch(
        actions.members.rejectMemberInvitationIntoFeed(_feed)
    ),
    onCancel: () => dispatch(
        actions.members.cancelMemberInvitationIntoFeed(_feed)
    ),
});

export const container = connect(null, mapDispatchToProps)(Component);
