import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AutoRefreshDate from 'components/Shared/Common/AutoRefreshDate';
import { Divider } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';

const StyledDivider = styled(Divider)`
    &.ant-divider{
        &-horizontal,
        &-with-text{
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            border-top-color: ${({theme}) => rgba(theme.black, .1)};
        }
    }
    .ant-divider{
        &-inner-text{
            color: ${({theme}) => rgba(theme.black, .4)};
            font-size: 12px;
            font-weight: 600;
            padding: 2px 4px;
        }
    }
`;

const SeparatorTemplate = ({
    createdAt,
    isRelative
}) => (
    <StyledDivider plain className="styled-divider">
        <AutoRefreshDate startDate={createdAt} isRelative={isRelative} />
    </StyledDivider>
);

SeparatorTemplate.propTypes = {
    createdAt: PropTypes.any.isRequired,
    isRelative: PropTypes.bool
};

SeparatorTemplate.defaultProps = {
    isRelative: false
};

export default memo(SeparatorTemplate);
