import styled from 'styled-components';
import { Alert } from 'antd';

const Container = styled(Alert)`
    width: 100%;
    z-index: 10;
    padding: 10px 20px!important;
`;

export default Container;
