import React, { memo } from 'react';
import dayjs from 'dayjs';

import { StyledTimePicker } from 'components/Shared/Forms/styles';

const TimePicker = ({ value, customFormat, ...props }) => (
    <StyledTimePicker
        {...props}
        value={value ? dayjs(value) : null}
        format={customFormat}
    />
);

export default memo(TimePicker);
