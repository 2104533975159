import Cookies from 'js-cookie';
import { utils } from '@amplement/backend-connector';

export function getToken() {
    const token = Cookies.get('jwt');
    return token || null;
}

export function decodeToken(token) {
    const myToken = token || getToken();
    if (myToken) {
        return utils.token.decodeToken(myToken);
    }
    return null;
}

export function getUserIdFromToken(token) {
    return (decodeToken(token) || {})._user;
}

export function setToken(token) {
    if (!token) return null;
    Cookies.set('jwt', token, { expires: 30, secure: true, sameSite: 'lax' });
    return Cookies.get('jwt');
}

export function deleteToken() {
    Cookies.remove('jwt');
    return true;
}

export function refreshExpirationToken() {
    return setToken(getToken());
}
