import React from 'react';

import Preview from 'components/Shared/Previews/Preview/container';

export default props => (
    <Preview
        content={file => (
            <div className="item active">
                <img
                    src={file}
                    className="loading"
                    alt="test"
                />
            </div>
        )}
        {...props}
    />
);
