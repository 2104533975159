import placeholderBroadCast2 from './placeholderBroadCast@2x.png';
import placeholderContact2 from './placeholderContact@2x.png';
import placeholderConversations from './placeholderConversations.png';
import placeholderConversations2 from './placeholderConversations@2x.png';
import placeholderConversations3 from './placeholderConversations@3x.png';
import placeholderFiles from './placeholderFiles.png';
import placeholderFiles2 from './placeholderFiles@2x.png';
import placeholderFiles3 from './placeholderFiles@3x.png';
import placeholderGroup2 from './placeholderGroup@2x.png';
import placeholderGroups from './placeholderGroups.png';
import placeholderGroups2 from './placeholderGroups@2x.png';
import placeholderGroups3 from './placeholderGroups@3x.png';
import placeholderList2 from './placeholderList@2x.png';
import placeholderLists2 from './placeholderLists@2x.png';
import placeholderNotification from './placeholderNotification.png';
import placeholderNotification2 from './placeholderNotification@2x.png';
import placeholderNotification3 from './placeholderNotification@3x.png';
import placeholderSIP from './placeholderSIP.png';
import placeholderSIP2 from './placeholderSIP@2x.png';
import placeholderSIP3 from './placeholderSIP@3x.png';
import placeholderSuggestions from './placeholderSuggestions.png';
import placeholderSuggestions2 from './placeholderSuggestions@2x.png';
import placeholderSuggestions3 from './placeholderSuggestions@3x.png';
import placeholder404 from './placeholder404.png';
import placeholder4042 from './placeholder404@2x.png';
import placeholder4043 from './placeholder404@3x.png';
import placeholderError from './placeholderError.png';
import placeholderError2 from './placeholderError@2x.png';
import placeholderError3 from './placeholderError@3x.png';
import placeholderErrorCode from './placeholderErrorCode.png';
import placeholderErrorCode2 from './placeholderErrorCode@2x.png';
import placeholderErrorCode3 from './placeholderErrorCode@3x.png';
import globalplaceholder from './global-placeholder.png';
import globalplaceholder2 from './global-placeholder@2x.png';
import globalplaceholder3 from './global-placeholder@3x.png';

export {
    placeholderBroadCast2,
    placeholderContact2,
    placeholderConversations,
    placeholderConversations2,
    placeholderConversations3,
    placeholderFiles,
    placeholderFiles2,
    placeholderFiles3,
    placeholderGroup2,
    placeholderGroups,
    placeholderGroups2,
    placeholderGroups3,
    placeholderList2,
    placeholderLists2,
    placeholderNotification,
    placeholderNotification2,
    placeholderNotification3,
    placeholderSIP,
    placeholderSIP2,
    placeholderSIP3,
    placeholderSuggestions,
    placeholderSuggestions2,
    placeholderSuggestions3,
    placeholder404,
    placeholder4042,
    placeholder4043,
    placeholderError,
    placeholderError2,
    placeholderError3,
    placeholderErrorCode,
    placeholderErrorCode2,
    placeholderErrorCode3,
    globalplaceholder,
    globalplaceholder2,
    globalplaceholder3
};
