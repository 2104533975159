import { connect } from 'react-redux';

import { selectors, actions, constants } from '@amplement/backend-connector';

import { openModal } from 'actions/modal';
import { MODAL_UPDATE_FEEDITEM, MODAL_DELETE_FEEDITEM } from 'types/modal';
import { getCurrentUserIdSelector } from 'selectors/user';
import Component from './index';

const mapStateToProps = (state, { _room, _feed }) => {
    const room = selectors.rooms.getRoomByIdSelector(state, _room);
    const { isPrivate, isPersistent } = room || {};
    const me = selectors.rooms.getMyClientAsARoomMemberSelector(state, _room);
    const { status, isAdmin } = me || {};
    let isLoading = false;
    let _selectedFeedItem;
    let items;
    let hasFeed = false;

    if (_feed) {
        items = selectors.feedItems.getFeedItems(state, _feed);
        isLoading = selectors.feedItems.isFetchingFeedItemsByFeedSelector(_feed)(state);
        const { _feedItem } = selectors.feeds.feedInputByIdSelector(_feed)(state);
        _selectedFeedItem = _feedItem;
        hasFeed = selectors.feeds.hasFeedByIdSelector(_feed)(state);
    }

    return ({
        _currentUser: getCurrentUserIdSelector(state),
        isEndReached: false,
        items,
        isLoading,
        _selectedFeedItem,
        isJoined: status === constants.rooms.USER_IN_ROOM_STATUS.JOINED,
        isPrivate,
        isPersistent,
        isMeAdmin: isAdmin,
        _feed,

        //
        hasFeed,
        isFeedsLoaded: selectors.feeds.getFeedsLoaded(state)
    });
};

const mapDispatchToProps = (dispatch, { _feed }) => ({
    getFeedItems: (reset = false) => dispatch(actions.feedItems.requestGetFeedItems(_feed, reset)),
    // updateFeedItem: (_feedItem, feedItem) => dispatch(actions.feeds.setFeedInput(_feed, feedItem?.content, _feedItem)),
    updateFeedItem: (_feedItem, feedItem) => dispatch(
        openModal(MODAL_UPDATE_FEEDITEM, { _feed, _feedItem, feedItem })
    ),
    deleteFeedItem: (_feedItem, content) => dispatch(
        openModal(MODAL_DELETE_FEEDITEM, { _feed, _feedItem, content })
    ),

    //
    setSelectedFeed: (reset) => dispatch(actions.feeds.setSelectedFeed(_feed, reset)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
