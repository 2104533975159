import { selectors } from '@amplement/backend-connector';
import { createSelector } from 'reselect';
import { getCurrentUserIdSelector } from './user';

export const getAwayRoomMemberIdsWithoutMyUserByRoomIdSelector = createSelector(
    [selectors.rooms.getAwayRoomMemberIdsByRoomIdSelector, getCurrentUserIdSelector],
    (_members, _user) => _members?.filter(x => x !== _user)
);

export const getRoomFeatures = createSelector(
    [selectors.rooms.getRoomByIdSelector],
    (room) => room?.features
);
