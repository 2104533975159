import { rgba, lighten, mix, darken } from 'polished';

export const defaultColors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b', '#2C3A47', '#182C61', '#FEA47F'];

export const colors = {
    defaultGroupColor: '#0996C3',
    secondaryActive: '#EEEEEE',
    secondaryActiveHover: `${darken(0.05, '#EEEEEE')}`,
    bgTooltip: '#121212',
    error: '#e74c3c',
    bgRoom: '#272727',
    white: '#FFFFFF',
    black: '#000000',
    infos: '#458cff',
    bgUnsupportedNavigator: `${rgba('#F74646', 0.5)}`,
    roomPulseBullet: '#EB4D5C',
    warning: '#FF6600',
    success: '#17BF63',
    lighten: '#fafafa',
    fixedWhite: '#FFFFFF',
    fixedBlack: '#000000',
    badges:{
        bgAdmin: '#FFD100',
        bgCreator: '#17BF63',
        bgPending: '#F7F8F9',
    },
    status: {
        busy: '#F74646',
        away: '#ffa162',
        offline: '#CCC',
        connect: '#17BF63'
    },
    feedItem: {
        bgEdit: '#fff2ac',
        bgJoin: `${lighten(.55, '#17BF63')}`,
        bgLeave: `${lighten(.40, '#e74c3c')}`,
        bgFeedAction: '#F9F9F9',
    },
    bg: {
        global: '#fff',
        dragAndDrop: '#091E42',
        modal: '#FFF'
    }
};

export const lightTheme = {
    ...colors,
    bgRoom: '#FFFFFF',
    groupSearch: '#F9F9F9',
    bgCloseModal: '#ccc',
    bgHoverListUsers: '#EEE',
    bgModalFooter: '#eee',
    bgNotificationDoubleCall: '#EEE'
};

export const darkTheme = {
    ...colors,
    white: '#36393F',
    black: '#FFFFFF',
    lighten: `${lighten('0.1', '#36393F')}`,
    bgRoom: '#272727',
    groupSearch: '#1C1C1C',
    bgCloseModal: '#606060',
    bgHoverListUsers: `${rgba('#FFF', 0.1)}`,
    bgModalFooter: `${rgba('#FFF', 0.1)}`,
    bgNotificationDoubleCall: '#121212',
    secondaryActive: `${lighten('0.15', '#36393F')}`,
    secondaryActiveHover: `${lighten('0.10', '#36393F')}`,
    feedItem: {
        bgEdit: '#fff2ac',
        bgJoin: `${mix(.10, '#17BF63', '#36393F')}`,
        bgLeave: `${mix(.10, '#e74c3c', '#36393F')}`,
        bgFeedAction: `${rgba('#FFF', 0.1)}`,
    },
    bg: {
        global: '#36393F',
        modal: '#272727',
        dragAndDrop: '#091E42',
    }
};

