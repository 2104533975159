export function copyToClipboard(value) {

    const tempNode = document.createElement('input');
    tempNode.setAttribute('type', 'text');
    tempNode.setAttribute('value', value);
    document.body.appendChild(tempNode);
    tempNode.select();
    document.execCommand('copy');
    document.body.removeChild(tempNode);

}
