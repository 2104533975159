import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import styled from 'styled-components';

const StyledReply = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 75%;
    width: 100%;
    margin: 0 auto 0px;
    background-color: ${props => props.theme.secondaryActive};
    padding: 10px 10px 10px 14px;
    border-radius: 6px;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        border-radius: 6px 0 0 6px;
        background-color: ${props => props.theme.mainColor2};
        top: 0;
    }
`;

const { Text } = Typography;

const ReplyArea = memo(({ content }) => (
    <StyledReply>
        <Text ellipsis>{content}</Text>
    </StyledReply>
));

ReplyArea.propTypes = {
    content: PropTypes.string,
};

ReplyArea.defaultProps = {
    content: '',
};

export default ReplyArea;
