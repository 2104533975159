import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Logger from 'services/debug/logger';
import ErrorFormatter from 'components/Shared/Errors/Formatter';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import ContentErrorComponent from 'components/Shared/Errors/ContentError';
import { utils } from '@amplement/backend-connector';

const logger = new Logger('component:CatchError');

export default class CatchError extends PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.element])
            .isRequired,
        fallbackRender: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.node,
            PropTypes.element,
            PropTypes.string
        ]),
        id: PropTypes.string
    };

    static defaultProps = {
        fallbackRender: null,
        id: null
    };

    state = {
        error: undefined
    };

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.clearError();
        }
    }

    // Note : is possible to get the Error object from getDerivedStateFromError args
    static getDerivedStateFromError(error) {
        return {
            error
        };
    }

    componentDidCatch(error, infos) {
        logger.warn(error, infos);
        utils.errorHandler.captureException(error, 'component:CatchError');
        this.setState({ error });
    }

    clearError = () => this.setState({ error: null });

    render() {
        const { error } = this.state;
        const { children, fallbackRender } = this.props;

        if (error) {
            if (typeof fallbackRender === 'string') {
                return fallbackRender;
            }
            if (React.isValidElement(fallbackRender)) {
                return fallbackRender;
            }
            if (typeof fallbackRender === 'function') {
                return fallbackRender(error);
            }
            return (
                <ErrorFormatter
                    error={error}
                    buttonType={BUTTON_TYPE.GOHOME}
                    render={ContentErrorComponent}
                />
            );
        }

        return children;
    }
}
