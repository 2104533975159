import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Style } from './style';

const Toast = (props) => {
    const {
        classPrefix,
        style,
        children,
        onClose
    } = props;
    const componentClass = `${classPrefix}-toast`;
    const className = {
        [`${componentClass}`]: 1,
        [props.className]: !!props.className
    };

    return (
        <Style
            className={classNames(className)}
            style={style}
        >
            <div className={`${componentClass}-content`}>
                {React.cloneElement(
                    children,
                    { onClose }
                )}
            </div>
        </Style>
    );
};

Toast.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    classPrefix: PropTypes.string,
    style: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

Toast.defaultProps = {
    classPrefix: '',
    className: '',
    style: {}
};

export default Toast;
