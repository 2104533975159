import { connect } from 'react-redux';

import { selectors, constants } from '@amplement/backend-connector';
import Component from 'components/Shared/Call/ResumeCallActions';
import { getCurrentClientIdSelector } from 'selectors/user';

const mapStateToProps = (state, { _room }) => {
    const room = selectors.rooms.getRoomByIdSelector(state, _room);
    const _client = getCurrentClientIdSelector(state);
    const { name: roomName } = room || {};
    let isLocalTrigger = false;
    const otherMembers = selectors.rooms.getConnectedRoomMembersWithoutMyDeviceByRoomIdSelectorLight(state, _room);

    let initiator;
    let initiatorName;
    let shouldDisplayMembers = true;

    if (room) {
        const currentMember = selectors.rooms.getMyClientAsARoomMemberSelector(state, _room);

        if (currentMember?.status === constants.rooms.USER_IN_ROOM_STATUS.HOLD
            && currentMember?._client === _client) {
            isLocalTrigger = true;
            initiator = currentMember;
        } else if (otherMembers) {
            initiator = otherMembers.find(x => x.status === constants.rooms.USER_IN_ROOM_STATUS.HOLD);
        }
    }

    if (initiator) {
        initiatorName = initiator.fullname;
        if (otherMembers?.length === 1 && otherMembers[0].fullname === initiator?.fullname) {
            shouldDisplayMembers = false;
        }
    }

    return ({
        initiatorName,
        isLocalTrigger,
        roomName,
        features: room?.features,
        members: room?.isPersistent || !shouldDisplayMembers ? undefined : otherMembers
    });
};

export default connect(mapStateToProps, null)(Component);
