import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form } from 'antd';
import Actions from 'components/Shared/Forms/Actions';
import { LabelInput } from 'components/Shared/Forms';
import EmailInput from 'components/Shared/Forms/EmailInput';
import { getCurrentUserSelector, getRequestedUserActionsSelector } from 'selectors/user';
import { requestPatchUserEmail } from 'actions/session';
import injectValidators from 'components/Shared/Forms/injectValidators';

const formatMessages = intl => ({
    passwordPlaceholder: intl.formatMessage({
        id: 'settings.resetEmail.password.placeholder',
        defaultMessage: 'Au moins 6 charactères'
    }),
    passwordLabel: intl.formatMessage({
        id: 'settings.resetEmail.password.label',
        defaultMessage: 'Mot de passe'
    }),
    emailErrorDifferents: intl.formatMessage({
        id: 'settings.resetEmail.differents',
        defaultMessage: 'Emails are differents'
    }),
});

const createValidator = intl => ({ email = {} } = {}) => {
    const errors = {}
    const i18n = formatMessages(intl);

    if (email.newEmail !== email.confirmEmail) {
        errors.email = errors.email || {};
        errors.email.confirmEmail = i18n.emailErrorDifferents;
    }
    return errors;
}

const ResetEmailForm = memo(({
    intl,
    validators,
    title,
    requestedActions,
    onSubmit,
}) => {
    const { required } = validators;
    const hasEmailWarning = requestedActions.some(x => x.key === 'emailReset');
    const i18n = useMemo(() => formatMessages(intl), [intl]);
    const validate = createValidator(intl);

    return (
        <FinalForm onSubmit={onSubmit} validate={validate}>
            {({ handleSubmit, pristine, submitting, valid, form }) => (
                <Form onSubmit={handleSubmit} layout="vertical">
                    {title && (
                        <div className="subtitle">
                            {intl.formatMessage({ id: title })}
                        </div>
                    )}

                    <EmailInput name="email" validators={validators} />

                    {!hasEmailWarning && (
                        <Field
                            name="password"
                            validate={required}
                        >
                            {({ input, ...rest }) => (
                                <LabelInput
                                    placeholder={i18n.passwordPlaceholder}
                                    name={input.name}
                                    label={i18n.passwordLabel}
                                    type="password"
                                    {...input}
                                    {...rest}
                                />
                            )}
                        </Field>
                    )}

                    <Actions
                        className="action"
                        pristine={pristine}
                        reset={form.restart}
                        submitting={submitting}
                        submit={form.submit}
                        valid={valid}
                    />
                </Form>
            )}
        </FinalForm>
    );
});

ResetEmailForm.propTypes = {
    intl: PropTypes.any.isRequired,
    validators: PropTypes.shape({
        required: PropTypes.func.isRequired,
        email: PropTypes.func.isRequired
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    requestedActions: PropTypes.array,
    title: PropTypes.string
};

ResetEmailForm.defaultProps = {
    title: 'settings.resetEmail.title',
    requestedActions: []
};

const mapStateToProps = state => ({
    initialValues: getCurrentUserSelector(state).settings,
    requestedActions: getRequestedUserActionsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    onSubmit: ({ email: { newEmail }, password }, form) => {
        dispatch(requestPatchUserEmail(newEmail, password));
        form.restart();
    }
});

export default compose(
    injectValidators,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
)(ResetEmailForm);
