import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import InfiniteScrollLoader from 'components/Feed/FeedItemList/InfiniteScrollLoaderMap';
import FeedItemWrapper from 'components/Feed/FeedItemList/ItemWrapper';

import Loader from 'components/Shared/Common/Loader';
import { StyledInterfaceWrapper } from 'components/Shared/style';

const FeedItemList = (props) => {
    const {
        items,
        _currentUser,
        isLoading,
        updateFeedItem,
        deleteFeedItem,
        onReplyFeedItem,
        getFeedItems,
        _selectedFeedItem,
        _feed,
        isEndReached,
        isAvatarClickable,
        hasStatus,
        ItemComponent,
        prefix
    } = props;
    const loadMore = useCallback((reset) => getFeedItems(reset), [getFeedItems]);

    if (!items?.length && isLoading) {
        return (
            <StyledInterfaceWrapper className="interface-content-wrapper">
                <Loader size="md" />
            </StyledInterfaceWrapper>
        );
    }

    return (
        <InfiniteScrollLoader
            id={_feed}
            items={items}
            isEndReached={isEndReached}
            onLoadMoreRows={loadMore}
            initialScrollPosition="bottom"
            loadMoreOffset="100"
            autoScrollTolerance={0.2}
            onEditItem={updateFeedItem}
            onDeleteItem={deleteFeedItem}
            onReplyItem={onReplyFeedItem}
            _selectedItem={_selectedFeedItem}
            _currentUser={_currentUser}
            isAvatarClickable={isAvatarClickable}
            hasStatus={hasStatus}
            ItemComponent={ItemComponent}
            prefix={prefix}
        />
    );
}

FeedItemList.propTypes = {
    _feed: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    _currentUser: PropTypes.string.isRequired,
    getFeedItems: PropTypes.func.isRequired,
    updateFeedItem: PropTypes.func.isRequired,
    deleteFeedItem: PropTypes.func.isRequired,
    onReplyFeedItem: PropTypes.func,
    isLoading: PropTypes.bool,
    isEndReached: PropTypes.bool,
    _selectedFeedItem: PropTypes.string,
    isAvatarClickable: PropTypes.bool,
    hasStatus: PropTypes.bool,
    ItemComponent: PropTypes.any,
    prefix: PropTypes.any,
};

FeedItemList.defaultProps = {
    _selectedFeedItem: null,
    onReplyFeedItem: () => {},
    isLoading: false,
    isEndReached: false,
    isAvatarClickable: undefined,
    hasStatus: undefined,
    prefix: undefined,
    ItemComponent: FeedItemWrapper
};

export default memo(FeedItemList, isEqual);
