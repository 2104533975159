import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { usePrevious } from 'react-use';
import PropTypes from 'prop-types';
import { Button, Space, Typography, message } from 'antd';
import { injectIntl } from 'react-intl';

import useForwardRules, { usePostBulkForwardRules } from 'hooks/useForwardRules';

import Spinner2 from 'components/Shared/Common/Spinner2';
import List from 'components/Shared/Drawers/Sip/Forwards/TimeBasedForwards/List';
import TimeBasedForm from 'components/Shared/Drawers/Sip/Forwards/TimeBasedForwards/Forms';
import BulkForm from 'components/Shared/Drawers/Sip/Forwards/TimeBasedForwards/Forms/BulkForm';

const { Text } = Typography;

const styleWidth = { width: '100%' };
const buttonStyle = { float: 'right' };

const formatMessages = (intl) => ({
    addRule: intl.formatMessage({ id: 'call.tabs.forwards.addRule' }),
    error: intl.formatMessage({ id: 'error.default' }),
    saveSuccess: intl.formatMessage({ id: 'global.button.saveSuccess' }),
    saveFailure: intl.formatMessage({ id: 'call.tabs.forwards.saveFailure' }),
});

const TimeBasedForwards = ({ intl, _sipAccount }) => {
    const [loadingSwitchIds, setLoadingSwitchIds] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const translations = useMemo(() => formatMessages(intl), [intl]);

    const {
        isLoading, error, isError, data, errorMessage
    } = useForwardRules({ _sipAccount });
    const {
        isLoading: postBulkIsLoading,
        mutate: postBulk,
        error: postBulkError,
        data: postBulkData,
    } = usePostBulkForwardRules({ _sipAccount });
    const prevBulkData = usePrevious(postBulkData);

    const [isForm, toggleForm] = useState(false);
    const [item, setItem] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleEdit = useCallback((selectedItem) => {
        toggleForm(true);
        setItem(selectedItem);
    }, []);

    const handleCreate = useCallback(() => {
        toggleForm(true);
        setItem(null);
    }, []);

    const onSubmit = useCallback(async ({ option }) => {
        try {
            await postBulk({ ids: selectedRows, task: option });
            setSelectedRows([]);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: translations.saveFailure,
            });
        }
    }, [selectedRows, postBulk, messageApi, translations]);

    const onPostBulk = useCallback(({ ids, task }) => {
        setLoadingSwitchIds([...loadingSwitchIds, ...ids]);
        postBulk({ ids, task });
    }, [postBulk, loadingSwitchIds]);

    useEffect(() => {
        if (postBulkData && postBulkData?.config?.data !== prevBulkData?.config?.data) {
            setLoadingSwitchIds(
                loadingSwitchIds.filter(({ id }) => postBulkData?.data?.find(postId => postId === id)),
            );
        }
    }, [postBulkData, loadingSwitchIds]);

    const handleSelectRows = useCallback((rows) => setSelectedRows(rows), []);

    if (isLoading) {
        return <Spinner2 />;
    }

    if (isError) {
        return <Text type="danger">{error?.message|| translations.error}</Text>;
    }

    return (
        <>
            {contextHolder}
            <Space direction="vertical" size="large" style={styleWidth}>
                {!isForm && (
                    <>
                        <Button style={buttonStyle} type="primary" onClick={handleCreate}>
                            {translations.addRule}
                        </Button>
                        <List
                            data={data}
                            handleEdit={handleEdit}
                            handleSelectRows={handleSelectRows}
                            selectedRows={selectedRows}
                            handleUpdateStatus={onPostBulk}
                            loadingIds={loadingSwitchIds}
                            isLoading={postBulkIsLoading && loadingSwitchIds.length < 1}
                        />
                        {postBulkError && (
                            <Text type="danger">
                                {postBulkError?.response?.data?.message || postBulkError?.message || translations.error}
                            </Text>
                        )}
                        {selectedRows.length > 0 && <BulkForm onSubmit={onSubmit} />}
                    </>
                )}
                {isForm && (
                    <TimeBasedForm
                        item={item}
                        toggleForm={toggleForm}
                        errorMessage={errorMessage}
                        _sipAccount={_sipAccount}
                    />
                )}
            </Space>
        </>
    );
};

TimeBasedForwards.propTypes = {
    _sipAccount: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

TimeBasedForwards.defaultProps = {
    _sipAccount: '',
};

export default injectIntl(TimeBasedForwards);
