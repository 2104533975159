import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Shared/Common/Icon';
import styled, { keyframes } from 'styled-components';
import { colors } from 'common/themes/Colors';
import { Tooltip, Typography } from 'antd';

const { Text } = Typography;

const StyleVoiceTester = styled.div`
    margin: 0px 0 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    &.danger{
        span{
            &.active{
                background-color: ${colors.error};
            }
        }
    }
    span{
        transition: all 250ms ease;
        display: flex;
        border-radius: 30px;
        height: 8px;
        background-color: ${colors.secondaryActive};
        flex: 1;
        margin-right: 4px;
        &:last-child{
            margin-right: 0;
        }
        &.active{
            background-color: ${props => props.theme.mainColor2};
        }
    }
`;

const bar = keyframes`
    0%   {transform: scale(1,0.25); }
    25%  {transform: scale(1,0.5); }
    50%  {transform: scale(1,0.75); }
    75%  {transform: scale(1,1); }
    100% {transform: scale(1,1.25);}
`;

const StyledVoiceDetection = styled.div`
    transition: all 250ms ease;
    display: flex;
    align-items: center;
    .voice-detection{
        font-size: 14px;
        color: ${({theme}) => theme.mainColor2};
        transition: all 250ms ease;
        opacity: 1;
        visibility: visible;
        padding: 0;
        &.active{
            .voice-bar{
                .bar{
                    height: 15px;
                    animation-name: ${bar};
                }
            }
        }
        .voice-bar{
            width: 14px;
            height: 14px;
            display: flex;
            flex-shrink: 0;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 0 auto;
            text-align: center;
            .bar{
                display: flex;
                flex-shrink: 0;
                background-color: ${({theme}) => theme.mainColor2};
                height: 3px;
                width: 3px;
                border-radius: 6px;
                text-indent: -9999px;
                transform: translateX(-50%);
                animation: none 0ms -1000ms linear infinite alternate;
                margin-right: 2px;
                &:last-child{
                    margin-right: 0;
                }
                &-0{
                    animation-duration: 337ms;
                }
                &-1{
                    animation-duration: 321ms;
                }
                &-2{
                    animation-duration: 353ms;
                }
            }
        }
    }
    
`;

const StyleEqualizer = styled.div`
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 32px;
    flex-shrink: 0;
    height: 32px;
    &.label{
        width: auto;
        padding: 0 5px;
        .label{
            display: flex;
            align-items: center;
            margin-left: 5px;
            font-size: 12px;
            position: relative;
            z-index: 2;
        }
    }
    .overlay{
        bottom: 0;
        width: 100%;
        height: 0;
        transition: all 250ms ease;
        position: absolute;
        background-color: rgba(46,204,113,0.8);
    }
    .icon{
        display: flex;
        position: relative;
        z-index: 1;
        &.danger{
            svg{
                color: ${colors.error};
            }
        }
        svg{
            width: 16px;
            height: 16px;
            mix-blend-mode: difference;
        }
    }
`;

export const AudioStrengthVisualizerButton = memo(({ strength }) => {
    const random = Math.floor(Math.random() * 10) % 10 + 5;
    const percent = Math.min(Math.ceil((strength || 0)) * 10, 85) + random;

    return (
        <StyleEqualizer className="equalizer">
            <div className="icon">
                <Text>
                    {strength === -1 ?
                        <Icon iconName="MicOff" color="danger" /> :
                        <Icon iconName="Mic" />}
                </Text>
            </div>
            <span
                className="overlay"
                style={{ height: `${strength !== -1 ? percent : '0'}%` }}
            />
        </StyleEqualizer>
    );
});

AudioStrengthVisualizerButton.propTypes = {
    strength: PropTypes.number
};

AudioStrengthVisualizerButton.defaultProps = {
    strength: 0
};

const IconMute = memo(({ isError, error }) => (
    <Tooltip 
        title={error}
        placement="left"
    >
        <div className="icon-container">
            <Icon
                iconName="MicOff"
                className={isError ? 'danger' : 'lighten'}
            />
        </div>
    </Tooltip>
))

IconMute.propTypes = {
    isError: PropTypes.bool,
    error: PropTypes.string
};

IconMute.defaultProps = {
    isError: false,
    error: undefined
};

export const AudioSpeakingVisualizer = memo(({
    error,
    isError,
    isMuted,
    isActive
}) => {
    if (isError || isMuted) {
        return <IconMute isError={isError} error={error} />;
    }

    return (
        <StyledVoiceDetection>
            <span className={`voice-detection${isActive ? ' active' : ''}`}>
                <div className="voice-bar">
                    <span className="bar bar-0" />
                    <span className="bar bar-1" />
                    <span className="bar bar-2" />
                </div>
            </span>
        </StyledVoiceDetection>
    );
});

AudioSpeakingVisualizer.propTypes = {
    isActive: PropTypes.bool,
    isError: PropTypes.bool,
    isMuted: PropTypes.bool,
    error: PropTypes.string
};

AudioSpeakingVisualizer.defaultProps = {
    isActive: false,
    isError: false,
    isMuted: false,
    error: undefined
};

const AudioStrengthVisualizer = ({
    strength,
    bulletCount
}) => {
    const renderItem = (_, index) => (
        <span
            key={index}
            className={(Math.round(strength || 0)) > index ? 'active' : ''}
        />
    );

    return (
        <StyleVoiceTester className={`${strength === -1 ? ' danger' : ''}`}>
            {Array(bulletCount).fill(0).map(renderItem)}
        </StyleVoiceTester>
    );
}

AudioStrengthVisualizer.propTypes = {
    strength: PropTypes.number,
    bulletCount: PropTypes.number
};

AudioStrengthVisualizer.defaultProps = {
    strength: 0,
    bulletCount: 10
};

export default AudioStrengthVisualizer;
