import * as companyTypes from 'types/company';
import * as userTypes from 'types/user';
import { RequestHelper, ReducerEntityBase } from 'utils/state/reducer';
import RequestStateFormatter from 'utils/state/requestStateFormatter';
import { getStoreKeyByContactStatus } from 'utils/user';

const initialState = {
    loaded: null,
    users: [],
    usersNotConnected: [],
    usersAccepted: [],
    usersPendingInitiator: [],
    usersPendingTarget: []
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case userTypes.WS_POST_CONTACT: {
            let newState = { ...state };
            const user = action.payload;

            newState = RequestHelper(newState, 'usersNotConnected').deleteById(action.payload.id);

            if (user.isInitiator !== undefined) {
                const pendingStore = user.isInitiator ? 'usersPendingInitiator' : 'usersPendingTarget';
                newState = RequestHelper(newState, pendingStore)
                    .update(RequestStateFormatter.success(action));
            }

            return newState;
        }

        case userTypes.WS_PUT_CONTACT: {

            let newState = { ...state };
            newState = RequestHelper(newState, 'usersPendingInitiator').deleteById(action.payload.id);
            newState = RequestHelper(newState, 'usersPendingTarget').deleteById(action.payload.id);
            newState = RequestHelper(newState, 'usersAccepted').update(RequestStateFormatter.success(action));
            return newState;

        }

        case userTypes.WS_DELETE_CONTACT: {
            let newState = { ...state };
            newState = RequestHelper(newState, 'usersPendingInitiator').deleteById(action.payload.id);
            newState = RequestHelper({ ...newState }, 'usersPendingTarget').deleteById(action.payload.id);
            newState = RequestHelper({ ...newState }, 'usersAccepted').deleteById(action.payload.id);

            return newState;
        }

        case userTypes.SEARCH_USERS_REQUEST:
        case userTypes.SEARCH_USERS_SUCCESS:
        case userTypes.SEARCH_USERS_FAILURE:
        case companyTypes.GET_COMPANY_USERS_REQUEST:
        case companyTypes.GET_COMPANY_USERS_SUCCESS:
        case companyTypes.GET_COMPANY_USERS_FAILURE: {
            const storeKey = getStoreKeyByContactStatus(
                action.params.contactStatus,
                action.params.isInitiator
            );
            // console.log(
            // 'seleeeeect2',
            // action.type, storeKey,
            // action.params.contactStatus,
            // action.params.isInitiator
            // );

            return ReducerEntityBase(state, storeKey, action, true);
        }

        case userTypes.SEARCH_USERS_CLEAR:
            return {
                ...state,
                [action.storeKey]: []
            };

        case userTypes.DECREASE_USER_COUNT: {
            const items = state[action.objectName] ? [...state[action.objectName]] : null;
            if (items && items.length) {
                const lastItem = { ...items.pop() };
                if (lastItem.pagination) lastItem.pagination.count -= 1;
                items.push(lastItem);
                return {
                    ...state,
                    [action.objectName]: items
                };
            }
            return state;
        }

        default:
            return state;
    }
};

export default searchReducer;
