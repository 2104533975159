import config from 'config';
import storage from 'services/storage/localStorage';

export const ONE_DAY = 86400 * 1000;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_MONTH = ONE_DAY * 30;

export const FULL_DATE_FORMAT_CONFIG = { month: 'long', day: '2-digit', year: 'numeric' };

export const isStringDate = date => typeof date === 'string' && !Number.isNaN(Date.parse(date));

export const getDate = date => (date instanceof Date ? date : new Date(date));

export const isToday = date => new Date().setHours(0, 0, 0, 0)
    === getDate(date).setHours(0, 0, 0, 0);

export const isSameDate = (previousDate, currentDate) => {
    if (!isStringDate(previousDate) && !(previousDate instanceof Date)) {
        throw new Error('previousDate must be string or Date');
    }
    if (!isStringDate(currentDate) && !(currentDate instanceof Date)) {
        throw new Error('currentDate must be string or Date');
    }
    const prev = getDate(previousDate);
    const curr = getDate(currentDate);
    prev.setHours(0, 0, 0, 0);
    curr.setHours(0, 0, 0, 0);
    return prev.toDateString() === curr.toDateString();
};

export const isRelativeDate = (date) => {
    if (!isStringDate(date) && !(date instanceof Date)) {
        throw new Error('date must be string or Date');
    }
    return Math.abs(
        new Date(date).getTime() - new Date().getTime()
    ) / (1000 * 3600 * 24) <= config.feedItem.relativeMaxDuration;
};

export const isBefore = (date1, date2) => {
    const dateA = getDate(date1);
    const dateB = getDate(date2);
    return ((!date1 && date2) || (dateA < dateB));
}

export const getIsExpired = (storageItem, duration) => {
    const value = storage.get(storageItem);
    let isExpired = true;

    if (value) {
        const timestamp = Date.parse(value);

        if (Date.now() - timestamp < duration) {
            isExpired = false;
        }
    }

    return isExpired;
};

export const setExpiration = (storageItem) => {
    storage.set(storageItem, new Date());
}
