import { useEffect, memo } from 'react';
import useNetworkState from "hooks/useNetworkState";
import { notification } from 'antd';
import { injectIntl } from 'react-intl';
import { constants } from '@amplement/backend-connector';

const NetworkErrorToaster = ({ intl }) => {
    const { wsStatus, isOnline } = useNetworkState(2000);
    const [api, contextHolder] = notification.useNotification();
    const isOpen = wsStatus === constants.network.READY_STATE.OPEN;

    useEffect(() => {
        const key = 'network';
        if (!isOnline) {
            api.error({
                key,
                message: intl.formatMessage({ id: 'error.title.networkError' }),
                description: intl.formatMessage({ id: 'global.websocket.status.offline'}),
                duration: 0
            });
        }
        else if (!isOpen) {
            api.warning({
                key,
                message: intl.formatMessage({ id: 'error.title.badConnection' }),
                description: intl.formatMessage({ id: 'error.networkDegradedMode' }),
                duration: 0
            });
        } else {
            api.destroy(key);
        }

        return () => {
            api.destroy(key);
        }
    }, [isOpen, isOnline, api])
    
    return contextHolder;
}

export default injectIntl(memo(NetworkErrorToaster));
