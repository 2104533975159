import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';


const Style = styled.div`
    .loader {
        ${'' /* width: 100px;
        height: 100px; */}
        -webkit-animation: loader 3s infinite normal;
        animation: loader 3s infinite normal;
        -webkit-filter: url(#goo);
        filter: url(#goo);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100px;
        width: 100px;
    }
    .dot {
        width: ${props => props.size === 'md' ? '15px' : '30px'};
        height: ${props => props.size === 'md' ? '15px' : '30px'};
        background: ${props => props.color};
        border-radius: 100%;

        transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .dot-1 {
        animation: dot1 1.5s infinite alternate;
    }
    .dot-2 {
        animation: dot2 1.5s infinite alternate;
    }
    .dot-3 {
        animation: dot3 1.5s infinite alternate;
    }
    .dot-4 {
        animation: dot4 1.5s infinite alternate;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    .loading svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const CustomLoader = ({ size, color }) => (
    <Style size={size} color={color}>
        <div className="loader">
            <div className="dot dot-1">&nbsp;</div>
            <div className="dot dot-2">&nbsp;</div>
            <div className="dot dot-3">&nbsp;</div>
            <div className="dot dot-4">&nbsp;</div>
        </div>
        <svg>
            <defs>
                <filter id="goo">
                    <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="8"
                        result="blur"
                    />
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 100 -45"
                        result="goo"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="goo"
                    />
                </filter>
            </defs>
        </svg>
    </Style>
);

const BrandedLoader = (props = {}) => {
    const { mainColor2 } = useContext(ThemeContext);

    return (
        <CustomLoader {...props} color={mainColor2} />
    );
};

const Loader = (props) => {
    const Component = props.color ? CustomLoader : BrandedLoader;

    return <Component {...props} />;
}

Loader.propTypes = {
    size: PropTypes.oneOf([
        'md',
        'lg'
    ]),
    color: PropTypes.string
};

Loader.defaultProps = {
    size: 'md',
    color: undefined
};


export default Loader;
