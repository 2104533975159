import { schema } from 'normalizr';

const optionWithReplaceStrategy = {
    processStrategy: entity => Object.keys(entity).reduce((acc, curr) => {
        acc[curr.replace(/^_/, '')] = entity[curr];
        return acc;
    }, {})
};

const presence = new schema.Entity('presences');

const user = new schema.Entity(
    'users',
    { presence },
    {
        processStrategy: entity => ({
            ...entity,
            presence: entity.id
        })
    }
);

const feed = new schema.Entity('feeds', { user }, optionWithReplaceStrategy);

const room = new schema.Entity('rooms', { targets: [user] });

const feedItem = new schema.Entity('feedItems', { user, feed }, optionWithReplaceStrategy);

const feedExtended = new schema.Entity('feeds', { user }, optionWithReplaceStrategy);

const account = new schema.Entity('callAccounts', { user }, { ...optionWithReplaceStrategy });

const activity = new schema.Entity('activities', { user });

const callAccount = new schema.Entity('callAccounts');

const feedMembers = new schema.Entity('feedMembers', { user }, {
    processStrategy: entity => ({ [entity.user.id]: entity.subscriptionStatus, user: entity.user }),
    idAttribute: '_feed'
});

const subscription = new schema.Entity('subscriptions', {
    target: user,
    initiator: user,
    feed
}, optionWithReplaceStrategy);

const notification = new schema.Entity('notifications', {
    payload: {
        user,
        feed
    }
}, optionWithReplaceStrategy);

export default {
    users: user,
    subscriptions: subscription,
    rooms: room,
    presences: presence,
    feedItems: feedItem,
    feeds: feed,
    feedExtendeds: feedExtended,
    accounts: account,
    activities: activity,
    notifications: notification,
    feedMembers,
    callAccounts: callAccount
};
