import React, { useMemo } from 'react';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';

import config from 'config';

import { getIsExpired, ONE_WEEK, setExpiration } from 'utils/date';

import supportedBrowsersRegex from './supportedBrowsers';
import Container from './styles';

function onClose() {
    setExpiration('showSupportedBrowsersWarningAt');
};

const SupportedBrowsers = ({ intl }) => {
    const translations = useMemo(() => ({
        description: intl.formatMessage({ id: 'global.unsupportedBrowser.message' }),
        errorMessage: intl.formatMessage({ id: 'global.unsupportedBrowser.title' }),
        button: intl.formatMessage({ id: 'global.unsupportedBrowser.button' })
    }), [intl]);

    if (supportedBrowsersRegex.test(navigator.userAgent) || !getIsExpired('showSupportedBrowsersWarningAt', ONE_WEEK)) {
        return null;
    }

    return (
        <Container
            message={translations.errorMessage}
            description={translations.description}
            type="error"
            closable
            banner
            showIcon={false}
            onClose={onClose}
            action={
                <Button
                    href={config.browserUpdateLink}
                    target="_blank"
                    type="primary"
                >
                    {translations.button}
                </Button>
            }
        />
    );
}

export default injectIntl(SupportedBrowsers);
