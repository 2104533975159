import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { injectIntl } from 'react-intl';

import { ContentMaskLayout } from 'components/Room/styles';
import ResumeCallActions from 'components/Room/containers/ResumeCallActions';
import { RECORDER_STATUS } from 'components/Shared/Recorder/constants';

const ContentMaskManager = (props) => {
    const {
        isHold,
        onStopRecording,
        handleChangeStatus,
        recorderState,
        _room
    } = props;

    useWhyDidYouUpdate('component:Room:Mask:ContentMaskManager', props);

    return isHold && (
        <ContentMaskLayout>
            <ResumeCallActions
                recorderState={recorderState}
                onStopRecording={onStopRecording}
                handleChangeStatus={handleChangeStatus}
                _room={_room}
            />
        </ContentMaskLayout>
    );
}

ContentMaskManager.propTypes = {
    isHold: PropTypes.bool,
    onStopRecording: PropTypes.func,
    recorderState: PropTypes.string,
    handleChangeStatus: PropTypes.func,
    _room: PropTypes.string
}

ContentMaskManager.defaultProps = {
    onStopRecording: () => {},
    recorderState: RECORDER_STATUS.STOPPED,
    handleChangeStatus: () => {},
    _room: undefined,
    isHold: false
};

export default injectIntl(memo(ContentMaskManager));
