import * as types from 'types/user';
import {
    $patchContact,
    $deleteContact,
    $postContact,
    $getContact,
    $searchUsers,
    $requestGetUsers,
    $requestGetUser
} from 'api/user';
import { getCompanySelector } from 'selectors/company';
import { user as userSchema } from 'schemas/user';
import { CONTACT_STATUS } from 'const/user';

export const requestGetUser = (_user, isFlushBeforeFetch) => ({
    types: [
        types.GET_USER_REQUEST,
        types.GET_USER_SUCCESS,
        types.GET_USER_FAILURE
    ],
    callAPI: () => $requestGetUser(_user),
    forceCallApi: false,
    schemas: userSchema,
    isFlushBeforeFetch,
    shouldCallAPI: state => state.ok || true, // todo : mettre la bonne valeur
    payload: {}
});

export const requestGetUsers = _users => ({
    types: [
        types.GET_USERS_REQUEST,
        types.GET_USERS_SUCCESS,
        types.GET_USERS_FAILURE
    ],
    callAPI: () => $requestGetUsers(_users),
    payload: { _users },
    schemas: [userSchema]
});

export const requestUpdateContactStatus = (_user, contactStatus) => ({
    types: [
        types.PATCH_CONTACT_STATUS_REQUEST,
        types.PATCH_CONTACT_STATUS_SUCCESS,
        types.PATCH_CONTACT_STATUS_FAILURE
    ],
    callAPI: () => $patchContact(_user, { status: contactStatus }),
    payload: {
        _user,
        contactStatus
    },
    params: {
        _user,
        contactStatus
    }
});

export const requestPostContact = _user => ({
    types: [
        types.POST_CONTACT_REQUEST,
        types.POST_CONTACT_SUCCESS,
        types.POST_CONTACT_FAILURE
    ],
    callAPI: () => $postContact(_user),
    payload: {
        _user
    },
    params: {
        _user
    }
});

export const requestGetContact = _user => ({
    types: [
        types.GET_CONTACT_REQUEST,
        types.GET_CONTACT_SUCCESS,
        types.GET_CONTACT_FAILURE
    ],
    callAPI: () => $getContact(_user),
    payload: {
        _user
    },
    params: {
        _user
    },
    schemas: userSchema
});

export const requestDeleteContact = _user => ({
    types: [
        types.DELETE_CONTACT_REQUEST,
        types.DELETE_CONTACT_SUCCESS,
        types.DELETE_CONTACT_FAILURE
    ],
    callAPI: () => $deleteContact(_user),
    payload: {
        _user
    },
    params: {
        _user
    }
});

export const searchUsers = (query, contactStatus, isInitiator, page, limit) => ({
    types: [
        types.SEARCH_USERS_REQUEST,
        types.SEARCH_USERS_SUCCESS,
        types.SEARCH_USERS_FAILURE
    ],
    resolver: state => ({
        company: getCompanySelector(state)
    }),
    callAPI: ({ company: { isPublic } = {} }) =>
        (!isPublic
        || !!query
        || (contactStatus !== null && contactStatus !== CONTACT_STATUS.NOT_CONNECTED)
            ? $searchUsers(query, contactStatus, isInitiator, page, limit)
            : Promise.resolve()),
    forceCallApi: false,
    shouldCallAPI: state => state.ok || true, // todo : mettre la bonne valeur
    payload: {
        query,
        contactStatus,
        isInitiator,
        page,
        limit
    },
    params: {
        query,
        contactStatus,
        isInitiator,
        page,
        limit
    }
});

export const clearSearchUser = storeKey => ({
    type: types.SEARCH_USERS_CLEAR,
    storeKey
});

export const saveUserIds = (context, ids, isFlush) => ({
    type: types.SAVE_USER_IDS,
    context,
    ids,
    isFlush
});

export const decreaseUserCount = objectName => ({
    type: types.DECREASE_USER_COUNT,
    objectName
});

// deprecated
export const requestAddRelations = emails => ({
    type: types.REQUEST_ADD_RELATIONS,
    emails
});
