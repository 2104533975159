import React from 'react';
import Health from 'components/Shared/Debug/Health/container';

const HealthPage = () => (
    <Health />
);

HealthPage.propTypes = {
};

HealthPage.defaultProps = {
};

export default HealthPage;
