import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Header from 'components/Feed/Header/container';
import Content from 'components/Feed/container';
import Acknowledgement from 'components/Feed/Acknowledgement/container';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import ErrorFormatter from 'components/Shared/Errors/Formatter';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import ContentErrorComponent from 'components/Shared/Errors/ContentError';

const NotFoundErrorPage = () => (
    <ErrorFormatter
        id="error.notFound"
        code={404}
        buttonType={BUTTON_TYPE.GOHOME}
        render={ContentErrorComponent}
    />
);

const Feed = (props) => {
    const {
        _feed,
        setSelectedFeed,
        isFeedsLoaded,
        hasFeed
    } = props;

    useWhyDidYouUpdate('screens:feed', props);

    useEffect(() => {
        setSelectedFeed(true);
        return () => setSelectedFeed(false);
    }, [_feed]);

    if (isFeedsLoaded && !hasFeed) {
        return <NotFoundErrorPage />;
    }

    return (
        <>
            <Acknowledgement _feed={_feed} />
            <Header _feed={_feed} />
            <Content />
        </>
    );
}

Feed.propTypes = {
    _feed: PropTypes.string.isRequired,
};

export default (Feed);
