import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { constants } from '@amplement/backend-connector';
import classNames from 'classnames';

import ThumbError from 'components/Room/Participant/ThumbError';
import ThumbLeave from 'components/Room/Participant/ThumbLeave';
import ThumbLoading from 'components/Room/Participant/ThumbLoading';
import ThumbUser from 'components/Room/Participant/ThumbUser/container';
import ThumbHold from 'components/Room/Participant/ThumbHold';
import { RECORDER_API_STATUS } from 'components/Shared/Recorder/constants';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import { useDebug } from 'contexts/sessionContext';
import StyleParticipant from './styles';

const debugStyleOverride = {
    overflow: 'auto'
};

const Participant = (props) => {
    const {
        intl,
        name,
        _user,
        _client,
        isLoading,
        isError,
        onToggleFocus,
        onKick,
        isAway,
        isAdmin,
        hasFocus,
        status,
        width,
        height,
        _room,
        avatar,
        isGalleryMode,
        recordingState,
    } = props;
    const [isDebug]= useDebug();

    const handleFocus = useCallback(() => {
        if (onToggleFocus) onToggleFocus(_client);
    }, [onToggleFocus, _client]);

    const handleKick = useCallback((e) => {
        e.preventDefault();
        if (onKick) onKick(_room, _user);
        return false;
    }, [onKick, _user, _room]);

    useWhyDidYouUpdate('debug:component:room:roomscreen:Participant', props);

    let content = null;
    const isHold = status === constants.rooms.USER_IN_ROOM_STATUS.HOLD;

    if (isError) {
        content = <ThumbError intl={intl} name={name} />;
    } else if (!isAway && isLoading) {
        content = (
            <ThumbLoading
                name={name}
            />
        );
    } else if (status === constants.rooms.USER_IN_ROOM_STATUS.LEFT
        || status === constants.rooms.USER_IN_ROOM_STATUS.KICKED) {
        content = (
            <ThumbLeave
                name={name}
                intl={intl}
            />
        );
    } else if (status === constants.rooms.USER_IN_ROOM_STATUS.HOLD) {
        content = (
            <ThumbHold
                name={name}
                intl={intl}
            />
        );
    } else {
        content = (
            <ThumbUser
                recordingState={recordingState}
                name={name}
                isAway={isAway}
                isAdmin={isAdmin}
                isHold={isHold}
                intl={intl}
                onKick={onKick && handleKick}
                _client={_client}
                _room={_room}
                avatar={avatar}
                isGalleryMode={isGalleryMode}
            />
        );
    }

    return (
        <StyleParticipant
            height={isDebug ? height * 1.5 : height}
            width={isDebug ? width * 2 : width}
            onClick={hasFocus ? undefined : handleFocus}
            key={_client}
            className={classNames('participant', {
                active: hasFocus,
                error: isError,
                loading: isLoading,
                offline: isAway,
                hold: isHold
            })}
            style={isDebug ? debugStyleOverride : undefined}
        >
            {content}
        </StyleParticipant>
    );
};

Participant.propTypes = {
    width: PropTypes.number,
    recordingState: PropTypes.oneOf(Object.values(RECORDER_API_STATUS)),
    height: PropTypes.number,
    _client: PropTypes.string,
    _room: PropTypes.string.isRequired,
    _user: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf(Object.values(constants.rooms.USER_IN_ROOM_STATUS)),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    onToggleFocus: PropTypes.func,
    onKick: PropTypes.func,
    intl: PropTypes.any.isRequired,
    isAway: PropTypes.bool,
    isAdmin: PropTypes.bool,
    hasFocus: PropTypes.bool,
    isGalleryMode: PropTypes.bool
};

Participant.defaultProps = {
    recordingState: RECORDER_API_STATUS.STOPPED,
    width: 190,
    height: 110,
    _client: '',
    _user: '',
    name: '',
    status: null,
    isLoading: false,
    isError: false,
    isAway: false,
    isAdmin: false,
    hasFocus: false,
    isGalleryMode: false,
    onKick: null,
    onToggleFocus: () => {}
};

export default memo(injectIntl(Participant));
