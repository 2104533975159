import React, { memo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import Icon from 'components/Shared/Common/Icon';

import { Input } from 'antd';
import { injectIntl } from 'react-intl';

const Style = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.groupSearch};
    border-radius: 4px;
    padding: 5px 10px;
    align-items: center;
    margin-bottom: 20px;
    transition: all 250ms ease;
    border: 1px solid ${({ theme }) => theme.groupSearch};
    &:focus-within{
        border-color: ${props => props.theme.mainColor2};
        box-shadow: 0 0 6px ${props => rgba(props.theme.mainColor2, 0.6)};
        label{
            svg{
                color: ${({ theme }) => theme.black};
            }
        }
        .ant-input-prefix{
            .icon{
                color: ${({ theme }) => theme.black};
            }
        }
    }
    .ant-input-affix-wrapper{
        border: none!important;
        background-color: transparent;
        &-focused{
            box-shadow: none;
        }
    }
    .ant-input-prefix{
        display: flex;
        align-items: center;
        margin-right: 10px;
        .icon{
            display: flex;
            align-items: center;
            color: ${({ theme }) => rgba(theme.black, 0.6)};
            svg{
                width: 14px;
                height: 14px;
            }
        }
    }
    input{
        font-size: 14px;
        color: ${({ theme }) => theme.black}!important;
        background-color: transparent!important;
        border: none;
        display: flex;
        flex: 1;
        min-width: 0;
        min-height: 0;
        width: 100%;
        transition: all 250ms ease;
        &:focus{
            box-shadow: none;
        }
        &::placeholder{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
        }
    }
`;

const PhoneKeyboardViewer = injectIntl(memo(({ onSubmit, isDisabled, autoFocus, intl, ...props }) => {
    const inputEl = useRef();

    useEffect(() => {
        if (autoFocus) inputEl.current.input.focus();
        return () => { };
    }, []);

    const handleSubmit = useCallback((e) => onSubmit(e.target.value), [onSubmit]);

    return (
        <Style>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Input
                ref={inputEl}
                prefix={<Icon iconName="Pad" />}
                placeholder={intl.formatMessage({ id: 'drawer.phone.input.placeholder' })}
                onPressEnter={handleSubmit}
                disabled={isDisabled}
                {...props}
            />
        </Style>
    )
}));

PhoneKeyboardViewer.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    isDisabled: PropTypes.bool,
    autoFocus: PropTypes.bool
};

PhoneKeyboardViewer.defaultProps = {
    isDisabled: false,
    autoFocus: true
};

export default memo(injectIntl(PhoneKeyboardViewer));
