import  { initializeApp } from 'firebase/app';
import { 
    getAnalytics, 
    setUserId, 
    setCurrentScreen, 
    setUserProperties, 
    logEvent,
    isSupported
} from "firebase/analytics";
import { utils } from '@amplement/backend-connector';
import storage from 'services/storage/localStorage';

import config from 'config';

import Logger from 'services/debug/logger';

const logger = new Logger('utils:analytics');

const getAnalyticsIfSupported = () => new Promise((resolve, reject) => {
    isSupported().then((isAvailable) => {
        if (isAvailable) {
            resolve();
        } else {
            reject();
        }
    });
});

class Analytics {

    #app = () => {};

    #analytics = () => {};

    isAnalyticsSupported;
    
    prevScreen;

    constructor() {
        try {
            this.#app = initializeApp(config.firebaseConfig);
            getAnalyticsIfSupported().then(() => {
                this.isAnalyticsSupported = true;
                this.#analytics = getAnalytics(this.#app);
                // should be automatic, but for web, it's not ! hack the version, need to take some time to do that
                this.setUserProperty('app_version', process.env.PKG_VERSION);
                this.setUserProperty('bundleId', storage.get('getBundleId') || 'web');
            }).catch(() => {
                this.isAnalyticsSupported = false;
            });
        } catch (e) {
            // 😰
            utils.errorHandler.captureException(e, 'analytics:constructor');
        }
    }

    setUserId =  userId => {
        logger.log('setUserId', userId);
        try {
            this.#analytics = getAnalytics(this.#app);
            return setUserId(this.#analytics, userId);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:setUserId');
            return null;
        }
    }

    setUserProperty = (tag, value) => {
        logger.log('setUserProperty', tag, value);
        if (!tag || !value) return null;
        return this.setUserProperties({[tag]: value});
    }

    setUserProperties = (properties) => {
        logger.log('setUserProperties', {...properties});
        if (!properties || !this.isAnalyticsSupported) return null;
        try {
            this.#analytics = getAnalytics(this.#app);
            return setUserProperties(this.#analytics, properties);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:setUserProperties');
            return null;
        }
    };

    setCurrentScreen = screen => {
        if (!screen || this.prevScreen === screen || !this.isAnalyticsSupported) return null;
        logger.log('setCurrentScreen', screen)
        this.prevScreen = screen;
        try {
            this.#analytics = getAnalytics(this.#app);
            return setCurrentScreen(this.#analytics, screen);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:setCurrentScreen');
            return null;
        }
    }

    logEvent = (tag, body = {}) => {
        const data = { env: config.env, ...body };
        logger.log('logEvent', tag, {...data});
        if (!this.isAnalyticsSupported) return null;
        try {
            this.#analytics = getAnalytics(this.#app);
            return logEvent(this.#analytics, tag, data);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:logEvent');
            return null;
        }
    };

}

export default new Analytics();
