import React from 'react';

export default props => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            id="pause-off"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M1,1 L23,23"
                id="Path"
                stroke="currentColor"
                strokeWidth="2"
            />
            <g
                id="pause"
                transform="translate(6.000000, 4.000000)"
                stroke="currentColor"
                strokeWidth="2"
            >
                <path
                    d="M0,2 L4,6 L4,16 L0,16 L0,2 Z M4,-1.77635684e-14 L4,2 L4,-1.77635684e-14 Z M2,-1.13964393e-13 L4,-1.13631327e-13 L2,-1.13964393e-13 Z"
                    id="Combined-Shape"
                />
                <path
                    d="M12,1.77635684e-15 L12,8 L12,1.77635684e-15 Z M8,1.77635684e-15 L8,4 L8,1.77635684e-15 Z M8.05,-1.12021503e-13 L11.75,-1.11799459e-13 L8.05,-1.12021503e-13 Z M12,16 L8,16 L8,10 L12,14 L12,16 Z"
                    id="Combined-Shape"
                />
            </g>
        </g>
    </svg>

);
