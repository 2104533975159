import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import FilesItem from 'components/Dashboard/LastFiles/FilesItem';
import DashboardBlock from 'components/Dashboard/DashboardBlock';
import Placeholder from 'components/Shared/Common/Placeholder';

import UserName from 'components/Shared/User/UserName';
import { Typography, Col, Row, Space } from 'antd';
import { getUrl } from 'utils/url';
import Avatar from 'components/Shared/User/Avatar';
import styled from 'styled-components';

const { Text } = Typography;
const filesUrl = getUrl('files');

export const StyledHightedFileItem = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
`;

const gutter = [8, 8];
const fileNameStyle = { fontSize: '12px' };
const HightedFileItem = ({ feedItem: { user: { firstname, lastname, avatarUri }, metasFile: { name: fileName } }}) => (
    <StyledHightedFileItem>
        <Col span={24}>
            <Text 
                type="secondary" 
                italic 
                ellipsis
                style={fileNameStyle}
            >
                {fileName}
            </Text>
        </Col>
        {(lastname || firstname) && (
            <Col span={24}>
                <Space>
                    <Avatar
                        src={avatarUri}
                        size="xs"
                        hasStatus={false}
                        alt={`${firstname} ${lastname}`}
                    />
                    <UserName
                        lastname={lastname}
                        firstname={firstname}
                    />
                </Space>
            </Col>
        )}
    </StyledHightedFileItem>
);

const fileListStyle = { position:'relative', marginBottom: '20px' };
const FileList = ({ feedItems }) => {
    const [hilightedFeedItem, setHilightedFeedItem] = useState();

    return (
        <div className="content-image" style={fileListStyle}>
            <Row className="files-container" gutter={gutter}>
                {!!feedItems.length && feedItems.map((feedItem) => (
                    <FilesItem
                        key={feedItem?.id}
                        feedItem={feedItem}
                        user={feedItem?.user}
                        setHighlight={setHilightedFeedItem}
                    />
                ))}
                {!!hilightedFeedItem && <HightedFileItem feedItem={hilightedFeedItem} />}
            </Row>
        </div>
    );
}

const LastFiles = ({ intl, feedItems, isLoading }) => (
    <DashboardBlock
        iconName="Paperclip"
        quickAction={filesUrl}
        title={intl.formatMessage({ id: 'dashboard.lastFiles.title' })}
        content={(
            <FileList feedItems={feedItems} />
        )}
        isLoading={isLoading}
        itemsLength={feedItems.length}
        placeholder={(
            <Placeholder
                title={intl.formatMessage({
                    id: 'dashboard.lastFiles.placeholder.title',
                    defaultMessage: 'Les derniers documents partagés sont listés ici'
                })}
                subTitle={intl.formatMessage({
                    id: 'dashboard.lastFiles.placeholder.subtitle',
                    defaultMessage: 'Vous n\'avez pas encore de documents'
                })}
                name="placeholderFiles"
            />
        )}
    />
)

LastFiles.propTypes = {
    intl: PropTypes.any.isRequired,
    feedItems: PropTypes.array,
    isLoading: PropTypes.bool
}

LastFiles.defaultProps = {
    feedItems: [],
    isLoading: false
}

export default injectIntl(memo(LastFiles));
