import * as previewTypes from 'types/preview';

const initialState = {
    _feedItem: null
};

export default (state = initialState, { type, _feedItem }) => {
    switch (type) {
        case previewTypes.OPEN_PREVIEW:
            return {
                _feedItem
            };

        case previewTypes.CLOSE_PREVIEW:
            return initialState;

        default:
            return state;
    }
};
