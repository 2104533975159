import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedDate, FormattedMessage } from 'react-intl';

import Avatar from 'components/Shared/User/Avatar';
import Button from 'components/Shared/Common/Button';
import UserName from 'components/Shared/User/UserName';
import { ESCAPE } from 'const/eventKey';
import { Tooltip } from 'antd';
import { StyleDialog } from './style';

export default class Preview extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        avatar: PropTypes.string,
        file: PropTypes.string.isRequired,
        downloadUrl: PropTypes.string.isRequired,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        content: PropTypes.func.isRequired,
        createdAt: PropTypes.string.isRequired,
        name: PropTypes.string,
    };

    static defaultProps = {
        avatar: undefined,
        name: 'file.js',
        firstname: null,
        lastname: null
    };

    mouseTimer = null;

    visibilityTimer = null;

    state = {
        isFullRender: false,
        isMouseOnHeader: false,
        isMouseMove: false,
        isHeaderVisible: true
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        if (this.visibilityTimer) {
            clearTimeout(this.visibilityTimer);
        }
        this.visibilityTimer = setTimeout(() => {
            this.setState(state => ({
                ...state,
                isHeaderVisible: false
            }));
        }, 4000);
    }

    componentDidUpdate(_, prevState) {
        const { isMouseOnHeader, isMouseMove } = this.state;
        if ((prevState.isMouseOnHeader !== isMouseOnHeader)
            || (prevState.isMouseMove !== isMouseMove)) {
            if (this.visibilityTimer) {
                clearTimeout(this.visibilityTimer);
            }
            this.setState(state => ({ // eslint-disable-line
                ...state,
                isHeaderVisible: true
            }));
            this.visibilityTimer = setTimeout(() => {
                if (!isMouseOnHeader || isMouseMove) {
                    this.setState(state => ({
                        ...state,
                        isHeaderVisible: false
                    }));
                }
            }, 4000);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        if (e.key === ESCAPE || e.keyCode === ESCAPE) {
            this.props.onClose();
        }
    }

    handleDownLoad = (event) => {
        const { name, downloadUrl } = this.props;
        event.preventDefault();
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = name;
        a.href = downloadUrl;
        a.click();
        a.remove();
        return false;
    };

    // handleFullRender = () => {
    //     const { width, height } = this.props;
    //     if (window.innerWidth <= width || window.innerHeight <= height) {
    //         this.setState(prevState => ({
    //             ...prevState,
    //             isFullRender: !prevState.isFullRender
    //         }));
    //     } else {
    //         this.setState(prevState => ({
    //             ...prevState,
    //             isFullRender: false
    //         }));
    //     }
    // };

    handleMouseEnter = () => this.setState(prevState => ({
        ...prevState,
        isMouseOnHeader: true
    }));

    handleMouseLeave = () => this.setState(prevState => ({
        ...prevState,
        isMouseOnHeader: false
    }));

    handleMouseMove = () => {
        if (this.mouseTimer) {
            clearTimeout(this.mouseTimer);
        }
        this.setState(prevState => ({
            ...prevState,
            isMouseMove: true
        }));
        this.mouseTimer = setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                isMouseMove: false
            }));
        });
    };

    render() {
        const {
            onClose,
            avatar,
            lastname,
            firstname,
            content,
            name,
            createdAt,
            file
        } = this.props;

        return (
            <StyleDialog
                isOpen
                onMouseMove={this.handleMouseMove}
            >
                <div className="overlay" />
                <div className="dialog-wrapper">
                    <div
                        className={classNames({
                            superDialogHeader: true,
                            hide: !this.state.isHeaderVisible
                        })}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <div className="dialog-header">
                            <div className="metas">
                                <Avatar
                                    src={avatar}
                                    size="md"
                                    status={0} // plug after
                                    alt={`${lastname} - ${firstname}`}
                                />
                                <div className="text">
                                    {lastname && firstname && (
                                        <div className="user">
                                            <UserName
                                                lastname={lastname}
                                                firstname={firstname}
                                            />
                                        </div>
                                    )}
                                    <div className="meta">
                                        <FormattedDate
                                            value={createdAt}
                                            year="numeric"
                                            month="long"
                                            day="2-digit"
                                        /> - {name}
                                    </div>
                                </div>
                            </div>
                            <div className="actions">
                                <Tooltip
                                    placement="bottom"
                                    title={
                                        <FormattedMessage
                                            id="global.button.download"
                                            defaultMessage="Télécharger"
                                        />
                                    }>
                                    <Button
                                        onClick={this.handleDownLoad}
                                        isTransparent
                                        size="md"
                                        color="secondary"
                                        iconName="Download"
                                    />
                                </Tooltip>

                                <span className="separator" />
                                <Tooltip
                                    placement="bottom"
                                    title={
                                        <FormattedMessage
                                            id="global.button.close"
                                            defaultMessage="Fermer"
                                        />
                                    }>
                                    <Button
                                        onClick={onClose}
                                        isTransparent
                                        size="md"
                                        color="secondary"
                                        iconName="X"
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-content">
                        <div className="fileviewer-content">
                            <div className="fileviewer">
                                {content(file, name)}
                            </div>
                        </div>
                    </div>
                    {/* <div
                        className={classNames({
                            superDialogContent: true,
                            'render-full': this.state.isFullRender
                        })}
                        onClick={this.handleFullRender}
                        onKeyPress={() => null}
                        role="button"
                        tabIndex={0}
                    >
                        {content(file, name)}
                    </div> */}
                </div>
            </StyleDialog>
        );
    }
}
