import React, { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import MainPlayer from 'components/Room/MainPlayer';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { injectIntl } from 'react-intl';
import useRoomSelector from 'hooks/useRoomSelector';
import { selectors } from '@amplement/backend-connector';
import SimplePhone from 'components/Shared/Call/SimplePhone';
import RoomCallUserInfos, { PlayerAvatar } from 'components/Room/MainParticipant/RoomCallUserInfos';
import { ContentLayout } from 'components/Room/styles';
import MainText from './MainText';

const RoomCallInProgress = injectIntl(memo(({ intl, _room }) => {
    const { isPersistent } = useRoomSelector(selectors.rooms.getRoomByIdSelector, _room) || {};
    const members = useRoomSelector(selectors.rooms.getWaitingRoomMembersByRoomIdSelector, _room) || [];

    return (
        <MainText 
            text={intl.formatMessage({
                id: isPersistent || members.filter(x => !x.isMe)?.length === 0 ? 'room.mainScreen.waitingMembers' :'room.mainScreen.calling'
            })}
        />
    );
}));

const MainParticipant = (props) => {
    const {
        avatar,
        _client,
        fullname,
        phonenumber,
        title,
        videoStream,
        updatedAt,
        isVideoOn,
        hasDtmf,
        onDtmfKeyPress,
        _room,
        className,
        onToggleInterface,
        audioTrack,
        isAudioOn,
        isMyUser
    } = props;

    useWhyDidYouUpdate('component:room:roomscreen:MainParticipant', props);

    const [number, setNumber] = useState('');
    const handleAddDigit = useCallback((digit) => {
        onDtmfKeyPress(digit);
        setNumber(`${number}${digit}`);
    }, [number, onDtmfKeyPress, setNumber]);

    const avatarProps = useMemo(() => ({
        src: avatar,
        firstname: fullname,
        size: 'xxxxl'
    }), [avatar, fullname]);

    const playerAvatar = useMemo(() => 
        <PlayerAvatar              
            track={audioTrack} 
            updatedAt={updatedAt} 
            isMe={isMyUser}
            isTrackMuted={!isAudioOn} 
            _client={_client}
            avatar={avatarProps}
        />
    ,[avatarProps, updatedAt, isMyUser, isAudioOn, _client, audioTrack]);

    let content = (
        <MainPlayer
            className="video"
            _client={_client}
            isMyUser={isMyUser}
            avatar={avatar}
            videoStream={videoStream}
            updatedAt={updatedAt}
            isVideoOn={isVideoOn}
            id={`Main-${_client}`}
            renderDefault={playerAvatar}
            forceMute
            isAudioOn={false}
        />
    );

    if (!_client) {
        content = <RoomCallInProgress _room={_room} />;
    }

    else if (!isVideoOn || !videoStream) {
        content = (
            <RoomCallUserInfos
                avatar={avatarProps}
                track={audioTrack} 
                updatedAt={updatedAt} 
                isMe={isMyUser}
                isTrackMuted={!isAudioOn} 
                _client={_client}    
                title={fullname || phonenumber}
                subTitle={title || (fullname ? phonenumber : undefined)}
            >
                {hasDtmf && (
                    <SimplePhone
                        _room={_room}
                        number={number}
                        onKeyPress={handleAddDigit}
                        hasCallButton={false}
                        className="call"
                    />
                )}
            </RoomCallUserInfos>
        );
    }

    return (
        <ContentLayout onClick={onToggleInterface} className={className}>
            {content}
        </ContentLayout>
    );
};

MainParticipant.propTypes = {
    avatar: PropTypes.any,
    _room: PropTypes.string,
    _client: PropTypes.string,
    videoStream: PropTypes.object,
    hasDtmf: PropTypes.bool,
    updatedAt: PropTypes.oneOfType([
        PropTypes.number, // Date.now();
        PropTypes.string
    ]),
    isVideoOn: PropTypes.bool,
    isAudioOn: PropTypes.bool,
    audioTrack: PropTypes.object,
    isMyUser: PropTypes.bool,
    onToggleInterface: PropTypes.func,
    className: PropTypes.string,
    fullname: PropTypes.string,
    phonenumber: PropTypes.string,
};

MainParticipant.defaultProps = {
    className: '',
    onToggleInterface: () => {},
    avatar: undefined,
    _client: '',
    _room: '',
    hasDtmf: false,
    isMyUser: undefined,
    videoStream: undefined,
    updatedAt: undefined,
    isVideoOn: undefined,
    audioTrack: undefined,
    isAudioOn: undefined,
    fullname: undefined,
    phonenumber: undefined,
};

export default MainParticipant;
