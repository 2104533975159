import { isCallUrl } from "./url";

export function colorLuminance(hex, lum) {

    let hexCustom = String(hex).replace(/[^0-9a-f]/gi, '');
    const lumCustom = lum || 0;
    let rgb = '#';
    let c;
    let i;

    if (hexCustom.length < 6) {

        hexCustom = `${hexCustom[0]}${hexCustom[0]}${hexCustom[1]}${hexCustom[1]}${hexCustom[2]}${hexCustom[2]}}`;

    }

    for (i = 0; i < 3; i += 1) {

        c = parseInt(hexCustom.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lumCustom)), 255)).toString(16);
        rgb += (`00${c}`).substr(c.length);

    }
    return rgb;

}

export const isDarkTheme = (theme) => (theme?.name === 'dark') || isCallUrl();
