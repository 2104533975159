import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Avatar from 'components/Shared/User/Avatar';
import { getUrl } from 'utils/url';

const formatMessages = intl => () => ({
    loading: intl.formatMessage({
        id: 'avatarList.loading',
        defaultMessage: 'Chargement ...'
    }),
    missingUser: intl.formatMessage({
        id: 'avatarList.missingUser',
        defaultMessage: 'Ce contact n\'existe plus.'
    })
});

const AvatarList = ({ users, isLoading, intl, hasStatus }) => {
    const i18n = formatMessages(intl)();

    if (isLoading) {
        return i18n.loading;
    }

    const renderAvatar = user => (
        <div
            key={user.id}
            className="list-users-avatar-item"
        >
            <Avatar
                src={user.avatarUri || user.avatar}
                size="lg"
                alt={`${user.firstname} ${user.lastname}`}
                isEditable={false}
                href={getUrl('profile', { _user: user.id })}
                hasStatus={hasStatus}
            />
            <div className="tooltip">{`${user.firstname} ${user.lastname}`}</div>
        </div>
    );

    return (
        <div className="list-users-avatar">
            {
                !!users.length
                    ? users.map(renderAvatar)
                    : i18n.missingUser // note : this is a safeguard
            }
        </div>
    );
};

AvatarList.propTypes = {
    users: PropTypes.array.isRequired,
    hasStatus: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired
};

AvatarList.defaultProps = {
    hasStatus: true
}

export default injectIntl(AvatarList);
