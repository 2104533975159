import { connect } from 'react-redux';
import { actions, selectors, apis } from '@amplement/backend-connector';

import Component from './index';

const mapStateToProps = (state, ownProps) => ({
    ...selectors.voiceMails.getVoiceMailByIdSelector(state, ownProps._voiceMail)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRemove: () => dispatch(actions.voiceMails.requestDeleteVoiceMail(ownProps._voiceMail)),
    getMediaByVoiceMailId: apis.voiceMails.$getVoiceMailMedia,
    onRead: () => dispatch(actions.voiceMails.requestPatchVoiceMail(ownProps._voiceMail, { "readState": "read" }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
