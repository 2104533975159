import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { SIP_ACCOUNT_STATUS } from 'const/call';

import SimplePhone from 'components/Shared/Call/SimplePhone';
import SipStatus from 'components/Shared/Call/SipStatus';
import SipData from 'components/Shared/Call/SipData';

import Analytics from 'utils/analytics';
import usePhonenumber from 'hooks/usePhonenumber';

const PhoneKeyboard = ({
    prefix,
    status,
    sipData,
    sound,
}) => {
    const { makeCall } = usePhonenumber({ prefix: false });

    const handleDigitClick = useCallback((digit) => {
        const asterisk = digit === '*' ? 'ASTERISK' : null;
        const pound = digit === '#' ? 'POUND' : null;
        sound(asterisk || pound || digit);
    }, [sound]);

    Analytics.setCurrentScreen('SIP_Keyboard');
    return (
        <>
            <SipStatus status={status} />
            <SipData sipData={sipData} />

            <SimplePhone
                onKeyPress={handleDigitClick}
                onCall={makeCall}
                defaultNumber=""
                className="phone"
                prefix={prefix}
                isDisabled={status !== SIP_ACCOUNT_STATUS.REGISTERED}
                hasCallButton
            />
        </>
    )
}

PhoneKeyboard.propTypes = {
    sound: PropTypes.func.isRequired,
    prefix: PropTypes.string,
};

PhoneKeyboard.defaultProps = {
    prefix: undefined
};

export default  memo(PhoneKeyboard);
