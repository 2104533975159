import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Shared/Common/Link';

import { getUrl, createUrl } from 'utils/url';
import Image from 'components/Shared/Common/Image';
import { DEFAULT_LOGO } from 'const/company';
import { ThemeContext } from 'styled-components';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

const Logo = (props) => {
    const { name, size, href, type, target } = props;
    const skin = useContext(ThemeContext);
    let logoSrc = skin[type] || skin.brandLogo;
    useWhyDidYouUpdate('components:logoSrc', props);

    // size are not used in backoffice, but just in case...
    if (size !== 'lg' && logoSrc && logoSrc.indexOf('w=') === -1) {
        logoSrc = createUrl(logoSrc, `w=${35}`);
    }

    if (!logoSrc) {
        return null;
    }

    const image = <Image alt={name} src={logoSrc} fallbackSrc={DEFAULT_LOGO} />;

    if (!href) {
        return image;
    }

    return (
        <Link
            className={`logo ${size}`}
            href={getUrl('dashboard')}
            target={target}
        >
            {image}
        </Link>
    );
};
Logo.propTypes = {
    type: PropTypes.oneOf([ 'shortLogo', 'brandLogo' ]),
    target: PropTypes.string,
    href: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'md', 'lg'])
};

Logo.defaultProps = {
    target: undefined,
    href: undefined,
    size: 'md',
    type: undefined,
    name: 'logo'
};

export default Logo;
