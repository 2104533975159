import { apiClient as request } from '@amplement/backend-connector';
import config from 'config';

export const $getCompanyWhois = (mode) => { 
    let referer = window.location.host;
    
    if (config.isLocalEnv && config['X-referer']) {
        try {
            const url = new URL(config['X-referer']);
            referer = url.host;
        }
        catch {
            referer = config['X-referer'];
        }
    }
    
    return request.getAxiosProvider()({
        method: 'get',
        url: `/companies/whois`,
        params: {
            referer,
            mode
        }
    });
}
export const $getCompanyUsers = (_company, page = 1, limit = 50) =>
    request.apiClient({
        method: 'get',
        url: `/companies/${_company}/users?page=${page}&limit=${limit}`,
    });

export const $getCompanyPlugins = (_company) => 
    request.apiClient({
        method: 'get',
        url: `/companies/${_company}/plugins`,
    });
