import { connect } from 'react-redux';

import { getCurrentUserIdSelector, getCurrentUserRolesSelector } from 'selectors/user';
import { getCompanySelector } from 'selectors/company';
import { getIsAuthenticated } from 'selectors/session';

import Screens from './index';

const mapStateToProps = state => {
    const company = getCompanySelector(state);
    const _user = getCurrentUserIdSelector(state);
    const plugins = company ? company.plugins : undefined;
    const tabs = plugins ? plugins.tabs : undefined;
    const roles = getCurrentUserRolesSelector(state);

    return {
        _currentUser: _user,
        isAuthenticated: getIsAuthenticated(state),
        tabs,
        roles
    };
};

export default connect(
    mapStateToProps,
    null
)(Screens);
