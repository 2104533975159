import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ItemLoading from './ItemLoading';

const renderItem = (item, index) => <ItemLoading key={index} />;

const Placeholder = ({ repeat }) => Array(repeat).fill(0).map(renderItem);

Placeholder.propTypes = {
    repeat: PropTypes.number.isRequired
};

export default memo(Placeholder);
