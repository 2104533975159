import {
    put,
    all,
    call,
} from 'redux-saga/effects';

import * as userTypes from 'types/user';
import * as sessionTypes from 'types/session';
import { $postRelationsBulk } from 'api/user';

import { addNotification } from 'actions/notification';
import { $patchUserEmail, $patchUserPassword } from 'api/profile';
import { utils } from '@amplement/backend-connector';
import { removeRequestedAction } from 'actions/session';

function* addRelations({ emails }) {
    try {
        if (!emails || !emails.length) return;

        const response = yield call($postRelationsBulk, { emails });

        if (response) {
            yield put(addNotification({
                severity: 'success',
                title: 'contact.upload.relations',
                values: {
                    emailNumber: emails.length
                }
            }));
        }
    } catch (e) {
        const errorTitle = e.id || 'error.default';
        yield call(utils.errorHandler.captureException, e, 'saga:form:addRelations');
        yield put(addNotification({
            title: errorTitle,
            referenceError: 'REL1'
        }));
    }
}

function* patchUserEmail({ email, password }) {
    try {
        const response = yield call($patchUserEmail, email, password);

        if (response) {
            yield put(removeRequestedAction('emailReset'));
            yield put(addNotification({
                severity: 'success',
                title: 'settings.resetEmail.notification.success'
            }));
        }
    } catch (e) {
        const errorTitle = e.response ? e.response?.data?.message : 'error.default';
        yield call(utils.errorHandler.captureException, e, 'saga:form:patchUserEmail');
        yield put(addNotification({
            title: errorTitle,
            referenceError: 'USR1'
        }));
    }
}

function* patchUserPassword({ currentPassword, newPassword }) {
    try {
        const response = yield call($patchUserPassword, currentPassword, newPassword);

        if (response) {
            yield put(addNotification({
                severity: 'success',
                title: 'settings.resetPassword.notification.success'
            }));
        }
    } catch (e) {
        const errorTitle = e.response ? e.response.data : 'error.default';
        yield call(utils.errorHandler.captureException, e, 'saga:form:patchUserPassword');
        yield put(addNotification({
            title: errorTitle,
            referenceError: 'USR2'
        }));
    }
}

export default function* root() {
    yield all([
        utils.sagas.takeLatest(
            userTypes.REQUEST_ADD_RELATIONS,
            addRelations
        ),
        utils.sagas.takeLatest(
            sessionTypes.REQUEST_PATCH_USER_EMAIL,
            patchUserEmail
        ),
        utils.sagas.takeLatest(
            sessionTypes.REQUEST_PATCH_USER_PASSWORD,
            patchUserPassword
        )
    ]);
}
