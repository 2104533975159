import { fork, all } from 'redux-saga/effects';

import formSaga from 'sagas/form';
import initSaga from 'sagas/init';
import userSaga from 'sagas/user';
import roomSaga from 'sagas/room';
import { sagas } from '@amplement/backend-connector';
import sessionSaga from 'sagas/session';
import uploadSaga from 'sagas/upload';
import pushNotificationSaga from 'sagas/pushNotification';

export default function* rootSaga() {
    yield all([
        fork(sagas.feeds.default || sagas.feeds),
        fork(sagas.members.default || sagas.members),
        fork(sagas.feedItems.default || sagas.feedItems),
        fork(sagas.activities.default || sagas.activities),
        fork(formSaga),
        fork(initSaga),
        fork(userSaga),
        fork(roomSaga),
        fork(sagas.rooms.default || sagas.rooms),
        fork(sagas.webRTC.default || sagas.webRTC),
        fork(sagas.websocket.default || sagas.websocket),
        fork(sagas.network.default || sagas.network),
        fork(sagas.notifications.default || sagas.notifications),
        fork(sagas.voiceMails.default || sagas.voiceMails),
        fork(sagas.sip.default || sagas.sip),
        fork(sessionSaga),
        fork(uploadSaga),
        fork(pushNotificationSaga)
    ]);
}
