import styled from 'styled-components';
import { rgba, lighten } from 'polished';

export const NotificationStyle = styled.div`
    &:hover{
        background-color: ${({theme}) => rgba(theme.white, 0.01)};
    }
    &.line{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        border-bottom: 1px solid ${({theme}) => rgba(theme.black, 0.1)};
        position: relative;
        transition: all 250ms ease;
        &.missed{
            background-color: ${({theme}) => lighten(0.4, theme.error)};
            .line{
                &-text{
                    color: ${({theme}) => theme.error};
                    a{
                        color: ${({theme}) => theme.error};
                    }
                }
                &-icon{
                    .avatar{
                        .s-status{
                            border-color: ${({theme}) => lighten(0.4, theme.error)};
                        }
                    }
                }
            }
        }
        &:last-child{
            border-bottom: none;
        }
        .line{
            &-icon{
                transition: all 250ms ease;
                .avatar-group{
                    display: flex;
                    flex-direction: column;
                    width: 40px;
                }
                .icon{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: ${({theme}) => theme.warning};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 18px;
                        height: 18px;
                        color: ${({theme}) => theme.fixedWhite};
                    }
                }
            }
            &--item{
                display: flex;
                flex-direction: row;
                align-items: center;
                &.success{
                    .icon{
                        background-color: ${({theme}) => rgba(theme.success, 0.2)};
                        svg{
                            color: ${({theme}) => theme.success};
                        }
                    }
                    .label{
                        color: ${({theme}) => theme.success};
                    }
                }
                .icon{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: ${({theme}) => theme.secondaryColor};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    svg{
                        width: 14px;
                        height: 14px;
                        color: ${({theme}) => theme.black};
                    }
                }
                .label{
                    color: ${({theme}) => theme.black};
                    font-size: 14px;
                }
            }
            &-action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                transition: all 250ms ease;
                margin-left: 50px;
                .item{
                    margin-left: 10px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
            &-text{
                margin-left: 20px;
                font-size: 16px;
                color: ${({theme}) => theme.black};
                transition: all 250ms ease;
                word-break: break-word;
                flex: 1;
                a{
                    color: ${({theme}) => theme.black};
                }
                .label{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    span{
                        font-size: 14px;
                    }
                    .icon{
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                        svg{
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
                .timestamp{
                    display: block;
                    margin-top: 5px;
                    font-size: 12px;
                    color: ${({theme}) => rgba(theme.black, 0.4)};
                }
            }
            &-line{
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
    }
`;
