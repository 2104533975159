import { types as bcTypes } from '@amplement/backend-connector';
import * as types from 'types/session';
import * as userTypes from 'types/user';
import { getUserIdFromToken } from 'services/token';
import config from 'config';

const initialState = {
    authenticated: false,
    processing: false,
    token: null,
    user: {
        id: getUserIdFromToken()
    },
    _client: null,
    isMuted: false,
    isReady: false,
    hasTabFocus: false,
    lastWSEventAt: Date.now(),
    isWsConnectedOnce: false,
    activeLang: config.defaultLanguage, // applied language

    error: null,
    contentError: null,

    // BC
    accounts: [],
    pendingAvatar: null
};

const sessionReducer = (currentState = initialState, action) => {
    let state = currentState;

    if (action.type.startsWith('WS_')) {
        state = {
            ...state,
            lastWSEventAt: Date.now()
        };
    }

    switch (action.type) {

        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                contentError: null,
                error: null
            };

        case types.SET_CONTENT_ERROR:
            return {
                ...state,
                contentError: action.error
            };

        case bcTypes.websocket.WS_CONNECT:
            return {
                ...state,
                isWsConnectedOnce: true
            };

        case types.SET_FATAL_ERROR:
            return {
                ...state,
                error: action.error
            };

        case types.REMOVE_REQUESTED_ACTION:
            return {
                ...state,
                user: {
                    ...state.user,
                    requestedActions: state.user.requestActions?.filter(ra => ra.key !== action.payload)
                }
            };

        case types.SET_TAB_FOCUS:
            return {
                ...state,
                hasTabFocus: action.isFocused
            };

        case types.APP_READY:
            return {
                ...state,
                isReady: true
            };

        case bcTypes.session.REQUEST_LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: !!action.payload.token,
                processing: false,
                token: action.payload.token,
                user: action.payload.user,
                // bc
                accounts: undefined,
                company: action.payload.company || state.company,
            };

        case bcTypes.session.UPDATE_TOKEN:
            return {
                ...state,
                token: action.token,
                authenticated: !!action.token,
            };

        case bcTypes.session.REQUEST_LOGIN_FAILED:
            return {
                ...state,
                authenticated: false,
                processing: false,
                loginError: action.message
            };

        case types.UPDATE_USER:
            return {
                ...state,
                user: { ...state.user, [action.key]: action.value }
            };

        case types.SET_USER:
            return {
                ...state,
                user: action.user
            };

        case types.SET_LANG:
            return {
                ...state,
                activeLang: action.lang
            };

        case types.SET_CLIENT:
            return {
                ...state,
                _client: action._client
            };

        case types.WS_PUT_USER:
            if (action.payload.id === state.user.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        ...action.payload
                    }
                };
            }
            return state;

        case types.TOGGLE_SOUND_NOTIFICATION:
            return {
                ...state,
                isMuted: !state.isMuted
            };

        case userTypes.WS_PUT_STATUS:
            return {
                ...state,
                user: {
                    ...state.user,
                    status: action.payload.status
                }
            };

        case types.PATCH_USER_SUCCESS: {
            const { data: { entities, result } } = action;

            return {
                ...state,
                user: {
                    ...state.user,
                    ...entities.users[result]
                }
            };
        }

        case bcTypes.session.REQUEST_LOGIN:
            return {
                ...state,
                processing: true,
            };
        case bcTypes.session.REQUEST_LOGIN_CHOICE:
            return {
                ...state,
                accounts: action.payload,
            };
        case bcTypes.session.SET_PENDING_AVATAR:
            return {
                ...state,
                pendingAvatar: action.avatar,
            };
        default:
            return state;
    }
};

export default sessionReducer;
