import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';

import { SIP_ACCOUNT_STATUS } from 'const/call';
import UnsupportedBrowserComponent from 'components/LeftMenu/Rooms/UnsupportedBrowser';
import { isWebRtcCompatible } from 'utils/webRTC';

import { NOTIFICATION_CATEGORIES } from 'const/notification';
import { connect } from 'react-redux';
import { selectors, apis } from '@amplement/backend-connector';
import Icon from 'components/Shared/Common/Icon';
import { Badge } from 'antd';
import { injectIntl } from 'react-intl';

import Settings from 'components/Shared/Drawers/Sip/Settings/container';
import CallHistory from 'components/Shared/Drawers/Sip/CallHistory/container';
import Keyboard from 'components/Shared/Drawers/Sip/Phone/container';
import VoiceMails from 'components/Shared/Drawers/Sip/VoiceMails/container';
import ForwardsWrapper from 'components/Shared/Drawers/Sip/Forwards';
import Informations from 'components/Shared/Drawers/Sip/Informations';

import Placeholder from 'components/Shared/Drawers/Sip/Placeholder';
import Logger from 'services/debug/logger';
import styled from 'styled-components';
import { StyledTabs } from 'components/Shared/styles';
import { getCompanyTabByNameSelector } from 'selectors/company';

const logger = new Logger('component:Drawer:Sip');

const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-badge{
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .icon{
        display: flex;
        align-items: center;
        margin-right: 5px;
        svg{
            width: 14px;
            height: 14px;
        }
    }
`;

const CallTabs = memo(injectIntl(({
    intl,
    notificationCount,
    unReadVoiceMailCount,
    isVoiceMailSupported,
    isEnabled,
    isEditable,
    onClose,
    _account,
    status
}) => {
    const [isAddAccount, setIsAddAccount] = useState();
    const [activeTab, setActiveTab] = useState(NOTIFICATION_CATEGORIES.ROOM);

    const handleTabChange = useCallback((tab) => setActiveTab(tab), [setActiveTab]);

    const handleAddAccount = useCallback(() => setIsAddAccount(true), [setIsAddAccount]);

    useEffect(() => {
        logger.log('useEffect:status changed', status, isAddAccount, activeTab);
        if (status === SIP_ACCOUNT_STATUS.REGISTERED && isAddAccount) {
            if (activeTab === 'settings') {
                setActiveTab('keyboard');
            }
            setIsAddAccount(undefined);
        }
    }, [status, isAddAccount]);


    if (!isWebRtcCompatible()) {
        return <UnsupportedBrowserComponent />;
    }

    const items = useMemo(() => [{        
        key: NOTIFICATION_CATEGORIES.ROOM,
        label: (
            <StyledLabel>
                <Icon iconName="BookOpen" />
                <Badge overflowCount={999} count={notificationCount} offset={[8, 0]}>
                    {intl.formatMessage({ id: 'call.tabs.callHistory' })}
                </Badge>
            </StyledLabel>
        ),
        children: <CallHistory category={NOTIFICATION_CATEGORIES.ROOM} />
    }, {
        key: 'voiceMail',
        label: (
            <StyledLabel>
                <Icon iconName="Voicemail" />
                <Badge overflowCount={999} count={unReadVoiceMailCount} offset={[8, 0]}>
                    {intl.formatMessage({ id: 'call.tabs.voiceMail' })}
                </Badge>
            </StyledLabel>
        ),
        children: <VoiceMails />,
        disabled: !!(!isEnabled || !isVoiceMailSupported)
    }, {
        key: 'keyboard',
        label: (
            <StyledLabel>
                <Icon iconName="Pad" />
                {intl.formatMessage({ id: 'call.tabs.keyboard' })}
            </StyledLabel>
        ),
        children: <Keyboard />,
        disabled: !!(!isEnabled || !_account || isAddAccount)
    }, {
        key: 'forwards',
        label: (
            <StyledLabel>
                <Icon iconName="PhoneForwarded" />
                {intl.formatMessage({ id: 'call.tabs.forwardSettings' })}  
            </StyledLabel>
        ),
        children: <ForwardsWrapper />,
        active: !!(_account && isEnabled && isEditable && !!apis?.callForward?.$getAllForwardsSettings)
    }, {
        key: 'settings',
        label: (
            <StyledLabel>
                <Icon iconName="Settings" />
                {intl.formatMessage({ id: 'call.tabs.settings' })}
            </StyledLabel>
        ),
        children: !_account && !isAddAccount ?
            <Placeholder onClose={onClose} addAccount={handleAddAccount} />
            : <Settings />,
        active: !!(isEnabled && isEditable)
    }, {
        key: 'Info',
        label: (
            <StyledLabel>
                <Icon iconName="Info" />
                {intl.formatMessage({ id: 'call.tabs.infos' })}
            </StyledLabel>
        ),
        children: <Informations />,
        active: !!(_account && isEnabled && isEditable)
    }].filter(x => x.active !== false)
    , [isEnabled, isVoiceMailSupported, unReadVoiceMailCount, _account, isAddAccount, handleAddAccount, onClose, isEditable]);

    return (
        <StyledTabs
            activeKey={activeTab}
            onChange={handleTabChange}
            destroyInactiveTabPane
            items={items}
        />
    );
}));

const mapStateToProps = state => {
    const { status, id: _account } = selectors.sip.getSIPAccount(state) || {};
    const { options: { isEnabled = true, isEditable = true } = {}} = getCompanyTabByNameSelector(state, 'phone') || {};

    return ({
        _account,
        status,
        notificationCount: selectors.notifications.getUnreadNotificationCountByCategorySelector(state, NOTIFICATION_CATEGORIES.ROOM),
        unReadVoiceMailCount: selectors.voiceMails.unreadVoiceMailCountSelector(state),
        isVoiceMailSupported: selectors.voiceMails.voiceMailSupportedSelector(state),
        isEnabled,
        isEditable,
    });
};

const CallTabsContainer = connect(mapStateToProps, null)(CallTabs);

export default memo(injectIntl(CallTabsContainer));
