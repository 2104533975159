import React from 'react';
import { injectIntl } from 'react-intl';
import { Typography, Button } from 'antd';

const { Text } = Typography;

const ButtonCta = ({ children, ...props }) => (
    <Button 
        type="primary" 
        shape="round" 
        {...props}
    >
        {children}
    </Button>
);

export const ChangeBtn = injectIntl(({ intl, label, ...props }) => <ButtonCta {...props}>{intl.formatMessage({ id: 'global.button.edit' })}</ButtonCta>);
export const SearchBtn = injectIntl(({ intl, label, ...props }) => <ButtonCta {...props}>{intl.formatMessage({ id: 'global.button.search' })}</ButtonCta>);
export const ForwardToLabel = injectIntl(({ intl }) => <Text strong>{intl.formatMessage({ id: 'call.forward.forwardTo' })}{' : '}</Text>);

export const PhoneBookNumber = injectIntl(({ intl, firstname, lastname, phones, emptyText }) => (
    <Text type="success">
        {(firstname || lastname) && phones?.length ?
            `${intl.formatMessage({ id: 'user.username' }, {
                firstname,
                lastname
            })} - ${phones?.[0]?.displayNumber}`
            : emptyText}
    </Text>
));

export const voiceMailFilterPhoneType = ['others'];
export const numberFilterPhoneType = ['others', 'mobile_phone', 'work_phone'];

