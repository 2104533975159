import {
    Checkbox,
    Radio,
    Input,
    InputNumber,
    Select,
    Typography,
    Form,
    DatePicker,
    TimePicker
} from 'antd';

import styled from 'styled-components';
import { rgba } from 'polished';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export const StyledForm = styled(Form)`
    display: flex;
    align-items: flex-start;
    width: 100%;
    &.min-height-activity{
        textarea{
            min-height: 42px!important;
        }
    }
    .ant-form-item{
        width: 100%;
        &.feed-input{
            margin-bottom: 0;
            textarea{
                background-color: transparent!important;
                border: none!important;
                box-shadow: none!important;
            }
        }
    }
    &.flex-row{
        flex-direction: row;
    }
    .btn{
        margin-left: 10px;
    }
`;

export const StyledSelect = styled(Select)`
    &.ant-select{
        width: 100%;
        &:not(.ant-select-customize-input){
            .ant-select-selector{
                border-radius: 4px;
            }
        }
        &.ant-select-show-search{
            .ant-select:not(.ant-select-customize-input) .ant-select-selector input{
                color: ${({ theme }) => theme.black};
            }
        }
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
        color: ${({ theme }) => theme.black};
    }
    &.ant-select-single,
    &.ant-select-multiple{
        &:not(.ant-select-customize-input){
            .ant-select-selector{
                background-color: transparent;
                border-color: ${({ theme }) => rgba(theme.black, 0.1)};
                border: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
            }
            .ant-select-selection-item,
            .ant-select-arrow{
                color: ${({ theme }) => theme.black};
            }
            .ant-select{
                &-selection{
                    &-item{
                        &-content{
                            color: ${({ theme }) => rgba(theme.black, 0.65)};
                        }
                        &-remove{
                            color: ${({ theme }) => rgba(theme.black, 0.45)};
                        }
                    }
                    &-search{
                        color: ${({ theme }) => theme.black};
                    }
                    &-placeholder{
                        color: ${({ theme }) => rgba(theme.black, 0.6)};
                    }
                }
            }
            &:hover{
                .ant-select-selector{
                    border-color: ${props => props.theme.mainColor2};
                }
            }
        }
        &.ant-select-focused{
            .ant-select-selector{
                border-color: ${props => props.theme.mainColor2}!important;
                box-shadow: none!important;
            }
        }
    }
`;

export const StyledWrapperDropdown = styled.div`
    .ant-select-item{
        &-option{
            &-selected{
                &:not(.ant-select-item-option-disabled){
                    background-color: ${props => rgba(props.theme.mainColor2, 0.2)};
                }
            }
            &-active{
                &:not(.ant-select-item-option-disabled){
                    background-color: ${({ theme }) => rgba(theme.black, 0.2)};
                }
            }
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    .drawer-actions{
        width: auto;
        padding: 0 0 0 10px;
    }
`;

export const StyledInput = styled(Input)`
    &.ant-input,
    &.ant-input-affix-wrapper{
        resize: none;
        border-width: 1px!important;
        width: 100%;
        &-disabled{
            &::placeholder{
                color: ${({ theme }) => rgba(theme.black, 0.2)};
            }
        }
        &:hover{
            border-color: ${props => props.theme.mainColor2};
        }
        &:focus,
        &-focused{
            border-color: ${props => props.theme.mainColor2};
        }
    }
`;

export const StyledRangePicker = styled(RangePicker)`
    /* todo */
`;

export const StyledTimePicker = styled(TimePicker)`
    /* todo */
`;

export const StyledDatePicker = styled(DatePicker)`
    &.ant-picker{
        border-color: ${({ theme }) => rgba(theme.black, 0.1)};
        background-color: transparent!important;
        resize: none;
        color: ${({theme}) => theme.black};
        border-width: 1px!important;
        border-radius: 4px;
        width: 100%;
        .ant-picker{
            &-suffix{
                svg{
                    color: ${({theme}) => theme.black};
                }
            }
            &-clear{
                background-color: ${({ theme }) => theme.bg.modal};
                svg{
                    color: ${({theme}) => theme.black};
                }
            }
            &-input{
                > input{
                    color: ${({theme}) => theme.black};
                }
            }
        }
        &::placeholder{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
        }
        &-disabled{
            &::placeholder{
                color: ${({ theme }) => rgba(theme.black, 0.2)};
            }
        }
        &:focus,
        &-focused{
            border-color: ${props => props.theme.mainColor2};
            box-shadow: none;
        }
    }
`;

export const StyledInputNumber = styled(InputNumber)`
    &.ant-input-number{
        resize: none;
        border-width: 1px!important;
        width: 100%;
        &-disabled{
            &::placeholder{
                color: ${({ theme }) => rgba(theme.black, 0.2)};
            }
        }
        &:focus,
        &-focused{
            border-color: ${props => props.theme.mainColor2};
            box-shadow: none;
        }
    }
`;

export const StyledPassword = styled(Input.Password)`
    &.ant-input-password{
        border-color:${({ theme }) => rgba(theme.black, 0.1)};
        background-color: transparent;
        resize: none;
        color: ${({theme}) => theme.black};
        border-width: 1px!important;
        border-radius: 4px;
        width: 100%;
        &::placeholder{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
        }
        &-disabled{
            &::placeholder{
                color: ${({ theme }) => rgba(theme.black, 0.2)};
            }
        }
        &:focus,
        &-focused{
            border-color: ${props => props.theme.mainColor2};
            box-shadow: none;
        }
    }
`;

export const StyledTextArea = styled(TextArea)`
    &.ant-input,
    &.ant-input-textarea{
        width: 100%;
        border-color:${({ theme }) => rgba(theme.black, 0.1)};
        background-color: transparent!important;
        resize: none;
        color: ${({theme}) => theme.black};
        border-width: 1px!important;
        border-radius: 4px;
        &::placeholder{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
        }
        &-disabled{
            &::placeholder{
                color: ${({ theme }) => rgba(theme.black, 0.2)};
            }
        }
        &:focus,
        &-focused{
            border-color: ${props => props.theme.mainColor2};
            box-shadow: none;
        }
    }
`;


export const StyledCheckbox = styled(Checkbox)`
    &.ant-checkbox-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            .label{
                color: ${({ theme }) => theme.black};
            }
        }
        &:hover{
            .ant-checkbox-inner{
                border-color: ${props => props.theme.mainColor2};
            }
        }
    }
    .ant-checkbox{
        .ant-checkbox-input:focus + .ant-checkbox-inner{
            border-color: ${props => props.theme.mainColor2};
        }
        &-checked{
            &::after{
                border-color: ${props => props.theme.mainColor2};
            }
            &.ant-checkbox-disabled{
                .ant-checkbox{
                    &-inner{
                        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)};
                        border-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)}!important;
                        &::after{
                            border-color: ${({ theme }) => rgba(theme.fixedWhite, 0.4)};
                        }
                    }
                }
            }
            .ant-checkbox{
                &-inner{
                    background-color: ${props => props.theme.mainColor2};
                    border-color: ${props => props.theme.mainColor2}!important;
                    &::after{
                        border-color: ${({ theme }) => theme.fixedWhite};
                    }
                }
            }
        }
        &-inner{
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
            border-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
    }
`;

export const StyledRadio = styled(Radio)`
    &.ant-radio-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            .label{
                color: ${({ theme }) => theme.black};
            }
        }
        &:hover{
            .ant-radio-inner{
                border-color: ${props => props.theme.mainColor2};
            }
        }
    }
    .ant-radio{
        .ant-radio-input:focus + .ant-radio-inner{
            border-color: ${props => props.theme.mainColor2};
        }
        &-checked{
            &::after{
                border-color: ${props => props.theme.mainColor2};
            }
            &.ant-radio-disabled{
                .ant-radio{
                    &-inner{
                        background-color: ${({ theme }) => rgba(theme.black, 0.2)};
                        border-color: ${({ theme }) => rgba(theme.black, 0.2)}!important;
                        &::after{
                            border-color: ${({ theme }) => rgba(theme.white, 0.4)};
                        }
                    }
                }
            }
            .ant-radio{
                &-inner{
                    background-color: ${props => props.theme.mainColor2};
                    border-color: ${props => props.theme.mainColor2} !important;
                    &::after{
                        border-color: ${({ theme }) => theme.black};
                    }
                }
            }
        }
        &-inner{
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
            border-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
    }
`;

export const StyledListManager = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .styled-list{
        margin: 0;
        margin-top: 20px;
        list-style: none;
        padding: 0;
        li{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            padding: 5px;
            .btn{
                margin: 0;
            }
            border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
            &:hover{
                background-color: ${({ theme }) => rgba(theme.black, 0.05)};
            }
            &:last-child{
                border: none;
            }
            .content{
                display: flex;
                flex: 1;
            }
        }
    }
`;

export const StyledText = styled(Typography.Text)`
    color: ${({ theme }) => theme.black};
`;

export const StyledLabel = styled(Form.Item)`
    label{
        margin-bottom: 5px;
        text-transform: uppercase;
        color: ${({ theme }) => rgba(theme.black, 0.6)}!important;
        font-size: 10px!important;
        font-weight: bold;
    }
`;
