import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Select } from "antd";
import { Form as FinalForm, Field } from 'react-final-form';

import Actions from 'components/Shared/Forms/Actions';
import { LabelYesNoToggleButton, LabelSelect } from 'components/Shared/Forms';

const { Option } = Select;

const formatMessages = intl => ({
    language: intl.formatMessage({
        id: 'settings.general.language.title',
        defaultMessage: 'Langue'
    }),
    soundTitle: intl.formatMessage({
        id: 'settings.general.sound.title',
        defaultMessage: 'Activer le son'
    }),
    languagePlaceholder: intl.formatMessage({
        id: 'settings.general.language.placeholder',
        defaultMessage: 'Choisir une langue'
    })
});

const LanguageField = memo(({ languageLabel, languagePlaceholder, availableLanguages }) => (
    <Field
        label={languageLabel}
        name="language"
        component={(props) => (
            <LabelSelect {...props}>
                {availableLanguages.map(x =>
                    <Option key={x.value} value={x.value}>{x.label}</Option>
                )}
            </LabelSelect>
        )}
        placeholder={languagePlaceholder}
    />
), (prevProps, nextProps) =>
    JSON.stringify(prevProps.availableLanguages) === JSON.stringify(nextProps.availableLanguages));

const GeneralSettingsForm = memo(({
    intl,
    initialValues: {
        language,
        sounds
    },
    onSubmit,
    availableLanguages,
}) => {
    const i18n = useMemo(() => formatMessages(intl), [intl]);
    const initialValues = useMemo(() => ({ language, sounds }), [language, sounds]);

    return (
        <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, pristine, submitting, valid, form }) => (
                <Form onSubmit={handleSubmit}>
                    <Field
                        component={LabelYesNoToggleButton}
                        label={i18n.soundTitle}
                        name="sounds"
                    />
                    <LanguageField
                        availableLanguages={availableLanguages}
                        languageLabel={i18n.language}
                        languagePlaceholder={i18n.languagePlaceholder}
                    />

                    <Actions
                        className="action"
                        pristine={pristine}
                        reset={form.reset}
                        submitting={submitting}
                        submit={form.submit}
                        valid={valid}
                    />
                </Form>
            )}
        </FinalForm>
    );
});

GeneralSettingsForm.propTypes = {
    intl: PropTypes.any.isRequired,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    availableLanguages: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    )
};

GeneralSettingsForm.defaultProps = {
    onSubmit: () => {},
    initialValues: {},
    availableLanguages: [],
}

export default injectIntl(GeneralSettingsForm);
