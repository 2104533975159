import { ForwardSummaryVoiceMail, ForwardSummaryNumber, ForwardSummaryExtension, ForwardSummaryNone } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ItemSummary';

const FORWARD_TRIGGERS_COMPONENTS = {
    'VOICEMAIL': ForwardSummaryVoiceMail,
    'MOBILE': ForwardSummaryNumber,
    'NUMBER': ForwardSummaryNumber,
    'EXTENSION': ForwardSummaryExtension,
    'NONE': ForwardSummaryNone,
    '': ForwardSummaryNone,
};

export default FORWARD_TRIGGERS_COMPONENTS;
