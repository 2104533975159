import { connect } from 'react-redux';

import { selectors } from '@amplement/backend-connector';
import Component from './index';

const mapStateToProps = (state, { _room }) => {
    const users = selectors.rooms.getRoomMembersWithoutKickedByRoomIdSelector(state, _room);
    const maxMembers = selectors.rooms.getRoomByIdSelector(state, _room)?.maxMembers;
    const canManage = selectors.rooms.getUserIsAdminByCurrentUserIdSelector(state, _room);

    return {
        users,
        maxMembers,
        canManage
    }
};

export default connect(mapStateToProps)(Component);
