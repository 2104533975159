import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import RelationCard from 'components/Contacts/RelationCard/container';
import { getUrl } from 'utils/url';
import { isWebRtcCompatible } from 'utils/webRTC';
import Button from 'components/Shared/Common/Button';
import { Typography } from 'antd';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { requestUpdateContactStatus } from 'actions/user';
import { CONTACT_STATUS } from 'const/user';
import ContactList from 'components/Shared/Contacts/ContactList';
import ContactSkeleton from 'components/Shared/Contacts/ContactSkeleton';
import ContactActions from 'components/Shared/User/Item/ContactActions';
import NavButtons from 'components/Shared/Common/NavButtons';

const { Text } = Typography;

const RelationCardWithRequestPrompt = injectIntl(({ intl, ...props }) => {
    const [contactStatus, setContactStatus] = useState();
    const dispatch = useDispatch();
    const { id: _user } = props;

    const handleAccept = useCallback(() => {
        dispatch(requestUpdateContactStatus(_user, CONTACT_STATUS.ACCEPTED));
        setContactStatus(1);
    }, [dispatch, requestUpdateContactStatus, _user]);

    const handleDecline = useCallback(() => {
        dispatch(requestUpdateContactStatus(_user, CONTACT_STATUS.DECLINED));
        setContactStatus(2);
    }, [dispatch, requestUpdateContactStatus, _user]);
    
    return (
        <RelationCard
            {...props}
            renderActions={({ id }) => {
                if (!id) return null;

                if (contactStatus === CONTACT_STATUS.ACCEPTED) {
                    return [<Text type="success">{intl.formatMessage({ id: 'global.loading' })}</Text>];
                }
                if (contactStatus === CONTACT_STATUS.DECLINED) {
                    return [<Text type="danger">{intl.formatMessage({ id: 'global.loading' })}</Text>];
                }
                return [
                    <Button key="decline" color="secondary" danger onClick={handleDecline}>
                        {intl.formatMessage({ id: 'profile.relationButton.label.dismiss' })}
                    </Button>,
                    <Button key="accept" color="primary" onClick={handleAccept}>
                        {intl.formatMessage({ id: 'profile.relationButton.label.accept' })}
                    </Button>,
                ];
            }}
        />
    );
});

export const getUserActions = (onDelete) => (props) => {
    const { id: _user, contactStatus } = props;
    const messageUrl = getUrl('feedByUser', { _user });
    const hasWebrtc = isWebRtcCompatible();
    const roomUrl = getUrl('roomByUser', { _user });

    if (contactStatus === CONTACT_STATUS.PENDING) {
        return [
            <ContactActions
                key="cancelRequest"
                _user={_user}
                contactStatus={contactStatus}
                isInitiator
                size="md"
            />
        ];
    }

    return [
        <Button
            style={{ width:'auto' }}
            key="gotToFeed"
            isTransparent
            iconName="MessageCircle"
            size="md"
            href={messageUrl} 
        />,
        <Button
            style={{ width:'auto' }}
            key="PhoneCall"
            isTransparent
            iconName={hasWebrtc ? 'Phone' : 'PhoneOff'}
            isDisabled={!hasWebrtc}
            size="md"
            href={roomUrl}
            target={`roomByUser-${_user}`} 
        />,
        <Button
            key="deleteContact"
            color="error"
            isTransparent
            size="md"
            onClick={() => onDelete(_user)}
            iconName="Trash2" 
        />
    ].filter((_, i) => (!!onDelete || i !== 2));
}

const RelationCardConnected = (onDelete) => (props) => (
    <RelationCard
        {...props}
        renderActions={getUserActions(onDelete)}
    />
);

const getNavButtons = (intl, contactStatus, setContactStatus) => [
    {
        type: 'link',
        children: intl.formatMessage({ id: 'contact.tabs.label.accepted' }),
        disabled: contactStatus === CONTACT_STATUS.ACCEPTED,
        onClick: () => setContactStatus(CONTACT_STATUS.ACCEPTED)
    },
    {
        type: 'link',
        children: intl.formatMessage({ id: 'contact.tabs.label.waiting' }),
        disabled: contactStatus === CONTACT_STATUS.PENDING,
        onClick: () => setContactStatus(CONTACT_STATUS.PENDING)
    }
];

const Contacts = injectIntl(memo(({ intl, query, onDelete, isPublic }) => {
    const [contactStatus, setContactStatus] = useState(CONTACT_STATUS.ACCEPTED);
    const [hasPending, setHasPending] = useState(true);

    const navButtons = useMemo(() => getNavButtons(intl, contactStatus, setContactStatus), [intl, contactStatus]);

    return (
        <>
            <ContactList
                className={hasPending ? "highlight" : undefined}
                contactStatus={CONTACT_STATUS.PENDING}
                isInitiator={false}
                pageSize={4}
                ItemComponent={RelationCardWithRequestPrompt}
                hasStatus={false}
                autoHide
                label={<h2>{intl.formatMessage({ id: 'pushNotification.contactInvitation.title' })}</h2>}
                onData={(data) => setHasPending(!!data?.length)}
                hasPending={hasPending}
                SkeletonComponent={ContactSkeleton}
            />
            {isPublic && <NavButtons buttons={navButtons} />}
            <ContactList
                query={query}
                contactStatus={contactStatus}
                isInitiator={contactStatus === CONTACT_STATUS.PENDING ? true : undefined}
                pageSize={48}
                ItemComponent={RelationCardConnected(onDelete)}
                SkeletonComponent={ContactSkeleton}
            />
        </>
    );
}));

Contacts.propTypes = {
    query: PropTypes.string,
    onDelete: PropTypes.func
};

Contacts.defaultProps = {
    query: undefined,
    onDelete: undefined
};

export default memo(Contacts);
