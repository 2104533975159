import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import UiIcon from 'components/Shared/Common/Icon';
import { Checkbox, Alert, Typography, Switch } from 'antd';

import config from 'config';
import { getBrowserName } from 'services/screenSharing';
import styled from 'styled-components';
import { rgba } from 'polished';
import chromeImageSrc from 'statics/images/browsers/chrome.png';
import firefoxImageSrc from 'statics/images/browsers/firefox.png';
import operaImageSrc from 'statics/images/browsers/opera.png';
import { VideoErrorLayout } from 'components/Room/styles';
import useMediaDeviceFinderAll from 'hooks/useMediaDeviceFinderAll';
import { useSelector } from 'react-redux';
import { getAvailableDevices, getEnumerateDevicesError } from 'selectors/roomSettings';
import { ENTER } from 'const/eventKey';
import DeviceSelector from './DeviceSelector';
import VideoStream from './VideoStream';
import AudioStream from './AudioStream';

import { ContentLayout, RequestPermissionLayout } from './Layouts';
import SpeakerTest from './SpeakerTest';
import { useWebgl } from '../Webgl/WebglContext';

const { Paragraph } = Typography;
const paraphStyle = { margin: 0, fontSize: 16 };

const StyledWarning = styled.div`
    background-color: ${({ theme }) => rgba(theme.error, .2)};
    border: 1px solid ${({ theme }) => rgba(theme.fixedBlack, .1)};
    padding: 10px;
    border-radius: 6px;
    color: ${({ theme }) => theme.fixedBlack};
    margin-bottom: 10px;
    .list-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .item{
            max-width: 50px;
            img{
                max-width: 100%;
                display: block;
            }
        }
    }
    .title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .desc{
        font-size: 15px;
        margin-bottom: 10px;
    }
`;

export const RequestPermissionAll = ({ intl, ...props }) => (
    <RequestPermissionLayout
        {...props}
        intl={intl}
        player={(
            <>
                <Alert 
                    type="info" 
                    message={intl.formatMessage({ id: 'rtc.modal.requestPermission.all.lighten' })} 
                />
                <VideoErrorLayout>
                    <UiIcon iconName="VideoOff" />
                </VideoErrorLayout>
            </>
        )}
    >
        <Paragraph>
            {intl.formatMessage({ id: 'rtc.modal.requestPermission.all.content' })}
        </Paragraph>
    </RequestPermissionLayout>
);

RequestPermissionAll.propTypes = {
    intl: PropTypes.any.isRequired
};

RequestPermissionAll.defaultProps = {
};

export const RequestPermissionVideo = ({ intl, ...props }) => (
    <RequestPermissionLayout
        {...props}
        intl={intl}
        player={(
            <>
                <Alert 
                    type="info" 
                    message={intl.formatMessage({ id: 'rtc.modal.requestPermission.video.lighten' })} 
                />
                <VideoErrorLayout>
                    <UiIcon iconName="VideoOff" />
                </VideoErrorLayout>
            </>
        )}
    >
        <Paragraph style={paraphStyle}>
            {intl.formatMessage({ id: 'rtc.modal.requestPermission.video.content' })}
        </Paragraph>
    </RequestPermissionLayout>
);

RequestPermissionVideo.propTypes = {
    intl: PropTypes.any.isRequired
};

RequestPermissionVideo.defaultProps = {
};

export const RequestPermissionAudio = ({ intl, avatar, ...props }) => (
    <RequestPermissionLayout
        {...props}
        intl={intl}
    >
        <Paragraph style={paraphStyle}>
            {intl.formatMessage({ id: 'rtc.modal.requestPermission.audio.content' })}
        </Paragraph>
    </RequestPermissionLayout>
);

RequestPermissionAudio.propTypes = {
    avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    intl: PropTypes.any.isRequired
};

RequestPermissionAudio.defaultProps = {
    avatar: undefined
};

export const NoCamAndMicPlugged = ({ intl, ...props }) => (
    <RequestPermissionLayout {...props} intl={intl}>
        {intl.formatMessage({ id: 'rtc.error.noDeviceAvailable' })}
    </RequestPermissionLayout>
);

export const RequestPermission = injectIntl(({ intl, audio, video, avatar, ...rest }) => {
    if (!audio && !video) {
        return <NoCamAndMicPlugged intl={intl} />;
    }

    if (audio && video) {
        return (
            <RequestPermissionAll
                {...rest}
                intl={intl}
                missingPermissions={undefined}
            />
        );
    }

    if (video) {
        return (
            <RequestPermissionVideo
                {...rest}
                intl={intl}
                missingPermissions={undefined}
            />
        );
    }

    return (
        <RequestPermissionAudio
            avatar={avatar}
            intl={intl}
            {...rest}
            missingPermissions={undefined}
        />
    );
});

RequestPermission.propTypes = {
    intl: PropTypes.any.isRequired,
    audio: PropTypes.bool,
    video: PropTypes.bool,
    avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

RequestPermission.defaultProps = {
    audio: undefined,
    video: undefined,
    avatar: undefined
};

export const PermissionDenied = injectIntl(({ intl, ...props }) => (
    <ContentLayout
        intl={intl}
        player={(
            <>
                <Alert 
                    type="info" 
                    message={intl.formatMessage({ id: 'rtc.modal.permissionDenied.lighten' })}
                />
                <VideoErrorLayout className="error">
                    <UiIcon iconName="VideoOff" />
                </VideoErrorLayout>
            </>
        )}
        footer={(
            <Button
                color="primary"
                size="lg"
                iconName="ArrowUpLeft"
                onClick={props.onReload}
            >
                {intl.formatMessage({ id: 'global.button.retry' })}
            </Button>
        )}
    >
        <Paragraph style={paraphStyle}>
            {intl.formatMessage({ id: 'rtc.modal.permissionDenied.content' })}
        </Paragraph>
    </ContentLayout>
));

PermissionDenied.propTypes = {
    onReload: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired
};

PermissionDenied.defaultProps = {
};

const UnsupportedScreenSharingComponent = ({ intl, ...props }) => (
    <ContentLayout
        intl={intl}
        footer={(
            <Button
                color="primary"
                size="lg"
                onClick={props.onProceed}
            >
                {intl.formatMessage({ id: 'rtc.modal.unsupportedScreenSharing.continue' })}
            </Button>
        )}
    >
        <StyledWarning className="warning">
            <div className="title">
                {intl.formatMessage({ id: 'rtc.modal.unsupportedScreenSharing.title' })}

            </div>
            <div className="desc">
                {intl.formatMessage({ id: 'rtc.modal.unsupportedScreenSharing.content' })}
            </div>
            <div className="list-row">
                <div className="item">
                    <a target="_blank" rel="noopener noreferrer" href={config.browserUpdateLink}>
                        <img alt="chrome" src={chromeImageSrc} />
                    </a>
                </div>
                <div className="item">
                    <a target="_blank" rel="noopener noreferrer" href={config.browserUpdateLink}>
                        <img alt="firefox" src={firefoxImageSrc} />
                    </a>
                </div>
                <div className="item">
                    <a target="_blank" rel="noopener noreferrer" href={config.browserUpdateLink}>
                        <img alt="opera" src={operaImageSrc} />
                    </a>
                </div>
            </div>
        </StyledWarning>
    </ContentLayout>
)

UnsupportedScreenSharingComponent.propTypes = {
    onProceed: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired
};

UnsupportedScreenSharingComponent.defaultProps = {
};
export const UnsupportedScreenSharing = injectIntl(UnsupportedScreenSharingComponent);

// const getDeviceIdFromStream = (stream, kind, defaultLabel) => {
//     let value = defaultLabel;

//     if (stream && kind && (!defaultLabel || defaultLabel === 'default')) {
//         const track = stream && stream.getTracks().find(x => x.kind === kind && x.enabled);
//         if (track) {
//             if (typeof track.getSettings === 'function') {
//                 value = track.getSettings().deviceId;
//             }
//             if (typeof track.getCapabilities === 'function') {
//                 value = track.getCapabilities().deviceId;
//             }
//         }
//     }
//     return value;
// }

const AudioOutputStatus = injectIntl(({ intl, devices }) => {
    const hasMultipleAudioinput = devices?.some(d => d.kind === 'audiooutput');

    return hasMultipleAudioinput ? (
        <Alert 
            type="error" 
            message={intl.formatMessage({ id: 'rtc.error.devices.microphone.NoSoundDetectedPleaseSelectAnother' })} 
        />
    ) : (
        <Alert 
            type="error"
            message={intl.formatMessage({ id: 'rtc.error.devices.microphone.NoSoundDetected' })}
        />
    );
});

const MicrophoneManagerComponent = ({ intl, onChange, value, devices, mediaDeviceError }) => {
    const [isEmptyAudioinput, setIsEmptyAudioinput] = useState(false);

    useEffect(() => {
        setIsEmptyAudioinput(undefined);
    }, [value]);

    const handleStrengthChange = useCallback((strength) => {
        const isEmpty = strength === -1;
        if (isEmptyAudioinput !== isEmpty) {
            setIsEmptyAudioinput(isEmpty);
        }
    }, [isEmptyAudioinput, setIsEmptyAudioinput]);

    return (
        <div className="group-input">
            <div className="groups-input">
                <DeviceSelector
                    devices={devices}
                    mediaDeviceError={mediaDeviceError}
                    onChange={onChange}
                    value={value}
                    inputLabel={intl.formatMessage({ id: 'rtc.label.audio' })}
                    kind="audioinput"
                />
                <AudioStream
                    value={value}
                    intl={intl}
                    onStrengthChange={handleStrengthChange}
                />
            </div>
            {isEmptyAudioinput && (
                <AudioOutputStatus 
                    devices={devices}
                />
            )}
        </div>
    )
}

MicrophoneManagerComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    intl: PropTypes.any.isRequired
};

MicrophoneManagerComponent.defaultProps = {
    value: undefined
};

const MicrophoneManager = injectIntl(MicrophoneManagerComponent);

const SpeakerManagerComponent = ({ intl, onChange, value, devices, mediaDeviceError }) => {
    const audioDevices = devices?.length ? devices?.filter(d => d.kind === 'audiooutput') : undefined;

    return (
        <div className="group-input">
            <div className="groups-input">
                {!mediaDeviceError && !!devices?.length ? (
                    <DeviceSelector
                        devices={audioDevices}
                        mediaDeviceError={mediaDeviceError}
                        onChange={onChange}
                        value={value}
                        inputLabel={intl.formatMessage({ id: 'rtc.label.output' })}
                        kind="audiooutput"
                    />
                ) : (
                    <>
                        <label>{intl.formatMessage({ id: 'rtc.modal.configure.speakerTest.label' })}</label>{/* eslint-disable-line */}
                        <div>{intl.formatMessage({ id: 'rtc.modal.configure.speakerTest.content' })}</div>
                    </>
                )}
                <SpeakerTest deviceId={value} intl={intl} />
            </div>
        </div>
    );
}

SpeakerManagerComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    intl: PropTypes.any.isRequired
};

SpeakerManagerComponent.defaultProps = {
    value: undefined
};

const SpeakerManager = injectIntl(SpeakerManagerComponent);

const footerStyle = { display: 'grid', width: '100%' };
const buttonStyle = { justifySelf: 'center' };
const switchStyle = { margin: '10px 10px 10px 0' };
const DivStyled = styled.div`
    margin: -30px 0 10px;
`;

const ConfigureComponent = ({
    intl,
    submitLabel,
    onSubmit,
    audio,
    showSkip,
    footerButtonProps,
    layoutDirection,
    video
}) => {
    const [skip, setSkip] = useState(true);
    const [audioinput, audiooutput, videoinput] = useMediaDeviceFinderAll();
    const { overlayType, setOverlayType } = useWebgl();
    const devices = useSelector(getAvailableDevices);
    const mediaDeviceError = useSelector(getEnumerateDevicesError);
    const [finalaudioinput, setFinalaudioinput] = useState(audioinput);
    const [finalaudiooutput, setFinalaudiooutput] = useState(audiooutput);
    const [finalvideoinput, setFinalvideoinput] = useState(videoinput);

    useEffect(() => setFinalaudioinput(audioinput), [setFinalaudioinput, audioinput]);
    useEffect(() => setFinalaudiooutput(audiooutput), [setFinalaudiooutput, audiooutput]);
    useEffect(() => setFinalvideoinput(videoinput), [setFinalvideoinput, videoinput]);

    const setAudioinputDeviceId = useCallback((deviceId) => setFinalaudioinput(deviceId), [setFinalaudioinput]);
    const setAudiooutputDeviceId = useCallback((deviceId) => setFinalaudiooutput(deviceId), [setFinalaudiooutput]);
    const setVideoinputDeviceId = useCallback((deviceId) => setFinalvideoinput(deviceId), [setFinalvideoinput]);

    const handleSkipNextTime = useCallback((e) => setSkip(e.target.checked), [setSkip]);

    const handleJoin = useCallback(
        () => {
            onSubmit({
                audioinput: finalaudioinput,
                audiooutput: finalaudiooutput,
                videoinput: finalvideoinput,
                skipNextTime: skip
            });
        },
        [onSubmit, finalaudioinput, finalaudiooutput, finalvideoinput, skip]
    );

    const onActivateBlur = useCallback(() => {
        if (overlayType === 'normal') {
            setOverlayType('blur');
        } else {
            setOverlayType('normal');
        }
    }, [setOverlayType, overlayType]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === ENTER || e.keyCode === ENTER) {
                handleJoin();
            }
        }

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <ContentLayout
            intl={intl}
            direction={layoutDirection}
            player={video && (
                <VideoStream
                    value={finalvideoinput}
                />
            )}
            footer={(
                <div style={footerStyle}>
                    <Button
                        color="primary"
                        size="lg"
                        iconName="ArrowUpLeft"
                        onClick={handleJoin}
                        style={buttonStyle}
                        {...footerButtonProps}
                    >
                        {submitLabel}
                    </Button>
                </div>
            )}
        >
            {video && (
                <>
                    <DeviceSelector
                        devices={devices}
                        mediaDeviceError={mediaDeviceError}
                        onChange={setVideoinputDeviceId}
                        value={finalvideoinput}
                        inputLabel={intl.formatMessage({ id: 'rtc.label.video' })}
                        kind="videoinput"
                    />
                    <DivStyled>
                        <Switch
                            checked={overlayType === 'blur'}
                            onChange={onActivateBlur}
                            style={switchStyle}
                        />
                        {overlayType === 'blur' ?
                            intl.formatMessage({ id: 'room.blurStatus.blur' }) :
                            intl.formatMessage({ id: 'room.blurStatus.normal' })}
                        {overlayType === 'blur' && (
                            <Alert
                                banner
                                type="warning"
                                showIcon
                                message={intl.formatMessage({ id: 'room.actions.blurWarning' })}
                            />
                        )}
                    </DivStyled>
                </>
            )}

            {audio && (
                <MicrophoneManager
                    devices={devices} 
                    mediaDeviceError={mediaDeviceError}
                    onChange={setAudioinputDeviceId}
                    value={finalaudioinput}
                />
            )}

            {getBrowserName !== 'firefox' && (
                <SpeakerManager 
                    devices={devices} 
                    mediaDeviceError={mediaDeviceError}
                    onChange={setAudiooutputDeviceId} 
                    value={finalaudiooutput} 
                />
            )}

            {showSkip && (
                <Checkbox
                    onChange={handleSkipNextTime}
                    defaultChecked={skip}
                >
                    {intl.formatMessage({ id: 'rtc.modal.configure.skipNextTime' })}
                </Checkbox>
            )}
        </ContentLayout>
    );
}

ConfigureComponent.propTypes = {
    intl: PropTypes.any.isRequired,
    submitLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    showSkip: PropTypes.bool,
    footerButtonProps: PropTypes.object,
    audio: PropTypes.bool,
    video: PropTypes.bool,
    stream: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    layoutDirection: PropTypes.string
};

ConfigureComponent.defaultProps = {
    footerButtonProps: {},
    showSkip: true,
    submitLabel: undefined,
    audio: true,
    video: true,
    stream: undefined,
    layoutDirection: 'horizontal'
};

export const Configure = injectIntl(ConfigureComponent);
