import * as sessionTypes from 'types/session';
import * as userTypes from 'types/user';
import { RequestHelper, ReducerEntityBase } from 'utils/state/reducer';
import RequestStateFormatter from 'utils/state/requestStateFormatter';

const initialState = {
    loaded: null,
    user: { ...RequestStateFormatter.initial(), entity: {} }
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case userTypes.GET_USER_REQUEST:
            return RequestHelper(state, 'user').update(RequestStateFormatter.request(action));

        case userTypes.GET_USER_SUCCESS: {
            const updatedState = RequestHelper(state, 'user').update(RequestStateFormatter.entitySuccess(action));
            if (action.data.entities && action.data.entities.users) {

                const entities = Object.values(action.data.entities.users);
                return {
                    ...updatedState,
                    user: {
                        ...updatedState.user,
                        entity: entities.length ? entities[0] : state.user.entity
                    }
                };

            }
            return state;
        }

        case userTypes.GET_USER_FAILURE:
            return RequestHelper(state, 'user').update(RequestStateFormatter.failure(action));

        case sessionTypes.POST_AVATAR_REQUEST:
        case sessionTypes.POST_AVATAR_SUCCESS:
        case sessionTypes.POST_AVATAR_FAILURE:
            return ReducerEntityBase(state, 'users', action, true);

        case sessionTypes.RESET_LOADED_USER:
            return initialState;

        case sessionTypes.UPDATE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    entity: {
                        ...state.user.entity,
                        [action.key]: action.value
                    }
                }
            };

        case userTypes.WS_PUT_STATUS:
            return {
                ...state,
                user: {
                    ...state.user,
                    entity: {
                        ...state.user.entity,
                        status: action.payload.status
                    }
                }
            };

        case sessionTypes.WS_PUT_USER:
            if (action.payload.id === state.user.entity.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        entity: {
                            ...state.user.entity,
                            ...action.payload
                        }
                    }
                };
            }
            return state;

        case userTypes.WS_POST_CONTACT:
        case userTypes.WS_PUT_CONTACT:
            if (action.payload.id === state.user.entity.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        entity: {
                            ...state.user.entity,
                            ...action.payload
                        }
                    }
                };
            }
            return state;

        case userTypes.WS_DELETE_CONTACT:
            if (action.payload.id === state.user.entity.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        entity: {
                            ...state.user.entity,
                            contactStatus: undefined
                        }
                    }
                };
            }
            return state;

        default:
            return state;
    }
};

export default profileReducer;
