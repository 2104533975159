import { connect } from 'react-redux';
import { compose } from 'redux';
import Settings from 'components/Shared/Drawers/Sip/Settings';
import  { actions, selectors } from '@amplement/backend-connector';
import injectValidators from 'components/Shared/Forms/injectValidators';

const mapStateToProps = state => {
    const {
        status,
        sipData,
        id: _account,
        hasSettingsLoaded,
        hasAdvancedSettings,
        username,
        outboundProxyIpaddr,
        outboundProxyPort,
        domainServer,
        server,
        serverPort,
        password = '',
        prefix,
    } = selectors.sip.getSIPAccount(state);
    const initialValues = hasSettingsLoaded ? {
        id: _account,
        username,
        server,
        outboundProxyIpaddr,
        outboundProxyPort,
        serverPort,
        domainServer,
        password,
        prefix,
    } : undefined;
    return ({
        loading: _account && !hasSettingsLoaded,
        hasAdvancedSettings,
        _account,
        status,
        sipData,
        initialValues
    });
};

const mapDispatchToProps = dispatch => ({
    onRemove: (_account) => dispatch(actions.sip.requestDeleteAccount(_account)),
    getAccounts: () => dispatch(actions.sip.fetchSIPAccounts()),
    onSubmit: ({ id, ...values }) => {
        if (id) {
            dispatch(actions.sip.requestPutAccount(values))
        } else {
            dispatch(actions.sip.requestPostAccount(values))
        }
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectValidators
)(Settings);
