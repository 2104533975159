import * as types from 'types/roomSettings';

export const setAvailableDevices = (devices) => ({
    type: types.SET_AVAILABLE_DEVICES,
    payload: devices
});

export const setEnumerateDeviceError = (error) => ({
    type: types.SET_ENUMERATEDEVICES_ERROR,
    payload: error
});

export const setDeviceSettings = (settings) => ({
    type: types.SET_DEVICE_SETTINGS,
    payload: settings
});

export const setMediaDevicePermissionState = (state) => ({
    type: types.SET_MEDIADEVICE_PERMISSION_STATE,
    payload: state
});
