import {
    all,
    put,
    call
} from 'redux-saga/effects';

import { addNotification } from 'actions/notification';

import * as uploadTypes from 'types/upload';
import config from 'config';
import Upload from 'services/upload';
import { flatUniqConcat } from 'utils/state/array';
import  { actions, utils } from '@amplement/backend-connector';

function* uploadEmailFiles({ files, change, prevEmails: existingEmails }) {
    try {
        if (!files) {
            throw new Error('At least one file is missing');
        }
        const resolvedEmails = Object.values(files).map((file) => {
            const upload = new Upload({ url: `${config.fileParserHostname}/upload` });
            upload.setFile('file', file);
            return upload.send();
        });

        const promise = resolvedEmails && resolvedEmails.length ? resolvedEmails[0] : undefined;

        if (promise) {
            const { data: emails } = yield (promise);

            const filteredEmails = existingEmails
                ? flatUniqConcat(emails, existingEmails)
                : emails;
            const emailsAdded = existingEmails
                ? filteredEmails.length - existingEmails.length
                : filteredEmails.length;

            change('emails', filteredEmails);

            yield put(addNotification({
                severity: 'success',
                title: 'contact.upload.customSuccess',
                values: {
                    emailsAdded
                }
            }));
        }

    } catch (e) {
        const errorTitle = (e && e.id) || 'error.default';
        yield put(addNotification({
            title: errorTitle,
            referenceError: 'MAIL1'
        }));
        yield call(utils.errorHandler.captureException, e, 'saga:upload:uploadEmailFiles');
    }
}

function* handlePostFeedItems({ _feed, feedItems }) {
    if (feedItems) {
        const hasFileUpload = feedItems.some(x => x.metasFile);
        if (!hasFileUpload) {
            return false;
        }
        try {
            const feedItemsLength = feedItems.length;
            for (let idx = 0; idx < feedItemsLength; idx++) {
                const { metasFile, metasFile: { name, type }, content, fileSize } = feedItems[idx];
                yield put(actions.feedItems.requestPostFeedFile(metasFile, name, _feed, type, content, fileSize));
            }
        } catch (e) {
            yield put(addNotification({
                title: 'feedItem.post.failure',
                referenceError: 'FI30'
            }));
            yield call(utils.errorHandler.captureException, e, 'saga:upload:handlePostFeedItems');
        }

    } else {
        // logger.error('aucun feeditem a inserer', _feed, feedItems);
    }
    return true;
}

export default function* root() {
    yield all([
        utils.sagas.takeLatest(uploadTypes.REQUEST_UPLOAD_EMAIL_FILES, uploadEmailFiles),
        utils.sagas.takeLatest('POST_FEED_ITEMS', handlePostFeedItems)
    ]);
}
