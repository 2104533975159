import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'antd';

const { Text } = Typography;

const SipData = ({
    sipData
}) => sipData && sipData.code !== 200 ? (
    <Text type="danger">Error: {sipData.code} - {sipData.reason}</Text>
) : null;

SipData.propTypes = {
    sipData: PropTypes.shape({
        code: PropTypes.number.isRequired,
        reason: PropTypes.string.isRequired
    })
};

SipData.defaultProps = {
    sipData: undefined
};

export default SipData;
