import withFieldLabel from 'hoc/withFieldLabel';
import {
    StyledSelect,
    StyledInputNumber,
    StyledPassword,
    StyledRangePicker
} from 'components/Shared/Forms/styles';

import ToggleButton, { YesNoToggleButton } from 'components/Shared/Forms/ToggleButton';
import ColorPicker, { ColorSelector } from 'components/Shared/Forms/ColorPicker';
import TextArea from 'components/Shared/Forms/TextArea';
import UserMultiSelect from 'components/Shared/Forms/UserMultiSelect';
import MultiSelect from 'components/Shared/Forms/MultiSelect';
import ListManager from 'components/Shared/Forms/ListManager';
import DatePicker from 'components/Shared/Forms/DatePicker';
import TimePicker from 'components/Shared/Forms/TimePicker';
import Input from 'components/Shared/Forms/Input';
import Text from 'components/Shared/Forms/Text';

export const LabelInput = withFieldLabel(Input, { autoComplete: 'off' });
export const LabelInputNumber = withFieldLabel(StyledInputNumber, { autoComplete: 'off'});
export const LabelPassword = withFieldLabel(StyledPassword, { autoComplete: 'off'});
export const LabelTogglebutton = withFieldLabel(ToggleButton);
export const LabelYesNoToggleButton = withFieldLabel(YesNoToggleButton);
export const LabelColorPicker = withFieldLabel(ColorPicker);
export const LabelColorSelector = withFieldLabel(ColorSelector);
export const LabelTextArea = withFieldLabel(TextArea, { autoComplete: 'off'});
export const LabelSelect = withFieldLabel(StyledSelect);
export const LabelMultiSelect = withFieldLabel(MultiSelect);
export const LabelUserMultiSelect = withFieldLabel(UserMultiSelect);
export const LabelListManager = withFieldLabel(ListManager);
export const LabelText = withFieldLabel(Text);
export const LabelRangePicker = withFieldLabel(StyledRangePicker);
export const LabelDatePicker = withFieldLabel(DatePicker);
export const LabelTimePicker = withFieldLabel(TimePicker);
