import React, { memo } from 'react';
import PropTypes from 'prop-types';

import pdfFilePath from 'common/file/file-pdf.svg';
import wordFilePath from 'common/file/file-word.svg';
import xlsFilePath from 'common/file/file-xls.svg';
import pptFilePath from 'common/file/file-ppt.svg';
import psFilePath from 'common/file/file-ps.svg';
import aiFilePath from 'common/file/file-ai.svg';
import videoFilePath from 'common/file/file-video.svg';
import defaultFilePath from 'common/file/file2.svg';
import cssFilePath from 'common/file/file-css.svg';
import htmlFilePath from 'common/file/file-html.svg';
import imageFilePath from 'common/file/file-img.svg';
import jsFilePath from 'common/file/file-js.svg';
import jsonFilePath from 'common/file/file-json.svg';
import mp3FilePath from 'common/file/file-mp3.svg';
import phpFilePath from 'common/file/file-php.svg';
import sketchFilePath from 'common/file/file-sketch.svg';
import zipFilePath from 'common/file/file-zip.svg';
import fallbackDisplayable from 'common/images/fallback-file-img.svg';
import { Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

export const iconsPath = {
    '.pdf': pdfFilePath,
    '.doc': wordFilePath,
    '.docx': wordFilePath,
    '.xls': xlsFilePath,
    '.xlsx': xlsFilePath,
    '.ppt': pptFilePath,
    '.psd': psFilePath,
    '.ai': aiFilePath,
    '.ogg': videoFilePath,
    '.ogv': videoFilePath,
    '.mp4': videoFilePath,
    '.mov': videoFilePath,
    '.webm': videoFilePath,
    '.css': cssFilePath,
    '.html': htmlFilePath,
    '.jpg': imageFilePath,
    '.png': imageFilePath,
    '.heic': imageFilePath,
    '.js': jsFilePath,
    '.json': jsonFilePath,
    '.mp3': mp3FilePath,
    '.php': phpFilePath,
    '.sketch': sketchFilePath,
    '.zip': zipFilePath,
    default: defaultFilePath
};

export const getIconPath = (extensionName) => {
    const imgToDisplay = (extensionName || '').toLowerCase();
    return iconsPath[imgToDisplay] ? iconsPath[imgToDisplay] : defaultFilePath;
};

const FileIcon = ({
    fileExtension,
    isDisplayable,
    src,
    thumb,
    name,
    style
}) => isDisplayable ? (
    <Image
        alt={name}
        src={thumb || src}
        fallback={fallbackDisplayable}
        preview={{ mask: <EyeOutlined />, src }}
        width="100%"
        height="100%"
        style={{ ...style, objectFit: 'cover' }}
    />
) : (
    <Image
        alt={name}
        src={getIconPath(fileExtension)}
        fallback={defaultFilePath}
        preview={false}
        width="100%"
        height="100%"
        style={style}
    />
);

FileIcon.propTypes = {
    fileExtension: PropTypes.string,
    src: PropTypes.string,
    thumb: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    isDisplayable: PropTypes.bool.isRequired
};

FileIcon.defaultProps = {
    src: undefined,
    thumb: undefined,
    fileExtension: undefined,
    name: undefined,
    style: {}
};

export default memo(FileIcon);
