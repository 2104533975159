import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Table, Button, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

import Icon from 'components/Shared/Common/Icon';
import { ForwardSummaryNumber, ForwardSummaryVoiceMail } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ItemSummary';

const formatMessages = (intl) => ({
    edit: intl.formatMessage({ id: 'global.button.edit' }),
    day: intl.formatMessage({ id: 'global.date.day' }),
    hour: intl.formatMessage({ id: 'global.date.hour' }),
    forward: intl.formatMessage({ id: 'call.tabs.forwards.forward' }),
    status: intl.formatMessage({ id: 'global.status' }),
    action: intl.formatMessage({ id: 'global.action' }),
    enable: intl.formatMessage({ id: 'call.tabs.forwards.enable' }),
    disable: intl.formatMessage({ id: 'call.tabs.forwards.disable' }),
});

const buttonStyle = { width: 30, height: 30 };

const List = ({
    data,
    handleEdit,
    handleSelectRows,
    selectedRows,
    handleUpdateStatus,
    intl,
    loadingIds,
    isLoading,
}) => {
    const translations = useMemo(() => formatMessages(intl), [intl]);

    const columns = useMemo(() => [
        {
            title: translations.day,
            dataIndex: 'day',
            key: 'day',
        },
        {
            title: translations.hour,
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: translations.forward,
            key: 'to',
            render: ({ context, to }) => {
                if (context) return <ForwardSummaryVoiceMail data={{ context }} />
                return <ForwardSummaryNumber data={{ to }} />
            },
        },
        {
            title: translations.status,
            key: 'enable',
            render: ({ enable, action: { updateStatus, loading } }) => (
                <Tooltip
                    placement='bottom'
                    title={enable ? translations.disable : translations.enable}
                >
                    <Switch
                        loading={loading}
                        checked={enable}
                        onChange={updateStatus}
                    />
                </Tooltip>
            ),
        },
        {
            title: translations.action,
            key: 'action',
            render: ({ action: { edit } }) => (
                <Tooltip placement='bottom' title={translations.edit}>
                    <Button
                        style={buttonStyle}
                        onClick={edit}
                        icon={<Icon iconName='Edit' />}
                    />
                </Tooltip>
            ),
        },
    ], [translations]);

    const dataSource = useMemo(() => data.map(row => ({
        key: row.id,
        day: row.weekdays.join(','),
        time: `${row.fromTime} - ${row.toTime}`,
        to: row.to,
        context: row.context,
        enable: row.enabled,
        action: {
            updateStatus: () => handleUpdateStatus({ ids: [row.id], task: row.enabled ? 'disable' : 'enable' }),
            loading: loadingIds.includes(row.id),
            edit: () => handleEdit(row),
        },
    })), [data, handleUpdateStatus, handleEdit, loadingIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox',
        onChange: handleSelectRows,
        selectedRowKeys: selectedRows,
    }), [handleSelectRows, selectedRows]);

    return (
        <Table
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
        />
    );
};

List.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    handleUpdateStatus: PropTypes.func,
    handleEdit: PropTypes.func,
    handleSelectRows: PropTypes.func,
    selectedRows: PropTypes.array,
    intl: PropTypes.object.isRequired,
    loadingIds: PropTypes.arrayOf(PropTypes.string),
};

List.defaultProps = {
    isLoading: false,
    loadingIds: [],
    data: [],
    handleUpdateStatus: () => {},
    handleEdit: () => {},
    handleSelectRows: () => {},
    selectedRows: [],
};

export default injectIntl(List);
