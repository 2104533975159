
import styled from 'styled-components';
import { rgba } from 'polished';
import { Tabs, Row } from 'antd';
import { colors } from 'common/themes/Colors';

export const StyledTabs = styled(Tabs)`
    width: 100%;
    .ant-tabs{
        &-nav{
            padding-left: 20px;
        }
        &-ink-bar{
            background: ${props => props.theme.mainColor2};
        }
        &-content{
            &-holder{
                overflow: auto;
            }
        }
    }

    .ant-tabs-tab{
        &-disabled{
            .ant-badge{
                color: ${rgba(colors.black, .25)};
            }
        }
        &-active,
        &:hover{
            .ant-badge,
            .ant-tabs-tab-btn{
                color: ${props => props.theme.mainColor2};
            }
        }

    }
`;

export const ButtonActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 20px 0px;
    width: 100%;
    justify-content: flex-end;
    &:only-child {
        margin-left: auto;
    }
    &.centered{
        justify-content: center;
    }
    &.spaced{
        justify-content: space-between;
    }
`;
export const Spacer = styled.div`
    &.left{
        margin-left: 10px;
    }
`;

export const StyledInformationLabel = styled.div`
    margin-top: -10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .item{
        border-radius: 30px;
        padding: 5px 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        &.waiting{
            background-color: ${({ theme }) => theme.secondaryActive};
            color: ${({ theme }) => theme.black};
        }
        &.accepted{
            background-color: ${({ theme }) => rgba(theme.success, 0.1)};
            color: ${({ theme }) => theme.success};
        }
        &.declined{
            background-color: ${({ theme }) => rgba(theme.error, 0.1)};
            color: ${({ theme }) => theme.error};
        }
    }
`;

export const StyledRow = styled(Row)`
    width: 100%;
`;

export const StyledCustomTabs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    .ant-btn{
        margin-right: 10px;
        color: ${props => props.theme.black};
        background-color: transparent;
        font-weight: bold;
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            color: ${props => props.theme.mainColor2}!important;
        }
        &[disabled]{
            cursor: pointer;
            background-color: ${props => rgba(props.theme.mainColor2, 0.2)};
            color: ${props => props.theme.mainColor2};
        }
    }
`;

export const StyledContacts = styled.div`
    width: 100%;
    &.highlight{
        padding: 20px;
        background-color: ${props => rgba(props.theme.secondaryActive, 0.3)};
        border-radius: 4px;
    }
`;

export const StyledPickedColor = styled.div`
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    position: relative;
    transition: all .25s ease;
    width: 50px;
    .btn{
        .icon{
            color: ${({ theme }) => theme.white};
        }
    }
`;

export const StyledPickerColorWrapper = styled.div`
    background-color: ${({ theme }) => theme.white};
    border-radius: 6px;
    opacity: 0;
    position: absolute;
    right: 0;
    transform: translateY(-10px);
    transition: all .25s ease;
    visibility: hidden;
    z-index: 3;
    &.active{
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
`;

export const StyledContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    label{
        margin-bottom: 5px;
        text-transform: uppercase;
        color: ${({ theme }) => rgba(theme.black, 0.6)};
        font-size: 10px;
        font-weight: bold;
    }
    .switch-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;
