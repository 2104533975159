import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestGetUsers } from 'actions/user';
import { getUsersByIds } from 'selectors/user';

import BasicAvatarList from './index';

class ContainerAvatarList extends PureComponent {
    static propTypes = {
        _users: PropTypes.array.isRequired,
        users: PropTypes.array,
        getUsers: PropTypes.func.isRequired
    }

    static defaultProps = {
        users: []
    }

    state = {
        isLoading: true,
        users: []
    }

    componentDidMount() {
        const { _users, users, getUsers } = this.props;
        if (!users || (_users.length !== users.length)) {
            this.setState({
                isLoading: true
            }, getUsers);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if ((props.users.length && !state.users.length)
        || (props.users !== state.users)) {
            return {
                isLoading: false,
                users: props.users
            };
        }
        return null;
    }

    render() {
        return (
            <BasicAvatarList
                isLoading={this.state.isLoading}
                users={this.state.users}
                hasStatus={false}
            />
        );
    }

}

const mapStateToProps = (state, props) => ({
    users: !!props._users && getUsersByIds(props._users)(state)
});

const mapDispatchToProps = (dispatch, props) => ({
    getUsers: () => dispatch(requestGetUsers(props._users))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerAvatarList);
