import { connect } from 'react-redux';

import { selectors, utils, actions } from '@amplement/backend-connector';
import { getRoomFeatures } from 'selectors/room';

import MainParticipant from './index';

const StreamManager = utils.streamManager;

const mapStateToProps = (state, props) => {
    const { _room } = props;
    const features = getRoomFeatures(state, props._room);
    const hasDtmf = !!features?.dtmf;
    const member = hasDtmf ? 
        selectors.rooms.getRoomMembersWithoutMyDeviceByRoomIdSelector(state, _room)?.[0] :
        selectors.rooms.getFocusedUserByRoomId(state, _room);
    const focusedMember = utils.rooms.isJoinedOrHoldMember?.(member?.status) ? member : undefined;
    const { _client, avatarUri, isAudioEnabled, stream: streamProps, phonenumber, fullname, title } = focusedMember || {};
    const memberId = selectors.rooms.getRoomMemberByClientIdSelector(state, _room, _client);
    const { isMyUser } = memberId ||  {};

    const videoStream = StreamManager.getVideoStream({
        _client,
        _entity: _room,
        checkEnabled: false,
        takeLast: true
    });

    const audioStream = StreamManager.getAudioStream({
        _client,
        _entity: _room,
        checkEnabled: false
    });

    const audioTrack = audioStream?.getAudioTracks()?.[0];

    return {
        phonenumber,
        fullname,
        title,
        _client,
        avatar: avatarUri,
        hasDtmf,
        videoStream,
        updatedAt: streamProps?.updatedAt,
        isVideoOn: true, // streamProps?.hasVideo // experimental
        audioTrack,
        isAudioOn: isAudioEnabled,
        isMyUser
    }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onDtmfKeyPress: (digit) => dispatch(actions.rooms.sendDTMF(ownProps._room, digit))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainParticipant);
