import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* UI Component */
const StyledUserName = styled.div`
    color: #FFF;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    flex: 1 1;
    min-height: 0;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: flex-end;
    margin-right: 10px;
    transition: all 250ms ease;
`;

const UserNameComponent = ({ name }) => (
    <StyledUserName>
        {name}
    </StyledUserName>
);

UserNameComponent.propTypes = {
    name: PropTypes.string
};

UserNameComponent.defaultProps = {
    name: ''
};

const UserName = memo(UserNameComponent);

/* Stuctural Component */
const StyledThumb = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: calc(100% - 30px);
    padding: 0 10px;
    display: flex;
    align-items: flex-end;
    z-index: 5;
    justify-content: flex-end;
`;

const ThumbUserName = ({ name }) => (
    <StyledThumb className="user-name-container">
        <UserName name={name} />
    </StyledThumb>
);

ThumbUserName.propTypes = {
    name: PropTypes.string
};

ThumbUserName.defaultProps = {
    name: ''
};

export default memo(ThumbUserName);
