import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import AvatarStatus from 'components/Shared/User/Avatar';

import Timestamp from 'components/Shared/Notification/Timestamp';
import classNames from 'classnames';
import { NotificationStyle } from './styles';
import Button from '../Common/Button';

const NotificationLayout = ({
    intl,
    imageSrc,
    imageHref,
    imageAlt,
    date,
    children,
    thumb,
    actions,
    onIgnore,
    className
}) => (
    <NotificationStyle className={classNames('line', className)}>
        <div className="line-line">
            <div className="line-icon">
                {thumb || (
                    <AvatarStatus
                        src={imageSrc}
                        alt={imageAlt}
                        href={imageHref}
                        size="lg"
                        hasStatus={false}
                    />
                )}
            </div>
            <div className="line-text">
                {children}
                <Timestamp date={date} />
            </div>
            <div className="line-action">
                {actions}
                {onIgnore && (
                    <div className="item">
                        <Button
                            color="transparency"
                            tooltipText={intl.formatMessage({ id: 'global.button.ignore' })}
                            tooltipPosition="bottom"
                            iconName="X"
                            onClick={onIgnore}
                        />
                    </div>
                )}
            </div>
        </div>
    </NotificationStyle>
);

NotificationLayout.propTypes = {
    thumb: PropTypes.any,
    imageSrc: PropTypes.string,
    imageHref: PropTypes.string,
    imageAlt: PropTypes.string,
    date: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    actions: PropTypes.any,
    onIgnore: PropTypes.func,
    className: PropTypes.string,
};

NotificationLayout.defaultProps = {
    thumb: undefined,
    imageHref: undefined,
    imageSrc: undefined,
    imageAlt: undefined,
    onIgnore: undefined,
    actions: undefined,
    className: ''
};

export default memo(injectIntl(NotificationLayout));
