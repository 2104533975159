import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider } from 'antd';
import { rgba } from 'polished';

const Styled = styled(Divider)`
&.ant-divider{
    &-horizontal,
    &-with-text{
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        border-top-color: ${({theme}) => rgba(theme.error, .2)};
    }
}
.ant-divider{
    &-inner-text {
        color: ${({theme}) => theme.error};
        z-index: 1;
        padding: 2px 4px;
        font-size: 12px;
        font-weight: bold;
    }
`;

const UnreadSeparatorTemplate = ({
    intl,
    unreadCount,
}) => (
    <Styled>
        {intl.formatMessage({ id: 'feedItem.unread' }, { count: unreadCount || 1})}
    </Styled>
);

UnreadSeparatorTemplate.propTypes = {
    intl: PropTypes.any.isRequired,
    unreadCount: PropTypes.any,
};

UnreadSeparatorTemplate.defaultProps = {
    unreadCount: false
};

export default memo(injectIntl(UnreadSeparatorTemplate));
