import React, { memo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Tooltip from 'components/Shared/Common/Tooltip';
import Button from 'components/Shared/Common/Button';

type ActionButtonProps = WrappedComponentProps & {
    onClick?: () => void;
};

export const DeleteActionButton = injectIntl(memo<ActionButtonProps>(({
    intl,
    onClick
}) => (
    <Tooltip key="delete" title={intl.formatMessage({ id: 'global.button.remove' })}>
        <Button
            iconName="Trash2"
            onClick={onClick}
            color="danger"
        />
    </Tooltip>
)));

export const EditActionButton = injectIntl(memo<ActionButtonProps>(({
    intl,
    onClick
}) => (
    <Tooltip key="edit" title={intl.formatMessage({ id: 'global.button.edit' })}>
        <Button
            iconName="Edit2"
            onClick={onClick}
        />
    </Tooltip>
)));

export const ReplyActionButton = injectIntl(memo<ActionButtonProps>(({
    intl,
    onClick
}) => (
    <Tooltip key="reply" title={intl.formatMessage({ id: 'global.button.reply' })}>
        <Button
            iconName="CornerUpLeft"
            onClick={onClick}
        />
    </Tooltip>
)));
