import data from '@emoji-mart/data';
import { init, getEmojiDataFromNative } from 'emoji-mart';

init({ data });

const replaceStringWithEmojis = async (nativeString = '') => {
    const result = nativeString.split(' ');
    for(let x = 0; x < result.length; x++) {
        try {
            // eslint-disable-next-line no-await-in-loop
            const { emoticons, native } = await getEmojiDataFromNative(result[x]);
            if (emoticons) {
                for (let y = 0; y < emoticons.length; y++) {
                    result[x] = { value: result[x].replace(emoticons[y], `${native}`) };
                }
            }
        } catch (e) {
            // console.error(e);
        }
    }

    return result.map(r => r.value || r).join(' ');
}

export default replaceStringWithEmojis;
