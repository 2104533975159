import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from 'common/themes/Colors';
import { Row } from 'antd';

export const StyleHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    z-index: 2;
    transition: all 250ms ease;
    transform: translateY(0px);
    align-items: center;
    &.hide-interface {
        transform: translateY(-120px);
    }
`;

export const StyleHeaderInformationRoom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .avatar-group-name{
        height: 40px;
        width: 40px;
        margin-right: 20px;
        img{
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
    .group-name{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
        span{
            &.title,
            &.timestamp{
                font-size: 14px;
                font-weight: bold;
                color: ${rgba(colors.white, 0.7)};
            }
        }
    }
    .group-count{
        display: flex;
        flex-direction: row;
        color: ${colors.black};
        background-color: ${colors.white};
        padding: 5px 10px;
        border-radius: 30px;
        .icon{
            svg{
                width: 10px;
                height: 10px;
            }
        }
        .animation-counter{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: bold;
            font-size: 10px;
            span{
                display: block;
                width: 5px;
                height: 5px;
                background-color: red;
                border-radius: 50%;
                margin-right: 3px;
            }
            margin-right: 10px;
        }
    }
`;

export const StyleHeaderMeRoom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    transition: all 350ms ease;
    transform: translateX(0);
    &.folded{
        transform: translateX(140px);
    }
    .room-quality-network{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 50%;
        margin-right: 20px;
        transition: all 250ms ease;
        cursor: pointer;
        position: relative;
        .tooltip{
            opacity: 0;
            visibility: hidden;
            background-color: ${colors.bgRoom};
            color: ${colors.white};
            font-size: 10px;
            text-transform: uppercase;
            padding: 2px 5px;
            border-radius: 30px;
            position: absolute;
            right: 100%;
            top: 50%;
            white-space: nowrap;
            transform: translateY(-50%) translateX(-5px);
            transition: all 250ms ease;
        }
        &:hover{
            .tooltip{
                opacity: 1;
                visibility: visible;
                transform: translateY(-50%) translateX(-10px);
            }
        }
    }
`;

export const StyleRoomInformation = styled(Row)`
    border-radius: 30px;
    font-size: 14px;
    background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.1)};
    color: ${({ theme }) => theme.fixedWhite};
    padding: 5px 16px;
    .ant-space-item-split{
        .ant-divider{
            margin: 0 4px;
            border-color: ${({ theme }) => rgba(theme.black, 0.1)};
        }
    }
    .item{
        .icon{
            margin-right: 5px;
            svg{
                width: 14px;
                height: 14px;
            }
        }
    }
`;
