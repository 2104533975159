import { useQuery } from "react-query"
import { apis } from '@amplement/backend-connector';
import { enrichAccount } from "hooks/useSipAccount";

const useSipAccounts = () => {
    const result = useQuery(
        ['sip-accounts'],
        async () => {
            const response =  await apis.sip.$getAccounts();

            if (response?.data) {
                response.data.map(enrichAccount);

                return response.data;
            }


            return {};
        },
        { refetchOnWindowFocus: false, refetchOnMount: false, staleTime: 5000 }
    )

    result.isMyAccount = (sipUsername) => result.data?.some(account => account.sipUsername === sipUsername);

    return result;
}

export default useSipAccounts;
