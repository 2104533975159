import { connect } from 'react-redux';

import { DRAWER_INVITE_EXTERNAL_EMAILS } from 'types/drawer';
import { openDrawer } from 'actions/drawer';
import { getCompanySignUpStatus, getCompanyIsPublic } from 'selectors/company';

import Contacts from 'screens/Contacts';
import { MODAL_DELETE_RELATION } from 'types/modal';
import { openModal } from 'actions/modal';
import { selectors } from '@amplement/backend-connector';

const mapStateToProps = state => ({
    isSignUpAllowed: getCompanySignUpStatus(state),
    isPublic: getCompanyIsPublic(state),
    hasPhonebook: selectors.session.userFeaturesSelector(state)?.phoneBook,
});

const mapDispatchToProps = dispatch => ({
    onInvitBulk: () => dispatch(openDrawer(DRAWER_INVITE_EXTERNAL_EMAILS)),
    unlinkUser: _user => dispatch(openModal(MODAL_DELETE_RELATION, { _user }))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contacts);
