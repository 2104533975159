import { connect } from 'react-redux';

import { getCurrentUserSelector } from 'selectors/user';
import { selectors } from '@amplement/backend-connector';

import Component from './index';

const mapStateToProps = state => ({
    ...selectors.network.all(state),
    user: getCurrentUserSelector(state)
});

const mapDispatchToProps = () => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
