import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CallTabs from 'components/Shared/Drawers/Sip/CallTabs';

import { StyledPageLayout } from 'components/Shared/style';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';

const Screen = ({ intl }) => (
    <StyledPageLayout className="ui-contact">
        <CategoryTitle
            label={intl.formatMessage({ id: 'menuLeft.navigation.phone' })}
            icon={<Icon size="xl" iconName="Phone" />}
        />
        <CallTabs />
    </StyledPageLayout>
    
);

Screen.propTypes = {
    intl: PropTypes.any.isRequired,
};

Screen.defaultProps = {
};

export default memo(injectIntl(Screen));
