import React from 'react';

import BrandLogo from 'components/Shared/Company/BrandLogo/container';
import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const StyledLayout = styled.div`
    position: fixed;
    background-color: ${props => props.theme.mainColor1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-direction: column;
    opacity: 1;
    visibility: visible;
    transition: all 250ms ease;
`;

const StyledContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader-circle {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px ${({ theme }) => rgba(theme.fixedWlack || '#FFF', .1)};
        margin-left: -75px;
        margin-top: -75px;
    }
    .loader-line-mask {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 75px;
        height: 150px;
        margin-left: -75px;
        margin-top: -75px;
        overflow: hidden;
        transform-origin: 75px 75px;
        animation: ${rotate} 1.2s infinite linear;
        .loader-line {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px ${({ theme }) => rgba(theme.fixedWlack || '#FFF', .5)};
        }
    }
    .content{
        svg,
        img{
            width: 60px;
            height: auto;
        }
    }
`;

const FullscreenLoader = () => (
    <StyledLayout>
        <StyledContent>
            <div className="content">
                <div className="loader-circle">&nbsp;</div>
                <div className="loader-line-mask">
                    <div className="loader-line">&nbsp;</div>
                </div>
                <BrandLogo size="md" />
            </div>
        </StyledContent>
    </StyledLayout>
);

export default FullscreenLoader;
