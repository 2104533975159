import * as types from 'types/notification';

export const addNotification = notification => ({
    type: types.NOTIFICATION_ADD,
    notification
});

export const removeNotification = id => ({
    type: types.NOTIFICATION_REMOVE,
    id
});
