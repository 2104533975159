import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { injectIntl } from 'react-intl';

import useSipAccounts from 'hooks/useSipAccounts';
import { usePhonebookByUsername } from 'hooks/usePhonebook';

import { PhoneBookNumber, voiceMailFilterPhoneType } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Commons';

const { Text } = Typography;

const SummaryVoiceMail = ({ data: { context: username } = {}, intl }) => {
    const { isMyAccount } = useSipAccounts();
    const isMe = isMyAccount(username);
    const { data: phoneBookUser } = usePhonebookByUsername({ username, phoneFilterTypes: voiceMailFilterPhoneType, enabled: !isMe && !!username });

    return (
        <PhoneBookNumber 
            {...(phoneBookUser || {})} 
            emptyText={isMe ? intl.formatMessage({ id: 'call.forward.myVoicemail' }) : `voicemailUser(${username})`} 
        />
    );
};

SummaryVoiceMail.propTypes = {
    intl: PropTypes.any.isRequired,
    data: PropTypes.shape({
        context: PropTypes.string.isRequired
    }).isRequired
};

const SummaryNumber = ({ data: { to } = {} }) => (
    <Text type="success">{to}</Text>
);

SummaryNumber.propTypes = {
    data: PropTypes.shape({
        to: PropTypes.string.isRequired
    }).isRequired
};

const SummaryExtension = ({ data: { to: username } = {}, intl }) => {
    const { isMyAccount } = useSipAccounts();
    const isMe = isMyAccount(username);
    const { data: phoneBookUser } = usePhonebookByUsername({ username, phoneFilterTypes: voiceMailFilterPhoneType, enabled: !isMe && !!username });

    return (
        <PhoneBookNumber 
            {...(phoneBookUser || {})} 
            emptyText={isMe ? intl.formatMessage({ id: 'call.forward.myNumber' }) : `user(${username})`} 
        />
    );
};

SummaryExtension.propTypes = {
    intl: PropTypes.any.isRequired,
    data: PropTypes.shape({
        to: PropTypes.string.isRequired
    }).isRequired
};

const SummaryNone = ({ intl }) => (
    <Text type="success">
        {intl.formatMessage({ id: 'call.forward.redirectionTypeRequired' })}
    </Text>
);

SummaryNone.propTypes = {
    intl: PropTypes.any.isRequired,
};

export const ForwardSummaryVoiceMail = memo(injectIntl(SummaryVoiceMail));
export const ForwardSummaryNumber = memo(SummaryNumber);
export const ForwardSummaryExtension = memo(injectIntl(SummaryExtension));
export const ForwardSummaryNone = memo(injectIntl(SummaryNone));
