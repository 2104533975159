import { useMutation, useQuery, useQueryClient } from 'react-query';
import { message } from 'antd';
import { apis } from '@amplement/backend-connector';

const useForwardRules = ({ _sipAccount }) => {
    const result = useQuery(['forwardRules',  { _sipAccount }], async () => {
        const response = await apis.forwardRules.$getAllForwardsRules(_sipAccount);

        return response?.data || {};
    }, {
        enabled: !!_sipAccount,
        refetchOnWindowFocus: false,
        staleTime: 60000
    });

    return result;
}

export const usePostForwardRules = ({ _sipAccount, onSuccess }) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        data => apis.forwardRules.$postForwardRule(_sipAccount, data),
        {
            onSuccess: ({ data }) => {
                queryClient.setQueryData(["forwardRules", { _sipAccount }], previous => [...previous, data]);
                message.success({
                    key: 'callForwardPostSuccess',
                    content : 'Save success',
                    duration: 3,
                });
                onSuccess?.();
            },
            onError: (e) => {
                message.error({
                    key: 'callForwardPostError',
                    content : e.response?.data?.message || e.message,
                    duration: 5,
                });
            },
        });

    return mutation;
}

export const usePatchForwardRules = ({ _sipAccount, onSuccess }) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        ({ _forwardRule, payload }) => apis.forwardRules.$patchForwardRule(_sipAccount, _forwardRule, payload),
        {
            onSuccess: ({ data }) => {
                queryClient.setQueryData(
                    ["forwardRules", { _sipAccount }],
                    previous => previous.map(e => e.id === data.id ? data : e),
                );
                message.success({
                    key: 'callForwardPatchSuccess',
                    content : 'Update success',
                    duration: 3,
                });
                onSuccess?.();
            },
            onError: (e) => {
                message.error({
                    key: 'callForwardPatchError',
                    content : e.response?.data?.message || e.message,
                    duration: 5,
                });
            },
        });

    return mutation;
}

export const usePostBulkForwardRules = ({ _sipAccount }) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (payload) => apis.forwardRules.$postBulkForwardsRules(_sipAccount, payload),
        {
            onSuccess: ({ data, config }) => {
                if (data) {
                    queryClient.setQueryData(
                        ["forwardRules", { _sipAccount }],
                        previous => previous.map(e => {
                            const element = data.find(el => e.id === el.id);
                            if (element?.id) {
                                return element;
                            }

                            return e;
                        }),
                    );
                } else {
                    const { ids } = JSON.parse(config.data);
                    queryClient.setQueryData(
                        ["forwardRules", { _sipAccount }],
                        previous => previous.filter((e) => !ids.includes(e.id)),
                    );
                }
                message.success({
                    key: 'callForwardPostBulkSuccess',
                    content : 'Update success',
                    duration: 3,
                });
            },
            onError: (e) => {
                message.error({
                    key: 'callForwardPostBulkError',
                    content : e.response?.data?.message || e.message,
                    duration: 5,
                });
            },
        });

    return mutation;
}

export default useForwardRules;
