import { connect } from 'react-redux';

import { selectors, utils } from '@amplement/backend-connector';
import Participant from './index';

const StreamManager = utils.streamManager;

const mapStateToProps = (state, props) => {
    const { _client, _room, _user } = props;

    if (_user && !_client) {
        const member = selectors.rooms.getRoomMemberByUserIdSelector(state, _room, _user);
        const { isAdmin } = member || {};
    
        return {
            isAdmin,
            isAway: true
        }
    }

    const member = selectors.rooms.getRoomMemberByClientIdSelector(state, _room, _client);
    const { isMyUser, isAdmin, stream, isMe, isVideoEnabled, isAudioEnabled, isSharingScreen: isSharingScreenEnabled, hasConnectivityIssues } = member ||  {};
    const { hasVideo: isVideoOn, hasAudio: isAudioOn, isSharingScreen: isSharingScreenOn, updatedAt } = stream || {};
    const rooms = selectors.rooms.getCurrentByUserRoomsSelector(state);
    const audioPeerState = selectors.webRTC.getPcStateFromClient(state, _room, _client, 'audioLow');
    const videoPeerState = selectors.webRTC.getPcStateFromClient(state, _room, _client, 'videoHigh');
    const streamEvent = selectors.webRTC.getStreamFromClient(state, _room, _client, 'audioLow');

    const videoStream = StreamManager.getVideoStream({
        _client,
        _entity: _room,
        checkEnabled: false,
        takeLast: true
    });
    const audioStream = StreamManager.getAudioStream({
        _client,
        _entity: _room,
        checkEnabled: false
    });

    const audioTrack = audioStream?.getAudioTracks()?.[0];

    return {
        isVideoOn: (isSharingScreenOn && isSharingScreenEnabled) || (isVideoOn && isVideoEnabled),
        isAudioOn: isAudioOn && isAudioEnabled,
        audioTrack, // juste for rerendering when audio source is changed
        videoStream,
        audioStream,
        forceMute: !!isMe || !member?._client, // isMe === undefined until join is triggered
        isAdmin,
        isMe,
        isMyUser,
        hasConnectivityIssues,
        streamEvent,
        _room,
        /**
         * B appel A en room audio (unicast ou multicast)
         * A appel un 06 (sip)
         * A raccroche avec B en cliquant sur la croix de l'onglet
         * A n'entend plus le 06 -> obligé de re-render
         * pour déclancher ce render, on recup un updatedAt lorsqu'on a un WS_ROOM_DELETE provenant de l'ancienne room détruite
         */
        updatedAt: updatedAt + (rooms?.length || 0),
        audioPeerState,
        videoPeerState
    }
};

export default connect(
    mapStateToProps
)(Participant);
