/* eslint-disable */
import React from 'react';

export default props => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="headphones-off" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="headphones" transform="translate(1.000000, 1.000000)"></g>
            <path d="M1,14.0000001 L1,10.0000001 C1,5.0294373 5.0294373,1.00000013 10,1.00000013 C11.0534361,1.00000013 12.0646002,1.18098808 13.0040935,1.51356522 M17.7399043,5.40445861 C18.5402996,6.74960002 19,8.32111568 19,10.0000001 L19,16.0000001" id="Shape" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M19,17 C19,18.1045695 18.1045695,19 17,19 L16,19 C14.8954305,19 14,18.1045695 14,17 L14,14 C14,12.8954305 14.8954305,12 16,12 L19,12 L19,17 Z M3,12 L1,12 L1,14" id="Shape" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M1.58925417,17.431 C1.26381947,17.1055676 1.26381761,16.5779356 1.58925,16.2525009 C1.58925084,16.2525 1.58925169,16.2524992 1.58925253,16.2524984 L16.9099121,0.931862054 C17.2353463,0.606428265 17.7629792,0.606428664 18.0884129,0.931862946 C18.0884142,0.931864187 18.0884154,0.931865427 18.0884167,0.931866667 C18.413851,1.25730564 18.4138489,1.78494223 18.0884119,2.11037857 L2.76776101,17.4309985 C2.44232531,17.7564336 1.91469069,17.7564342 1.58925417,17.431 Z" id="Path" fill="#EB144C" fillRule="nonzero"></path>
        </g>
    </svg>
);
