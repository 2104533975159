import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeCancelable } from 'utils/cancelablePromise';
import { processFile } from 'utils/file';
import { FILE_TEMPLATES, FILE_TYPES } from 'const/feed';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'react-fast-compare';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import Logger from 'services/debug/logger';
import Carousel from './Carousel';
import CarouselModal from './CarouselModal';

const logger = new Logger('components:feeditems:FileUploadManager');

const FileUploadManager = (props) => {
    const {
        feedItemFiles,
        onClose,
        visible,
        onUpload,
        setFeedItemFiles,
    } = props;
    let cancelablePromise;
    useWhyDidYouUpdate('components:feeditems:FileUploadManager', props);

    const handleSubmit = useCallback((items, compress) => {
        logger.log('handleSubmit', items, compress);
        const p = items.map(item => {
            const content = item.content ? item.content : undefined;
            const rawItem = ({ content, metasFile: item.raw, fileSize: item.raw.size });

            if (item.type === FILE_TYPES.IMAGE) {
                logger.log('handleSubmit:image', items);
                return processFile(item.raw, compress ? FILE_TEMPLATES.COMPRESSED : FILE_TEMPLATES.RAW)
                    .then(result => ({ content, metasFile: result.output, fileSize: result.output.size }))
                    .catch(() => rawItem);
            }

            logger.log('handleSubmit:rawFile', item);
            logger.log('handleSubmit:rawItem', rawItem);
            return Promise.resolve(rawItem);
        })
        cancelablePromise = makeCancelable(Promise.all(p));
        cancelablePromise.promise.then(results => onUpload(results))

        onClose();
    }, [cancelablePromise, processFile, onUpload]);

    const handleClose = useCallback(() => {
        logger.log('handleClose');
        if (cancelablePromise) {
            cancelablePromise.cancel();
        }
        onClose();
    }, [cancelablePromise, onClose]);

    const handleRemoveFiles = useCallback((ids) => {
        logger.log('handleRemoveFile', ids);
        if (ids) {
            const toRemove = Array.isArray(ids) ? ids : [ids];
            setFeedItemFiles(feedItemFiles.filter(f => toRemove.indexOf(f.name) === -1));
        }
    }, [feedItemFiles, setFeedItemFiles]);

    const handleAddFiles = useCallback((filesToAdd) => {
        logger.log('handleAddFiles', filesToAdd);
        const fileUploadQueue = uniqBy(union(feedItemFiles, filesToAdd), 'name');

        setFeedItemFiles(fileUploadQueue);
    }, [feedItemFiles, setFeedItemFiles]);

    return (
        <Carousel
            visible={visible}
            files={feedItemFiles}
            maxUploadCount={30}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            allowCompression={false}
            onAddFileToUpload={handleAddFiles}
            removeFileToUpload={handleRemoveFiles}
        >
            {(childProps) => (
                <CarouselModal
                    {...childProps}
                    visible={visible}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                />
            )}
        </Carousel>
    );
}

FileUploadManager.propTypes = {
    feedItemFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    visible: PropTypes.bool.isRequired,
    onUpload: PropTypes.func.isRequired,
    setFeedItemFiles: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

FileUploadManager.defaultProps = {
};

export default memo(FileUploadManager, isEqual);
