import fallbackAvatar from 'statics/images/fallbackAvatar.svg';

const getFallbackAvatar = () => fallbackAvatar;

export const getAvatarUrl = (user, height = null, width = null) => {
    const { avatar, avatarUri } = user || {};
    const uri = avatarUri || avatar?.file?.uri;

    if (!uri) {
        return getFallbackAvatar();
    }

    return (!height || !width) ? uri : `${uri}?h=${height}&w=${width}`;
};
