import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ActivitiesItem from 'components/Dashboard/MissedInfos/ActivitiesItem';
import ActivityCreator from 'components/Dashboard/MissedInfos/ActivityCreator';
import DashboardBlock from 'components/Dashboard/DashboardBlock';
import Placeholder from 'components/Shared/Common/Placeholder';

class MissedInfos extends PureComponent {
    // Todo : handle useless rendering
    static propTypes = {
        activities: PropTypes.array,
        intl: PropTypes.any.isRequired,
        isLoading: PropTypes.bool,
        _currentUser: PropTypes.string.isRequired
    }

    static defaultProps = {
        activities: [],
        isLoading: false
    }

    renderActivities = feedItem => (
        <ActivitiesItem
            feedItem={feedItem}
            key={feedItem.id}
            isEllipsed
            isMe={this.props._currentUser === (feedItem.user || {}).id}
            noBlock
            deleteActivity={this.props.deleteActivity}
            editActivity={this.props.editActivity}
        />
    );

    render() {
        const { intl, activities, isLoading } = this.props;

        return (
            <DashboardBlock
                title={intl.formatMessage({
                    id: 'dashboard.whatYouMissed.title',
                    defaultMessage: 'Activités de votre réseau'
                })}
                iconName="Activity"
                content={(
                    <div className="dashboard-items-content">
                        <ActivityCreator />
                        <div className="dashboard nobloc">
                            {activities.map(this.renderActivities)}
                        </div>
                    </div>
                )}
                placeholder={(
                    <>
                        <ActivityCreator />
                        <Placeholder
                            title={intl.formatMessage({
                                id: 'dashboard.whatYouMissed.placeholder.title',
                                defaultMessage: 'Vous découvrez ici les activités de votre entourage'
                            })}
                            subTitle={intl.formatMessage({
                                id: 'dashboard.whatYouMissed.placeholder.subtitle',
                                defaultMessage: 'Il n\'y a pas encore eu d\'activités'
                            })}
                            name="placeholderConversations"
                        />
                    </>
                )}
                itemsLength={activities.length}
                isLoading={isLoading}
            />
        );
    }
}

export default injectIntl(MissedInfos);
