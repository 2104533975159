/* eslint-disable no-bitwise */
/* eslint-disable consistent-return */
import Logger from 'services/debug/logger';
import { fsBilateralFilter, fsFinalCompositionLightWrapping, fsFusion, fsLightWrapping, fsSource, fsSourceBlur, fsSourceGaussianBlur, fsSourceGaussianHorizontalBlur, fsSourceGaussianVerticalBlur, vsSource } from "./shaders";

const logger = new Logger('webgl:setup');

// Init WebGL
export function initWebGL(canvas) {
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) {
        logger.warn('Unable to initialize WebGL. Your browser may not support it.');
        return null;
    }
    return gl;
}

function compileShader(gl, source, type, retries = 3) {
    function tryCompile() {
        const shader = gl.createShader(type);
        if (!shader) {
            logger.error('An error occurred creating the shaders.');
            return null;
        }

        gl.shaderSource(shader, source);
        gl.compileShader(shader);

        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            logger.error(`An error occurred compiling the shaders: ${gl.getShaderInfoLog(shader)}`);
            gl.deleteShader(shader);
            return null;
        }

        return shader;
    }

    let shader = tryCompile();
    let attempts = 1;

    while (!shader && attempts < retries) {
        logger.warn(`Retrying shader compilation (${attempts + 1}/${retries})`);
        shader = tryCompile();
        attempts++;
    }

    if (!shader) {
        logger.error('Shader compilation failed after several attempts.');
    }

    return shader;
}

export function prepareRenderShaderProgram(gl, vertexShader, fragmentShaderSource, retries = 3) {
    const fragmentShader = compileShader(gl, fragmentShaderSource, gl.FRAGMENT_SHADER, retries);
    if (!fragmentShader) {
        logger.error('Fragment shader compilation failed.');
        return null;
    }

    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        logger.error(`Unable to initialize the shader program: ${gl.getProgramInfoLog(shaderProgram)}`);
        return null;
    }

    return shaderProgram;
}

export function prepareRenderShaders(gl) {
    const vertexShader = compileShader(gl, vsSource, gl.VERTEX_SHADER);
    if (!vertexShader) {
        logger.error('Vertex shader compilation failed.');
        return;
    }

    const shaderProgramNormal = prepareRenderShaderProgram(gl, vertexShader, fsSource);
    const shaderProgramFusionBlur = prepareRenderShaderProgram(gl, vertexShader, fsFusion);
    const shaderProgramBilateralFilter = prepareRenderShaderProgram(gl, vertexShader, fsBilateralFilter);
    const shaderProgramLightWrapping = prepareRenderShaderProgram(gl, vertexShader, fsLightWrapping);
    const shaderProgramFinalComposition = prepareRenderShaderProgram(gl, vertexShader, fsFinalCompositionLightWrapping);

    // Blur
    const shaderProgramBlur = prepareRenderShaderProgram(gl, vertexShader, fsSourceBlur);

    // Gaussian blur
    const shaderProgramGaussianBlur = prepareRenderShaderProgram(gl, vertexShader, fsSourceGaussianBlur);
    const shaderProgramGaussianVerticalBlur = prepareRenderShaderProgram(gl, vertexShader, fsSourceGaussianVerticalBlur);
    const shaderProgramGaussianHorizontalBlur = prepareRenderShaderProgram(gl, vertexShader, fsSourceGaussianHorizontalBlur);

    return {
        shaderProgramNormal,
        shaderProgramBlur,
        shaderProgramFusionBlur,
        shaderProgramBilateralFilter,
        shaderProgramLightWrapping,
        shaderProgramFinalComposition,
        shaderProgramGaussianBlur,
        shaderProgramGaussianVerticalBlur,
        shaderProgramGaussianHorizontalBlur,
    };
};
export function initFrameBuffer(gl, texture, shaderProgram, buffers, video, small) {
    const maxRetries = 3;
    let retries = 0;

    function configureFrameBuffer() {
        // Clean resources before retrying
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.bindRenderbuffer(gl.RENDERBUFFER, null);

        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer); // eslint-disable-line
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

        const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
        if (status !== gl.FRAMEBUFFER_COMPLETE) {
            logger.error(`Erreur dans la configuration du FBO: ${status}`);
            retries++;
            if (retries < maxRetries) {
                logger.warn(`Retrying framebuffer configuration (${retries}/${maxRetries})`);
                configureFrameBuffer();
            } else {
                logger.error('Échec de la configuration du FBO après plusieurs tentatives');
                return { error: 'Échec de la configuration du FBO après plusieurs tentatives' };
            }
        } else {
            finalizeFrameBuffer(); // eslint-disable-line
        }
    }

    function finalizeFrameBuffer() {
        gl.bindBuffer(gl.ARRAY_BUFFER, buffers.vertex);
        const vertexPositionAttributeLocation = gl.getAttribLocation(shaderProgram, 'aVertexPosition');
        if (vertexPositionAttributeLocation < 0) {
            logger.error('Failed to get the storage location of aVertexPosition');
            return { error: 'Failed to get the storage location of aVertexPosition' };
        }
        gl.vertexAttribPointer(vertexPositionAttributeLocation, 2, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(vertexPositionAttributeLocation);

        gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
        const textureCoordAttributeLocation = gl.getAttribLocation(shaderProgram, 'aTextureCoord');
        if (textureCoordAttributeLocation < 0) {
            logger.error('Failed to get the storage location of aTextureCoord');
            return { error: 'Failed to get the storage location of aTextureCoord' };
        }
        gl.vertexAttribPointer(textureCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(textureCoordAttributeLocation);

        if (small) {
            gl.viewport(0, 0, video.videoWidth / 8, video.videoHeight / 8);
        } else {
            gl.viewport(0, 0, video.videoWidth, video.videoHeight);
        }

        gl.clearColor(0.0, 0.0, 0.0, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }

    // Initialisation du framebuffer
    const framebuffer = gl.createFramebuffer();
    configureFrameBuffer();
}

export function setupBuffers(gl, buffers, shaderProgram) {
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.vertex);
    const vertexPosition = gl.getAttribLocation(shaderProgram, 'aVertexPosition');
    if (vertexPosition < 0) {
        logger.error('Failed to get the storage location of aVertexPosition');
        return;
    }
    gl.vertexAttribPointer(vertexPosition, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(vertexPosition);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    const textureCoord = gl.getAttribLocation(shaderProgram, 'aTextureCoord');
    if (textureCoord < 0) {
        logger.error('Failed to get the storage location of aTextureCoord');
        return;
    }
    gl.vertexAttribPointer(textureCoord, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(textureCoord);
}

export function useLightWrappingShader(gl, normalTexture, blurTexture, maskTexture, fusionProgram, buffers) {
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    gl.useProgram(fusionProgram);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, normalTexture);
    gl.uniform1i(gl.getUniformLocation(fusionProgram, 'uForegroundTexture'), 0);

    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, blurTexture);
    gl.uniform1i(gl.getUniformLocation(fusionProgram, 'uBackgroundTexture'), 1);

    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, maskTexture);
    gl.uniform1i(gl.getUniformLocation(fusionProgram, 'uMaskTexture'), 2);

    setupBuffers(gl, buffers, fusionProgram);
}

export function bindShaderProgramFusionBlurBuffers(gl, buffers, shaderProgramFusionBlur) {
    gl.uniform1i(gl.getUniformLocation(shaderProgramFusionBlur, "uNormalTexture"), 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.vertex);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgramFusionBlur, "aVertexPosition"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgramFusionBlur, "aVertexPosition"));

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgramFusionBlur, "aTextureCoord"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgramFusionBlur, "aTextureCoord"));
}

export function createTexture(gl, small) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    if (small) {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.canvas.width / 8, gl.canvas.height / 8, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    } else {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.canvas.width, gl.canvas.height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    }
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    return texture;
}

export function updateTexture(gl, texture, video) {
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, video);
}

export function updateTextureSize(gl, texture, width, height) {
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
}

export function updateTexturesSizes(gl, width, height, textures) {
    textures.forEach(texture => updateTextureSize(gl, texture, width, height));
}

export function updateVideoTexture(gl, video, texture) {
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, video);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
}

export function applyLightWrappingShader(gl, foregroundTexture, backgroundTexture, maskTexture, shaderProgramLightWrapping, buffers) {
    gl.useProgram(shaderProgramLightWrapping);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, foregroundTexture);
    gl.uniform1i(gl.getUniformLocation(shaderProgramLightWrapping, 'uForegroundTexture'), 0);

    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, maskTexture);
    gl.uniform1i(gl.getUniformLocation(shaderProgramLightWrapping, 'uMaskTexture'), 1);

    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, backgroundTexture);
    gl.uniform1i(gl.getUniformLocation(shaderProgramLightWrapping, 'uBackgroundTexture'), 2);

    setupBuffers(gl, buffers, shaderProgramLightWrapping);

    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
}

export function useFinalCompositionShader(gl, normalTexture, lightWrappingTexture, shaderProgramFinalComposition, buffers) {
    gl.useProgram(shaderProgramFinalComposition);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, normalTexture);
    gl.uniform1i(gl.getUniformLocation(shaderProgramFinalComposition, 'uNormalTexture'), 0);

    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, lightWrappingTexture);
    gl.uniform1i(gl.getUniformLocation(shaderProgramFinalComposition, 'uLightWrappingTexture'), 1);

    setupBuffers(gl, buffers, shaderProgramFinalComposition);

    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
}

function applyGaussianBlur(gl, fbo, texture, textureNormal, maskTexture, sourceTexture, shaderProgram, buffers, video, blurStrength) {
    gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
    gl.useProgram(shaderProgram);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, textureNormal);
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, sourceTexture);
    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, maskTexture);

    const samplerLocation = gl.getUniformLocation(shaderProgram, "uForegroundSampler");
    const simpleBackgroundLocation = gl.getUniformLocation(shaderProgram, "uBackgroundSampler");
    const maskSamplerLocation = gl.getUniformLocation(shaderProgram, "uMaskSampler");
    const blurSizeLocation = gl.getUniformLocation(shaderProgram, 'blurSize');
    gl.uniform1i(samplerLocation, 0);
    gl.uniform1i(simpleBackgroundLocation, 1);
    gl.uniform1i(maskSamplerLocation, 2);
    gl.uniform1f(blurSizeLocation, 1.0 / blurStrength);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.vertex);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgram, "aVertexPosition"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgram, "aVertexPosition"));

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgram, "aTextureCoord"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgram, "aTextureCoord"));

    gl.viewport(0, 0, video.videoWidth, video.videoHeight);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}

function applyResizeBlur(gl, normalResizeFbo, resizeTexture, blurTexture, video, shaderProgramNormal) {
    gl.bindFramebuffer(gl.FRAMEBUFFER, normalResizeFbo);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, resizeTexture, 0);
    gl.useProgram(shaderProgramNormal);
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, blurTexture);

    gl.viewport(0, 0, video.videoWidth, video.videoHeight);

    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}

function finalComposition(gl, textureNormal, blurredTexture, maskTexture, buffers, video, shaderProgramFusionBlur) {
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, textureNormal);
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, blurredTexture);
    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, maskTexture);

    const blurSamplerLocation = gl.getUniformLocation(shaderProgramFusionBlur, "uBlurTexture");
    const maskSamplerLocation = gl.getUniformLocation(shaderProgramFusionBlur, "uMaskTexture");
    gl.uniform1i(blurSamplerLocation, 1);
    gl.uniform1i(maskSamplerLocation, 2);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.vertex);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgramFusionBlur, "aVertexPosition"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgramFusionBlur, "aVertexPosition"));

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgramFusionBlur, "aTextureCoord"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgramFusionBlur, "aTextureCoord"));

    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
}

export function bindBlurAndMaskTextures({
    gl,
    blurTexture,
    maskTexture,
    segmentationMask,
    shaderProgramBlur,
    shaderProgramFusionBlur,
    buffers,
    textureNormal,
    blurFbo,
    video,
    blurStrength = 150.0,
    gaussBlurHorizontalFbo,
    gaussBlurHorizontalTexture,
    gaussBlurVerticalFbo,
    gaussBlurVerticalTexture,
    shaderProgramGaussianHorizontalBlur,
    shaderProgramGaussianVerticalBlur,
    shaderProgramNormal,
    normalResizeFbo,
    resizeTexture
}) {
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, video.videoWidth / 8, video.videoHeight / 8);

    gl.useProgram(shaderProgramBlur);
    updateTexture(gl, textureNormal, video);
    updateTexture(gl, blurTexture, video);
    updateTexture(gl, maskTexture, segmentationMask);

    const maskLocation = gl.getUniformLocation(shaderProgramBlur, "uMaskSampler");
    gl.uniform1i(maskLocation, 2);

    const blurSizeLocation = gl.getUniformLocation(shaderProgramBlur, 'blurSize');
    gl.uniform1f(blurSizeLocation, 1.0 / blurStrength);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.vertex);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgramBlur, "aVertexPosition"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgramBlur, "aVertexPosition"));

    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    gl.vertexAttribPointer(gl.getAttribLocation(shaderProgramBlur, "aTextureCoord"), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(gl.getAttribLocation(shaderProgramBlur, "aTextureCoord"));

    gl.bindFramebuffer(gl.FRAMEBUFFER, blurFbo);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, blurTexture, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    applyResizeBlur(gl, normalResizeFbo, resizeTexture, blurTexture, video, shaderProgramNormal);

    applyGaussianBlur(gl, gaussBlurHorizontalFbo, gaussBlurHorizontalTexture, textureNormal, maskTexture, resizeTexture, shaderProgramGaussianHorizontalBlur, buffers, video, blurStrength);
    applyGaussianBlur(gl, gaussBlurVerticalFbo, gaussBlurVerticalTexture, textureNormal, maskTexture, gaussBlurHorizontalTexture, shaderProgramGaussianVerticalBlur, buffers, video, blurStrength);

    gl.useProgram(shaderProgramFusionBlur);
    finalComposition(gl, textureNormal, gaussBlurVerticalTexture, maskTexture, buffers, video, shaderProgramFusionBlur);
};

export function drawSegmentationTextures({
    gl,
    buffers,
    video, segmentationMask,
    overlayType,
    blurStrength,
    textureNormal, blurTexture, maskTexture, resizeTexture,
    gaussBlurHorizontalTexture, gaussBlurVerticalTexture,
    shaderProgramNormal, shaderProgramBlur, shaderProgramFusionBlur,
    shaderProgramGaussianHorizontalBlur, shaderProgramGaussianVerticalBlur,
    blurFbo,
    gaussBlurHorizontalFbo, gaussBlurVerticalFbo,
    normalResizeFbo,
}) {
    if (overlayType === 'blur') {
        bindBlurAndMaskTextures({
            gl,
            blurTexture,
            maskTexture,
            segmentationMask,
            shaderProgramBlur,
            shaderProgramFusionBlur,
            buffers,
            textureNormal,
            blurFbo,
            video,
            blurStrength,
            gaussBlurHorizontalFbo,
            gaussBlurHorizontalTexture,
            gaussBlurVerticalFbo,
            gaussBlurVerticalTexture,
            shaderProgramGaussianHorizontalBlur,
            shaderProgramGaussianVerticalBlur,
            shaderProgramNormal,
            normalResizeFbo,
            resizeTexture
        });
    }
}

export function initBuffers(gl, shaderProgram) {
    const vertices = new Float32Array([
        -1.0,  1.0,
        1.0,  1.0,
        -1.0, -1.0,
        1.0, -1.0,
    ]);

    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    const vertexPosition = gl.getAttribLocation(shaderProgram, 'aVertexPosition');
    if (vertexPosition < 0) {
        logger.error('Failed to get the storage location of aVertexPosition');
    }
    gl.vertexAttribPointer(vertexPosition, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(vertexPosition);

    const textureCoordinates = new Float32Array([
        0.0,  0.0,
        1.0,  0.0,
        0.0,  1.0,
        1.0,  1.0,
    ]);

    const textureCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, textureCoordinates, gl.STATIC_DRAW);

    const textureCoord = gl.getAttribLocation(shaderProgram, 'aTextureCoord');
    if (textureCoord < 0) {
        logger.error('Failed to get the storage location of aTextureCoord');
    }
    gl.vertexAttribPointer(textureCoord, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(textureCoord);

    return {
        vertex: vertexBuffer,
        textureCoord: textureCoordBuffer,
    };
}

export function cleanupWebGLResources(gl, resources) {
    resources.textures.forEach(texture => {
        if (texture) gl.deleteTexture(texture)
    });
    resources.framebuffers.forEach(fbo => {
        if (fbo) gl.deleteFramebuffer(fbo)
    });
    resources.programShaders.forEach(shader => {
        if (shader) gl.deleteProgram(shader)
    });
};
