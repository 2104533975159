import Logger from 'services/debug/logger';
import { utils } from '@amplement/backend-connector';
import { Howl } from 'howler';
import config from 'config';

const logger = new Logger('service:SoundManager');

// fix to load sound, overwise sound will be 404
// since sound are dynamically imported, webpack has not sounds loaded
// take from testing instead
// eslint-disable-next-line no-undef
const basePath = __webpack_public_path__ === '/' && config.isLocalEnv ? 'https://cdn-gra-testing.amplement.io/' : __webpack_public_path__;

const sounds = [
    { name: 'chat' },
    { name: 'delete' },
    { name: 'pop', canPlayInOpenedWindow: true },
    { name: 'ding', canPlayInOpenedWindow: true },
    { name: 'incomingRinging', loop: true },
    { name: 'outgoingRinging', loop: true, canPlayInOpenedWindow: true, volume: 0.2 },
    { name: '0', canPlayInOpenedWindow: true },
    { name: '1', canPlayInOpenedWindow: true },
    { name: '2', canPlayInOpenedWindow: true },
    { name: '3', canPlayInOpenedWindow: true },
    { name: '4', canPlayInOpenedWindow: true },
    { name: '5', canPlayInOpenedWindow: true },
    { name: '6', canPlayInOpenedWindow: true },
    { name: '7', canPlayInOpenedWindow: true },
    { name: '8', canPlayInOpenedWindow: true },
    { name: '9', canPlayInOpenedWindow: true },
    { name: 'asterisk', canPlayInOpenedWindow: true },
    { name: 'pound', canPlayInOpenedWindow: true }
];

const createUrl = (path, name) => {
    const rand = new Date().valueOf();
    return ['mp3', 'ogg', 'aac'].map(ext => `${path + encodeURIComponent(name)}.${ext}?${rand}`);
}

class SoundManager {
    #snds = {};

    // no sound if call window (ROOM or SIP)
    canPlay = (name) =>
        !window.opener || !!sounds.find(sound => sound.name === name)?.canPlayInOpenedWindow;

    isPlaying = (name) => this.#snds[name]?.playing();

    play = (name, abortTimeout) => {
        logger.warn('play', name, abortTimeout)

        if (!this.canPlay(name)) {
            return;
        }
        
        if (!this.#snds[name]) {
            const url = createUrl(`${basePath}statics/sounds/`, name);
            const conf = sounds.find(sound => sound.name === name);
            const sound = new Howl({
                src: url,
                autoplay: false,
                loop: !!conf?.loop,
                volume: conf?.volume || 1.0,
                // onend() {
                //     logger.warn('Son terminé', url);
                // }
            });
            this.#snds[name] = sound;
        }

        this.#snds[name].play();
    }

    stop = (name) => {
        const sound = this.#snds[name];

        if (sound) {
            logger.log('stop', name);
            sound?.stop();
        } else {
            logger.warn('Cannot stop sound that is not started', name);
        }

    }
}

export default utils.debug.addDebugAttribute(new SoundManager(), 'SoundManager');
