import { useEffect, useState } from 'react';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import { utils } from '@amplement/backend-connector';

const StreamManager = utils.streamManager;

export default (kind, deviceId) => {
    const [stream, setStream] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        let p;

        if (deviceId) {
            p = DeviceManager.getStream({ [kind]: deviceId })
                .then((s) => {
                    setStream(s);
                    setError('');
                    return s;
                })
                .catch((e) => {
                    setError(e.id);
                });
        }
        
        return () => {
            if (p) p.then(s => StreamManager.setStreamTrackEnabled(s, kind, false, true))
        }
    }, [deviceId]);

    return [stream, error];
}
