import { connect } from 'react-redux';

import { closePreview } from 'actions/preview';
import { getFileSource } from 'utils/file';
import { getUserByIdSelector } from 'selectors/user';
import PreviewBasic from './index';

const mapStateToProps = (state, {
    feedItem: {
        _user,
        metasFile,
        createdAt
    }
}) => {
    const user = getUserByIdSelector(state, _user);
    const {
        lastname,
        firstname,
        avatar,
        avatarUri,
    } = user || {};
    const { name, uri, width, height } = metasFile || {};

    return {
        lastname,
        firstname,
        avatar: avatarUri || avatar,
        name,
        createdAt,
        file: getFileSource(uri, false),
        downloadUrl: getFileSource(uri, true),
        width,
        height
    };
};

const mapDispatchToProps = (dispatch, { onClose }) => ({
    onClose: () => onClose ? onClose() : dispatch(closePreview())
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewBasic);
