import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Rate, Typography, Col, Space, message } from 'antd';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import Analytics from 'utils/analytics';
import { useSelector } from 'react-redux';
import { selectors } from '@amplement/backend-connector';
import { getCurrentClientIdSelector } from 'selectors/user';

const StyledStars = styled.div`
    margin-bottom: 20px;
    border-radius: 6px;
`;

const { Paragraph } = Typography;
const removeMargin = { margin: 0 };
const sizeStars = { fontSize: 30 };

const Rating = ({ intl }) => {
    const _room = useSelector(selectors.rooms._currentRoomSelector);
    const _client = useSelector(getCurrentClientIdSelector);

    const [rating, setRating] = useState();
    const [hasVoted, setHasVoted] = useState(false);


    const ratingLabels = useMemo(() => ({
        1: intl.formatMessage({ id: 'room.rating.vote.level.one' }),
        2: intl.formatMessage({ id: 'room.rating.vote.level.two' }),
        3: intl.formatMessage({ id: 'room.rating.vote.level.three' }),
        4: intl.formatMessage({ id: 'room.rating.vote.level.four' }),
        5: intl.formatMessage({ id: 'room.rating.vote.level.five' })
    }), [intl]);

    const translations = useMemo(() => ({
        content: intl.formatMessage({ id: 'notifications.rating.vote' }),
        qualityCheck: intl.formatMessage({ id: 'room.rating.quality.check' })
    }), [intl]);

    const handleStartMsg = useCallback(
        debounce(() => {
            message.success({
                key: 'ratingCallSuccess',
                content: translations.content,
                duration: 1,
            });
        }, 250),[]
    );

    const handleRateChange = useCallback((newRating) => {
        if (!hasVoted) { 
            setRating(newRating);
            handleStartMsg(newRating);
            if(newRating > 0){
                Analytics.logEvent('call_rating', {
                    note: newRating,
                    _room,
                    _client
                });
            }
            setHasVoted(true);
        }
    }, [handleStartMsg, hasVoted]);

    const selectedLabel = useMemo(() => ratingLabels?.[rating], [ratingLabels, rating]);

    useEffect(() => () => {
        handleStartMsg?.cancel();
    }, [handleStartMsg]);

    return (
        <StyledStars className="ui-stars">
            <Col align="middle">
                <Space direction="vertical">
                    <Paragraph style={removeMargin} strong>
                        {translations.qualityCheck}
                    </Paragraph>
                    <Rate 
                        onChange={handleRateChange} 
                        value={rating} 
                        style={sizeStars} 
                        disabled={hasVoted}
                    />
                    <Paragraph type="secondary" style={removeMargin}>
                        {selectedLabel}
                    </Paragraph>
                </Space>
            </Col>
        </StyledStars>
    );
};

export default memo(injectIntl(Rating));
