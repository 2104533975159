export const vsSource = `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;
varying vec2 vTextureCoord;

void main() {
    gl_Position = aVertexPosition;
    vTextureCoord = aTextureCoord;
}
`;

export const fsSource = `
precision mediump float;
uniform sampler2D uSampler;
varying vec2 vTextureCoord;

void main() {
    vec2 flippedCoord = vec2(vTextureCoord.x, 1.0 - vTextureCoord.y);
    gl_FragColor = texture2D(uSampler, flippedCoord);
}
`;

export const fsSourceGaussianBlur = `
precision mediump float;

uniform sampler2D uSampler;
uniform float blurSize;
varying highp vec2 vTextureCoord;

const float pi = 3.1415926535897932384626433832795;

float gaussian(float x, float sigma) {
    return exp(-(x * x) / (2.0 * sigma * sigma)) / (2.0 * pi * sigma * sigma);
}

void main(void) {
    vec4 sum = vec4(0.0);
    float totalWeight = 0.0;
    float sigma = 2.0;

    vec2 flippedCoord = vec2(vTextureCoord.x, 1.0 - vTextureCoord.y);

    for (int x = -4; x <= 4; x++) {
        for (int y = -4; y <= 4; y++) {
            float weight = gaussian(float(x), sigma) * gaussian(float(y), sigma);
            vec2 samplePos = flippedCoord + vec2(x, y) * blurSize;
            sum += texture2D(uSampler, samplePos) * weight;
            totalWeight += weight;
        }
    }

    gl_FragColor = sum / totalWeight;
}
`;

export const fsSourceBlur = `
precision highp float;

uniform sampler2D uBackgroundSampler;
uniform sampler2D uForegroundSampler;
uniform sampler2D uMaskSampler;
uniform float blurSize;
varying highp vec2 vTextureCoord;

void main(void) {
    vec2 flippedCoord = vec2(vTextureCoord.x, 1.0 - vTextureCoord.y);
    vec4 bgColor = texture2D(uBackgroundSampler, flippedCoord);
    vec4 fgColor = texture2D(uForegroundSampler, flippedCoord);
    float maskValue = texture2D(uMaskSampler, flippedCoord).r;

    vec4 sum = vec4(0.0);
    float totalWeight = 0.0;

    if (maskValue < 0.5) {
        for (int x = -7; x <= 7; x++) {
            for (int y = -7; y <= 7; y++) {
                vec2 samplePos = flippedCoord + vec2(float(x), float(y)) * blurSize;
                float sampleMaskValue = texture2D(uMaskSampler, samplePos).r;
                float weight = sampleMaskValue < 0.5 ? 1.0 : 0.0;
                vec2 clampedCoord = clamp(samplePos, 0.0, 1.0);
                sum += texture2D(uBackgroundSampler, clampedCoord) * weight;
                totalWeight += weight;
            }
        }
    }

    if (totalWeight > 0.0) {
        bgColor = sum / totalWeight;
    }

    gl_FragColor = mix(bgColor, fgColor, maskValue);
}
`;

export const fsSourceGaussianHorizontalBlur = `
precision highp float;

uniform sampler2D uBackgroundSampler;
uniform sampler2D uForegroundSampler;
uniform sampler2D uMaskSampler;
uniform float blurSize;
varying highp vec2 vTextureCoord;

const float pi = 3.1415926535897932384626433832795;

float gaussian(float x, float sigma) {
    return exp(-(x * x) / (2.0 * sigma * sigma)) / (2.0 * pi * sigma * sigma);
}

vec4 horizontalBlur() {
    vec2 flippedCoord = vec2(vTextureCoord.x, 1.0 - vTextureCoord.y);
    vec4 sum = vec4(0.0);
    float totalWeight = 0.0;
    float sigma = 8.0;
    float maskValue = texture2D(uMaskSampler, flippedCoord).r;

    float edgeSmoothness = 0.3;
    float smoothFactor = smoothstep(0.5 - edgeSmoothness, 0.5 + edgeSmoothness, maskValue);

    if (maskValue < 0.5) {
        for (int x = -5; x <= 5; x++) {
            float weight = gaussian(float(x), sigma);
            vec2 offset = vec2(blurSize * float(x), 0.0);
            vec2 samplePos = flippedCoord + offset;
            float sampleMaskValue = texture2D(uMaskSampler, samplePos).r;
            if (sampleMaskValue < 0.5) {
                vec2 clampedCoord = clamp(samplePos, 0.0, 1.0);
                sum += texture2D(uBackgroundSampler, clampedCoord) * weight;
                totalWeight += weight;
            }
        }
    }

    if (totalWeight > 0.0) {
        vec4 backgroundBlur = sum / totalWeight;
        vec4 foregroundSharp = texture2D(uForegroundSampler, flippedCoord);
        return mix(backgroundBlur, foregroundSharp, smoothFactor);
    } else {
        return texture2D(uForegroundSampler, flippedCoord);
    }
}

void main() {
    gl_FragColor = horizontalBlur();
}
`;

export const fsSourceGaussianVerticalBlur = `
precision highp float;

uniform sampler2D uBackgroundSampler;
uniform sampler2D uForegroundSampler;
uniform sampler2D uMaskSampler;
uniform float blurSize;
varying highp vec2 vTextureCoord;

const float pi = 3.1415926535897932384626433832795;

float gaussian(float x, float sigma) {
    return exp(-(x * x) / (2.0 * sigma * sigma)) / (2.0 * pi * sigma * sigma);
}

vec4 verticalBlur() {
    vec2 flippedCoord = vec2(vTextureCoord.x, 1.0 - vTextureCoord.y);
    vec4 sum = vec4(0.0);
    float totalWeight = 0.0;
    float sigma = 8.0;
    float maskValue = texture2D(uMaskSampler, flippedCoord).r;

    float edgeSmoothness = 0.0;
    float smoothFactor = smoothstep(0.5 - edgeSmoothness, 0.5 + edgeSmoothness, maskValue);

    if (maskValue < 0.5) {
        for (int y = -5; y <= 5; y++) {
            float weight = gaussian(float(y), sigma);
            vec2 offset = vec2(blurSize * float(y), 0.0);
            vec2 samplePos = flippedCoord + offset;
            float sampleMaskValue = texture2D(uMaskSampler, samplePos).r;
            if (sampleMaskValue < 0.5) {
                vec2 clampedCoord = clamp(samplePos, 0.0, 1.0);
                sum += texture2D(uBackgroundSampler, clampedCoord) * weight;
                totalWeight += weight;
            }
        }
    }

    if (totalWeight > 0.0) {
        vec4 backgroundBlur = sum / totalWeight;
        vec4 foregroundSharp = texture2D(uForegroundSampler, flippedCoord);
        return mix(backgroundBlur, foregroundSharp, smoothFactor);
    } else {
        return texture2D(uForegroundSampler, flippedCoord);
    }
}

void main() {
    gl_FragColor = verticalBlur();
}
`;

export const fsFusion = `
precision highp float;

uniform sampler2D uNormalTexture;
uniform sampler2D uBlurTexture;
uniform sampler2D uMaskTexture;

varying vec2 vTextureCoord;

void main() {
    float mask = texture2D(uMaskTexture, vTextureCoord).r;
    vec4 normalColor = texture2D(uNormalTexture, vTextureCoord);
    vec4 blurColor = texture2D(uBlurTexture, vTextureCoord);
    gl_FragColor = mix(blurColor, normalColor, mask);
}
`;

export const fsBilateralFilter = `
precision mediump float;

uniform sampler2D uSampler;
varying highp vec2 vTextureCoord;

void main(void) {
    float blurSize = 1.0 / 300.0;
    vec4 sum = vec4(0.0);

    for (int x = -3; x <= 3; x++) {
        for (int y = -3; y <= 3; y++) {
            vec2 samplePos = vTextureCoord + vec2(x, y) * blurSize;
            sum += texture2D(uSampler, samplePos);
        }
    }

    gl_FragColor = sum / 49.0;
}
`;

export const fsLightWrapping = `
precision mediump float;

uniform sampler2D uForegroundTexture;
uniform sampler2D uBackgroundTexture;
uniform sampler2D uMaskTexture;

varying vec2 vTextureCoord;

void main() {
    vec2 flippedCoord = vec2(vTextureCoord.x, 1.0 - vTextureCoord.y);

    vec4 fgColor = texture2D(uForegroundTexture, flippedCoord);
    vec4 bgColor = texture2D(uBackgroundTexture, flippedCoord);

    float mask = texture2D(uMaskTexture, flippedCoord).r;
    float edgeBlur = smoothstep(0.45, 0.55, mask);

    vec4 wrappedLight = mix(bgColor, fgColor, edgeBlur);

    gl_FragColor = mix(wrappedLight, fgColor, step(0.5, mask));
}
`;

export const fsFinalCompositionLightWrapping = `
precision mediump float;

uniform sampler2D uNormalTexture;
uniform sampler2D uLightWrappingTexture;
varying vec2 vTextureCoord;

void main() {
    vec4 normalColor = texture2D(uNormalTexture, vTextureCoord);
    vec4 lightWrapColor = texture2D(uLightWrappingTexture, vTextureCoord);

    gl_FragColor = (normalColor + lightWrapColor) * 0.5;
}
`;
