import React, { ReactNode, useContext } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import BrandLogo from 'components/Shared/Company/BrandLogo/container';
import styled, { ThemeContext } from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';
import { Alert, Typography, Space } from 'antd';
import { StyledBgApp } from 'components/Shared/style';

const SPACE_BETWEEN_WRAPPER = 24;
const styleSpacer = { width: '100%'};

type StyledLayoutProps= {
    backgroundColor: string;
}

type RequestPermissionLayoutProps = { 
    children?: ReactNode | string; 
    player?: ReactNode | string;
    onRequest?: () => void;
    missingPermissions?: { audio?: string, video?: string }
} & WrappedComponentProps;

type ContentLayoutProps = { 
    children: ReactNode | string; 
    footer: ReactNode | string;
    player: ReactNode | string;
    direction: 'horizontal';
} & WrappedComponentProps;

type LayoutProps = {
    size?: '' | 'sm' | null,
    title?: string,
    children?: ReactNode | string,
} & WrappedComponentProps;

type LayoutContext = {
    mainColor1: string, 
    backgroundImage: string
}

const StyledLayout = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    min-height: 0;
    min-width: 0;
    background-color: ${(props: StyledLayoutProps) => props.backgroundColor};
    overflow: hidden;
    z-index: 2;
`;

const StyledView = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    position: relative;
    min-height: 200px;
    max-height: 200px;
    margin-bottom: 20px;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => rgba(theme.black, .1)};
    width: 100%;
    flex-shrink: 0;
    &:after{
        content: "";
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, ${({ theme }) => rgba(theme.fixedBlack, .3)} 0%, ${({ theme }) => rgba(theme.fixedBlack, 0)}; 100%);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .ant-alert{
        z-index: 2;
    }
    video,
    .room-preset-video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const StyledWrapperContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex: 1;
`;

const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${({ theme }) => rgba(theme.black, .2)};
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 40px 20px 40px;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.black, .1)};
    border-radius: 6px;
    overflow: auto;
    @media (max-width: 768px) {
        padding: 20px;
        margin: 0 20px;
    }
    .container{
        display: flex;
        flex-direction: row;
        .ant-space-item{
            width: 100%;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            margin-right: 0;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

const StyledContent = styled.div`
    .select{
        max-width: 100%;
    }
    .content{
        .select{
            &.ant-select{
                border: none;
            }
        }
        >.group-input{
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            label{
                font-size: 10px;
                font-weight: 700;
                margin-bottom: 5px;
                text-transform: uppercase;
            }
            .groups-input{
                display: flex;
                flex-direction: row;
            }
        }
        .groups-input{
            margin-bottom: 30px;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            .btn,
            .equalizer,
            .animated-btn{
                margin-left: 10px;
            }
            .ant-select{
                display: flex!important;
                width: 100%;
            }
            .ant-select-selection{
                display: flex!important;
                width: 100%;
            }
            .ant-select-selection__rendered{
                display: flex!important;
            }
            .ant-select-selection-selected-value{
                white-space: normal!important;
            }
            &:last-child{
                margin-bottom: 0px;
            }
            >.select{
                flex: 1;
                margin: 0;
                margin-right: 10px;
            }
            .group-input{
                margin-bottom: 0;
                min-width: 0;
                width: 100%;
                align-self: stretch;
            }
        }   
    }
`;
const StyledWrapper = styled.div`
    position: relative;
    z-index: 1;
    &.preset{
        display: flex;
        max-width: 800px;
        width: 100%;
        margin: 0px auto 20px;
        flex-direction: column;
        &.sm{
            max-width: 500px;
        }
    }
`;

export const ContentLayout = ({ children, footer, player, direction }: ContentLayoutProps) => (
    <Space 
        size={SPACE_BETWEEN_WRAPPER}
        direction={direction}
        align={direction === "horizontal" ? "start" : undefined}
        style={styleSpacer}
    >
        {player && (
            <StyledWrapperContent>
                <StyledView>
                    {player}
                </StyledView>
            </StyledWrapperContent>
        )}
        <StyledWrapperContent>
            <StyledContent>
                <div className="content">
                    {children}
                </div>
            </StyledContent>
            {footer && (
                <StyledFooter>
                    {footer}
                </StyledFooter>
            )}
        </StyledWrapperContent>
    </Space>
);

ContentLayout.propTypes = {
    children: PropTypes.any.isRequired,
    footer: PropTypes.any,
    player: PropTypes.any,
    direction: PropTypes.string,
};

ContentLayout.defaultProps = {
    player: undefined,
    footer: undefined,
    direction: 'vertical',
};

const StyledBrandLogo = styled.div`
    justify-content: center;
    display: flex;
    max-width: 200px;
    width: 100%;
    margin: 50px auto;
    height: 50px;
    align-items: flex-start;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: flex-start;
        align-self: flex-start;
        object-fit: contain;
        object-position: center center;    
    }
`;

export const RequestPermissionLayout = injectIntl(({ intl, children, player, onRequest, missingPermissions }: RequestPermissionLayoutProps) => (
    <ContentLayout
        player={player}
        intl={intl}
        direction="horizontal"
        footer={onRequest && (
            <Button
                color="primary"
                size="lg"
                iconName="ArrowUpLeft"
                onClick={onRequest}
            >
                {intl.formatMessage({ id: 'rtc.modal.requestPermission.button' })}
            </Button>
        )}
    >
        {children}
        {
            missingPermissions &&
            missingPermissions.audio &&
            <Alert
                type="error"
                message={intl.formatMessage({ id: 'rtc.modal.requestPermission.video.missing' })}
            />
        }
        {
            missingPermissions &&
            missingPermissions.video &&
            <Alert
                type="error"
                message={intl.formatMessage({ id: 'rtc.modal.requestPermission.video.missing' })}
            />
        }
    </ContentLayout>
));

RequestPermissionLayout.propTypes = {
    children: PropTypes.any,
    player: PropTypes.any,
    onRequest: PropTypes.func,
    missingPermissions: PropTypes.object,
};

RequestPermissionLayout.defaultProps = {
    children: null,
    player: undefined,
    onRequest: undefined,
    missingPermissions: undefined
};

const titleStyle = { fontSize: 16, marginBottom: 20 };

const LayoutComponent: React.FC<LayoutProps> = ({ intl, size, title, children } : LayoutProps) => {
    const { mainColor1, backgroundImage }: LayoutContext = useContext(ThemeContext);

    return (
        <StyledLayout 
            className="requirement" 
            backgroundColor={mainColor1}
        >
            <StyledBgApp 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /*
                // @ts-ignore */
                backgroundColor={mainColor1}
                backgroundImage={backgroundImage}
            >
                <div className="bg" />
                <span className="overlay" />
            </StyledBgApp>
            <StyledWrapper 
                className={classNames(
                    'preset',
                    size
                )}
            >
                <StyledBrandLogo>
                    <BrandLogo size="md" type="shortLogo" />
                </StyledBrandLogo>
                <StyledContainer className="room-preset-wrapper">
                    {title || (
                        <Typography.Text strong style={titleStyle}>
                            {intl.formatMessage({ id: 'rtc.modal.global.video.title' })}
                        </Typography.Text>
                    )}
                    <div className="container">
                        {children}
                    </div>
                </StyledContainer>
            </StyledWrapper>
        </StyledLayout>
    );
};

LayoutComponent.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    size: PropTypes.oneOf([ '', 'sm' ]),
    intl: PropTypes.any.isRequired
};

LayoutComponent.defaultProps = {
    children: null,
    size: '',
    title: undefined
};

export const Layout = injectIntl(LayoutComponent);
