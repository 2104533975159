import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

const ToggleSelectLabel = ({ checked, intl }) => (
    <div className="label">
        {intl.formatMessage({ id: checked ? 'global.button.unselectAll' : 'global.button.selectAll' })}
    </div>
);

ToggleSelectLabel.propTypes = {
    checked: PropTypes.bool,
    intl: PropTypes.any.isRequired,
};

ToggleSelectLabel.defaultProps = {
    checked: false
};

export default memo(injectIntl(ToggleSelectLabel));
