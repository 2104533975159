import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import useSipAccounts from 'hooks/useSipAccounts';

import ManualForwards from 'components/Shared/Drawers/Sip/Forwards/ManualForwards';
import TimeBasedForwards from 'components/Shared/Drawers/Sip/Forwards/TimeBasedForwards';
import NavButtons from 'components/Shared/Common/NavButtons';

const NAVIGATION = {
    MANUAL_FORWARDS: 'MANUAL_FORWARDS',
    TIME_BASED_FORWARDS: 'TIME_BASED_FORWARDS'
};

const style = { minHeight: '50vh' };

const getNavButtons = (intl, navigation, setNavigation) => [
    {
        type: 'link',
        children: intl.formatMessage({ id: 'call.tabs.forwards.manual' }),
        disabled: navigation === NAVIGATION.MANUAL_FORWARDS,
        onClick: () => setNavigation(NAVIGATION.MANUAL_FORWARDS)
    },
    {
        type: 'link',
        children: intl.formatMessage({ id: 'call.tabs.forwards.timeBased' }),
        disabled: navigation === NAVIGATION.TIME_BASED_FORWARDS,
        onClick: () => setNavigation(NAVIGATION.TIME_BASED_FORWARDS)
    }
];

const ForwardsWrapper = ({ intl }) => {
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const [navigation, setNavigation] = useState(NAVIGATION.MANUAL_FORWARDS);
    const navButtons = useMemo(() => getNavButtons(intl, navigation, setNavigation), [intl, navigation]);

    return (
        <div style={style}>
            <NavButtons buttons={navButtons} />
            {navigation === NAVIGATION.MANUAL_FORWARDS && <ManualForwards _sipAccount={_sipAccount} />}
            {navigation === NAVIGATION.TIME_BASED_FORWARDS && <TimeBasedForwards _sipAccount={_sipAccount} />}
        </div>
    );
};

ForwardsWrapper.propTypes = {
    intl: PropTypes.any.isRequired
};

export default injectIntl(ForwardsWrapper);
