import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';
import StyleParticipant from '../Participant/styles';

const StyleListParticipants = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 280px);
    flex-shrink: 0;
    position: relative;
    transition: all 550ms ease;
    transform: translateX(0);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -20px;
    padding-right: 20px;
    &::-webkit-scrollbar {
        width: 10px;
        &-track {
            border-radius: 4px;
            background: ${rgba(colors.black, 0.15)};
        }
        &-thumb {
            border-radius: 4px;
            background: ${rgba(colors.black, 0.35)};
            &:hover {
                background: ${rgba(colors.black, 0.45)};
            }
        }
    }
    ${({ folded }) => folded && `
        transform: translateX(350px);
    `}
    ${StyleParticipant}{
        margin-bottom: 10px;
        .video{
            object-fit: cover;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
`;

const ParticipantList = ({ isOpen, children, className }) => (
    <StyleListParticipants
        folded={isOpen}
        className={className}
    >
        {children}
    </StyleListParticipants>
);

ParticipantList.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};

ParticipantList.defaultProps = {
    className: ''
};

export default ParticipantList;
