import { connect } from 'react-redux';
import { getCurrentUserSelector } from 'selectors/user';
import { setMediaDevicePermissionState } from 'actions/roomSettings';
import Component from './index';

const mapStateToProps = (state) => ({
    user: getCurrentUserSelector(state),
});

const mapDispatchToProps = dispatch => ({
    setMediaDevicePermissionState: (state) => dispatch(setMediaDevicePermissionState(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
