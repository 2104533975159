import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { Typography, Row, Pagination, Grid } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import Icon from 'components/Shared/Common/Icon';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from 'styled-components';

import useSearch from 'hooks/useSearch';

import FileList from 'components/FileList';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import { StyledPageLayout } from 'components/Shared/style';
import injectValidators from 'components/Shared/Forms/injectValidators';
import { StyledInput } from 'components/Shared/Forms/styles';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const StyledText = styled(Text)`
    margin-top: 5px;
`;

function getCurrentBreakpoint(screens) {
    const breakpoints = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

    return breakpoints.find(bp => screens[bp]) || null;
}

// get page size options based on current breakpoint
function getPageSizeOptions(screens) {
    switch (getCurrentBreakpoint(screens)) {
        case 'xs':
        case 'sm':
            return [10, 20];
        case 'md':
            return [10, 20];
        case 'lg':
            return [12, 18];
        case 'xl':
            return [12, 20];
        case 'xxl':
            return [12, 18];
        default:
            return [12, 18];
    }
}

const StyledFileResults = styled.div`
    margin: 20px 0;
    font-size: 12px;
`;
const StyledRow = styled(Row)`
    width: 100%;
`;

const Files = ({ intl, validators }: { validators } & WrappedComponentProps) => {
    const screens = useBreakpoint();
    const [pageSizeOptions, setPageSizeOptions] = useState<number[]>(getPageSizeOptions(screens));
    const [searchValue, setSearchValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
    const [limit, setLimit] = useState<number>(pageSizeOptions[0]);
    const warningMessage = useMemo(() => validators.searchLength(debouncedSearchValue), [debouncedSearchValue, validators]);
    const { isLoading, data: filesResults = {} } = useSearch({
        query: warningMessage ? '' : debouncedSearchValue,
        filters: ['feedItems'],
        subFilters: { feedItemType: 'file' },
        page,
        limit,
    });

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setPage(1);
    }, []);

    const onShowSizeChange = (_, pageSize) => setLimit(pageSize);

    useDebounce(() => setDebouncedSearchValue(searchValue), 200, [searchValue]);

    useEffect(() => {
        const newOptions = getPageSizeOptions(screens);
        setPageSizeOptions(newOptions);
        setLimit(newOptions[0]);
    }, [screens]);

    return (
        <StyledPageLayout className="ui-contact">
            <CategoryTitle
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label={intl.formatMessage<string>({ id: 'menuLeft.navigation.files' })}
                icon={<Icon size="xl" iconName="Paperclip" />}
            />
            <StyledInput
                placeholder={intl.formatMessage({ id: 'search.files' })}
                prefix={<SearchOutlined />}
                onChange={onInputChange}
                value={searchValue}
            />
            {warningMessage && <StyledText type="danger">{warningMessage}</StyledText>}
            <StyledFileResults>
                <Text strong type="secondary">
                    {filesResults?.feedItems?.paging?.total || 0} {intl.formatMessage({ id: 'menuLeft.navigation.files' }).toLowerCase()}
                </Text>
            </StyledFileResults>
            {isLoading && <LoadingOutlined />}
            {!isLoading && filesResults?.feedItems && <FileList {...filesResults} />}
            {!!filesResults?.feedItems?.results?.length && (
                <StyledRow align="middle" justify="center" wrap={false}>
                    <Pagination
                        current={page}
                        onChange={setPage}
                        total={filesResults?.feedItems?.paging.total || 1}
                        onShowSizeChange={onShowSizeChange}
                        pageSizeOptions={pageSizeOptions}
                        pageSize={limit}
                    />
                </StyledRow>
            )}
        </StyledPageLayout>
    );
}

export default injectValidators(injectIntl(Files));
