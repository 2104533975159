import React from 'react';
import config from 'config';

type GenericErrorProps = {
    error: Error;
    actions: React.ReactNode;
    ErrorComponent: React.FC<{content: string, subtitle: string, code?: number, actions: React.ReactNode}>;
}

function GenericError({ error, actions, ErrorComponent }: GenericErrorProps) {
    const {
        name,
        message,
        stack
    } = error || {};
    
    return (
        <ErrorComponent
            // id="error.default"
            content={`${message} (${name})`}
            subtitle={config.debug && stack && JSON.stringify(stack)}
            actions={actions}
        />
    );
}

export default GenericError;
