import { connect } from 'react-redux';

import { openModal } from 'actions/modal';
import { MODAL_KICK_ROOM_USER } from 'types/modal';
import { actions, selectors, constants } from '@amplement/backend-connector';
import { getAwayRoomMemberIdsWithoutMyUserByRoomIdSelector } from 'selectors/room';
import RoomScreen from './index';

const mapStateToProps = (state, ownProps) => {
    const { _room } = ownProps;
    const room = selectors.rooms.getRoomByIdSelector(state, _room);
    const _onlineClients = selectors.rooms.getConnectedRoomMemberClientIdsWithoutMeByRoomIdSelector(state, _room);
    const _awayUsers = getAwayRoomMemberIdsWithoutMyUserByRoomIdSelector(state, _room);
    const isSimpleMode = !room?.feature?.video && ((_onlineClients?.length || 0) + (_awayUsers?.length || 0) <= 2);
    const isHold = selectors.rooms.getMyClientAsARoomMemberSelector(state, _room)?.status === constants.rooms.USER_IN_ROOM_STATUS.HOLD;

    return {
        isFeatureDtmf: room?.features?.dtmf,
        _initiator: room?.caller?._user,
        _onlineClients,
        _awayUsers,
        isSimpleMode,
        isFeatureFeed: room?.features?.feed,
        displayMode: room?.displayMode,
        isHold
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onKick: _user => dispatch(openModal(MODAL_KICK_ROOM_USER, { _user, _room: ownProps._room })),
    onFocusMember: _client => dispatch(actions.rooms.setClientFocusTo(ownProps._room, _client, true)),
    onUnfocusAllMember: () => dispatch(actions.rooms.clearClientFocus(ownProps._room, true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoomScreen);
