export const inviteExternalEmailForm = 'inviteExternalEmailForm';
export const inviteContactRoom = 'inviteContactRoom';
export const phoneForm = 'phoneForm';
export const sipSettingsForm = 'sipSettingsForm';
export const generalSettingsForm = 'generalSettingsForm';
export const notificationSettingsForm = 'notificationSettingsForm';
export const resetEmailForm = 'resetEmailForm';
export const resetPasswordForm = 'resetPasswordForm';
export const feedInformationsForm = 'feedInformationsForm';
export const addContactForm = 'addContactForm';
export const roomReceptionForm = 'roomReceptionForm';
export const profileForm = 'profileForm';
export const editGroupForm = 'editGroupForm';
export const roomSettingsForm = 'roomSettingsForm';
export const activityCreateForm = 'activityCreateForm';
export const feedItemCreateForm = 'feedItemCreateForm';
export const updateFeedItemForm = 'updateFeedItemForm';
export const voiceMailSelectorForm = 'voiceMailSelectorForm';
