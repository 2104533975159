import React from 'react';

import { Switch, Drawer, Checkbox, Radio, Input, Tabs, Select } from 'antd';
import { Comment } from '@ant-design/compatible';

const { TextArea } = Input;

import styled, { keyframes } from 'styled-components';
import { colors } from 'common/themes/Colors';
import { rgba, darken, lighten } from 'polished';
import { isDarkTheme } from 'utils/colors';

const TOASTER_HEIGHT = 40;

const bgAn4 = keyframes`
    0% {
        width: 291.66666667px;
        height: 291.66666667px;
    }
    96% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 291.66666667px;
        height: 291.66666667px;
    }
`;

const move = keyframes`
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
`;

const pulsar = theme => keyframes`
    0% {
        box-shadow: 0 0 0 0 ${rgba(theme.black, 0.6)};
    }
    70% {
        box-shadow: 0 0 0 20px ${rgba(theme.black, 0)};
    }
    100% {
        box-shadow: 0 0 0 0 ${rgba(theme.black, 0)};
    }
`;

const bgAn3 = keyframes`
    0% {
        width: 250px;
        height: 250px;
    }
    87% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 250px;
        height: 250px;
    }
`;

const bgAn2 = keyframes`
    0% {
        width: 208.33333333px;
        height: 208.33333333px;
    }
    78% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 208.33333333px;
        height: 208.33333333px;
    }
`;

const bgAn1 = keyframes`
    0% {
        width: 166.66666667px;
        height: 166.66666667px;
    }
    69% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 166.66666667px;
        height: 166.66666667px;
    }
`;

export const StyleHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(0);
    padding: 20px;
    z-index: 2;
    transition: all 250ms ease;
`;

export const StylePanelError = styled.div`
    padding: 15px;
    background-color: #FF4040;
    border-radius: 4px;
    margin: 20px 0;
    .text{
        font-size: 14px;
        color: ${({ theme }) => theme.fixedWhite};
    }
`;

export const StyleFooterAction = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 5px;
    flex-direction: row;
    bottom: 0;
    left: 0;
    transform: translateY(0);
    transition: all 250ms ease;
    z-index: 2;
    &.flexend{
        justify-content: flex-end;
    }
    &.center{
        justify-content: center;
    }
    .footer-container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        &.centered{
            position: absolute;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .button-container{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-right: 12px;
        width: 50px;
        &.pos-abs-right{
            position: absolute;
            right: 20px;
        }
        &:last-child{
            margin-right: 0;
        }
    }
`;

const getColumnCount = participant => {
    if(participant <= 2) return 1;
    if(participant > 2 && participant <= 4) return 2;
    if(participant > 4 && participant <= 7) return 3;
    if(participant > 7 && participant <= 13) return 4;
    if(participant > 13) return 6;
};

const SIZE_MESSAGE_PANEL = 400;

export const StyledReply = styled.div`
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-left: 46px;
    margin-top: 10px;
    &:before{
        --avatar-size: 32px;
        --gutter: 9px;
        --spine-width: 2px;
        content: "";
        display: block;
        position: absolute;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        top: 50%;
        right: 100%;
        bottom: 0;
        left: calc(var(--avatar-size)/2*-1 + var(--gutter)*-1);
        margin-right: var(--reply-spacing);
        margin-top: calc(var(--spine-width)*-1/2);
        margin-left: calc(var(--spine-width)*-1/2);
        margin-bottom: calc(.125rem - 4px);
        border-left: var(--spine-width) solid ${({theme}) => lighten(.90, theme.black)};
        border-bottom: 0 solid ${({theme}) => lighten(.90, theme.black)};
        border-right: 0 solid ${({theme}) => lighten(.90, theme.black)};
        border-top: var(--spine-width) solid ${({theme}) => lighten(.90, theme.black)};
        border-top-left-radius: 6px;
    }
    .ant-avatar{
        width: 16px;
        height: 16px;
        margin-left: 2px;
        margin-right: 5px;
        flex-shrink: 0;
    }
    .author,
    .text{
        font-size: 12px;
        color: ${({theme}) => rgba(theme.black, .6)};
        white-space: nowrap;
    }
    .author{
        font-weight: bold;
        margin-right: 5px;
        transition: all 250ms ease;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
            color: ${({theme}) => rgba(theme.black, .8)};
        }
    }
    .text{
        transition: all 250ms ease;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover{
            color: ${({theme}) => theme.black};
        }
    }
`;

const highlight = keyframes`
    0% {
        background-color: ${({theme}) => rgba(theme.infos, 0.25)};
    }
    100% {
        background-color: ${({theme}) => rgba(theme.infos, 0.05)};
    }
`
export const StyledComment = styled(Comment)`
    transition: all .2s ease-in-out;
    border-radius: 4px;
    margin-top: 10px;
    position: relative;
    display: flex;
    flex-shrink: 0;
    &.has-replied{
        margin-top: 0;
    }
    &.no-avatar{
        margin-top: 0;
        min-height: 1.375rem;
        &:hover{
            .ant-comment{
                &-content{
                    &-author{
                        .meta-date{
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(-50%) translateX(-3px);
                        }
                        .meta-edit{
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }
            }
        }
        .ant-comment{
            &-inner{
                padding-top: 0;
            }
            &-content{
                padding-left: 42px;
                &-author{
                    position: absolute;
                    left: 3px;
                    bottom: 1px;
                    transition: all .2s ease;
                    .meta-date,
                    .meta-edit{
                        position: absolute;
                        transform: translateY(-55%) translateX(0);
                        left: 0;
                        transition: all 250ms ease;
                    }
                    .meta-date{
                        opacity: 0;
                        visibility: hidden;
                        font-size: 9px;
                        bottom: -5px;
                    }
                    .meta-edit{
                        opacity: 1;
                        transform: translateY(-9px) translateX(0);
                        visibility: visible;
                    }
                }
            }
        }
    }
    &:hover{
        background-color: ${({theme}) => isDarkTheme(theme) ? lighten(.05, theme.bg.global) : darken(.03, theme.bg.global)};
        .ant-comment{
            &-actions{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.recording{
        background-color: ${({theme}) => rgba(theme.success, 0.05)};
    }
    &.endRecording{
        background-color: ${({theme}) => rgba(theme.error, 0.05)};
    }
    &.joining,
    &.leaving,
    &.editing,
    &.replied,
    &.feedAction{
        &:before{
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            content: "";
        }
    }
    &.replied{
        background-color: ${({theme}) => rgba(theme.infos, 0.05)};
        animation: ${highlight} 1000ms ease;
        &:before{
            background-color: ${({theme}) => theme.infos};
        }
    }
    &.editing{
        background-color: ${({theme}) => rgba(theme.feedItem.bgEdit,.3)};
        &:before{
            background-color: ${({theme}) => darken(0.2, theme.feedItem.bgEdit)};
        }
    }
    &.joining,
    &.leaving,
    &.feedAction{
        .ant-comment-content-detail{
            .content{
                font-size: 12px;
            }
        }
    }
    &.feedAction{
        background-color: ${({theme}) => theme.feedItem.bgFeedAction};
        .ant-comment-avatar{
            .s-status{
                border-color: ${({theme}) => theme.feedItem.bgFeedAction};
            }
        }
        &:before{
            background-color: ${({theme}) => darken(0.2, theme.feedItem.bgFeedAction)};
        }
    }
    &.joining{
        background-color: ${({theme}) => theme.feedItem.bgJoin};
        .ant-comment-avatar{
            .s-status{
                border-color: ${({theme}) => theme.feedItem.bgJoin};
            }
        }
        &:before{
            background-color: ${({theme}) => theme.success};
        }
    }
    &.leaving{
        background-color: ${({theme}) => theme.feedItem.bgLeave};
        .ant-comment-avatar{
            .s-status{
                border-color: ${({theme}) => theme.feedItem.bgLeave};
            }
        }
        &:before{
            background-color: ${({theme}) => theme.error};
        }
    }
    .ant-comment{
        &-actions{
            position: absolute;
            top: -15px;
            right: 10px;
            margin: 0;
            border-radius: 4px;
            background-color: ${({theme}) => theme.white};
            border: 1px solid ${({theme}) => isDarkTheme(theme) ? lighten(.08, theme.white) : lighten(.9, theme.black)};
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: all 250ms ease;
            opacity: 0;
            visibility: hidden;
            &:hover{
                border-color: ${({theme}) => isDarkTheme(theme) ? lighten(.2, theme.white) : lighten(.8, theme.black)};
                box-shadow: 0 2px 4px ${({theme}) => rgba(theme.black, .1)};
            }
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 250ms ease;
                cursor: pointer;
                color: ${({theme}) => rgba(theme.black, .6)};
                margin: 0;
                &:first-child{
                    .button, .btn{
                        border-radius: 4px 0 0 4px;
                    }
                }
                &:last-child{
                    .button, .btn{
                        border-radius: 0px 4px 4px 0;
                    }
                }
                .button, .btn{
                    &:hover{
                        background-color: ${({theme}) => isDarkTheme(theme) ? lighten(.10, theme.white) : lighten(.94, theme.black)}!important;
                        color: ${({theme}) => theme.black};
                        &.danger{
                            background-color: ${({theme}) => rgba(theme.error, .1)}!important;
                        }
                    }
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 0px;
                    &.danger{
                        color: ${({theme}) => theme.error};
                        background-color: transparent;
                    }
                }
                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        &-inner{
            padding: 10px 5px 3px;
            padding-top: .125rem;
            padding-bottom: .125rem;
            width: 100%;
        }
        &-content{
            &-author{
                margin-bottom: 0;
                &-name{
                    color: ${({theme}) => theme.black};
                    font-size: 16px!important;
                }
                &-time{
                    color: ${({theme}) => rgba(theme.black,.4)};
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .meta-date,
                    .meta-edit{
                        display: flex;
                        align-items: center;
                    }
                    .meta-edit{
                        margin-left: 5px;
                        .icon{
                            display: flex;
                            align-items: center;
                            svg{
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
                &-name > *{
                    color: ${({theme}) => theme.black};
                    font-weight: bold;
                }
            }
            &-detail{
                color: ${({theme}) => theme.black};
                >.ant-row{
                    >.ant-col{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
                .content{
                    /* white-space: pre-wrap; */
                    width: 100%;
                    word-break: break-word;
                    font-size: 16px;
                    blockquote{
                        margin: 5px 0;
                    }
                    ul, ol{
                        margin-left: 20px;
                    }
                    pre{
                        margin: 0;
                        font-size: 12px;
                    }
                    .ant-typography{
                        >div{
                            color: inherit!important;
                        }
                        pre{
                            margin: 0;
                        }
                    }
                    &.bigemoji{
                        .ant-typography{
                            font-size: 32px !important;
                        }
                    }
                }
                .files-item{
                    margin-top: .125rem;
                }
                .ant-spin-spinning{
                    display: flex;
                }
                p{
                    color: ${({theme}) => theme.black};
                    margin: 0;
                }
            }
        }
    }
`;

export const StyleMessagePanel = styled.div`
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: ${SIZE_MESSAGE_PANEL}px;
    transform: translateX(100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 250ms ease;
    background-color: ${({ theme }) => theme.white};
    .content{
        padding: 10px;
        overflow: auto;
    }
    .header{
        padding: 10px;
        border-bottom: 1px solid ${({ theme }) => rgba(theme.black, .1)};
        .metas{
            margin-left: 10px;
            line-height: 1.2;
        }
        .big-title{
            font-size: 24px;
            font-weight: bold;
            color: ${({ theme }) => theme.black};
        }
        .title{
            font-size: 15px;
            font-weight: bold;
            color: ${({ theme }) => theme.black};
        }
        .subtitle{
            font-size: 13px;
            color: ${({ theme }) => rgba(theme.black, .6)};
        }
        .action{
            align-items: center;
            .btn{
                &:hover{
                    background-color: ${({ theme }) => rgba(theme.black, .2)}!important;
                    .icon{
                        svg{
                            color: ${({ theme }) => theme.black};
                        }
                    }
                }
                .icon{
                    svg{
                        color: ${({ theme }) => rgba(theme.black, .7)};
                    }
                }
            }
        }
    }
    &.active{
        transform: translateX(0);
    }
    &.remove{
        transform: translateX(100%)!important;
    }
`;

export const StyledBlock = styled.div`
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;
    background-color: ${({theme}) => theme.secondaryActive};
    border: 1px solid ${({theme}) => darken(.05, theme.secondaryActive)};
    color: ${({theme}) => theme.white};
    &.success{
        background-color: ${({theme}) => rgba(theme.success, .1)};
        border: 1px solid ${({theme}) => rgba(theme.success, .15)};
        color: ${({theme}) => theme.success};
    }
    &.danger{
        background-color: ${({theme}) => rgba(theme.error, .1)};
        border: 1px solid ${({theme}) => rgba(theme.error, .15)};
        color: ${({theme}) => theme.error};
    }
    &.infos{
        background-color: ${({theme}) => rgba(theme.infos, .1)};
        border: 1px solid ${({theme}) => rgba(theme.infos, .15)};
        color: ${({theme}) => theme.infos};
    }
`;

export const StyledNewUser = styled.div`
    font-size: 12px;
    margin: 10px; 0;
    color: ${({ theme }) => darken(.25, theme.black)};
    span{
        font-weight: bold;
    }
`;

export const StyledShare = styled.div`
    margin: 0 auto;
    width: 100%;
    border-radius: 4px;
    background-color: ${({ theme }) => darken(.05, theme.black)};
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text{
        margin: 10px 0;
    }
    .actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .link{
        display: flex;
        flex-direction: row;
        padding: 5px;
        border-radius: 30px;
        align-items: center;
        background-color:  ${({ theme }) => theme.black};
        span{
            display: flex;
            flex: 1;
            font-weight: bold;
            color: ${({ theme }) => theme.white};
        }
        .btn{
            margin-left: 5px;
        }
        >.icon{
            display: flex;
            align-items: center;
            margin-right: 5px;
            svg{
                width: 14px;
                height: 14px;
                color: ${({ theme }) => rgba(theme.white, .4)};
            }
        }
    }
`;

export const Style = styled.div`
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #414141;
    display: flex;
    min-height: 0;
    min-width: 0;
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition: all 250ms ease;
    &.messageOpen{
        width: calc(100% - ${SIZE_MESSAGE_PANEL}px);
    }
    &.galery{
        transition: all 250ms ease;
        .list-participants{
            transition: all 250ms ease;
            top: 0;
            left: 0;
            padding: 100px 20px;
            right: auto;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            >div{
                padding: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-columns: repeat(${({ participantsCount }) => getColumnCount(participantsCount)}, 1fr);
                grid-auto-rows: 1fr;
                column-gap: 10px;
                row-gap: 10px;
                margin-bottom: 10px;
                transition: all 250ms ease;
                >div{
                    transition: all 250ms ease;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &.sip{
        background-color: ${({ theme }) => theme.bgRoom};
        .sip-container{
            margin: 0 auto;
            min-height: 0;
        }
        .sip-header{
            margin: 0;
        }
        .logo{
            position: absolute;
            top: 20px;
            left: 20px;
            max-width: 50px;
            display: flex;
            align-items: flex-start;
            img{
                max-width: 100%;
            }
        }
    }
    &.toaster{
        top: ${TOASTER_HEIGHT}px;
        height: calc(100% - ${TOASTER_HEIGHT}px);
        .list-participants{
            >div:first-child{
                height: calc(100vh - (280px + ${TOASTER_HEIGHT}px));
            }
        }
    }
    .ant-notification-notice{
        padding: 10px;
        &-description,
        &-message{
            margin-left: 28px;
            line-height: initial;
            font-size: 14px;
            color: ${({ theme }) => theme.fixedWhite};
        }
        &-message{
            font-size: 14px;
            font-weight: bold;
            color: ${({ theme }) => theme.fixedWhite};
        }
        &-close,
        &-icon{
            color: ${({ theme }) => theme.fixedWhite};
            svg{
                width: 17px;
                height: 17px;
            }
        }
        &-close{
            top: 11px;
            right: 12px;
        }
        &-icon{
            font-size: 17px;
            line-height: inherit;
            margin-left: 0;
        }
        &.success{
            background-color: ${({ theme }) => theme.success};
        }
        &.error{
            background-color: ${({ theme }) => theme.error};
        }
        &.info{
            background-color: ${({ theme }) => theme.bgRoom};
        }
        &.warning{
            background-color: ${({ theme }) => theme.warning};
        }
    }
    .ant-dropdown{
        background-color: ${({ theme }) => lighten(0.1, theme.bgRoom)};
        border-radius: 4px;
        box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
        &.menu-header{
            display: flex;
            max-width: 380px;
            min-width: initial!important;
            &.ant-dropdown-hidden,
            &.ant-dropdown-menu-hidden{
                display: none;
            }
        }
    }
    .ant-popover{
        color: red;
        .ant-btn{
            &-dangerous{
                background-color: ${({ theme }) => theme.error};
                color: ${({ theme }) => theme.fixedWhite};
                &:hover{
                    background-color: ${({ theme }) => darken(.5, theme.error)};
                }
            }
        }
        &-message{
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon{
                display: flex;
                align-items: center;
                margin-right: 5px;
                svg{
                    color: ${({ theme }) => theme.error};
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    .connection-coming{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        .label{
            color: ${({ theme }) => theme.fixedWhite};
            font-size: 32px;
            letter-spacing: 2px;
            .anticon{
                margin-right: 20px;
            }
        }
    }
    &.remove{
        width: 100%;
        ${StyleHeader}{
            transform: translateY(-110%);
        }
        ${StyleFooterAction}{
            transform: translateY(115%);
        }
        >.overlay{
            transform: translateY(-110%);
            &.bottom{
                transform: translateY(110%);
            }
        }
    }
    .fold-list{
        position: absolute;
        top: 100%;
        right: 0px;
        cursor: pointer;
        transition: all 250ms ease;
        .btn{
            &:hover{
                background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.4)}!important;
            }
        }
        &.active{
            .icon{
                transform: rotate(-180deg);
            }
        }
        .icon{
            transition: all 250ms ease;
            svg{
                width: 18px;
                height: 18px;
                color: ${({ theme }) => theme.fixedWhite};
            }
        }
    }
    .list-participants{
        position: absolute;
        right: 20px;
        top: 140px;
        z-index: 1;
        transition: all 250ms ease;
    }
    .new-user{
        position: absolute;
        top: calc(100% - 30px);
        right: 105%;
        border-radius: 4px;
        overflow: hidden;
        height: 30px;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 0;
        transition: all .2s ease-in-out;
        justify-content: flex-end;
        opacity: 1;
        &.active{
            width: 100%;
        }
        &.folded{
            opacity: 0;
            right: 50%;
        }
        .content{
            white-space: nowrap;
        }
        .label{
            background-color: ${({ theme }) => rgba(theme.white, 0.15)};
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            color: ${({ theme }) => theme.black};
            font-size: 12px;
            width: 100%;
            font-weight: bold;
            padding: 5px 10px;
            align-items: center;
            .icon{
                margin-left: 5px;
                display: flex;
                align-items: center;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
        }

    }
    >.overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 120px;
        width: 100%;
        z-index: 1;
        background: linear-gradient(${({ theme }) => rgba(theme.fixedBlack, 0.6)} 0%, rgba(0,0,0,0) 100%);
        transition: all 250ms ease;
        &.bottom{
            top: auto;
            bottom: 0;
            background: linear-gradient(rgba(0,0,0,0) 0%, ${({ theme }) => rgba(theme.fixedBlack, 0.6)} 100%);
        }
    }
`;

export const StyleHeaderInformationRoom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .avatar-group-name{
        height: 40px;
        width: 40px;
        border-radius: 15px;
        background-color: red;
        color: white;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
    }
    .group-name{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
        span{
            &.title,
            &.timestamp{
                font-size: 14px;
                font-weight: bold;
                color: ${({ theme }) => rgba(theme.fixedWhite, 0.7)};
            }
        }
    }
    .group-count{
        display: flex;
        flex-direction: row;
        color: ${({ theme }) => theme.fixedBlack};
        background-color: ${({ theme }) => theme.fixedWhite};
        padding: 0px 10px;
        border-radius: 30px;
        .icon{
            svg{
                width: 10px;
                height: 10px;
            }
        }
        .animation-counter{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: bold;
            font-size: 10px;
            span{
                display: block;
                width: 5px;
                height: 5px;
                background-color: red;
                border-radius: 50%;
                margin-right: 3px;
            }
            margin-right: 10px;
        }
    }
`;

export const StyleHeaderMeRoom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    transition: all 350ms ease;
    transform: translateX(0);
    &.folded{
        transform: translateX(140px);
    }
    .room-quality-network{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 50%;
        margin-right: 20px;
        transition: all 250ms ease;
        cursor: pointer;
        position: relative;
        .tooltip{
            opacity: 0;
            visibility: hidden;
            background-color: ${({ theme }) => theme.bgRoom};
            color: ${({ theme }) => theme.fixedWhite};
            font-size: 10px;
            text-transform: uppercase;
            padding: 2px 5px;
            border-radius: 30px;
            position: absolute;
            right: 100%;
            top: 50%;
            white-space: nowrap;
            transform: translateY(-50%) translateX(-5px);
            transition: all 250ms ease;
        }
        &:hover{
            .tooltip{
                opacity: 1;
                visibility: visible;
                transform: translateY(-50%) translateX(-10px);
            }
        }
    }
`;

export const StyleParticipant = styled.div`
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.bgRoom};
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    ${({ offline }) => offline && `
        opacity: 0.4;
    `}
    justify-content: space-between;
    flex-shrink: 0;
    &.hold{
        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.4)};
        background-image:
            linear-gradient(
            -45deg,
            ${({ theme }) => rgba(theme.fixedWhite, 0.2)} 25%,
            transparent 25%,
            transparent 50%,
            ${({ theme }) => rgba(theme.fixedWhite, 0.2)} 50%,
            ${({ theme }) => rgba(theme.fixedWhite, 0.2)} 75%,
            transparent 75%,
            transparent
        );
        background-size:50px 50px;
        animation: ${move} 2s linear infinite;

        .badge{
            &.waiting{
                padding: 2px 10px;
                background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.2)};
                color: ${({ theme }) => theme.fixedWhite};
            }
        }
    }
    &.error{
        background-color: ${({ theme }) => theme.error};
        .overlay{
            display: none!important;
        }
        .error{
            display: flex;
            flex-direction: row;
            flex: 1;
            min-width: 0;
            min-height: 0;
            width: 100%;
            padding: 0 10px;
            justify-content: center;
            .label{
                font-size: 10px;
                color: ${({ theme }) => theme.fixedWhite};
            }
            .icon{
                svg{
                    width: 16px;
                    height: 16px;
                    color: ${({ theme }) => theme.fixedWhite};
                }
            }
        }
    }
    &.loading{
        .loader-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            .label{
                margin-top: 60px;
                color: ${({ theme }) => theme.fixedWhite};
                font-size: 12px;
            }
        }
        .overlay{
            display: none!important;
        }
        .loader{
            width: 60px;
            height: 60px;
            .dot{
                width: 15px;
                height: 15px;
            }
        }
    }
    &.active{
        border: 3px solid ${props => props.theme.mainColor2};
    }
    .centered-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        z-index: 2;
        .label{
            font-size: 12px;
            color: ${({ theme }) => theme.fixedWhite};
            text-align: center;
        }
        .avatar{
            width: 60px;
            height: 60px;
        }
        img{
            border-radius: 50%;
            max-width: 100%;
        }
    }
    &.mirror{
        video,
        .video{
            transform: scaleX(-1);
        }
    }
    .video{
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        background-size: cover;
        border-radius: 4px;
    }
    .avatar{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        transition: all 250ms ease;
        cursor: pointer;
        &.danger{
            .icon{
                svg{
                    color: ${({ theme }) => theme.error};
                }
            }
        }
        &:hover{
            background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.2)};
        }
        .icon{
            display: flex;
            align-items: center;
            svg{
                width: 14px;
                height: 14px;
                color: ${({ theme }) => theme.fixedWhite};
            }
        }
    }
    &:hover{
        .overlay{
            background-color: ${({ theme }) => rgba(theme.bgRoom, 0.7)};
        }
    }
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transition: all 250ms ease;
        background-color: ${({ theme }) => rgba(theme.bgRoom, 0.2)};
        z-index: 1;
    }
    .admin{
        .icon{
            display: flex;
            align-items: center;
            svg{
                color: gold;
                width: 12px;
                height: 12px;
            }
        }
    }
    .name{
        color: ${({ theme }) => theme.fixedWhite};
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-shadow: 0 1px 1px ${({ theme }) => rgba(theme.fixedBlack, 0.5)};
    }
    .line{
        &-wrapper{
            display: flex;
            flex-direction: column;
            flex: 1;
            z-index: 2;
            width: 100%;
            .line{
                &:last-child{
                    align-items: flex-end;
                }
                &:first-child{
                    align-items: flex-start;
                }
            }
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 5px;
        flex: 1;
        min-height: 0;
        min-width: 0;
        &-container{
            display: flex;
            flex-direction: row;
            align-items: baseline;
            min-height: 0;
            min-width: 0;
            &:last-child{
                margin-left: 10px;
            }
            >div{
                margin-right: 5px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
`;

export const StyleIconRounded = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &.primary{
        background-color: ${props => props.theme.mainColor2};
        box-shadow: 0 2px 4px ${props => rgba(props.theme.mainColor2, 0.5)}
    }
    &.good{
        background-color: ${({ theme }) => theme.success};
        box-shadow: 0 2px 4px ${rgba(colors.success, 0.5)}
    }
    &.medium{
        background-color: ${({ theme }) => theme.warning};
        box-shadow: 0 2px 4px ${rgba(colors.warning, 0.5)}
    }
    &.low{
        background-color: ${({ theme }) => theme.error};
        box-shadow: 0 2px 4px ${rgba(colors.error, 0.5)}
    }
    .icon{
        display: flex;
        align-items: center;
        svg{
            color: ${({ theme }) => theme.fixedWhite};
            width: 15px;
            height: 15px;
        }
    }
`;

export const StyleBigScreen = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    &.mirror{
        video,
        .video{
            transform: scaleX(-1);
        }
    }
    .video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const StyleBigAvatar = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    .avatar-animated-container{
        position: relative;
        .bg-circle{
            top: 50%;
            left: 50%;
            right: 0;
            bottom: 0;
            background: ${props => props.theme.mainColor2};
            position: absolute;
            border-radius: 50%;
            transform: translateY(-50%) translateX(-50%);
            &:nth-child(1){
                width: 166.66666667px;
                height: 166.66666667px;
                opacity: 0.025;
                animation: ${bgAn1} 1.8s ease-in infinite;
                animation-delay: 0.05s;
            }
            &:nth-child(2){
                width: 208.33333333px;
                height: 208.33333333px;
                opacity: 0.05;
                animation: ${bgAn2} 1.8s ease-in infinite;
                animation-delay: 0.1s;
            }
            &:nth-child(3){
                width: 250px;
                height: 250px;
                opacity: 0.075;
                animation: ${bgAn3} 1.8s ease-in infinite;
                animation-delay: 0.15s;
            }
            &:nth-child(4){
                width: 291.66666667px;
                height: 291.66666667px;
                opacity: 0.1;
                animation: ${bgAn4} 1.8s ease-in infinite;
                animation-delay: 0.2s;
            }
        }
        .avatar{
            border-radius: 50%;
            animation: dp-a 1.8s ease-out infinite;
            animation-delay: 1.10s;
            transform: scale(1.4);
            img{
                border-radius: 50%;
            }
        }
    }
`;

export const StyleFooterButton = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    .fake-button{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        background-color: ${props => rgba(props.theme.fixedWhite, 0.1)};
        transition: all 250ms ease;
        cursor: pointer;
        .desc{
            display: flex;
            align-items: center;
            margin: 0 0 0 2px!important;
            font-size: 10px;
            font-weight: bold;
            color: ${props => props.theme.fixedWhite};
        }
        &:hover{
            background-color: ${props => rgba(props.theme.fixedWhite, 0.4)};
        }
        &.active{
            background-color: ${props => props.theme.fixedWhite};
            .desc{
                color: ${props => props.theme.fixedBlack};
            }
        }
    }
    .btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 14px;
    }
    .label{
        text-align: center;
        margin: 5px 0 0 0!important;
        font-size: 12px;
        color: ${({ theme }) => theme.fixedWhite}!important;
        ${({ danger }) => danger && `
            color: #F64646!important;
        `}
    }
`;

export const StyleListParticipants = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
    position: relative;
    transition: all 550ms ease;
    transform: translateX(0);
    margin-right: -20px;
    padding-right: 20px;
    &::-webkit-scrollbar {
        width: 10px;
        &-track {
            border-radius: 4px;
            background: ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
        }
        &-thumb {
            border-radius: 4px;
            background: ${({ theme }) => rgba(theme.fixedBlack, 0.35)};
            &:hover {
                background: ${({ theme }) => rgba(theme.fixedBlack, 0.45)};
            }
        }
    }
    ${({ folded }) => folded && `
        transform: translateX(350px);
    `}

    ${StyleParticipant}{
        margin-bottom: 10px;
        .video{
            object-fit: cover;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
`;

export const StyleModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    display: flex;
    visibility: hidden;
    min-height: 0;
    min-width: 0;
    .ant-tabs{
        display: none;
    }
    &.modal-sip{
        .modal-room{
            height: auto;
            &-content{

            }
        }
    }
    &.active{
        visibility: visible;
        .ant-tabs{
            display: flex;
            flex-direction: column;
        }
        .modal-room{
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
        }
        .overlay{
            opacity: 1;
            visibility: visible;
        }
    }
    ${StyleListUsers}{
        .item{
            padding: 10px;
            transition: all 250ms ease;
            cursor: pointer;
            border-radius: 4px;
            margin: 0!important;
            &:hover{
                background-color: ${({ theme }) => theme.bgHoverListUsers};
            }
        }
    }
    .modal-room{
        background-color: ${({ theme }) => theme.bgRoom};
        z-index: 6;
        position: absolute;
        max-width: 455px;
        width: 100%;
        display: flex;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        border-radius: 4px;
        transition: all 250ms ease;
        max-height: 80%;
        opacity: 0;
        visibility: hidden;
        height: 100%;
        box-shadow: 0 2px 10px ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
        &-footer{
            padding: 10px 20px;
            background-color: ${({ theme }) => theme.bgModalFooter};
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            border-radius: 0 0 4px 4px;
            &.jc-sb{
                justify-content: space-between;
            }
            &-container{
                display: flex;
                align-items: center;
                justify-content: center
            }
            .btn{
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &-content{
            display: flex;
            flex-direction: column;
            padding: 20px;
            //overflow: auto;
            min-height: 0;
            min-width: 0;
            .overflow{
                overflow: auto;
                padding-right: 20px;
                margin-right: -20px;
            }
        }
        &-header{
            padding: 20px;
            position: relative;
            display: flex;
            .label{
                font-size: 18px;
                font-weight: 500;
                color: ${({ theme }) => theme.black};
            }
            .btn{
                position: absolute;
                background-color: ${({ theme }) => theme.bgCloseModal};
                top: -20px;
                right: -20px;
                border: 4px solid ${({ theme }) => theme.bgRoom};
                &:hover{
                    background-color: ${({ theme }) => darken(0.15, theme.bgCloseModal)}!important;
                }
            }
        }
    }
    >.overlay{
        transition: all 250ms ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.6)};
    }
`;

export const StyleDropdown = styled.div`
    position: absolute;
    bottom: 110%;
    right: 0;
    background-color: ${({ theme }) => lighten(0.1, theme.bgRoom)};
    box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
    padding: 10px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all 250ms ease;
    transform: translateY(-10px);
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &.infos{
        width: 200px;
    }
    &.more{
        width: 250px;
    }
    &:after{
        top: 100%;
        right: 15px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: ${({ theme }) => theme.bgRoom};
        border-width: 5px;
        margin-left: -5px;
    }
    .list-line-options{
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: calc(100vh - 160px);
        margin-left: -10px;
        margin-right: -10px;
        padding: 0px 10px;
        .btn{
            &.save{
                border-radius: 4px;
            }
        }
        .line{
            &:hover{
                .label{
                    cursor: pointer;
                    //color: ${({ theme }) => theme.black};
                }
            }
            &.j-sb{
                justify-content: space-between;
            }
            &.ai-fe{
                align-items: flex-end;
            }
            &-wrapper{
                display: flex;
                flex-direction: row;
                margin-right: 10px;
            }
            &-item{
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
                &:last-child{
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border: none;
                }
            }
            &:first-child{
                margin-right: 10px;
            }
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            &.column{
                flex-direction: column;
            }
            input{
                border-radius: 4px;
                border: 2px solid transparent;
                background-color: ${({ theme }) => rgba(theme.black, 0.1)};
                padding: 5px;
                color: ${({ theme }) => theme.black};
                transition: all 250ms ease;
                width: 100%;
                font-size: 14px;
                margin-top: 5px;
                &:focus{
                    background-color:${({ theme }) => rgba(theme.black, 0.15)};
                    border-color: ${props => props.theme.mainColor2};
                }
            }
            .icon-hd{
                background-color: ${({ theme }) => theme.black};
                font-size: 10px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                color: ${({ theme }) => theme.white};
            }
            &.header{
                align-items: center;
            }
            >.label{
                font-size: 15px;
                font-weight: bold;
                color: ${({ theme }) => rgba(theme.black, 0.9)};
                margin-bottom: 0px;
                text-align: left;
                transition: all 250ms ease;
            }
            .btn{
                &.save{
                    .label{
                        font-weight: bold;
                        margin-top: -2px;
                    }
                }
            }
            .desc{
                color: ${({ theme }) => rgba(theme.black, 0.6)};
                font-size: 14px;
                font-weight: 400;
                margin-top: 3px;
                display: flex;
                b{
                    font-weight: bold;
                    color: ${({ theme }) => theme.black};
                }
            }
            .copy{
                margin-left: 3px;
                cursor: pointer;
                .icon{
                    svg{
                        width: 17px;
                        height: 17px;
                    }
                }
            }
            .icon{
                display: flex;
                svg{
                    color: ${({ theme }) => theme.black};
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
`

export const StyleListUsers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    min-height: 0;
    flex: 1;
    overflow: auto;
    .item{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
        >label{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .ant-checkbox{
                margin-right: 10px;
            }
        }
        &.offline{
            .user-wrapper{
                .avatar{
                    opacity: 0.4;
                }
                .label{
                    color: ${({ theme }) => rgba(theme.black, 0.6)};
                }
                .badge{
                    &.admin{
                        opacity: 0.4;
                    }
                }
            }
        }
        .btn{
            margin-left: 10px;
            padding: 5px 10px;
            background-color: ${({ theme }) => rgba(theme.black, 0.6)};
            &.primary{
                background-color: ${props => rgba(props.theme.mainColor2, 0.4)};
                &:hover{
                    background-color: ${props => props.theme.mainColor2}!important;
                }
            }
            &:hover{
                background-color: ${colors.error}!important;
            }
            .label{
                font-size: 10px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        .user-wrapper{
            display: flex;
            align-items: center;
            flex-direction: row;
            flex: 1;
            width: 100%;
            min-width: 0;
            justify-content: flex-start;
            .label{
                color: ${({ theme }) => theme.black};
                font-size: 14px;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1;
                line-height: initial;
                min-width: 0;
                min-height: 0;
                .name{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 3px;
                }
                .subtitle{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-weight: 400;
                    width: 100%;
                    color: ${({ theme }) => theme.black};
                }
                .badge{
                    &-container{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .badge{
                            margin-right: 10px;
                        }
                    }
                    font-size: 8px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    padding: 5px 10px;
                    border-radius: 30px;
                    border: 1px solid;
                    box-shadow: none;
                    &.offline{
                        border-color: ${({ theme }) => rgba(theme.black, 0.1)};
                        color: ${({ theme }) => rgba(theme.black, 0.4)};
                    }
                    &.admin{
                        background-color: transparent;
                        border-color: ${rgba('#fff4bf', 0.4)};
                        color: #d9b200;
                    }
                }
                .icon{
                    margin-right: 5px;
                    svg{
                        color: gold;
                        width: 14px;
                        height: 14px;
                    }
                }

            }
        }
        .avatar,
        .group-avatar{
            margin-right: 10px;
            width: 40px;
            display: flex;
            flex-shrink: 0;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            img{
                max-width: 100%;
            }
        }
    }
`;

export const StyleGroupLabelPhone = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.groupSearch};
    border-radius: 4px;
    padding: 10px;
    align-items: center;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.black};
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

export const StyleGroupSearch = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.groupSearch};
    border-radius: 4px;
    padding: 10px;
    align-items: center;
    margin-bottom: 20px;
    transition: all 250ms ease;
    border: 1px solid ${({ theme }) => theme.groupSearch};
    &:focus-within{
        border-color: ${props => props.theme.mainColor2};
        box-shadow: 0 0 6px ${props => rgba(props.theme.mainColor2, 0.6)};
        label{
            svg{
                color: ${({ theme }) => theme.black};
            }
        }
    }
    input{
        font-size: 14px;
        color: ${({ theme }) => theme.black};
        background-color: transparent;
        border: none;
        display: flex;
        flex: 1;
        min-width: 0;
        min-height: 0;
        width: 100%;
        transition: all 250ms ease;
        &::placeholder{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
        }
    }
    label{
        display: flex;
        margin-right: 5px;
        cursor: pointer;
        svg{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
            transition: all 250ms ease;
            width: 14px;
            height: 14px;
        }
    }
`;

export const StyleDrawer = styled(Drawer)`
    &.ant-drawer{
        position: absolute;
    }
    ${StyleListUsers}{
        .item{
            padding: 10px;
            transition: all 250ms ease;
            cursor: pointer;
            border-radius: 4px;
            margin: 0!important;
            &:hover{
                background-color: ${({ theme }) => theme.bgHoverListUsers};
            }
        }
    }
    .ant-drawer{
        &-content,
        &-header{
            background-color: ${({ theme }) => theme.bgRoom};
        }
        &-title{
            color: ${({ theme }) => theme.black};
        }
        &-header,
        &-footer{
            border-color: ${({ theme }) => rgba(theme.black, 0.1)};
        }
        &-close{
            color: ${({ theme }) => theme.black};
        }
        &-footer{
            .actions{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

export const StyleCheckbox = styled(Checkbox)`
    &.ant-checkbox-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            .label{
                color: ${({ theme }) => theme.black};
            }
        }
        &:hover{
            .ant-checkbox-inner{
                border-color: ${props => props.theme.mainColor2};
            }
        }
    }
    .ant-checkbox{
        .ant-checkbox-input:focus + .ant-checkbox-inner{
            border-color: ${props => props.theme.mainColor2};
        }
        &-checked{
            &::after{
                border-color: ${props => props.theme.mainColor2};
            }
            &.ant-checkbox-disabled{
                .ant-checkbox{
                    &-inner{
                        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)};
                        border-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)}!important;
                        &::after{
                            border-color: ${({ theme }) => rgba(theme.fixedWhite, 0.4)};
                        }
                    }
                }
            }
            .ant-checkbox{
                &-inner{
                    background-color: ${props => props.theme.mainColor2};;
                    border-color: ${props => props.theme.mainColor2};!important;
                    &::after{
                        border-color: ${({ theme }) => theme.fixedWhite};
                    }
                }
            }
        }
        &-inner{
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
            border-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
    }
`;

export const StyleRadio = styled(Radio)`
    &.ant-radio-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            .label{
                color: ${({ theme }) => theme.black};
            }
        }
        &:hover{
            .ant-radio-inner{
                border-color: ${props => props.theme.mainColor2};
            }
        }
    }
    .ant-radio{
        .ant-radio-input:focus + .ant-radio-inner{
            border-color: ${props => props.theme.mainColor2};
        }
        &-checked{
            &::after{
                border-color: ${props => props.theme.mainColor2};
            }
            &.ant-radio-disabled{
                .ant-radio{
                    &-inner{
                        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)};
                        border-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)}!important;
                        &::after{
                            border-color: ${({ theme }) => rgba(theme.fixedWhite, 0.4)};
                        }
                    }
                }
            }
            .ant-radio{
                &-inner{
                    background-color: ${props => props.theme.mainColor2};;
                    border-color: ${props => props.theme.mainColor2};!important;
                    &::after{
                        border-color: ${({ theme }) => theme.fixedWhite};
                    }
                }
            }
        }
        &-inner{
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
            border-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
    }
`;
export const StyleTagUser = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .item-tag{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: ${({ theme }) => rgba(theme.black, 0.1)};
        border-radius: 30px;
        margin-right: 10px;
        margin-bottom: 10px;
        transition: all 250ms ease;
        cursor: pointer;
        padding-right: 5px;
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
        .user-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            .avatar{
                width: 20px;
                height: 20px;
                border-radius: 100%;
                margin-right: 5px;
                display: flex;
                img{
                    max-width: 100%;
                    border-radius: 50%;
                }
            }
            .label{
                font-size: 10px;
                color: ${({ theme }) => rgba(theme.black, 0.6)};
            }
        }
        .icon{
            display: flex;
            align-items: center;
            margin-left: 5px;
            cursor: pointer;
            svg{
                color: ${({ theme }) => theme.black};
                width: 14px;
                height: 14px;
            }
        }
    }
`;

export const StyleCheckboxList = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
    .item{
        display: flex;
        flex-direction: column;
        padding: 0;
        &:hover{
            background-color: transparent;
        }
        >.label{
            font-size: 14px;
            color: ${({ theme }) => theme.black};
            font-weight: bold;
            margin-bottom: 10px;
        }
        .line{
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            .checkbox{
                margin-right: 10px;
            }
            .label{
                font-size: 14px;
                color: ${({ theme }) => theme.black};
            }
        }
    }
`;

export const StyleBlockBordered = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
    &:last-child{
        border: none;
        margin-bottom: 0;
    }
    >.label{
        font-size: 14px;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.black};
    }
`;

export const StyleTextArea = styled(TextArea)`
    &.ant-input{
        border-color:${({ theme }) => rgba(theme.black, 0.2)};
        background-color: ${({ theme }) => isDarkTheme(theme) ? rgba(theme.black, 0.2) : rgba(theme.white, 0.8)};
        resize: none;
        color: ${({theme}) => theme.black};
        border-width: 2px!important;
        &-disabled{
            &::placeholder{
                color: ${({ theme }) => rgba(theme.black, 0.2)};
            }
        }
        &:focus,
        &-focused{
            border-color: ${props => props.theme.mainColor2};
            box-shadow: none;
        }
    }
`;

export const StyleDropdownMenu = styled.div`
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.white, 0.15)};
    padding: 5px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &:after{
        content: "";
        top: 100%;
        right: 7px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: ${({ theme }) => theme.bgRoom};
        border-width: 5px;
        margin-left: -5px;

    }
    .item{
        display: flex;
        flex-direction: row;
        padding: 5px;
        border-radius: 4px;
        transition: all 250ms ease;
        cursor: pointer;
        align-items: center;
        flex: 1;
        min-height: 0;
        min-width: 0;
        width: 100%;
        &:hover{
            background-color: ${({ theme }) => rgba(theme.black, 0.15)};
        }
        &.delete{
            &:hover{
                background-color: ${rgba(colors.error, 0.15)};
            }
            .icon{
                svg{
                    color: ${({ theme }) => theme.error};
                }
            }
            .label{
                color: ${({ theme }) => theme.error};
            }
        }
        .icon{
            display: flex;
            margin-right: 10px;
            svg{
                color: ${({ theme }) => theme.black};
                width: 12px;
                height: 12px;
            }
        }
        .label{
            color: ${({ theme }) => theme.black};
            font-size: 12px;
        }
    }
`;

export const StyleShortcuts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .item{
        border-radius: 0;
        background-color: transparent!important;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
        width: 100%;
        margin-bottom: 10px!important;
        padding: 0 0 10px 0;
        cursor: default;
        &:last-child{
            margin: 0!important;
            padding: 0;
            border: none;
        }
        .label{
            display: flex;
            font-size: 14px;
            flex: 1;
            color: ${({ theme }) => theme.black};
        }
        .key{
            font-size: 10px;
            font-weight: bold;
            height: 20px;
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${({ theme }) => theme.black};
            padding: 5px;
            border-radius: 4px;
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
    }
`;

export const StyleTitleCountParticipant = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
    &.error{
        .count{
            background-color: ${rgba(colors.error, 0.3)};
            span{
                color: ${({ theme }) => theme.error};
            }
        }
    }
    .label,
    .count{
        color: ${({ theme }) => rgba(theme.black, 0.6)};
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .count{
        background-color: ${({ theme }) => rgba(theme.black, 0.2)};
        padding: 5px 10px;
        border-radius: 4px;
    }
`;

export const StyleToaster = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: #ffc540;
    transition: height 250ms ease;
    height: ${TOASTER_HEIGHT}px;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    padding: 0 20px;
    box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.white, 0.15)};
    z-index: 999999999999999999999999999;
    height: 0;
    &.active{
        height: ${TOASTER_HEIGHT}px;
    }
    .toaster-container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .btn{
            .icon{
                svg{
                    color: #552100;
                }
            }
        }
        .label{
            color: #552100;
            font-size: 14px;
        }
    }
`;

export const StylePanelOfferPremium = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 4px;
    background-color: #ffc540;
    margin-bottom: 20px;
    .title{
        font-size: 14px;
        font-weight: bold;
        color: #552100;
        margin-bottom: 5px;
        span{
            position: relative;
            top: -4px;
            background-color: ${rgba('#552100', 0.1)};
            padding: 5px 5px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 9px;
        }
    }
    .desc{
        font-size: 12px;
        color: ${rgba('#552100', 0.6)};
    }
    .action{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        .btn{
            border-radius: 4px;
            padding: 5px 10px;
            margin-right: 10px;
            &.transparency{
                &.lighten{
                    .label{
                        color: #552100
                    }
                }
            }
            &.primary{
                background-color: #552100;
                &:hover{
                    background-color: ${darken(0.05, '#552100')}!important;
                }
            }
        }
    }

`;

export const StyleTabs = styled(Tabs)`
    overflow: initial;
    .ant-tabs-bar{
        border-bottom-color: ${({ theme }) => rgba(theme.black, 0.1)};
    }
    .ant-tabs-tab{
        color: ${({ theme }) => rgba(theme.black, 0.6)};
        &:hover{
            color: ${({ theme }) => theme.black};
        }
        .label{
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon{
                display: flex;
                margin-right: 10px;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
        }
        &.ant-tabs-tab-active{
            color: ${({ theme }) => theme.black};
        }
    }
    .ant-tabs-ink-bar{
        background-color: ${props => props.theme.mainColor2};
    }
    .groups-line{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 20px;
        width: 100%;
        &.pointer{
            cursor: pointer;
        }
        .video{
            width: 100%;
            padding-top: 56.25%;
            position: relative;
            &.mirror{
                img,
                video,
                .video{
                    transform: scaleX(-1);
                }
            }
            img,
            video{
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-width: 100%;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        .btn{
            margin-left: 10px;
            border-radius: 2px;
            height: 32px;
        }
        .equalizer{
            margin-left: 10px;
            border-radius: 2px;
            border-width: 2px;
            border-color: ${({ theme }) => rgba(theme.black, 0.4)};
            .icon{
                .icon{
                    svg{
                        color: ${({ theme }) => theme.black};
                    }
                }
            }
        }
        &-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            .label,
            .desc{
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 5px;
                color: ${({ theme }) => rgba(theme.black, 0.6)};
            }
            .desc{
                margin-bottom: 0;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

`;

export const StyleSelect = styled(Select)`
    &.ant-select{
        width: 100%;
    }
    &.ant-select-single{
        &:not(.ant-select-customize-input){
            .ant-select-selector{
                background-color: ${({ theme }) => rgba(theme.black, 0.2)};
                border-color: ${({ theme }) => rgba(theme.black, 0.2)};
                border-width: 2px!important;
            }
            .ant-select-selection-item{
                color: ${({ theme }) => theme.black};
            }
            .ant-select-arrow{
                color: ${({ theme }) => theme.black};
            }
            &:hover{
                .ant-select-selector{
                    border-color: ${props => props.theme.mainColor2};
                }
            }
        }
        &.ant-select-focused{
            .ant-select-selector{
                border-color: ${props => props.theme.mainColor2}!important;
                box-shadow: none!important;
            }
        }
    }
`;

export const StyleSwitch = styled(Switch)`
    &.ant-switch-checked{
        background-color: ${props => props.theme.mainColor2}!important;
    }
`;

export const StyleRoomError = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.mainColor1}!important;
    .container{
        padding: 20px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        margin: 0px auto;
        overflow: auto;
        flex-direction: column;
        .title{
            font-size: 18px;
            color: ${({ theme }) => theme.fixedWhite};
            font-weight: 600;
            margin-bottom: 10px;
        }
        .action{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-top: 40px;
            .btn{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .desc{
            font-size: 15px;
            color: ${({ theme }) => rgba(theme.fixedWhite, 0.6)};
        }
        .logo{
            display: flex;
            max-height: 50px;
            max-width: 200px;
            align-items: center;
            margin-bottom: 20px;
            img{
                display: flex;
                max-height: 100%;
                align-items: center;
                max-width: 100%;
            }
        }
    }
`;

export const StyleCallEnd = styled.div`
    background-color: #121212;
    display: flex;
    flex-direction: column;
    max-width: 375px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 0 40px;
    .logo{
        display: flex;
        max-height: 50px;
        max-width: 200px;
        align-items: center;
        position: absolute;
        top: 20px;
        left: 20px;
        height: 100%;
        width: 100%;
        object-fit: contain;
        img{
            display: flex;
            max-height: 100%;
            align-items: center;
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .caller-infos{
        display: flex;
        flex-direction: column;
        margin: 50px 0 0 0;
        align-items: center;
        justify-content: center;
        .label{
            font-size: 14px;
            font-weight: bold;
            color: ${({ theme }) => theme.black};
            text-align: center;
        }
        .avatar-container{
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            white-space: nowrap;
            .bullet{
                background-color: ${props => props.theme.mainColor2};
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${({ theme }) => theme.black};
                font-size: 32px;
                font-weight: bold;
                width: 170px;
                border-radius: 50%;
                height: 170px;
            }
            .avatar,
            .more{
                width: 170px;
                border-radius: 50%;
                height: 170px;
                border: 4px solid #121212;
                img{
                    width: 100%;
                    border-radius: 50%;
                }
            }
            .more{
                width: 60px;
                height: 60px;
                margin-left: -10px;
                background-color: #353535;
                color: ${({ theme }) => theme.black};
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-weight: bold;
            }
        }
        &.multiple{
            .avatar-container{
                .avatar{
                    width: 60px;
                    height: 60px;
                    margin-left: -10px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .call-end{
        display: flex;
        flex-direction: column;
        margin: 100px 0;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-height: 0;
        min-width: 0;
        &--label{
            font-size: 24px;
            font-weight: bold;
            color: ${({ theme }) => theme.black};
        }
        &--duration{
            margin-top: 10px;
            padding: 7px 15px;
            color: ${({ theme }) => rgba(theme.black, 0.6)};
            background-color: ${({ theme }) => rgba(theme.black, 0.3)};
            display: flex;
            border-radius: 30px;
            align-items: center;
            justifiy-content: center;
        }
    }
    .call-end-actions{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .line{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 0;
            }
            &.center{
                justify-content: center;
            }
        }
    }
`;

export const StyleCallEndFooterButton = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    &.close{
        .btn{
            border: 3px solid ${({ theme }) => rgba(theme.fixedWhite, 0.2)};
            background-color: transparent;
            &:hover{
                background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.1)};
                .icon{
                    svg{
                        color: ${({ theme }) => theme.fixedWhite};
                    }
                }
            }
            .icon{
                svg{
                    color: ${({ theme }) => rgba(theme.fixedWhite, 0.5)};
                }
            }
        }
    }
    .btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
    }
    .label{
        text-align: center;
        margin-top: 5px;
        font-size: 10px;
        color: ${({ theme }) => rgba(theme.black, 0.6)};
    }
`;

export const StyleSipWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    position: relative;
`;

export const StyleSipHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .badge{
        box-shadow: none;
        color: ${({ theme }) => theme.black};
        background-color: ${({ theme }) => rgba(theme.black, 0.1)};
    }
    .icon-quality-network{
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        align-items: flex-end;
        &.good{
            span{
                background-color: ${({ theme }) => theme.success};
            }
        }
        &.medium{
            span{
                background-color: ${({ theme }) => theme.warning};
                &:last-child{
                    background-color: ${({ theme }) => theme.fixedBlack};
                }
            }
        }
        &.low{
            span{
                background-color: ${({ theme }) => theme.fixedBlack};
                &:first-child{
                    background-color: ${({ theme }) => theme.error};
                }
            }
        }
        span{
            display: block;
            width: 5px;
            margin-right: 1px;
            transition: all 250ms ease;
            height: 5px;
            &:nth-child(2){
                height: 10px;
            }
            &:last-child{
                height: 15px;
            }
        }
    }
`;

export const StyleSipCentral = styled.div`
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    .sip-double-call--container{
        &--wrapper{
            display: flex;
            flex: 1;
            margin-bottom: 20px;
            justify-content: center;
            align-items: center;
        }
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme.bgNotificationDoubleCall};
        border-radius: 4px;
        padding: 10px 20px;
        width: 100%;
        box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
        ${StyleListUsers}{
            .item{
                border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
                padding-bottom: 10px;
                margin-bottom: 10px;
                align-items: flex-start;
                .label{
                    .name{
                        margin: 0;
                    }
                }
                .button-container{
                    text-align: center;
                    .btn{
                        width: 40px;
                        height: 40px;
                        margin: 0;
                        background-color: ${({ theme }) => rgba(theme.black, 0.20)};
                        &:hover{
                            background-color: ${({ theme }) => rgba(theme.black, 0.30)}!important;
                        }
                        .icon{
                            svg{
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                &:last-child{
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                    .user-wrapper{
                        opacity: 0.4;
                    }
                }
            }
        }
    }
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        flex: 1;
        flex-shrink: 0;
        min-height: 0;
        margin-bottom: 20px;
        .information-call{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            .name{
                font-size: 16px;
                font-weight: bold;
                color: ${({ theme }) => theme.black};
            }
            .timer{
                font-size: 14px;
                font-weight: 400;
                color: ${({ theme }) => rgba(theme.black, 0.6)};
            }
        }
        .avatar-fullscreen-container{
            .avatar,
            .group-avatar{
                width: 130px;
                height: 130px;
                font-size: 50px;
            }
            &:before{
                animation: ${({theme}) => pulsar(theme)} 2s .3s infinite;
            }
            &:after{
                animation: ${({theme}) => pulsar(theme)} 2s .6s infinite;
            }
        }
    }
`;

export const StyleSipFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    .line{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0;
        }
        &.center{
            justify-content: center;
        }
    }
`;

export const StyleSipDoubleCallNotification = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateY(-120%) translateX(-50%);
    transition: all 250ms ease;
    z-index: 10;
    &.active{
        transform: translateY(40px) translateX(-50%);
    }
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.bgNotificationDoubleCall};
    border-radius: 4px;
    padding: 10px 20px;
    box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
    ${StyleListUsers}{
        overflow: inherit;
        .item{
            align-items: flex-start;
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
            .label{
                .name{
                    width: 100%;
                    margin: 0;
                }
            }
            .button-container{
                margin-left: 10px;
                .btn{
                    width: 40px;
                    height: 40px;
                    margin: 0;
                    &.success{
                        background-color: ${({ theme }) => theme.success};
                        &:hover{
                            background-color: ${({ theme }) => darken(0.15, theme.success)}!important;
                        }
                    }
                    &.error{
                        background-color: ${({ theme }) => theme.error};
                        &:hover{
                            background-color: ${({ theme }) => darken(0.15, theme.error)}!important;
                        }
                    }
                    .icon{
                        svg{
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
`;

export const StyleIconLabel = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    .item{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 10px;
        border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
        transition: all 250ms ease;
        border-radius: 0;
        line-height: initial;
        .toggle-item{
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            height: 100%;
            min-height: 0;
            flex-shrink: 0;
        }
        input{
            border-radius: 4px;
            border: 2px solid transparent;
            background-color: ${({ theme }) => rgba(theme.black, 0.1)};
            padding: 5px;
            color: ${({ theme }) => theme.black};
            transition: all 250ms ease;
            width: 100%;
            font-size: 14px;
            margin-top: 5px;
            &:focus{
                background-color:${({ theme }) => rgba(theme.black, 0.15)};
                border-color: ${props => props.theme.mainColor2};
            }
        }
        .btn{
            &.save{
                border-radius: 4px;
                .label{
                    font-weight: bold;
                    margin-top: -2px;
                }
            }
        }
        .desc{
            color: ${({ theme }) => rgba(theme.black, 0.6)};
            font-size: 14px;
            font-weight: 400;
            margin-top: 3px;
            display: flex;
            b{
                font-weight: bold;
                color: ${({ theme }) => theme.black};
            }
        }
        .container-row{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: 100%;
        }
        .container-column{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 10px;
        }
        &.disabled{
            opacity: .4;
        }
        .userlist{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .avatar{
                width: 20px;
                height: 20px;
                border: 1px solid ${({ theme }) => theme.bgRoom};
                margin-right: 0;
                margin-left: -5px;
                border-radius: 50%;
                img{
                    max-width: 100%;
                    border-radius: 50%;
                }
            }

        }
        .ant-radio-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin: 0;
            >span{
                display: flex;
                flex-direction: row;
                align-items: center;
                &:last-child{
                    padding-right: 0;
                    width: 100%;
                }
            }
        }
        &:first-child{
            border-radius: 4px 4px 0 0;
        }
        &:hover{
            background-color: ${({ theme }) => rgba(theme.black, 0.10)};
        }
        &:last-child{
            border-bottom: 0;
            border-radius: 0 0 4px 4px;
        }
        .icon,
        .avatar{
            display: flex;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            flex-shrink: 0;
            color: ${({ theme }) => theme.black};
            svg{
                width: 18px;
                height: 18px;
            }
        }
        .icon{
            border-radius: 4px;
            background-color: ${({ theme }) => rgba(theme.black, 0.20)};
        }
        .label{
            font-size: 14px;
            color: ${({ theme }) => theme.black};
            font-weight: 500;
            flex: 1;
            display: flex;
            flex-direction: column;
            .name,
            .subtitle{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
            .subtitle{
                font-size: 14px;
                font-weight: 400;
                color: ${({ theme }) => rgba(theme.black, 0.6)};
            }
        }
    }
`;

export const StylePhoneKeyboard = styled.div`
    margin: 0 auto;
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-shrink: 0;
    overflow: auto;
    justify-content: center;
    .dig-pad{
        width: 100%;
        padding: 0;
        margin: 0;
        height: auto;
        .row{
            &.center{
                .digit{
                    .title{
                        margin-top: 0;
                    }
                }
            }
            &.first{
                .digit{
                    &:first-child{
                        .title{
                            margin-top: 0;
                        }
                    }
                }
            }
            .digit{
                width: 50px;
                height: 50px;
                flex-shrink: 0;
                border-radius: 50%;
                color: ${({ theme }) => theme.black};
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin-bottom: 10px;
                .title{
                    font-size: 16px;
                    margin-top: -10px;
                    font-weight: bold;
                }
                background-color: ${({ theme }) => rgba(theme.black, 0.1)};
                &:hover{
                    background-color: ${({ theme }) => rgba(theme.black, 0.20)};
                }
                &:active{
                    transform: scale(0.95);
                }
                .sub{
                    position: absolute;
                    bottom: 8px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 8px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: ${({ theme }) => rgba(theme.black, 0.6)};
                }
            }
        }
    }
`;

export const StyleRoomInformation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 14px;
    background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.2)};
    color: ${({ theme }) => theme.fixedWhite};
    .item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        transition: all 250ms ease;
        &.hover{
            &:first-child{
                border-radius: 30px 0 0 30px;
            }
            &:last-child{
                border-radius: 0 30px 30px 0;
            }
            &:hover{
                background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.3)};
                cursor: pointer;
            }
        }
        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            svg{
                width: 14px;
                height: 14px;
            }
        }
    }
    .separator{
        display: flex;
        height: 20px;
        background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.2)};
        width: 1px;
    }
`;

export const StyleRoomHold = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.9)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .container{
        display: flex;
        flex-direction: column;
        .actions{
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            justify-content: center;
            .button-container{
                margin-right: 40px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .label{
            display: flex;
            flex-direction: column;
            text-align: center;
            p{
                margin: 0;
                font-size: 18px;
                font-weight: initial;
                color: ${({ theme }) => theme.black};
            }
        }
    }
`;
export const StyledRecordingBloc = styled.div`
    position: absolute;
    top: -110%;
    left: 20px;
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border-radius: 4px;
    background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.9)};
    box-shadow: 0 0 4px ${({ theme }) => rgba(theme.fixedBlack, 0.15)};
    display: flex;
    align-items: center;
    flex-direction: row;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: all 250ms ease;
    transform: translateY(10px);
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    .icon{
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
    .label{
        color: ${({ theme }) => theme.fixedWhite};
        font-size: 14px;
        font-weight: bold;
        flex: 1;
    }
    .action{
        margin-left: 10px;
        font-size: 12px;
        color: ${({ theme }) => rgba(theme.fixedWhite, 0.4)};
        transition: all 250ms ease;
        cursor: pointer;
        padding: 2px 5px;
        position: relative;
        border-radius: 4px;
        &:hover{
            background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.1)};
        }
    }
`;
