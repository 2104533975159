import * as types from 'types/room';

export const toggleOwnAudio = (_room, isChecked) => ({
    type: types.TOGGLE_OWN_AUDIO,
    _room,
    isChecked
});

export const toggleOwnVideo = (_room, isChecked) => ({
    type: types.TOGGLE_OWN_VIDEO,
    _room,
    isChecked
});

export const toggleOwnScreen = (_room, isChecked) => ({
    type: types.TOGGLE_OWN_SCREEN,
    _room,
    isChecked
});
