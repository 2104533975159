import React from 'react';

import { JoiError as JoiErrorType } from 'sagas/shared/errors';
import config from 'config';

type JoiErrorProps = {
    error: JoiErrorType;
    actions: React.ReactNode;
    ErrorComponent: React.FC<{content: string, subtitle: string, code?: number | string, actions: React.ReactNode}>;
}

function JoiError({ error, actions, ErrorComponent }: JoiErrorProps) {
    const {
        message = undefined,
        statusCode = undefined,
        joi = undefined
    } = error || {};
    
    return (
        <ErrorComponent
            // id="error.default"
            content={`${message} (${statusCode})`}
            subtitle={config.debug && joi && JSON.stringify(joi)}
            code={statusCode}
            actions={actions}
        />
    );
}

export default JoiError;
