import styled, { keyframes }  from 'styled-components';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

const bgAn4 = keyframes`
    0% {
        width: 291.66666667px;
        height: 291.66666667px;
    }
    96% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 291.66666667px;
        height: 291.66666667px;
    }
`;

const bgAn3 = keyframes`
    0% {
        width: 250px;
        height: 250px;
    }
    87% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 250px;
        height: 250px;
    }
`;
const dpA = keyframes`  
    0% {
        transform: scale(1.4);
    }
    90% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1.4);
    }
`;

const bgAn2 = keyframes`
    0% {
        width: 208.33333333px;
        height: 208.33333333px;
    }
    78% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 208.33333333px;
        height: 208.33333333px;
    }
`;

const bgAn1 = keyframes`
    0% {
        width: 166.66666667px;
        height: 166.66666667px;
    }
    69% {
        width: 0px;
        height: 0px;
    }
    100% {
        width: 166.66666667px;
        height: 166.66666667px;
    }
`;

export default  styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 350px;
    margin: 0 auto;
    .call-incoming{
        .avatar-container{
            display: flex;
            align-items: center;
            justify-content: center;
            .avatar-animated-container{
                margin: 40px 0;
                transform: scale(0.8);
                position: relative;
                .bg-circle{
                    top: 50%;
                    left: 50%;
                    right: 0;
                    bottom: 0;
                    background: ${props => props.theme.mainColor2};
                    position: absolute;
                    border-radius: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    &:nth-child(1){
                        width: 166.66666667px;
                        height: 166.66666667px;
                        opacity: 0.025;
                        animation: ${bgAn1} 1.8s ease-in infinite;
                        animation-delay: 0.05s;
                    }
                    &:nth-child(2){
                        width: 208.33333333px;
                        height: 208.33333333px;
                        opacity: 0.05;
                        animation: ${bgAn2} 1.8s ease-in infinite;
                        animation-delay: 0.1s;
                    }
                    &:nth-child(3){
                        width: 250px;
                        height: 250px;
                        opacity: 0.075;
                        animation: ${bgAn3} 1.8s ease-in infinite;
                        animation-delay: 0.15s;
                    }
                    &:nth-child(4){
                        width: 291.66666667px;
                        height: 291.66666667px;
                        opacity: 0.1;
                        animation: ${bgAn4} 1.8s ease-in infinite;
                        animation-delay: 0.2s;
                    }
                }
                .avatar{
                    border-radius: 50%;
                    animation: ${dpA} 1.8s ease-out infinite;
                    animation-delay: 1.10s;
                    transform: scale(1.4);
                    img{
                        border-radius: 50%;
                    }
                }
            }
        }
        .text-container{
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            align-items: center;
            justify-content: center;
            .badge{
                margin-top: 20px;
            }
            .desc{
                font-size: 15px;
                font-weight: 400;
            }
        }
        .list-participants-container{
            display: flex;
            flex-direction: column;
            margin: 20px 0 20px 0;
            justify-content: center;
            text-align: center;
            .label{
                text-transform: uppercase;
                font-size: 10px;
                font-weight: bold;
                color: ${rgba(colors.black, 0.6)};
                margin-bottom: 5px;
            }
        }
        .user-list-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            .avatar-container{
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-left: -10px;
            }
            .avatar,
            .bullet{
                border: 2px solid ${colors.white};
                border-radius: 50%;
            }
            .bullet{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                font-weight: bold;
                background-color: ${colors.black};
                color: ${colors.white};
            }
        }
        .action-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;
            margin-bottom: 20px;
            position: relative;
            .button{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                transition: all 250ms ease;
                cursor: pointer;
                &.add-margin-right{
                    margin-right: 7px;
                }
                &:hover{
                    background-color: ${rgba(colors.black, 0.2)}
                }
                .icon{
                    display: flex;
                    align-items: center;
                    svg{
                        width: 16px;
                        height: 16px;
                        color: ${colors.black};
                    }
                }
            }
            &-line{
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                &.add-margin{
                    margin-bottom: 10px;
                }
                &.flex-end{
                    justify-content: flex-end;
                }
                &.align-end{
                    align-items: flex-end;
                }
            }
            .button-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100px;
                width: 100%;
                &:last-child{
                    margin-right: 0;
                }
                &.centered{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .label{
                    text-transform: uppercase;
                    margin-top: 10px;
                }
            }
        }
    }
`;
