import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import toArray from 'lodash/toArray';
import CustomPropTypes from 'utils/propTypes';
import { StyledGroupAvatar } from 'components/Shared/style';

const FeedIcon = ({
    name,
    color,
    size,
    href
}) => {
    // used to get smiley because '🙂'.length === 2 and lodash can handle that
    const arr = toArray(name);
    const firstLetter = arr.length <= 1 ? name : arr[0];

    return (
        <StyledGroupAvatar
            className={classNames('s-group-avatar', size)}
            style={{ backgroundColor: color }}
            href={href}
        >
            {firstLetter}
        </StyledGroupAvatar>
    );
};

FeedIcon.propTypes = {
    name: PropTypes.string.isRequired,
    color: CustomPropTypes.hexColor,
    size: PropTypes.oneOf(['', 'xs', 'md']),
    href: PropTypes.string
};

FeedIcon.defaultProps = {
    href: null,
    color: '#000CCC',
    size: ''
};

export default memo(FeedIcon);
