import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MainPlayer from 'components/Room/MainPlayer';
import UiIcon from 'components/Shared/Common/Icon';
import { VideoErrorLayout } from 'components/Room/styles';
import { injectIntl } from 'react-intl';
import useMediaDevices from 'hooks/useMediaDevice';
import { Alert } from 'antd';

const VideoStream = ({ intl, value }) => {
    const [stream, streamError] = useMediaDevices('video', value);

    if (!stream || stream.getVideoTracks().length === 0) {
        return (
            <>
                <Alert 
                    type="error" 
                    message={intl.formatMessage({ id: 'rtc.error.noVideoStream' })}
                />
                <VideoErrorLayout className="error">
                    <UiIcon iconName="VideoOff" />
                </VideoErrorLayout>
            </>
        );
    }
    return (
        <MainPlayer
            isVideoOn
            forceMute
            videoStream={stream}
            renderDefault={streamError && <Alert 
                type="error" 
                message={intl.formatMessage({ id: streamError })} 
            />}
        />
    );
}

VideoStream.propTypes = {
    value: PropTypes.string,
    intl: PropTypes.any.isRequired
};

VideoStream.defaultProps = {
    value: undefined
};

export default injectIntl(memo(VideoStream));
