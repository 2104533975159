import { connect } from 'react-redux';

import  { selectors } from '@amplement/backend-connector';
import BasicFeedItemCreator from './index';

const mapStateToProps = (state, ownProps) => ({
    fileUploadingProgression: selectors.feedItems.getFileUploadProgressionByFeedSelector(state, ownProps?.id),
});

export default connect(mapStateToProps, null)(BasicFeedItemCreator);
