import RequestStateFormatter from 'utils/state/requestStateFormatter';
import * as types from 'types/user';
import { ReducerEntityBase } from 'utils/state/reducer';

const initialState = {
    items: { ...RequestStateFormatter.initial() }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONTACT_REQUEST:
        case types.GET_CONTACT_SUCCESS:
        case types.GET_CONTACT_FAILURE:
            return ReducerEntityBase(state, 'items', action, false);
        default:
            return state;
    }
};
