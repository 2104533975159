import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import Button from 'components/Shared/Common/Button';
import { EyeOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import emptyImageSrc from 'statics/images/empty-image-196x196.png';
import styled from 'styled-components';
import classNames from 'classnames';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

const MAX_THUMB_SIZE = 360;

const StyledGif = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${({theme}) => theme.fixedWhite};
    background-color: ${({theme}) => rgba(theme.fixedBlack, 0.2)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 5;
    cursor: pointer;
`;
const StyleImageContainer = styled.div`
    position: relative;
    margin-top: 2px;
    &.min-height{
        .ant-image{
            min-height: 100px;
            background-color: ${colors.lighten};
            &-img{
                min-height: 100px;
                object-fit: contain;        
            }
        }
    }
    img{
        border-radius: 4px;
    }
    &:hover{
        .action,
        .meta{
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
        }
    }
    .action{
        align-items: center;
        display: flex;
        flex-direction: row;
        right: 10px;
        top: 10px;
        transform: translateY(-20px);
        z-index: 4;
        position: absolute;
        transition: all 250ms ease;
    }
    .ant-image-mask{
        border-radius: 4px;
    }
    .meta{
        align-items: flex-start;
        bottom: 5px;
        left: 10px;
        opacity: 0;
        position: absolute;
        transform: translateY(-20px);
        transition: all .25s ease;
        visibility: hidden;
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 2;
        line-height: 1.2;
        .name{
            color: ${({theme}) => theme.fixedWhite};
            font-weight: 600;
        }
        .text{
            color: ${({theme}) => theme.fixedWhite};
            font-weight: 400;
            opacity: .4;
        }
        .name,
        .text{
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            width: 100%;
        }
    }
`;

const calcDimensions = (width, height) => {
    let maxWidthThumb = width;
    let maxHeightThumb = height;

    if (MAX_THUMB_SIZE < height || MAX_THUMB_SIZE < width) {
        if (height > width) {
            const ratio = MAX_THUMB_SIZE / height;
            maxWidthThumb = width * ratio;
            maxHeightThumb =  MAX_THUMB_SIZE;
        } else {
            const ratio = MAX_THUMB_SIZE / width;
            maxWidthThumb = MAX_THUMB_SIZE;
            maxHeightThumb =  height * ratio;
        }
    }

    return {
        width: maxWidthThumb || 194,
        height: maxHeightThumb || 194
    }
}

const FileImageTemplateMask = memo(injectIntl(({
    hrefDownload,
    onDownload,
    title,
    subtitle,
    intl,
    isGif,
    isGifStarted
}) => (
    <>
        {hrefDownload && (
            <div className="action">
                <Button
                    iconName="DownloadCloud"
                    onClick={onDownload}
                    size="md"
                    color="lighten"
                    isTransparent
                    href={hrefDownload}
                />
            </div>
        )}

        <div className="meta">
            <div className="name">{title}</div>
            <div className="text">{subtitle}</div>
        </div>
        {(isGifStarted || !isGif) && (
            <>
                <EyeOutlined /> &nbsp;
                {intl.formatMessage({ id: 'feedItem.preview' })}
            </>
        )}
    </>
)));

const FileImageTemplate = (props) => {
    const {
        src,
        thumb,
        onDownload,
        width: widthProps,
        height: heightProps,
        title,
        subtitle,
        isGif
    } = props;
    const [dimensions, setDimensions] = useState({});
    const [isGifStarted, setIsGifStarted] = useState(false);
    const hrefDownload = src ? src.replace(/&token=.+$/, '') : '';
    const isMinHeight = dimensions?.height < 100;

    useEffect(() => {
        setDimensions(calcDimensions(widthProps, heightProps));
    }, [widthProps, heightProps]);

    // should be used only if backend don't give that information,
    // so, we can preserve the aspect ratio
    const handleImageLoad = useCallback((e) => {
        setDimensions(calcDimensions(e.target?.naturalWidth, e.target?.naturalHeight ));
    }, []);

    const handleStartGif = useCallback(() => {
        setIsGifStarted(!isGifStarted);
    }, [isGif, isGifStarted]);

    return (
        <StyleImageContainer
            className={classNames(
                'styled-image-preview',
                { 'min-height': isMinHeight }
            )}
            style={{
                width: dimensions?.width
            }}
        >
            {(isGif && !isGifStarted) && (
                <StyledGif onClick={handleStartGif}>
                    GIF
                </StyledGif>
            )}
            <Image
                alt={title}
                className="image-preview"
                src={isGifStarted ? src : (thumb || src)}
                onLoad={(!heightProps || !widthProps) ? handleImageLoad : undefined}
                height={dimensions?.height}
                width={dimensions?.width}
                preview={{
                    src,
                    mask: (
                        <FileImageTemplateMask
                            hrefDownload={hrefDownload}
                            onDownload={onDownload}
                            title={title}
                            subtitle={subtitle}
                            isGif={isGif}
                            isGifStarted={isGifStarted}
                        />
                    )}}
                fallback={emptyImageSrc}
            />
        </StyleImageContainer>
    );
}

FileImageTemplate.propTypes = {
    onDownload: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string,
    thumb: PropTypes.string,
    subtitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

FileImageTemplate.defaultProps = {
    width: undefined,
    height: undefined,
    title: null,
    thumb: null,
    subtitle: null
};

export default memo(FileImageTemplate);
