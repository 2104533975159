/* eslint-disable */
import React from 'react';

export default props => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="headphones" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M1,16.0000001 L1,10.0000001 C1,5.0294373 5.0294373,1.00000013 10,1.00000013 C14.9705627,1.00000013 19,5.0294373 19,10.0000001 L19,16.0000001" id="Path" stroke="currentColor" strokeWidth="2"></path>
            <path d="M19,17 C19,18.1045695 18.1045695,19 17,19 L16,19 C14.8954305,19 14,18.1045695 14,17 L14,14 C14,12.8954305 14.8954305,12 16,12 L19,12 L19,17 Z M1,17 C1,18.1045695 1.8954305,19 3,19 L4,19 C5.1045695,19 6,18.1045695 6,17 L6,14 C6,12.8954305 5.1045695,12 4,12 L1,12 L1,17 Z" id="Shape" stroke="currentColor" strokeWidth="2"></path>
        </g>
    </svg>
);
