import { connect } from 'react-redux';
import { selectors } from '@amplement/backend-connector';

import Component from './index';

const mapStateToProps = state => ({
    _rooms: selectors.rooms.getRingingRoomsSelector(state),
});

export default connect(mapStateToProps, null)(Component);
