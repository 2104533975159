import { compose } from 'redux';
import { connect } from 'react-redux';

import injectValidators from 'components/Shared/Forms/injectValidators';
import { selectors } from '@amplement/backend-connector';
import { getIsAuthenticated } from 'selectors/session';
import GuestLogin from './index';

const mapStateToProps = (state) => ({
    isWsOpen: selectors.network.getIsWsOpenSelector(state),
    isAuthenticated: getIsAuthenticated(state),
});

export default compose(
    connect(mapStateToProps, null),
    injectValidators
)(GuestLogin);
