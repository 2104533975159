const CONTENT_RANGE_REGEXP = /([0-9]+) - ([0-9]+) \/ ([0-9]+)/;

export const getPaginationInfos = (response) => {
    const contentRange = response.headers['content-range'];

    if (!contentRange || typeof contentRange !== 'string') {
        return true;
    }

    const matchs = contentRange.match(CONTENT_RANGE_REGEXP);

    if (!matchs || matchs.length < 4) {
        return true;
    }

    const from = parseInt(matchs[1], 10);
    const to = parseInt(matchs[2], 10) + 1;
    const count = parseInt(matchs[3], 10);
    const page = Math.floor(to / (to - from)) || 0;

    return {
        count,
        from,
        to,
        page,
        pageSize: to-from
    };
};


export const hasRemainingItems = (response) => {
    const pagination = getPaginationInfos(response);

    return pagination.to < pagination.count;
};
