import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Action from 'components/Shared/Common/Action';
import ModalConfirm from 'components/Shared/Common/ModalConfirm';
import Icon from 'components/Shared/Common/Icon';

const LeaveButton = memo(injectIntl(({ onClick, intl }) => (
    <Action
        className="line-item"
        onClick={onClick}
    >
        <div className="line">
            <Icon iconName="XCircle" />
        </div>
        <div className="line-wrapper">
            <div className="line column">
                <div className="label">
                    {intl.formatMessage({ id: 'room.actions.delete' })}
                </div>
            </div>
        </div>
    </Action>
)));

const LeaveRoomButton = ({
    intl,
    onClick,
    name,
    onClose
}) => (
    <ModalConfirm
        title={intl.formatMessage({ id: 'room.actions.confirmDelete' }, { name })}
        onConfirm={onClick}
        onClose={onClose}
        TriggerComponent={LeaveButton}
    />
);

LeaveRoomButton.propTypes = {
    intl: PropTypes.any.isRequired,
    name: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired,
    onClick: PropTypes.any.isRequired
};

LeaveRoomButton.defaultProps = {
};

export default memo(injectIntl(LeaveRoomButton));
