import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apis } from '@amplement/backend-connector';
import useSipAccounts from 'hooks/useSipAccounts';
import { AxiosError } from 'axios';

/**
 * https://json-generator.com/
[
  '{{repeat(5, 7)}}',
    {
        id: '{{objectId()}}',
        user_name: {
        last:'{{surname()}}',
        first:'{{firstName()}}'
        },
        user_phone: {
        work_phone: '{{phone()}}',
        mobile_phone: '{{phone()}}',
        fax: '{{phone()}}'
        },
        company_name: '{{company().toUpperCase()}}',
        
        user_phone: function (tags) {
        var rand = tags.integer(0, 1);
        var user_phone = {};
        
        if (tags.integer(0, 1) === 0) {
            user_phone.work_phone = tags.phone();
        }
        if (tags.integer(0, 1) === 0) {
            user_phone.mobile_phone = tags.phone();
        }
        if (tags.integer(0, 1) === 0) {
            user_phone.fax_phone = tags.phone();
        }

        return user_phone;
        }
    }
]
 */

type PhonebookPayload = {
    firstname?: string
    lastname?: string
    company?: string
    email?: string
    mobile?: string
    landline?: string
    fax?: string
    country?: string
    city?: string
    address?: string
    zipcode?: string
}

type PatchPhonebookPayload = {
    _contact: string
} & PhonebookPayload

type PhonebookNumber = {
    displayNumber: string
    callNumber: string
}

type PhonebookType = 'internal' | 'external' | string;

type Phonebook = {
    id: string
    firstname?: string
    lastname?: string
    sipUsername: string
    _user?: string
    type?: PhonebookType
    phones: PhonebookNumber[]
}

export type PhonebookDb = {
    id: string
    user_name?: {
        first?: string
        last?: string
    }
    username: string
    user_phone: {
        work_phone: string
        mobile_phone: string
        fax: string
    }
    card_type: PhonebookType
    sipUsername: string
    _user?: string
}

type PhoneFilterType = 'others' | 'mobile_phone' | 'work_phone' | string | undefined;

const enrichAccount = ({ data, phoneFilterTypes = undefined }: { data: PhonebookDb, phoneFilterTypes?: PhoneFilterType[] }): Phonebook => ({
    id: data.id,
    firstname: data.user_name?.first,
    lastname: data.user_name?.last,
    sipUsername: data.username,
    _user: data._user,
    type: data.card_type,
    // socials: data.socials,
    // phones: Object.keys(data.phones).map(key => ({ type: key, value: data.phones[key] })).filter(x => !!x.value),
    // socials: Object.keys(data.socials).map(key => ({ type: key, value: data.socials[key] })).filter(x => !!x.value)
    phones: data.user_phone && Object.keys(data.user_phone)
        .filter(key => !phoneFilterTypes || phoneFilterTypes.includes(key))
        .map(key => {
            let callNumber = data.user_phone[key];
            if (data.card_type !== 'internal' || key !== 'others') {
                callNumber = data.user_phone[key];
            }
            return { displayNumber: data.user_phone[key], callNumber, type: key };
        })
});

const usePhonebooks = ({ query: queryParam, flat = false, phoneFilterTypes = undefined, type, enabled = true }) => {
    const query = queryParam?.toLowerCase();
    const result = useQuery(
        ['phonebooks', { query, type, phoneFilterTypes, flat }],
        async (): Promise<PhonebookDb[]> => {
            const response = await apis.phonebook.$searchPhonebook(query, type);
            // const mock = await Promise.resolve([{"id":28749,"card_type":"external","user_name":{"first":"Support Efisens"},"user_phone":{"work_phone":"0033969393323"},"work_info":{"company_name":"Efisens"}}]);
            return response.data;
        },
        { 
            refetchOnWindowFocus: false, 
            refetchOnMount: false, 
            enabled, 
            staleTime: 60000, 
            select: (data) => data
                ?.reduce((acc: Phonebook[], c: PhonebookDb) => {
                    const enriched = enrichAccount({ data: c, phoneFilterTypes });
                    const { phones } = enriched || {};

                    if (phones?.length > 1 && flat) {
                        phones.forEach((phone, i) =>
                            acc.push({ ...enriched, id: `${enriched.id}-${i}`, phones: [phone] })
                        );
                    } else if (phones?.length) {
                        acc.push(enriched);
                    }
                    return acc;
                }, [])
                .filter(
                    x =>
                        !query ||
                        x.firstname?.toLowerCase().indexOf(query) !== -1 ||
                        x.lastname?.toLowerCase().indexOf(query) !== -1
                )
        }
    );

    return result;
};

export default usePhonebooks;

export const usePhonebookByUsername = ({ username, _sipAccount, phoneFilterTypes = undefined, enabled = true }) => {
    const { data: accounts } = useSipAccounts();
    const { id = _sipAccount } = accounts?.[0] || {};

    const result = useQuery(
        ['phonebook', { username, id, phoneFilterTypes }],
        async () => {
            if (!apis.sip.$getAccountByUsername) return {};
            
            const response = await apis.sip.$getAccountByUsername(id, username);

            return response.data;
        },
        { 
            refetchOnWindowFocus: false, 
            refetchOnMount: false, 
            enabled, 
            staleTime: 60000,
            select: data => data ? enrichAccount({ data, phoneFilterTypes }) : undefined
        }
    );

    return result;
};

type PhoneBookParams = {
    _sipAccount?: string
    onSuccess?: (response) => void
    onError?: (error: AxiosError) => void
    _contact?: string
}

type PhoneBookSearchParams = {
    search?: string
} & PhoneBookParams;

export const getPhonebookBySearch = ({ search, _sipAccount }: PhoneBookSearchParams = {}) => {
    const { data: accounts } = useSipAccounts();
    const { id = _sipAccount } = accounts?.[0] || {};

    const result = useQuery(
        ['phonebookBySearch', { id, search }],
        async () => {
            if (!apis.sip.$getAccountPhoneBook) return {};
            const response = await apis.sip.$getAccountPhoneBook(id, search);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            staleTime: 1000,
        }
    );

    return result;
}

export const usePhonebookByContact = ({ _sipAccount, _contact }: PhoneBookParams = {}) => {
    const { data: accounts } = useSipAccounts();
    const { id = _sipAccount } = accounts?.[0] || {};

    const result = useQuery(
        ['externalPhonebook', { id, _contact }],
        async () => {
            if (!apis.sip.$getUserPhoneBook) return {};
            const response = await apis.sip.$getUserPhoneBook(id, _contact);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            staleTime: 1000,
        }
    );

    return result;
};

export const usePostPhonebook = ({ _sipAccount, onSuccess, onError }: PhoneBookParams = {}) => {
    const queryClient = useQueryClient();
    const { data: accounts } = useSipAccounts();
    const { id = _sipAccount } = accounts?.[0] || {};

    const mutation = useMutation(
        (payload: PhonebookPayload) => apis.sip.$postUserPhoneBook(id, payload),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('phonebooks');

                if (onSuccess) {
                    queryClient.invalidateQueries('phonebookBySearch');
                    onSuccess(response);
                }
            },
            onError,
        });

    return mutation;
}

export const usePatchPhonebook = ({ _sipAccount, onSuccess, onError }: PhoneBookParams = {}) => {
    const queryClient = useQueryClient();
    const { data: accounts } = useSipAccounts();
    const { id = _sipAccount } = accounts?.[0] || {};

    const mutation = useMutation(
        ({ _contact, ...payload }: PatchPhonebookPayload) => apis.sip.$patchUserPhoneBook(id, _contact, payload),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('phonebooks');

                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });

    return mutation;
}

export const useDeletePhonebook = ({ _sipAccount, onSuccess, onError }: PhoneBookParams = {}) => {
    const queryClient = useQueryClient();
    const { data: accounts } = useSipAccounts();
    const { id = _sipAccount } = accounts?.[0] || {};

    const mutation = useMutation(
        (_contact: string) => apis.sip.$deleteUserPhoneBook(id, _contact),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('phonebooks');
                queryClient.invalidateQueries('phonebookBySearch');

                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });

    return mutation;
}
