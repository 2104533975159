import React, { memo, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import { actions } from '@amplement/backend-connector';

import { LabelInput } from 'components/Shared/Forms';
import { StyledForm } from 'components/Shared/Forms/styles';

import injectValidators from 'components/Shared/Forms/injectValidators';

const ActivityCreator = ({
    intl,
    submit,
    validators
}) => {
    const translations = useMemo(() => ({
        fieldPlaceholder: intl.formatMessage({ id: 'feed.textArea.placeholder' }),
        shareButton: intl.formatMessage({ id: 'global.share' })
    }), [intl]);

    const onSubmit = useCallback(({ content }, form) => {
        if (form.getState().dirty) {
            submit(content);
            form.reset();
        }
    }, [submit]);

    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, submitting, valid, pristine }) => (
                <StyledForm className="flex-row min-height-activity" onSubmit={handleSubmit}>
                    <Field
                        name="content"
                        component={LabelInput}
                        placeholder={translations.fieldPlaceholder}
                        onPressEnter={handleSubmit}
                        autoFocus
                        validate={validators.activityLength}
                        rows="10"
                        rounded
                    />
                    <Button
                        color="primary"
                        isDisabled={submitting || !valid || pristine}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        {translations.shareButton}
                    </Button>
                </StyledForm>
            )}
        </Form>
    );
};

ActivityCreator.propTypes = {
    intl: PropTypes.any.isRequired,
    submit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    submit: (content) => dispatch(actions.activities.createNewActivity(content)),
});

export default compose(
    injectValidators,
    connect(null, mapDispatchToProps),
    injectIntl,
    memo
)(ActivityCreator);
