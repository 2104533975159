import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Space, Input } from 'antd';

import { ForwardToLabel } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Commons';

const ForwardNumberInput = ({ onChange, intl }) => {
    const form = Form.useFormInstance();
    useEffect(() => form.setFieldValue('type', 'NUMBER'), []);

    return (
        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            {/* <div>
                <Button onClick={() => onChange('0649231469')}>06</Button>
                <Button onClick={() => onChange('0033649231469')}>0033</Button>
                <Button onClick={() => onChange('+33649231469')}>+33</Button>
                <Button onClick={() => onChange('+521649231469')}>+521</Button>
                <Button onClick={() => onChange('+5216492')} type="danger">Error</Button>
                <Button onClick={() => onChange('+5216492')} type="danger">Error</Button>
            </div> */}
            <ForwardToLabel />
            <Input
                autoFocus
                placeholder={intl.formatMessage({ id: 'call.forward.typeNumber' })}
                onChange={onChange}
            />
        </Space>
    );
}

ForwardNumberInput.propTypes = {
    intl: PropTypes.any.isRequired,
    onChange: PropTypes.func,
};

ForwardNumberInput.defaultProps = {
    onChange: undefined,
};

export default injectIntl(memo(ForwardNumberInput));
