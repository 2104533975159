// avoid circle dependencies

export default {
    colors: [
        '',
        'primary',
        'secondary',
        'danger',
        'error',
        'success',
        'warning',
        'light-transparency',
        'darken',
        'darken-faded',
        'lighten',
        'transparency',
        'transparency-bordered-primary',
        'secondary-primary'
    ],
    sizes: [
        '',
        'xs',
        'sm',
        'md',
        'lg',
        'xl'
    ],
    tooltipPositions: [
        '',
        'top',
        'bottom',
        'left',
        'right'
    ],
    animations: [
        '',
        'pulse'
    ]
};
