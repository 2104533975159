import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getFileSource, isImage } from 'utils/file';
import FileItem from './Layout';

const FilesItem = ({ feedItem, user, setHighlight }) => {
    const { metasFile: { mimeType, name, extension, thumbnailUri, uri } } = feedItem;

    const getSource = useCallback(
        (download) => getFileSource(thumbnailUri || uri, download),
        [thumbnailUri, uri, getFileSource]);

    const handleDownload = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        const a = document.createElement('a');
        a.href = getSource(true);
        a.download = name;
        a.target = '_blank';
        a.click();
        a.remove();
        return false;
    }, [name]);

    const fileItemProps = useMemo(() => {
        const handleMouseEnter = () => setHighlight?.(feedItem);
        const handleMouseLeave = () => setHighlight?.();

        let props = {
            onClick: handleDownload,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            avatarUri: user?.avatarUri,
            firstname: user?.firstname,
            lastname: user?.lastname,
            fileName: name,
            isDisplayable: false,
            extension,
        };

        if (isImage({ type : mimeType })) {
            props = {
                ...props,
                thumb: getFileSource(thumbnailUri, false), // remove
                src: getFileSource(uri, false), // remove
                isDisplayable: true,
                onClick: ()=>{}
            };
        }

        return props;
    }, [name, user, mimeType, extension, feedItem, handleDownload]);

    return <FileItem {...fileItemProps} />;
}

FilesItem.propTypes = {
    feedItem: PropTypes.object.isRequired,
    user: PropTypes.object,
    setHighlight: PropTypes.func,
}

FilesItem.defaultProps = {
    user: undefined,
    setHighlight: undefined
};

export default memo(FilesItem);
