import { useQuery } from "react-query"
import { $requestGuestLogin } from "api/session";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyIdSelector } from "selectors/company";
import { guestLoginSuccess } from "actions/session";

const useGuestLogin = (firstname, lastname, scope) => {
    const _company = useSelector(getCompanyIdSelector);
    const dispatch = useDispatch();
    
    const result = useQuery(
        ['session/user',  { firstname, lastname, scope, _company } ],
        async () => {
            const response = await $requestGuestLogin(firstname, lastname, scope, _company);

            if (response?.data) {
                dispatch(guestLoginSuccess(response.data));
            }
            
            return { data: response?.data };
        },
        {
            enabled: !!(firstname && lastname && scope && _company)
        }
    )

    return result;
}

export default useGuestLogin;
