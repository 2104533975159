import styled from 'styled-components';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

export default styled.div`
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    .btn{
        display: none;
    }
    :hover .btn{
        display: block;
    }
    .wrapper {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-shrink: 0;
        min-height: 0;
        min-width: 0;
        align-items: flex-start;
        &>.icon {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            position: relative;
            .icon {
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 24px;
                z-index: 3;
                width: 20px;
                height: 20px;
                right: 10px;
                margin: 0;
                border: 2px solid ${props => props.theme.white};
                &.green{
                    background-color: #2ecc71;
                }
                &.purple{
                    background-color: #9013FE;
                }
                svg {
                    color: ${props => props.theme.white};
                    width: 12px;
                    height: 12px;
                }
            }
            .avatar {
                z-index: 2;
                border: 3px solid ${props => props.theme.white};
                margin-right: 15px;
                border-radius: 50%;
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-height: 0;
            min-width: 0;
            flex-shrink: 0;
            .line{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .timestamp{
                display: flex;
                align-items: flex-start;
                font-size: 12px;
                color: ${props => rgba(props.theme.black, 0.6)};
                position: relative;
                margin-left: 3px;
                &:hover{
                    .tooltip{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .tooltip{
                    position: absolute;
                    left: auto;
                    right: 0;
                    transition: all 250ms ease;
                    top: -20px;
                    background-color: ${rgba(colors.black, 0.7)};
                    box-shadow: 0 2px 2px ${rgba(colors.black, 0.15)};
                    white-space: nowrap;
                    color: white;
                    font-size: 10px;
                    padding: 3px 5px;
                    border-radius: 4px;
                    opacity: 0;
                    visibility: hidden;
                }
            }
            .name {
                color: ${props => rgba(props.theme.black, 0.6)};
                font-size: 12px;
                font-weight: 400;
                position: relative;
                display: flex;
                align-items: center;
                >div{
                    margin-right: 3px;
                }
            }
            .text {
                width: 100%;
            }
        }
    }

    &.noBlock{
        background-color: transparent;
        box-shadow: none;
        padding-top: 0;

        .wrapper>.icon {
            position: relative;
        }
        .icon .icon{
            position: absolute;
            top: 24px;
            z-index: 3;
            width: 20px;
            height: 20px;
            right: 10px;
            margin: 0;
            border: 2px solid #FFF;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
`;
