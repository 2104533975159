import React, { createRef, PureComponent } from 'react';

import Preview from 'components/Shared/Previews/Preview/container';

export default class VideoPreview extends PureComponent {
    videoRef = createRef();

    componentDidMount() {
        this.videoRef.current.focus();
    }

    render() {
        return (
            <Preview
                content={(file, name) => (
                    <video
                        id="video-player"
                        src={file}
                        height="500px"
                        controls
                        ref={this.videoRef}
                    >
                        <track label={name} kind="captions" />
                    </video>
                )}
                {...this.props}
            />
        );
    }
}
