import { connect } from 'react-redux';

import { getRouteParams } from 'utils/url';
import  { actions, selectors } from '@amplement/backend-connector';
import FeedView from './index';

const mapStateToProps = (state, ownProps) => {
    const _feed = getRouteParams('_feed')(ownProps);

    return ({
        _feed,
        hasFeed: selectors.feeds.hasFeedByIdSelector(_feed)(state),
        isFeedsLoaded: selectors.feeds.getFeedsLoaded(state)
    });
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const _feed = getRouteParams('_feed')(ownProps);

    return {
        setSelectedFeed: (reset) => dispatch(actions.feeds.setSelectedFeed(_feed, reset)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(FeedView);
