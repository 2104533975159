import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import  { selectors } from '@amplement/backend-connector';

import { getDrawerVisibilitySelector } from 'selectors/smallDrawer';
import { getSoundsStatusSelector } from 'selectors/user';
import { getStatusById } from 'selectors/presence';
import { openModal } from 'actions/modal';
import { openDrawer } from 'actions/drawer';
import { openSmallDrawer, closeAllSmallDrawers } from 'actions/smallDrawer';
import { updateAndPatchUser } from 'actions/session';
import * as smallDrawerTypes from 'types/smallDrawer';
import { DRAWER_ADD_MEMBERS } from 'types/drawer';
import { MODAL_DELETE_GROUP, MODAL_LEAVE_GROUP } from 'types/modal';

import { UNICAST } from 'const/feed';

import Header from './index';

const mapStateToProps = (state, { _feed }) => {
    const feed = selectors.feeds.feedByIdSelector(state, _feed);
    const soundFeeds = getSoundsStatusSelector(state)?.feeds;
    const isMuted = soundFeeds?.[_feed] === false;
    const { name, color, description, user } = feed || {};
    const presence = user?.id ? getStatusById(state, user.id) : undefined;

    return {
        name,
        isMuted,
        color,
        description,
        user,
        presence,
        isFeedUnicast: feed && feed.cast === UNICAST,
        isContactDrawerOpen: getDrawerVisibilitySelector(smallDrawerTypes.FEED_DRAWER_MEMBERS)(
            state
        ),
        isGalleryDrawerOpen: getDrawerVisibilitySelector(smallDrawerTypes.FEED_DRAWER_GALLERY)(
            state
        )
    };
};

const mapDispatchToProps = (dispatch, { _feed }) => ({
    removeFeed: name => dispatch(openModal(MODAL_DELETE_GROUP, { _feed, name })),
    onMute: (isSoundEnabled) => dispatch(updateAndPatchUser('settings', {
        sounds: { feeds: { [_feed]: isSoundEnabled } } })),
    leaveFeed: name => dispatch(openModal(MODAL_LEAVE_GROUP, { _feed, name })),
    onOpenInformationDrawer: () =>
        dispatch(openSmallDrawer(smallDrawerTypes.FEED_DRAWER_INFORMATIONS)),
    onOpenContactsDrawer: () => dispatch(openSmallDrawer(smallDrawerTypes.FEED_DRAWER_MEMBERS)),
    onOpenGalleryDrawer: () => dispatch(openSmallDrawer(smallDrawerTypes.FEED_DRAWER_GALLERY)),
    closeDrawer: () => dispatch(closeAllSmallDrawers()),
    onOpenAddContactsDrawer: () => dispatch(openDrawer(DRAWER_ADD_MEMBERS, { _feed })),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl
)(Header);
