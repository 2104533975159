import React, { memo, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import FeedItemRow from 'components/Feed/FeedItem/FeedItemRow';
import isEqual from 'react-fast-compare';

const FeedItemWrapper = forwardRef((props, ref) => {
    const {
        item,
        style,
        _selected,
        _currentUser,
        onEdit,
        onDelete,
        onReply,
        isAvatarClickable,
        hasStatus,
        Component
    } = props;
    const isSelected = useMemo(() => !!_selected && item?.id === _selected, [_selected, item?.id]);
    const isEditable = useMemo(() => _currentUser === item?._user, [_currentUser, item?._user]);

    return (
        <Component
            ref={ref}
            item={item}
            style={style}
            updateFeedItem={isEditable ? onEdit : undefined}
            deleteFeedItem={isEditable ? onDelete : undefined}
            replyFeedItem={onReply}
            isSelected={isSelected}
            isEditable={isEditable}
            isRelative={item.relative}
            hideAvatar={item.hideAvatar}
            isFailed={item.isFailed}
            isSending={item.isSending}
            failureCode={item.failureCode}
            failureMessage={item.failureMessage}
            hideFullname={item.hideFullname}
            isAvatarClickable={isAvatarClickable}
            hasStatus={hasStatus}
        />
    );
});

FeedItemWrapper.propTypes = {
    item: PropTypes.object,
    style: PropTypes.object,
    _selected: PropTypes.string,
    _currentUser: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onReply: PropTypes.func,
    isAvatarClickable: PropTypes.bool,
    hasStatus: PropTypes.bool,
    Component: PropTypes.any,
};

FeedItemWrapper.defaultProps = {
    item: undefined,
    style: undefined,
    _selected: undefined,
    _currentUser: '',
    onEdit: undefined,
    onDelete: undefined,
    onReply: () => {},
    isAvatarClickable: undefined,
    hasStatus: undefined,
    Component: FeedItemRow,
};

export default memo(FeedItemWrapper, isEqual);
