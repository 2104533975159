import React, { memo } from 'react';
import Icon from 'components/Shared/Common/Icon';

const FilePreviewItem = () => (
    <div className="img-container file">
        <Icon iconName="ZipFile" />
    </div>
);

FilePreviewItem.propTypes = {
};

FilePreviewItem.defaultProps = {
};

export default memo(FilePreviewItem);
