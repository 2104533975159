import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form as FinalForm, Field } from 'react-final-form';

import Actions from 'components/Shared/Forms/Actions';
import { LabelYesNoToggleButton } from 'components/Shared/Forms';
import Button from 'components/Shared/Common/Button';
import desktopNotificationManager from 'services/desktopNotificationManager';
import { StyledContainerInput } from 'components/Shared/styles';

const NotificationInputs = injectIntl(({
    intl,
    input: { name },
}) => {
    const [permission, setPermission] = useState(desktopNotificationManager.getPermission());

    const handleActivate = useCallback(() => {
        desktopNotificationManager.askNotificationPermission()
            .then(setPermission);
    }, []);

    return (
        <>
            {permission === 'default' && (
                <StyledContainerInput className="ui-input-container">
                    <label> {/* eslint-disable-line */}
                        {intl.formatMessage({ id: 'settings.notifications.title' })}
                    </label>
                    <div className="switch-container">
                        <Button
                            onClick={handleActivate}
                            color="transparency-bordered-primary"
                        >
                            {intl.formatMessage({ id: 'global.button.activate' })}
                        </Button>
                    </div>
                </StyledContainerInput>
            )}
            {permission === 'denied' && (
                <StyledContainerInput className="ui-input-container">
                    <label> {/* eslint-disable-line */}
                        {intl.formatMessage({ id: 'settings.notifications.title' })}
                    </label>
                    <div className="switch-container">
                        <Button
                            color="error"
                        >
                            {intl.formatMessage({ id: 'settings.notifications.denied' })}
                        </Button>
                    </div>
                </StyledContainerInput>
            )}
            {permission === 'granted' && (
                <Field
                    component={LabelYesNoToggleButton}
                    label={intl.formatMessage({ id: 'settings.notifications.title' })}
                    name={`${name}.global`}
                />
            )}
        </>
    );
});

const GeneralSettingsForm = ({ initialValues, onSubmit }) => (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, pristine, submitting, valid, form }) => (
            <form onSubmit={handleSubmit}>
                <Field
                    name="desktopNotifications"
                    component={NotificationInputs}
                />

                <Actions
                    className="action"
                    pristine={pristine}
                    reset={form.reset}
                    submitting={submitting}
                    submit={form.submit}
                    valid={valid}
                />
            </form>
        )}
    </FinalForm>
);

GeneralSettingsForm.propTypes = {
    onSubmit: PropTypes.func,
};

GeneralSettingsForm.defaultProps = {
    onSubmit: () => {},
};

export default GeneralSettingsForm;
