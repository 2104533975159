import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { injectIntl } from 'react-intl';

import { Auth } from 'services/authentication';

import RedirectTo from 'screens/Redirects/To';
import { getUrl, isAnonymousAllowedUrl, isGuestAllowedUrl } from 'utils/url';
import { isGuest, isUser } from 'utils/user';
import FullscreenMessageFormatter from 'components/Shared/Errors/Formatter';
import GlobalError from 'components/Shared/Errors/GlobalError';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import Logger from 'services/debug/logger';
import RedirectToHome from 'screens/Redirects/Home';

const logger = new Logger('screen:privateRoute');

const PrivateRoute = ({
    path,
    component: Component,
    roles = [],
    fallbackTo,
    intl,
    ...rest
}) => {
    const { allowAnonymous, allowGuest } = useMemo(() => ({
        allowAnonymous: isAnonymousAllowedUrl(path),
        allowGuest: isGuestAllowedUrl(path),
    }), [path]);

    const { isGuestUser, isAppUser } = useMemo(() => ({
        isGuestUser: isGuest(roles),
        isAppUser: isUser(roles),
    }), [roles]);

    logger.log('Page:config', roles, path, { allowGuest, allowAnonymous, isGuestUser, isAppUser });

    const translations = useMemo(() => ({
        errorForbidden: intl.formatMessage({ id: 'error.forbidden' }),
        errorUnauthorized: intl.formatMessage({ id: 'error.unauthorized' })
    }), [intl]);

    return (
        <Route
            path={path}
            render={props => {
                logger.log('render', path, Auth.getAuth(), (isAppUser || allowAnonymous || (isGuestUser && allowGuest === true)));

                if (isAppUser || allowAnonymous || (isGuestUser && allowGuest)) {
                    logger.log('Complete ', props);
                    return <Component {...props} />;
                }

                if (isGuestUser && !allowGuest) {
                    logger.warn('Guest not allowed');
                    return (
                        <FullscreenMessageFormatter
                            content={translations.errorForbidden}
                            code={403}
                            buttonType={BUTTON_TYPE.CONNECT}
                            render={GlobalError}
                        />
                    );
                }

                if (fallbackTo && Auth.getAuth() === false) {
                    logger.warn('Fallback', fallbackTo);
                    return <RedirectTo to={fallbackTo} params={rest.computedMatch.params} />;
                }

                if (allowGuest) {
                    const { pathname, search } = window.location;
                    const returnUrl = `${pathname}${search}`;
                    logger.warn('Redirect to guest login ', getUrl('loginGuest', { returnUrl }));
                    return <RedirectTo to={getUrl('loginGuest', { returnUrl })} />;
                }

                if (!isAppUser && !allowAnonymous) {
                    logger.warn('Anonymous not allowed');
                } else {
                    logger.warn('error ');
                }

                if (path === getUrl('home') && !isGuestUser) {
                    logger.warn('Not connected, redirect to whitelabel homepage', path, getUrl('home'), isAppUser, isGuestUser);
                    return <RedirectToHome />;
                }

                return (
                    <FullscreenMessageFormatter
                        content={translations.errorUnauthorized}
                        code={401}
                        buttonType={BUTTON_TYPE.CONNECT}
                        render={GlobalError}
                    />
                );
            }}
            {...rest}
        />
    );
}

PrivateRoute.propTypes = {
    intl: PropTypes.any.isRequired,
    component: PropTypes.any.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string,
    fallbackTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

PrivateRoute.defaultProps = {
    roles: [],
    path: undefined,
    fallbackTo: undefined
};

export default memo(injectIntl(PrivateRoute));
