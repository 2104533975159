import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field } from 'react-final-form';

import {
    LabelInput,
} from 'components/Shared/Forms';
import { composeValidators } from 'utils/formValidator';

const formatMessages = intl => ({
    emailPlaceholder: intl.formatMessage({
        id: 'settings.resetEmail.newEmail.placeholder',
        defaultMessage: 'email@domaine.com'
    }),
    emailLabel: intl.formatMessage({
        id: 'settings.resetEmail.newEmail.label',
        defaultMessage: 'Nouvel email'
    }),
    confirmEmailPlaceholder: intl.formatMessage({
        id: 'settings.resetEmail.newEmailConfirm.placeholder',
        defaultMessage: 'email@domaine.com'
    }),
    confirmEmailLabel: intl.formatMessage({
        id: 'settings.resetEmail.newEmailConfirm.label',
        defaultMessage: 'Confirmation de votre email'
    })
});

const EmailInput = ({
    name,
    validators,
    intl,
}) => {
    const i18n = useMemo(() => formatMessages(intl), [intl]);
    const { required, email } = validators;

    return (
        <>
            <Field
                placeholder={i18n.emailPlaceholder}
                validate={composeValidators(required, email)}
                name={`${name}.newEmail`}
                label={i18n.emailLabel}
            >
                {(props) => <LabelInput type="email" {...props} />}
            </Field>

            <Field
                validate={composeValidators(required, email)}
                placeholder={i18n.confirmEmailPlaceholder}
                name={`${name}.confirmEmail`}
                label={i18n.confirmEmailLabel}
            >
                {(props) => <LabelInput type="email" {...props} />}
            </Field>
        </>
    );
}

EmailInput.propTypes = {
    name: PropTypes.string.isRequired,
    validators: PropTypes.object.isRequired,
};

export default memo(injectIntl(EmailInput));
