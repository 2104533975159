import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const Timestamp = ({ intl, date, hasDate, hasTime }) =>
    date ? (
        <span className="timestamp">
            {hasDate && intl.formatDate(date, { day: '2-digit', year: 'numeric', month: 'long' })}
            {hasDate && hasTime ? ' - ' : null}
            {hasTime && intl.formatTime(date)}
        </span>
    ) : null;

Timestamp.propTypes = {
    intl: PropTypes.any.isRequired,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date).isRequired, PropTypes.string.isRequired]).isRequired,
    hasDate: PropTypes.bool,
    hasTime: PropTypes.bool,
};

Timestamp.defaultProps = {
    hasDate: true,
    hasTime: true
};

export default memo(injectIntl(Timestamp));
