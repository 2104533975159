import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Shared/Common/Icon';
import Avatar from 'components/Shared/User/Avatar';
import { Popconfirm } from 'antd';

import { getUrl } from 'utils/url';

import Button from 'components/Shared/Common/Button';
import FormattedDate from 'components/Shared/Common/FormattedDate';
import AutoRefreshDate from 'components/Shared/Common/AutoRefreshDate';
import Style from './styles';

const ActivitiesItemLayout = ({
    iconName,
    noBlock,
    iconColor,
    children,
    lighten,
    onRemove,
    isEdited,
    createdAt,
    user: {
        id: _user,
        lastname,
        avatar,
        avatarUri
    } = {},
    intl: {
        formatTime,
        formatMessage,
    }
}) => (
    <Style 
        className={classNames(
            'item',
            {noBlock}
        )}>
        <div className="wrapper">
            <div className="icon">
                {iconName && (
                    <Icon
                        iconName={iconName}
                        color={iconColor}
                        className={iconColor}
                    />
                )}
                <Avatar
                    src={avatar || avatarUri}
                    alt={lastname}
                    href={getUrl('profile', { _user })}
                    hasStatus={false}
                />
            </div>
            <div className="content">
                <div className="line">
                    <div className="name">
                        {lighten}{' '}
                        {isEdited && (
                            <Button
                                iconName="Edit"
                                size="xs"
                                hasHover
                            />
                        )}
                    </div>
                    {createdAt && (
                        <div className="timestamp">
                            <AutoRefreshDate startDate={createdAt} isRelative />
                            <div className="tooltip">
                                <FormattedDate startDate={createdAt} isRelative={false} />
                                - {formatTime(createdAt)}
                            </div>
                        </div>
                    )}
                </div>
                <div className="line">
                    <div className="text">
                        <div className="content">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            {onRemove && (
                <Popconfirm
                    placement="left"
                    title={formatMessage({ id: 'dashboard.whatYouMissed.remove' })}
                    onConfirm={onRemove}
                    okText={formatMessage({ id: 'button.toggle.on'})}
                    cancelText={formatMessage({ id: 'button.toggle.off'})}
                >
                    <Button
                        iconName="Trash2"
                        color="danger"
                        size="sm"
                    />
                </Popconfirm>
            )}
        </div>
    </Style>
)

ActivitiesItemLayout.propTypes = {
    isEdited: PropTypes.bool,
    createdAt: PropTypes.any,
    intl: PropTypes.any.isRequired,
    iconName: PropTypes.string,
    noBlock: PropTypes.bool,
    iconColor: PropTypes.oneOf(['', 'red', 'purple', 'green']),
    children: PropTypes.any,
    lighten: PropTypes.any,
    onRemove: PropTypes.func,
    user: PropTypes.shape({
        id: PropTypes.string,
        lastname: PropTypes.string,
        avatar: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ])
    })
}

ActivitiesItemLayout.defaultProps = {
    isEdited: false,
    createdAt: new Date(),
    noBlock: false,
    iconName: '',
    iconColor: '',
    children: undefined,
    lighten: '',
    onRemove: undefined,
    user: undefined
}

export default injectIntl(memo(ActivitiesItemLayout));
