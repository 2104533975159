import * as modalTypes from 'types/modal';

const initialState = {
    [modalTypes.MODAL_DELETE_FEEDITEM]: {
        isVisible: false,
        _feedItem: null,
        _feed: null
    },
    [modalTypes.MODAL_UPDATE_FEEDITEM]: {
        isVisible: false,
        feedItem: null,
        _feed: null
    },
    [modalTypes.MODAL_DELETE_ACCOUNT]: {
        isVisible: false
    },
    [modalTypes.MODAL_DELETE_RELATION]: {
        isVisible: false,
        _user: null
    },
    [modalTypes.MODAL_DELETE_GROUP]: {
        isVisible: false,
        _feed: null,
        name: null
    },
    [modalTypes.MODAL_LEAVE_GROUP]: {
        isVisible: false,
        _feed: null,
        name: null
    },
    [modalTypes.MODAL_ROOM_INCOMING]: {
        isVisible: false,
        _initiator: null,
        _room: null
    },
    [modalTypes.MODAL_CALL_INCOMING]: {
        isVisible: false,
        callId: null
    },
    [modalTypes.MODAL_DELETE_MEMBER]: {
        isVisible: false,
        _user: null,
        _feed: null
    },
    [modalTypes.MODAL_KICK_ROOM_USER]: {
        isVisible: false,
        _room: null,
        _user: null
    }
};

export default (state = initialState, action) => {
    const { modalType, type, ...rest } = action || {};

    switch (type) {
        case modalTypes.OPEN_MODAL:
            return {
                ...state,
                [modalType]: {
                    isVisible: true,
                    ...rest
                }
            };

        case modalTypes.CLOSE_MODAL:
            return {
                ...state,
                [modalType]: {
                    isVisible: false
                }
            };

        default:
            return state;
    }
};
