export const RECORDER_STATUS = {
    IDLE: 'idle',
    RECORDING: 'recording',
    PAUSED: 'paused',
    STOPPED: 'stopped',
    ERROR: 'error',
};

export const RECORDER_API_STATUS = {
    NONE: 0,
    STARTED: 1,
    PAUSED: 2,
    ENDED: 3,
};

export default {
    RECORDER_STATUS,
    RECORDER_API_STATUS,
};
