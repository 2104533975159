import { OPEN_PREVIEW, CLOSE_PREVIEW } from 'types/preview';

export const openPreview = _feedItem => ({
    type: OPEN_PREVIEW,
    _feedItem
});

export const closePreview = () => ({
    type: CLOSE_PREVIEW
});
