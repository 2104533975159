import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { selectors } from '@amplement/backend-connector';

import SchedulerInput from 'components/Shared/Forms/Scheduler/SchedulerInput';

const mapStateToProps = (state) => {
    const now  = dayjs();
    const stepNow = now.second(0).minute(Math.ceil(now.minute() / 5) * 5 + 10) // step 5 and add 10mins
    return {
        initialValues: {
            scheduler: {
                type: 'room',
                _entity: selectors.rooms._currentRoomSelector(state),
                startDate: dayjs(stepNow),
                endDate: dayjs(stepNow).add(1, 'hour') // 1h
            }
        }
    };
};

export default connect(mapStateToProps, null)(SchedulerInput);
