export const ACTIVITY = 3;
export const MULTICAST = 2;
export const UNICAST = 1;

export const MEMBER_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    DECLINED: 2
};

export const FILE_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    FILE: 'FILE'
};

export const FILE_TEMPLATES = {
    THUMB: 'THUMB',
    PREVIEW: 'PREVIEW',
    RAW: 'RAW',
    COMPRESSED: 'COMPRESSED',
};
