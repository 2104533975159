import React from 'react';
import { Button, notification } from 'antd';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import { i18n } from '@amplement/backend-connector';
import { QuestionCircleOutlined } from '@ant-design/icons';

const openRemoveNotification = (e) => {
    const key = e.message;
    const handleClose = () => notification.destroy(key);
    const btn = (
        <Button type="primary" size="small" onClick={handleClose}>
            {i18n.translate('global.button.close')}
        </Button>
    );
    let description = i18n.translate(e.message);
    
    if (e.message?.indexOf('.screen.') !== -1) {
        description = (
            <>
                {i18n.translate(e.message)}
                <Button 
                    icon={<QuestionCircleOutlined />}
                    type="link" 
                    style={{padding: 0}}
                    target="_blank" 
                    href="https://my-collaborate.com/troubleshoot-screen-sharing" 
                    onClick={handleClose}
                >
                    {i18n.translate('global.clickHereForHelp')}
                </Button>
            </>
        );
    }
    notification.error({
        message: 'Error',
        description,
        btn,
        key,
        duration: 5
    });
};

export const getStream = (kind, setLocalStream) => {
    if (!kind) return Promise.reject(new Error('Kind must be spécified'));
    
    return DeviceManager.enumerateDevices()
        .then(async devices => {
            const constraints = {
                audio: (kind === 'audio' || kind === '*') && devices?.some(d => d.kind === 'audioinput'),
                video: (kind === 'video' || kind === '*') && devices?.some(d => d.kind === 'videoinput'),
                screen: kind === 'screen'
            };

            // if no device requested
            if (!Object.values(constraints).some(x => x === true)) {
                return Promise.resolve();
            }

            const stream = await DeviceManager.getStream(constraints).catch(openRemoveNotification);

            if (kind === 'screen' || kind === 'video') {
                setLocalStream(stream);
            }

            return stream;
        });
};
