import styled from 'styled-components';
import { rgba, darken } from 'polished';

export default styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex-shrink: 0;
    background-color: ${props => props.bgColor};
    margin-bottom: 20px;
    ${({ hasRadius }) => hasRadius && `
        border-radius: 4px;
    `}
    .title{
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.textColor};
        margin-bottom: 5px;
        span{
            position: relative;
            top: -4px;
            background-color: ${props => rgba(props.textColor, 0.1)};
            padding: 5px 5px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 9px;
        }
    }
    .desc{
        font-size: 12px;
        color: ${props => rgba(props.textColor, 0.6)};
    }
    .action{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        .btn{
            border-radius: 4px;
            padding: 5px 10px;
            margin-right: 10px;
            &.transparency{
                &.lighten{
                    .label{
                        color: ${props => props.textColor};
                    }
                }
            }
            &.primary{
                background-color: ${props => props.textColor};
                .label{
                    color: ${props => props.bgColor};
                }
                &:hover{
                    background-color: ${props => darken(0.05, props.textColor)}!important;
                }
            }
        }
    }
`;
