import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Shared/Common/Button';
import { RECORDER_STATUS, RECORDER_API_STATUS } from 'components/Shared/Recorder/constants';

const StyledButton = styled(Button)`
    .icon{
        svg{
            fill: ${({ theme }) => theme.roomPulseBullet};
            color: ${({ theme }) => theme.roomPulseBullet}!important;
        }
    }
`;

const RecorderButton = ({ recorderState, onStartRecording, onStopRecording, handleChangeStatus }) => {
    const isRecordStopped = recorderState === RECORDER_STATUS.STOPPED;

    const onRecord = useCallback(() => {
        onStartRecording();
        handleChangeStatus(RECORDER_API_STATUS.STARTED);
    }, [onStartRecording, handleChangeStatus]);

    const onStop = useCallback(() => {
        onStopRecording();
        handleChangeStatus(RECORDER_API_STATUS.ENDED);
    }, [onStopRecording, handleChangeStatus]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (!isRecordStopped) {
                onStop();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [isRecordStopped]);

    return (
        <StyledButton 
            onClick={isRecordStopped ? onRecord : onStop}
            iconName={isRecordStopped ? 'Circle' : 'Square'}
            color={isRecordStopped ? 'light-transparency': 'lighten'}
        />
    );
}

RecorderButton.propTypes = {
    recorderState: PropTypes.oneOf(Object.values(RECORDER_STATUS)),
    onStartRecording: PropTypes.func,
    onStopRecording: PropTypes.func,
    handleChangeStatus: PropTypes.func,
};

RecorderButton.defaultProps = {
    recorderState: RECORDER_STATUS.STOPPED,
    onStartRecording: () => {},
    onStopRecording: () => {},
    handleChangeStatus: () => {},
};

export default memo(RecorderButton);
