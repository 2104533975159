import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Css = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-css" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-video" fill={color} fillRule="nonzero">
                <g id="file-ai">
                    {withBorder && <Border />}
                </g>
            </g>
            <g id="Group" transform="translate(17.000000, 49.000000)" fill={color}>
                <polygon
                    id="Fill-8"
                    points="7.45945946 17.15 12.4324324 17.15 12.4324324 7.35 7.45945946 7.35"
                />
                <path
                    d="M9.94594595,9.8 C8.57091892,9.8 7.45945946,8.7024 7.45945946,7.35 C7.45945946,3.2977 10.8062703,0 14.9189189,0 C16.2939459,0 17.4054054,1.0976 17.4054054,2.45 C17.4054054,3.8024 16.2939459,4.9 14.9189189,4.9 C13.5463784,4.9 12.4324324,6.00005 12.4324324,7.35 C12.4324324,8.7024 11.320973,9.8 9.94594595,9.8"
                    id="Fill-9"
                />
                <path
                    d="M2.48648649,26.95 C1.11145946,26.95 0,25.8524 0,24.5 C0,23.1476 1.11145946,22.05 2.48648649,22.05 C5.22659459,22.05 7.45945946,19.85235 7.45945946,17.15 C7.45945946,15.7976 8.57091892,14.7 9.94594595,14.7 C11.320973,14.7 12.4324324,15.7976 12.4324324,17.15 C12.4324324,22.5547 7.97167568,26.95 2.48648649,26.95"
                    id="Fill-10"
                />
                <polygon
                    id="Fill-11"
                    points="7.45945946 41.65 12.4324324 41.65 12.4324324 31.85 7.45945946 31.85"
                />
                <path
                    d="M14.9189189,49 C10.8062703,49 7.45945946,45.7023 7.45945946,41.65 C7.45945946,40.2976 8.57091892,39.2 9.94594595,39.2 C11.320973,39.2 12.4324324,40.2976 12.4324324,41.65 C12.4324324,42.99995 13.5463784,44.1 14.9189189,44.1 C16.2939459,44.1 17.4054054,45.1976 17.4054054,46.55 C17.4054054,47.9024 16.2939459,49 14.9189189,49"
                    id="Fill-12"
                />
                <path
                    d="M9.94594595,34.3 C8.57091892,34.3 7.45945946,33.2024 7.45945946,31.85 C7.45945946,29.14765 5.22659459,26.95 2.48648649,26.95 C1.11145946,26.95 0,25.8524 0,24.5 C0,23.1476 1.11145946,22.05 2.48648649,22.05 C7.97167568,22.05 12.4324324,26.4453 12.4324324,31.85 C12.4324324,33.2024 11.320973,34.3 9.94594595,34.3"
                    id="Fill-13"
                />
                <polygon
                    id="Fill-15"
                    points="39.5675676 41.65 44.5405405 41.65 44.5405405 31.85 39.5675676 31.85"
                />
                <path
                    d="M37.0810811,49 C35.7060541,49 34.5945946,47.9024 34.5945946,46.55 C34.5945946,45.1976 35.7060541,44.1 37.0810811,44.1 C38.4536216,44.1 39.5675676,42.99995 39.5675676,41.65 C39.5675676,40.2976 40.679027,39.2 42.0540541,39.2 C43.4290811,39.2 44.5405405,40.2976 44.5405405,41.65 C44.5405405,45.7023 41.1937297,49 37.0810811,49"
                    id="Fill-16"
                />
                <path
                    d="M42.0540541,34.3 C40.679027,34.3 39.5675676,33.2024 39.5675676,31.85 C39.5675676,26.4453 44.0283243,22.05 49.5135135,22.05 C50.8885405,22.05 52,23.1476 52,24.5 C52,25.8524 50.8885405,26.95 49.5135135,26.95 C46.7734054,26.95 44.5405405,29.14765 44.5405405,31.85 C44.5405405,33.2024 43.4290811,34.3 42.0540541,34.3"
                    id="Fill-17"
                />
                <polygon
                    id="Fill-18"
                    points="39.5675676 17.15 44.5405405 17.15 44.5405405 7.35 39.5675676 7.35"
                />
                <path
                    d="M42.0540541,9.8 C40.679027,9.8 39.5675676,8.7024 39.5675676,7.35 C39.5675676,6.00005 38.4536216,4.9 37.0810811,4.9 C35.7060541,4.9 34.5945946,3.8024 34.5945946,2.45 C34.5945946,1.0976 35.7060541,0 37.0810811,0 C41.1937297,0 44.5405405,3.2977 44.5405405,7.35 C44.5405405,8.7024 43.4290811,9.8 42.0540541,9.8"
                    id="Fill-19"
                />
                <path
                    d="M49.5135135,26.95 C44.0283243,26.95 39.5675676,22.5547 39.5675676,17.15 C39.5675676,15.7976 40.679027,14.7 42.0540541,14.7 C43.4290811,14.7 44.5405405,15.7976 44.5405405,17.15 C44.5405405,19.85235 46.7734054,22.05 49.5135135,22.05 C50.8885405,22.05 52,23.1476 52,24.5 C52,25.8524 50.8885405,26.95 49.5135135,26.95"
                    id="Fill-20"
                />
            </g>
        </g>
    </svg>
);

Css.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

Css.defaultProps = {
    color: '#FFC100',
    withBorder: true
};

export default Css;
