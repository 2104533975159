import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from 'common/themes/Colors';

import { injectIntl } from 'react-intl';

const StyleTitleCountParticipant = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
    &.error{
        .count{
            background-color: ${rgba(colors.error, 0.3)};
            span{
                color: ${colors.error};
            }
        }
    }
    .label,
    .count{
        color: ${({ theme }) => rgba(theme.black, 0.6)};
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .count{
        background-color: ${({ theme }) => rgba(theme.black, 0.2)};
        padding: 5px 10px;
        border-radius: 4px;
    }
`;

const MemberCounter = ({
    memberCount,
    maxMembers,
    intl
}) => (
    <StyleTitleCountParticipant
        className={classNames(
            { error: memberCount > maxMembers }
        )}
    >
        <div className="label">
            {intl.formatMessage({ id: 'room.members.label' })}
        </div>
        {(memberCount || maxMembers) && (
            <div className="count">
                {memberCount && <span>{memberCount}</span>}
                {maxMembers && `/${maxMembers}`}
            </div>
        )}
    </StyleTitleCountParticipant>
);

MemberCounter.propTypes = {
    intl: PropTypes.any.isRequired,
    memberCount: PropTypes.number,
    maxMembers: PropTypes.number,
};

MemberCounter.defaultProps = {
    memberCount: undefined,
    maxMembers: undefined,
};

export default injectIntl(MemberCounter);
