import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AudioStrengthVisualizerButton } from 'components/Shared/Common/AudioStrengthVisualizer';
import SoundMeter, { THRESHOLD_TYPES } from 'components/Shared/Call/SoundMeterStrength';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useMediaDevice from 'hooks/useMediaDevice';
import { Button, Tooltip } from 'antd';
import Icon from 'components/Shared/Common/Icon';

const AudioStrength = memo(({ strength, error }) => {
    if (error) {
        return null;
    }

    return <AudioStrengthVisualizerButton strength={strength} />;
});

const ErrorUi = memo(({ message }) => <Tooltip title={message}>
    <Button 
        style={{marginLeft: 5}} 
        shape="round" 
        type="danger" 
        icon={<Icon iconName="MicOff" size="md" />} 
    />
</Tooltip>);

const AudioStream = (props) => {
    const { intl, value, onStrengthChange } = props;
    const [stream, streamError] = useMediaDevice('audio', value);
    const [errorState, setErrorState] = useState();
    const track = stream?.getAudioTracks()?.[0];

    useWhyDidYouUpdate('component:room:settings:AudioStream', { ...props, track, errorState, stream });

    useEffect(() => {
        setErrorState(undefined);
    }, [track?.id]);

    const content = track?.id ? (
        <SoundMeter
            track={track}
            Component={AudioStrength}
            precision={5}
            onChange={onStrengthChange}
            onError={setErrorState}
            thresholdType={THRESHOLD_TYPES.NUMERIC}
        />
    ) :
        <AudioStrengthVisualizerButton strength={-1} />;

    if (errorState) {
        return <ErrorUi message={errorState} />;
    }

    if (streamError) {
        return <ErrorUi message={intl.formatMessage({ id: streamError })} />;
    }

    return content;
};

AudioStream.propTypes = {
    onStrengthChange: PropTypes.func,
    stream: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    intl: PropTypes.any.isRequired
};

AudioStream.defaultProps = {
    stream: undefined,
    onStrengthChange: undefined
};

export default memo(AudioStream);
