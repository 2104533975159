const initial = () => ({
    isLoading: false,
    updatedAt: null,
    error: null,
    origin: null,
    hash: null,
    payload: null,
    data: null
});

const request = action => (action.isFlush
    ? initial()
    : ({
        isLoading: true,
        error: '',
        hash: action.hash
    }));

const success = action => ({
    isLoading: false,
    updatedAt: new Date(),
    error: '',
    hash: action.hash,
    payload: action.payload,
    origin: action.origin,
    data: action.response?.data
});

const entitySuccess = action => ({
    ...success(action),
    data: action.response.data.result,
    pagination: action.pagination
});

const failure = action => ({
    isLoading: false,
    updatedAt: new Date(),
    error: action.error,
    hash: action.hash,
    payload: action.payload
});

export default {
    request,
    success,
    entitySuccess,
    failure,
    initial
};
