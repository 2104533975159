import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedItemStartupPlaceholder from 'components/Feed/FeedItem/FeedItemStartupPlaceholder/container';
import styled from 'styled-components';

const Styled = styled.div`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    padding: 10px;

`;
class InfiniteScrollLoader extends Component {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            isAutoScroll: true
        };
    }

    componentDidMount() {
        this.previousScrollTop = this.listRef.current.scrollTop;
        this.listRef.current.addEventListener('scroll', this.handleScroll, false);
        setTimeout(this.scrollBottom, 0);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.isAutoScroll === this.state.isAutoScroll;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isAutoScroll || prevProps.id !== this.props.id) {
            setTimeout(this.scrollBottom, 0);
        } else if (snapshot !== null) {
            const offset = (this.listRef.current?.scrollHeight || 0) - snapshot;
            this.scrollTo(offset);
        }
    }

    componentWillUnmount() {
        this.listRef.current.removeEventListener('scroll', this.handleScroll, false);
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.items.length < this.props.items.length) {
            const list = this.listRef.current;
            return list.scrollHeight - list.scrollTop;
        }
        return null;
    }

    // scrollTop = () => {
    //     this.scrollTo(0);
    // }

    scrollBottom = () => {
        if (this.listRef.current && this.listRef.current?.scrollHeight !== undefined) {
            this.scrollTo(this.listRef.current.scrollHeight);
        }
    }

    scrollTo = (offset) => {
        this.listRef.current.scrollTop = offset;
    }

    handleScroll = (e) => {
        const target = e.currentTarget;
        const tolerance = this.props.autoScrollTolerance;

        if (!target) return ;

        if (this.previousScrollTop > target.scrollTop) {
            if (target.scrollTop === 0) {
                this.props.onLoadMoreRows();
            }
        }

        const isToleranceRange = target.clientHeight + target.scrollTop
            > (target.scrollHeight - (target.scrollHeight * tolerance));

        if (!this.state.isAutoScroll && isToleranceRange) {
            this.setState({ isAutoScroll: true });
        } else if (this.state.isAutoScroll && !isToleranceRange) {
            this.setState({ isAutoScroll: false });
        }
        this.previousScrollTop = target.scrollTop;
    };

    handleReplyItem = (...args) => {
        this.props.onReplyItem(...args);
        setTimeout(() => this.scrollTo(this.listRef.current.scrollHeight), 0);
    }

    render() {
        const {
            items,
            id,
            isEndReached,
            onEditItem,
            onDeleteItem,
            _selectedItem,
            _currentUser,
            isAvatarClickable,
            hasStatus,
            ItemComponent,
            prefix: Prefix
        } = this.props;

        return (
            <Styled className="scroll-list" ref={this.listRef}>
                {Prefix && <Prefix containerRef={this.listRef.current} />}
                {isEndReached && (
                    <FeedItemStartupPlaceholder
                        _feed={id}
                    />
                )}
                {items.map((item, index) => (
                    <ItemComponent
                        onEdit={onEditItem}
                        onReply={this.handleReplyItem}
                        onDelete={onDeleteItem}
                        _selected={_selectedItem}
                        _currentUser={_currentUser}
                        isAvatarClickable={isAvatarClickable}
                        hasStatus={hasStatus}
                        item={item}
                        index={index}
                        key={item?.id}
                    />
                ))}
            </Styled>
        );
    }
}

InfiniteScrollLoader.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array,
    onLoadMoreRows: PropTypes.func,
    prefix: PropTypes.any,
    isEndReached: PropTypes.bool.isRequired,
    autoScrollTolerance: PropTypes.number.isRequired,
    onReplyItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
    _selectedItem: PropTypes.string,
    isAvatarClickable: PropTypes.bool,
    hasStatus: PropTypes.bool,
    _currentUser: PropTypes.string,
    ItemComponent: PropTypes.any.isRequired,
};

InfiniteScrollLoader.defaultProps = {
    items: [],
    onReplyItem: () => {},
    onLoadMoreRows: () => { },
    onEditItem: undefined,
    prefix: undefined,
    onDeleteItem: undefined,
    _selectedItem: undefined,
    _currentUser: undefined,
    isAvatarClickable: undefined,
    hasStatus: undefined,
};

export default InfiniteScrollLoader;
