import styled from 'styled-components';
import { rgba } from 'polished';

import CallButton from 'components/Room/RecallButton';

const StyledCallButton = styled(CallButton)`
    flex-direction: row;
    align-items: center;
    height: 32px;
    &.btn {
        border-radius: 30px;
        border: 1px solid ${props => props.theme.mainColor2};
        color: ${props => props.theme.mainColor2};
        &:hover {
            background-color: ${props => rgba(props.theme.mainColor2, 0.05)};
        }
        .icon {
            margin-right: 5px;
            svg{
                font-size: 14px;
            }
        }
    }
`;

export default StyledCallButton;
