import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ActivitiesItem from 'components/Dashboard/MissedInfos/ActivitiesItem';
import ActivityCreator from 'components/Dashboard/MissedInfos/ActivityCreator';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Loader from 'components/Shared/Common/Loader';
import styled from 'styled-components';
import { StyledPageLayout } from 'components/Shared/style';
import Icon from 'components/Shared/Common/Icon';
import Placeholder from 'components/Shared/Common/Placeholder';

const StyledItems = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 20px 20px 0 0;
    margin: 0 -20px 0 0;
    width: 100%;
`;

const Activities = (props) => {
    const { intl, activities, isLoading, _currentUser, getActivities, deleteActivity, editActivity } = props;

    useEffect(getActivities, []);

    const i18n = useMemo(() => ({
        title: intl.formatMessage({ id: 'activities.title' }),
        placeholderTitle: intl.formatMessage({ id: 'dashboard.whatYouMissed.placeholder.title' }), 
        placeholderSubTitle: intl.formatMessage({ id: 'dashboard.whatYouMissed.placeholder.subtitle' })
    }), [intl]);

    const renderActivities = feedItem => (
        <ActivitiesItem
            feedItem={feedItem}
            key={feedItem.id}
            isMe={_currentUser === (feedItem.user || {}).id}
            deleteActivity={deleteActivity}
            editActivity={editActivity}
        />
    );

    if (isLoading) {
        return (
            <div className="interface-content-wrapper">
                <Loader size="md" />
            </div>
        );
    }
    return (
        <StyledPageLayout className="ui-activity">
            <CategoryTitle
                label={i18n.title}
                icon={<Icon size="xl" iconName="Activity" />}
            />
            <ActivityCreator />
            {activities?.length ? (
                <StyledItems className="items">
                    {activities.map(renderActivities)}
                </StyledItems>
            ): (
                <Placeholder
                    title={i18n.placeholderTitle}
                    subTitle={i18n.placeholderSubTitle}
                    name="placeholderConversations"
                />
            )}
            
        </StyledPageLayout>
    );
};

Activities.propTypes = {
    getActivities: PropTypes.func.isRequired,
    activities: PropTypes.array,
    intl: PropTypes.any.isRequired,
    isLoading: PropTypes.bool,
    _currentUser: PropTypes.string.isRequired
}

Activities.defaultProps = {
    activities: [],
    isLoading: false
}

export default injectIntl(Activities);
