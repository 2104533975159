import React from 'react';
import PropTypes from 'prop-types';

import Placeholder from 'components/Shared/Common/Placeholder';

const ContentError = ({ actions, content, subtitle, code, details }) => (
    <div className="error-code">
        <Placeholder
            className="centered error"
            name={code ? "placeholderErrorCode" : "placeholderError"}
            title={content}
            subTitle={subtitle}
            action={actions}
            errorCode={code}
            details={details}
        />
    </div>
);

ContentError.propTypes = {
    actions: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]),
    content: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    code: PropTypes.oneOf(['', 404, 422, 504, 500, 401, 403, 503]),
    details: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ])
};

ContentError.defaultProps = {
    actions: undefined,
    subtitle: undefined,
    code: undefined,
    details: undefined
};

export default ContentError;
