import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Php = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-php" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-ai" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
            </g>
            <g id="Group" transform="translate(17.000000, 55.000000)">
                <text
                    id="?"
                    fontFamily="VAGRoundedStd-Light, VAG Rounded Std"
                    fontSize="55"
                    fontWeight="300"
                    fill={color}
                >
                    <tspan x="17.5" y="42">?</tspan>
                </text>
                <path
                    d="M11.514652,36.3666692 L0,25.0591607 L11.514652,13.75 M48.485348,13.75 L60,25.0591607 L48.485348,36.3666692"
                    id="Stroke-175"
                    stroke={color}
                    strokeWidth="3.36439794"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
);

Php.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};
Php.defaultProps = {
    color: '#816AA1',
    withBorder: true
};

export default Php;
