import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { darken } from 'polished';

import config from 'config';
import Icon from 'components/Shared/Common/Icon';
import { colors } from 'common/themes/Colors';


const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${colors.bgUnsupportedNavigator};
    padding: 5px 20px 10px 20px;
`;

const ContentLeft = styled.div`
    color: white;
    margin-right: 10px;
    .icon {
        svg {
            width: 14px;
            height: 14px;
        }
    }
`;

const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
`;

const Text = styled.div`
    color: white;
    font-size: 12px;
`;

const Button = styled.a`
    align-self: flex-end;
    background-color: white;
    padding: 2px 12px;
    border-radius: 6px;
    color: black;
    &:hover {
        color: black;
        background-color: ${darken(0.1, 'white')}
    }
    transition: all 250ms ease;
`;

const formatMessages = intl => () => ({
    informativeText: intl.formatMessage({
        id: 'menuLeft.rooms.unsupportedbrowser.content',
        defaultMessage: "Votre navigateur ne supporte pas les fonctionnalités Audio/Vidéo de l'application."
    }),
    buttonLabel: intl.formatMessage({
        id: 'menuLeft.rooms.unsupportedbrowser.labelbutton',
        defaultMessage: 'Mettre à jour'
    }),
});

const UnsupportedBrowserComponent = ({ intl }) => {
    const i18n = formatMessages(intl)();

    return (
        <Container>
            <ContentLeft>
                <Icon iconName="PhoneOff" />
            </ContentLeft>
            <ContentRight>
                <Text>{i18n.informativeText}</Text>
                <Button
                    href={`${config.browserUpdateLink}${intl.locale}`}
                    target="_blank"
                >
                    {i18n.buttonLabel}
                </Button>
            </ContentRight>
        </Container>
    );
};

UnsupportedBrowserComponent.propTypes = {
    intl: PropTypes.any.isRequired
};

export default injectIntl(UnsupportedBrowserComponent);
