import { connect } from 'react-redux';

import { getPadSound } from 'actions/call';
import  { selectors } from '@amplement/backend-connector';
import Component from './index';

const mapStateToProps = state => {
    const { prefix, status, sipData } = selectors.sip.getSIPAccount(state) || {};

    return ({
        status,
        sipData,
        prefix
    });
};

const mapDispatchToProps = (dispatch) => ({
    sound: padName => dispatch(getPadSound(padName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
