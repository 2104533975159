import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Shared/Common/Icon';
// import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import Link from 'components/Shared/Common/Link';
import {
    LoadingOutlined,
} from '@ant-design/icons';
import buttonProps from 'components/Shared/Common/Button/Button.props';

const Button = (props) => {
    const {
        children,
        theme,
        iconName,
        onClick,
        isDisabled,
        color,
        size,
        isShadow,
        tooltipPosition,
        tooltipText,
        animation,
        href,
        hasHover,
        isTransparent,
        active,
        className,
        target,
        iconBgColor,
        isLoading,
        ...rest
    } = props;

    // useWhyDidYouUpdate('component:shared:button', props);

    return (
        <Link
            {...rest}
            onClick={!isDisabled && onClick ? onClick : undefined}
            disabled={isDisabled}
            theme={theme}
            className={classNames(
                'btn',
                { [`${color}`]: color },
                { [`${size}`]: size },
                { [`tooltip tooltip-${tooltipPosition}`]: tooltipText },
                { shadow: isShadow },
                { nohover: hasHover },
                { transparency: isTransparent },
                { pulse: animation },
                { active },
                { [`${className}`]: className }
            )}
            href={!isDisabled && href ? href : undefined}
            target={target}
            {...rest}
        >
            {isLoading && <LoadingOutlined />}
            {!!iconName && !isLoading && <Icon iconName={iconName} bgColor={iconBgColor} />}
            {!!children && <div className="label">{children}</div>}
            {!!tooltipText && <div className="tooltip">{tooltipText}</div>}
        </Link>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.element,
        PropTypes.array
    ]),
    isLoading: PropTypes.bool,
    iconName: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    hasHover: PropTypes.bool,
    color: PropTypes.oneOf(buttonProps.colors),
    size: PropTypes.oneOf(buttonProps.sizes),
    isShadow: PropTypes.bool,
    tooltipPosition: PropTypes.oneOf(buttonProps.tooltipPositions),
    animation: PropTypes.oneOf(buttonProps.animations),
    tooltipText: PropTypes.string,
    href: PropTypes.string,
    isTransparent: PropTypes.bool,
    active: PropTypes.bool,
    iconBgColor: PropTypes.string,
    className: PropTypes.string,
    theme: PropTypes.object,
    target: PropTypes.string
};

Button.defaultProps = {
    isLoading: false,
    children: null,
    iconName: null,
    onClick: undefined,
    isDisabled: false,
    size: '',
    color: '',
    isShadow: false,
    animation: '',
    tooltipPosition: '',
    tooltipText: null,
    href: null,
    hasHover: false,
    isTransparent: false,
    active: false,
    iconBgColor: undefined,
    className: null,
    target: null,
    theme: undefined
};

export default memo(Button);
