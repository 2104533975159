/* eslint-disable */
import React from 'react';

export default props => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none">
            <path d="M1963 5105 c-98 -42 -95 -200 4 -242 92 -38 185 24 185 122 0 101 -95 161 -189 120z"/>
            <path d="M3033 5100 c-18 -10 -39 -30 -48 -44 -21 -32 -21 -110 -1 -141 74 -112 246 -63 246 70 0 103 -111 168 -197 115z"/>
            <path d="M891 4439 c-206 -40 -286 -297 -139 -449 128 -131 350 -95 433 70 10 19 20 65 23 101 4 56 1 75 -20 120 -55 120 -172 182 -297 158z"/>
            <path d="M1973 4439 c-211 -39 -293 -299 -141 -451 160 -160 437 -58 456 168 14 170 -146 314 -315 283z"/>
            <path d="M3053 4439 c-135 -25 -232 -149 -221 -283 19 -226 296 -328 456 -168 130 131 89 360 -77 430 -58 25 -105 31 -158 21z"/>
            <path d="M4122 4439 c-82 -16 -151 -73 -190 -158 -21 -45 -24 -64 -20 -120 11 -144 105 -238 249 -249 56 -4 75 -1 120 20 170 78 216 282 95 425 -53 63 -166 99 -254 82z"/>
            <path d="M1895 3481 c-70 -23 -114 -52 -167 -109 -75 -80 -108 -163 -108 -272 0 -159 86 -296 230 -364 60 -29 73 -31 170 -31 97 0 110 2 172 32 85 40 151 106 191 191 30 62 32 75 32 172 0 97 -2 110 -31 170 -69 146 -206 231 -369 229 -38 0 -93 -8 -120 -18z"/>
            <path d="M2984 3485 c-112 -35 -199 -110 -248 -215 -29 -60 -31 -73 -31 -170 0 -97 2 -110 32 -172 40 -85 106 -151 191 -191 62 -30 75 -32 172 -32 97 0 110 2 170 31 207 98 291 349 185 555 -24 46 -102 127 -151 155 -88 52 -226 69 -320 39z"/>
            <path d="M878 3360 c-117 -29 -200 -137 -200 -260 0 -155 134 -281 286 -268 226 19 328 296 168 456 -64 63 -170 93 -254 72z"/>
            <path d="M4102 3356 c-117 -38 -192 -138 -192 -256 0 -182 178 -312 351 -255 223 74 245 386 34 494 -53 27 -138 35 -193 17z"/>
            <path d="M62 3210 c-36 -22 -62 -72 -62 -117 0 -35 31 -87 64 -108 32 -21 110 -21 141 -1 112 74 63 246 -70 246 -23 0 -54 -9 -73 -20z"/>
            <path d="M4912 3210 c-80 -49 -78 -173 3 -226 31 -20 109 -20 141 1 112 73 62 245 -71 245 -23 0 -54 -9 -73 -20z"/>
            <path d="M1899 2406 c-179 -68 -279 -207 -279 -386 0 -110 33 -192 108 -272 81 -86 175 -128 292 -128 159 0 296 86 364 230 29 60 31 73 31 170 0 97 -2 110 -32 172 -40 85 -106 151 -191 191 -59 28 -79 32 -162 34 -61 2 -108 -2 -131 -11z"/>
            <path d="M2957 2396 c-115 -48 -174 -104 -225 -213 -24 -52 -27 -70 -27 -163 0 -97 2 -110 31 -170 68 -144 205 -230 364 -230 117 0 211 42 292 128 75 80 108 162 108 272 0 159 -86 296 -230 364 -56 27 -78 31 -160 34 -82 2 -103 -1 -153 -22z"/>
            <path d="M846 2270 c-139 -55 -206 -219 -146 -357 73 -170 300 -213 432 -81 130 130 91 352 -77 433 -58 27 -148 30 -209 5z"/>
            <path d="M4094 2278 c-54 -18 -126 -80 -153 -133 -34 -64 -37 -179 -7 -239 102 -204 397 -200 486 7 60 139 -9 305 -147 357 -57 21 -128 24 -179 8z"/>
            <path d="M102 2149 c-55 -11 -102 -69 -102 -124 0 -107 122 -172 211 -112 120 81 35 267 -109 236z"/>
            <path d="M4950 2149 c-72 -15 -116 -104 -88 -177 39 -104 201 -109 244 -7 45 108 -40 208 -156 184z"/>
            <path d="M835 1187 c-100 -48 -157 -136 -157 -241 0 -154 113 -268 266 -268 234 -1 353 280 192 450 -48 51 -101 75 -181 79 -59 3 -78 0 -120 -20z"/>
            <path d="M1935 1197 c-121 -47 -194 -163 -180 -283 27 -227 309 -318 463 -150 158 172 32 449 -202 445 -28 0 -65 -6 -81 -12z"/>
            <path d="M3001 1193 c-52 -19 -119 -82 -145 -138 -83 -173 50 -377 244 -377 149 0 264 114 264 261 1 108 -56 201 -150 246 -52 25 -156 29 -213 8z"/>
            <path d="M4100 1201 c-69 -21 -132 -75 -165 -141 -10 -19 -20 -65 -23 -101 -4 -56 -1 -75 20 -120 48 -104 135 -161 244 -161 288 0 371 386 109 508 -51 24 -132 31 -185 15z"/>
            <path d="M1963 256 c-67 -31 -94 -127 -53 -194 22 -36 72 -62 117 -62 35 0 87 31 108 64 21 32 21 110 1 141 -36 55 -114 78 -173 51z"/>
            <path d="M3041 252 c-51 -25 -73 -61 -73 -116 0 -54 19 -89 65 -116 86 -53 197 12 197 115 0 52 -31 102 -75 121 -45 18 -73 18 -114 -4z"/>
        </g>
    </svg>
);
