import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Card, Dropdown, Typography, Button } from 'antd';
import styled from 'styled-components';
import classNames from 'classnames';
import { MoreOutlined } from '@ant-design/icons';

import Avatar from 'components/Shared/User/Avatar';
import UserName from 'components/Shared/User/UserName';
import Link from 'components/Shared/Common/Link';
import ContactButton from 'components/Contacts/Contacts/ContactButton';
import ExternalContactFormActions from 'components/Contacts/RelationCard/ExternalContactFormActions';

import { getUrl } from 'utils/url';
import { PRESENCE_STATUSES } from 'const/user';

const { Meta } = Card;
const { Text } = Typography

const StyledCard = styled(Card)`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    .ant-card{
        &-meta{
            .name{
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        &-actions{
            padding: 12px;
            >li{
                margin: 0;
                padding: 0 12px;
                >span{
                    display: flex;
                    justify-content: center;
                    a{
                        >span{
                            color: ${props => props.theme.black};
                        }
                    }
                }
            }
        }
        &-body{
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }
    & .ant-card-meta-detail > div:not(:last-child) {
        margin-bottom: 0;
    }
    &.external .ant-card-body {
        width: calc(100% - 41px);
    }
`;

const StyledDropdown = styled(Dropdown)`
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
        background-color: transparent !important;
    }
`;

const NoPhoneNumbers = styled(Text)`
    min-height: 32px;
`;

const styles = {
    cover: { position: "relative", width: '100%' },
    bookmarkActions: { position: "absolute", top: '0px', right: '10px', display: 'flex', flexDirection: 'column' },
    avatar: { justifyContent: "center", display: "flex", marginTop: '10px' }
};

const RelationCard = (props) => {
    const {
        intl,
        noClick,
        firstname,
        lastname,
        title,
        avatarUri,
        status,
        hasStatus,
        id: _user,
        renderActions,
        renderBookmarkActions,
        type,
        phones,
    } = props;
    const profileUrl = noClick ? undefined : getUrl('profile', { _user });
    let bookmarkActions;
    const truncateDescription = (desc, limit) => desc?.length > limit ? `${desc.substring(0, limit)}...` : desc;
    const fullname = useMemo(() =>
        `${firstname || (lastname ? '' : intl.formatMessage({ id: 'dashboard.unknown' }))} ${lastname || ''}`, [intl]);

    const actions = useMemo(() => {
        if (type === 'external' && !phones?.length) {
            return [<NoPhoneNumbers>{intl.formatMessage({ id: 'contacts.emptyPhoneNumbers' })}</NoPhoneNumbers>];
        }

        if (renderActions) {
            return renderActions(props);
        }

        return null;
    }, [renderActions, type, phones, intl]);

    if (renderBookmarkActions) {
        bookmarkActions = renderBookmarkActions(props);
    } 

    const description = useMemo(() => truncateDescription(title, 50), [title]);
    const itemsDropdown = useMemo(() => ({
        items: [{
            key: 'externalContactFormEdit',
            label: (
                <ContactButton compact contactId={_user} />
            ),
        }, {
            key: 'externalContactFormActions',
            danger: true,
            label: (
                <ExternalContactFormActions
                    {...props}
                    contactId={_user}
                />
            ),
        }],
    }), [props, _user]);

    return (
        <StyledCard
            hoverable
            className={classNames("contact-cards-item", { 'external': type === 'external' })}
            size="small"
            cover={
                <div style={styles.cover}>
                    <div style={styles.bookmarkActions}>
                        {bookmarkActions}
                        {type === 'external' && (
                            <StyledDropdown 
                                overlayClassName="s-dd-bookmark-action"
                                menu={itemsDropdown}
                                placement="bottomRight"
                                getPopupContainer={() => document.getElementById('app')}
                            >
                                <Button type="text">
                                    <MoreOutlined />
                                </Button>
                            </StyledDropdown>
                        )}
                    </div>
                    <div style={styles.avatar}>
                        <Avatar
                            src={avatarUri}
                            size="xl"
                            hasStatus={hasStatus}
                            status={status}
                            href={profileUrl}
                            alt={fullname}
                            content={fullname}
                        />
                    </div>
                </div>
            }
            actions={actions}
        >
            <Link
                href={profileUrl}
                className="text"
            >
                <Meta
                    title={(
                        <UserName
                            firstname={firstname}
                            lastname={lastname}
                        />
                    )}
                    description={description}
                />
            </Link>
        </StyledCard>
    );
};

RelationCard.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    phones: PropTypes.array,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    title: PropTypes.string,
    avatarUri: PropTypes.string,
    status: PropTypes.oneOf(Object.values(PRESENCE_STATUSES)),
    hasStatus: PropTypes.bool,
    noClick: PropTypes.bool,
    renderActions: PropTypes.func,
    type: PropTypes.oneOf(['internal', 'external']),
    renderBookmarkActions: PropTypes.func,
};

RelationCard.defaultProps = {
    id: null,
    phones: null,
    firstname: null,
    lastname: null,
    title: null,
    avatarUri: undefined,
    status: PRESENCE_STATUSES.OFFLINE,
    hasStatus: true,
    noClick: false,
    renderActions: undefined,
    renderBookmarkActions: undefined,
    type: undefined,
};

export default injectIntl(memo(RelationCard));
