import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// import Logger from 'services/debug/logger';

// const logger = new Logger('component:AutoPatchRoom');

const AutoPatchRoom = ({ devices, toggleAudioVideo }) => {
    const hasAudio = useRef();
    const hasVideo = useRef();

    useEffect(() => {
        const audio = devices?.some(x => x.kind === 'audioinput');
        const video = devices?.some(x => x.kind === 'videoinput');
        const nextAudio = hasAudio.current !== undefined && hasAudio.current !== audio ? audio : undefined;
        const nextVideo = hasVideo.current !== undefined && hasVideo.current !== video ? video : undefined;
        
        if (nextAudio === false || nextVideo === false) {
            toggleAudioVideo(nextAudio, nextVideo, 'autoPatchRoom');
        } 
        
        hasAudio.current = audio;
        hasVideo.current = video;
    }, [devices, toggleAudioVideo]);


    return null;
}

AutoPatchRoom.propTypes = {
    devices: PropTypes.array,
    toggleAudioVideo: PropTypes.func.isRequired
};

AutoPatchRoom.defaultProps = {
    devices: undefined
};

export default memo(AutoPatchRoom);
