import React, { useMemo, memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AntdDrawer from 'components/Shared/Drawers';
import { NOTIFICATION_CATEGORIES } from 'const/notification';
import { connect } from 'react-redux';
import { actions, selectors } from '@amplement/backend-connector';
import { StyledTabs } from 'components/Shared/styles';
import { MessageOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import NotificationList from './List';

const mapStateToProps = state => ({
    _notifications: selectors.notifications.notificationIdsSelector(state),
    isLoading: !state.notifications.loaded,
});

export const NotificationListContainer = connect(mapStateToProps, null)(NotificationList);

const _BlockNotifications = memo(injectIntl(({
    intl,
    category = NOTIFICATION_CATEGORIES.QUICKACTIONS,
    getNotifications,
    markAllReadNotifications,
    cleanNotifications,
}) => {
    const handleSelect = useCallback((key) => {
        if (Object.values(NOTIFICATION_CATEGORIES).indexOf(key) !== -1) {
            getNotifications(key, undefined, 50, 1, undefined, undefined);
        }
    }, [getNotifications]);

    useEffect(() => {
        getNotifications(category, undefined, 50, 1, undefined, undefined);
        return () => {
            markAllReadNotifications();
            cleanNotifications();
        };
    }, []);

    const items = useMemo(() => [{
        key: NOTIFICATION_CATEGORIES.QUICKACTIONS,
        label:(
            <span>
                {intl.formatMessage({ id: 'notification.tabs.all' })}
            </span>
        ),
        children: (
            <NotificationListContainer
                category={NOTIFICATION_CATEGORIES.QUICKACTIONS}
                getNotifications={getNotifications}
            />
        )
    },
    {
        key: NOTIFICATION_CATEGORIES.MEMBER,
        label:(
            <span>
                <MessageOutlined />
                {intl.formatMessage({ id: 'notification.tabs.feedMembers' })}
            </span>
        ),
        children: (
            <NotificationListContainer
                category={NOTIFICATION_CATEGORIES.MEMBER}
                getNotifications={getNotifications}
            />
        )
    },
    {
        key: NOTIFICATION_CATEGORIES.CONTACT,
        label:(
            <span>
                <UsergroupAddOutlined />
                {intl.formatMessage({ id: 'notification.tabs.contacts' })}
            </span>
        ),
        children: (
            <NotificationListContainer
                category={NOTIFICATION_CATEGORIES.CONTACT}
                getNotifications={getNotifications}
            />
        )
    }], [intl]);

    return (
        <StyledTabs
            defaultActiveKey={NOTIFICATION_CATEGORIES.QUICKACTIONS}
            onChange={handleSelect}
            items={items}
        />
    );
}));

_BlockNotifications.propTypes = {
    intl: PropTypes.any.isRequired,
    getNotifications: PropTypes.func.isRequired,
    markAllReadNotifications: PropTypes.func.isRequired,
    cleanNotifications: PropTypes.func.isRequired,
};

_BlockNotifications.defaultProps = {
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    getNotifications: (category, type, limit, page, ignored, read) =>
        dispatch(actions.notifications.requestGetNotifications(category, type, limit, page, ignored, read)),
    markAllReadNotifications: () => dispatch(actions.notifications.requestMarkNotificationsAsRead(ownProps.category)),
    cleanNotifications: () => dispatch(actions.notifications.setNotifications([])),
});

const BlockNotifications = connect(null, mapDispatchToProps)(_BlockNotifications);

const DrawerNotifications = ({
    isOpen,
    intl,
    onClose
}) => (
    <AntdDrawer
        isOpen={isOpen}
        hasHorizontalPadding
        hasNoPadding
        title={intl.formatMessage({ id: 'drawer.notifications.title' })}
        onClose={onClose}
        placement="left"
        destroyOnClose
    >
        <BlockNotifications
            category={NOTIFICATION_CATEGORIES.QUICKACTIONS}
        />
    </AntdDrawer>
)

DrawerNotifications.propTypes = {
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
};

export default memo(injectIntl(DrawerNotifications));
