import { schema } from 'normalizr';

import { presenceSchema } from './presence';

export const user = new schema.Entity(
    'users',
    {
        presence: presenceSchema
    },
    {
        processStrategy: entity => ({
            ...entity,
            presence: entity.id
        })
    }
);
