import { connect } from 'react-redux';

import { getAvailableDevices, getRoomSettings } from 'selectors/roomSettings';
import { setDeviceSettings } from 'actions/roomSettings';
import DeviceListener from './index';

const mapStateToProps = (state) => ({
    devices: getAvailableDevices(state),
    settings: getRoomSettings(state)
});

const mapDispatchToProps = dispatch => ({
    setDeviceSettings: (devices) => dispatch(setDeviceSettings(devices))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListener);

