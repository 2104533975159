import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'antd';
import Action from 'components/Shared/Common/Action';
import FileIcon from 'components/Shared/Common/FileIcon';

import styled from 'styled-components';

const StyledContainer = styled(Action)`
    width: 100%;
    padding-bottom: 100%; 
    position: relative;
    transition: all 250ms ease;

    .ant-image{
        position: initial;
    }
    .ant-image-mask,
    .ant-image img{
        border-radius: 5px;
    }
    &.rounded .ant-image-mask,
    &.rounded .ant-image img{
        border-radius: 20px;
    }
`;

const style = { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: '2px solid #eee' };
const styleNotDisplayable = { position: 'absolute', top: '50%', left: '50%', width: '80%', height: '80%', transform: 'translateY(-50%) translateX(-50%)' };
const Layout = ({
    onClick,
    onMouseEnter,
    onMouseLeave,
    fileName: name,
    extension,
    isDisplayable,
    thumb, 
    src,
}) => (
    <Col xs={24} sm={12} md={6}>
        <StyledContainer
            className={isDisplayable ? 'rounded' : undefined}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <FileIcon
                style={isDisplayable ? style : styleNotDisplayable}
                name={name}
                fileExtension={extension}
                isDisplayable={isDisplayable}
                src={src}
                thumb={thumb}
            />
        </StyledContainer>
    </Col>
);


Layout.propTypes = {
    onClick: PropTypes.func,
    fileName: PropTypes.string.isRequired
};

Layout.defaultProps = {
    onClick: () => null,
};

export default Layout;
