import { connect } from 'react-redux';

import { openModal } from 'actions/modal';
import { MODAL_DELETE_ACCOUNT } from 'types/modal';
import { getCurrentUserSelector } from 'selectors/user';
import { getCompanyHasEmailEditable, getCompanyHasPasswordEditable, getCompanySignUpStatus } from 'selectors/company';

import SettingsBasic from './index';

const mapStateToProps = state => ({
    isLoading: !getCurrentUserSelector(state).settings,
    user: getCurrentUserSelector(state),
    isPasswordEditable: getCompanyHasPasswordEditable(state),
    isEmailEditable: getCompanyHasEmailEditable(state),
    isSignUpAllowed: getCompanySignUpStatus(state),
});

const mapDispatchToProps = dispatch => ({
    onDeleteAccount: () => dispatch(openModal(MODAL_DELETE_ACCOUNT))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsBasic);
