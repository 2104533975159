import React, { useCallback } from 'react';
import PropType from 'prop-types';

import { EditActionButton, DeleteActionButton, ReplyActionButton } from 'components/Feed/FeedItem/FeedItemMessageAction';
import { MetasBot, MetasFile } from 'hooks/useSearch';

type ActionsProps = {
    deleteFeedItem?: (id: string, content: string, isLocal?: boolean) => void;
    replyFeedItem?: (id: string, content: string) => void;
    onEdit?: () => void;
    isEditable?: boolean;
    isFailed?: boolean;
    item: {
        id: string;
        content: string;
        metasFile?: MetasFile;
        metasBot?: MetasBot;
    };
};

const Actions = ({
    deleteFeedItem,
    replyFeedItem,
    onEdit,
    isEditable,
    isFailed,
    item: {
        id,
        content,
        metasFile,
        metasBot
    }
}: ActionsProps) => {
    const handleDelete = useCallback(() => deleteFeedItem?.(id, content), [id, content, deleteFeedItem]);
    const handleDeleteLocal = useCallback(() => deleteFeedItem?.(id, content, true), [id, content, deleteFeedItem]);
    const handleReply = useCallback(() => replyFeedItem?.(id, content), [id, content, deleteFeedItem]);

    const actionComponents: JSX.Element[] = [];

    if (isFailed) {
        actionComponents.push(<DeleteActionButton key="delete" onClick={handleDeleteLocal} />);
    } else {
        if (!metasFile && !metasBot) {
            actionComponents.push(<ReplyActionButton key="reply" onClick={handleReply} />);
        }
        if (isEditable) {
            actionComponents.push(<EditActionButton key="edit" onClick={onEdit} />);
            actionComponents.push(<DeleteActionButton key="delete" onClick={handleDelete} />);
        }
    }

    return actionComponents;
};

Actions.propTypes = {
    deleteFeedItem: PropType.func,
    replyFeedItem: PropType.func,
    onEdit: PropType.func,
    isEditable: PropType.bool,
    isFailed: PropType.bool,
    item: PropType.shape({
        id: PropType.string,
        content: PropType.string
    })
}

Actions.defaultProps = {
    deleteFeedItem: undefined,
    replyFeedItem: undefined,
    onEdit: undefined,
    isEditable: false,
    isFailed: false,
    item: {
        id: '',
        content: ''
    }
}

export default Actions;
