import styled from 'styled-components';
import { colors } from 'common/themes/Colors';

export const Styled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    min-width: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 20px 20px 0;
    align-items: flex-start;
    margin: 0 auto;
    position: relative;
    .ant-tabs{
        display: flex;
        flex: 1;
    }
    form{
        padding-bottom: 20px;
    }
    .subtitle{
        color: ${colors.black};
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .tabs-wrapper{
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-shrink: 0;
        width: 100%;
        min-height: 0;
        min-width: 0;
        height: 100%;
        overflow: auto;
        max-width: 1130px;
        margin: 0 auto;
    }
    .account-delete{
        margin-bottom: 20px;
    }
    .action{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 40px;
        .btn{
            margin-left: 20px;
        }
    }

`;
