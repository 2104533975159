import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Card } from 'antd';
import SimpleSkeleton from './SimpleSkeletonCard';

const PhonebookSkeleton = ({ active, size, buttonShape, block, ...rest }) => (
    <Card
        actions={[
            <Skeleton.Button active={active} size={size} shape={buttonShape} block={block} />,
        ]}
    >
        <SimpleSkeleton active={active} size={size} {...rest} />
    </Card>
)

PhonebookSkeleton.propTypes = {
    active: PropTypes.bool,
    size: PropTypes.string,
    buttonShape: PropTypes.string,
    block: PropTypes.bool,
};

PhonebookSkeleton.defaultProps = {
    active: true,
    size: 'small',
    buttonShape: 'circle',
    block: false
};

export default memo(PhonebookSkeleton);
