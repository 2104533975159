export const POST_AVATAR_REQUEST = 'POST_AVATAR_REQUEST';
export const POST_AVATAR_SUCCESS = 'POST_AVATAR_SUCCESS';
export const POST_AVATAR_FAILURE = 'POST_AVATAR_FAILURE';

export const PATCH_USER_REQUEST = 'PATCH_USER_REQUEST';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_FAILURE = 'PATCH_USER_FAILURE';

export const WS_PUT_USER = 'WS_PUT_USER';
export const COPY_TEXT = 'COPY_TEXT';

export const REQUEST_PATCH_USER_EMAIL = 'REQUEST_PATCH_USER_EMAIL';
export const REQUEST_PATCH_USER_PASSWORD = 'REQUEST_PATCH_USER_PASSWORD';

export const RESET_LOADED_USER = 'RESET_LOADED_USER';
export const POST_AVATAR = 'POST_AVATAR';

export const REQUEST_PATCH_USER = 'REQUEST_PATCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_AND_PATCH_USER = 'UPDATE_AND_PATCH_USER';
export const SET_USER = 'SET_USER';
export const SET_CLIENT = 'SET_CLIENT';
export const REQUEST_GUEST_LOGIN = 'REQUEST_GUEST_LOGIN';

export const TOGGLE_SOUND_NOTIFICATION = 'TOGGLE_SOUND_NOTIFICATION';

export const SET_TAB_FOCUS = 'SET_TAB_FOCUS';

export const APP_READY = 'APP_READY';
export const SET_FATAL_ERROR = 'SET_FATAL_ERROR';
export const SET_CONTENT_ERROR = 'SET_CONTENT_ERROR';
export const SET_LANG = 'SET_LANG';

export const REMOVE_REQUESTED_ACTION = 'REMOVE_REQUESTED_ACTION';
