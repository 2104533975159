import AiFile from 'common/file/icons/Ai';
import CssFile from 'common/file/icons/Css';
import DefaultFile from 'common/file/icons/File';
import HtmlFile from 'common/file/icons/Html';
import ImgFile from 'common/file/icons/Img';
import JsFile from 'common/file/icons/Js';
import JsonFile from 'common/file/icons/Json';
import Mp3File from 'common/file/icons/Mp3';
import PdfFile from 'common/file/icons/Pdf';
import PhpFile from 'common/file/icons/Php';
import PptFile from 'common/file/icons/Ppt';
import PsFile from 'common/file/icons/Ps';
import SketchFile from 'common/file/icons/Sketch';
import VideoFile from 'common/file/icons/Video';
import WordFile from 'common/file/icons/Word';
import XlsFile from 'common/file/icons/Xls';
import ZipFile from 'common/file/icons/Zip';

export const mapIconFile = {
    '.ai': AiFile,
    '.css': CssFile,
    '.html': HtmlFile,
    '.js': JsFile,
    '.jsx': JsFile,
    '.img': ImgFile,
    '.png': ImgFile,
    '.webp': ImgFile,
    '.heic': ImgFile,
    '.mp3': Mp3File,
    '.pdf': PdfFile,
    '.php': PhpFile,
    '.ppt': PptFile,
    '.pptx': PptFile,
    '.psd': PsFile,
    '.sketch': SketchFile,
    '.mp4': VideoFile,
    '.mov': VideoFile,
    '.avi': VideoFile,
    '.doc': WordFile,
    '.docx': WordFile,
    '.xls': XlsFile,
    '.xlsx': XlsFile,
    '.zip': ZipFile,
    '.rar': ZipFile,
    default: DefaultFile
} as const;

export const Icons = {
    AiFile,
    CssFile,
    DefaultFile,
    HtmlFile,
    ImgFile,
    JsFile,
    JsonFile,
    Mp3File,
    PdfFile,
    PhpFile,
    PptFile,
    PsFile,
    SketchFile,
    VideoFile,
    WordFile,
    XlsFile,
    ZipFile
} as const;

export default Icons;
