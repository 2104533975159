import { connect } from 'react-redux';
import  { selectors } from '@amplement/backend-connector';
import BasicLastFiles from './index';

const mapStateToProps = state => ({
    feedItems: selectors.feedItems.getLastFileFeedItems(state),
    isLoading: false
});

export default connect(mapStateToProps, null)(BasicLastFiles);
