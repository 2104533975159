import styled, { css, keyframes } from 'styled-components';
import { rgba, darken } from 'polished';
import { Link } from 'react-router-dom';
import { colors } from 'common/themes/Colors';

// box-shadow: ${props => rgba(props.mainColor2, 0.60)};
// background-color: ${props => rgba(props.mainColor2, 0.20)};
const pulse = keyframes`
    0% { box-shadow: 0 0 0 0 ${rgba(colors.white, 0.6)};}
    70% {box-shadow: 0 0 0 20px transparent;}
    100% {box-shadow: 0 0 0 0 transparent;}
`
const pulseActiveColor = keyframes`
    0% {box-shadow: 0 0 0 0 ${props => rgba(props.theme.mainColor2, 0.4)};}
    70% {box-shadow: 0 0 0 8px transparent;}
    100% {box-shadow: 0 0 0 0 transparent;}
`
const pulseCallSuccess = keyframes`
    0% {box-shadow: 0 0 0 0 ${rgba(colors.success, 0.6)};}
    70% {box-shadow: 0 0 0 10px fade(@white, 0);}
    100% {box-shadow: 0 0 0 0 fade(@white, 0);}
`;

const sizeTemplate = (padding, labelSize, svgSize) => css`
    padding: ${padding}px;
    .label{
        font-size: ${labelSize}px;
    }
    svg{
        height: ${svgSize}px;
        width: ${svgSize}px;
    }
`;

const commonTemplate = (bgColor, labelColor, hoverColor) => css`
    background-color: ${bgColor};
    svg, .label{
        color: ${labelColor};
    }
    &:not(.nohover)&:hover{
        background-color: ${hoverColor};
    }
`;

const commonCss = css`
    &.btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        position: relative;
        cursor: pointer;
        transition: all 250ms ease;
        flex-shrink: 0;
        padding: 10px;
        font-weight: bold;
        input[type="file"]{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }
        &.shadow{
            box-shadow: 0 2px 4px ${rgba(colors.black, 0.15)};
        }
        &[disabled]{
            opacity: 0.2!important;
            &:hover{
                cursor: initial!important;
                background-color: sorry!important;
            }
        }
        .icon{
            display: flex;
            align-items: center;
            //margin-right: 2px;
            svg{
                transition: all 250ms ease;
                font-size: 20px;
            }
            &.emoji{
                width: 16px;
                height: 16px;
                font-size: 16px;
                svg{
                    font-size: 16px;
                }
            }
        }
        &.border-up{
            border-width: 2px;
        }
        >div{
            &:nth-child(2){
                margin-left: 5px;
            }
        }
        .label{
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
        }
        .tooltip{
            display: none;
        }
        &.tooltip{
            &:hover{
                .tooltip{
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(-50%, 0, 0);
                }
            }
            .tooltip{
                display: flex;
                position: absolute;
                left: 50%;
                z-index: 4;
                transition: all 250ms ease;
                background-color: ${colors.bgTooltip};
                color: ${colors.white};
                font-size: 10px;
                font-weight: bold;
                white-space: nowrap;
                flex-shrink: 0;
                padding: 5px 10px;
                margin: 0;
                border-radius: 30px;
                text-transform: uppercase;
                opacity: 0;
                visibility: hidden;
                box-shadow: 0 2px 4px ${rgba(colors.black, 0.15)};
                transform: translate3d(-50%, -10px, 0);
                bottom: 110%;
            }
            &-left{
                &:hover{
                    .tooltip{
                        transform: translate3d(-5%, -50%, 0);
                    }
                }
                .tooltip{
                    transform: translate3d(3%, -50%, 0);
                    right: 100%;
                    left: auto;
                    top: 50%;
                    bottom: auto;
                }
            }
            &-right{
                &:hover{
                    .tooltip{
                        transform: translate3d(5%, -50%, 0);
                    }
                }
                .tooltip{
                    transform: translate3d(-3%, -50%, 0);
                    right: -100%;
                    left: auto;
                    top: 50%;
                    bottom: auto;
                }
            }
            &-bottom{
                .tooltip{
                    bottom: auto;
                    top: 110%;
                    transform: translate3d(-50%, 10px, 0);
                }
            }

        }
        &.pulse{
            animation: ${pulse} 2s infinite;
        }
        &.lg{
            ${sizeTemplate(11, 18, 22)}
            .label{
                font-weight: 700;
            }
        }
        &.xl{
            ${sizeTemplate(14, 24, 32)}
        }
        &.md{
            ${sizeTemplate(8, 14, 16)}
        }
        &.sm{
            ${sizeTemplate(7, 12, 14)}
        }
        &.xs{
            ${sizeTemplate(4, 10, 12)}
        }
        &.bordered-lighten{
            background-color: transparent;
            border: 1px solid ${rgba(colors.black, 0.2)};
            &:not(.nohover)&:hover{
                background-color: ${colors.white};
                border-color: ${rgba(colors.black, 0.3)};
            }
        }
        &.transparency-bordered-primary{
            background-color: transparent;
            border: 1px solid ${rgba(colors.black, 0.1)};
            color: ${colors.black};
            &:not(.nohover)&:hover{
                color: ${colors.white};
                border-color: ${props => props.theme.mainColor2};
                background-color: ${props => props.theme.mainColor2};
            }
        }
        &.bordered-primary-transparency{
            &.label{ // a voir si pertinant
                color: ${props => props.theme.mainColor2};
            }
            border: 1px solid ${props => rgba(props.theme.mainColor2, 0.4)};
            color: ${props => props.theme.mainColor2};
            &:not(.nohover)&:hover{
                border-color: ${props => props.theme.mainColor2};
            }

            ${props => commonTemplate(rgba(props.theme.mainColor2, 0.2), colors.white, darken(0.05, props.theme.mainColor2))}
        }
        &.light-transparency{
            background-color: ${rgba(colors.white, 0.1)};
            color: ${colors.white};
            &:not(.nohover)&:hover{
                background-color: ${rgba(colors.white, 0.4)};
            }
        }
        &.secondary-primary{
            color: ${colors.black};
            ${props => commonTemplate('transparent', colors.black, darken(0.05, props.theme.mainColor2))}
        }
        &.primary{
            ${props => commonTemplate(props.theme.mainColor2, colors.white, darken(0.05, props.theme.mainColor2))}
            &.pulse{
                animation: ${pulseActiveColor} 2s infinite;
            }
        }
        &.light-transparency{
            background-color: ${rgba(colors.white, 0.1)};
            color: ${colors.white};
            &:not(.nohover)&:hover{
                background-color: ${rgba(colors.white, 0.4)};
            }
        }
        &.danger{
            ${commonTemplate(rgba(colors.error, 0.1), colors.error, rgba(colors.error, 0.25))}
        }
        &.error{
            ${commonTemplate(colors.error, colors.white, darken(0.05, colors.error))}
        }
        &.success{
            ${commonTemplate(colors.success, colors.white, darken(0.05, colors.success))}
            .pulse{
                animation: ${pulseCallSuccess} 2s infinite;
            }
        }
        &.warning{
            ${commonTemplate(colors.warning, colors.white, darken(0.05, colors.warning))}
        }
        &.secondary{
            ${props => commonTemplate(props.theme.secondaryActive, props.theme.black, props.theme.secondaryActiveHover)}
        }
        &.darken{
            ${commonTemplate(colors.bgTooltip, colors.white, darken(0.05, colors.bgTooltip))}
        }
        &.darken-faded{
            ${commonTemplate(rgba(colors.bgTooltip, 0.35), colors.white, rgba(colors.bgTooltip, 0.55))}
        }
        &.lighten{
            ${commonTemplate(colors.lighten, colors.black, darken(0.05, colors.lighten))}
        }
        &.transparency{
            svg, .label{
                color: ${colors.black};
            }
            &:not(.nohover)&:hover{
                background-color: ${colors.secondaryActive};
            }

            &.primary{
                color: ${props => props.theme.mainColor2};
                ${props => commonTemplate('transparent', props.theme.mainColor2, rgba(props.theme.mainColor2, 0.15))}
            }
            &.secondary{
                color: ${colors.black};
                ${commonTemplate('transparent', colors.black, rgba(colors.black, 0.15))}
            }
            &.error{
                ${commonTemplate('transparent', colors.error, rgba(colors.error, 0.2))}
            }
            &.lighten{
                ${commonTemplate('transparent', colors.white, rgba(colors.white, 0.2))}
            }
            &.warning{
                ${commonTemplate('transparent', colors.warning, rgba(colors.warning, 0.2))}
            }
            &.success{
                ${commonTemplate('transparent', colors.success, rgba(colors.success, 0.2))}
            }
        }
    }
`;

export const StyledLink = styled(Link)`
    ${commonCss}
`;

export const StyledAnchor = styled.a`
    ${commonCss}
`;

export const StyledDiv = styled.div`
    ${commonCss}
`;
