import { connect } from 'react-redux';

import  { selectors } from '@amplement/backend-connector';
import BasicLastFeedItems from './index';

const mapStateToProps = state => ({
    feeds: selectors.feeds.getFeedsAccepted(state),
    loaded: selectors.feeds.getFeedsLoaded(state),
    isLoading: !selectors.feeds.getFeedsLoaded(state)
});

export default connect(mapStateToProps, null)(BasicLastFeedItems);
