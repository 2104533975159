import {
    all, select, put, delay, take
} from 'redux-saga/effects';
import * as typesRoom from 'types/room';
import * as typesRoomSettings from 'types/roomSettings';

import Logger from 'services/debug/logger';

import { utils, selectors, actions } from '@amplement/backend-connector';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import { getRoomSettings } from 'selectors/roomSettings';

const StreamManager = utils.streamManager;
const PeerManager = utils.peerManager;

const logger = new Logger('saga:room');

export function applyTrack(_room, _client, newTrack, isReplace) {
    try {
        if (PeerManager.replaceTrack && isReplace) {
            PeerManager.replaceTrack({ _entity: _room, newTrack });
        }

        if (StreamManager.replaceTrack) {
            StreamManager.replaceTrack({ _client, _entity: _room, newTrack });
        }
    } catch (e) {
        logger.error(e);
    }
}

function* handleToggleOwnAudio({
    _room,
    isChecked
}) {
    yield put(actions.rooms.requestToggleAudioVideo(_room, isChecked, undefined, undefined, 'handleToggleOwnAudio'));
}

function* handleToggleOwnVideo({
    _room,
    isChecked
}) {
    const myStream = yield select((state) => selectors.rooms.getMyRoomStreamInfoByRoomIdSelector(state, _room));

    if (isChecked && (myStream?.hasScreen || myStream?.isSharingScreen)) {
        yield put(actions.rooms.requestToggleAudioVideo(_room, undefined, undefined, false, 'handleToggleOwnVideo1'));
        yield take('WS_WRTC_PC_CLOSE');
        yield delay(100)
    } 

    yield put(actions.rooms.requestToggleAudioVideo(_room, undefined, isChecked, undefined, 'handleToggleOwnVideo2'));
}

function* handleToggleOwnScreen({
    _room,
    isChecked
}) {
    const myStream = yield select((state) => selectors.rooms.getMyRoomStreamInfoByRoomIdSelector(state, _room));

    if (isChecked && myStream?.hasVideo) {
        yield put(actions.rooms.requestToggleAudioVideo(_room, undefined, false, undefined, 'handleToggleOwnScreen1'));
        yield take('WS_WRTC_PC_CLOSE');
        yield delay(100)
    } 

    yield put(actions.rooms.requestToggleAudioVideo(_room, undefined, undefined, isChecked, 'handleToggleOwnScreen2'));
}

function* handleUpdateOwnStream({
    payload: settings = {}
}) {
    const oldSettings = yield select(getRoomSettings);
    const _room = yield select(selectors.rooms._currentRoomSelector);
    const _client = yield select(selectors.session._currentClientSelector);
    const myStream = yield select((state) => selectors.rooms.getMyRoomStreamInfoByRoomIdSelector(state, _room));
    const tracks = StreamManager.getLocalTracksByEntity(_room);
    logger.log('handleUpdateOwnStream ', tracks, oldSettings, settings, _room, tracks?.audio?.getSettings()?.deviceId, settings.audioinput);
    
    if (!_room) {
        logger.log('handleUpdateOwnStream no room');
        return;
    }

    if (settings.audioinput && myStream?.hasAudio && tracks?.audio?.getSettings()?.deviceId !== settings.audioinput) {
        DeviceManager.getStream({ audio: settings.audioinput }).then(s => {
            const [newTrack] = s?.getAudioTracks() || [];
            logger.log('handleUpdateOwnStream:audioinput', newTrack);
            
            applyTrack(_room, _client, newTrack, true);
        });
    }
    if (settings.videoinput && !myStream?.isSharingScreen && myStream?.hasVideo && tracks?.video?.getSettings()?.deviceId !== settings.videoinput) {
        DeviceManager.getStream({ video: settings.videoinput }).then(s => {
            const [newTrack] = s?.getVideoTracks() || [];
            logger.log('handleUpdateOwnStream:videoinput', newTrack);

            applyTrack(_room, _client, newTrack, true);
        });
    }

    if (settings.audiooutput && (!oldSettings || oldSettings.audiooutput !== settings.audiooutput)) {
        const els = document.querySelectorAll(".video audio");
        logger.log('attach speakers', els);
        const $outputs = [...els];
        $outputs.forEach((element, index) => {
            logger.log('attach speakers', element, settings.audiooutput);
            DeviceManager.attachAudioOutput(settings.audiooutput)(element, index)
            // .catch((e) => {
            //     if (e && e.id) setSpeakerError(e);
            // });
        });
    }
}

export default function* root() {
    yield all([
        utils.sagas.takeEvery(typesRoom.TOGGLE_OWN_AUDIO, handleToggleOwnAudio),
        utils.sagas.takeEvery(typesRoom.TOGGLE_OWN_VIDEO, handleToggleOwnVideo),
        utils.sagas.takeEvery(typesRoom.TOGGLE_OWN_SCREEN, handleToggleOwnScreen),
        utils.sagas.takeEvery(typesRoomSettings.SET_DEVICE_SETTINGS, handleUpdateOwnStream)
    ]);
}
