import { useQuery } from "react-query"
import dayjs from 'dayjs';
import { apiClient as request } from '@amplement/backend-connector';

const $requestScheduler = (data) => 
    request.apiClient({
        url: `/schedules`,
        method: 'POST',
        body: data
    });

const useScheduler = (form) => {
    const result = useQuery(
        ['Scheduler', form],
        async () => {
            const payload = {
                startAt: dayjs(form.startDate),
                endAt: dayjs(form.endDate),
                type: form.type,
                // If entity is not set, then we use an existing room. If it is empty, then we create a new room.
                _entity: form._entity || form._room,
                data: {
                    message: form.description,
                    title: form.subject,
                    _users: form._users,
                    emails: form.emails
                }
            };
            
            const response = await $requestScheduler(payload);

            return response?.data?.data;
        },
        { refetchOnWindowFocus: false, refetchOnMount: false, enabled: !!(form?.subject && form.startDate), retry: 0 }
    )

    return result;
}

export default useScheduler;
