import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Pdf = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-pdf" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero" id="Shape">
                {withBorder && <Border />}
                <path
                    d="M81.21,78.32 C81.14,77.81 80.73,77.17 80.29,76.74 C79.03,75.5 76.26,74.85 72.04,74.79 C69.18,74.76 65.74,75.01 62.12,75.52 C60.5,74.59 58.83,73.57 57.52,72.34 C53.99,69.05 51.05,64.48 49.21,59.45 C49.33,58.98 49.43,58.57 49.53,58.15 C49.53,58.15 51.51,46.87 50.99,43.05 C50.92,42.53 50.87,42.38 50.73,41.97 L50.56,41.53 C50.02,40.28 48.96,38.96 47.3,39.03 L46.32,39 L46.3,39 C44.44,39 42.94,39.95 42.54,41.36 C41.34,45.8 42.58,52.45 44.83,61.05 L44.25,62.45 C42.64,66.39 40.62,70.35 38.84,73.84 L38.61,74.29 C36.73,77.96 35.03,81.08 33.48,83.72 L31.89,84.56 C31.77,84.62 29.04,86.07 28.4,86.45 C22.97,89.7 19.37,93.38 18.77,96.3 C18.58,97.24 18.72,98.43 19.69,98.98 L21.23,99.76 C21.9,100.09 22.61,100.26 23.33,100.26 C27.2,100.26 31.69,95.44 37.88,84.64 C45.02,82.32 53.16,80.38 60.29,79.32 C65.72,82.37 72.4,84.5 76.62,84.5 C77.37,84.5 78.02,84.43 78.54,84.29 C79.35,84.07 80.03,83.61 80.45,82.99 C81.27,81.76 81.43,80.06 81.21,78.32 Z M22.49,97.33 C23.19,95.4 25.99,91.58 30.12,88.2 C30.38,87.99 31.02,87.39 31.6,86.83 C27.28,93.72 24.39,96.46 22.49,97.33 Z M46.95,41 C48.19,41 48.9,44.13 48.96,47.07 C49.02,50.01 48.33,52.07 47.48,53.6 C46.77,51.34 46.43,47.78 46.43,45.45 C46.43,45.45 46.38,41 46.95,41 Z M39.65,81.14 C40.52,79.59 41.42,77.95 42.34,76.22 C44.59,71.97 46.01,68.65 47.06,65.92 C49.16,69.74 51.78,72.99 54.85,75.59 C55.24,75.91 55.65,76.24 56.07,76.57 C49.82,77.8 44.41,79.31 39.65,81.14 Z M79.08,80.79 C78.7,81.02 77.61,81.16 76.91,81.16 C74.65,81.16 71.84,80.13 67.91,78.44 C69.42,78.33 70.81,78.27 72.05,78.27 C74.32,78.27 74.99,78.26 77.22,78.83 C79.44,79.4 79.47,80.55 79.08,80.79 Z"
                    fill={color}
                />
            </g>
        </g>
    </svg>
);

Pdf.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};
Pdf.defaultProps = {
    color: '#FF402F',
    withBorder: true
};

export default Pdf;
