import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NotificationListContainer as NotificationList } from 'components/Shared/Drawers/Notifications';

import { NOTIFICATION_CATEGORIES } from 'const/notification';

import { connect } from 'react-redux';
import { actions, selectors } from '@amplement/backend-connector';
import Spinner2 from 'components/Shared/Common/Spinner2';

const BlockNotifications = memo(({
    category,
    getNotifications,
    markAllReadNotifications,
    cleanNotifications,
    isLoading
}) => {
    useEffect(() => {
        getNotifications(category, undefined, 50, 1, undefined, undefined);
        return () => {
            markAllReadNotifications();
            cleanNotifications();
        };
    }, []);

    if (isLoading) {
        return <Spinner2 />;
    }

    return <NotificationList category={category} />;
});

BlockNotifications.propTypes = {
    category: PropTypes.oneOf(Object.values(NOTIFICATION_CATEGORIES)),
    getNotifications: PropTypes.func.isRequired,
    markAllReadNotifications: PropTypes.func.isRequired,
    cleanNotifications: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

BlockNotifications.defaultProps = {
    category: NOTIFICATION_CATEGORIES.ROOM,
    isLoading: false
};

const mapStateToProps = (state, { category }) => ({
    isLoading: selectors.notifications.getLoadingNotificationByCategory(state, `${category}_all`)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getNotifications: (category, type, limit, page, ignored, read) =>
        dispatch(actions.notifications.requestGetNotifications(category, type, limit, page, ignored, read)),
    markAllReadNotifications: () => dispatch(actions.notifications.requestMarkNotificationsAsRead(ownProps.category)),
    cleanNotifications: () => dispatch(actions.notifications.setNotifications([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockNotifications);
