import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import * as placeholders from 'statics/images/placeholder';
import { rgba } from 'polished';
import Action from './Action';

const StyledImage = styled.div`
    margin: auto;
    max-width: 250px;
    width: 30%;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    .code-error{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 70px;
        color: ${({ theme }) => theme.error};
        margin-top: -11px;
        margin-left: -10px;
    }
    img{
        width: 100%;
        display: flex;
        align-items: flex-start;
        max-width: 100%;
    }
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    a{
        color: ${props => props.theme.mainColor2};
    }
    .text{
        font-size: 18px;
        color: ${({ theme }) => theme.black};
        font-weight: 700;
        .name{
            display: inline;
        }
    }
    .action{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-self: flex-start;
        >div{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .subtext{
        font-size: 14px;
        color: ${({ theme }) => rgba(theme.black, 0.6)};
        font-weight: 400;
        &.flex-row{
            flex-direction: row;
            .btn{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &.flexed{
            display: flex;
            align-items: center;
            justify-content: center;
            .btn{
                margin-top: 20px;
            }
        }
    }
`;

const Styled = styled(Action)`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 10px 15px;
    width: 100%;
    position: relative;
    &.error{
        ${StyledImage}{
            position: relative;
            display: flex;
            justify-content: center;
        }
        ${StyledContent}{
            .text{
                color: ${({ theme }) => theme.error};
            }
        }

    }
    &.lg{
        ${StyledImage}{
            max-width: 500px;
        }
    }
    &.left{
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        ${StyledContent}{
            text-align: left;
            .action{
                align-self: flex-start;
            }
        }

    }
    &.centered{
        align-items: center;
        text-align: center;
        justify-content: center;
        ${StyledContent}{
            text-align: center;
            .action{
                align-self: center;
            }
        }
    }
    &.bordered{
        margin-bottom: 10px;
        border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.05)};
    }
`;

class Placeholder extends PureComponent {
    constructor(props) {
        super(props);
        this.images = Object.keys(placeholders)
            .filter(x => [props.name, `${props.name}2`, `${props.name}3`].indexOf(x) !== -1)
            .map(x => placeholders[x]);
    }

    getSrcSet = (x, index) => `${x} ${index + 2}x`;

    render() {
        const [src = null, ...extraSrc] = this.images;
        const {
            position,
            title,
            subTitle,
            className,
            onClick,
            size,
            action,
            errorCode
        } = this.props;
        const hasError = errorCode && this.props.name === 'placeholderErrorCode';

        return (
            <Styled
                className={classNames(
                    'styled-placeholder',
                    className,
                    position,
                    size,
                    { error : !!hasError }
                )}
                onClick={onClick}
            >
                <StyledImage>
                    <img
                        src={src}
                        srcSet={extraSrc.map(this.getSrcSet)}
                        alt=''
                    />
                    {hasError && <div className="code-error">{errorCode}</div>}
                </StyledImage>
                {(title || subTitle || action) && (
                    <StyledContent>
                        {title && (
                            <div className="text">
                                {title}
                            </div>
                        )}
                        {subTitle && (
                            <div className="subtext">
                                {subTitle}
                            </div>
                        )}
                        {action && (
                            <div className="action">
                                {action}
                            </div>
                        )}
                    </StyledContent>
                )}
            </Styled>
        );
    }
}

export const names = [
    'placeholderNotification',
    'placeholderConversations',
    'placeholderFiles',
    'placeholderGroups',
    'placeholderSIP',
    'placeholderSuggestions',
    'placeholder404',
    'placeholderError',
    'placeholderErrorCode'
];
export const positions = [
    'centered',
    'left',
    'bordered'
];
export const sizes = [
    null,
    'md',
    'lg'
];
Placeholder.propTypes = {
    name: PropTypes.oneOf(names).isRequired,
    position: PropTypes.oneOf(positions),
    size: PropTypes.oneOf(sizes),
    className: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    subTitle: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.element,
        PropTypes.string
    ]),
    errorCode: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.number,
        PropTypes.string
    ]),
    onClick: PropTypes.func
};

Placeholder.defaultProps = {
    onClick: null,
    position: 'centered',
    title: null,
    className: '',
    subTitle: null,
    action: null,
    size: null,
    errorCode: null
};

export default Placeholder;
