import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Header from 'components/Room/Header/container';
import ParticipantList from 'components/Room/ParticipantList';
import Participant, { Away as ParticipantAway } from 'components/Room/Participant/container';
import ParticipantListFold from 'components/Room/ParticipantListFold';
import MainParticipant from 'components/Room/MainParticipant/container';
import Footer from 'components/Room/Footer/container';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import classNames from 'classnames';
import { constants } from '@amplement/backend-connector';
import { Grid } from 'antd';
import Styled from 'components/Room/styles';
import ContentMask from 'components/Room/containers/ContentMask';
import NetworkErrorToaster from 'components/Room/NetworkErrorToaster';
import Recorder from 'components/Shared/Recorder';

const { useBreakpoint } = Grid;

const RoomScreen = (props) => {
    const {
        _room,
        onFocusMember,
        _onlineClients,
        _awayUsers,
        isFeatureFeed,
        displayMode,
        onUnfocusAllMember,
        isHold,
        isFeatureDtmf
    } = props;

    useWhyDidYouUpdate('component:room:RoomScreen', props);

    const screens = useBreakpoint();

    const [isFold, setIsFold] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState();
    const [isInterfaceDisplayed, setInterfaceDisplay] = useState(true);
    const hasGalleryMode = _onlineClients?.length > 1;
    const isGalleryMode = displayMode === constants.rooms.ROOM_DISPLAY_MODE.GRID && hasGalleryMode;

    useEffect(() => {
        setIsChatOpen(!screens?.xs && isFeatureFeed && !isHold);
    }, [screens?.xs, isFeatureFeed, isHold]);

    const toggleInterface = useCallback(() => {
        if (isGalleryMode || isFeatureDtmf) {
            return;
        }
        if (isInterfaceDisplayed) {
            setIsFold(true);
        } else {
            setIsFold(false);
        }
        setInterfaceDisplay((curr) => !curr);
    }, [setInterfaceDisplay, isInterfaceDisplayed, isGalleryMode, isFeatureDtmf]);

    const handleDisplayMode = useCallback((mode) => {
        if (mode === constants.rooms.ROOM_DISPLAY_MODE.GRID) {
            onUnfocusAllMember();
        } else {
            onFocusMember(_onlineClients?.[0]);
        }
    }, [_onlineClients?.[0]]);

    return (
        <Styled
            participantsCount={_onlineClients.length}
            className={classNames(
                { gallery: isGalleryMode },
                { 'drawer-right': isChatOpen },
            )}
        >
            <span className="overlay" />
            <span className="overlay bottom" />
            <Header
                _room={_room}
                onFocusMember={!isGalleryMode ? onFocusMember : undefined}
                className={classNames("header", {
                    'hide-interface': !isInterfaceDisplayed,
                })}
            />

            {_onlineClients && (
                <>
                    {!isGalleryMode && (
                        <MainParticipant
                            className="video"
                            _room={_room}
                            onToggleInterface={toggleInterface}
                        />
                    )}
                    <div className="list-participants">
                        <ParticipantList
                            isOpen={isFold}
                            className="participant-list"
                        >
                            {_onlineClients.map(o => (
                                <Participant
                                    key={o}
                                    isGalleryMode={isGalleryMode}
                                    onToggleFocus={onFocusMember}
                                    _client={o}
                                    _room={_room} // for kick
                                />
                            ))}
                            {!isGalleryMode && _awayUsers.map(o => (
                                <ParticipantAway
                                    key={o}
                                    _user={o}
                                    _room={_room} // for kick
                                />
                            ))}

                        </ParticipantList>
                        {_onlineClients?.length || _awayUsers?.length ?
                            <ParticipantListFold
                                isInterfaceDisplayed={isInterfaceDisplayed}
                                isFold={isFold}
                                onFold={setIsFold}
                            />
                            : null}
                    </div>
                </>
            )}

            <Recorder showInterface={!isFold} _room={_room}>
                <Footer
                    _room={_room}
                    onDisplayModeChange={handleDisplayMode}
                    _onlineClients={_onlineClients}
                    displayMode={displayMode}
                    hasGalleryMode={hasGalleryMode}
                    isInterfaceDisplayed={isInterfaceDisplayed}
                    onFold={setIsFold}
                    className={classNames("footer", {
                        'hide-interface': !isInterfaceDisplayed,
                    })}
                    isChatOpen={isChatOpen}
                    onToggleChat={setIsChatOpen}
                />

                <ContentMask _room={_room} />
            </Recorder>

            <NetworkErrorToaster />
        </Styled>
    );
}

RoomScreen.propTypes = {
    _room: PropTypes.string.isRequired,
    isFeatureFeed: PropTypes.bool,
    isHold: PropTypes.bool,
    onFocusMember: PropTypes.func.isRequired,
    _onlineClients: PropTypes.arrayOf(PropTypes.string).isRequired,
    _awayUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
    isFeatureDtmf: PropTypes.bool
};

RoomScreen.defaultProps = {
    isFeatureFeed: false,
    isHold: undefined,
    isFeatureDtmf: false
};

export default injectIntl(RoomScreen);
