import styled from 'styled-components';
import { rgba } from 'polished';

export default styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    >label{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .ant-checkbox{
            margin-right: 10px;
        }
    }
    &.offline{
        .user-wrapper{
            .avatar{
                opacity: 0.4;
            }
            .label{
                color: ${({ theme }) => rgba(theme.black, 0.6)};
            }
            .badge{
                &.admin{
                    opacity: 0.4;
                }
            }
        }
    }
    .btn{
        padding: 5px 10px;
        background-color: ${({ theme }) => rgba(theme.black, 0.4)};
        &:hover{
            background-color: ${({ theme }) => rgba(theme.black, 0.6)};
        }
    }
    .user-wrapper{
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        min-width: 0;
        justify-content: flex-start;
        .label{
            color: ${({ theme }) => theme.black};
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            font-weight: 500;
            font-size: 14px;
            min-width: 0;
            line-height: initial;
            min-height: 0;
            .name,
            .title{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
            .title{
                font-weight: 400;
                color: ${({ theme }) => rgba(theme.black, 0.6)};
            }
            .badge{
                &-container{
                    margin-top: 3px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .badge{
                        margin-right: 10px;
                    }
                }
                font-size: 8px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding: 5px 10px;
                border-radius: 30px;
                border: 1px solid;
                box-shadow: none;
                &.offline{
                    border-color: ${({ theme }) => rgba(theme.black, 0.1)};
                    color: ${({ theme }) => rgba(theme.black, 0.4)};
                }
                &.admin{
                    background-color: transparent;
                    border-color: ${rgba('#fff4bf', 0.4)};
                    color: #d9b200;
                }
            }
            .icon{
                margin-right: 5px;
                svg{
                    color: gold;
                    width: 14px;
                    height: 14px;
                }
            }

        }
    }
    .avatar{
        margin-right: 10px;
        width: 40px;
        display: flex;
        flex-shrink: 0;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img{
            max-width: 100%;
        }
    }
`;
