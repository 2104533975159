import { Logger, utils } from '@amplement/backend-connector';

import soundManager from 'services/soundManager';

const logger = new Logger('utils:callManager');

class CallManager {
    #soundEnabled = true;

    _ringTone = {};

    _ringBackTone = false;

    start = ({ kind = 'audio', target, ringbackTone = false, _room }) => {
        if (!this.#soundEnabled) {
            logger.log('start:skip', { kind, target, ringbackTone });
            return;
        }
        logger.log('start', { kind, target, ringbackTone });
        this.stopRingtone({ _room });
        if (ringbackTone) {
            this.startRingback();
        }
    };

    stop = ({ _room }) => {
        if (!this.#soundEnabled) {
            logger.log('stop:skip', { _room });
            return;
        }
        logger.log('stop', { _room });
        this.stopRingtone({ _room });
        this.stopRingback();
    };

    startRingtone = ({ _room }) => {

        if (!this.#soundEnabled) {
            logger.log('startRingtone:skip');
            return;
        }

        if (!_room) {
            utils.errorHandler.captureException(
                new Error('startRingtone without _room'),
                'startRingtone',
                { _room },
            );
        }
        
        if (!this._ringTone[_room]) {
            logger.log('startRingtone', _room, this._ringTone[_room]);
            soundManager.play('incomingRinging')
         
            this._ringTone[_room] = true;
        }
    };

    stopRingtone = ({ _room }) => {

        if (!_room) {
            utils.errorHandler.captureException(
                new Error('stopRingtone without _room'),
                'stopRingtone',
                { _room },
            );
            this._ringTone = {};
        }

        if (this._ringTone[_room]) {
            delete this._ringTone[_room];
        }

        logger.info('stopRingtone', _room, this._ringTone[_room]);
        if (this._ringTone && !Object.keys(this._ringTone).length) {
            soundManager.stop('incomingRinging');
        }
    };

    stopRingback = () => {
        if (!this.#soundEnabled) {
            logger.log('stopRingback:skip');
            return;
        }
        logger.log('stopRingback');
        soundManager.stop('outgoingRinging');
        this._ringBackTone = false;
    };

    startRingback = () => {
        if (!this.#soundEnabled) {
            logger.log('startRingback:skip');
            return;
        }
        
        if (!this._ringBackTone) {
            logger.log('startRingback', this._ringBackTone);
            soundManager.play('outgoingRinging', 10000)
            this._ringBackTone = true;
        }
    };
}

export default utils.debug.addDebugAttribute(new CallManager(), 'CallManager');
