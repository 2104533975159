import React, { memo } from 'react';
import { TourProvider } from "@reactour/tour";

const badgeContent = ({ totalSteps, currentStep }) => `${currentStep + 1}/${totalSteps}`;

const CustomTourProvider = ({ children }) => (
    <TourProvider badgeContent={badgeContent}>
        {children}
    </TourProvider>
);

export default memo(CustomTourProvider);
