import { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const JoinTemplate = ({ intl }) => intl.formatMessage({
    id: 'feedItem.joinStatus',
    defaultMessage: 'a rejoint le groupe'
});

JoinTemplate.propTypes = {
    intl: PropTypes.object.isRequired
};

JoinTemplate.defaultProps = {
};

export default memo(injectIntl(JoinTemplate));
