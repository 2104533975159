import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getUrl, getRouteParams, getQueryStringParameter } from 'utils/url';
import FullscreenLoader from 'components/Shared/Layout/FullscreenLoader';
import FullscreenMessageFormatter from 'components/Shared/Errors/Formatter';
import RoomFullscreenMessage from 'components/Shared/Errors/RoomFullscreenMessage';
import Logger from 'services/debug/logger';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import { actions, constants, selectors, callbacks } from '@amplement/backend-connector';
import UserCheckboxSelector from 'components/Shared/Room/UserCheckboxSelector';

const logger = new Logger('screen:redirect:room');

class RedirectToRoom extends PureComponent {
    countDownError = setTimeout(() => !this.state.pendingRoomInfo && this.setState({ error: 'error.default' }), 30000);

    constructor(props) {
        super(props);
        this.state = {
            isRequested: false,
            pendingRoomInfo: false,
            error: undefined,
            errorValues: undefined
        };
        logger.log('constructor', props);
    }

    componentDidMount() {
        this.createRoom();
        callbacks.set('onRoomCreateMaxMembersReached', ({ members, maxMembers, roomType } = {}) => {
            this.setState({ pendingRoomInfo: { members, maxMembers, roomType } });
        });
        callbacks.set('onRoomCreateMaxCallsReached', ({ maxCalls } = {}) => {
            this.setState({ error: 'room.error.maxCallsLimit', errorValues: { maxCalls } });
        });
    }

    componentDidUpdate() {
        this.createRoom();
    }

    componentWillUnmount() {
        clearTimeout(this.countDownError);
    }

    createRoom = () => {
        const {
            _feed, _user, requestCreateRoom, history, _room, kind: kindQs, destNumber
        } = this.props;
        let kind = kindQs || constants.rooms.ROOM_TYPE.AUDIO;
        if (!this.state.isRequested) {
            let isRequested = true;
            if (_feed) {
                requestCreateRoom(kind, _feed, undefined);
            } else if (_user) {
                requestCreateRoom(kind, undefined, [_user]);
            }  else if (destNumber) {
                kind = constants.rooms.ROOM_TYPE.AUDIO;
                requestCreateRoom(undefined, undefined, undefined, [destNumber]);
            } else if (!_room) {
                requestCreateRoom(kind, undefined, undefined);
            } else {
                isRequested = false;
            }
            this.setState({ isRequested });
        }
        if (_room) {
            clearTimeout(this.countDownError);
            const params = { _room };
            if (kindQs === constants.rooms.ROOM_TYPE.VIDEO) {
                params.kind = constants.rooms.ROOM_TYPE.VIDEO;
            }
            history.replace(`${getUrl('room', params)}`);
        }
    };

    handleCreateRoomUsers = (_users) => {
        this.props.requestCreateRoom(
            this.props.kind || constants.rooms.ROOM_TYPE.AUDIO,
            undefined,
            _users?.length ? _users : undefined
        );
    };

    render() {
        const { intl } = this.props;
        const {
            error,
            errorValues,
            pendingRoomInfo,
            pendingRoomInfo: {
                members: users,
                maxMembers: maxSelected
            }
        } = this.state;

        if (error) {
            return (
                <FullscreenMessageFormatter
                    content={intl.formatMessage({ id: error })}
                    buttonType={BUTTON_TYPE.CLOSE}
                    render={RoomFullscreenMessage}
                    resValues={errorValues}
                />
            );
        }

        if (pendingRoomInfo) {
            return (
                <UserCheckboxSelector
                    users={users}
                    maxSelected={maxSelected}
                    onSubmit={this.handleCreateRoomUsers}
                />
            );
        }

        return <FullscreenLoader />;
    }
}

RedirectToRoom.propTypes = {
    intl: PropTypes.any.isRequired,
    _user: PropTypes.string,
    _feed: PropTypes.string,
    _room: PropTypes.string,
    destNumber: PropTypes.string,
    kind: PropTypes.oneOf(['', ...Object.values(constants.rooms.ROOM_TYPE)]),
    requestCreateRoom: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired
};

RedirectToRoom.defaultProps = {
    _user: undefined,
    _feed: undefined,
    _room: undefined,
    destNumber: undefined,
    kind: constants.rooms.ROOM_TYPE.AUDIO
};

export const Component = injectIntl(RedirectToRoom);

const mapStateToProps = (state, ownProps) => {
    const _room = selectors.rooms._currentRoomSelector(state);

    return ({
        _room,
        _feed: getRouteParams('_feed')(ownProps),
        _user: getRouteParams('_user')(ownProps),
        destNumber: getRouteParams('destNumber')(ownProps),
        kind: getQueryStringParameter('kind')(ownProps.location.search) || constants.rooms.ROOM_TYPE.AUDIO
    });
};

const mapDispatchToProps = dispatch => ({
    requestCreateRoom: (roomType, _feed, _user, destNumber) =>
        dispatch(actions.rooms.createRoom(
            roomType,
            undefined,
            _feed,
            _user,
            false,
            destNumber,
        ))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
