import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'common/themes/Colors';
import { LoadingOutlined } from '@ant-design/icons';

const Styled = styled.div`
    text-align: center;
    .label{
        color: ${colors.white};
        font-size: 28px;
        letter-spacing: 2px;
        .anticon{
            margin-right: 20px;
        }
    }
`;

const MainText = ({
    text
}) => ((
    <Styled>
        <div className="label">
            <LoadingOutlined />
            {text}
        </div>
    </Styled>
));

MainText.propTypes = {
    text: PropTypes.string.isRequired
};

MainText.defaultProps = {
};

export default memo(MainText);
