import React, { useMemo, ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Tooltip, Typography, Space, Col, Row as AntdRow } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { SpaceSize } from 'antd/es/space';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Timestamp from 'components/Shared/Notification/Timestamp';
import AvatarList from 'components/Shared/Call/AvatarList';
import Avatar from 'components/Shared/User/Avatar';

import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

const SPACE_SIZE: SpaceSize | [SpaceSize, SpaceSize] = [0,0];
const timeStampStyle = {fontSize: 12};
const { Text } = Typography;
const StyledWrapper = styled(AntdRow)`
    padding: 5px;
    border-radius: 4px;
    transition: all 250ms ease;
    margin: 1px 0;
    width: 100%;
    &:hover{
        background-color: ${props => props.theme.lighten};
    }
    &.missedCall{
        background-color: ${rgba(colors.error, 0.1)};
        .s-recent-call-name{
            color: ${({theme}) => theme.error}!important;
            text-decoration: underline!important;
            &:hover{
                color: ${({theme}) => theme.error}!important;
                text-decoration: none!important;
            }
        }
    }
    .s-recent-call-name{
        color: ${({theme}) => theme.black}!important;
        text-decoration: underline!important;
        &:hover{
            color: ${({theme}) => theme.black}!important;
            text-decoration: none!important;
        }
    }

`;

const WrapperSpace = styled(Space)`
    &.missedCall{
        color: ${colors.error};
    }
`;

const StyledText = styled(Text)`
    &.missedCall{
        color: ${colors.error};
    }
`;

type RowProps = {
    onAddParticipant: () => void;
    onRemoveParticipant: () => void;
    label?: string | ReactNode;
    phoneNumber?: string;
    date?: string;
    status?: string;
    hasParticipant: boolean;
    children?: ReactNode;
    avatarUri?: string;
    presenceStatus?: number;
    avatarSize?: string;
    avatarMode?: string;
    maxAvatarCount?: number;
    isMissed?: boolean;
    hideStatus?: boolean;
    // eslint-disable-next-line
    members?: any[];
    disablePlusButton?: boolean;
} & WrappedComponentProps;

const Row = ({
    intl,
    onAddParticipant,
    onRemoveParticipant,
    label,
    phoneNumber,
    date,
    status,
    isMissed,
    hasParticipant,
    avatarSize,
    avatarMode,
    avatarUri,
    maxAvatarCount,
    presenceStatus,
    hideStatus,
    members = [],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    user,
    children: callButtonComponent,
    disablePlusButton,
}: RowProps): JSX.Element => {
    const hasUsersWithoutMe = useMemo(() => {
        if (!members.length) return false;

        return members.some(member => {
            if (member.isMyUser) {
                return false;
            }

            return true;
        });
    }, [members]);

    const membersWithoutMeExceptIfOnlyMe = useMemo(() => {
        if (members.length === 1 && members[0].isMyUser) {
            return members;
        }

        return members.filter(member => !member.isMyUser);
    }, [members]);

    return (
        <StyledWrapper
            align="middle"
            justify="space-between"
            className={classNames("ui-item-call-builder", { missedCall: isMissed })}
        >
            <Space>
                {(typeof presenceStatus === 'number' || hideStatus || avatarUri) && (
                    <Avatar
                        status={presenceStatus}
                        src={avatarUri}
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        /* @ts-ignore */
                        content={typeof label === 'string' ? label : ''}
                        hasStatus={!hideStatus}
                        size="md"
                    />
                )}
                {avatarMode && (
                    <AvatarList
                        size={avatarSize}
                        mode={avatarMode}
                        members={user ? [user] : membersWithoutMeExceptIfOnlyMe}
                        maxCount={maxAvatarCount}
                    />
                )}
                <Col flex={1}>
                    <WrapperSpace
                        className={classNames({ missedCall: isMissed })}
                        direction="vertical"
                        size={SPACE_SIZE}
                    >
                        <StyledText
                            className={classNames({ missedCall: isMissed })}
                            strong
                            ellipsis
                        >
                            {label}
                        </StyledText>
                        {phoneNumber && <Text type="secondary" ellipsis>{phoneNumber}</Text>}
                        <AntdRow align="middle" className="status-call">
                            <Space>
                                {status}
                            </Space>
                        </AntdRow>
                        {date && <Text 
                            style={timeStampStyle} 
                            type="secondary" 
                            ellipsis>
                            <Timestamp date={date} hasDate hasTime />
                        </Text>}
                        {!avatarMode && (
                            <AvatarList
                                size={avatarSize}
                                mode={avatarMode}
                                members={user ? [user] : membersWithoutMeExceptIfOnlyMe}
                                maxCount={maxAvatarCount}
                            />
                        )}
                    </WrapperSpace>
                </Col>
            </Space>
            <AntdRow align="middle">
                <Space>
                    {callButtonComponent}
                    <Tooltip
                        placement="top"
                        title={(
                            <>
                                {phoneNumber ? <p>{phoneNumber}</p> : null}
                                {members.map(member => <p key={member.id}>{member.fullname}</p>)}
                                {user ? <p>{user.firstname} {user.lastname}</p> : null}
                                {!members.length && !phoneNumber && !user ? (
                                    <p>{intl.formatMessage({ id: 'callBuilder.noMembersOrPhones' })}</p>
                                ) : null}
                            </>
                        )}
                    >
                        <Button
                            shape="circle"
                            onClick={hasParticipant ? onRemoveParticipant : onAddParticipant}
                            disabled={(!hasUsersWithoutMe && !phoneNumber && !user) || !hasParticipant && disablePlusButton}
                        >
                            {hasParticipant ? <MinusOutlined /> : <PlusOutlined />}
                        </Button>
                    </Tooltip>
                </Space>
            </AntdRow>
        </StyledWrapper>
    );
}

Row.propTypes = {
    disablePlusButton: PropTypes.bool,
    avatarSize: PropTypes.string,
    presenceStatus: PropTypes.number,
    avatarUri: PropTypes.string,
    hideStatus: PropTypes.bool,
    avatarMode: PropTypes.string,
    maxAvatarCount: PropTypes.number,
    onAddParticipant: PropTypes.func,
    isMissed: PropTypes.bool,
    onRemoveParticipant: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    phoneNumber: PropTypes.string,
    members: PropTypes.array,
    date: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hasParticipant: PropTypes.bool,
    children: PropTypes.node,
};

Row.defaultProps = {
    avatarUri: undefined,
    disablePlusButton: false,
    onAddParticipant: () => {},
    onRemoveParticipant: () => {},
    avatarSize: 'default',
    avatarMode: 'square',
    maxAvatarCount: 2,
    presenceStatus: undefined,
    hideStatus: false,
    members: [],
    isMissed: false,
    label: '',
    phoneNumber: '',
    date: '',
    status: '',
    hasParticipant: false,
    children: null,
};

export default injectIntl(Row as React.FunctionComponent<RowProps>);
