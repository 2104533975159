import fallbackAvatar from 'statics/images/fallbackAvatar.svg';

export const CONTACT_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    DECLINED: 2,
    NOT_CONNECTED: -1,
    WAITING_ANSWER: 4
};

export const CONTACT_CONTEXTS = {
    DRAWER_CREATE_UNICAST: 'DRAWER_CREATE_UNICAST',
    ROOM_SIP_CONTACTS: 'ROOM_SIP_CONTACTS'
};

const contextualConfig = {
    [CONTACT_CONTEXTS.DRAWER_CREATE_UNICAST]: {
        contactStatus: CONTACT_STATUS.ACCEPTED,
        type: 'contact'
    },
    [CONTACT_CONTEXTS.ROOM_SIP_CONTACTS]: {
        contactStatus: CONTACT_STATUS.ACCEPTED,
        type: 'contact',
        sip: true
    },
};

export const getContextualContactConfig = context => contextualConfig[context] || {};

export const DEFAULT_AVATAR = fallbackAvatar;
export const PRESENCE_STATUSES = {
    ONLINE: 0,
    AWAY: 2,
    BUSY: 1,
    OFFLINE: 3
};

// RANK_STATUSES helps to keep a specific order in sort
export const RANK_STATUSES = {
    ONLINE: 1,
    BUSY: 2,
    AWAY: 3,
    OFFLINE: 4
};

export const statuses = [
    {
        id: PRESENCE_STATUSES.ONLINE,
        color: '#2ecc71',
        labelId: 'status.online',
        className: 'online'
    },
    {
        id: PRESENCE_STATUSES.AWAY,
        color: '#FF6600',
        labelId: 'status.away',
        className: 'away'
    },
    {
        id: PRESENCE_STATUSES.BUSY,
        color: '#f64646',
        labelId: 'status.busy',
        className: 'busy'
    },
    {
        id: PRESENCE_STATUSES.OFFLINE,
        color: '#CCC',
        labelId: 'status.offline',
        subtitleId: 'menu.dropdown.invisible.subtitle',
        className: 'offline'
    }
];
export const getStatus = _status => statuses.find(status => status.id === _status);

export const ROLES = {
    USER: 'USER',
    GUEST: 'GUEST',
    COMPANY_ADMIN: 'COMPANY_ADMIN',
    COMPANY_PARENT_ADMIN: 'COMPANY_PARENT_ADMIN',
    ADMIN: 'ADMIN'
};

export const AVATAR_SIZES = {
    xs: 20,
    sm: 25,
    md: 30,
    default: 40,
    lg: 45,
    xl: 60,
    xxxl: 130,
    xxxxl: 150
}
