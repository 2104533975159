import { useInfiniteQuery } from "react-query"
import { $getContactsV2, $searchUsersV2 } from 'api/user';
import { getPaginationInfos, hasRemainingItems } from "utils/state/api";

const useContacts = (query, contactStatus, isInitiator, size, sip) => {
    const result = useInfiniteQuery(
        ['contacts',  { query, contactStatus, isInitiator, sip, size } ],
        async ({ pageParam = 1 }) => {
            let response;

            if (query) {
                response = await $searchUsersV2(query, contactStatus, isInitiator, size, pageParam, sip)
            } else {
                response = await $getContactsV2(contactStatus, isInitiator, size, pageParam, sip);
            }
            
            const pagination = getPaginationInfos(response);
            const hasNext = hasRemainingItems(response);
            return { data: response.data, ...pagination, hasNext };
        },
        {
            getNextPageParam: data => data.hasNext ? data.page + 1 : undefined,
            refetchOnWindowFocus: false, refetchOnMount: false, enable:true
        }
    )

    return result;
}

export default useContacts;
