import * as presenceTypes from 'types/presence';
import * as sessionTypes from 'types/session';
import * as userTypes from 'types/user';
import * as entityTypes from 'types/entity';
import { utils, types as bcTypes } from '@amplement/backend-connector';
import {
    mergeEntities,
    setEntityById
} from 'utils/state/object';
import { PRESENCE_STATUSES } from 'const/user';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';

const initialState = {
    users: {},
    anchors: {},
    feeds: {},
    feedItems: {},
    notifications: {},
    activities: {},
    rooms: {},
    callAccounts: {},
    presences: {},
    feedMembers: {}
};

const entityReducer = (state = initialState, action) => {
    const feedItems = {};

    switch (action.type) {
        case bcTypes.entities.PUT_ENTITY:
            return {
                ...state,
                feedItems: {
                    ...state.feedItems,
                    [action.payload.id]: { ...action.payload },
                },
            };

        case bcTypes.feedItems.REQUEST_DELETE_LOCAL_FEED_ITEMS:
            Object.keys(state.feedItems).forEach(_feedItem => {
                if (!action._feedItems.includes(_feedItem)) {
                    feedItems[_feedItem] = state.feedItems[_feedItem];
                }
            });
            return {
                ...state,
                feedItems,
            };

        case bcTypes.feedItems.REMOVE_FEED_ITEMS:
            action._feedItems.forEach(_feedItem => delete state.feedItems[_feedItem]); // eslint-disable-line
            return {
                ...state,
                feedItems: {
                    ...state.feedItems,
                },
            };

        case bcTypes.feedItems.PATCH_LOCAL_FEED_ITEM:
            return {
                ...state,
                feedItems: {
                    ...state.feedItems,
                    [action._feedItem]: { ...state.feedItems[action._feedItem], ...action.payload },
                },
            };

        case bcTypes.activities.REMOVE_ACTIVITY_FROM_ENTITIES:
            delete state.activities?.[action._activity]; // eslint-disable-line
            return {
                ...state,
                activities: { ...state.activities },
            };

        case bcTypes.notifications.REMOVE_NOTIFICATION_FROM_ENTITIES:
            delete state.notifications?.[action._notification]; // eslint-disable-line
            return {
                ...state,
                notifications: { ...state.notifications },
            };

        case bcTypes.notifications.SET_NOTIFICATION_TO_READ_BY_CATEGORY:
            Object.keys(state.notifications).forEach(_notif => {
                if (action.category === state.notifications[_notif].category) {
                    state.notifications[_notif].read = true; // eslint-disable-line
                }
            });
            return state;

        case bcTypes.rooms.SET_ROOMS:
            if (action?.rooms?.length) {
                return {
                    ...state,
                    rooms: keyBy(
                        filter(state.rooms, (room, id) => action._rooms.includes(id)),
                        'id',
                    ),
                };
            }
            return state;


        case bcTypes.rooms.WS_ROOM_FEED_EMPTY:
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    [action.payload.id]: {
                        ...state.rooms[action.payload.id],
                        _feed: action.payload?._feed
                    }
                }
            };

        case bcTypes.rooms.REMOVE_ROOM:
            delete state.rooms[action._room]; // eslint-disable-line
            return {
                ...state,
            };

        case bcTypes.feedItems.SET_LAST_FILE_FEED_ITEMS:
            return {
                ...state,
                lastFileFeedItems: keyBy(
                    filter(state.lastFileFeedItems, (item, id) => action._items.includes(id)),
                    'id',
                ),
            };


        case userTypes.GET_USERS_SUCCESS:
        case userTypes.SEARCH_USERS_SUCCESS:
            return {
                ...mergeEntities(state, action.response.data.entities, ['users'])
            };

        case sessionTypes.SET_USER:
            return setEntityById(state, 'users', action.user.id, action.user);

        case sessionTypes.WS_PUT_USER:
            return setEntityById(state, 'users', action.payload.id, action.payload);

        case presenceTypes.WS_POST_CONNECTED: {
            return {
                ...state,
                presences: action.payload
            };
        }

        case presenceTypes.WS_UPDATE_STATUS:
        case presenceTypes.WS_PUT_CONNECTED: {
            return {
                ...mergeEntities(state, {
                    presences: {
                        ...state.presences,
                        ...action.payload
                    }
                }, ['presences'])
            };
        }

        case presenceTypes.WS_PUT_DISCONNECTED: {
            return setEntityById(state, 'presences', action.payload, PRESENCE_STATUSES.OFFLINE);
        }

        case sessionTypes.PATCH_USER_SUCCESS: {
            if (action.payload.data.status || action.payload.data.status === 0) {
                return setEntityById(state, 'presences', action.payload._user, action.payload.data.status);
            }
            return state;
        }

        // ne pas supprimer l'entité, sinon les anchors ne fonctionnerons plus
        case userTypes.WS_DELETE_CONTACT: {
            const _user = action.payload?.id;
            if (state.users?.[_user]) {
                return {
                    ...state,
                    users: {
                        ...state.users,
                        [_user]: {
                            ...state.users[_user],
                            contactStatus: undefined
                        }
                    },
                };
            }
            return state;
        }

        case userTypes.WS_POST_CONTACT:
        case userTypes.WS_PUT_CONTACT:
        case userTypes.GET_CONTACT_SUCCESS:
            return {
                ...mergeEntities(state, action.payload)
            };

        case entityTypes.MERGE_ENTITIES:
            return {
                ...mergeEntities(state, action.payload || action.entities)
            };

        case bcTypes.entities.MERGE_ENTITIES:
            return {
                ...utils.object.mergeEntities(state, action.payload)
            };

        default:
            return state;
    }
};

export default entityReducer;
