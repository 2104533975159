import React, {
    useCallback,
    useEffect,
    useState,
    useMemo,
    Children,
    isValidElement,
    cloneElement,
    memo
} from 'react';
import { connect } from 'react-redux';
import { selectors, constants, actions } from '@amplement/backend-connector';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { injectIntl } from 'react-intl';

import Recorder from 'components/Shared/Recorder/Recorder';
import AudioStreamsMerger from 'components/Shared/Recorder/AudioStreamsMerger';
import { downloadChunks } from 'components/Shared/Recorder/utils';
import { RECORDER_API_STATUS } from 'components/Shared/Recorder/constants';

const messageKey = 'recordingNotification';
const messageKeyWarn = 'recordingNotificationWarn';

const getMessages = (intl, members) => {
    const names = members.map(member => member.fullname);

    return {
        description: intl.formatMessage({ id: 'room.members.isRecording' }, { count: names.length, names: names.join(', ') }),
        notifyRules: intl.formatMessage({ id: 'room.members.recordingInfo' }),
    };
};

const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

const RecordWrapper = (props) => {
    const {
        roomName,
        showInterface,
        members,
        _room,
        intl,
        onStatusRecordChange,
        children
    } = props;
    const [joinedMembers, setJoinedMembers] = useState([]);
    const [recordingMembers, setRecordingMembers] = useState([]);
    const isMeRecording = recordingMembers?.some(m => m.isMe);
    const [api, contextHolder] = message.useMessage();
    const i18n = useMemo(() => getMessages(intl, recordingMembers), [intl, recordingMembers]);
    const handleChangeStatus = useCallback((status) => onStatusRecordChange(_room, status), [_room]);

    useEffect(() => {
        const joined = members?.filter(x => x.status === constants.rooms.USER_IN_ROOM_STATUS.JOINED);
        const recordings = joined.filter(member => member.recordingState === RECORDER_API_STATUS.STARTED);
        setJoinedMembers(joined);
        setRecordingMembers(recordings);
    }, [members]);

    useEffect(() => {
        if (isMeRecording && showInterface) {
            api.open({
                type: 'warning',
                key: messageKeyWarn,
                content: i18n.notifyRules,
                duration: 5,
            });
        }
    }, [isMeRecording, i18n]);

    const handleStreamRecordingComplete = useCallback((chunks, mimeType) => {
        const date = new Date();
        const dateFormatter = new Intl.DateTimeFormat(navigator.language, dateOptions);
        const roomDate = dateFormatter.format(date).split('/').join('-');
        downloadChunks(
            chunks,
            mimeType,
            `${roomName} ${roomDate}.webm`,
        );
    }, [i18n, roomName]);

    useEffect(() => {
        if (recordingMembers.length && showInterface) {
            api.open({
                type: 'info',
                key: messageKey,
                content: i18n.description,
                duration: 0,
            });
        } else {
            api.destroy(messageKey);
            api.destroy(messageKeyWarn);
        }
    }, [recordingMembers, showInterface, i18n]);

    const childrenWithProps = useMemo(() => Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { handleChangeStatus });
        }

        return child;
    }, [children, handleChangeStatus]));

    return (
        <>
            {contextHolder}
            <AudioStreamsMerger members={joinedMembers} _room={_room}>
                <Recorder onStreamRecordingComplete={handleStreamRecordingComplete}>
                    {childrenWithProps}
                </Recorder>
            </AudioStreamsMerger>
        </>
    );
};

RecordWrapper.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({
        _client: PropTypes.string
    })),
    showInterface: PropTypes.bool,
    _room: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onStatusRecordChange: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    roomName: PropTypes.string,
};

RecordWrapper.defaultProps = {
    roomName: '',
    children: null,
    onStatusRecordChange: () => {},
    members: [],
    showInterface: true,
};

const mapStateToProps = (state, ownProps) => {
    const members = selectors.rooms.getRoomMembersByRoomIdSelector(state, ownProps._room);
    const room = selectors.rooms.getRoomByIdSelector(state, ownProps._room);

    return {
        members,
        roomName: room?.name,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onStatusRecordChange: (_room, status) => dispatch(actions.rooms.patchRecording(_room, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(memo(RecordWrapper)));
