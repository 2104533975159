import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import Modal from 'components/Shared/Modals';
import { Alert } from 'antd';
import { StyledZoneUpload } from './styles';

const CarouselModal = (props)=> {
    const {
        intl,
        visible,
        onSubmit,
        onClose,
        ArrowLeft,
        ArrowRight,
        UploadButton,
        Preview,
        Thumbs,
        // Counter,
        CompressSettingButton,
        SubmitButton,
        CancelButton,
        ErrorMessage,
        count
    } = props;

    useWhyDidYouUpdate('component:feed:CarouselModal', props);

    return (
        <Modal
            isOpen={visible}
            title={intl.formatMessage({ id: 'modal.uploadFile.title' }, { count })}
            onOk={onSubmit}
            onClose={onClose}
            footer={(
                <>
                    {CancelButton}
                    <div className="input-container">
                        {CompressSettingButton}
                    </div>
                    {SubmitButton}
                </>
            )}
            width={520}
            bodyStyle={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
            <div className="modal-content" id="modal-upload-content">
                {ErrorMessage && <Alert type="error" message={ErrorMessage} />}
                <StyledZoneUpload className="styled-zone-upload">
                    <div className="content">
                        <div className="container">
                            {Preview}
                        </div>
                        {ArrowLeft}
                        {ArrowRight}
                    </div>
                    <div className="thumbs">
                        <div className="thumbs-container">
                            {UploadButton}
                            {Thumbs}
                        </div>

                    </div>
                </StyledZoneUpload>
            </div>
        </Modal>
    );
}

CarouselModal.propTypes = {
    intl: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    ArrowLeft: PropTypes.node,
    ArrowRight: PropTypes.node,
    UploadButton: PropTypes.node,
    Preview: PropTypes.arrayOf(PropTypes.node),
    Thumbs: PropTypes.arrayOf(PropTypes.node),
    CompressSettingButton: PropTypes.node,
    SubmitButton: PropTypes.node,
    // Size: PropTypes.node,
    // Counter: PropTypes.node,
    CancelButton: PropTypes.node,
    ErrorMessage: PropTypes.node,
    count: PropTypes.number
};

CarouselModal.defaultProps = {
    visible: true,
    ArrowLeft: null,
    ArrowRight: null,
    UploadButton: null,
    Preview: null,
    Thumbs: null,
    CompressSettingButton: null,
    SubmitButton: null,
    // Size: null,
    // Counter: null,
    CancelButton: null,
    ErrorMessage: null,
    count: 0
};

export default injectIntl(memo(CarouselModal));
