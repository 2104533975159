import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { injectIntl } from 'react-intl';
import { RoomButton } from 'components/Room/Footer';
import { container as CallTransferButton } from 'components/Shared/Call/Buttons/CallTransferDropDown';
import { container as HoldButton } from './Buttons/Hold';
import { container as ExitButton } from './Buttons/Exit';
import { RECORDER_STATUS } from '../Recorder/constants';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.fixedWhite};
    .actions{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        justify-content: center;
        .button-container{
            margin-right: 40px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .label{
        display: flex;
        flex-direction: column;
        text-align: center;
        p{
            margin: 0;
            font-size: 15px;
            font-weight: initial;
            color: ${({ theme }) => theme.fixedWhite};
        }
    }
`;

const StyledTitle = styled.div`
    margin: 0;
    text-align: center;
    font-size: 25px;
    font-weight: initial;
    color: ${({ theme }) => theme.fixedWhite};
`;

const TitleComponent = ({
    name,
    isMe,
    intl
}) => {
    let result = null;

    if (isMe) {
        result = intl.formatMessage({ id: 'room.holdScreen.byMe' });
    } else if (name) {
        result = intl.formatMessage({ id: 'room.holdScreen.byUser' }, { 
            b: chunks => <b>{chunks}</b>,
            name
        });
    
    } else {
        result = intl.formatMessage({ id: 'room.holdScreen.byUnknown' });
    }

    return <StyledTitle>{result}</StyledTitle>;
}

const Title = injectIntl(memo(TitleComponent));


const StyledMember = styled.li`
    margin: 0;
    font-size: 15px;
    font-weight: initial;
    color: ${({ theme }) => theme.fixedWhite};
    text-align: center;
    list-style: none;
`;

const MemberNameComponent = ({
    fullname,
    phonenumber,
    title,
    intl
}) => (
    <StyledMember>
        {fullname || intl.formatMessage({ id: 'room.member.name.unknown' })}
        {phonenumber && ` (${phonenumber})`}
        {title && title !== phonenumber && ` - ${title}`}
    </StyledMember>
);

const MemberName = injectIntl(memo(MemberNameComponent));

const StyledMemberList = styled.div`
    margin-top: 15px;
`;

const MemberListNameComponent = ({
    members, limit, intl
}) => {
    if (!members) return null;

    const memberCount = members?.length;
    const moreCount = memberCount - limit;
    const list = moreCount ? members.slice(0, limit) : members;

    return (
        <StyledMemberList>
            {memberCount > 1 && intl.formatMessage({ id: 'room.members.count' }, { count: memberCount })}
            <ul>
                {list.map(member => <MemberName key={member?._client} {...member} />)}
                {moreCount > 0 ? <StyledMember>{`+ ${moreCount} More...`}</StyledMember>: null}
            </ul>
        </StyledMemberList>
    );
}


MemberListNameComponent.propTypes = {
    members: PropTypes.array,
    limit: PropTypes.number,
    intl: PropTypes.any.isRequired,
}

MemberListNameComponent.defaultProps = {
    members: undefined,
    limit: undefined
};


const MemberListName = injectIntl(memo(MemberListNameComponent));

const ResumeCallActions = (props) => {
    const {
        onStopRecording,
        handleChangeStatus,
        recorderState,
        initiatorName,
        roomName,
        members,
        _room,
        ExitComponent,
        HoldComponent,
        CallForwardComponent,
        isLocalTrigger,
        features,
        intl
    } = props;

    useWhyDidYouUpdate('component:Room:Mask:ResumeCallActions', props);

    return (
        <Container>
            <div className="label">
                <Title
                    name={initiatorName}
                    isMe={isLocalTrigger}
                />
                <p>
                    {roomName && (<span>{roomName}</span>)}
                    {/* {meta} */}
                </p>
                <MemberListName members={members} limit={3} />
            </div>
            <div className="actions">
                <div className="button-container">
                    <RoomButton
                        label={intl.formatMessage({ id: 'room.actions.exit' })}
                        size="xl"
                    >
                        <ExitComponent
                            color="light-transparency"
                            recorderState={recorderState}
                            handleChangeStatus={handleChangeStatus}
                            onStopRecording={onStopRecording}
                            _room={_room}
                        />
                    </RoomButton>
                </div>

                {isLocalTrigger && (
                    <div className="button-container">
                        <RoomButton
                            label={intl.formatMessage({ id: 'room.actions.toggleResume' })}
                            size="xl"
                        >
                            <HoldComponent
                                isEnabled
                                color="success"
                                animation="pulse"
                                _room={_room}
                            />
                        </RoomButton>
                    </div>
                )}

                {isLocalTrigger && (
                    (features?.transfers || features?.transferToSipUser || features?.transferToPhonenumber || features?.transferToUser || features?.transferToRoom) && (
                        <div className="button-container">
                            <RoomButton
                                label={intl.formatMessage({ id: 'room.actions.callTransfer' })}
                                size="xl"
                            >
                                <CallForwardComponent
                                    _room={_room}
                                    transferToUser={features?.transferToUser}
                                    transferToPhonenumber={features?.transferToPhonenumber}
                                    transferToSipUser={!!features?.transferToSipUser}
                                    transferToRoom={!!features?.transferToRoom}
                                />
                            </RoomButton>
                        </div>
                    ))}
            </div>
        </Container>
    );
}

ResumeCallActions.propTypes = {
    handleChangeStatus: PropTypes.func,
    onStopRecording: PropTypes.func,
    recorderState: PropTypes.oneOf(Object.values(RECORDER_STATUS)),
    initiatorName: PropTypes.string,
    roomName: PropTypes.string,
    isLocalTrigger: PropTypes.bool,
    _room: PropTypes.string.isRequired,
    features: PropTypes.object,
    ExitComponent: PropTypes.any,
    HoldComponent: PropTypes.any,
    CallForwardComponent: PropTypes.any,
    intl: PropTypes.any.isRequired,
}

ResumeCallActions.defaultProps = {
    onStopRecording: () => {},
    handleChangeStatus: () => {},
    recorderState: RECORDER_STATUS.STOPPED,
    initiatorName: undefined,
    roomName: undefined,
    isLocalTrigger: false,
    HoldComponent: HoldButton,
    ExitComponent: ExitButton,
    features: undefined,
    CallForwardComponent: CallTransferButton
};

export default injectIntl(memo(ResumeCallActions));
