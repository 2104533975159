import styled from 'styled-components';
import { rgba, mix } from 'polished';

export const StyledItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
`;

export const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    top: 21px;
    height: 100%;
    width: 30px;
    right: 0;
    justify-content: flex-end;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 250ms ease;
    z-index: 3;
    background: linear-gradient(to right, ${({ theme }) => rgba(theme.white, .6)} 0%, ${({ theme }) => theme.white} 100%);
    &.s-search-btn{
        .item{
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;
            .btn{
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    &.fixed{
        opacity: 1;
        visibility: visible;
        background: transparent;
        width: auto;
        position: relative;
        .item{
            transform: translateY(0);
        }
    }
    .item{
        margin-right: 10px;
        transform: translateY(10px);
        transition: all 250ms ease;
        white-space: nowrap;
        &:nth-child(2){
            transition-delay: 100ms;
        }
        &:nth-child(3){
            transition-delay: 200ms;
        }
        &:nth-child(4){
            transition-delay: 300ms;
        }
        &:last-child{
            margin-right: 0;
        }
    }
`;

export const StyledRole = styled.span`
    margin-left: 5px;
    font-size: 10px;
    padding: 2px 5px;
    display: inline-block;
    letter-spacing: 1px;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: bold;
    &.admin{
        background-color: ${({ theme }) => mix( .75, theme.white, theme.badges.bgAdmin)};
        color: ${({ theme }) => mix(0.15, theme.black, theme.badges.bgAdmin)};
    }
    &.creator{
        background-color: ${({ theme }) => mix( .75, theme.white, theme.badges.bgCreator)};
        color: ${({ theme }) => mix(0.15, theme.black, theme.badges.bgCreator)};
    }
    &.pending{
        background-color: ${({ theme }) => theme.badges.bgPending};
        color: ${({ theme }) => theme.black};
    }
`;

export const StyledText = styled.div`
    margin-bottom: 15px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    flex-shrink: 0;
    margin-bottom: 20px;
    justify-content: space-between;
    .s-text{
        margin-bottom: 0;
    }
    &.waiting{
        transition: all 250ms ease;
        opacity: 0.3;
    }
    &.xs{
        ${StyledText}{
            padding: 10px 0;
        }
    }
    &:last-child{
        ${StyledText} {
            margin-bottom: 0;
            &::after{
                background-color: transparent;
            }
        }
    }
    &.loading{
        ${StyledActions}{
            display: none;
        }
        ${StyledText}{
            min-height: ${({ avatarSize }) => avatarSize} + 5px;
        }
    }
    &:hover{
        &.waiting{
            opacity: 1;
        }
        ${StyledActions}{
            opacity: 1;
            visibility: visible;
            .item{
                transform: translateY(0);
            }
        }
    }
    .avatar{
        margin-right: 10px;
    }
    .timestamp{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        color: ${({ theme }) => rgba(theme.black, .6)};
    }
}
`;
