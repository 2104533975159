import { connect } from 'react-redux';
import { getErrorSelector, getContentErrorSelector } from 'selectors/session';

import Component from './Formatter';
import RoomFullscreenMessageComponent from './RoomFullscreenMessage';
import GlobalErrorComponent from './GlobalError';
import ContentErrorComponent from './ContentError';

const mapStateToProps = type => (state) => {
    if (type === 'globalError') {
        return ({
            ...getErrorSelector(state),
            render: GlobalErrorComponent
        });
    }
    if (type === 'contentError') {
        const error = getContentErrorSelector(state);
        return ({
            ...error,
            render: ContentErrorComponent
        });
    }
    if (type === 'roomError') {
        return ({
            ...getErrorSelector(state),
            render: RoomFullscreenMessageComponent
        });
    }
    return {};
}

export const GlobalError = connect(mapStateToProps('globalError'))(Component);

export const ContentError = connect(mapStateToProps('contentError'))(Component);

export const RoomError = connect(mapStateToProps('roomError'))(Component);
