import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Icon from 'components/Shared/Common/Icon';
import Input from 'components/Shared/Forms/Input';

class SearchBar extends PureComponent {
    inputRef = createRef();

    debounceTimeout = null;

    state = {
        value: this.props.defaultValue
    };

    componentDidMount() {
        if (this.props.autoFocus && this.inputRef.current) this.inputRef.current.focus();
    }

    componentDidUpdate(lastProps, lastState) {
        const stateValueChanged = lastState.value !== this.state.value;
        const inputValueEmpty = lastState.value.length && !this.state.value.length;

        if (stateValueChanged && this.state.value.length > 2) {
            this.requestDebouncedSearch(this.state.value, this.props.debounceDuration);
        }

        if (inputValueEmpty && this.props.onClear) {
            this.debounceTimeout = null;
            this.props.onClear();
        }
    }

    requestDebouncedSearch = (value, duration) => {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }

        if (duration === -1) {
            this.props.onSearch(value);
            return;
        }

        this.debounceTimeout = setTimeout(() => {
            this.props.onSearch(value);
            this.debounceTimeout = null;
        }, duration);
    }

    handleInput = ({ target: { value } }) => {
        this.setState({ value });
    }

    render() {
        const {
            inputProps,
            disabled,
            placeholder,
            className
        } = this.props;

        return (
            <Input
                icon={<Icon iconName="Search" />}
                className={classNames(
                    's-search-container',
                    className
                )}
                ref={this.inputRef}
                role="searchbox"
                aria-label="Search"
                type="search"
                placeholder={placeholder}
                onChange={this.handleInput}
                disabled={disabled}
                autoComplete="off"
                rounded
                {...inputProps}
            />
        );
    }
}

SearchBar.propTypes = {
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    debounceDuration: PropTypes.number,
    onSearch: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    labelSize: PropTypes.number,
    autoFocus: PropTypes.bool
};

SearchBar.defaultProps = {
    className: '',
    defaultValue: '',
    placeholder: '',
    debounceDuration: -1,
    disabled: false,
    labelSize: 22,
    inputProps: {},
    onClear: () => {},
    autoFocus: false
};

export default SearchBar;
