import memoize from 'lodash/memoize';
import adapter from  'webrtc-adapter';
import config from 'config';
import { isNativeScreenSharingSupported } from 'services/screenSharing';
import DeviceManager from 'components/Room/Helpers/DeviceManager';

const rate = ({ curr, prev, field, coef = 1 }) => {
    const currValue = parseInt(curr[field] || 0, 10) * coef;
    const prevValue = parseInt(prev[field] || 0, 10) * coef;

    return (currValue - prevValue) / (curr.createdAt - prev.createdAt);
};



const audioStats = {
    audioInputLevel: undefined, // local
    audioOutputLevel: undefined, // remote
    packetsReceived: undefined,
    packetsSent: undefined,
    packetsLost: undefined,
    googRtt: undefined
};

const videoStats = {
    googFrameWidthReceived: undefined,
    googFrameHeightReceived: undefined,
    packetsReceived: undefined,
    packetsSent: undefined,
    packetsLost: undefined,
    googRtt: undefined
};

const findTrackStats = (stats, track, prevStats) => {
    const trackStats = track?.kind === 'video' ? { ...videoStats } : { ...audioStats };

    // let trackId;
    const allStats = [];
    if ((!stats?.size && !stats?.length) || !track?.id) return trackStats;

    const statsFound = [];
    const tracksids = [];
    const levels = [];

    stats.forEach((res) => {
        allStats.push(res);

        if (track?.kind && track?.kind === res.kind) {
            if (undefined !== res.trackIdentifier && res.type === 'track') tracksids.push(res?.trackIdentifier);

            if (res.ssrc) {
                if (undefined !== res.packetSent) trackStats.packetSent = res.packetSent;
                if (undefined !== res.packetsReceived) trackStats.packetsReceived = res.packetsReceived;
                if (undefined !== res.packetsSent) trackStats.packetsSent = res.packetsSent;
                if (undefined !== res.packetsLost) trackStats.packetsLost = res.packetsLost;
                if (undefined !== res.googRtt) trackStats.googRtt = res.googRtt;
            }
            if (undefined !== res.audioLevel) {
                levels.push(` ${res.id}(${res.audioLevel})`);
            }
            if (res.type === 'media-source') {
                if (undefined !== res.audioLevel) {
                    trackStats.audioInputLevel = res.audioLevel;
                }
            } else if (res.id?.indexOf('RTCMediaStreamTrack_receiver') !== -1) {
                if (undefined !== res.audioLevel) {
                    trackStats.audioOutputLevel = res.audioLevel;
                }
            }
            if (res.type === 'track' && res.remoteSource === false) {
                // if (undefined !== res.trackIdentifier) trackId = res.trackIdentifier;
                if (undefined !== res.frameWidth) trackStats.googFrameWidthReceived = res.frameWidth;
                if (undefined !== res.frameHeight) trackStats.googFrameHeightReceived = res.frameHeight;
            }
            statsFound.push(res);
        }
    });

    // console.log('#### findTrackStats: ', trackStats.audioOutputLevel, trackStats.audioInputLevel, levels.join(','), { tracksids, track, statsFound, trackStats, allStats, statsTrackId: trackId, trackId: track?.id });

    if (tracksids?.length && tracksids.indexOf(track.id) === -1) {
        // eslint-disable-next-line
        // console.warn('#### findTrackStats: ', { tracksids, track, statsFound, trackStats, allStats, statsTrackId: trackId, trackId: track?.id });
        return null;
    }

    trackStats.createdAt = Date.now();
    const packets = parseInt(trackStats.packetsSent || trackStats.packetsReceived, 10);

    if (packets) {
        trackStats.packetsLostRatio = parseInt(trackStats.packetsLost, 10) / packets;
    }

    if (prevStats?.createdAt) {
        trackStats.bitrate = rate({
            prev: prevStats,
            curr: trackStats,
            field: 'bytesReceived',
            coef: 8,
        });
        if (!trackStats.bitrate) {
            trackStats.bitrate = rate({
                prev: prevStats,
                curr: trackStats,
                field: 'bytesSent',
                coef: 8,
            });
        }
    }

    return trackStats;
};

export const setMediaBitrate = (sdp, mediaType, bandwidth) => {
    let newSdp = sdp;
    let modifier = 'AS';
    if (adapter.browserDetails.browser === 'firefox') {
        bandwidth = (bandwidth >>> 0) * 1000; // eslint-disable-line
        modifier = 'TIAS';
    }
    if (newSdp && newSdp.indexOf(`m=${  mediaType  }`) !== -1) {
        const replacePatter = `m=${mediaType}$1\r\nc=IN$2\r\nb=${modifier}:${bandwidth}\r\n`;
        const basePattern = `m=${mediaType}(.*)\r\nc=IN(.*)\r\n`;
        const bitrateRegexPattern = new RegExp(`${basePattern}b=${modifier}:.*\r\n`);

        if (!bitrateRegexPattern.test(newSdp)) {
            newSdp = newSdp.replace(new RegExp(basePattern), replacePatter);
            // console.log('pwet1', mediaType, sdp === newSdp, basePattern, replacePatter);
        } else {
            newSdp = newSdp.replace(bitrateRegexPattern, replacePatter);
            // console.log('pwet2', mediaType, sdp === newSdp, basePattern, replacePatter);
        }
    }

    return newSdp;
}

export const getPcStats = async (peer, stream, prevStats = {}) => {
    if (!peer || !peer.getStats) return {};
    if (!stream) return {};
    const [videoTrack] = stream.getVideoTracks();
    const [audioTrack] = stream.getAudioTracks();

    if (!audioTrack && !!videoTrack) return {};
    try {
        const stats = await peer.getStats(null);
        const audio = findTrackStats(stats, audioTrack, prevStats.audio);
        const video = findTrackStats(stats, videoTrack, prevStats.video);

        return { audio, video };
    } catch (e) {
        // eslint-disable-next-line
        console.log('getPcStats:error', e);
        return {};
    }
}

const checkSupportForIce = (server) => {
    const stunServer = server || "stun:stun.l.google.com:19302";
    const conf = {"iceServers": [{"urls": stunServer}]};
    try {
        const RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
        let pc = new RTCPeerConnection(conf);
        const isSupported = pc && !!pc.iceConnectionState;
        if (pc) pc.close();
        pc = null;
        return isSupported;
    } catch (e) {
        return false;
    }
}

export const isGumSupported = () => !!(navigator.mozGetUserMedia
    || navigator.webkitGetUserMedia
    || navigator.msGetUserMedia
    || navigator.getUserMedia);

const isWebRtcCompatibleToBeMemoized = () => {
    try {
        const supportsVideoElement = !!document.createElement('video').canPlayType;
        // const supportsVP8 = document.createElement('video').canPlayType('video/webm; codecs="vp8", vorbis') === "probably";
        const supportsGetUserMedia = isGumSupported();
        const supportsRTCPeerConnection = !!(window.RTCPeerConnection
            || window.mozRTCPeerConnection
            || window.webkitRTCPeerConnection);
        const supportsIceCandidates = checkSupportForIce();
        const browserWithDataChannelCompatible = (
            (adapter && adapter.browserDetails && adapter.browserDetails.version > 62)
            || window.location.search.indexOf('forceCompatibility=1') !== -1
        );

        return config.env === 'test'
            || (
                supportsVideoElement
                // && supportsVP8
                && browserWithDataChannelCompatible
                && supportsGetUserMedia
                && supportsRTCPeerConnection
                && supportsIceCandidates
            );
    } catch (e) {
        return false;
    }
};

export const isWebRtcCompatible = memoize(isWebRtcCompatibleToBeMemoized);

export const getWebrtcSupport = async () => {
    const devices = await DeviceManager.enumerateDevices();

    const getUserMedia = isGumSupported();
    const getDisplayMedia = isNativeScreenSharingSupported;
    const enumerateDevice = !!navigator?.mediaDevices?.enumerateDevices;
    const permissions = !!navigator?.permissions?.query;
    const webRTC = isWebRtcCompatible(); 

    return {
        getUserMedia,
        getDisplayMedia,
        enumerateDevice,
        permissions,
        webRTC,
        devices
    };
}
