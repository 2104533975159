import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ImageSliderThumbItemComponent = ({ url }) => (
    <img alt="thumb" className="thumbs-img" src={url} />
);

ImageSliderThumbItemComponent.propTypes = {
    url: PropTypes.string.isRequired
};

ImageSliderThumbItemComponent.defaultProps = {
};

export default memo(ImageSliderThumbItemComponent);
