import React, { memo, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getCurrentUserIdSelector } from 'selectors/user';

import Row from 'components/CallBuilder/Row/Row';
import InfiniteScrollList from 'components/CallBuilder/InfiniteScrollList';

import { NAVIGATION } from 'components/CallBuilder/constants';
import CallButton from 'components/CallBuilder/DefaultResults/style';
import ButtonClick from 'components/Shared/Common/Button/ButtonClick';

import useFeedMembers from 'hooks/useFeedMembers';

import { FeedProps, ListProps } from 'components/CallBuilder/types';

const Feed = injectIntl(({
    intl,
    item: { id, name },
    onAddParticipant,
    onRemoveParticipant,
    inputValue,
    onCloseModal,
    showDirectCall,
}: FeedProps): JSX.Element => {
    const _myUser = useSelector(getCurrentUserIdSelector);
    const { data = [] } = useFeedMembers({ _feed: id }) || {};
    const members = useMemo(() => data.map(({ user }) => ({
        ...user,
        _user: user.id,
        fullname: `${user.firstname} ${user.lastname}`,
        isMyUser: user.id === _myUser,
    })), [data]);

    const handleAddFeed = useCallback(() => {
        members.forEach(member => {
            if (member.id === _myUser || inputValue.find((item) => item?._user === member.id)) {
                return;
            }

            onAddParticipant?.({
                resultType: NAVIGATION.FEEDS,
                _feed: id,
                _user: member.id,
                label: member.fullname,
                value: member.id,
                ...member
            });
        });
    }, [onAddParticipant, members, inputValue, id, _myUser]);

    const handleRemoveFeed = useCallback(() => {
        members.forEach(member => onRemoveParticipant?.(member, '_user'));
    }, [members, onRemoveParticipant]);

    const hasParticipant = useMemo(() => {
        if (!members.length || (members.length === 1 && members[0].isMyUser)) return false;

        return members.every(member => {
            if (member.isMyUser) {
                return true;
            }

            return inputValue.some(item => item?._user === member?.id)
        });
    }, [members, inputValue]);

    return (
        <Row
            label={name}
            onAddParticipant={handleAddFeed}
            onRemoveParticipant={handleRemoveFeed}
            hasParticipant={hasParticipant}
            members={members}
            avatarSize="md"
            avatarMode=""
            maxAvatarCount={14}
        >
            {!inputValue.length && showDirectCall ? (
                <ButtonClick onClick={onCloseModal}>
                    <CallButton
                        disabled={members.length === 1 && members[0].isMyUser}
                        iconName="Phone"
                        members={members}
                        _feed={id}
                    >
                        <p>{intl.formatMessage({ id: 'call.call' })}</p>
                    </CallButton>
                </ButtonClick>
            ) : null}
        </Row>
    );
});

const FeedList = (props: ListProps): JSX.Element => (
    <InfiniteScrollList
        dataKey={NAVIGATION.FEEDS}
        component={Feed}
        {...props}
    />
);

export default memo(FeedList as React.FC<ListProps>);
