import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import Link from 'components/Shared/Common/Link';

const move = keyframes`
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
`;

export default styled(Link)`
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.bgRoom};
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    justify-content: space-between;
    flex-shrink: 0;
    overflow: hidden;
    &.offline{
        opacity: 0.4;
    }
    &.hold{
        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.4)};
        background-image:
            linear-gradient(
            -45deg,
            ${({ theme }) => rgba(theme.fixedWhite, 0.2)} 25%,
            transparent 25%,
            transparent 50%,
            ${({ theme }) => rgba(theme.fixedWhite, 0.2)} 50%,
            ${({ theme }) => rgba(theme.fixedWhite, 0.2)} 75%,
            transparent 75%,
            transparent
        );
        background-size:50px 50px;
        animation: ${move} 2s linear infinite;
        .badge{
            &.waiting{
                padding: 2px 10px;
                background-color: ${({ theme }) => rgba(theme.fixedWhite, 0.2)};
                color: ${({ theme }) => theme.fixedWhite};
            }
        }
    }
    &.error{
        background-color: ${({ theme }) => theme.error};
        .overlay{
            display: none!important;
        }
        .error{
            display: flex;
            flex-direction: row;
            flex: 1;
            min-width: 0;
            min-height: 0;
            width: 100%;
            padding: 0 10px;
            justify-content: center;
            .label{
                font-size: 10px;
                color: ${({ theme }) => theme.fixedWhite};
            }
            .icon{
                svg{
                    width: 16px;
                    height: 16px;
                    color: ${({ theme }) => theme.fixedWhite};
                }
            }
        }
    }
    &.loading{
        .loader-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            .label{
                margin-top: 60px;
                color: ${({ theme }) => theme.fixedWhite};
                font-size: 12px;
            }
        }
        .overlay{
            display: none!important;
        }
        .loader{
            width: 60px;
            height: 60px;
            .dot{
                width: 15px;
                height: 15px;
            }
        }
    }
    &.active{
        border: 3px solid ${props => props.theme.mainColor2};
    }
    .centered-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        z-index: 2;
        .label{
            font-size: 12px;
            color: ${({ theme }) => theme.fixedWhite};
            text-align: center;
        }
        .avatar{
            width: 60px;
            height: 60px;
        }
        img{
            border-radius: 50%;
            max-width: 100%;
        }
    }
    .avatar{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon{
            display: flex;
            align-items: center;
            &.danger{
                svg{
                    color: ${({ theme }) => theme.error};
                }
            }
            svg{
                width: 14px;
                height: 14px;
                color: ${({ theme }) => theme.fixedWhite};
            }
        }
    }
    &:hover{
        .overlay{
            background-color: ${({ theme }) => rgba(theme.bgRoom, 0.7)};
        }
    }
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transition: all 250ms ease;
        background-color: ${({ theme }) => rgba(theme.bgRoom, 0.2)};
        z-index: 1;
    }
    .admin{
        .icon{
            display: flex;
            align-items: center;
            svg{
                color: gold;
                width: 12px;
                height: 12px;
            }
        }
    }
    .name{
        color: ${({ theme }) => theme.fixedWhite};
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .line{
        &-wrapper{
            display: flex;
            flex-direction: column;
            flex: 1;
            z-index: 2;
            width: 100%;
            .line{
                &:last-child{
                    align-items: flex-end;
                }
                &:first-child{
                    align-items: flex-start;
                }
            }
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 5px;
        flex: 1;
        min-height: 0;
        min-width: 0;
        &-container{
            display: flex;
            flex-direction: row;
            align-items: baseline;
            min-height: 0;
            min-width: 0;
            &:last-child{
                margin-left: 10px;
            }
            >div{
                margin-right: 5px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
`;
