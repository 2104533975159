const storage = (provider) => {
    const get = (ns) => {
        const data = provider.getItem(ns);

        if (!Number.isNaN(data)) {
            // eslint-disable-line
            return data;
        }

        if (data) {
            return JSON.parse(data);
        }

        return null;
    };

    const set = (ns, item) => {
        if (item) {
            provider.setItem(ns, item);
        }

        return null;
    };

    const setItems = (ns, data) => {
        if (data) {
            provider.setItem(ns, JSON.stringify({ updatedAt: new Date(), items: data }));
        }

        return null;
    };

    const remove = ns => provider.removeItem(ns);

    return {
        get,
        set,
        setItems,
        remove
    };
};

export default storage;
