import styled from 'styled-components';

/*
Details here: https://github.com/reactjs/react-transition-group/tree/v1-stable
*/

export const Style = styled.div`
    .amp-toaster-toast-content {
        padding: 0.5rem;
    }

    .amp-toaster-container {
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        z-index: 1000;
        padding-top: 1rem;
        top: 0;
        left: 50%;
        box-sizing: border-box;
        max-height: 100vh;
        max-width: 100vw;
        overflow: hidden;
        transform: translateX(-50%);
    }

    .amp-toaster-toast {
        opacity: 1;
        transform: translateY(0);
        transition: all 300ms ease-out;
        max-height: 100px;
        overflow: hidden;
    }

    .amp-toaster-fade-enter {
        transform: translateY(-100%);
        opacity: 0.01;
    }

    .amp-toaster-fade-enter-active {
        opacity: 1;
        transform: translateY(0%) scale(1.05);
    }

    .amp-toaster-fade-leave {
    }

    .amp-toaster-fade-leave-active {
        opacity: 0;
        max-height: 0;
    }

`;
