import { apiClient as request } from '@amplement/backend-connector';

export const $patchUserEmail = (email, password) =>
    request.apiClient({
        url: '/settings/email',
        method: 'PUT',
        body: { email, password }
    });

export const $patchUserPassword = (currentPassword, password) =>
    request.apiClient({
        url: '/settings/password',
        method: 'PUT',
        body: { currentPassword, password }
    });
