/* eslint-disable */
import React from 'react';

export default props => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g id="VideoOutgoing" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
            <path d="M23.5,10.1 C23.2,9.9 22.8,10 22.5,10.2 L17,14.1 L17,12 C17,10.3 15.7,9 14,9 L3,9 C1.3,9 0,10.3 0,12 L0,21 C0,22.7 1.3,24 3,24 L14,24 C15.7,24 17,22.7 17,21 L17,17.9 L22.4,21.8 C22.6,21.9 22.8,22 23,22 C23.2,22 23.3,22 23.5,21.9 C23.8,21.7 24,21.4 24,21 L24,11 C24,10.6 23.8,10.3 23.5,10.1 Z M15,21 C15,21.6 14.6,22 14,22 L3,22 C2.4,22 2,21.6 2,21 L2,12 C2,11.4 2.4,11 3,11 L14,11 C14.6,11 15,11.4 15,12 L15,21 Z M22,12.9 L22,19.1 L17.7,16 L22,12.9 Z M23.8034934,0.104803493 C24.0131004,0.209606987 24.2227074,0.419213974 24.3275109,0.628820961 C24.4323144,0.733624454 24.4323144,0.943231441 24.4323144,1.04803493 L24.4323144,7.33624454 C24.4323144,7.9650655 24.0131004,8.38427948 23.3842795,8.38427948 C22.7554585,8.38427948 22.3362445,7.9650655 22.3362445,7.33624454 L22.3362445,3.56331878 L17.7816594,8.11790393 C17.3624454,8.5371179 16.7336245,8.5371179 16.3144105,8.11790393 C15.8951965,7.69868996 15.8951965,7.069869 16.3144105,6.65065502 L20.8689956,2.09606987 L17.0960699,2.09606987 C16.4672489,2.09606987 16.0480349,1.6768559 16.0480349,1.04803493 C16.0480349,0.419213974 16.4672489,0 17.0960699,0 L23.3842795,0 C23.489083,0 23.69869,0 23.8034934,0.104803493 Z" id="videooutgoing" fill="currentColor" fillRule="nonzero"></path>
        </g>
    </svg>
);

