import { connect } from 'react-redux';
import { actions, selectors } from '@amplement/backend-connector';

import { setDeviceSettings } from 'actions/roomSettings';
import MoreButton from './index';

const mapStateToProps = (state, ownProps) => {
    const { isPrivate, name, isPersistent, features } = selectors.rooms.getRoomByIdSelector(state, ownProps._room) || {};

    return {
        isPrivate,
        name,
        isPersistent,
        isMeAdmin: selectors.rooms.getUserIsAdminByCurrentUserIdSelector(state, ownProps._room),
        canChangeVisibility: features?.public,
        persistence: features?.persistence,
        feed: features?.feed,
        editMembers: features?.editMembers
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    requestPatchRoom: (room) => dispatch(actions.rooms.requestPatchRoom(ownProps._room, room)),
    setDeviceSettings: (settings) => dispatch(setDeviceSettings(settings)),
    onLeave: (_room) => dispatch(actions.rooms.requestRoomQuit(_room)),
    onDelete: (_room) => dispatch(actions.rooms.requestDeleteRoom(_room))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MoreButton);
