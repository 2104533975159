import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import localeData from 'i18n';
import config from 'config';
import { utils } from '@amplement/backend-connector';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

const handleError = (error) => {
    utils.errorHandler.captureException(error, 'i18n');
};

const CustomIntlProvider = (props) => {
    const { locale, children } = props;

    useWhyDidYouUpdate('components:CustomIntlProvider', props);

    return (
        <IntlProvider
            locale={locale}
            messages={localeData[locale]}
            defaultLocale={config.defaultLanguage}
            onError={handleError}
        >
            {children}
        </IntlProvider>
    );
};

CustomIntlProvider.propTypes = {
    locale: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]).isRequired
};

// This component will be connected to redux to access chosen local language (settings)
export default memo(CustomIntlProvider);
