import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Zip = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-zip" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-img" fill={color} fillRule="nonzero">
                {withBorder && (
                    <Border />
                )}
            </g>
            <g
                id="Group"
                transform="translate(31.000000, 36.000000)"
                fill={color}
                fillRule="nonzero"
            >
                <rect
                    id="Rectangle-path"
                    x="13.6565097"
                    y="112.170822"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="0.2677747"
                    y="120.204063"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="13.6565097"
                    y="96.1043398"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="0.2677747"
                    y="104.137581"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="13.6565097"
                    y="80.0378578"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="0.2677747"
                    y="88.0710988"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="13.6565097"
                    y="63.9713758"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="0.2677747"
                    y="72.0046168"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="13.6565097"
                    y="47.9048938"
                    width="13.388735"
                    height="8.033241"
                />
                <rect
                    id="Rectangle-path"
                    x="0.2677747"
                    y="55.9381348"
                    width="13.388735"
                    height="8.033241"
                />
                <path
                    d="M25.7063712,1.04432133 L4.2843952,1.04432133 C2.81163435,1.04432133 1.6066482,2.24930748 1.6066482,3.72206833 L1.6066482,37.1939058 C1.6066482,38.6666667 2.81163435,39.8716528 4.2843952,39.8716528 L25.7063712,39.8716528 C27.179132,39.8716528 28.3841182,38.6666667 28.3841182,37.1939058 L28.3841182,3.72206833 C28.3841182,2.24930748 27.179132,1.04432133 25.7063712,1.04432133 Z M21.6897507,22.4662973 L8.3010157,22.4662973 L8.3010157,7.73868883 L21.6897507,7.73868883 L21.6897507,22.4662973 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);

Zip.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

Zip.defaultProps = {
    color: '#00B8DF',
    withBorder: true
};

export default Zip;
