import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import ThumbUserName from 'components/Room/Participant/ThumbUserName';
import useTimeout from 'hooks/useTimeout';

const Style = styled.div`
    width: 160px;
    height: 90px;
    background-color: orange;
    position: relative;
    transition: width 250ms ease, height 250ms ease;
    margin-bottom: 10px;
    cursor: pointer;
    overflow: hidden;

    .state{
        color: white;
        text-align: center;
        font-size: 12px;
        padding-top: 3px;
    }
`;

const ThumbLeave = memo(({ intl, name }) => {
    const [leaving, setLeaving] = useState(true);
    const handleLeave = () => {
        setLeaving(false);
    }
    useTimeout(handleLeave, 5000);

    if (leaving) {
        return (
            <Style>
                <div className="state">
                    {intl.formatMessage({ id: 'room.responder.leaving' })}
                </div>
                <ThumbUserName name={name} />
            </Style>
        );
    }
    return null;
});

ThumbLeave.propTypes = {
    name: PropTypes.string,
    intl: PropTypes.any.isRequired
};

ThumbLeave.defaultProps = {
    name: ''
};

export default memo(injectIntl(ThumbLeave));
