import { connect } from 'react-redux';

import { getDesktopNotificationStatusSelector } from 'selectors/user';
// import { requestPatchUser } from 'actions/session';
// import { getUserIdFromToken } from 'services/token';
import desktopNotificationManager from 'services/desktopNotificationManager';
import { addNotification } from 'actions/notification';
import Form from './index';

const mapStateToProps = (state) => ({
    initialValues: {
        desktopNotifications: {
            global: getDesktopNotificationStatusSelector(state)?.global ?? true,
        },
    },
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (values) => {
        if (values.desktopNotifications?.global !== undefined) {
            desktopNotificationManager.setEnable(values.desktopNotifications?.global);
            dispatch(addNotification({ severity: 'success', title: 'profile.submit.success' }));
        } else {
            dispatch(addNotification({ title: 'profile.submit.failure', referenceError: 'USR4' }));
        }
        // not used by front AND back
        // dispatch(
        //     requestPatchUser(getUserIdFromToken(), {
        //         settings: {
        //             ...values,
        //         }
        //     })
        // );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
