import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import  { actions, selectors } from '@amplement/backend-connector';

import FeedItemList from 'components/Feed/FeedItemList';

import { openModal } from 'actions/modal';
import { MODAL_UPDATE_FEEDITEM, MODAL_DELETE_FEEDITEM } from 'types/modal';
import { getCurrentUserIdSelector } from 'selectors/user';

const mapStateToProps = (state, { _feed }) => {
    const items = selectors.feedItems.getFeedItems(state, _feed);
    const isEndReached = selectors.feedItems.isEndReachedFeedItemsByFeedSelector(_feed)(state);
    const isLoading = selectors.feedItems.isFetchingFeedItemsByFeedSelector(_feed)(state);
    const { _feedItem: _selectedFeedItem } = selectors.feeds.feedInputByIdSelector(_feed)(state);

    return ({
        _currentUser: getCurrentUserIdSelector(state),
        isEndReached,
        items,
        isLoading,
        _selectedFeedItem
    });
};

const mapDispatchToProps = (dispatch, { _feed }) => ({
    getFeedItems: (reset = false) => dispatch(actions.feedItems.requestGetFeedItems(_feed, reset)),
    updateFeedItem: (_feedItem, feedItem) => dispatch(
        openModal(MODAL_UPDATE_FEEDITEM, { _feed, _feedItem, feedItem })
    ),
    deleteFeedItem: (_feedItem, content, local = false) => {
        if (local) {
            dispatch(actions.feedItems.requestDeleteLocalFeedItems([_feedItem]));
        } else {
            dispatch(        
                openModal(MODAL_DELETE_FEEDITEM, { _feed, _feedItem, content })
            )
        }
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(FeedItemList);
