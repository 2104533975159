import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Switch, Button, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import styled from 'styled-components';

import Icon from 'components/Shared/Common/Icon';

const { Title, Text } = Typography;

const StyleButton = styled(Button)`
    width: 50px!important;
    height: 50px!important;
    &:not([disabled]){
        &:active{
            transform: scale(0.85);
        }
    }
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
`;

const Style = styled.div`
    width: 100%;
    padding: 0;
    margin: 20px auto;
    height: auto;
    max-width: 250px;
    .row{
        align-items: flex-start;
        clear: both;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        &:last-child{
            .digit{
                background-color: transparent;
                &:hover{
                    background-color: transparent;
                }
            }
        }
        &.center{
            .digit{
                .title{
                    margin-top: 0;
                }
            }
        }
        &.first{
            .digit{
                &:first-child{
                    .title{
                        margin-top: 0;
                    }
                }
            }
        }
        .digit{
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            margin-bottom: 10px;
            transition: all 250ms ease;
            position: relative;
            .title{
                margin-top: -10px;
                margin-bottom: 0;
            }
            .sub{
                position: absolute;
                bottom: 6px;
                &.prefix{
                    bottom: 8px;
                }
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
    }
`;

const PhoneKeyPad = memo((props) => {
    const { isDisabled, digit, sub, onClick } = props;

    const handleClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDisabled && onClick) onClick(digit);
        return false;
    }, [isDisabled, onClick, digit]);

    return (
        <StyleButton
            className="digit"
            onClick={handleClick}
            size="large"
            shape="circle"
        >
            <Title className="title" level={4} strong>{digit}</Title>
            {sub && <Text className="sub">{sub}</Text>}
        </StyleButton>
    );
});

PhoneKeyPad.propTypes = {
    onClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    sub: PropTypes.string,
    digit: PropTypes.string.isRequired,
};

PhoneKeyPad.defaultProps = {
    sub: undefined,
    isDisabled: false
};

const PhoneKeyboard = (props) => {
    const { onRemoveLastChar, hasRemoveLastChar, isDisabled, prefix, onTogglePrefix, intl, onKeyPress, className } = props;
    const digitProps = { onClick: onKeyPress, isDisabled }

    return (
        <Style className={classNames(className, 'dig-pad', {
            disable: isDisabled
        })}
        >
            <div className="row first">
                <PhoneKeyPad digit="1" {...digitProps} />
                <PhoneKeyPad digit="2" sub="ABC" {...digitProps} />
                <PhoneKeyPad digit="3" sub="DEF" {...digitProps} />
            </div>
            <div className="row">
                <PhoneKeyPad digit="4" sub="GHI" {...digitProps} />
                <PhoneKeyPad digit="5" sub="JKL" {...digitProps} />
                <PhoneKeyPad digit="6" sub="MNO" {...digitProps} />
            </div>
            <div className="row">
                <PhoneKeyPad digit="7" sub="PQRS" {...digitProps} />
                <PhoneKeyPad digit="8" sub="TUV" {...digitProps} />
                <PhoneKeyPad digit="9" sub="WXYZ" {...digitProps} />
            </div>
            <div className="row center">
                <PhoneKeyPad digit="*" {...digitProps} />
                <PhoneKeyPad digit="0" {...digitProps} />
                <PhoneKeyPad digit="#" {...digitProps} />
            </div>
            {((onTogglePrefix && prefix !== null && prefix !== undefined) || onRemoveLastChar) && (
                <div className="row">
                    <div className="digit">
                        {(onTogglePrefix && prefix !== null && prefix !== undefined) && (
                            <>
                                <div className="prefix sub">{intl.formatMessage({id : 'drawer.sip.label.prefix' })}</div>
                                <Switch
                                    defaultChecked
                                    onChange={onTogglePrefix}
                                    disabled={isDisabled}
                                />
                            </>
                        )}
                    </div>
                    <div className="digit" />
                    <div className="digit">
                        {onRemoveLastChar && (
                            <StyleButton
                                type="primary"
                                danger
                                shape="circle"
                                size="large"
                                onClick={onRemoveLastChar}
                                disabled={!hasRemoveLastChar || isDisabled}
                                icon={<Icon iconName="Delete" />}
                            />
                        )}
                    </div>
                </div>
            )}
        </Style>
    );
}

PhoneKeyboard.propTypes = {
    intl: PropTypes.any.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onRemoveLastChar: PropTypes.func,
    onTogglePrefix: PropTypes.func,
    isDisabled: PropTypes.bool,
    hasRemoveLastChar: PropTypes.bool,
    number: PropTypes.string,
    className: PropTypes.string,
    prefix: PropTypes.string
};

PhoneKeyboard.defaultProps = {
    onRemoveLastChar: null,
    onTogglePrefix: null,
    number: null,
    className: '',
    prefix: null,
    hasRemoveLastChar: false,
    isDisabled: false
};

export default injectIntl(memo(PhoneKeyboard));
