import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { useDebounce } from 'react-use';
import { Typography, Row, Col, Space, Button, TagProps } from 'antd';
import { useSelector } from 'react-redux';
import { CloseSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { SpaceSize } from 'antd/es/space';
import styled from 'styled-components';
import { selectors, constants } from '@amplement/backend-connector';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/Shared/Common/Icon';
import MultiSelect from 'components/Shared/Forms/MultiSelect';
import SearchResults from 'components/CallBuilder/SearchResults';
import DefaultResults from 'components/CallBuilder/DefaultResults';
import CallButton from 'components/CallBuilder/CallButton';
import { NAVIGATION, NavigationKeys } from 'components/CallBuilder/constants';
import Participant, { getCustomNavButtonsType } from 'components/CallBuilder/types';

import useSearch, { SearchResultsData } from 'hooks/useSearch';
import { usePostInviteRoom, usePostRoom } from 'hooks/useRooms';

import { isNumber } from 'utils/sip';
import { getUrl } from 'utils/url';
import usePhonenumber from 'hooks/usePhonenumber';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

const { Text, Paragraph } = Typography;

const MAX_MEMBERS = 24;
const MAX_MEMBERS_WITH_ME = MAX_MEMBERS - 1;
const MAX_SIP = 2;
const SPACE_SIZE: SpaceSize | [SpaceSize, SpaceSize] = [5,5];
const StyledLabel = styled(Paragraph)`
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 5px!important;
    margin-top: 10px;
`;
const StyledMultiSelect = styled(MultiSelect)`
    .ant-select-selector {
        min-height: 50px;
    }
`;
const StyledWrapper = styled.div`
    position: relative;
`;

type StyledFakedDropdownProps = {
    maxHeight: number;
};

const StyledFakedDropdown = styled.div<StyledFakedDropdownProps>`
    &.active{
        background-color: ${props => props.theme.bg.global};
        box-shadow: 0 0 4px ${props => rgba(props.theme.black, 0.1)};
        border: 1px solid ${props => rgba(props.theme.black, 0.1)};
        padding: 0 20px;
        border-radius: 6px;
        position: absolute;
        top: calc(100% - 20px);
        left: 0;
        width: 100%;
        max-height: ${(props) => props.maxHeight}px;
        overflow: auto;
    }
`;
const StyledSearchOutlined = styled(SearchOutlined)`
    pointer-events: none !important;
`;
const StyledCount = styled(Row)`
    margin-top: 5px;
`;
const StyledButtonRow = styled(Row)`
    margin-top: 20px;
`;
const StyledClearButton = styled(Button)`
    padding: 0;
    font-size: 11px;
    height: auto;
    span{
        text-decoration: underline;
    }
    &:hover{
        background-color: transparent!important;
        span{
            text-decoration: none;
        }
    }
`;

const CONFIRM_KEYS = ['Enter', 'Tab', ' ', ',', ';'];
const MAX_BY_PAGE = 20;

type TagRenderProps = {
    label: string;
} & TagProps;

const StyledRemoveTagIcon = styled(CloseSquareOutlined)`
    margin-left: 5px;
`;

const TagRender = ({ closable, onClose, label }: TagRenderProps) => (
    <div className="ant-select-selection-item">
        <Text>
            {label}
        </Text>
        {closable && (
            <Text className="ant-select-selection-item-remove" onClick={onClose}>
                <StyledRemoveTagIcon />
            </Text>
        )}
    </div>
);

const getParameters = (searchValue, navigation, page) => {
    const isNumeric = isNumber(searchValue);
    const query = isNumeric ? undefined : searchValue;

    let filters;
    if ((!isNumeric && searchValue)) {
        filters = [];
    } else if (navigation === NAVIGATION.RECENT_CALLS) {
        filters = undefined;
    } else {
        filters = [navigation];
    }

    return {
        query,
        filters,
        limit: MAX_BY_PAGE,
        page: query ? 1 : page,
    };
}

const trimSpaces = (value: string) => value.replace(/^\s+/, '');

const formatMessages = ({ intl, maxMembers }) => ({
    maxMemberInvitationLimit: intl.formatMessage({ id: 'room.error.maxMemberInvitationLimit' }, { maxMembers }),
    maxSipMemberInvitationLimit: intl.formatMessage({ id: 'room.error.maxSipMemberInvitationLimit' }, { maxMembers: MAX_SIP }),
    warningNumber: intl.formatMessage({ id: 'callbuilder.warning.number' }),
    placeholder: intl.formatMessage({ id: 'callbuilder.placeholder.search' }),
    labelParticipants: intl.formatMessage({ id: 'callbuilder.label.participants' }, { maxMembers, maxSip: MAX_SIP }),
    labelRoomParticipants: intl.formatMessage({ id: 'callbuilder.label.room.participants' }, { maxMembers, maxSip: MAX_SIP }),
    removeAll: intl.formatMessage({ id: 'global.button.removeAll' }),
    forbiddenWarningNumber: intl.formatMessage({ id: 'callbuilder.forbidden.warning.number' }),
});

type CallBuilderProps = {
    onFocus?: () => void;
    isListOpen?: boolean;
    isFakeListDropdown?: boolean;
    onBlur?: () => void;
    resetCallBuilder?: boolean;
    setResetCallBuilder?: (value: boolean) => void;
    onInputChange?: (inputValue) => void;
    onlySearch?: boolean;
    onClose: () => void;
    _room?: string;
    autoFocus?: boolean;
    maxMembers?: number;
    memberCount?: number;
    showLabel?: boolean;
    maxListHeight?: number;
    useSip?: boolean;
    selectPlaceholder?: string;
    showDirectCall?: boolean;
    sipMemberCount?: number;
    getCustomNavButtons?: getCustomNavButtonsType;
    searchResultsKeys?: NavigationKeys[];
    defaultNavigation?: NavigationKeys;
} & WrappedComponentProps;

const CallBuilder = ({
    intl,
    onClose,
    maxListHeight,
    resetCallBuilder,
    setResetCallBuilder,
    showLabel,
    selectPlaceholder,
    _room,
    autoFocus,
    useSip,
    onFocus,
    onBlur,
    onInputChange,
    defaultNavigation = NAVIGATION.BOOKMARKS,
    onlySearch,
    maxMembers = MAX_MEMBERS_WITH_ME,
    showDirectCall,
    memberCount = 0,
    sipMemberCount = 0,
    isListOpen = true,
    searchResultsKeys,
    isFakeListDropdown = false,
    getCustomNavButtons,
}: CallBuilderProps): JSX.Element => {
    const i18n = useMemo(() => formatMessages({ intl, maxMembers }), [intl, maxMembers]);
    const _client = useSelector<string>(selectors.session._currentClientSelector);
    const blurTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
    const [showWarningNumberMessage, setShowWarningNumberMessage] = useState<boolean>(false);
    const [forbiddenNumberMessage, setForbiddenNumberMessage] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<Participant[]>([]);
    const [navigation, setNavigation] = useState<NavigationKeys>(defaultNavigation);
    const [searchValue, setSearchValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
    const searchParameters = useMemo(() =>
        getParameters(debouncedSearchValue, navigation, page),
    [debouncedSearchValue, navigation, page]);
    const { data: searchResults = {}, isFetching } = useSearch(searchParameters);
    const { mutateAsync: post } = usePostRoom({ _client });
    const { mutateAsync: postInvite } = usePostInviteRoom({ _room });
    const maxReached = inputValue.length + memberCount > maxMembers;
    const sipCount = inputValue.filter(item => item.phoneNumber).length;
    const maxSipReached = sipCount + sipMemberCount >= MAX_SIP;
    const maxSipError = sipCount + sipMemberCount > MAX_SIP;

    const handleInputChange = useCallback((newValues: string[]) => {
        const oldValues = inputValue.map(item => item.value);
        const valueRemoved = oldValues.find(value => !newValues.includes(value));
        if (valueRemoved) {
            setInputValue(inputValue.filter(item => item.value !== valueRemoved));
        }
    }, [inputValue]);

    const onAddParticipant = useCallback((value: Participant) => {
        if (!maxReached) {
            setInputValue((prevValues) => [...prevValues, value]);
            setSearchValue('');
            if (blurTimeout) {
                clearTimeout(blurTimeout.current);
            }
        }
    }, [maxReached]);

    const checkBlur = useCallback(() => {
        if (blurTimeout) {
            blurTimeout.current = setTimeout(() => {
                if (isListOpen && (!inputValue.length || onlySearch)) {
                    onBlur?.();
                }
            }, 200);
        }
    }, [searchValue, onBlur, isListOpen, inputValue, onlySearch]);

    const handleBlur = useCallback(() => {
        setSearchValue(searchValue);
        checkBlur();
    }, [searchValue, inputValue, checkBlur]);

    const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (maxReached) return;

        if (CONFIRM_KEYS.includes(event.key)) {
            const target = event.target as HTMLInputElement;
            const value = trimSpaces(target.value).replace(/[^+*#0-9]/g, '');

            if (!CONFIRM_KEYS.includes(target.value) && value && useSip) {
                onAddParticipant({ label: value , value, phoneNumber: value });
            }
        }
    }, [onAddParticipant, maxReached, searchValue]);

    const handleSelectParticipant = useCallback(() => setSearchValue(''), []);

    const onRemoveParticipant = useCallback((participant: Participant, filterKey: string) => {
        setInputValue(
            (prevValues) => filter(prevValues, (value) => get(value, filterKey) !== get(participant, filterKey))
        );
    }, []);

    const onClear = useCallback(() => {
        setInputValue([]);
        setSearchValue('');
        onBlur?.();
    }, [onBlur]);

    const handleSearch = useCallback(value => {
        setSearchValue(trimSpaces(value));
        setPage(1);
    }, []);

    const handleNavigationChange = useCallback((key: NavigationKeys) => {
        setNavigation(key);
        if (key !== NAVIGATION.RECENT_CALLS) {
            setPage(1);
        }
    }, []);

    const { format } = usePhonenumber({ prefix: true });

    const handleCall = useCallback(async () => {
        const _users = inputValue.map(item => !item.phoneNumber ? item._user : undefined).filter(user => !!user);
        const destNumbers = inputValue.map(item => item.phoneNumber && format(item.phoneNumber)).filter(phoneNumber => !!phoneNumber);
        // backend does not support multiple destNumbers & _users at same time for now
        const payload = {
            _users: _users.length ? _users : undefined,
            destNumbers: destNumbers.length ? destNumbers : undefined,
        };

        if (_room) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await postInvite(payload);
            onClear();
            onClose();

            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = await post({ payload, type: constants.rooms.ROOM_TYPE.AUDIO });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response?.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const roomUrl = getUrl('room', { _room: response?.data?.id }) as string;
            const a = document.createElement('a');
            a.href = roomUrl;
            a.target = '_blank';
            a.click();
            onClear();
            onClose();
        }
    } , [inputValue, format, _client, post, onClear, onClose, _room, postInvite]);

    useDebounce(() => setDebouncedSearchValue(searchValue), 200, [searchValue]);

    useEffect(() => {
        if (useSip) {
            setShowWarningNumberMessage(isNumber(searchValue));
        } else {
            setForbiddenNumberMessage(isNumber(searchValue));
        }
        if (!isListOpen && searchValue) {
            onFocus?.();
        }
    }, [searchValue]);

    useEffect(() => {
        if ((!inputValue.length || onlySearch) && isListOpen) {
            onBlur?.();
        }
    }, [inputValue]);

    useEffect(() => {
        if (onInputChange) {
            onInputChange(inputValue);
        }
    }, [inputValue, onInputChange]);

    useEffect(() => {
        if (resetCallBuilder) {
            setResetCallBuilder?.(false);
            onClear();
        }
    }, [resetCallBuilder]);

    return (
        <StyledWrapper>
            <Row align="middle" justify="end">
                <Col flex={1}>
                    {showLabel && (
                        <StyledLabel>
                            {_room ? i18n.labelRoomParticipants : i18n.labelParticipants}
                        </StyledLabel>
                    )}
                    <StyledMultiSelect
                        autoFocus={autoFocus}
                        onFocus={onFocus}
                        value={inputValue}
                        onChange={handleInputChange}
                        searchValue={searchValue}
                        onSearch={handleSearch}
                        dropdownRender={null}
                        onBlur={undefined}
                        onBlurChange={handleBlur}
                        onSelect={handleSelectParticipant}
                        onKeyDown={onKeyDown}
                        open={false}
                        suffixIcon={<StyledSearchOutlined />}
                        tagRender={TagRender}
                        placeholder={selectPlaceholder || i18n.placeholder}
                    />
                    {showWarningNumberMessage && useSip && <Text type="warning">{i18n.warningNumber}</Text>}
                    {maxReached && <Text type="danger">{i18n.maxMemberInvitationLimit}</Text>}
                    {maxSipError && useSip && (
                        <Text type="danger">
                            {i18n.maxSipMemberInvitationLimit}
                        </Text>
                    )}
                    {forbiddenNumberMessage && !useSip && (
                        <Text type="danger">
                            {i18n.forbiddenWarningNumber}
                        </Text>
                    )}
                    {isListOpen && (
                        <StyledCount
                            align="middle"
                            justify="space-between"
                        >
                            {!!inputValue.length ? (
                                <StyledClearButton onClick={onClear} type="text">
                                    {i18n.removeAll}
                                </StyledClearButton>
                            ) : <div/>}
                            <Space size={SPACE_SIZE}>
                                <Icon
                                    bgColor={_room ? colors.white : colors.black}
                                    iconName="Users"
                                />
                                <Text>{inputValue.length + memberCount} / {maxMembers}</Text>
                            </Space>
                        </StyledCount>
                    )}
                </Col>
            </Row>
            {isListOpen && (
                <StyledFakedDropdown
                    className={classNames(
                        { 'active': isFakeListDropdown }
                    )}
                    maxHeight={maxListHeight || 200}
                >
                    {searchValue && !showWarningNumberMessage ? (
                        <SearchResults
                            showDirectCall={showDirectCall}
                            searchResultsKeys={searchResultsKeys}
                            isLoading={isFetching}
                            maxSipReached={maxSipReached}
                            inputValue={inputValue as Participant[]}
                            searchResults={searchResults as SearchResultsData}
                            onAddParticipant={onAddParticipant}
                            onRemoveParticipant={onRemoveParticipant}
                            onCloseModal={onClose}
                            onChangePage={setPage}
                            query={debouncedSearchValue}
                        />
                    ) : (
                        <DefaultResults
                            getCustomNavButtons={getCustomNavButtons}
                            showDirectCall={showDirectCall}
                            isLoading={isFetching}
                            maxSipReached={maxSipReached}
                            inputValue={inputValue}
                            searchResults={searchResults as SearchResultsData}
                            navigation={navigation}
                            onNavigationChange={handleNavigationChange}
                            onAddParticipant={onAddParticipant}
                            onRemoveParticipant={onRemoveParticipant}
                            onCloseModal={onClose}
                            onChangePage={setPage}
                            query={debouncedSearchValue}
                        />
                    )}
                    {!onlySearch && (
                        <StyledButtonRow align="middle" justify="center">
                            <CallButton
                                _room={_room}
                                maxReached={maxReached || maxSipError}
                                onCall={handleCall}
                                inputValue={inputValue}
                            />
                        </StyledButtonRow>
                    )}
                </StyledFakedDropdown>
            )}
        </StyledWrapper>
    );
};

CallBuilder.propTypes = {
    _room: PropTypes.string,
    autoFocus: PropTypes.bool,
    resetCallBuilder: PropTypes.bool,
    setResetCallBuilder: PropTypes.func,
    showLabel: PropTypes.bool,
    maxListHeight: PropTypes.number,
    selectPlaceholder: PropTypes.string,
    useSip: PropTypes.bool,
    showDirectCall: PropTypes.bool,
    onInputChange: PropTypes.func,
    searchResultsKeys: PropTypes.arrayOf(PropTypes.oneOf(Object.values(NAVIGATION))),
    defaultNavigation: PropTypes.string,
    onlySearch: PropTypes.bool,
    memberCount: PropTypes.number,
    sipMemberCount: PropTypes.number,
    maxMembers: PropTypes.number,
    isListOpen: PropTypes.bool,
    isFakeListDropdown: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    getCustomNavButtons: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

CallBuilder.defaultProps = {
    showDirectCall: false,
    autoFocus: false,
    setResetCallBuilder: undefined,
    maxListHeight: undefined,
    resetCallBuilder: false,
    showLabel: false,
    useSip: false,
    selectPlaceholder: '',
    maxMembers: MAX_MEMBERS_WITH_ME,
    sipMemberCount: 0,
    searchResultsKeys: undefined,
    defaultNavigation: undefined,
    onInputChange: undefined,
    onlySearch: false,
    memberCount: 0,
    _room: undefined,
    isListOpen: true,
    isFakeListDropdown: false,
    onFocus: undefined,
    onBlur: undefined,
    getCustomNavButtons: undefined,
};

export default injectIntl(CallBuilder as React.FC<CallBuilderProps>);
