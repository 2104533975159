import * as smallDrawerTypes from 'types/smallDrawer';
import { setAllElementsInvisible } from 'utils/state/drawer';

const intialState = {
    [smallDrawerTypes.FEED_DRAWER_INFORMATIONS]: { isVisible: false },
    [smallDrawerTypes.FEED_DRAWER_MEMBERS]: { isVisible: false },
    [smallDrawerTypes.FEED_DRAWER_GALLERY]: { isVisible: false }
};

export default (state = intialState, action) => {
    const { smallDrawerType, type, ...rest } = action || {};

    switch (type) {
        case smallDrawerTypes.OPEN_SMALL_DRAWER:
            return {
                ...setAllElementsInvisible(state),
                [smallDrawerType]: {
                    isVisible: true,
                    ...rest
                }
            };

        case smallDrawerTypes.CLOSE_SMALL_DRAWER:
            return {
                ...state,
                [smallDrawerType]: {
                    isVisible: false,
                    ...rest
                }
            };

        case smallDrawerTypes.CLOSE_ALL_SMALL_DRAWERS:
            return {
                ...setAllElementsInvisible(state)
            };

        default:
            return state;
    }
};
