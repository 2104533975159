import Logger from 'services/debug/logger';

import useMediaDeviceFinder from './useMediaDeviceFinder';

const logger = new Logger('component:room:useMediaDeviceFinderAll');

const useMediaDeviceFinderAll = () => {
    const audioinput = useMediaDeviceFinder('audioinput');
    const audiooutput = useMediaDeviceFinder('audiooutput');
    const videoinput = useMediaDeviceFinder('videoinput');

    logger.log('useMediaDeviceFinderAll:render', audioinput, audiooutput, videoinput)

    return [audioinput, audiooutput, videoinput];
};

export default useMediaDeviceFinderAll;
