/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import { apis } from '@amplement/backend-connector';
import { AxiosError } from 'axios';

/**
{
    id: '00000000-0000-0000-0000-000000000000',
    type: 'number',
    _entity: '22222222-2222-2222-2222-222222222222', // MYC user attached to the phone number
    _user: '11111111-1111-1111-1111-111111111111',
    data: {
        number: '0836656565',
        label: 'Père Noël'
    }
    relatedEntity: { // enriched entity
        id: '22222222-2222-2222-2222-222222222222',
        firstname: 'Jean-Claude',
        lastname: 'Dus',
        avatarUri: 'https://myc.io/avatar.png',
        status: 0 // can maybe be computed using client data
    }
}
 */

type Bookmark = {
    id: string
    type: string
    _entity?: string
    _user?: string
    data: {
        number: string
        label: string
    }
    relatedEntity: { // enriched entity
        id: string
        firstname?: string
        lastname?: string
        avatarUri?: string
        status?: number // can maybe be computed using client data
    }
}

type PostBookmarkData = {
    externalId: string
    label: string
    phoneNumber: string
}

type PostBookmark = {
    data: PostBookmarkData
    type: 'phoneNumber'
    _entity?: string
}

const useBookmarks = ({ type, enabled = true }) => {
    const result = useQuery(
        ['bookmarks', { type }],
        async () : Promise<Bookmark> => {
            const response = await apis.bookmarks.$getBookmarks(type);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled,
            staleTime: 60000,
        }
    );
    return result;
};

export const usePostBookmark = ({ type, onSuccess, onError, intl }) => {
    const mutation = useMutation(
        ({ _entity, ...data }: PostBookmarkData & { _entity: string }) => apis.bookmarks.$postBookmark({ type, data, _entity } as PostBookmark),
        {
            onSuccess: () => {
                message.success({
                    key: 'callForwardPostSuccess',
                    content : intl.formatMessage({id: 'bookmarks.notification.created'}),
                    duration: 3,
                });
                onSuccess?.();
            },
            onError: (e: AxiosError<{message?: string}>) => {
                message.error({
                    key: 'callForwardPostError',
                    content : e.response?.data?.message || e.message,
                    duration: 5,
                });
                onError?.();
            },
        });

    return mutation;
}

export const useDeleteBookmark = ({ onSuccess, onError, intl }) => {
    const mutation = useMutation(
        id => apis.bookmarks.$deleteBookmark(id),
        {
            onSuccess: () => {
                message.success({
                    key: 'callForwardPostSuccess',
                    content : intl.formatMessage({id: 'bookmarks.notification.deleted'}),
                    duration: 3,
                });
                onSuccess?.();
            },
            onError: (e: AxiosError<{message?: string}>) => {
                message.error({
                    key: 'callForwardPostError',
                    content : e.response?.data?.message || e.message,
                    duration: 5,
                });
                onError?.();
            },
        });

    return mutation;
}

export default useBookmarks;
