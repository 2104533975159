/* eslint-disable global-require */
import React, { lazy, Suspense, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import { Router as ConnectedRouter } from "react-router-dom";
import { history } from 'store';
import config from 'config';

import { Auth } from 'services/authentication';
import Dashboard from 'screens/Dashboard/container';
import Contacts from 'screens/Contacts/container';
import Activities from 'screens/Activities/container';
import ReadOnlyProfile from 'screens/Profile/container';
import EditableProfile from 'screens/EditableProfile/container';
import Settings from 'screens/Settings/container';
import Room from 'screens/Room/container';
import RoomReception from 'screens/RoomReception/container';
import Feed from 'screens/Feed/container';
import RedirectToFeed from 'screens/Redirects/Feed';
import RedirectToRoom from 'screens/Redirects/Room';
import Logout from 'screens/Redirects/Logout';
import Login from 'screens/Login';
import Files from 'screens/Files';
import Page404 from 'screens/404';
import Health from 'screens/Health';
import GuestLogin from 'screens/GuestLogin/container';
import callSettings from 'screens/Call';
import SchedulerPage from 'screens/Scheduler';
// import Local from 'screens/Local/container';

import IframeReader from 'components/Shared/Layout/IframeReader';
import ErrorFormatter from 'components/Shared/Errors/Formatter';

import CatchError from 'components/CatchError';

import { getUrl, isCallUrl } from 'utils/url';

import RedirectLayout from 'components/Layouts/Redirect/container';
// import ContentLayout from 'components/Layouts/Content/container';
// import FullscreenLayout from 'components/Layouts/Fullscreen';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import GlobalError from 'components/Shared/Errors/GlobalError';
import AppDebugger from 'components/Shared/Debug';
import { ROLES } from 'const/user';
import { isLiveDebug } from 'services/debug/helper';
import { useTour } from "@reactour/tour";
import { config as backendConfig } from '@amplement/backend-connector';
import RoomCallNotificationModalList from 'components/Shared/Call/Notifications/RoomCallNotificationModalList/container';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { message } from 'antd';
import useReactQuerySubscription from 'hooks/useReactQuerySubscription';
import SupportedBrowsers from 'components/SupportedBrowsers';

const ContentLayout = lazy(() => import('../components/Layouts/Content/container'));
const FullscreenLayout = lazy(() => import('../components/Layouts/Fullscreen'));

const Version = () => process.env.PKG_VERSION;

const ErrorComponent = () => (
    <ErrorFormatter
        id="error.default"
        buttonType={BUTTON_TYPE.RELOAD}
        render={GlobalError}
    />
);

const Screens = (props) => {
    const { _currentUser, tabs, roles, isAuthenticated } = props;
    const { setIsOpen } = useTour();
    useReactQuerySubscription();

    useEffect(() => {
        if (isCallUrl()) {
            backendConfig.setConfig({ activity: { fetchOnWsReload: false } });
            backendConfig.setConfig({ feed: { fetchOnWsReload: false } });
            backendConfig.setConfig({ anchor: { fetchOnWsReload: false } });
            backendConfig.setConfig({ sipAccount: { fetchOnWsReload: false } });
            backendConfig.setConfig({ dashboard: { fetchOnWsReload: false } });
            backendConfig.setConfig({ room: { fetchOnWsReload: false } });
        }
    }, []);

    useEffect(() => {
        if (!Auth.getAuth() && props.isAuthenticated) {
            Auth.authenticate();
        }
        if (config.env && config.initialEnv !== config.env) {
            message.warning({
                key: 'envMessageInfo',
                content : `Attention, vous etes sur ${config.env}`,
                duration: 0,
                className: 'envMessageInfo'
            });
        }
    }, []);

    useEffect(() => {
        if (!Auth.getAuth() && isAuthenticated) {
            Auth.authenticate();
        } else if (Auth.getAuth() && !isAuthenticated) {
            Auth.signout();
        }
    }, [isAuthenticated])

    const renderIframeComponent = useCallback(url => () => (
        <IframeReader
            url={url}
            key={url}
            id={url}
            sandbox="allow-forms allow-scripts allow-modals allow-popups allow-same-origin allow-downloads"
        />
    ), []);

    const roomInviteFallback = useCallback(
        ({ _room } = {}) => getUrl('roomInvite', { _room, returnUrl: getUrl('room', { _room }) })
        , []);

    const openTour = useCallback(() => setIsOpen(true), []);

    useWhyDidYouUpdate('component:screens:index', props);

    return (
        <CatchError fallbackRender={<ErrorComponent />}>
            <div id="roomError" />
            <ConnectedRouter history={history}>
                {isLiveDebug() && <AppDebugger />}
                <SupportedBrowsers />
                <Suspense fallback=''>
                    <Switch>
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('login')}
                            component={Login}
                            screenName="Login"
                            exact
                        />

                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('files')}
                            component={Files}
                            screenName="Files"
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('home')}
                            component={Dashboard}
                            screenName="Dashboard"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('feedByUser')}
                            component={RedirectToFeed}
                            screenName="RedirectToFeed"
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('call')}
                            component={callSettings}
                            screenName="Call"
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('feed')}
                            component={Feed}
                            screenName="Feed"
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('internalContacts')}
                            component={Contacts}
                            screenName="Contacts"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('businessContacts')}
                            component={Contacts}
                            screenName="Contacts"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('activities')}
                            component={Activities}
                            screenName="activities"
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('dashboard')}
                            component={Dashboard}
                            screenName="Dashboard"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('ownProfile')}
                            component={ReadOnlyProfile}
                            screenName="Profile"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('profileEdition')}
                            component={EditableProfile}
                            screenName="EditableProfile"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('profile')}
                            component={ReadOnlyProfile}
                            screenName="Profile"
                            exact
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('scheduler')}
                            screenName="Scheduler"
                            component={SchedulerPage}
                            exact
                        />
                        <RedirectLayout roles={roles} path={getUrl('logout')} component={Logout} />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('settings')}
                            component={Settings}
                            screenName="Settings"
                        />
                        {tabs.map(
                            tab =>
                                tab.url && (
                                    <ContentLayout
                                        openTour={openTour}
                                        roles={roles}
                                        path={getUrl(`custom-${tab.name}`)}
                                        key={tab.name}
                                        component={renderIframeComponent(
                                            tab.url.replace(':_user', _currentUser)
                                        )}
                                        screenName={`Custom-${tab.name}`}
                                        hasPrimaryNav={tab.hasPrimaryNav}
                                        hasSecondaryNav={tab.hasSecondaryNav}
                                    />
                                )
                        )}
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('version')}
                            component={Version}
                            exact
                        />
                        {/* <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            path={getUrl('secret')}
                            component={Local}
                            screenName="SecretPage"
                            exact
                        /> */}
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('roomByUser')}
                            component={RedirectToRoom}
                            screenName="RedirectToRoom"
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('roomByNumber')}
                            component={RedirectToRoom}
                            screenName="RedirectToRoom"
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('roomByFeed')}
                            component={RedirectToRoom}
                            screenName="RedirectToRoom"
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('roomCreate')}
                            component={RedirectToRoom}
                            screenName="RedirectToRoom"
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('room')}
                            exact
                            component={Room}
                            screenName="Room"
                            fallbackTo={roomInviteFallback} // sera automatisé plus tard
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('roomInvite')}
                            exact
                            component={RoomReception}
                            screenName="RoomReception"
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('loginGuest')}
                            exact
                            component={GuestLogin}
                            screenName="GuestLogin"
                        />
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('health')}
                            exact
                            component={Health}
                            screenName="Health"
                        // fallbackTo={getUrl('loginGuest', { _room: 'pr-Lb', returnUrl: getUrl('health') })}
                        />
                        {config.isLocalEnv && (
                            <FullscreenLayout
                                roles={roles}
                                path={getUrl('whitelabelHome', { lang: 'fr' })}
                                exact
                                component={Login}
                                screenName="Login"
                            />
                        )}
                        <FullscreenLayout
                            roles={roles}
                            path={getUrl('404')}
                            exact
                            component={Page404}
                        />
                        <ContentLayout
                            openTour={openTour}
                            roles={roles}
                            component={Page404}
                            screenName="404"
                        />
                    </Switch>
                </Suspense>
                <RoomCallNotificationModalList />
            </ConnectedRouter>
        </CatchError>
    );
}

Screens.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    _currentUser: PropTypes.string,
    tabs: PropTypes.array,
    roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ROLES)))
};

Screens.defaultProps = {
    _currentUser: null,
    tabs: [],
    roles: []
};

export default memo(Screens);
