import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { StarOutlined } from '@ant-design/icons';

import MemberItemContainer from 'components/Room/MemberManagement/Member/container';
import MemberItemList from 'components/Room/MemberManagement/MemberList';
import TextError from 'components/Room/MemberManagement/TextError';
import MemberCounter from 'components/Room/MemberManagement/MemberCounter';
import PremiumBlock from 'components/Shared/Blocks/PremiumBlock';
import CallBuilder from 'components/CallBuilder';

import { getCustomNavButtonsType } from 'components/CallBuilder/types';

import { NAVIGATION } from 'components/CallBuilder/constants';

const StyledDiv = styled.div`
    margin-top: 10px;
`;

const getNavButtons: getCustomNavButtonsType = (intl, navigation, onNavigationChange) => [
    {
        type: 'link',
        children: (
            <p>
                <StarOutlined /> {intl.formatMessage({ id: 'call.tabs.bookmarks' })}
            </p>
        ),
        disabled: navigation === NAVIGATION.BOOKMARKS,
        onClick: () => onNavigationChange(NAVIGATION.BOOKMARKS)
    },
];

const MemberManagementDrawer = ({
    _room,
    users,
    maxMembers,
    canManage,
    intl
}) => {
    const [isCallbuilderVisible, setIsCallbuilderVisible] = useState(false);
    const handleFocus = useCallback(() => setIsCallbuilderVisible(true), []);
    const handleBlur = useCallback(() => setIsCallbuilderVisible(false), []);
    const handleClose = useCallback(() => setIsCallbuilderVisible(false), []);

    const sipMemberCount = useMemo(() => users.filter(user => user.phonenumber).length, [users]);
    const _users = useMemo(() => users.map(user => user._user), [users]);
    const memberCount = users.length;
    const isLoading = false;

    return (
        <>
            {canManage && (
                <CallBuilder
                    isListOpen={isCallbuilderVisible}
                    onClose={handleClose}
                    _room={_room}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    getCustomNavButtons={getNavButtons}
                    maxMembers={maxMembers}
                    memberCount={memberCount}
                    sipMemberCount={sipMemberCount}
                    useSip
                    showLabel
                />
            )}


            {!isCallbuilderVisible && (
                <StyledDiv>
                    {canManage && (
                        <MemberCounter
                            memberCount={memberCount}
                            maxMembers={maxMembers}
                        />
                    )}

                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {canManage && <PremiumBlock descriptionKey="premium.roomMemberCountUsage" />}

                    {maxMembers && maxMembers < memberCount && (
                        <TextError
                            errorKey="room.error.maxMemberInvitationLimit"
                            errorValues={{ maxMembers }}
                        />
                    )}
                    <MemberItemList
                        _room={_room}
                        ids={_users}
                        isLoading={isLoading}
                        fallbackMessage={intl.formatMessage({ id: 'room.error.noActiveClient' })}
                        ItemComponent={MemberItemContainer}
                        canManage={canManage}
                    />
                </StyledDiv>
            )}
        </>
    );
}

MemberManagementDrawer.propTypes = {
    intl: PropTypes.any.isRequired,
    _room: PropTypes.string.isRequired,
    canManage: PropTypes.bool,
    users: PropTypes.array,
    maxMembers: PropTypes.number
};

MemberManagementDrawer.defaultProps = {
    maxMembers: undefined,
    users: [],
    canManage: undefined
};

export default injectIntl(MemberManagementDrawer);
