import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from 'components/Shared/Common/Button';
import { Dropdown, Typography } from 'antd';
import { constants, actions } from '@amplement/backend-connector';
import { MAX_USER_DISPLAYED_ON_INCOMING } from 'const/call';
import IncomingCallLayout from 'components/Shared/Call/Notifications/IncomingCallLayout';
import AvatarList from 'components/Shared/Call/AvatarList';
import { getUrl } from 'utils/url';
import { useDispatch } from 'react-redux';
import { StyledFastReply } from './styles';

const { AUDIO, VIDEO } = constants.rooms.ROOM_TYPE;
const { Text } = Typography;
const styleText = { fontSize: 9 };

const FastReplyDropDown = injectIntl(memo(({ intl, _room }) => {
    const dispatch = useDispatch();
    
    const fastReplyMessages = useMemo(() => [
        intl.formatMessage({ id: 'room.actions.fastReplies.01' }),
        intl.formatMessage({ id: 'room.actions.fastReplies.02' }),
        intl.formatMessage({ id: 'room.actions.fastReplies.03' }),
        intl.formatMessage({ id: 'room.actions.fastReplies.04' })
    ], []);

    const handleFastMsg = useCallback((content) => () => {
        dispatch(actions.rooms.sendMessageToCaller(_room, content));
    }, [_room, dispatch]);

    const menu = useMemo(() => ({
        items: fastReplyMessages.map(message => ({
            key: message,
            onClick: handleFastMsg(message),
            label: message
        }))
    }), [fastReplyMessages]);

    return (
        <StyledFastReply>
            <Dropdown
                menu={menu}
                placement="top"
                arrow
                trigger={['click']}
            >
                <div className="button-container">
                    <Button
                        iconName="MessageSquare"
                        color="secondary"
                        size="md"
                    />
                    <Text
                        className="label"
                        style={styleText}
                        strong
                    >
                        {intl.formatMessage({ id: 'room.actions.chat' })}
                    </Text>
                </div>
            </Dropdown>
        </StyledFastReply>
    );
}));

const RoomIncommingCall = (props) => {
    const {
        avatarUri,
        callerName,
        description,
        roomType,
        onAccept,
        onReject,
        onIgnore,
        onClose,
        intl,
        members,
        hasFastReply,
        _room,
        transferToPhonenumber
    } = props;
    const videoRoomUrl = getUrl('room', { _room, kind: VIDEO });
    const audioRoomUrl = getUrl('room', { _room });
    
    const handleAcceptAudio = useCallback(() => {
        onAccept(_room, AUDIO);
        onClose();
    }, [_room, onAccept]);

    const handleAcceptVideo = useCallback(() => {
        onAccept(_room, VIDEO);
        onClose();
    }, [_room, onAccept]);

    const handleReject = useCallback(() => {
        onReject(_room);
        onClose();
    }, [_room, onReject]);

    const handleIgnore = useCallback(() => {
        onIgnore(_room);
        onClose();
    }, [_room, onIgnore]);

    return (
        <IncomingCallLayout
            _room={_room}
            transferToPhonenumber={transferToPhonenumber}
            avatarUri={avatarUri}
            callerName={callerName}
            description={description}
            body={
                members?.length ? (
                    <div className="list-participants-container">
                        <div className="label">
                            {intl.formatMessage({ id: 'room.actions.memberList' })}
                        </div>
                        <AvatarList
                            members={members}
                            hasMore
                            maxCount={MAX_USER_DISPLAYED_ON_INCOMING}
                        />
                    </div>
                ) : null
            }
            actions={(
                <>
                    {hasFastReply && <FastReplyDropDown _room={_room} />}
                    <div className="action-container-line align-end">
                        <div className="button-container">
                            <Button
                                iconName="Phone"
                                color="success"
                                animation="pulse"
                                href={audioRoomUrl}
                                target={`room-${_room}`}
                                onClick={handleAcceptAudio}
                            />
                            <Text
                                className="label"
                                style={styleText}
                                strong
                            >
                                {intl.formatMessage({ id: 'call.button.label.audio' })}
                            </Text>
                        </div>
                        {roomType === constants.rooms.ROOM_TYPE.VIDEO && (
                            <div className="button-container">
                                <Button
                                    iconName="Video"
                                    color="success"
                                    animation="pulse"
                                    href={videoRoomUrl}
                                    target={`room-${_room}`}
                                    onClick={handleAcceptVideo}
                                />
                                <Text
                                    className="label"
                                    style={styleText}
                                    strong
                                >
                                    {intl.formatMessage({ id: 'call.button.label.video' })}
                                </Text>
                            </div>
                        )}
                        <div className="button-container">
                            <Button
                                iconName="BellOff"
                                onClick={handleIgnore}
                                color="secondary"
                            />
                            <Text
                                className="label"
                                style={styleText}
                                strong
                            >
                                {intl.formatMessage({ id: 'global.button.ignore' })}
                            </Text>
                        </div>
                        <div className="button-container danger">
                            <Button
                                iconName="PhoneOff"
                                color="danger"
                                onClick={handleReject}
                            />
                            <Text
                                className="label"
                                style={styleText}
                                strong
                            >
                                {intl.formatMessage({ id: 'call.button.endCall' })}
                            </Text>
                        </div>
                    </div>
                </>
            )}
        />
    );
}

RoomIncommingCall.propTypes = {
    _room: PropTypes.string.isRequired,
    transferToPhonenumber: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    avatarUri: PropTypes.any,
    roomType: PropTypes.oneOf(Object.values(constants.rooms.ROOM_TYPE)),
    callerName: PropTypes.string,
    description: PropTypes.string.isRequired,
    onReject: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onIgnore: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired
};

RoomIncommingCall.defaultProps = {
    transferToPhonenumber: false,
    callerName: '',
    avatarUri: '',
    roomType: constants.rooms.ROOM_TYPE.AUDIO
};

export default injectIntl(RoomIncommingCall);
