import { compose } from 'redux';
import { connect } from 'react-redux';

import { getRouteParams } from 'utils/url';
import { actions, selectors } from '@amplement/backend-connector';

import BasicRoomReception from './index';

const mapStateToProps = (state, ownProps) => {
    const _room = getRouteParams('_room')(ownProps);
    const caller = selectors.rooms.getRoomInitiatorByRoomIdSelector(state, _room);
    const room = selectors.rooms.getRoomByIdSelector(state, _room);
    const { name, isPrivate, id } = room || {};

    return {
        _room,
        name,
        isPrivate,
        caller,
        isLoading: !id
    };
};

const mapDispatchToProps = dispatch => ({
    requestGetRoom: _room => dispatch(actions.rooms.requestGetRoom(_room))
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(BasicRoomReception);
