import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AvatarEqualizer from 'components/Room/Participant/AvatarEqualizer';
import styled from 'styled-components';
import { rgba } from 'polished';

export const PlayerAvatar = memo(({ avatar, track, updatedAt, isMe, isTrackMuted, _client }) => (
    <div className="pulse">
        <AvatarEqualizer 
            track={track} 
            updatedAt={updatedAt} 
            isMe={isMe} 
            isTrackMuted={isTrackMuted} 
            _client={_client}
            avatar={avatar} 
        />
    </div>
));

const StyledTitle = styled.div`
    margin-top: 20px;
    color: ${({ theme }) => theme.fixedWhite};
    font-size: 16px;
    font-weight: bold;
`;

const StyledSubTitle = styled.div`
    color: ${({ theme }) => rgba(theme.fixedWhite, 0.6)};
    font-size: 14px;
    font-weight: 400;
`;

const RoomCallUserInfos = ({
    avatar,
    title,
    subTitle,
    children,
    track,
    updatedAt,
    isMe,
    isTrackMuted,
    _client
}) => (
    <>
        <PlayerAvatar 
            avatar={avatar} 
            track={track} 
            updatedAt={updatedAt} 
            isMe={isMe}
            isTrackMuted={isTrackMuted} 
            _client={_client}
        />
        {title && <StyledTitle>{title}</StyledTitle>}
        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
        {children}
    </>
);

RoomCallUserInfos.propTypes = {
    avatar: PropTypes.object,
    track: PropTypes.object,
    updatedAt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    isMe: PropTypes.bool,
    isTrackMuted: PropTypes.bool,
    _client: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.any,
};

RoomCallUserInfos.defaultProps = {
    avatar: undefined,
    title: undefined,
    subTitle: undefined,
    children: undefined,
    track: undefined,
    isMe: undefined,
    isTrackMuted: undefined,
    _client: undefined,
    updatedAt: undefined,
};

export default RoomCallUserInfos;
