import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Drawer } from 'antd';
import { rgba } from 'polished';
import StyleListUserItem from 'components/Room/MemberManagement/Member/styles';

// avoid react warnings, remove useless props
const DrawerContainer = ({ hasNoPadding, hasHorizontalPadding, noOverflowBody, ...rest }) => <Drawer {...rest} />;

const StyleDrawer = styled(DrawerContainer)`
    ${StyleListUserItem}{ /* todo, déplacer dans style.js su component cible ? */
        padding: 10px;
        transition: all 250ms ease;
        cursor: pointer;
        border-radius: 4px;
        margin: 0!important;
        &:hover{
            background-color: ${({ theme }) => theme.bgHoverListUsers};
        }
    }
    .ant-tabs{
        flex: 1;
        min-height: 0;
        min-width: 0;
        height: 100%;
        flex-shrink: 0;
        &-content{
            flex-shrink: 0;
            flex: 1;
            height: 100%;
            min-height: 0;
        }
    }
    .ant-drawer{
        &-title{
            font-size: 24px;
            margin-right: 20px;
            .s-title-page{
                margin-bottom: 0;
                padding-bottom: 0;
                &:after{
                    content: unset;
                }
            }
        }
        &-body{
            padding: 20px;
            display: flex;
            flex-direction: column;
            .ant-tabs-content-holder{
                overflow: auto;
            }
        }
        ${({ noOverflowBody }) => noOverflowBody && `
            &-body{
                overflow: hidden;
            }
        `}
        ${({ padding }) => padding === "md" && `
            &-body{
                padding: 10px;
            }
        `}
        ${({ hasHorizontalPadding }) => hasHorizontalPadding && `
            &-body{
                padding: 20px 0;
                .ant-tabs-content-holder{
                    padding: 0 20px;
                }
            }
        `}
        ${({ hasNoPadding }) => hasNoPadding && `
            &-body{
                padding: 0;
            }
        `}
        &-header{
            border-color: ${props => rgba(props.theme.black, .15)};
            &-title{
                flex: 1;
                flex-direction: row-reverse;
            }
        }
        &-close{
            margin-right: 0;
        }
        &-footer{
            .actions{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

const AntdDrawer = ({
    isOpen,
    destroyOnClose,
    placement,
    title,
    onClose,
    hasHorizontalPadding,
    hasNoPadding,
    width,
    mask,
    padding,
    noOverflowBody,
    children,
    footer,
    // ...otherProps
}) => (
    <StyleDrawer
        title={title}
        width={width}
        mask={mask}
        onClose={onClose}
        open={isOpen}
        footer={footer}
        noOverflowBody={noOverflowBody}
        padding={padding}
        hasNoPadding={hasNoPadding}
        hasHorizontalPadding={hasHorizontalPadding}
        placement={placement}
        destroyOnClose={destroyOnClose}
        // {...otherProps}
    >
        {children}
    </StyleDrawer>
);

AntdDrawer.propTypes = {
    title: PropTypes.any.isRequired,
    placement: PropTypes.string,
    padding: PropTypes.string,
    hasHorizontalPadding: PropTypes.bool,
    hasNoPadding: PropTypes.bool,
    noOverflowBody: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    footer: PropTypes.any,
    mask: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    destroyOnClose: PropTypes.bool
};

AntdDrawer.defaultProps = {
    isOpen: false,
    placement: 'right',
    destroyOnClose: undefined,
    hasHorizontalPadding: false,
    hasNoPadding: false,
    width: 500,
    mask: true,
    footer: undefined,
    noOverflowBody: false,
    padding: ''
};

export default AntdDrawer;
