/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import isEqual from 'react-fast-compare';
import { Space, Row, Col, Grid } from 'antd';

import Button from 'components/Shared/Common/Button';
import Separator from 'components/Shared/Layout/Separator';
import ToggleSound from 'components/Shared/Common/SoundButton/ToggleSound';
import { StyledInterfaceHeader } from 'components/Shared/style';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import { getUrl } from 'utils/url';
import { isWebRtcCompatible } from 'utils/webRTC';

import FeedHeaderUnicastIdentity from './FeedHeaderUnicastIdentity';
import FeedHeaderMulticastIdentity from './FeedHeaderMulticastIdentity';
import DropDownActions from './DropDownActions';

const { useBreakpoint } = Grid;

const formatMessages = intl => ({ name }) => ({
    contactDrawer: intl.formatMessage({
        id: 'feed.header.dropdown.contactDrawer',
        defaultMessage: 'Membres du groupe'
    }),
    startRoom: intl.formatMessage({
        id: 'feed.header.button.drawer.room',
        defaultMessage: 'Démarrer une conférence'
    }),
    startRoomAudio: intl.formatMessage({
        id: 'feed.header.button.drawer.room.audio',
        defaultMessage: 'Conférence audio'
    }),
    contactDrawerTooltip: intl.formatMessage({
        id: 'feed.header.button.drawer.users',
        defaultMessage: 'Membres'
    }),
    settingDrawerTooltip: intl.formatMessage({
        id: 'feed.header.button.drawer.settings',
        defaultMessage: 'Paramètres'
    }),
    galleryDrawerTooltip: intl.formatMessage({
        id: 'feed.header.button.drawer.gallery',
        defaultMessage: 'Documents'
    }),
    soundOff: intl.formatMessage({ id: 'menu.dropdown.sound.multicast.off' }, {
        b: chunks => <b>{chunks}</b>,
        groupName: name
    }),
    soundOn: intl.formatMessage({ id: 'menu.dropdown.sound.multicast.on' }),
});

const FeedHeader = (props) =>  {
    const {
        _feed,
        isMuted,
        onMute,
        name,
        color,
        description,
        intl,
        isContactDrawerOpen,
        isGalleryDrawerOpen,
        user,
        isFeedUnicast,
        isLoading,
        closeDrawer,
        onOpenContactsDrawer,
        onOpenGalleryDrawer,
        presence
    } = props;
    const audioRoomUrl = getUrl('roomByFeed', { _feed });
    const i18n = formatMessages(intl)(props);
    const handleOpenContactsDrawer = useCallback(() =>
        isContactDrawerOpen
            ? closeDrawer()
            : onOpenContactsDrawer(), [isContactDrawerOpen, closeDrawer, onOpenContactsDrawer]);
    const handleOpenGalleryDrawer = useCallback(() =>
        isGalleryDrawerOpen
            ? closeDrawer()
            : onOpenGalleryDrawer(), [isGalleryDrawerOpen, closeDrawer, onOpenGalleryDrawer]);

    useWhyDidYouUpdate('components:colleft:feed:header', props);

    const screens = useBreakpoint();
    const isCompact = screens?.xs || !screens?.lg;

    if (!_feed || isLoading) {
        return null;
    }

    const feedIdentity = isFeedUnicast ? (
        user && <FeedHeaderUnicastIdentity {...user} presence={presence} />
    ) : (
        name && <FeedHeaderMulticastIdentity name={name} color={color} description={description} />
    );

    const callButtons = isFeedUnicast ? (
        <Space>
            <Button
                className="icon-bell"
                color="primary"
                href={audioRoomUrl}
                target={_feed ? `roomByFeed-${_feed}` : 'blank'}
                iconName={isWebRtcCompatible() ? 'Phone' : 'PhoneOff'}
                isTransparent
                tooltipText={i18n.startRoomAudio}
                tooltipPosition="bottom"
                isDisabled={!isWebRtcCompatible()}
            />

            <Separator />
            <Button
                iconName="Paperclip"
                isTransparent
                tooltipPosition="bottom"
                tooltipText={i18n.galleryDrawerTooltip}
                onClick={handleOpenGalleryDrawer}
                active={isGalleryDrawerOpen}
            />
        </Space>
    ) : (
        <Space>
            <ToggleSound
                enabled={!isMuted}
                onClick={onMute}
                iconClassName="black"
                buttonClassName="small"
                iconOff="BellOffCustom"
                iconOn="Bell"
                soundOff={i18n.soundOff}
                soundOn={i18n.soundOn}
            />
            <Separator />
            {isCompact ? (
                <Button
                    href={audioRoomUrl }
                    target={_feed ? `roomByFeed-${_feed}` : 'blank'}
                    iconName={isWebRtcCompatible() ? 'Phone' : 'PhoneOff'}
                    isTransparent
                    tooltipText={i18n.startRoomAudio}
                    tooltipPosition="bottom"
                    isDisabled={!isWebRtcCompatible()}
                />)
                : (
                    <>
                        <Button
                            className="icon-bell"
                            color="primary"
                            href={audioRoomUrl}
                            target={_feed ? `roomByFeed-${_feed}` : 'blank'}
                            isDisabled={!isWebRtcCompatible()}
                        >
                            {i18n.startRoom}
                        </Button>
                        <Separator />
                        <Button
                            iconName="Users"
                            tooltipPosition="bottom"
                            tooltipText={i18n.contactDrawerTooltip}
                            isTransparent
                            onClick={handleOpenContactsDrawer}
                            active={isContactDrawerOpen}
                        />
                        <Button
                            iconName="Paperclip"
                            isTransparent
                            tooltipPosition="bottom"
                            tooltipText={i18n.galleryDrawerTooltip}
                            onClick={handleOpenGalleryDrawer}
                            active={isGalleryDrawerOpen}
                        />
                    </>
                )}
            <Separator />
            <DropDownActions
                groupName={name}
                onClickContactsDrawer={handleOpenContactsDrawer}
                onClickGalleryDrawer={handleOpenGalleryDrawer}
                _feed={_feed}
                isCompact={isCompact}
            />
        </Space>
    );

    return (
        <StyledInterfaceHeader className="interface-content-header">
            <Col span={24}>
                <Row justify="space-between" align="middle">
                    <Col flex="1">
                        <Row>
                            {feedIdentity}
                        </Row>
                    </Col>
                    <Col>
                        <div className="actions">{callButtons}</div>
                    </Col>
                </Row>
            </Col>
        </StyledInterfaceHeader>
    );
}

FeedHeader.propTypes = {
    isFeedUnicast: PropTypes.bool,
    isMuted: PropTypes.bool,
    onMute: PropTypes.func,
    onOpenContactsDrawer: PropTypes.func.isRequired,
    onOpenGalleryDrawer: PropTypes.func.isRequired,
    feed: PropTypes.object,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    isContactDrawerOpen: PropTypes.bool,
    isGalleryDrawerOpen: PropTypes.bool,
    presence: PropTypes.any,
    closeDrawer: PropTypes.func.isRequired
};

FeedHeader.defaultProps = {
    isFeedUnicast: null,
    isMuted: false,
    onMute: () => {},
    feed: null,
    user: null,
    isContactDrawerOpen: false,
    isGalleryDrawerOpen: false,
    presence: undefined
};

export default injectIntl(memo(FeedHeader, isEqual));
