import React from 'react';

export default props => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            id="volume-off"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g
                id="volume-2"
                transform="translate(1.000000, 1.000000)"
                stroke="currentColor"
                strokeWidth="2"
            >
                <path d="M0,0 L22,22" id="Path" />
                <polygon
                    id="Path"
                    points="5 8 1 8 1 14 5 14 10 18 10 10 6.49316406 6.50683594"
                />
                <path
                    d="M10,4 L10,6 L10,4 Z M8.4339558,5.24278744 L9.96604469,3.95721222 L8.4339558,5.24278744 Z"
                    id="Combined-Shape"
                />
                <path
                    d="M18.07,3.93 C20.0195008,5.88008965 20.9954542,8.43493496 20.9978601,10.9905266 C20.9991784,12.3908754 20.7081847,13.7914484 20.124879,15.0928702 M14.54,7.46 C15.5096716,8.42996446 15.9976288,9.69916396 16.0038716,10.97029 C16.0055766,11.3174517 15.97135,11.6647572 15.9011919,12.0061496"
                    id="Shape"
                />
            </g>
        </g>
    </svg>

);
