import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Ppt = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-ppt" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero" id="Shape">
                {withBorder && <Border />}
                <path
                    d="M62,73.4 C62,77.1 60.8,79.9 58.5,81.9 C56.2,83.9 52.9,84.8 48.7,84.8 L45.6,84.8 L45.6,97 L38.3,97 L38.3,62.7 L49.2,62.7 C53.4,62.7 56.5,63.6 58.7,65.4 C60.9,67.2 62,69.9 62,73.4 Z M45.6,78.9 L48,78.9 C50.2,78.9 51.9,78.5 53,77.6 C54.1,76.7 54.7,75.4 54.7,73.7 C54.7,72 54.2,70.7 53.3,69.9 C52.4,69.1 50.9,68.7 48.9,68.7 L45.6,68.7 L45.6,78.9 Z"
                />
            </g>
        </g>
    </svg>
);

Ppt.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

Ppt.defaultProps = {
    color: '#FF5A29',
    withBorder: true
};

export default Ppt;
