import React from 'react';
import { Button } from 'antd';

import { StyledCustomTabs } from '../styles';

const NavButtons = ({ buttons }) => (
    <StyledCustomTabs>
        {buttons.map(({ children, ...props }, index) => (
            <Button key={`${props.id || props.label || index}`} {...props}>{children}</Button>
        ))}
    </StyledCustomTabs>
);

export default NavButtons;
