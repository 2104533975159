import styled from 'styled-components';

export const Styled =  styled.div`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    min-height: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.mainColor1};
    overflow: auto;
    align-items: center;
    justify-content: center;
    min-height: min-content;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: 999;
    .logo{
        margin-bottom: 80px;
        max-width: 100px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        img{
            display: flex;
            align-items: flex-start;
            max-width: 100%;
        }
    }
    .container{
        z-index: 1;
    }
    .wrapper {
        max-width: 500px;
        margin: 0px auto;
        width: 100%;

        .content-container {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            min-height: 0;
            .ant-input{
                background-color: transparent;
                &:hover{
                    background-color: transparent;
                }
            }
            .block-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                .text-container p {
                    margin: 0;
                    font-size: 16px;
                    color: #FFF;
                    font-weight: 500;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                .avatar-container {
                    margin-bottom: 10px;
                }
            }
            .ant{
                &-row{
                    input{
                        background-color: #FFF!important;
                        color: #000!important;
                        &::placeholder{
                            color: rgba(0,0,0.4)!important;
                        }
                    }
                }
            }

            .action-container {
                .btn{
                    &.primary{
                        border-radius: 6px;
                        padding: 10px 20px;
                        .label{
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
                &.jc-fe{
                    justify-content: flex-end;
                }
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
`;
