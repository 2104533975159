import { useState, useEffect, useRef } from 'react';
import workletUrl from 'services/webRTC/speakingMeter.worklet';

const AudioContext = window.AudioContext || window.webkitAudioContext;

const HISTORY_ROTATION_COUNT = 8;

export default ({
    track,
    refreshRate,
    isMe,
    isTrackMuted,
    _client,
    setSoundMeters,
    soundMeters,
}) => {
    const [strength, setStrength] = useState();
    const [, setModuleLoaded] = useState();
    const audioContext = useRef();
    const node = useRef();
    const [error, setError] = useState();
    const [warning, setWarning] = useState();
    const [muted, setMuted] = useState();
    const [speaking, setSpeaking] = useState(false);

    useEffect(() => {
        if (!AudioContext && isMe) {
            setError('room.error.webAudioNotSupported');
        }
    }, []);

    const processDataSpeaking = (event) => {
        const { isSpeaking, strength: volume, isMuted } = event.data;

        if (audioContext.current?.state === 'running' && track?.id) {
            setSpeaking(isSpeaking);
            setStrength(Math.min(Math.round(volume), 10));
            setSoundMeters?.(prevParticipants => ({
                ...prevParticipants,
                [_client]: {
                    isSpeaking,
                    volume,
                    muted: isMuted,
                    strength: Math.min(Math.round(volume), 10),
                },
            }));
            setMuted(isMuted);

            if (!isTrackMuted && isMuted) {
                if(isMe){
                    setError('room.error.emptyAudio.me');
                } else {
                    setWarning('room.error.emptyAudio')
                }
            } else {
                setError(undefined);
                setWarning(undefined);
            }
        }
    };

    const loadModule = async () => {
        try {
            await audioContext.current.audioWorklet.addModule(workletUrl);
            setModuleLoaded({ moduleLoaded: true });
        } catch(e) {
            setModuleLoaded({ moduleLoaded: false });
            setError('room.error.cannotAnalyseStream');
        }
    }

    const initAudioContext = async (t) => {
        const stream = new MediaStream([t]);

        try {
            audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        } catch(e) {
            if (isMe) setError('room.error.audioContextNotSupported');
        }

        const source = audioContext.current.createMediaStreamSource(stream);

        const processorOptions = { 
            updateIntervalInMS: refreshRate, 
            debug: false, 
            maxHistorySize: HISTORY_ROTATION_COUNT, 
            _client 
        };
        // load worklet, and load it if not loaded before
        try {
            node.current = new AudioWorkletNode(audioContext.current, 'speakingMeter', { processorOptions });
        }
        catch (e) {
            await loadModule();
            node.current = new AudioWorkletNode(audioContext.current, 'speakingMeter', { processorOptions });
        }

        node.current.port.onmessage = processDataSpeaking;
        source.connect(node.current);
    }

    useEffect(() => {
        setError(undefined);
        setWarning(undefined);
        if (track && !audioContext.current && !soundMeters?.[_client]) {
            initAudioContext(track);
        }
    }, [track]);

    return [
        soundMeters?.[_client] ? soundMeters?.[_client]?.strength : strength,
        muted,
        error,
        speaking,
        warning,
    ];
};
