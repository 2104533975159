import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SIP_ACCOUNT_STATUS } from 'const/call';
import { rgba, darken } from 'polished';
import { colors } from 'common/themes/Colors';

import { injectIntl } from 'react-intl';

import styled from 'styled-components';

const StyleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Style = styled.div`
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;
    background-color: ${colors.secondaryActive};
    border: 1px solid ${darken(.05, colors.secondaryActive)};
    color: ${colors.black};
    &.success{
        background-color: ${rgba(colors.success, .1)};
        border: 1px solid ${rgba(colors.success, .15)};
        color: ${colors.success};
    }
    &.danger{
        background-color: ${rgba(colors.error, .1)};
        border: 1px solid ${rgba(colors.error, .15)};
        color: ${colors.error};
    }
    &.pending{
        background-color: ${rgba(colors.infos, .1)};
        border: 1px solid ${rgba(colors.infos, .15)};
        color: ${colors.infos};
    }
    & > strong  {
        margin-left: 5px;
    }
`;

const COLOR_STATUS = {
    [SIP_ACCOUNT_STATUS.PENDING]: 'pending',
    [SIP_ACCOUNT_STATUS.NOT_REGISTERED]: 'danger',
    [SIP_ACCOUNT_STATUS.REGISTERED]: 'success',
    default: 'black'
};

const LABEL_STATUS = {
    [SIP_ACCOUNT_STATUS.PENDING]: 'drawer.sip.status.pending',
    [SIP_ACCOUNT_STATUS.NOT_REGISTERED]: 'drawer.sip.status.notRegistered',
    [SIP_ACCOUNT_STATUS.REGISTERED]: 'drawer.sip.status.registered',
    default: 'drawer.sip.status.unknown'
};

const SipStatus = ({
    status,
    intl
}) => (
    <StyleContainer>
        <Style className={COLOR_STATUS[status] || COLOR_STATUS.default}>
            {intl.formatMessage({ id: 'drawer.sip.status.label' })}:
            <strong>
                {intl.formatMessage({ id: LABEL_STATUS[status] || LABEL_STATUS.default })}
            </strong>
        </Style>
    </StyleContainer>
);

SipStatus.propTypes = {
    status: PropTypes.oneOf(Object.values(SIP_ACCOUNT_STATUS)),
    intl: PropTypes.any.isRequired
};

SipStatus.defaultProps = {
    status: undefined
};

export default memo(injectIntl(SipStatus));
