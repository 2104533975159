import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import Link from 'components/Shared/Common/Link';
import Placeholder from 'components/Shared/Common/Placeholder';
import  { i18n } from '@amplement/backend-connector';
import Icon from 'components/Shared/Common/Icon';
import { Alert } from 'antd';

import { getUrl } from 'utils/url';
import { MULTICAST } from 'const/feed';

const formatMessages = intl => ({ createdAt, createdBy }) => ({
    feedPlaceholderMulticast: createdAt ? intl.formatMessage({
        id: 'feed.multicast.create.label',
    }, {
        date: `${i18n.formatDate(new Date(createdAt))}`,
        time: `${i18n.formatTime(new Date(createdAt))}`
    }) : '',
    feedPlaceholderUnicast: createdAt ? intl.formatMessage({
        id: 'feed.unicast.create.label',
    }, {
        date: `${i18n.formatDate(new Date(createdAt))}`,
        time: `${i18n.formatTime(new Date(createdAt))}`
    }) : '',
    byUser: intl.formatMessage({
        id: 'smallDrawer.feed.information.createdAt.name',
    }, {
        name: createdBy
    }),

    feedPlaceholderSubtitle: intl.formatMessage({
        id: 'feed.placeholder.firstmessage.subtitle',
        defaultMessage: 'C\'est ici que tout a commencé'
    }),
    buttonEditGroup: intl.formatMessage({
        id: 'feed.changeProperties',
        defaultMessage: 'Modifier le groupe'
    }),
    secureExchanges: intl.formatMessage({
        id: 'feed.secureExchanges',
        defaultMessage: 'Votre échange est sécurisé de bout en bout'
    })
});

const FeedItemStartupPlaceholder = (props) => {
    const {
        intl,
        fullname,
        _user,
        _feed,
        cast,
        createdAt,
        onOpenInformationDrawer,
        isEditable
    } = props;
    const profileUrl = getUrl('profile', { _user });
    const i18nMsg = formatMessages(intl)({ createdAt, createdBy: '' });
    const isMultiCast = cast === MULTICAST;

    return (
        _feed && (
            <Placeholder
                name="placeholderGroups"
                position="left"
                className="bordered"
                title={
                    <>
                        <Alert
                            banner
                            type="error"
                            showIcon
                            icon={<Icon iconName='Lock' />}
                            message={i18nMsg.secureExchanges}
                        />
                        <br/>
                        {isMultiCast ? i18nMsg.feedPlaceholderMulticast : i18nMsg.feedPlaceholderUnicast}
                        {isMultiCast && fullname && _user && (
                            <>
                                {' '}
                                {i18nMsg.byUser}
                                <Link
                                    href={profileUrl}
                                    className="name"
                                >
                                    {fullname}
                                </Link>
                            </>
                        )}
                    </>
                }
                subTitle={i18nMsg.feedPlaceholderSubtitle}
                action={
                    (isEditable && isMultiCast) && (
                        <div className="action">
                            <Button
                                iconName="Edit3"
                                onClick={onOpenInformationDrawer}
                                size="sm"
                                color="secondary"
                            >
                                {i18nMsg.buttonEditGroup}
                            </Button>
                        </div>
                    )
                }
            />
        )
    );
};

FeedItemStartupPlaceholder.propTypes = {
    intl: PropTypes.object.isRequired,
    _feed: PropTypes.string.isRequired,
    _user: PropTypes.string,
    cast: PropTypes.number,
    onOpenInformationDrawer: PropTypes.func.isRequired,
    createdAt: PropTypes.string,
    fullname: PropTypes.string,
    isEditable: PropTypes.bool
};

FeedItemStartupPlaceholder.defaultProps = {
    createdAt: undefined,
    cast: 2,
    isEditable: false,
    fullname: undefined,
    _user: undefined
};

export default injectIntl(memo(FeedItemStartupPlaceholder));
