import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Typography } from 'antd';
import styled from 'styled-components';

import SchedulerWrapper from 'components/Shared/Forms/Scheduler/SchedulerWrapper';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import { StyledPageLayout } from 'components/Shared/style';
import Icon from 'components/Shared/Common/Icon';

const { Paragraph } = Typography;

const StyledParagraph = styled(Paragraph)`
    max-width: 650px;
`;

const SchedulerPage = ({ intl }: WrappedComponentProps) => (
    <StyledPageLayout className="ui-contact">
        <CategoryTitle
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={intl.formatMessage<string>({ id: 'room.scheduleForm.title' })}
            icon={<Icon size="xl" iconName="Calendar" />}
        />
        <StyledParagraph>{intl.formatMessage({ id: 'room.scheduleForm.description' })}</StyledParagraph>
        <SchedulerWrapper isFakeListDropdown />
    </StyledPageLayout>
);

export default injectIntl(SchedulerPage);
