const requiredHexColor = (props, propName, componentName) => {
    const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    const { [propName]: propValue } = props;

    if (!propValue || !regex.test(propValue)) {
        return new Error(`Invalid prop ${propName} passed to ${componentName}. Expected a hex color.`);
    }
    if (propValue.toLowerCase() === '#FFFFFF'.toLowerCase()) {
        return new Error(`Invalid prop ${propName} passed to ${componentName}. White color is forbidden.`);
    }
    return null;
};

const validator = (props, propName, ...rest) => {
    if (props[propName] === null) {
        return null;
    }
    return requiredHexColor(props, propName, ...rest);
};

validator.isRequired = requiredHexColor;

export default validator;
