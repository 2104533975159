import { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const LeftTemplate = ({ intl }) => intl.formatMessage({
    id: 'feedItem.leaveStatus',
    defaultMessage: 'a quitté le groupe'
});

LeftTemplate.propTypes = {
    intl: PropTypes.object.isRequired
};

LeftTemplate.defaultProps = {
};

export default memo(injectIntl(LeftTemplate));
