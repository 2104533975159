import { useQuery } from 'react-query';
import { apis } from '@amplement/backend-connector';

const useFeedMembers = ({ _feed }) => {
    const result = useQuery(
        ['feeds', { _feed }],
        async () => {
            const response = await apis.members.$getFeedMembers(_feed);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 60000,
        }
    );
    return result;
};

export default useFeedMembers;
