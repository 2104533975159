import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'redux-first-history';

import Content from 'components/Feed';
import { closeSmallDrawer, openSmallDrawer } from 'actions/smallDrawer';
import  { selectors } from '@amplement/backend-connector';

import { MULTICAST } from 'const/feed';
import { FEED_DRAWER_MEMBERS } from 'types/smallDrawer';

const mapStateToProps = (state) => {
    const isLoading = !selectors.feeds.getFeedsLoaded(state);
    const feed = selectors.feeds.getSelectedFeed(state);
    const { id: _feed } = feed;

    return {
        _feed,
        isLoading,
        isMulticast: feed?.cast === MULTICAST,
    };
};

const mapDispatchToProps = (dispatch) => ({
    navigate: (path, state) => dispatch(push(path, state)),
    openContactDrawer: () => dispatch(openSmallDrawer(FEED_DRAWER_MEMBERS)),
    closeContactDrawer: () => (dispatch(closeSmallDrawer(FEED_DRAWER_MEMBERS)))
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(Content);
