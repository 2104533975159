import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Skeleton, Row } from 'antd';

const StyleCard = styled(Row)`
    margin-bottom: 10px;
    &:last-child{
        margin-bottom: 0;
    }
    .col{
        display: flex;
        flex-direction: column;
        >div{
            margin-bottom: 5px;
            display: flex;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .ant{
        &-skeleton{
            &-avatar{
                margin-right: 10px;
            }
            &-input{
                border-radius: 6px;
                height: 12px !important;
            }
        }
    }
    .card{
        &-header{
            margin-bottom: 20px;
            margin-right: 20px;
            .ant-skeleton-avatar{
                width: 60px;
                height: 60px;
            }
        }
        &-content{
            margin-bottom: 20px;
            .ant-skeleton{
                &-element{
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

const SimpleSkeleton = ({ active, size, avatarShape, inputSize }) => (
    <StyleCard>
        <Skeleton.Avatar active={active} size={size} shape={avatarShape} />
        <div className="col">
            <Skeleton.Input style={{ width: 60 }} active={active} size={inputSize} />
            <Skeleton.Input style={{ width: 60 }} active={active} size={inputSize} />
        </div>
    </StyleCard>
)

SimpleSkeleton.propTypes = {
    active: PropTypes.bool,
    size: PropTypes.string,
    avatarShape: PropTypes.string,
    inputSize: PropTypes.string,
};

SimpleSkeleton.defaultProps = {
    active: true,
    size: 'large',
    avatarShape: 'circle',
    inputSize: 'small',
};

export default memo(SimpleSkeleton);
