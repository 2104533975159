module.exports = {
    firebaseConfig: {
        apiKey: "AIzaSyAlf-INvwIcq5tFtB_UJHbqEeLZbJJcN4c",
        authDomain: "amplement-1034.firebaseapp.com",
        databaseURL: "https://amplement-1034.firebaseio.com",
        projectId: "amplement-1034",
        storageBucket: "amplement-1034.appspot.com",
        messagingSenderId: "473980935547",
        appId: "1:473980935547:web:d9aa99f8fcfb0aa7a2b1de",
        measurementId: "G-8T506YHK41"
    },
    feedItem: {
        relativeMaxDuration: 3 // days
    },
    feed: {
        reverseFeedItems: true
    },
    maxLastFileFeedItems: 4,
    room: {
        backendPingIntervalOnWsDown: 50000,
        displayEndedCallDuration: 0,
        displayRoomEndIfPersistent: true,
        displayRoomEndIfNoMembersInvited: true,
        autoUnHoldAtRoomEnd: false
    },
    features: {
        roomReminderEnabled: true,
        roomFastReplyEnabled: true,
    },
    defaultLanguage: 'en',
    fileParserHostname: 'https://file-parser-216107.appspot.com',
    browserUpdateLink: 'https://bestvpn.org/outdatedbrowser/',
    networkMonitoring: {
        reachability: {
            reachabilityUrls: []
        },
        wsRTTCountdownOnTabFocusOut: 5000,
    },
    sentryEnabled: true,
    requestLocalStreamAtWsRoomIncoming: false,
    webRTC: {
        maxPeersRequestedConcurrencies: 12,
        streamStatsInterval: 0, // asked by cedb -> too much flood
    },
    salesNumber: '+33 1.83.75.38.89',
    attemptApiDelays: [300, 800, 1200, 3000, 5000],
    activity: {
        pageSize: 20,
    },
    speakingMeterRefreshRate: 300,
    sentry: {
        timeout: true,
        wsEvent: false,
        networkError: true,
        tracesSampleRate: 0.0, // sentry performances
        replaysSessionSampleRate: 0.0, // sentry replay sample
        replaysOnErrorSampleRate: 1.0, // sentry replay on Error
    },
    webgl: {
        selfieSegmentationUrl: 'https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/',
    },
};
