import styled from 'styled-components';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

export const StyleFooterButton = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    .btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        &.loading{
            width: 40px;
            height: 40px;
            &[disabled]{
                opacity: 1!important;
                &:hover{
                    background-color: ${rgba(colors.white,.1)}!important;
                }
            }
        }

    }
    >.label{
        text-align: center;
        margin: 5px 0 0 0!important;
        font-size: 10px;
        color: ${colors.white}!important;
        ${({ danger }) => danger && `
            color: #F64646!important;
        `}
    }
    &.xl {
        .btn{
            padding: 14px;
        }
        .label{
            font-size: 12px;
        }
    }
`;

export const StyleFooterAction = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 10px;
    flex-direction: row;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: all 250ms ease;
    transform: translateY(0px);
    &.hide-interface {
        transform: translateY(90px);
    }
    .footer-container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        &.centered{
            position: absolute;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .button-container{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        .arrow-folding{
            position: absolute;
            bottom: 210%;
            right: -120px;
            background-color: ${colors.black};
            color: ${colors.white};
            text-transform: uppercase;
            padding: 5px 10px;
            border-radius: 30px;
            width: 200px;
            z-index: 2;
            font-weight: 700;
            font-size: 12px;
            .icon{
                position: absolute;
                bottom: -130%;
                right: 40%;
                svg{
                    width: 60px;
                    transform: rotateY(-180deg);
                }
            }
        }    
        &:last-child{
            margin-right: 0;
        }
    }
`;
