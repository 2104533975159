import config from 'config';
import { normalize } from 'normalizr';
import { apiClient as request } from '@amplement/backend-connector';

export const apiRequest = ({ url, schemaName, ...params } = {}) =>
    request.getAxiosProvider()({ 
        url: `${config.apiServerUrl}${url}`, 
        ...params, 
    })
        .then((response) => {
            if (schemaName) {
                if (response && response.data && response.data.data) {
                    response.data = response.data.data;
                }

                let schema = require('../../schemas/index.js').default[schemaName]; // eslint-disable-line
                schema = Array.isArray(response.data) ? schema : [schema];
                response.data = normalize(response.data, [schema]);
            }

            return response;
        });
