import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Mp3 = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-mp3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-ai" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
            </g>
            <g
                id="Group"
                transform="translate(21.000000, 50.000000)"
                fill={color}
                fillRule="nonzero"
            >
                <path
                    d="M19,41 L15,41 L15,10.523 C15,8.729 16.207,7.143 17.936,6.667 L38.959,1.139 C40.151,0.813 41.423,1.057 42.424,1.819 C43.426,2.582 44,3.741 44,5.001 L44,35.375 L40,35.375 L40,5.001 L18.977,10.529 L19,41 Z"
                    id="Shape"
                />
                <g transform="translate(0.000000, 34.000000)">
                    <ellipse id="Oval" cx="9.5" cy="7" rx="7.5" ry="5" />
                    <path
                        d="M9.5,14 C4.173,14 0,10.925 0,7 C0,3.075 4.173,0 9.5,0 C14.827,0 19,3.075 19,7 C19,10.925 14.827,14 9.5,14 Z M9.5,4 C6.353,4 4,5.584 4,7 C4,8.416 6.353,10 9.5,10 C12.647,10 15,8.416 15,7 C15,5.584 12.647,4 9.5,4 Z"
                        id="Shape"
                    />
                </g>
                <g transform="translate(25.000000, 28.000000)">
                    <ellipse id="Oval" cx="9.5" cy="7.375" rx="7.5" ry="5.375" />
                    <path
                        d="M9.5,14.749 C4.173,14.749 0,11.51 0,7.375 C0,3.239 4.173,0 9.5,0 C14.827,0 19,3.239 19,7.375 C19,11.51 14.827,14.749 9.5,14.749 Z M9.5,4 C6.57,4 4,5.577 4,7.375 C4,8.971 6.259,10.749 9.5,10.749 C12.741,10.749 15,8.971 15,7.375 C15,5.577 12.43,4 9.5,4 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </g>
    </svg>
);

Mp3.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

Mp3.defaultProps = {
    color: '#00B8DF',
    withBorder: true
};

export default Mp3;
