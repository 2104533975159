import React, { memo } from 'react';
import FileIcon from 'components/Shared/Common/FileIcon';

const NoThumb = () => (
    <FileIcon fileExtension="default" isDisplayable />
);
NoThumb.propTypes = {
};

NoThumb.defaultProps = {
};

export default memo(NoThumb);
