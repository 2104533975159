// sounds
export const DIAL_PAD_SOUND_0 = 'DIAL_PAD_SOUND_0';
export const DIAL_PAD_SOUND_1 = 'DIAL_PAD_SOUND_1';
export const DIAL_PAD_SOUND_2 = 'DIAL_PAD_SOUND_2';
export const DIAL_PAD_SOUND_3 = 'DIAL_PAD_SOUND_3';
export const DIAL_PAD_SOUND_4 = 'DIAL_PAD_SOUND_4';
export const DIAL_PAD_SOUND_5 = 'DIAL_PAD_SOUND_5';
export const DIAL_PAD_SOUND_6 = 'DIAL_PAD_SOUND_6';
export const DIAL_PAD_SOUND_7 = 'DIAL_PAD_SOUND_7';
export const DIAL_PAD_SOUND_8 = 'DIAL_PAD_SOUND_8';
export const DIAL_PAD_SOUND_9 = 'DIAL_PAD_SOUND_9';
export const DIAL_PAD_SOUND_ASTERISK = 'DIAL_PAD_SOUND_ASTERISK';
export const DIAL_PAD_SOUND_POUND = 'DIAL_PAD_SOUND_POUND';
