import React from 'react';

import Avatar from 'components/Shared/User/Avatar';

const Placeholder = () => (
    <div className="contact-card-item loading">
        <div className="user-item">
            <Avatar
                size="xl"
                isLoading
                alt="loader"
            />
        </div>
    </div>
);

export default Placeholder;
