import {useEffect} from 'react';
import { useQueryClient } from "react-query"
import { resources, selectors } from '@amplement/backend-connector';
import { useSelector } from 'react-redux';
import { CONTACT_STATUS } from 'const/user';

const { websocket : { manager: webSocketManager } } = resources;

const useReactQuerySubscription = () => {
    const queryClient = useQueryClient();
    const isWsOpen = useSelector(selectors.network.getIsWsOpenSelector)

    useEffect(() => {
        if (isWsOpen) {
            const websocket = webSocketManager.getWs('api');
            
            websocket?._primus.on('data', (event) => {

                if (event?.code?.indexOf('_CONTACT') !== -1) {
                    const queryKey = ['contacts']
                    queryClient.invalidateQueries(queryKey);

                    const previousContact = queryClient.getQueryData(['user', event?.payload?.id]);
                    if (previousContact) {
                        queryClient.setQueryData(['user', event?.payload?.id], {
                            ...previousContact,
                            ...(event?.payload || {}),
                            contactStatus: event?.code === 'DELETE_CONTACT' ? CONTACT_STATUS.NOT_CONNECTED : event?.payload?.contactStatus
                        });
                    };
                }
                if (event?.code?.indexOf('_USER') !== -1) {
                    queryClient.setQueryData(['user', event.payload?.id], event.payload);
                }
                if (event?.code?.indexOf('ROOM_UPDATE') !== -1) {
                    const caches = queryClient.queryCache.findAll(['rooms', { transfer: false }]);
                    
                    caches.forEach((cache) => {
                        queryClient.setQueryData(
                            cache.queryKey, 
                            (previous = []) => {
                                const next = previous;
                                const idx = previous.findIndex(room => room?.id === event.payload?.id);

                                if (idx !== -1) {
                                    next[idx] = event.payload;
                                } else {
                                    next.push(event.payload);
                                }

                                return next;
                            }
                        );
                    });
                }
                if (event?.code?.indexOf('ROOM_DELETE') !== -1) {
                    const caches = queryClient.queryCache.findAll(['rooms']);
                    caches.forEach((cache) => {
                        queryClient.setQueryData(
                            cache.queryKey, 
                            (previous = []) => previous.filter(room => room.id !== event.payload?.id)
                        );
                    });
                }
                // Phone bookmarks handlers
                if (event?.code?.indexOf('POST_BOOKMARK') !== -1) {
                    let previousBookmarks = queryClient.getQueryData(['bookmarks', { type: 'phoneNumber' }]) || [];
                    previousBookmarks = [event.payload, ... previousBookmarks];
                    const caches = queryClient.queryCache.findAll(['bookmarks', { type: 'phoneNumber' }]);
                    caches.forEach((cache) => {
                        queryClient.invalidateQueries(cache.queryKey);
                        queryClient.setQueryData(
                            cache.queryKey, 
                            previousBookmarks
                        );
                    });
                }
                if (event?.code?.indexOf('DELETE_BOOKMARK') !== -1) {
                    let previousBookmarks = queryClient.getQueryData(['bookmarks', { type: 'phoneNumber' }]) || [];
                    previousBookmarks = previousBookmarks.filter(b => b.id !== event.payload?.id)
                    const caches = queryClient.queryCache.findAll(['bookmarks', { type: 'phoneNumber' }]);                    
                    caches.forEach((cache) => {
                        queryClient.invalidateQueries(cache.queryKey);
                        queryClient.setQueryData(
                            cache.queryKey, 
                            previousBookmarks
                        );
                    });
                }
                
            });
        }
    }, [queryClient, isWsOpen]);
}

export default useReactQuerySubscription;
