import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StyledDropdownItem } from 'components/Shared/style';
import Icon from 'components/Shared/Common/Icon';

import { Dropdown } from "antd";

const formatItem = (item) => {
    const {
        label,
        onClick,
        iconName,
        href,
        children, // eg: userstatus selector
        submenuItems, // for callforward in room left menu section
        ...rest
    } = item;
    const Component = href ? Link : 'div';
    const extraProps = href && { to: href };

    const render = (
        <StyledDropdownItem
            onClick={submenuItems ? undefined : onClick}
            className={item.className} 
            key={item.id || item.label}
        >
            {children || (
                <Component
                    className="item"
                    {...extraProps}
                >   
                    {label}
                </Component>
            )}
        </StyledDropdownItem>
    );

    return ({
        label: render,
        key: item.id || item.label,
        children: submenuItems,
        icon: iconName && <Icon size="md" iconName={iconName} />,
        onClick: submenuItems ? onClick : undefined,
        ...rest
    });
}

const CustomDropdown = ({ items, children, className, ...rest }) => {
    const menuItems = useMemo(() => ({ items: items.map(formatItem) }), [items]);
    
    return (
        <Dropdown
            menu={menuItems}
            className={className}
            placement="topLeft"
            arrow
            trigger="click"
            {...rest}
            // dropdownRender={dropdownRender}
        >
            {children}
        </Dropdown>
    );
}

CustomDropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.any,
        // Child corresponds to the submenu items: https://ant.design/components/menu#submenutype
        submenuItems: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.any,
            key: PropTypes.any,
        })),
        // JSX element
        children: PropTypes.any,
        className: PropTypes.string,
        onClick: PropTypes.func,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        href: PropTypes.string,
        iconName: PropTypes.string
    })),
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};

CustomDropdown.defaultProps = {
    items: [],
    className: undefined
};

export default CustomDropdown;
