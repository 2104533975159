import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Input, Collapse, Button, Form, Space, Row } from 'antd';

import useSipAccounts from 'hooks/useSipAccounts';
import useSipAccount from 'hooks/useSipAccount';

import ThresholdDelaySelector from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ThresholdDelaySelector';
import VoiceMailInput from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Form/VoiceMailInput';
import ContactInput from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Form/ContactInput';
import NumberInput from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Form/NumberInput';
import MobileInput from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Form/MobileInput';

const { Text } = Typography;
const { Panel } = Collapse;

const TAB_KEYS = {
    VOICEMAIL: 'voicemail',
    MOBILE: 'mobile',
    NUMBER: 'number',
    EXTENSION: 'extension'
};

export const CollapsedForm = injectIntl(({
    intl,
    value,
    mobileNumber,
    onSubmit,
    onCancel,
    isLoading
}) => {
    const form = Form.useFormInstance();

    const handleSubmit = useCallback(() => {
        onSubmit?.(form.getFieldsValue());
    }, [onSubmit]);

    return (
        <>
            <Collapse
                accordion
                destroyInactivePanel
                defaultActiveKey={TAB_KEYS[value?.type]}
            >
                {value?.trigger !== 'unreachable' && (
                    <Panel
                        header={intl.formatMessage({ id: 'call.forward.type.voicemail' })}
                        key="voicemail"
                    >
                        <Form.Item
                            name="context"
                            rules={[
                                { required: true, message: 'required' },
                            ]}
                        >
                            <VoiceMailInput />
                        </Form.Item>
                    </Panel>
                )}
                {mobileNumber && (
                    <Panel
                        header={intl.formatMessage({ id: 'call.forward.type.mobile' })}
                        key="mobile"
                    >
                        <Form.Item
                            name="context"
                            rules={[
                                { required: true, message: 'required' },
                            ]}
                        >
                            <MobileInput />
                        </Form.Item>
                        <Form.Item
                            name="to"
                            rules={[
                                { required: true, message: 'required' },
                            ]}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                    </Panel>
                )}
                {value?.trigger !== 'unreachable' && (
                    <Panel
                        header={intl.formatMessage({ id: 'call.forward.type.extension' })}
                        key="extension"
                    >
                        <Form.Item
                            name="to"
                            rules={[
                                { required: true, message: 'required' },
                            ]}
                        >
                            <ContactInput />
                        </Form.Item>
                    </Panel>
                )}
                <Panel
                    header={intl.formatMessage({ id: 'call.forward.type.number' })}
                    key="number"
                >
                    <Form.Item
                        name="to"
                        rules={[
                            { required: true, message: 'required' }
                        ]}
                    >
                        <NumberInput />
                    </Form.Item>
                </Panel>
            </Collapse>

            {value?.trigger === 'unavailable' && (
                <Form.Item
                    name="delay"
                    label={intl.formatMessage({ id: 'call.forward.delay' })}
                    rules={[
                        { required: true, message: 'required' },
                    ]}
                >
                    <ThresholdDelaySelector />
                </Form.Item>
            )}

            <Row justify="end" style={{ paddingTop: '20px' }}>
                <Space>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                    >
                        {intl.formatMessage({ id: 'global.button.cancel' })}
                    </Button>
                    {onSubmit ? (
                        <Button type="primary" onClick={handleSubmit} loading={isLoading}>
                            {intl.formatMessage({ id: 'global.button.validate'})}
                        </Button>
                    ) : (
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {intl.formatMessage({ id: 'global.button.validate'})}
                        </Button>
                    )}
                </Space>
            </Row>
        </>
    );
});

const ForwardTypeSelector = injectIntl((props) => {
    const {
        intl,
        value, 
        onSubmit,
        onCancel,
        isLoading
    } = props;

    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { data: account } = useSipAccount({ _sipAccount });
    const { phone: { mobileNumber } = {} } = account || {};

    const [error, setError] = useState();

    const handleSubmit = useCallback((values) => {
        if (!values.type || values.type === 'NONE') {
            setError('Please select forwarding type.');
        } else {
            setError('');
            onSubmit(values);
        }
    }, [onSubmit]);

    return (
        <Form
            onFinish={handleSubmit}
            initialValues={{
                ...value,
                to: value?.type !== 'NUMBER' ? value?.to : undefined,
            }}
        >
            {error && <Text type="danger">{error}</Text>}
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                <Form.Item
                    name="type"
                    hidden
                >
                    <Input />
                </Form.Item>
                <CollapsedForm
                    intl={intl}
                    value={value}
                    mobileNumber={mobileNumber}
                    onCancel={onCancel}
                    isLoading={isLoading}
                />
            </Space>
        </Form>
    );
});

ForwardTypeSelector.propTypes = {
    intl: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    value: PropTypes.object,
};

ForwardTypeSelector.defaultProps = {
    onClose: undefined,
    isLoading: false,
    value: {}
};

export default injectIntl(ForwardTypeSelector);
