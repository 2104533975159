import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { actions, selectors, constants } from '@amplement/backend-connector';
import Button from 'components/Shared/Common/Button';

const HoldButton = (props) => {
    const {
        onClick,
        isEnabled,
        ...btnProps
    } = props;

    const handleClick = useCallback(() => onClick(!isEnabled, 'HoldButton'), [isEnabled]);

    useWhyDidYouUpdate('component:HoldButton', props);

    return (
        <Button
            iconName={isEnabled ? 'Play' : 'Pause'}
            color={isEnabled ? 'lighten' : 'light-transparency'}
            onClick={handleClick}
            {...btnProps}
        />
    );
}

HoldButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

HoldButton.defaultProps = {
};

const Component = memo(HoldButton);

export default Component;

const mapStateToProps = (state, { _room }) => ({
    isEnabled: selectors.rooms.getMyClientAsARoomMemberSelector(state, _room)?.status === constants.rooms.USER_IN_ROOM_STATUS.HOLD,
});

const mapDispatchToProps = (dispatch, { _room }) => ({
    onClick: (hold, triggeredBy) => dispatch(actions.rooms.requestHoldRoom(_room, hold, triggeredBy))
});

export const container = connect(mapStateToProps, mapDispatchToProps)(Component);
