import React from 'react';

import Toaster from 'components/Archives/ToastStack/Toaster';
import ToastStack from 'components/Archives/ToastStack';

const toastStackRef = ToastStack.initToasterStack();
const toasterPosition = {
    position: 'fixed',
    top: 0,
    left: '50%',
    zIndex: 999,
    transform: 'translateX(-50%)'
};


const Toast = () => (
    <Toaster
        style={toasterPosition}
        ref={toastStackRef}
    />
);

export default Toast;
