import React, { memo } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import PropTypes from 'prop-types';

import { NAVIGATION } from 'components/CallBuilder/constants';

const { Title } = Typography;

const StyledTitle = styled(Title)`
    font-size: 11px!important;
    font-weight: normal!important;
    text-transform: uppercase;
    margin: 20px 0 0;
`;

type TitleProps = {
    title: string;
} & WrappedComponentProps;

const getTitle = (title: string, intl): null | string => {
    switch (title) {
        case NAVIGATION.BOOKMARKS:
            return intl.formatMessage({ id: 'call.tabs.bookmarks' });
        case NAVIGATION.FEEDS:
            return intl.formatMessage({ id: 'menuLeft.feeds.title' });
        case NAVIGATION.ROOMS:
            return intl.formatMessage({ id: 'menuLeft.rooms.title' });
        case NAVIGATION.USERS:
            return intl.formatMessage({ id: 'feed.header.button.drawer.users' });
        case NAVIGATION.PHONEBOOK:
            return intl.formatMessage({ id: 'contacts.tab.businessContacts' });

        default:
            return null;
    }
}

const TitleResults = ({ title, intl }: TitleProps) => (
    <StyledTitle type="secondary" level={5}>
        {getTitle(title, intl)}
    </StyledTitle>
) as JSX.Element;

TitleResults.propTypes = {
    title: PropTypes.string.isRequired,
    intl: PropTypes.any.isRequired,
};

export default memo(injectIntl(TitleResults));
