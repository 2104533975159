import { connect } from 'react-redux';

import { getCurrentUserIdSelector } from 'selectors/user';
import { getCompanyIdSelector } from 'selectors/company';
import Dashboard from './index';

const mapStateToProps = state => ({
    _me: getCurrentUserIdSelector(state),
    _company: getCompanyIdSelector(state)
});

export default connect(
    mapStateToProps,
    null
)(Dashboard);
