import { connect } from 'react-redux';

import { getErrorSelector } from 'selectors/session';
import PageLayout from './index';

const mapStateToProps = state => ({
    hasError: !!getErrorSelector(state)
});

export default connect(
    mapStateToProps,
    null
)(PageLayout);
