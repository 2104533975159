import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Shared/Common/Icon';
import styled from 'styled-components';
import { rgba } from 'polished';
import AvatarList from '../Call/AvatarList';

const Style = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    transition: all 250ms ease;
    width: 100%;
    .icon{
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: ${({ theme }) => theme.black};
        svg{
            width: 18px;
            height: 18px;
        }
        border-radius: 4px;
        background-color: ${({ theme }) => rgba(theme.black, 0.20)};
    }
    .label{
        font-size: 14px;
        color: ${({ theme }) => theme.black};
        font-weight: 500;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`;

const RoomItem = ({ name, members, hasIcon }) => (
    <Style>
        {hasIcon && <Icon iconName="Video" />}
        <div className="label">
            {name}
        </div>
        {members && (
            <AvatarList members={members} maxCount={8} hasMore size="xs" />
        )}
    </Style>
);

RoomItem.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({
        avatarUri: PropTypes.string,
        fullname: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    hasIcon: PropTypes.bool,
};

RoomItem.defaultProps = {
    members: undefined,
    hasIcon: false
};

export default memo(RoomItem);
