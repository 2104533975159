import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { container as FeedItemFile } from 'components/Shared/Feed/FeedItemFile';
import { container as FeedItemLink } from 'components/Shared/Feed/FeedItemLink';
import FeedItemQuote from 'components/Shared/Feed/FeedItemQuote';
import JoinTemplate from 'components/Shared/Feed/ItemTemplate/JoinTemplate';
import LeftTemplate from 'components/Shared/Feed/ItemTemplate/LeftTemplate';
import RecordingTemplate from 'components/Shared/Feed/ItemTemplate/RecordingTemplate';
import FeedNameChanged from 'components/Shared/Feed/ItemTemplate/FeedNameChanged';
import FeedItemBot from 'components/Shared/Feed/FeedItemBot';
import FeedItemContentLayout from 'components/Shared/Feed/FeedItemContentLayout';

import { FULL_DATE_FORMAT_CONFIG } from 'utils/date';

const formattedMessage = (intl, { updatedAt = null }) => ({
    formattedDate: intl.formatDate(updatedAt, FULL_DATE_FORMAT_CONFIG)
});

const FeedItemMessageContent = ({
    item: {
        id: _feedItem,
        specialType,
        updatedAt,
        content,
        metasLink: link,
        metasFile: file,
        metasDiff: diff,
        metasQuote: quote,
        metasBot: bot,
    },
    intl,
}) => {
    const contentChild = useMemo(() => {
        if (file) {
            const { formattedDate } = formattedMessage(intl, { updatedAt });
            const meta = [formattedDate, file.extension].filter(x => !!x);

            return (
                <FeedItemFile
                    _feedItem={_feedItem}
                    file={file}
                    subtitle={meta.join(' - ')}
                />
            );
        }

        if (bot) {
            return <FeedItemBot {...bot} />;
        }

        if (quote) {
            return <FeedItemQuote quote={quote} />;
        }

        if (link) {
            return <FeedItemLink url={link.url} link={link} />;
        }

        if (specialType === 'join') {
            return <JoinTemplate />;
        }

        if (specialType === 'left') {
            return <LeftTemplate />;
        }

        if (
            specialType === 'callStartRecording' ||
            specialType === 'callEndRecording' ||
            specialType === 'callPauseRecording'
        ) {
            return <RecordingTemplate specialType={specialType} />;
        }

        if (specialType === 'feedNameChanged') {
            return <FeedNameChanged value={diff?.diff?.old} newValue={diff?.diff?.new} />;
        }

        return null;
    }, [file, link, specialType, diff, updatedAt, _feedItem, intl]);

    if (!content && !contentChild) return null;

    return (
        <FeedItemContentLayout
            isChildrenBeforeContent={!!quote}
            // Workaround until the backend no longer updates the content property with gpt results.
            content={bot ? content.split("-- --\nchat GPT")[0] : content}
        >
            {contentChild}
        </FeedItemContentLayout>
    );
}

FeedItemMessageContent.propTypes = {
    intl: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
};

export default injectIntl(memo(FeedItemMessageContent));
