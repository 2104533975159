import { useEffect, useState } from 'react';
import Logger from 'services/debug/logger';
import { useSelector } from 'react-redux'
import { getAvailableDeviceIdByKind, getAvailableDevicesByKind } from 'selectors/roomSettings';

const logger = new Logger('component:room:useMediaDeviceFinder');

const useMediaDeviceFinder = (kind) => {
    const [deviceId, setDeviceId] = useState('default');
    const devices = useSelector(state => getAvailableDevicesByKind(state, kind));
    const requestedDeviceId = useSelector(state => getAvailableDeviceIdByKind(state, kind));

    const checkDevice = (list, id) => {
        let finalDeviceId = list ? list.find(item => item.deviceId === id)?.deviceId
            || list.find(item => item.deviceId?.indexOf('default') !== -1 || item.label?.indexOf('default') !== -1)?.deviceId
            || list?.[0]?.deviceId : undefined;
        
        if (!finalDeviceId && !!list?.length) {
            finalDeviceId = list[0]?.deviceId;
        }

        return finalDeviceId;
    };

    useEffect(() => {
        const nextDeviceId = checkDevice(devices, requestedDeviceId);
        if (deviceId !== nextDeviceId) {
            setDeviceId(nextDeviceId);
        }
    }, [devices, requestedDeviceId, deviceId]);

    logger.log('useMediaDeviceFinder', kind, requestedDeviceId, deviceId, devices);

    return deviceId;
};

export default useMediaDeviceFinder;
