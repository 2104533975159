import { connect } from 'react-redux';

import { getQueryStringParameter } from 'utils/url';

import GuestLoginPage from './index';

const mapStateToProps = (_, { location }) => ({
    returnUrl: getQueryStringParameter('returnUrl')(location.search)
});

export default connect(mapStateToProps, null)(GuestLoginPage);
