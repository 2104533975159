import * as types from 'types/session';
import {
    $requestPatchUser
} from 'api/user';
import { user as userSchema } from 'schemas/user';

export const requestPatchUser = (_user, data) => ({
    types: [
        types.PATCH_USER_REQUEST,
        types.PATCH_USER_SUCCESS,
        types.PATCH_USER_FAILURE
    ],
    callAPI: () => $requestPatchUser(_user, data),
    forceCallApi: false,
    schemas: userSchema,
    shouldCallAPI: state => state.ok || true,
    payload: {
        _user,
        data
    }
});

export const requestPostAvatar = avatar => ({
    type: types.POST_AVATAR,
    avatar
});

export const updateUser = (key, value) => ({
    type: types.UPDATE_USER,
    key,
    value
});

export const setLang = lang => ({
    type: types.SET_LANG,
    lang,
});

export const setUser = user => ({
    type: types.SET_USER,
    user
});

export const updateAndPatchUser = (key, value) => ({
    type: types.UPDATE_AND_PATCH_USER,
    key,
    value
});

export const setClient = _client => ({
    type: types.SET_CLIENT,
    _client
});

export const guestLoginSuccess = (data) => ({
    type: types.REQUEST_GUEST_LOGIN,
    data
});

export const requestPatchUserEmail = (email, password) => ({
    type: types.REQUEST_PATCH_USER_EMAIL,
    email,
    password
});

export const requestPatchUserPassword = (currentPassword, newPassword) => ({
    type: types.REQUEST_PATCH_USER_PASSWORD,
    currentPassword,
    newPassword
});

export const copyTextToClipboard = text => ({
    type: types.COPY_TEXT,
    text
});

export const setTabFocus = isFocused => ({
    type: types.SET_TAB_FOCUS,
    isFocused
});

export const setFatalError = (id, meta) => ({
    type: types.SET_FATAL_ERROR,
    error: { id, ...meta }
});

export const setContentError = (id, meta) => ({
    type: types.SET_CONTENT_ERROR,
    error: { id, ...meta }
});

export const removeRequestedAction = (actionName) => ({
    type: types.REMOVE_REQUESTED_ACTION,
    payload: actionName
});
