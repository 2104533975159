import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Shared/Common/Button';
import { Typography } from 'antd';
import { injectIntl } from 'react-intl';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import { getUrl } from 'utils/url';
import JoiError from './JoiError';
import GenericError from './GenericError';

const { Text } = Typography;

class FullscreenMessageFormatter extends Component {
    handleClose = () => {
        this.handleDefault();
        window.close();
    }

    handleRefresh = () => {
        this.handleDefault();
        window.location.reload();
    }

    handleDefault = () => {
        const { onClick } = this.props;
        onClick && onClick(); // eslint-disable-line
    }

    render() {
        const {
            intl,
            buttonType,
            render: ErrorComponent,
            error,
            ...rest
        } = this.props;
        let actions = null;

        switch (buttonType) {
            case BUTTON_TYPE.CLOSE: {
                const isClosableWindow = window.opener != null || window.history.length === 1;
                if (isClosableWindow) {
                    actions = (
                        <>
                            <Button
                                onClick={this.handleClose}
                                color="light-transparency"
                                size="xl"
                                iconName="X"
                            />
                            <Text className="text">{intl.formatMessage({ id: 'room.error.button' })}</Text>
                        </>
                    );
                }
                break;
            }
            case BUTTON_TYPE.RELOAD: {
                actions = (
                    <Button
                        onClick={this.handleRefresh}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.reload' })}
                    </Button>
                );
                break;
            }
            case BUTTON_TYPE.GOHOME: {
                actions = (
                    <Button
                        href={getUrl('dashboard')}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.navigateToDashboard' })}
                    </Button>
                );
                break;
            }
            case BUTTON_TYPE.CONNECT: {
                actions = (
                    <Button
                        href={getUrl('logout')}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.reconnect' })}
                    </Button>
                );
                break;
            }
            case BUTTON_TYPE.RETRY: {
                actions = (
                    <Button
                        onClick={this.handleDefault}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.retry' })}
                    </Button>
                );
                break;
            }
            case BUTTON_TYPE.CUSTOM: {
                actions = (
                    <Button
                        onClick={this.handleDefault}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.reload' })}
                    </Button>
                );
                break;
            }

            default:
        }

        if (error) {
            // joi error directly
            if (error?.joi) {
                return (
                    <JoiError
                        error={error}
                        ErrorComponent={ErrorComponent}
                        actions={actions}
                    />
                )
            } 
            // axios error with joi payload
            if (error?.response?.data?.joi) {
                return (
                    <JoiError
                        error={error?.response?.data}
                        ErrorComponent={ErrorComponent}
                        actions={actions}
                    />
                )
            } 
            // normal error
            return (
                <GenericError
                    error={error}
                    ErrorComponent={ErrorComponent}
                    actions={actions}
                />
            );
        }

        const { content = intl.formatMessage({ id: 'call.info.ended' }) } = this.props;

        return <ErrorComponent {...rest} content={content} actions={actions} />;
    }
}

FullscreenMessageFormatter.propTypes = {
    onClick: PropTypes.func,
    content: PropTypes.string,
    code: PropTypes.oneOf(['', 404, 422, 504, 500, 401, 403, 503]),
    render: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPE)),
};

FullscreenMessageFormatter.defaultProps = {
    content: '',
    code: undefined,
    onClick: undefined,
    buttonType: undefined
};

export default injectIntl(FullscreenMessageFormatter);
