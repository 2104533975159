import { useEffect, memo } from 'react';
import { getToken } from 'services/token';
import config from 'config';
import { selectors, utils } from '@amplement/backend-connector';
import { getCurrentUserIdSelector, getCurrentClientIdSelector } from 'selectors/user';
import { useSelector } from 'react-redux';

const TabEventListener = () => {
    const _user = useSelector(getCurrentUserIdSelector);
    const _room = useSelector(selectors.rooms._currentRoomSelector);
    const _client = useSelector(getCurrentClientIdSelector);
    const dirty = _room && _user && _client;

    const requestRoomLeave = () => // same as $postRoomLeave
        fetch(`${config.apiServerUrl}/rooms/${_room}/leave/${_user}`, {
            keepalive: true,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: getToken()
            },
            body: JSON.stringify({ _client }),
        }).catch(error => utils.errorHandler.captureException(error, 'TabEventListener:requestRoomLeave'));

    useEffect(() => {
        const handleUnloadEvent = (e) => {
            const confirmationMessage = "Are you sur ?";

            // HACK: if we click on download from feeditem room chat, don't show security popin
            const hrefTrigger = e?.srcElement?.activeElement?.href;
            if (hrefTrigger && hrefTrigger.indexOf('download') !== -1 && hrefTrigger.indexOf('feed') !== -1) {
                return null;
            }
            
            (e || window.event).returnValue = confirmationMessage; // Gecko + IE
            return confirmationMessage;                            // Webkit, Safari, Chrome
        };

        if (dirty) {
            window.addEventListener("beforeunload", handleUnloadEvent);
            window.addEventListener('unload', requestRoomLeave, false);

            return () => {
                window.removeEventListener("beforeunload", handleUnloadEvent);
                window.removeEventListener('unload', requestRoomLeave, false);
            }
        }

        return undefined;
    }, [dirty]);

    return null;
}

export default memo(TabEventListener);
