import { useEffect, useRef } from 'react';
import Logger from 'services/debug/logger';
import { isLiveDebug } from 'services/debug/helper';

const logger = new Logger('hooks:useWhyDidYouUpdate'); // eslint-disable-line

export default (name, props) => {
    const previousProps = useRef();
    
    useEffect(() => {
        if (isLiveDebug()) {
            if (previousProps.current) {
                const allKeys = Object.keys({ ...previousProps.current, ...props });
                const changesObj = {};

                allKeys.forEach(key => {
                    if (previousProps.current[key] !== props[key]) {
                        changesObj[key] = {
                            from: previousProps.current[key],
                            to: props[key]
                        };
                    }
                });

                if (Object.keys(changesObj).length) {
                    logger.setName(name);
                    logger.log('[why-did-you-update]', changesObj);
                }
            }

            previousProps.current = props;
        }
    });

    useEffect(() => {
        if (isLiveDebug()) {
            logger.setName(name);
            logger.log('[why-did-you-update]:mount', props);
            return () => {
                logger.setName(name);
                logger.log('[why-did-you-update]:unmount', props);
            }
        }
        return undefined;
    }, [])
}
