import React, { useState } from "react";
import styled from "styled-components";
import { Image } from "antd";

import PreviewManager from "components/Shared/Previews/PreviewManager";

import { MetasFile } from "hooks/useSearch";

const AntdImgPreview = styled(Image)`
    object-fit: cover;

    &.s-file-preview{
        width: 59px;
        height: 73px !important;
        margin: 0 auto;
    }
`;

const Img = styled.img`
    &.s-file-preview{
        width: 59px;
        height: 73px;
        margin: 0 auto;
    }
`;

const PreviewButton = ({ extension, id, metasFile, updatedAt, src }) => {
    const [metas, setMetasFile] = useState<{ metasFile?: MetasFile, id?: string, createdAt?: string }>({});
    const setPreview = () => setMetasFile({ metasFile, id, createdAt: updatedAt });
    const closePreview = () => setMetasFile({});

    switch (extension) {
        case '.mp4':
        case '.mov':
        case '.avi':
        case '.webm':
        case '.ogg':
        case '.pdf':
            return (
                <AntdImgPreview
                    className="s-file-preview"
                    src={src}
                    preview={{
                        visible: !!metas?.metasFile,
                        imageRender: () => (
                            <PreviewManager
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                onClose={closePreview}
                                feedItem={metas}
                                key="preview-manager"
                            />
                        ),
                        toolbarRender: () => null,
                        closeIcon: null,
                    }}
                    onClick={setPreview}
                />
            );
        default:
            return <Img className="s-file-preview" src={src} />;
    }
};

export default PreviewButton;
