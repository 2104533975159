import * as types from 'types/roomSettings';
import { getDevices, getSkipCall, setDevices, setSkipCall } from 'services/userSettings';

const initialState = {
    devices: undefined,
    enumerateDeviceError: undefined,
    permissionState: undefined,
    settings: {
        ...(getDevices() || {}),
        skipNextTime: getSkipCall()
    }
};

const RoomSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_AVAILABLE_DEVICES: {
            if (!action.payload) {
                return state;
            }

            return { 
                ...state,
                devices: action.payload
            };
        }
        case types.SET_DEVICE_SETTINGS: {
            const settings = {
                ...state.settings, 
                ...action.payload,
            }
            const { skipNextTime, ...devices } = settings || {};

            if (devices && Object.keys(devices)?.length) {
                setDevices(devices);
            }
            if (skipNextTime !== undefined) {
                setSkipCall(skipNextTime);
            }

            return { 
                ...state,
                settings
            };
        }
        case types.SET_ENUMERATEDEVICES_ERROR: {
            return { 
                ...state,
                enumerateDeviceError: action.payload, 
            };
        }
        case types.SET_MEDIADEVICE_PERMISSION_STATE: {
            return { 
                ...state,
                permissionState: action.payload, 
            };
        }

        default:
            return state;
    }
};

export default RoomSettingsReducer;
