import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import { injectIntl } from 'react-intl';

import Style from 'components/Shared/Blocks/style';

const Block = ({ intl, title, description, hasIgnore, onIgnore, onClick, hasCTA, CTALabel, bgColor, isVisible, hasRadius, textColor }) => {
    const [isIgnored, setIsIgnored] = useState(false);

    const handleIgnore = useCallback(() => {
        setIsIgnored(true);
        if (typeof onIgnore === 'function') onIgnore();
    }, [setIsIgnored, onIgnore]);

    const handleClick = useCallback(() => {
        setIsIgnored(true);
        if (typeof onClick === 'function') onClick();
    }, [onClick]);

    if (isVisible === false || (isVisible === undefined && isIgnored)) {
        return false;
    }

    return (
        <Style bgColor={bgColor} hasRadius={hasRadius} textColor={textColor}>
            <div className="container">
                <div className="title">{title}</div>
                {description && (
                    <div className="desc">{description}</div>
                )}
                <div className="action">
                    {hasCTA && (
                        <Button
                            color="primary"
                            onClick={handleClick}
                        >
                            {CTALabel}
                        </Button>
                    )}
                    {hasIgnore && (
                        <Button
                            color="lighten"
                            isTransparent
                            onClick={handleIgnore}
                        >
                            {intl.formatMessage({ id: 'premium.cta.ignore' })}
                        </Button>
                    )}
                </div>
            </div>
        </Style>
    );
};

Block.propTypes = {
    intl: PropTypes.any.isRequired,
    hasIgnore: PropTypes.bool,
    hasCTA: PropTypes.bool,
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    onIgnore: PropTypes.func,
    onClick: PropTypes.func,
    CTALabel: PropTypes.string,
    bgColor: PropTypes.string,
    isVisible: PropTypes.bool,
    hasRadius: PropTypes.bool,
    textColor: PropTypes.string
};

Block.defaultProps = {
    hasIgnore: true,
    hasCTA: false,
    onIgnore: undefined,
    onClick: undefined,
    CTALabel: undefined,
    bgColor: '#ffc540',
    textColor: '#552100',
    isVisible: undefined,
    hasRadius: true
};

export default memo(injectIntl(Block));
