import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Space } from 'antd';
import { injectIntl } from 'react-intl';

import useCallForwards from 'hooks/useCallForwards';

import Spinner2 from 'components/Shared/Common/Spinner2';
import ForwardItem from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ItemSummaryLayout';

const { Text } = Typography;

const ForwardList = ({ intl, _sipAccount }) => {
    const { isLoading, error, isError, data } = useCallForwards({ _sipAccount });

    if (isLoading) {
        return <Spinner2 />;
    }

    if (isError) {
        return <Text type="danger">{error?.message|| intl.formatMessage({ id: 'error.default' })}</Text>;
    }

    return (
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            {data?.map((forwardItem) => (
                <ForwardItem
                    key={forwardItem.trigger}
                    data={forwardItem}
                />
            ))}
        </Space>
    );
}

ForwardList.propTypes = {
    intl: PropTypes.any.isRequired,
    _sipAccount: PropTypes.string,
};

ForwardList.defaultProps = {
    _sipAccount: '',
};

export default memo(injectIntl(ForwardList));
