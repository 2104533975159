import { connect } from 'react-redux';

import { selectors, constants } from '@amplement/backend-connector';
import RoomHeader from './index';

export const getNetworkError = (isOnline, wsStatus) => {
    if (!isOnline) {
        return 'global.websocket.status.ended';
    }

    switch(wsStatus) {
        case constants.network.READY_STATE.CLOSED:
            return 'global.network.status.offline';
        case constants.network.READY_STATE.OPENING:
            return 'global.websocket.status.reconnecting';
        default:
            return undefined;
    }
}

const mapStateToProps = (state, ownProps) => {
    const memberCount = selectors.rooms.getRoomMemberCountByRoomIdSelector(state, ownProps?._room);
    const room = selectors.rooms.getRoomByIdSelector(state, ownProps?._room) || {};
    const _client = selectors.session._currentClientSelector(state);
    const { joinedAt } = selectors.rooms.getRoomMemberByClientIdSelector(state, ownProps?._room, _client) || {};
    const { id: _room, name: roomName, color: roomColor, isPrivate, maxMembers, focusOn } = room || {};
    const hasFocus = focusOn?._client === _client;

    return {
        _room,
        roomName,
        roomColor,
        isPrivate,
        memberCount,
        maxMembers,
        joinedAt,
        _client,
        hasFocus
    };
};

export default connect(
    mapStateToProps,
    null
)(RoomHeader);
