import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from 'common/themes/Colors';

const getColumnCount = participant => {
    if(participant <= 1) return 1;
    if(participant >= 2 && participant <= 4) return 2;
    if(participant > 4 && participant <= 7) return 3;
    if(participant > 7 && participant <= 13) return 4;
    if(participant > 13) return 6;
    return 1;
};

export default styled.div`
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #414141;
    display: flex;
    min-height: 0;
    min-width: 0;
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition: all 250ms ease;
    &.drawer-right{
        width: calc(100% - 400px);
    }
    &.gallery{
        transition: all 250ms ease;
        .list-participants{
            transition: all 250ms ease;
            top: 0;
            left: 0;
            padding: 100px 20px;
            right: auto;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            >div{
                padding: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-columns: repeat(${({ participantsCount }) => getColumnCount(participantsCount)}, 1fr);
                grid-auto-rows: 1fr;
                column-gap: 10px;
                row-gap: 10px;
                transition: all 250ms ease;
                >div{
                    transition: all 250ms ease;
                    width: 100%;
                    height: 100%;
                    border: none!important;
                    .overlay{
                        background-color: transparent!important;
                    }
                    video{
                        object-fit: contain;
                    }
                }
            }
        }
    }
    .fold-list{
        position: absolute;
        top: 100%;
        right: 0px;
        cursor: pointer;
        transition: all 250ms ease;
        transform: translateX(0px);
        .btn{
            &:hover{
                background-color: ${({ theme }) => rgba(theme.black, 0.4)}!important;
            }
            .icon{
                transition: all 250ms ease;
                svg{
                    width: 18px;
                    height: 18px;
                    color: ${({ theme }) => theme.black};
                }
            }
        }
        &.active{
            .btn{
                .icon{
                    transform: rotate(-180deg);
                }
            }
        }
        &.hide-interface {
            transform: translateX(400px);
        }
        .tooltip{
            position: absolute;
            bottom: 210%;
            right: 40px;
            background-color: ${colors.black};
            color: ${colors.white};
            text-transform: uppercase;
            padding: 5px 10px;
            border-radius: 30px;
            white-space: nowrap;
            font-weight: 700;
            font-size: 12px;
            .icon{
                position: absolute;
                bottom: -62px;
                right: 0;
                z-index: -1;
                svg{
                    width: 60px;
                    height: auto;
                    transform: rotateY(0deg);
                }
            }
        }
    }
    .list-participants{
        position: absolute;
        right: 20px;
        top: 140px;
        z-index: 1;
    }
    >.overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 120px;
        width: 100%;
        z-index: 1;
        background: linear-gradient(rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
        transition: all 250ms ease;
        &.bottom{
            top: auto;
            bottom: 0;
            background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
        }
    }
`;

export const ContentMaskLayout = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.9)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

export const ContentLayout = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    flex-direction: column;
`;

export const VideoErrorLayout = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    &.error{
        background-color: ${({ theme }) => theme.error};
    }
    svg{
        width: 32px;
        height: 32px;
        color: ${({ theme }) => theme.fixedWhite};
    }
`;
