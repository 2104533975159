export const REQUEST_ADD_RELATIONS = 'REQUEST_ADD_RELATIONS';

export const WS_SET_PENDING_RELATION = 'WS_SET_PENDING_RELATION';
export const WS_UPDATE_PENDING_TO_ACCEPTED_RELATION = 'WS_UPDATE_PENDING_TO_ACCEPTED_RELATION';
export const WS_PUT_PRESENCE = 'WS_PUT_PRESENCE';
export const WS_PUT_STATUS = 'WS_PUT_STATUS';

export const DECREASE_USER_COUNT = 'DECREASE_USER_COUNT';
export const SEARCH_USERS_CLEAR = 'SEARCH_USERS_CLEAR';
export const SAVE_USER_IDS = 'SAVE_USER_IDS';

export const WS_PUT_CONTACT = 'WS_PUT_CONTACT';
export const WS_POST_CONTACT = 'WS_POST_CONTACT';
export const WS_DELETE_CONTACT = 'WS_DELETE_CONTACT';

// CRUD
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';

export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST';
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS';
export const POST_CONTACT_FAILURE = 'POST_CONTACT_FAILURE';

export const GET_CONTACT_REQUEST = 'GET_CONTACT_REQUEST';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAILURE = 'GET_CONTACT_FAILURE';

export const PATCH_CONTACT_STATUS_REQUEST = 'PATCH_CONTACT_STATUS_REQUEST';
export const PATCH_CONTACT_STATUS_SUCCESS = 'PATCH_CONTACT_STATUS_SUCCESS';
export const PATCH_CONTACT_STATUS_FAILURE = 'PATCH_CONTACT_STATUS_FAILURE';

export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
