import { useQuery } from "react-query"
import { $getContact, $requestGetUser } from "api/user";
import { useSelector } from "react-redux";
import { getCurrentUserIdSelector } from "selectors/user";
import { CONTACT_STATUS } from "const/user";

const useUser = ({ _user, enabled = true }) => {
    const _currentUser = useSelector(getCurrentUserIdSelector);

    const result = useQuery(
        ['user', _user],
        async () => {
            const profile = await $requestGetUser(_user);
            let contact = { data: { contactStatus: CONTACT_STATUS.NOT_CONNECTED }};
            
            if (_currentUser !== _user) {
                try {
                    contact = await $getContact(_user);
                } catch(e) {
                    // nothing to do
                }
            }
                        
            return { ...(profile?.data?.data || {}), ...(contact?.data || {}) };
        },
        { refetchOnWindowFocus: false, refetchOnMount: false, enabled }
    )

    return result;
}

export default useUser;
