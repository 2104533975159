/* eslint-disable react/no-unused-prop-types */
import React, { memo } from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BookmarkList from 'components/CallBuilder/DefaultResults/BookmarkList';
import FeedList from 'components/CallBuilder/DefaultResults/FeedList';
import RoomsList from 'components/CallBuilder/DefaultResults/RoomList';
import UserList from 'components/CallBuilder/DefaultResults/UserList';
import PhoneBookList from 'components/CallBuilder/DefaultResults/PhoneBookList';
import TitleResults from 'components/CallBuilder/Title';

import { ListProps, MaxSipReachedProps } from 'components/CallBuilder/types';

import { NAVIGATION } from 'components/CallBuilder/constants';

const max = 3;
const StyledWrapper = styled.div`
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    min-height: 40px;
    overflow: auto;
    overflow: overlay;
`;

const SEARCH_RESULTS_COMPONENTS = {
    [NAVIGATION.BOOKMARKS]: BookmarkList,
    [NAVIGATION.FEEDS]: FeedList,
    // Dont use the room list until the backend has fixed the ringtone problem
    [NAVIGATION.ROOMS]: RoomsList,
    [NAVIGATION.USERS]: UserList,
    [NAVIGATION.PHONEBOOK]: PhoneBookList,
};

const defaultSearchResultsKeys = [NAVIGATION.BOOKMARKS, NAVIGATION.FEEDS, NAVIGATION.USERS, NAVIGATION.PHONEBOOK];

const SearchResults = (props: ListProps & MaxSipReachedProps): JSX.Element | null => {
    const {
        isLoading,
        searchResults,
        searchResultsKeys = defaultSearchResultsKeys,
    } = props;

    return (
        <Wrapper>
            {isLoading && <LoadingOutlined />}
            {searchResultsKeys.map((key): JSX.Element | null => {
                const SearchResultComponent = SEARCH_RESULTS_COMPONENTS[key];

                if (searchResults?.[key]?.results?.length) {
                    return (
                        <StyledWrapper key={key}>
                            <TitleResults title={key} />
                            <SearchResultComponent max={max} {...props} isLoading={false} />
                        </StyledWrapper>
                    );
                }

                return null;
            })}
        </Wrapper>
    );
};

SearchResults.propTypes = {
    isLoading: PropTypes.bool,
    searchResultsKeys: PropTypes.array,
    searchResults: PropTypes.object.isRequired,
};

SearchResults.defaultProps = {
    isLoading: false,
    searchResultsKeys: defaultSearchResultsKeys,
};

export default memo(SearchResults as React.FC<ListProps & MaxSipReachedProps>);
