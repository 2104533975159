import { connect } from 'react-redux';

import { getTabFocusSelector } from 'selectors/session';
import  { selectors, actions } from '@amplement/backend-connector';

import Acknowledgement from './index';

const mapStateToProps = (state, { _feed }) => {
    const feed = selectors.feeds.feedByIdSelector(state, _feed);
    const { lastAckAt, unreadCount } = feed || {};

    return ({
        hasTabFocus: getTabFocusSelector(state),
        lastAckAt,
        unreadCount
    });
};

const mapDispatchToProps = (dispatch) => ({
    requestAcknowledgement: (_feed) => dispatch(actions.feeds.requestPostAcknowledgement(_feed, true))
});

export default connect(mapStateToProps, mapDispatchToProps)(Acknowledgement);
