import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from "antd";

const { Text } = Typography;

const ApiResponseError = ({ value }) => {
    if  (value?.data?.code === "VALIDATION_ERROR"){
        return (
            <Text type="danger">
                <ul>
                    {value?.data?.joi?.[0]?.details?.map(a => (<li key={a?.message}>{a?.message}</li>))}
                </ul>
            </Text>
        );
    }
    return null;
}

ApiResponseError.propTypes = {
    value: PropTypes.object,
};

ApiResponseError.defaultProps = {
    value: undefined
};

export default memo(ApiResponseError);
