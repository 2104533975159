import { createSelector } from 'reselect';

// basic selectors
export const getCompanySelector = state => state.company;
export const getCompanyLanguageSelector = state => state.company.language;
export const getCompanyIdSelector = state => state.company.id;
export const getCompanyAvailableLanguagesSelector = state => state.company.availablelanguages;
export const getCompanySignUpStatus = state => state.company?.isSignupAllowed;
export const getCompanyHasEmailEditable = state => state.company?.features?.isEmailEditable;
export const getCompanyHasPasswordEditable = state => state.company.features?.isPasswordEditable;
export const getIsPremiumCompanySelector = state => state.company?.isPremium;
export const getCompanyPluginsSelector = state => state.company.plugins;
export const getCompanyNameSelector = state => state.company.name;
export const _tabNameParameter = (state, name) => name;
export const getCompanyIsPublic = state => state.company?.isPublic;
export const getParentCompanyId = state => state.company?._parent;

// memoized selectors
export const getCompanyPluginsLoadingSelector = state =>
    state.company?.plugins === undefined;

// memoized selectors
export const getCompanyTabsSelector = createSelector(
    getCompanyPluginsSelector,
    ({ tabs } = {}) => tabs
);

export const getHasPhoneTabsSelector = createSelector(
    getCompanyPluginsSelector,
    ({ tabs } = {}) => tabs?.some(tab => tab?.name?.toLowerCase() === 'phone')
);

export const getCompanyTabByNameSelector = createSelector(
    [getCompanyPluginsSelector, _tabNameParameter],
    ({ tabs } = {}, name = '') => tabs?.find(t => t.name?.toLowerCase() === name?.toLowerCase())
);
