import { connect } from 'react-redux';

import { getAvailableDevices, getRoomSettings } from 'selectors/roomSettings';
import  { actions } from '@amplement/backend-connector';
import DeviceListener from './index';

const mapStateToProps = (state) => ({
    devices: getAvailableDevices(state),
    settings: getRoomSettings(state)
});

const mapDispatchToProps = (dispatch, { _room }) => ({
    toggleAudioVideo: (audio, video, triggeredBy) => dispatch(actions.rooms.requestToggleAudioVideo(_room, audio, video, undefined, triggeredBy))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListener);

