import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from 'components/Shared/Common/Button';
import Logger from 'services/debug/logger';
import useMediaDevicesPermissions from 'components/Shared/Call/useMediaDevicesPermissions';
import { useSelector } from 'react-redux';
import { getAvailableDevicesByKind } from 'selectors/roomSettings';

const logger = new Logger('component:room:DeviceControlButton');

const getMessage = (rules = []) => rules.map(([condition, value]) => condition ? value : null).find(m => !!m);

const DeviceControlButton = ({
    isDisabled,
    isChecked,
    onToggle,
    intl,
    type,
    mediaType,
    deviceType,
    btnOffLabel,
    btnOnLabel,
    formatter,
    errorId,
}) => {
    const [disableMultipleClicks, setDisableMultipleClicks] = useState(false);
    const devices = useSelector(state => getAvailableDevicesByKind(state, mediaType));
    const permissions = useMediaDevicesPermissions(deviceType);
    const hasDevice = devices && devices.length > 0;

    const i18n = formatter(intl)({ isOn: isChecked });

    const handleClick = useCallback(() => {
        if (isDisabled) {
            logger.warn('handleToggle:skip', type, !isChecked, isDisabled, hasDevice, permissions);
            return;
        }

        // Avoid multiple consecutive clicks
        setDisableMultipleClicks(true);
        setTimeout(() => setDisableMultipleClicks(false), 1000);

        if (isChecked) {
            logger.log('handleToggle', type, false);
            onToggle(false);
        } else if (hasDevice && permissions !== 'deny') {
            logger.log('handleToggle', type, true);
            onToggle(true);
        } else {
            logger.warn('handleToggle:skip', type, true, hasDevice, permissions);
        }
    }, [isDisabled, hasDevice, permissions, onToggle, isChecked, type]);

    const error = getMessage([
        [errorId, errorId && intl.formatMessage({ id: errorId })],
        [!hasDevice, i18n.noDevice],
        [permissions === 'deny', i18n.denied],
    ]);

    // eslint-disable-next-line no-nested-ternary
    const color = error && error !== i18n.button ? 'danger' : isChecked ? 'lighten' : 'light-transparency';

    return (
        <Button
            iconName={isChecked ? btnOnLabel : btnOffLabel}
            tooltipPosition="top"
            color={color}
            isDisabled={isDisabled || !hasDevice || permissions === 'deny' || disableMultipleClicks}
            tooltipText={error}
            onClick={handleClick}
        />
    );
}

DeviceControlButton.propTypes = {
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    deviceType: PropTypes.string,
    btnOffLabel: PropTypes.string.isRequired,
    btnOnLabel: PropTypes.string.isRequired,
    formatter: PropTypes.func.isRequired,
    errorId: PropTypes.string,
};

DeviceControlButton.defaultProps = {
    isDisabled: false,
    isChecked: false,
    mediaType: undefined,
    deviceType: undefined,
    errorId: undefined,
};

export default injectIntl(DeviceControlButton);
