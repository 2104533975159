import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getNetworkError } from 'components/Room/Header/container';
import  { selectors } from '@amplement/backend-connector';
import { useTimeout } from 'react-use';

const useNetworkState = (debounce = 1000) => {
    const isOnline = useSelector(selectors.network.getIsOnlineSelector);
    const wsStatus = useSelector(selectors.network.getWsStatusSelector);
    const error = getNetworkError(isOnline, wsStatus);
    const [timer, setTimer] = useState(0);
    const [isReady, cancel, reset] = useTimeout(timer);

    useEffect(() => {
        if (error) {
            reset();
            setTimer(debounce);
        } else if (timer) {
            cancel();
        }
    }, [error]);

    if (isReady()) {
        return {
            error,
            isOnline,
            wsStatus
        }
    }

    return {
        error: undefined,
        isOnline: isOnline || undefined,
        wsStatus: wsStatus || undefined
    }
}

export default useNetworkState;
