import React, { useCallback, useMemo } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Form, Typography, message, Collapse } from 'antd';
import { Form as FinalForm, Field } from 'react-final-form';
import styled from 'styled-components';
import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { LabelInput, LabelTextArea } from 'components/Shared/Forms';
import Button from 'components/Shared/Common/Button';

import { usePostPhonebook, usePatchPhonebook, usePhonebookByContact } from 'hooks/usePhonebook';

const { Paragraph } = Typography;

const PHONE_MIN_LENGTH = 7;
const PHONE_MAX_LENGTH = 15;

const isNumber = (value) => /^\d+$/.test(value);

const StyledField = styled(Field)`
    width: 48%;
    margin-bottom: 20px;
`;

const StyledFieldFull = styled(Field)`
    width: 100%;
    margin-bottom: 20px;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

const StyledErrorText = styled(Paragraph)`
    margin-bottom: 20px;
    color: red;
`;

type ErrorType = {
    fillAtLeastOneTitleField?: string;
    landline?: string;
    fax?: string;
    mobile?: string;
};

const StyledCollapse = styled(Collapse)`
    margin-bottom: 20px;
`;

const StyledContainer = styled.div`
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function validateContactNumbers({ landline, fax, mobile }, translations) {
    const errors = {};

    const validateField = (fieldValue, fieldName) => {
        if (fieldValue && !isNumber(fieldValue)) {
            errors[fieldName] = translations.phoneError;
        } else if (fieldValue && fieldValue.length < PHONE_MIN_LENGTH) {
            errors[fieldName] = translations.phoneSizeError;
        } else if (fieldValue && fieldValue.length > PHONE_MAX_LENGTH) {
            errors[fieldName] = translations.maxPhoneSizeError;
        }
    };

    validateField(landline, 'landline');
    validateField(fax, 'fax');
    validateField(mobile, 'mobile');

    return errors;
}

const CollapsedItems = ({ translations }) => (
    <StyledCollapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        items={[{
            key: '1',
            label: translations.moreInfos,
            children: (
                <>
                    <StyledDiv>
                        <StyledField
                            name="mobile"
                            component={LabelInput}
                            label={translations.mobileField}
                            placeholder={translations.mobileField}
                        />
                        <StyledField
                            name="fax"
                            component={LabelInput}
                            label={translations.faxField}
                            placeholder={translations.faxField}
                        />
                    </StyledDiv>
                    <StyledDiv>
                        <StyledField
                            name="email"
                            component={LabelInput}
                            label={translations.emailField}
                            placeholder={translations.emailField}
                        />
                        <StyledField
                            name="country"
                            component={LabelInput}
                            label={translations.countryField}
                            placeholder={translations.countryField}
                        />
                        <StyledField
                            name="city"
                            component={LabelInput}
                            label={translations.cityField}
                            placeholder={translations.cityField}
                        />
                        <StyledField
                            name="zipcode"
                            component={LabelInput}
                            label={translations.zipcodeField}
                            placeholder={translations.zipcodeField}
                        />
                        <StyledFieldFull
                            name="address"
                            component={LabelInput}
                            label={translations.addressField}
                            placeholder={translations.addressField}
                        />
                        <StyledFieldFull
                            name="comment"
                            component={LabelTextArea}
                            label={translations.comment}
                            placeholder={translations.comment}
                        />
                    </StyledDiv>
                </>
            )
        }]}
    />
);

type ContactFormProps = {
    contactId?: string;
    closeModal?: () => void;
    onSuccess?: (response) => void;
    phoneNumber?: string;
} & WrappedComponentProps;

const ContactForm = ({
    intl,
    contactId,
    closeModal,
    onSuccess,
    phoneNumber
}: ContactFormProps): JSX.Element => {
    const translations = useMemo(() => ({
        firstnameField: intl.formatMessage({ id: 'profile.fields.firstname.placeholder' }),
        lastnameField: intl.formatMessage({ id: 'profile.fields.lastname.placeholder' }),
        companyField: intl.formatMessage({ id: 'contacts.fields.company.placeholder' }),
        emailField: intl.formatMessage({ id: 'settings.menu.email' }),
        mobileField: intl.formatMessage({ id: 'call.forward.infos.mobile' }),
        landlineField: intl.formatMessage({ id: 'contacts.fields.landline' }),
        faxField: intl.formatMessage({ id: 'contacts.fields.fax' }),
        addressField: intl.formatMessage({ id: 'contacts.fields.address' }),
        cityField: intl.formatMessage({ id: 'contacts.fields.city' }),
        zipcodeField: intl.formatMessage({ id: 'contacts.fields.zipcode' }),
        countryField: intl.formatMessage({ id: 'contacts.fields.country' }),
        comment: intl.formatMessage({ id: 'contacts.fields.comment' }),
        saveSuccess: intl.formatMessage({ id: 'contacts.save.success' }),
        saveError: intl.formatMessage({ id: 'contacts.save.error' }),
        saveButton: intl.formatMessage({ id: 'global.button.save' }),
        moreInfos: intl.formatMessage({ id: 'contacts.moreInfos' }),
        fillAtLeastOneTitleField: intl.formatMessage({ id: 'contacts.minimalRequirementsMessage' }),
        phoneError: intl.formatMessage({ id: 'contacts.phoneError' }),
        phoneSizeError: intl.formatMessage({ id: 'contacts.phoneSizeError' }),
        maxPhoneSizeError: intl.formatMessage({ id: 'contacts.maxPhoneSizeError' }),
    }), [intl]);

    const validate = useCallback(({
        firstname = '',
        lastname = '',
        company = '',
        landline = '',
        fax = '',
        mobile = '',
    } = {}) => {
        const err: ErrorType = {};

        if (!firstname && !lastname && !company) {
            err.fillAtLeastOneTitleField = translations.fillAtLeastOneTitleField;
        }

        return {
            ...err,
            ...validateContactNumbers({ landline, fax, mobile }, translations),
        };
    }, [translations]);

    const handleSuccess = useCallback(response => {
        message.success({
            key: 'savePhoneBookUserSuccess',
            content: translations.saveSuccess,
            duration: 3,
        });
        onSuccess?.(response);
        closeModal?.();
    }, [translations, closeModal]);

    const onError = useCallback((error) => {
        const { response } = error;

        message.error({
            key: 'savePhoneBookUserError',
            content: response?.data?.message || error?.message || translations.saveError,
            duration: 3,
        });
    }, [translations]);

    const { data: phonebookData = {}, isLoading: isLoadingContactInfos } = usePhonebookByContact({ _contact: contactId });
    const { mutate: post, isLoading: postIsLoading } = usePostPhonebook({ onSuccess: handleSuccess, onError });
    const { mutate: patch, isLoading: patchIsLoading } = usePatchPhonebook({ onSuccess: handleSuccess, onError});

    const initialValues = useMemo(() => {
        if (contactId) {
            return {
                id: phonebookData.id,
                firstname: phonebookData.user_name?.first,
                lastname: phonebookData.user_name?.last,
                company: phonebookData.work_info?.company_name,
                email: phonebookData.email,
                mobile: phonebookData.user_phone?.mobile_phone,
                landline: phonebookData.user_phone?.work_phone,
                fax: phonebookData.user_phone?.work_fax,
                address: phonebookData.work_address?.address_line1,
                city: phonebookData.work_address?.city,
                zipcode: phonebookData.work_address?.postal_code,
                country: phonebookData.work_address?.country,
                comment: phonebookData.user_notes?.history?.[0].data,
            };
        }

        if (phoneNumber) {
            return { landline: phoneNumber };
        }

        return {};
    }, [phonebookData, contactId, phoneNumber]);

    const handleSubmit = useCallback(({ id, ...form }) => {
        if (id) {
            const payload = Object.keys(initialValues).reduce((acc, key) => {
                acc[key] = typeof form[key] === 'undefined' ? '' : form[key];
                return acc;
            }, {});
            Object.assign(payload, form);
            const patchValues = Object.keys(payload).reduce((acc, key) => {
                if (payload[key] !== initialValues[key] && key !== 'id') {
                    acc[key] = payload[key];
                }
                return acc;
            }, {});

            patch({ _contact: id, ...patchValues });
        } else {
            post(form);
        }
    }, [post, patch, initialValues]);

    if (contactId && isLoadingContactInfos) {
        return (
            <StyledContainer>
                <LoadingOutlined />
            </StyledContainer>
        );
    }

    return (
        <FinalForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            validate={validate}
        >
            {({ handleSubmit: formSubmit, pristine, submitting, valid, errors }) => (
                <Form layout='vertical'>
                    <StyledDiv>
                        <StyledField
                            name="firstname"
                            component={LabelInput}
                            autoFocus
                            label={translations.firstnameField}
                            placeholder={translations.firstnameField}
                        />
                        <StyledField
                            name="lastname"
                            component={LabelInput}
                            label={translations.lastnameField}
                            placeholder={translations.lastnameField}
                        />
                    </StyledDiv>
                    <StyledDiv>
                        <StyledField
                            name="company"
                            component={LabelInput}
                            label={translations.companyField}
                            placeholder={translations.companyField}
                        />
                        <StyledField
                            name="landline"
                            component={LabelInput}
                            label={translations.landlineField}
                            placeholder={translations.landlineField}
                        />
                    </StyledDiv>
                    <CollapsedItems translations={translations} />
                    {errors?.fillAtLeastOneTitleField && !pristine && (
                        <StyledErrorText>
                            {errors?.fillAtLeastOneTitleField}
                        </StyledErrorText>
                    )}
                    <Button
                        color="primary"
                        isLoading={postIsLoading || patchIsLoading}
                        isDisabled={submitting || !valid || pristine}
                        onClick={formSubmit}
                    >
                        {translations.saveButton}
                    </Button>
                </Form>
            )}
        </FinalForm>
    )
}

ContactForm.propTypes = {
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    closeModal: PropTypes.func,
    phoneNumber: PropTypes.string,
    intl: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
};

ContactForm.defaultProps = {
    closeModal: () => {},
    onSuccess: () => {},
    phoneNumber: '',
    contactId: undefined,
};

export default injectIntl(ContactForm as React.FC<ContactFormProps>);
