import React, { useMemo, useCallback, memo } from 'react';
import { injectIntl } from 'react-intl';
import { selectors } from '@amplement/backend-connector';
import isEqual from 'lodash/isEqual';

import useRoomSelector from 'hooks/useRoomSelector';

import ButtonClick from 'components/Shared/Common/Button/ButtonClick';
import { NAVIGATION } from 'components/CallBuilder/constants';
import Row from 'components/CallBuilder/Row/Row';
import InfiniteScrollList from 'components/CallBuilder/InfiniteScrollList';
import CallButton from 'components/CallBuilder/DefaultResults/style';

import { ListProps, RoomProps } from 'components/CallBuilder/types';

const Room = injectIntl(({
    item: { id, name },
    onAddParticipant,
    onRemoveParticipant,
    inputValue,
    onCloseModal,
    intl,
    showDirectCall,
}: RoomProps) => {
    const { members = [], isPersistent } = useRoomSelector(selectors.rooms.getRoomByIdSelector, id) || {};

    const handleAddRoomParticipants = useCallback(() => {
        members.forEach(member => {
            if (member.isMyUser || inputValue.find((item) => item?._user === member?._user)) {
                return;
            }

            onAddParticipant?.({
                resultType: NAVIGATION.ROOMS,
                phoneNumber: member.phoneNumber,
                _room: id,
                value: member._user || member.phoneNumber,
                label: member.label || member.name || member.phoneNumber || member.fullname,
                ...member
            });
        });
    }, [onAddParticipant, members, inputValue, id]);

    const handleRemoveRoomParticipants = useCallback(() => {
        members.forEach(member => onRemoveParticipant?.(member, '_user'));
    }, [members, onRemoveParticipant]);

    const hasParticipant = useMemo(() => {
        if (!members.length || (members.length === 1 && members[0].isMyUser)) return false;

        return members.every(member => {
            if (member.isMyUser) {
                return true;
            }

            return inputValue.some(item => item?._user === member?._user)
        });
    }, [inputValue, members]);

    return (
        <Row
            label={name}
            onAddParticipant={handleAddRoomParticipants}
            onRemoveParticipant={handleRemoveRoomParticipants}
            hasParticipant={hasParticipant}
            members={members}
            avatarSize="md"
            avatarMode=""
            maxAvatarCount={14}
        >
            {!inputValue.length && showDirectCall ? (
                <ButtonClick onClick={onCloseModal}>
                    <CallButton
                        iconName="Phone"
                        members={members}
                        isPersistent={isPersistent}
                        _room={id}
                    >
                        <p>{intl.formatMessage({ id: 'call.call' })}</p>
                    </CallButton>
                </ButtonClick>
            ) : null}
        </Row>
    );
});

const RoomsList = (props: ListProps): JSX.Element => (
    <InfiniteScrollList
        dataKey={NAVIGATION.ROOMS}
        component={Room}
        {...props}
    />
);

export default memo(RoomsList as React.FC<ListProps>, isEqual);
