import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { getFileType } from 'utils/file';
import { MyUpload } from 'components/Shared/Common/Upload';
import Button from 'components/Shared/Common/Button';
import Icon from 'components/Shared/Common/Icon';
import Action from 'components/Shared/Common/Action';

import { LEFT_ARROW, RIGHT_ARROW, ENTER, ESCAPE } from 'const/eventKey';
import { injectIntl } from 'react-intl';
import { YesNoToggleButton } from 'components/Shared/Forms/ToggleButton';
import { callbacks } from '@amplement/backend-connector';

import ThumbFactory from 'components/Feed/Creator/FileUploadManager/ThumbsTemplates/Factory';
import PreviewFactory from 'components/Feed/Creator/FileUploadManager/PreviewTemplates/Factory';


class Carousel extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            compress: props.allowCompression,
            isUploading: false,
            currentDisplayIndex: 0,
            totalSize: 0,
            isOverSize: false,
            inputFocused: false
        };
    }

    componentDidMount() {
        this.updateFiles(this.props.files);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.files !== this.props.files) {
            this.updateFiles(this.props.files);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        const { inputFocused } = this.state;

        if (this.props.visible) {
            if (e.key === ESCAPE || e.keyCode === ESCAPE) {
                this.props.onCancel();
            }

            if (!inputFocused) {
                if (e.key === LEFT_ARROW || e.keyCode === LEFT_ARROW) {
                    this.selectPrev();
                } else if (e.key === RIGHT_ARROW || e.keyCode === RIGHT_ARROW) {
                    this.selectNext();
                } else if (e.key === ENTER || e.keyCode === ENTER) {
                    this.handleSubmit();
                }
            } else if ((e.key === ENTER || e.keyCode === ENTER) && !e.shiftKey) {
                this.handleSubmit();
            }
        }
    };

    updateFiles = (files) => {
        const fileSizeLimit = callbacks.get('getFileUploadSizeLimit')();
        this.setState(prevState => ({
            items: files.map(file => {
                const extended = prevState.items.find(x => x.id === file.name);
                if (!extended) {
                    return ({ raw: file, type: getFileType(file), id: file.name, content: '', isLoading: true });
                }
                return extended;
            }),
            currentDisplayIndex: prevState.items.length ? Math.max(0, this.props.files.length - 1)
                : Math.max(0, Math.min(prevState.currentDisplayIndex, this.props.files.length - 1)),
            isOverSize: files.find(f => f.size && f.size > fileSizeLimit)
        }));
    }

    handleAddFile = (file) => this.props.onAddFileToUpload(file);

    handleChangeContent = (id, content) => {
        this.state.items = this.state.items.map(x => x.id === id ? { ...x, content } : x);
    }

    handleRemove = id => this.props.removeFileToUpload(id);

    renderPreview = (item, index) => (
        <PreviewFactory
            key={item.id}
            file={item.raw}
            type={item.type}
            content={item.content}
            onChange={this.handleChangeContent}
            isCurrent={index === this.state.currentDisplayIndex}
            onFocus={this.handleInputFocus}
        />
    );

    selectIndex = (index) => () => this.setState({ currentDisplayIndex: index });

    selectNext = () => this.setState(state => ({
        currentDisplayIndex:
            Math.min(state.currentDisplayIndex + 1, this.props.files.length - 1)
    }));

    selectPrev = () => this.setState(state => ({
        currentDisplayIndex: Math.max(state.currentDisplayIndex - 1, 0)
    }));

    renderThumbItem = (item, index) => (
        <Fragment key={item.id}>
            <ThumbFactory
                onClick={this.selectIndex(index)}
                file={item.raw}
                type={item.type}
                onRemove={this.handleRemove}
                isCurrent={index === this.state.currentDisplayIndex}
            />
        </Fragment>
    );

    handleSubmit = () => {
        this.props.onSubmit(this.state.items, this.state.compress);
        setTimeout(() => this.props.onCancel(), 1);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleInputFocus = newState => this.setState({ inputFocused: newState });

    render() {
        const { maxUploadCount, allowCompression, intl, files } = this.props;
        const { items, compress, isUploading, currentDisplayIndex, totalSize, isOverSize } = this.state;
        const itemLength = files && files.length ;
        const hasNext = currentDisplayIndex < itemLength - 1;
        const hasPrev = currentDisplayIndex > 0;
        const isFileCountOverSize = itemLength > maxUploadCount;

        const ArrowLeft = itemLength > 1 ? (
            <Action className="arrow prev" onClick={this.selectPrev}>
                <Button iconName="ChevronLeft" isDisabled={!hasPrev} />
            </Action>
        ) : null;

        const ArrowRight = itemLength > 1 ? (
            <Action className="arrow next" onClick={this.selectNext}>
                <Button iconName="ChevronRight" isDisabled={!hasNext} />
            </Action>
        ) : null;

        const Preview = items.map(this.renderPreview);

        const UploadButton = (
            <MyUpload
                onAdd={this.handleAddFile}
                disabled={isUploading}
                className="thumbs-item upload"
            >
                <Icon iconName="Plus" />
                <div className="label">
                    {intl.formatMessage({
                        id: isUploading ? 'modal.uploadFile.loading' : 'modal.uploadFile.addfile'
                    })}
                </div>
            </MyUpload>
        );

        const Thumbs =items.map(this.renderThumbItem);

        const CompressSettingButton = allowCompression && (
            <>
                <div className="switch-container">
                    <YesNoToggleButton
                        value={compress}
                        onChange={() => this.setState(state => ({ compress: !state.compress }))}
                    />
                </div>
                <div className="label">{intl.formatMessage({ id: 'modal.uploadFile.lowBandwith' })}</div>
            </>
        );

        const SubmitButton = (
            <Button
                color="primary"
                iconName="Check"
                isDisabled={isOverSize || isFileCountOverSize}
                onClick={this.handleSubmit}
            >
                {intl.formatMessage({ id: 'global.button.send' })}
            </Button>
        );

        const CancelButton =  (
            <Button
                color="secondary"
                iconName="X"
                onClick={this.handleCancel}
            >
                {intl.formatMessage({ id: 'global.button.cancel' })}
            </Button>
        );

        const Counter = itemLength > 1 ? intl.formatMessage({ id: 'modal.uploadFile.counter' }, { index: currentDisplayIndex + 1, count: itemLength }) : null;

        const Size = `size: ${totalSize} octets`;

        const ErrorMessage = (isOverSize || isFileCountOverSize) && (
            <div>
                {isOverSize && intl.formatMessage({ id: 'modal.uploadFile.error.oversize' })}
                {isOverSize && isFileCountOverSize && <br />}
                {isFileCountOverSize && intl.formatMessage({ id: 'modal.uploadFile.error.filecountoversize' })}
            </div>
        );

        return this.props.children({
            ArrowLeft,
            ArrowRight,
            UploadButton,
            Preview,
            Thumbs,
            CompressSettingButton,
            SubmitButton,
            CancelButton,
            Counter,
            Size,
            ErrorMessage,
            count: itemLength
        });
    }
}

Carousel.propTypes = {
    intl: PropTypes.any.isRequired,
    files: PropTypes.arrayOf(PropTypes.object).isRequired,
    maxUploadCount: PropTypes.number,
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onAddFileToUpload: PropTypes.func,
    removeFileToUpload: PropTypes.func,
    allowCompression: PropTypes.bool,
    visible: PropTypes.bool
};

Carousel.defaultProps = {
    maxUploadCount: 10,
    onSubmit: undefined,
    onCancel: undefined,
    onAddFileToUpload: undefined,
    removeFileToUpload: undefined,
    allowCompression: true,
    visible: true
};

export default injectIntl(Carousel);
