import React, { lazy, Suspense } from 'react';

const JSONTree = lazy(() => import('react-json-tree').then(module => ({
    default: module.JSONTree
 }))); // eslint-disable-line

const JsonTree = ({
    data,
    theme,
    postprocessValue,
    ...rest
}) => (
    <Suspense fallback="loading">
        <JSONTree
            data={data}
            theme={theme}
            hideRoot
            postprocessValue={postprocessValue}
            {...rest}
        />
    </Suspense>
);

export default JsonTree;
