import React, { memo } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import AvatarStatus from 'components/Shared/User/Avatar';
import Button from 'components/Shared/Common/Button';
import Icon from 'components/Shared/Common/Icon';
import isEqual from 'react-fast-compare';

import { CONTACT_STATUS } from 'const/user';
import { getUrl } from 'utils/url';
import { isWebRtcCompatible } from 'utils/webRTC';
import { StyledPageLayout } from 'components/Shared/style';
import useUser from 'hooks/useUser';
import ContactActions from 'components/Shared/User/Item/ContactActions';
import ErrorFormatter from 'components/Shared/Errors/Formatter';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import ContentErrorComponent from 'components/Shared/Errors/ContentError';
import { 
    StyledContainer, 
    StyledActions, 
    StyledProfileHeader, 
    StyledProfileContent, 
    StyledProfileItem, 
    StyledProfileTitle, 
    StyledProfileList 
} from './styles';

const Profile = ({ status: userStatus, isPublic, _me, _user, intl }) => {
    const { isLoading, error, isError, data, isFetching } = useUser({ _user });
    const { avatarUri, firstname, lastname, isInitiator, contactStatus, biography, education, title, company } = data || {};
    const messageUrl = getUrl('feedByUser', { _user });
    const roomUrl = getUrl('roomByUser', { _user });
    const isOwner = _me === _user;
    const hasWebRtcCompatible = isWebRtcCompatible();

    if (isError) {
        return (
            <ErrorFormatter
                error={error}
                buttonType={BUTTON_TYPE.GOHOME}
                render={ContentErrorComponent}
            />
        )
    }

    return (
        <StyledPageLayout className="ui-profile">
            <StyledContainer className="ui-profile-container">
                <StyledProfileHeader className="ui-profile-header">
                    <AvatarStatus
                        src={avatarUri}
                        status={userStatus}
                        size="xxxxl"
                        alt={`${firstname} ${lastname}`}
                        isEditable={false}
                    />
                    <div className="break">
                        <div className="wrapper">
                            <div className="text">
                                <div className="name">
                                    <div className="firstname">
                                        {firstname}
                                    </div>
                                    <div className="lastname">
                                        {lastname}
                                    </div>
                                </div>
                                <div className="text">
                                    {title}
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            {!isOwner && contactStatus === CONTACT_STATUS.ACCEPTED && (
                                <>
                                    <Button
                                        color="secondary"
                                        iconName="MessageCircle"
                                        href={messageUrl}
                                    />
                                    <Button
                                        color="secondary"
                                        iconName={hasWebRtcCompatible ? 'Phone' : 'PhoneOff'}
                                        href={roomUrl}
                                        target={`roomByUser-${_user}`}
                                        isDisabled={!isWebRtcCompatible()}
                                    />
                                </>
                            )}
                            {isOwner && (
                                <Button
                                    iconName="Edit2"
                                    href={getUrl('profileEdition')}
                                    color="secondary"
                                    tooltipText={intl.formatMessage({
                                        id: 'profile.edit',
                                        defaultMessage: 'Éditer'
                                    })}
                                />
                            )}
                        </div>
                    </div>
                </StyledProfileHeader>
                <StyledProfileContent className="ui-profile-content">
                    <StyledProfileItem className="ui-profile-content-item">
                        {(biography && biography.length) && (
                            <>
                                <StyledProfileTitle className="ui-title">
                                    <FormattedMessage
                                        id="profile.fields.bio"
                                        defaultMessage="Biographie"
                                    />
                                </StyledProfileTitle>
                                <div
                                    className={classNames(
                                        'ui-text',
                                        { loading: isLoading || isFetching }
                                    )}
                                >
                                    {biography}
                                </div>
                            </>
                        )}
                    </StyledProfileItem>
                    <StyledProfileItem className="ui-profile-content-item">
                        {(education || company) && (
                            <StyledProfileTitle className="ui-title">
                                <FormattedMessage
                                    id="profile.fields.job"
                                    defaultMessage="Carrière"
                                />
                            </StyledProfileTitle>
                        )}
                        <StyledProfileList className="ui-profile-list">

                            {(company && company.length) && (
                                <div
                                    className={classNames(
                                        'item',
                                        { loading: isLoading || isFetching }
                                    )}
                                >
                                    <Icon iconName="Briefcase" />
                                    {company}
                                </div>
                            )}

                            {(education && education.length) && (
                                <div
                                    className={classNames(
                                        'item',
                                        { loading: isLoading || isFetching }
                                    )}
                                >
                                    <Icon iconName="Award" />
                                    {education}
                                </div>
                            )}

                        </StyledProfileList>
                    </StyledProfileItem>
                </StyledProfileContent>
                {isPublic && (
                    <StyledActions className="ui-profile-header">
                        {!isOwner && (
                            <ContactActions
                                _user={_user}
                                contactStatus={contactStatus}
                                isInitiator={isInitiator}
                                showUnlink
                                size="md"
                            />
                        )}
                    </StyledActions>
                )}
            </StyledContainer>
        </StyledPageLayout>
    );
}

export default injectIntl(memo(Profile, isEqual));
