import React, { useState, useContext, useMemo, createContext, memo } from 'react';
import localStorage from 'services/storage/localStorage';
import { useToggle } from 'react-use';

type SessionContextType = {
    _client?: string;
    _user?: string;
} & DebugContextType;

type DebugContextType = {
    debug: boolean;
    toggleDebug: () => void;
}

const SessionContext = createContext<SessionContextType>({
    debug: localStorage.get('debugWebrtc') === '1',
    toggleDebug: () => {},
    _client: undefined,
    _user: undefined
});

export const useDebug = () => {
    const { debug, toggleDebug } = useContext(SessionContext);

    return useMemo(() => ([
        debug,
        toggleDebug
    ]), [debug, toggleDebug]);
}

export const useSession = () => useContext(SessionContext)

export const SessionProvider = memo((props) => {
    const [debug, toggleDebug] = useToggle(localStorage.get('debugWebrtc') === '1');
    const [session, setSession] = useState({ 
        _user: undefined,
        _client: undefined
    });

    const value = useMemo<SessionContextType>(() => ({
        debug,
        toggleDebug,
        session, 
        setSession
    }), [debug, session]);

    return (
        <SessionContext.Provider
            value={value}
            {...props}
        />
    );
});
