import { connect } from 'react-redux';

import * as smallDrawerTypes from 'types/smallDrawer';
import { openSmallDrawer } from 'actions/smallDrawer';
import  { selectors } from '@amplement/backend-connector';

import { getUserByIdSelector } from 'selectors/user';
import FeedItemStartupPlaceholder from './index';

const mapStateToProps = (state, { _feed }) => {
    const feed = selectors.feeds.feedByIdSelector(state, _feed);
    const { cast, createdAt, _user } = feed || {};
    const { fullname } = _user ? getUserByIdSelector(state, _user) || {} : {};

    return ({
        _feed,
        cast,
        createdAt,
        fullname,
        _user,
        isEditable: feed?.isOwned
    });
};

const mapDispatchToProps = dispatch => ({
    onOpenInformationDrawer: () => dispatch(
        openSmallDrawer(smallDrawerTypes.FEED_DRAWER_INFORMATIONS)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedItemStartupPlaceholder);
