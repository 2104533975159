export const mergeEntities = (state, entitiesToMerge, deepMergeKeys) => {
    if (entitiesToMerge === undefined && deepMergeKeys === undefined) {
        return state;
    }
    if (typeof entitiesToMerge !== 'object') {
        throw new Error('Missing entities !');
    }

    if (Object.keys(entitiesToMerge).length === 0) {
        return state;
    }

    const nextState = {
        ...state
    };

    (deepMergeKeys || Object.keys(entitiesToMerge)).forEach((entityName) => {
        // todo : trouver un tricks pour le faire automatiquement, via un helper peut etre ?
        if (entityName === 'users') {
            const mergeStrategy = (source = {}, dest = {}) => {
                Object.keys(source).forEach((id) => {
                    if (dest[id]) {
                        dest[id] = ({ // eslint-disable-line
                            ...dest[id],
                            ...source[id]
                        });
                    } else {
                        dest[id] = { ...source[id] }; // eslint-disable-line
                    }
                });
                return { ...dest };
            };

            nextState[entityName] = mergeStrategy(entitiesToMerge[entityName], nextState[entityName]); // eslint-disable-line
        } else if (typeof entitiesToMerge[entityName] === 'object') {
            nextState[entityName] = {
                ...nextState[entityName],
                ...entitiesToMerge[entityName]
            };
        }
    });

    return nextState;
};

export const setEntityById = (state, entityName, id, entity) => {
    let newState = state;

    if (!newState[entityName]) {
        throw new Error(`Missing entities ${entityName} in state !`);
    }

    if (!id) {
        throw new Error(`Entity ${entityName} has no id !`);
    }

    newState = {
        ...state,
        [entityName]: { ...state[entityName] }
    };

    newState[entityName][id] = entity;

    return newState;
};

export const removeEntityById = (state, entityName, id) => {
    const newState = {
        ...state,
        [entityName]: { ...state[entityName] }
    };

    if (newState[entityName][id]) {
        delete newState[entityName][id];
    }

    return newState;
};
