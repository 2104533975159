import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography, Row } from 'antd';
import Avatar from 'components/Shared/User/Avatar';
import UserName from 'components/Shared/User/UserName';
import Link from 'components/Shared/Common/Link';

import { getUrl } from 'utils/url';
import { StyledText } from 'components/Shared/User/Item/styles';

const { Text } = Typography;

const UserItem = ({
    _user,
    avatar,
    avatarSize,
    status,
    firstname,
    lastname,
    title,
    isLoading,
    noLink,
    badges
}) => (
    <Row align="middle" wrap={false}>
        <Avatar
            src={avatar}
            size={avatarSize}
            status={status}
            alt={`avatar-${firstname}-${lastname}`}
            isEditable={false}
            href={noLink ? undefined : getUrl('profile', { _user })}
            isLoading={isLoading}
        />
        <StyledText className={classNames('s-text', { loading: isLoading })}>
            <Link href={noLink ? undefined : getUrl('feedByUser', { _user })}>
                <UserName lastname={lastname} firstname={firstname} badges={badges} />
            </Link>
            <Text ellipsis type="secondary">{title}</Text>
        </StyledText>
    </Row>
);

UserItem.propTypes = {
    _user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    badges: PropTypes.array,
    avatarSize: PropTypes.string,
    status: PropTypes.number,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    noLink: PropTypes.bool
};

UserItem.defaultProps = {
    avatar: {},
    status: undefined,
    badges: undefined,
    isLoading: undefined,
    avatarSize: 'md',
    title: undefined,
    noLink: undefined,
};

export default UserItem;
