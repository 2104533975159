import { connect } from 'react-redux';

import { selectors, constants } from '@amplement/backend-connector';

import { openModal } from 'actions/modal';
import { MODAL_KICK_ROOM_USER } from 'types/modal';

import Participant from './index';

const mapStateToProps = (state, props) => {
    const { _client, _room } = props;
    const member = selectors.rooms.getRoomMemberByClientIdSelector(state, _room, _client);
    const {
        fullname,
        phonenumber,
        avatarUri: avatar,
        _user,
        status,
        recordingState,
    } = member || {};
    const _focusedMember = selectors.rooms.getFocusedUserByRoomId(state, _room)?._client;

    return {
        _user,
        avatar,
        recordingState,
        name: fullname || phonenumber,
        status,
        isLoading: status === constants.rooms.USER_IN_ROOM_STATUS.WAITING,
        isError: false,
        hasFocus: _focusedMember === _client
    }
};

const mapDispatchToProps = (dispatch) => ({
    onKick: (_room, _user) => dispatch(openModal(MODAL_KICK_ROOM_USER, { _user, _room })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Participant);


const mapStateToPropsAway = (state, ownProps) => {
    const { _user, _room } = ownProps;
    const member = selectors.rooms.getRoomMemberByUserIdSelector(state, _room, _user);
    const { fullname, phonenumber, avatarUri: avatar } = member || {};
    

    return {
        name: fullname || phonenumber,
        isAway: true,
        avatar
    }
};

export const Away = connect(
    mapStateToPropsAway,
    mapDispatchToProps
)(Participant);
