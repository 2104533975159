import React from 'react';
import PropTypes from 'prop-types';

const IframeReader = ({
    url,
    id,
    title,
    width,
    height,
    frameBorderSize,
    sandbox
}) => (
    <div
        className="iframe-reader"
        style={{ width: '100%', height: '100%' }}
    >
        <iframe
            id={id}
            title={title}
            width={width}
            height={height}
            src={url}
            frameBorder={frameBorderSize}
            sandbox={sandbox}
        />
    </div>
);

IframeReader.propTypes = {
    url: PropTypes.string.isRequired,
    id: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    frameBorderSize: PropTypes.number,
    sandbox: PropTypes.string
};

IframeReader.defaultProps = {
    id: '',
    title: '',
    sandbox: '',
    width: '100%',
    height: '100%',
    frameBorderSize: 0
};

export default IframeReader;
