import React, { memo, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Form, Input, Button, Typography, Select , Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import { getUrl, getQueryStringParameter } from 'utils/url';
import config from 'config';
import storage from 'services/storage/localStorage';
import JsonTree from 'components/Shared/Debug/components/JsonTree';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUserIdSelector, getCurrentUserSelector } from 'selectors/user';
import * as tokenService from 'services/token';
import { cleanSession } from 'screens/Redirects/Logout';
import Logger from 'services/debug/logger';
import { apiClient, actions, apis, callbacks } from '@amplement/backend-connector';

const logger = new Logger('component:Login');

const { Text } = Typography;
const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { 
        offset: 8, 
        span: 16 
    }
};

const Style = styled.div`
    margin: auto;
`;

const AccountSelectorStyles = styled.div`
    margin: auto;
    background-color: '#ccc'
`;

type WhiteLabel = {
    token: string;
    company: {
        name: string,
        domainUrl: string
    }
}

type AccountSelectorProps = {
    accounts: Array<WhiteLabel> 
    onSelect
}

type OnLogin = (values: { namespace?: string; email: string; password: string; }) => void;

type UserBookmarksProps = {
    onLogin: OnLogin;
}

type UserBookmarkProps = {
    index: number;
    login: string;
    pwd: string;
    onLogin: OnLogin;
}

const AccountSelector = ({ accounts, onSelect }: AccountSelectorProps) => (
    <AccountSelectorStyles>
        <h2>Whitelabels</h2>
        {accounts?.map((wl, index) => (
            <div key={wl.token}>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => onSelect(wl, index)}
                >
                    {wl.company.name} - {wl.company.domainUrl}
                </Button>
                <JsonTree data={wl} theme={undefined} postprocessValue={undefined} />
                <br />
            </div>
        ))}
    </AccountSelectorStyles>
);

const SameAccountDetected = memo(() => {
    const { firstname, lastname, id } = useSelector(getCurrentUserSelector);

    return !!id && !!firstname && (
        <Text type="danger">
            You are already connected with a user
            <br /><strong>id:</strong> {id}
            <br /><strong>firstname:</strong> {firstname}
            <br /><strong>lastname:</strong> {lastname}
            <br /><strong>token (end):</strong>*****{tokenService.getToken()?.substr(-7)}
        </Text>
    );
})

const UserBookmark = ({ index, login, pwd, onLogin }: UserBookmarkProps) => (
    <Row>
        <Col span={18}>{index} - {login}</Col>
        <Col span={6}> <Button onClick={() => onLogin({ email: login, password: pwd })}>Go</Button></Col>
    </Row>
)

const UserBookmarks = memo(({ onLogin }: UserBookmarksProps) => (
    <>
        <h2>shortcuts</h2>
        {config.localCreds?.map((creds, index) => 
            <UserBookmark key={creds?.login} onLogin={onLogin} index={index} {...creds} />)}
    </>
));

const Login = ({ intl }) => {
    const _user = useSelector(getCurrentUserIdSelector);
    const _initialUser = useRef(_user);
    const [accounts, setAccounts] = useState<WhiteLabel[]>([]);
    const [, setState] = useState({});
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    logger.log('render ', _user, _initialUser);

    const navigateToWL = useCallback(() => {
        const returnUrl = getQueryStringParameter('returnUrl')(window.location.toString());

        window.location.href = returnUrl || getUrl('home') || '/';
    }, []);

    const handleLogin: OnLogin = useCallback(values => {
        const _device = callbacks.get('getDeviceId')?.();

        storage.set('getBundleId', values.namespace || undefined);
        setError('');
        apis.session.$loginRequest(values.email, values.password, _device)
            .then(({ data }) =>{
                if (Array.isArray(data) && data?.length > 1) {
                    logger.log('Multiple whitelabel detected, please choose', data);
                    setAccounts(data);
                } else {
                    logger.log('Connecting to whitelabel', data);
                    tokenService.setToken(`${data?.[0]?.token}`);
                    setTimeout(navigateToWL, 100);
                }
            })
            .catch(e => setError(e.message));
    }, []);

    const handleSelectWL = useCallback((wl, index) => {
        const url = new URL(wl?.company?.domainUrl);

        logger.log('Connecting to whitelabel', wl, index);
        storage.set('X-referer', url.host);
        tokenService.setToken(wl.token);
        setTimeout(navigateToWL, 100);
    }, [accounts, dispatch]);

    return (
        <Style>
            Delete Token : 
            <Button onClick={() => tokenService.deleteToken() && setState({})}>Delete cookie</Button>
            <Button onClick={() => { apiClient.setAxiosToken(''); setState({}); }}>Delete axios</Button>
            <Button onClick={() => dispatch(actions.session.updateToken('')) && setState({})}>Delete redux</Button>
            <br />
            CTA: <Button onClick={() => setState({})}>refresh data</Button>
            <Button onClick={() => { cleanSession(); setState({}); }}>clean</Button>
            <Button onClick={() => { window.location.href = getUrl('home') || '/'; }}>Go home</Button>
            <br />
            <SameAccountDetected />
            <br />
            <br />
            {accounts && <AccountSelector accounts={accounts} onSelect={handleSelectWL} />}

            {config.localCreds?.length && <UserBookmarks onLogin={handleLogin} />}
            <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true, email: config.login, password: config.pwd }}
                onFinish={handleLogin}
            >
                {error && <Text type="danger">{error}</Text>}
                {config.isLocalEnv && (
                    <Form.Item name="namespace" label="Select vendor">
                        <Select
                            placeholder="Select vendor"
                        >
                            <Option value="">Default (from url)</Option>
                            <Option value="com.amplement.corporate.mtncollaboration">com.amplement.corporate.mtncollaboration</Option>
                            <Option value="com.amplement.corporate.konet">com.amplement.corporate.konet</Option>
                            <Option value="com.amplement.corporate">com.amplement.corporate</Option>
                        </Select>
                    </Form.Item>
                )}
                <Form.Item
                    label={intl.formatMessage({ id : 'settings.menu.email' })}
                    name="email"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ id : 'settings.menu.password' })}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.formatMessage({ id : 'global.button.connect' })}
                    </Button>
                </Form.Item>
            </Form>
        </Style>
    );
};

export default memo(injectIntl(Login));
