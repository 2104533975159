import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import { injectIntl } from 'react-intl';

const CallForwardFooter = ({
    intl,
    onClose,
    onSubmit,
    isDisabled,
    submitText
}) => ([
    <Button
        onClick={onClose}
        color="secondary"
        key="cancel"
    >
        {intl.formatMessage({ id: 'global.button.cancel' })}
    </Button>,
    <Button
        onClick={onSubmit}
        color="primary"
        isDisabled={isDisabled}
        key="forward"
    >
        {submitText || intl.formatMessage({ id: 'global.button.forward' })}
    </Button>
]);


CallForwardFooter.propTypes = {
    intl: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitText: PropTypes.string,
};

CallForwardFooter.defaultProps = {
    submitText: undefined
};

export default memo(injectIntl(CallForwardFooter));
