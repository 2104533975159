import { useMutation, useQuery } from "react-query"
import { apis } from '@amplement/backend-connector';

const useRooms = ({ _room, enabled = true, transfer = true } = {}) => {
    // const transfer = true;
    const result = useQuery(
        ['rooms', { _room, transfer }],
        async () => {
            let response;

            if (transfer) {
                response = await apis.rooms.$getRoomsDestinations(_room);
            } else {
                response = await apis.rooms.$getRooms();
            }
            const destRooms = response?.data;

            // // todo : check if backend return current room or not, it shouldn't be
            // if (_room && destRooms) {
            //     destRooms = destRooms.filter(room => room.id !== _room);
            // }

            return destRooms?.map(room => ({
                ...room,
                caller: room.members.find(m => !!m.isCaller)
            }));
        },
        { refetchOnWindowFocus: true, refetchOnMount: false, enabled, retry: 0, stale: 10000 }
    )

    return result;
}

export const usePostInviteRoom = ({
    _room,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const mutation = useMutation(
        ({ _users, destNumbers }) => apis.rooms.$postRoomInvites(_room, _users, destNumbers),
        {
            onSuccess,
            onError,
        });

    return mutation;
}

export const useCleanChatRoom = ({
    _room,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const mutation = useMutation(
        () => apis.rooms.$cleanFeeditems(_room),
        {
            onSuccess,
            onError,
        });

    return mutation;
}

export const usePostRoom = ({
    _client,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const mutation = useMutation(
        ({ type, payload }) => apis.rooms.$postRoom({ ...payload, _client, type }),
        {
            onSuccess,
            onError,
        });

    return mutation;
}

export default useRooms;
