
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styled from 'styled-components';
import { Radio } from 'antd';
import { rgba, darken } from 'polished';

const Styled = styled.div`
&.item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
    transition: all 250ms ease;
    border-radius: 0;
    line-height: initial;
    &.spacer{
        padding: 10px;
    }
    &.disabled{
        opacity: .4;
    }
    // reset broken style
    .s-list-user-item{
        margin: 0;
    }
    .ant-radio-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 0;
        >span{
            display: flex;
            flex-direction: row;
            align-items: center;
            &:last-child{
                padding-right: 0;
                width: 100%;
            }
        }
    }
    &:hover{
        background-color: ${({ theme }) => rgba(theme.black, 0.10)};
    }
    &:last-child{
        border-bottom: 0;
    }
}
`;

const StyleRadio = styled(Radio)`
    &.ant-radio-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            .label{
                color: ${({ theme }) => theme.black};
            }
        }
        &:hover{
            .ant-radio-inner{
                border-color: ${props => props.theme.mainColor2};
            }
        }
    }
    .ant-radio{
        .ant-radio-input:focus + .ant-radio-inner{
            border-color: ${props => props.theme.mainColor2};
        }
        &-checked{
            &::after{
                border-color: ${props => props.theme.mainColor2};
            }
            &.ant-radio-disabled{
                .ant-radio{
                    &-inner{
                        background-color: ${({ theme }) => rgba(theme.black, 0.2)};
                        border-color: ${({ theme }) => rgba(theme.black, 0.2)}!important;
                        &::after{
                            border-color: ${({ theme }) => rgba(theme.white, 0.4)};
                        }
                    }
                }
            }
            .ant-radio{
                &-inner{
                    background-color: ${props => props.theme.mainColor2};
                    border-color: ${props => props.theme.mainColor2}!important;
                    &::after{
                        border-color: ${({ theme }) => theme.white};
                        background-color: ${props => darken(0.25, props.theme.mainColor2)};
                    }
                }
            }
        }
        &-inner{
            background-color: ${({ theme }) => rgba(theme.black, 0.2)};
            border-color: ${({ theme }) => rgba(theme.black, 0.2)};
        }
    }
    &.disabled{
        opacity: .4;
    }
`;

const RadioItem = ({ disabled, onChange, value, isSpacer, ItemComponent, ...itemProps }) => (
    <Styled 
        className={classNames(
            'item', 
            { disabled },
            { 'spacer': isSpacer}
        )}>
        <StyleRadio
            disabled={disabled}
            value={value}
        >
            <ItemComponent {...itemProps} value={value} />
        </StyleRadio>
    </Styled>
);

RadioItem.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    ItemComponent: PropTypes.any.isRequired,
    isSpacer: PropTypes.bool
};

RadioItem.defaultProps = {
    disabled: false,
    checked: false,
    isSpacer: false
};

export default memo(RadioItem);
