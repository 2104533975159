import React, { useCallback } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import PropTypes from 'prop-types';

import { useDeletePhonebook } from 'hooks/usePhonebook';

type ExternalContactFormActionsProps = {
    contactId: string;
} & WrappedComponentProps;

const ExternalContactFormActions = ({ intl, contactId }: ExternalContactFormActionsProps): JSX.Element => {
    const onSuccess = useCallback(() => {
        message.success({
            key: 'deletePhoneBookUserSuccess',
            content: intl.formatMessage({ id: 'contacts.delete.success' }),
            duration: 3,
        });
    }, [intl]);

    const onError = useCallback(() => {
        message.error({
            key: 'deletePhoneBookUserError',
            content: intl.formatMessage({ id: 'contacts.delete.error' }),
            duration: 3,
        });
    }, [intl]);

    const { mutate: remove, isLoading: isDeleting } = useDeletePhonebook({ onSuccess, onError });
    const onDeleteContact = useCallback(() => remove(contactId), [contactId, remove]);

    return (
        <Popconfirm
            placement="bottom"
            title={intl.formatMessage({ id: 'contacts.confirmDeletion' })}
            onConfirm={onDeleteContact}
            okText={intl.formatMessage({ id: 'global.button.remove'})}
            cancelText={intl.formatMessage({ id: 'global.button.cancel'})}
        >
            <Button type="text" loading={isDeleting}>
                <DeleteOutlined />
            </Button>
        </Popconfirm>
    );
}

ExternalContactFormActions.propTypes = {
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(ExternalContactFormActions as React.FC<ExternalContactFormActionsProps>);
