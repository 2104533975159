import React, { memo, useCallback, useMemo } from 'react';
import { Typography, Button, Col, Row, Badge } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rgba } from 'polished';

import { actions } from '@amplement/backend-connector';

import Icon from 'components/Shared/Common/Icon';

import useBookmarks from 'hooks/useBookmark';

import { getStatus, PRESENCE_STATUSES } from 'const/user';
import usePhonenumber from 'hooks/usePhonenumber';

const { Text, Title } = Typography;

const StyledBookMarkListContent = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 20px;
    margin-top: -30px;
    background-color: ${props => props.theme.lighten};
    border: 1px solid ${props => rgba(props.theme.black, 0.05)};
    height: 100%;
`;
const StyledTitle = styled(Text)`
    font-size: 12px;
`;
const StyledSubTitle = styled(Text)`
    font-size: 10px;
    margin-top: -5px;
`;
const StyledButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    height: 40px!important;
    padding: 2px 10px!important;
    &:hover{
        background-color: ${props => props.theme.mainColor2}!important;
        ${StyledTitle},
        .icon{
            color: ${props => props.theme.fixedWhite};
        }
        ${StyledSubTitle}{
            color: ${props => rgba(props.theme.fixedWhite, 0.75)}!important;
        }
    }
    .text-wrapper{
        line-height: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-width: 0;
    }
    &:last-child{
        margin-bottom: 0;
    }
    .icon{
        display: flex;
        margin-right: 2px;
    }
`;
const StyledRowHeader = styled(Row)`
    margin-bottom: 10px;
    h5{
        margin-bottom: 0;
    }
    .icon{
        display: flex;
        margin-right: 10px;
    }
`;

const StyledBadge = styled(Badge)`
    margin-right: 5px;
`;

const BookmarkButton = ({ user, label, phoneNumber, onTransferCall }) => {
    const { format } = usePhonenumber({ prefix: true });
    const handleTransfer = useCallback(() => onTransferCall(format(phoneNumber)), [onTransferCall, phoneNumber, format]);
    const status = getStatus(user?.status ?? PRESENCE_STATUSES.OFFLINE);

    return (
        <StyledButton
            icon={<Icon iconName="PhoneForwarded" />}
            onClick={handleTransfer}
            className="ui-btn-transfert"
        >
            <Col className="text-wrapper" align="start" justify="start">
                <StyledTitle ellipsis strong>
                    <StyledBadge color={status.color} />
                    {label}
                </StyledTitle>
                <StyledSubTitle ellipsis type="secondary">{phoneNumber}</StyledSubTitle>
            </Col>
        </StyledButton>
    );
};

const BookmarkList = ({ intl, onTransferCall }) => {
    const { isSuccess, data: bookmarks } = useBookmarks({ type: 'phoneNumber' });

    const translations = useMemo(() => ({
        transfer: intl.formatMessage({ id: 'room.actions.callTransfer' }),
    }), [intl]);

    if (!isSuccess || !bookmarks || !bookmarks.length) {
        return null;
    }

    return (
        <StyledBookMarkListContent>
            <StyledRowHeader justify="start" align="middle">
                <Icon iconName="PhoneForwarded" />
                <Title level={5}>
                    {translations.transfer}
                </Title>
            </StyledRowHeader>
            {bookmarks.map(({ data: { label, phoneNumber }, user }) => (
                <BookmarkButton
                    key={label + phoneNumber}
                    label={label}
                    phoneNumber={phoneNumber}
                    onTransferCall={onTransferCall}
                    user={user}
                />
            ))}
        </StyledBookMarkListContent>
    );
};

BookmarkList.propTypes = {
    intl: PropTypes.object.isRequired,
    onTransferCall: PropTypes.func,
};

BookmarkList.defaultProps = {
    onTransferCall: () => {},
};

const mapDispatchToProps = (dispatch, { _room }) => ({
    onTransferCall: (destNumber => dispatch(actions.rooms.forwardRoom(_room, undefined, destNumber)))
});

export default connect(null, mapDispatchToProps)(injectIntl(memo(BookmarkList)));
