import styled from 'styled-components';
import { rgba, mix } from 'polished';

export const StyledBloc = styled.div`
    &.bloc{
        &-btn{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            align-self: flex-start;
            flex-wrap: wrap;
            flex-direction: column;
            .label{
                color: ${({ theme }) => rgba(theme.black, 0.6)};
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 5px;
                margin-top: 10px;
            }
            ul{
                display: flex;
                flex-direction: column;
                list-style: none;
                li{
                    a{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 14px;
                        color: ${({ theme }) => theme.black};
                        padding: 5px;
                        transition: all 250ms ease;
                        border-bottom: 1px dashed ${({ theme }) => rgba(theme.black, 0.1)};
                        position: relative;
                        margin-top: -1px;
                        span{
                            position: relative;
                            z-index: 2;
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            svg{
                                width: 14px;
                                height: 14px;
                                color: ${({ theme }) => theme.black};
                                margin-right: 5px;
                            }
                        }
                        &:before{
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 0;
                            background-color: transparent;
                            transition: all 250ms ease;
                            z-index: 1;
                        }
                        &:hover{
                            border-bottom-style: solid;
                            &:before{
                                width: 100%;
                                background-color: ${({ theme }) => mix(0.9,theme.white, theme.black)};
                            }
                        }
                    }
                }
            }
            .btn{
                margin-left: 10px;
                margin-bottom: 10px;
            }
        }
        &-text{
            font-size: 14px;
            p{
                margin: 0;
                padding: 0;
                font-size: 14px;
                color: ${({ theme }) => theme.black};
                margin-bottom: 10px;
            }
        }
        &-img{
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            img{
                max-width: 100%;
                display: block;
                border-radius: 6px;
                margin-bottom: 10px;
            }
        }
    }
`;
