import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { requestPatchUser, requestPostAvatar } from 'actions/session';
import { getUserIdFromToken } from 'services/token';
import { getUrl } from 'utils/url';

import BasicEditableProfile from 'screens/EditableProfile/index';
import injectValidators from 'components/Shared/Forms/injectValidators';

const mapDispatchToProps = (dispatch) => ({
    onSubmit: ({ avatar, ...values }, form, userId) => {
        const { dirty } = form.getState();
        if (dirty) {
            dispatch(requestPatchUser(getUserIdFromToken(), values));
            if (avatar && (avatar instanceof File || avatar instanceof Blob)) {
                dispatch(requestPostAvatar(avatar));
            }
            setTimeout(() => dispatch(push(getUrl('profile', { _user: userId }))), 300);
        }
        return values;
    },
    switchToReadMode: _user => dispatch(push(getUrl('profile', { _user }))),
});

export default compose(
    connect(null, mapDispatchToProps),
    injectValidators
)(BasicEditableProfile);
