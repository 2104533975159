/* eslint-disable require-yield */
import { select, all, call, take, put } from 'redux-saga/effects';

import Logger from 'services/debug/logger';

import desktopNotificationManager from 'services/desktopNotificationManager';
import { getCompanySelector } from 'selectors/company';
import { getUrl } from 'utils/url';
import { hashParameters } from 'const/url';
import { utils, selectors } from '@amplement/backend-connector';
import { MULTICAST } from 'const/feed';
import { getCurrentUserIdSelector } from 'selectors/user';
import { getTabFocusSelector } from 'selectors/session';
import { eventChannel } from 'redux-saga';
import { CONTACT_STATUS } from 'const/user';

const logger = new Logger('saga:pushNotification');

function* roomParser(room = {}, type) {
    logger.log('roomParser: ', type, room);
    const { id, name, isMulticast } = room;
    const company = yield select(getCompanySelector);
    const icon = company?.skin?.shortLogo;
    const title = type === 'missed' ?
        utils.i18n.translate('call.callhistory.status.missedIncoming')
        : utils.i18n.translate('call.callhistory.status.incoming');
    const { firstname, lastname } = room?.members?.find(m => m.isCaller) || {};

    const options = {
        body: !isMulticast && firstname ? `${firstname} ${lastname}`: name,
        tag: `room-${id}`,
        icon,
    };

    const meta = {
        duration: 60,
        url: `${getUrl('dashboard')}#${hashParameters.CALL}`,
        windowName: getUrl('dashboard'),
    };
    // meta.url = `${getUrl('room', { _room: room.id })  }`;
    // meta.windowName = getUrl('room', { _room: room.id });

    return { title, options, meta };
}

function* roomParserMissed(room = {}) {
    return yield call(roomParser, room, 'missed')
}

function* roomParserIncoming(room = {}) {
    return yield call(roomParser, room, 'incoming')
}

function* contactParser(contact = {}) {
    logger.log('contactParser: ', contact);
    const { id, firstname, lastname, title, avatarUri, isInitiator, contactStatus } = contact;
    const company = yield select(getCompanySelector);
    const icon = company?.skin?.shortLogo;
    const notificationTitle = `${firstname} ${lastname} - ${title}`;
    let body = '';
    let duration = 0;

    if (!isInitiator && contactStatus === CONTACT_STATUS.PENDING) {
        body = utils.i18n.translate('pushNotification.contactInvitation.title');
    } else if (isInitiator && contactStatus === CONTACT_STATUS.ACCEPTED) {
        body = utils.i18n.translate('pushNotification.contactInvitationAccepted.title');
        duration = 10;
    }

    const options = {
        body,
        tag: `contact-${id}`,
        icon: avatarUri || icon,
    };

    const meta = {
        duration,
        url: `${getUrl('dashboard')}#${hashParameters.BELL}`,
        windowName: getUrl('dashboard'),
    };

    return { title: notificationTitle, options, meta };
}

function* memberParser(payload = {}) {
    logger.log('memberParser: ', payload);
    const { user: { avatarUri }, _feed, feed } = payload;
    const company = yield select(getCompanySelector);
    const icon = company?.skin?.shortLogo;
    const notificationTitle = utils.i18n.translate('pushNotification.memberInvitation.title');

    const options = {
        body: feed?.name,
        tag: `member-${_feed}`,
        icon: avatarUri || icon,
    };

    const meta = {
        duration: 5,
        url: `${getUrl('dashboard')}#${hashParameters.BELL}`,
        windowName: getUrl('dashboard'),
    };

    return { title: notificationTitle, options, meta };
}

function* feedItemParser(feedItem = {}) {
    logger.log('feedItemParser: ', feedItem, window.name, window.opener);
    const { content, _feed, user: { avatarUri, avatar, firstname, lastname } = {}, metasFile } = feedItem;
    const company = yield select(getCompanySelector);
    const _user = yield select(getCurrentUserIdSelector);
    const feed = yield select(state => selectors.feeds.feedByIdSelector(state, feedItem._feed));
    const feedName = feed?.cast === MULTICAST ? feed?.name : undefined;

    if (feedItem._user === _user) {
        return null;
    }

    const icon = company?.skin?.shortLogo;
    const notificationTitle = feedName ?
        utils.i18n.translate('pushNotification.feedItem.multicast.title', { feedname: feedName })
        : utils.i18n.translate('pushNotification.feedItem.unicast.title', { firstname, lastname })
    const body = content || metasFile?.name;

    if (!body) {
        logger.warn('Skip notification: no content - #1');
        return null;
    }

    const options = {
        body,
        tag: `feed-${_feed}`,
        icon: avatarUri || avatar?.file?.uri || icon,
        data: { _feed },
    };

    const meta = {
        duration: 5,
        url: getUrl('feed', { _feed }),
        windowName: getUrl('dashboard'),
        // windowName: getUrl('feed', { _feed }),
    };

    return { title: notificationTitle, options, meta };
}

function* activityParser(payload = {}) {
    logger.log('activityParser: ', payload);
    const { user: { avatar, avatarUri, firstname, lastname } = {}, content } = payload;
    if ((!firstname && !lastname) || !content) {
        return null;
    }
    const notificationTitle = utils.i18n.translate('user.username', { firstname, lastname });

    const options = {
        body: content,
        tag: `activity`,
        icon: avatarUri || avatar?.file?.uri,
    };

    const meta = {
        duration: 3,
        url: getUrl('activities'),
        windowName: getUrl('dashboard'),
        // windowName: getUrl('activities'),
    };

    return { title: notificationTitle, options, meta };
}

function* notificationManager(payload, parserFunction) {
    const isTabFocus = yield select(getTabFocusSelector);
    logger.log('notificationManager: ', payload, parserFunction, isTabFocus, desktopNotificationManager.isEnabled());

    if (desktopNotificationManager.isEnabled() && !isTabFocus && !payload.silent) {
        try {
            const config = yield call(parserFunction, payload);
            if (!config) {
                return null;
            }

            const channel = eventChannel(emitter => {
                // setTimeout(() => {
                desktopNotificationManager.setEmitter(emitter);
                desktopNotificationManager.buildNotification(config);
                // }, 0)
                return () => {};
            });

            const act = yield take(channel);

            if (act) {
                yield put(act);
            }
        } catch (e) {
            yield call(utils.errorHandler.captureException, e, 'saga:pushNotification:notificationManager');
        }
    }
    return null;
}

function* handleRoomIncoming(action) {
    logger.log('handleRoomIncoming: ', action);
    const { payload } = action || {};
    const [room] = payload || [];

    if (room) {
        yield call(notificationManager, room, roomParserIncoming);
    }
}

function* handlePutNotification(action) {
    logger.log('handlePutNotification: ', action);
    const { category, type, payload, silent } = action?.payload || {};

    if (silent) return;

    if (category === 'room' && type === 'missed') {
        yield call(notificationManager, payload, roomParserMissed);
    }
}

function* handlePostNotification(action) {
    logger.log('handlePostNotification: ', action);
    const { category, type, payload, silent } = action?.payload || {};

    if (silent) return;

    if (category === 'contact' && type === 'post') {
        yield call(notificationManager, payload, contactParser);
    }
    if (category === 'member' && type === 'post') {
        yield call(notificationManager, payload, memberParser);
    }
}

function* handlePostFeedItem(action) {
    logger.log('handlePostFeedItem: ', action);
    const { payload } = action || {};

    yield call(notificationManager, payload, feedItemParser);
}

function* handlePostActivity(action) {
    logger.log('handlePostActivity: ', action);
    const { payload } = action || {};

    yield call(notificationManager, payload, activityParser);
}

function* handleCloseIncomingNotification(action) {
    logger.log('handleCloseIncomingNotification: ', action);

    desktopNotificationManager.close(`room-${action?.payload?._room}`);
}

export default function* root() {
    yield all([
        utils.sagas.takeEvery("WS_ROOM_INCOMING", handleRoomIncoming),
        utils.sagas.takeEvery("WS_PUT_NOTIFICATION", handlePutNotification),
        utils.sagas.takeEvery("WS_POST_FEED_ITEM", handlePostFeedItem),
        utils.sagas.takeEvery("WS_POST_ACTIVITY", handlePostActivity),
        utils.sagas.takeEvery("WS_POST_NOTIFICATION", handlePostNotification),
        utils.sagas.takeEvery("WS_ROOM_TAKEOFF", handleCloseIncomingNotification),
    ]);
}
