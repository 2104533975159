import { connect } from 'react-redux';
import { getStatusById } from 'selectors/presence';

import UserItem from './index';

const mapStateToProps = (state, { id }) => {
    const status = getStatusById(state, id);

    return {
        status,
    };
};

export default connect(mapStateToProps, null)(UserItem);
