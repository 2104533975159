import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import Icon from 'components/Shared/Common/Icon';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import AvatarProfile from 'components/Shared/Common/AvatarProfile';
import StyleListUserItem from './styles';

const UserItem = ({
    intl, avatarUri, fullname,
    isAdmin, isAway, canManage,
    isMember, onKick, title,
    phonenumber, onAddAdministrators,
    onRemoveAdministrators, isMe
}) => {
    const menu = useMemo(() => {
        if (!isMe && isMember && canManage && onKick) {
            const items = [];

            if (isAdmin) {
                items.push({
                    key: 'removeAdmin',
                    label: intl.formatMessage({ id: 'room.member.removeAdmin' }),
                    icon: <Icon iconName="King" />,
                    onClick: onRemoveAdministrators
                });
            } else if (!phonenumber) {
                items.push({
                    key: 'addAdmin',
                    label: intl.formatMessage({ id: 'room.member.addAdmin' }),
                    icon: <Icon iconName="King" />,
                    onClick: onAddAdministrators
                });
            }

            if (phonenumber) {
                items.push({
                    key: 'kick',
                    label: intl.formatMessage({ id: 'room.member.takeOff' }),
                    icon: <Icon iconName="PhoneOff" />,
                    danger: true,
                    onClick: () => onKick(true),
                });
            } else {
                items.push({
                    key: 'kick',
                    label: intl.formatMessage({ id: 'room.member.kick' }),
                    icon: <DeleteOutlined />,
                    danger: true,
                    onClick: onKick
                });
            }

            return { items };
        }

        return null;
    }, [
        intl, isMember, canManage,
        isAdmin, onKick, onAddAdministrators,
        onRemoveAdministrators, phonenumber, isMe
    ]);

    return (
        <StyleListUserItem
            className={classNames(
                { offline: isAway && isMember }
            )}
        >
            <div className="user-wrapper">
                <AvatarProfile
                    src={avatarUri}
                    firstname={fullname || phonenumber}
                    className="avatar"
                />
                <div className="label">
                    <span className="name">{fullname || phonenumber}</span>
                    {(isMember && (isAdmin || isAway)) && (
                        <div className="badge-container">
                            {isAdmin && (<span className="badge admin">{intl.formatMessage({ id: 'room.member.badge.admin' })}</span>)}
                            {isAway && (<span className="badge offline">{intl.formatMessage({ id: 'room.member.badge.notConnected' })}</span>)}
                        </div>
                    )}
                    {!isMember && <div className="title">{title}</div>}
                </div>
            </div>

            {menu && (
                <Dropdown menu={menu} placement="bottomRight">
                    <Button size='md'>
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            )}
        </StyleListUserItem>
    );
};

UserItem.propTypes = {
    onAddAdministrators: PropTypes.func,
    onRemoveAdministrators: PropTypes.func,
    intl: PropTypes.any.isRequired,
    avatarUri: PropTypes.string,
    fullname: PropTypes.string,
    phonenumber: PropTypes.string,
    isAdmin: PropTypes.bool,
    isAway: PropTypes.bool,
    isMember: PropTypes.bool,
    onKick: PropTypes.func,
    canManage: PropTypes.bool,
    title: PropTypes.string,
};

UserItem.defaultProps = {
    onAddAdministrators: () => {},
    onRemoveAdministrators: () => {},
    avatarUri: undefined,
    isAdmin: false,
    isAway: true,
    isMember: false,
    onKick: undefined,
    canManage: false,
    title: undefined,
    fullname: '',
    phonenumber: ''
};

export default injectIntl(UserItem);
