import React, { createContext, useContext, useMemo, useState, memo } from 'react';

const SoundMeterContext = createContext({});

export const useSoundMeter = () => useContext(SoundMeterContext);

export default memo(({ children }: { children: React.ReactNode }) => {
    const [soundMeters, setSoundMeters] = useState({});

    const value = useMemo(() => ({
        soundMeters,
        setSoundMeters,
    }), [soundMeters]);

    return (
        <SoundMeterContext.Provider value={value}>
            {children}
        </SoundMeterContext.Provider>
    )
});
