export const NAVIGATION = {
    ROOMS: 'rooms',
    RECENT_CALLS: 'recentCalls',
    BOOKMARKS: 'bookmarks',
    FEEDS: 'feeds',
    CONTACTS: 'contacts',
    USERS: 'users',
    PHONEBOOK: 'phoneBook',
    NONE: '',
} as const;

export const MAX: number = 100;

export default NAVIGATION;

export type NavigationKeys = typeof NAVIGATION[keyof typeof NAVIGATION];
