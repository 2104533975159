import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Popover, Button } from 'antd';

const { Text } = Typography;

const CollapseError = ({
    title,
    content,
    label,
    textButton
}) => content ? (
    <Popover placement="right" title={title} content={content} trigger="click">
        <Text type="danger">{label}</Text><Button type="link" danger>{textButton}</Button>
    </Popover>
) : label;

CollapseError.propTypes = {
    label: PropTypes.any,
    title: PropTypes.any,
    content: PropTypes.any,
    textButton: PropTypes.any
};

CollapseError.defaultProps = {
    label: undefined,
    title: undefined,
    content: undefined,
    textButton: undefined
};

export default CollapseError;
