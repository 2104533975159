import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import styled from 'styled-components';
import SearchBar from 'components/Shared/Forms/SearchBar';
import Phonebook from 'components/Contacts/Phonebook';
import SimpleSkeleton from 'components/Shared/Contacts/SimpleSkeleton';
import UserItem from 'components/Shared/User/Item';

const SearchContainer = styled.div`
    padding: 10px 0 30px;
`;

export const PhonebookItem = props => {
    const { firstname, lastname } = props;
    let fullname = firstname || '';
    if (lastname && fullname !== '') {
        fullname += ` ${lastname}`;
    } else if (lastname) {
        fullname += lastname;
    }
    return (
        <UserItem
            firstname={fullname}
            lastname=""
            noLink
            avatarSize="sm"
            title={props.phones?.[0].displayNumber}
            size="xs"
            _user={props.id}
        />
    );
};

const formatMessages = intl => () => ({
    searchInputPlaceholder: intl.formatMessage({
        id: 'drawer.search.input.placeholder',
        defaultMessage: 'Votre recherche'
    }),
    searchError: intl.formatMessage({
        id: 'drawer.search.input.errorMessage',
        defaultMessage: 'Votre recherche doit dépasser les 2 caractères.'
    }),
    searchFallBackMessage: intl.formatMessage({
        id: 'rightColumn.contacts.searchFallBackMessage',
        defaultMessage: 'Aucun résultat ne correspond à votre recherche.'
    }),
    placeholderTitle: intl.formatMessage({
        id: 'drawer.search.fallbackMessage.title',
        defaultMessage: 'Tapez dans la barre de recherche'
    })
});

const SearchablePhonebookList = ({ intl, ...rest }) => {
    const i18n = formatMessages(intl)();
    const [query, setQuery] = useState();
    const handleClear = useCallback(() => setQuery(''), [setQuery]);

    return (
        <>
            <SearchContainer>
                <SearchBar
                    onSearch={setQuery}
                    onClear={handleClear}
                    debounceDuration={500}
                    placeholder={i18n.searchInputPlaceholder}
                    autoFocus
                />
            </SearchContainer>
            <Phonebook
                query={query}
                displayMode="list"
                flat
                ItemSkeleton={SimpleSkeleton}
                {...rest}
            />
        </>
    );
};

SearchablePhonebookList.propTypes = {
    intl: PropTypes.any.isRequired
};

export default injectIntl(SearchablePhonebookList);
