import React, { memo, useCallback } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getFileSource } from 'utils/file';
import { MetasFile } from 'hooks/useSearch';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    width: 70px;
    justify-content: space-between;
    top: 10px;
    right: 10px;
`;

const download = (uri, name) => {
    const a = document.createElement('a');
    a.href = getFileSource(uri, true);
    a.download = name;
    a.click();
    a.remove();
}

const Actions = ({ uri, name, extension }: MetasFile): JSX.Element => {
    const onDownload = useCallback(() => download(uri, name), [uri, name]);

    return (
        <Wrapper>
            {extension !== ".aac" && <Button onClick={onDownload} icon={<DownloadOutlined />} />}
        </Wrapper>
    );
}

Actions.propTypes = {
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
};

export default memo(Actions);
