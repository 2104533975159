import { connect } from 'react-redux';

import { getCurrentUserSelector, getSoundsStatusSelector } from 'selectors/user';
import { requestPatchUser } from 'actions/session';
import { getUserIdFromToken } from 'services/token';
import { getCompanyAvailableLanguagesSelector } from 'selectors/company';
import Form from './index';

const mapStateToProps = (state) => ({
    initialValues: {
        language: (getCurrentUserSelector(state) || {}).settings?.language,
        sounds: (getSoundsStatusSelector(state) || {}).global
    },
    availableLanguages: getCompanyAvailableLanguagesSelector(state)
        .map(x => ({ label: x.value, value: x.key }))
});

const mapDispatchToProps = dispatch => ({
    onSubmit: (values) => {
        dispatch(
            requestPatchUser(getUserIdFromToken(), {
                settings: {
                    ...values,
                    sounds: {
                        global: values.sounds
                    }
                }
            })
        );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
