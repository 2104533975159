import { rgba } from 'polished';
import styled from 'styled-components';

export const StyleDialog = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 111;

    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    opacity: ${props => props.isOpen ? 1 : 0};
    transition: all 250ms ease;

    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.70)};
    }

    .dialog{
        &-content{
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height: 100vh;
            font-size: 15px;
            line-height: 1.46668;
            font-weight: 400;
            .fileviewer-content{
                flex: 1 1 0;
                overflow: hidden;
                position: relative;
                .fileviewer{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    .video-background {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        iframe{
                            object-fit: contain;
                        }
                    }
                    video,
                    iframe{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        border: none;
                        height: 100%;
                    }
                    video{
                        object-fit: contain;
                    }
                }
                img{
                    transition: transform 200ms ease 0s;
                    object-fit: contain;
                    max-height: 100%;
                    flex-shrink: 0;
                    max-width: 100%;
                    user-select: none;
                }
            }
        }
        &-wrapper{
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 0;
            min-width: 0;
            width: 98%;
            height: 98%;
            margin: 0;
            border-radius: 4px;
            background-color: ${({ theme }) => theme.lighten};
            transition: all 250ms ease;
            transform: translateY(${props => props.isOpen ? '0' : '20px'});
        }
        &-header,
        &-footer{
            .icon-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon{
                    svg{
                        width: 15px;
                        height: 15px;
                        ${({ theme }) => rgba(theme.black, 0.65)};
                    }
                }
                .ant-slider{
                    width: 100px;
                }
            }
            padding: 12px 7px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-radius: 4px 4px 0 0;
            height: 50px;
            background-color: ${({ theme }) => theme.white};
            box-shadow: 0 1px 0 ${({ theme }) => rgba(theme.black, 0.15)};
            min-height: 0;
            min-width: 0;
            .actions{
                display: flex;
                flex-direction: row;
                align-items: center;
                .btn{
                    border-radius: 4px;
                    &:hover{
                        background-color: ${({ theme }) => theme.secondaryActive};
                        .icon{
                            svg{
                                color: ${({ theme }) => theme.black};
                            }
                        }
                    }
                    .icon{
                        svg{
                            color: ${({ theme }) => rgba(theme.black, 0.6)};
                        }
                    }
                }
                .separator{
                    width: 1px;
                    height: 30px;
                    margin: 0 5px;
                    background-color: ${({ theme }) => rgba(theme.black, 0.1)};
                }
            }
            .metas{
                display: flex;
                flex-direction: row;
                align-items: center;
                min-width: 0;
                min-height: 0;
                .ant-avatar,
                .avatar{
                    margin-right: 10px;
                }
                .text{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    min-width: 0;
                    .user{
                        font-weight: bold;
                        font-size: 15px;
                    }
                    .meta{
                        font-size: 13px;
                        color: ${({ theme }) => rgba(theme.black, 0.7)};
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        &-footer{
            box-shadow: 0 -1px 0 ${({ theme }) => rgba(theme.black, 0.15)};
            border-radius: 0 0 4px 4px;
        }
    }
`;
