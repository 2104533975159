import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const AiIcon = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-ai" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero">
                <g id="Group" transform="translate(21.000000, 60.000000)">
                    <path
                        d="M25.3,37 L22.8,28.8 L10.3,28.8 L7.8,37 L0,37 L12.1,2.6 L21,2.6 L33.1,37 L25.3,37 Z M21.1,22.8 C18.8,15.4 17.5,11.2 17.2,10.3 C16.9,9.4 16.7,8.6 16.6,8 C16.1,10 14.6,14.9 12.2,22.8 L21.1,22.8 Z"
                        id="Shape"
                    />
                    <path
                        d="M36.6,4 C36.6,1.7 37.9,0.5 40.5,0.5 C43.1,0.5 44.4,1.7 44.4,4 C44.4,5.1 44.1,6 43.4,6.6 C42.7,7.2 41.8,7.5 40.5,7.5 C37.9,7.5 36.6,6.4 36.6,4 Z M44,37 L36.9,37 L36.9,10.8 L44,10.8 L44,37 Z"
                        id="Shape"
                    />
                </g>
                {withBorder && <Border />}
            </g>
        </g>
    </svg>
);

AiIcon.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

AiIcon.defaultProps = {
    color: '#FF9A30',
    withBorder: true
};

export default AiIcon;
