import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import { getCompanyNameSelector, getIsPremiumCompanySelector } from 'selectors/company';
import config from 'config';
import Block from './Block';

const PremiumBlock = ({ intl, active, companyName, hasIgnore, hasCTA, descriptionKey, descriptionValues }) => {
    const handleSeeMore = useCallback(() => {}, []); // what do we do ? waiting for answer

    if (!active) return null;

    return (
        <Block
            bgColor="#ffc540"
            title={(
                <>
                    {intl.formatMessage({ id: 'premium.tryPremium' }, { companyName })}
                    <span>{intl.formatMessage({ id: 'premium.beta' })}</span>
                </>)}
            description={intl.formatMessage({ id: descriptionKey }, { companyName, salesNumber: config.salesNumber, ...descriptionValues })}
            hasIgnore={hasIgnore}
            onClick={handleSeeMore}
            hasCTA={hasCTA}
            CTALabel={intl.formatMessage({ id: 'premium.cta.tellMeMore' })}
        />
    );
};

PremiumBlock.propTypes = {
    intl: PropTypes.any.isRequired,
    companyName: PropTypes.string.isRequired,
    hasIgnore: PropTypes.bool,
    hasCTA: PropTypes.bool,
    descriptionKey: PropTypes.string.isRequired,
    descriptionValues: PropTypes.object,
    active: PropTypes.bool
};

PremiumBlock.defaultProps = {
    hasIgnore: true,
    hasCTA: false,
    descriptionValues: {},
    active: true
};

export const Component = injectIntl(PremiumBlock);

const mapStateToProps = (state) => ({
    companyName: getCompanyNameSelector(state),
    active: !getIsPremiumCompanySelector(state)
});

export default connect(
    mapStateToProps,
    null
)(Component);
