import { connect } from 'react-redux';
import { selectors, actions } from '@amplement/backend-connector';

import { getCurrentUserIdSelector } from 'selectors/user';
import { MODAL_KICK_ROOM_USER } from 'types/modal';
import { openModal } from 'actions/modal';
import Component from './index';

const mapStateToProps = (state, { _user, _room }) => {
    const member = selectors.rooms.getRoomMemberByUserIdSelector(state, _room, _user);
    const aways = selectors.rooms.getAwayRoomMemberIdsByRoomIdSelector(state, _room);
    const currentUser = getCurrentUserIdSelector(state);

    const {
        avatarUri,
        fullname,
        isAdmin,
        phonenumber,
        title,
    } = member || {};

    return {
        avatarUri,
        fullname,
        isAdmin,
        isAway: aways?.indexOf(member?._user) !== -1,
        _user: currentUser,
        isMe: currentUser === member?._user,
        _memUser: member?._user,
        isMember: true,
        title,
        phonenumber
    }
};

const mapDispatchToStore = (dispatch, { _room, _user }) => ({
    onKick: (isPhoneNumber) => dispatch(openModal(MODAL_KICK_ROOM_USER, { _room, _user, isPhoneNumber })),
    onAddAdministrators: () => dispatch(actions.rooms.requestPatchRoom(_room, { _administratorsToAdd: [_user] })),
    onRemoveAdministrators: () => dispatch(actions.rooms.requestPatchRoom(_room, { _administratorsToRemove: [_user] })),
});

export default connect(
    mapStateToProps,
    mapDispatchToStore
)(Component);
