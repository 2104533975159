import { connect } from 'react-redux';

import { getCurrentUserIdSelector } from 'selectors/user';
import  { actions, selectors } from '@amplement/backend-connector';
import activities from './index';

const mapStateToProps = state => ({
    activities: selectors.activities.getActivities(state),
    isLoading: !selectors.activities.getLoadedActivities(state),
    _currentUser: getCurrentUserIdSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getActivities: () => dispatch(actions.activities.getNewPageOfActivities()),
    deleteActivity: (_activity) => dispatch(actions.activities.deleteActivity(_activity)),
    editActivity: (_activity, content) => dispatch(actions.activities.editActivity(_activity, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(activities);
