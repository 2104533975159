import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Placeholder from 'components/Shared/Common/Placeholder';
import Button from 'components/Shared/Common/Button';

const DrawerPlaceholder = ({ intl, addAccount }) => (
    <Placeholder
        className="centered"
        name="placeholderSIP"
        title={intl.formatMessage({
            id: 'drawer.sip.placeholder.title',
            defaultMessage: 'In order to make VOIP calls, you must first add a SIP account.'
        })}
        subTitle={(
            <div className="margin-top-20">
                <Button
                    color="primary"
                    onClick={addAccount}
                >
                    {intl.formatMessage({
                        id: 'drawer.sip.button',
                        defaultMessage: 'Add my SIP account'
                    })}
                </Button>
            </div>
        )}
    />
);

DrawerPlaceholder.propTypes = {
    addAccount: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

export default injectIntl(DrawerPlaceholder);
