import Logger from 'services/debug/logger';
import { utils } from '@amplement/backend-connector';
import { push } from 'redux-first-history';
import { DEFAULT_LOGO } from 'const/company';
import { getIsExpired, ONE_MONTH, setExpiration } from 'utils/date';

const logger = new Logger('service:desktopNotificationManager');

class DesktopNotificationManager {
    #options = {};

    #emitter = null;

    constructor(options) {
        this.#options = {
            lang: 'en',
            // badge: null, // uncomment at your own risk, it will fetch url like /feed/null on each notifications
            body: 'Hi',
            tag: 'default-notif',
            icon: DEFAULT_LOGO,
            image: '',
            data: '',
            vibrate: true, // todo
            renotify: true, // todo
            requireInteraction: true, // todo
            actions: [], // todo
            silent: false, // todo
            ...options
        };
    }

    // Function to check whether browser supports the promise version of requestPermission()
    // Safari only supports the old callback-based version
    #checkNotificationPromise = () => {
        try {
            Notification.requestPermission().then();
        } catch(e) {
            return false;
        }

        return true;
    }

    // Emit the Notificaiton
    #push = (title, options) => {
        if (typeof title === 'string' && typeof options === 'object') {
            logger.log('push', title, options);
            const ntfOptions = { ...this.#options, ...options };
            if (!ntfOptions.icon) {
                // Ensure we always have an icon even if supressed in options
                ntfOptions.icon = this.#options.icon;
            }
            return new Notification(title, ntfOptions);
        }        
        if (typeof title === 'object') {
            const { title: notificationTitle, ...opts } = title;
            logger.log('push', notificationTitle, opts);
            const ntfOptions = { ...this.#options, ...opts };
            if (!ntfOptions.icon) {
                // Ensure we always have an icon even if supressed in options
                ntfOptions.icon = this.#options.icon;
            }
            return new Notification(notificationTitle, { ...this.#options, ...opts });
        }
        logger.log('push', title, options);
        throw new Error('Notification: bad configuration');
    };

    askNotificationPermission = () => new Promise((resolve, reject) => {
        // function to actually ask the permissions
        const handlePermission = (permission) => {
            // Whatever the user answers, we make sure Chrome stores the information
            if(!('permission' in Notification)) {
                Notification.permission = permission;
            }

            // set the button to shown or hidden, depending on what the user answers
            if(this.getPermission() === 'denied' || this.getPermission() === 'default') {
                logger.warn('Notifications are disabled.');
            } else {
                logger.log('Notifications are enabled');
            }
            resolve(permission);
        }

        // Let's check if the browser supports notifications
        if (!this.isSupported()) {
            logger.log('This browser does not support notifications.');
            reject(new Error('This browser does not support notifications.'));
        } else if (this.#checkNotificationPromise()) {
            Notification.requestPermission().then(handlePermission);
        } else {
            Notification.requestPermission(handlePermission);
        }
    });

    ignorePromptUnitDelay = () => setExpiration('desktopNotificationBlockSkippedAt');

    shouldAskPermission = () => getIsExpired('desktopNotificationBlockSkippedAt', ONE_MONTH);

    isSupported = () => !!("Notification" in window);

    notifications = {};

    #isEnabled = true;

    setEnable = (isEnabled) => { this.#isEnabled = isEnabled; }

    getPermission = () => this.isSupported() ? Notification?.permission : undefined;

    isEnabled = () => this.#isEnabled && this.getPermission() === 'granted' && window.opener === null && !window.name;

    // Set default notification icon url
    setIcon = icon => { this.#options.icon = icon; }

    // Set default notification image url
    setImage = image => { this.#options.image = image; }

    // Set callback function for notifications onClick
    setEmitter = emitter => { this.#emitter = emitter; }

    // Get callback fucntion for notifications onClick
    getEmitter = () => this.#emitter;

    close = (id) => this.notifications[id]?.close?.();

    // Build and emit the notification.
    buildNotification = (config) => {
        logger.log('buildNotification: ', config);

        if (!config?.options || !config?.title) {
            logger.warn('buildNotification:No valid configuration found.', config);
            return;
        }

        const { options: { tag: id }, options, title, meta } = config;
        const instance = this.#push(title, options);
        let timeout;

        if (meta?.duration) {
            timeout = setTimeout(() => instance?.close?.(), meta.duration * 1000);
        }

        const cleanNotification = (event) => {
            event.preventDefault();
            if (timeout) {
                clearTimeout(timeout);
            }
            if (instance) delete this.notifications[id];
        }

        instance.onclick = (event) => {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab

            if (meta?.url) {
                this.#emitter?.(push(meta.url));
                window.focus?.();
            }

            instance?.close?.();
            cleanNotification(event);
        }

        instance.onclose = cleanNotification;
        instance.onerror = cleanNotification;

        this.notifications[id] = instance;
    }
}

export default utils.debug.addDebugAttribute(new DesktopNotificationManager(), 'DesktopNotificationManager');
