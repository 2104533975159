import { put, select } from 'redux-saga/effects';
import { setFatalError } from 'actions/session';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import  { selectors } from '@amplement/backend-connector';
import { AxiosError, isAxiosError } from 'axios';

type ErrorSettings = {
    buttonType?: string
    code?: string | number;
    content?: string;
    error?: unknown;
}

type ErrorItem = {
    key: string ;
    settings?: ErrorSettings; 
}

type ErrorCollection = {
    [key: string | number]: ErrorItem
}

export type JoiError = {
    joi: Array<{
        _original: unknown;
        details: Array<{
            message: string;
            path: unknown;
            type: string;
            context: unknown
        }>
    }>;
    statusCode: string;
    message: string;
} & Error;

type CustomError = (JoiError | AxiosError | Error) & {
    status?: number // from where it can be present ? i don't know
}

const _ErrorConfig = {
    401: { key: 'error.unauthorized', settings: { buttonType: BUTTON_TYPE.CONNECT } },
    403: { key: 'error.forbidden', settings: { buttonType: BUTTON_TYPE.GOHOME } },
    404: { key: 'error.notFound', settings: { buttonType: BUTTON_TYPE.GOHOME } },
    415: { key: 'error.mediaTypeNotSupported', settings: { buttonType: BUTTON_TYPE.CLOSE } },
    416: { key: 'error.notFound' },
    422: { key: 'error.badRequest', settings: { buttonType: BUTTON_TYPE.GOHOME } },
    502: { key: 'error.underMaintenance' },
    504: { key: 'error.timeout' },

    500: { key: 'call.error.internal' },
    501: { key: 'call.error.notImplemented' },
    // 502: { key: 'call.error.badGateway' },
    503: { key: 'call.error.serviceUnavailable' },
    505: { key: 'call.error.versionNotSupported' },
    513: { key: 'call.error.messageTooLarge' },

    904: { key: 'call.error.noMatchingChallenge' },

    'timeout of': { key: 'error.timeout' },
    'Network Error': { key: 'error.networkError' },
    'default': { key: 'error.default' },
};

// build default config
Object.keys(_ErrorConfig).forEach((key) => {
    _ErrorConfig[key].settings = _ErrorConfig[key].settings || {};

    if (!_ErrorConfig[key].settings.code && key && !Number.isNaN(Number.parseInt(key, 10))) {
        _ErrorConfig[key].settings.code = key;
    }

    if (!_ErrorConfig[key].settings.buttonType) {
        _ErrorConfig[key].settings.buttonType = BUTTON_TYPE.RELOAD;
    }
})

export const HTTP_ERRORS_TYPES: ErrorCollection = _ErrorConfig;

const ReError = /[0-9]{3}|Network Error|timeout of/;

export function* handleFatalErrors(error: CustomError) {
    if (error?.message) {
        let id: string | undefined | number = error.message; // default Error

        if (!id && isAxiosError(error)) {
            id = error?.response?.status;
        }

        if (!id) {
            id = error.status;
        }
        // const id = error.message || (error.response && error.response.status) || error.status;
        const match = `${id}`.match?.(ReError);
        let code  = match ? match[0] : 'default';

        if (code === 'Network Error') {
            const isOnline = yield select(selectors.network.getIsOnlineSelector);
            code = isOnline ? '502' : code;
        }

        const matchedError = HTTP_ERRORS_TYPES[code] || HTTP_ERRORS_TYPES.default;

        matchedError.settings = matchedError.settings || {};
        matchedError.settings.error = error;

        yield put(setFatalError(matchedError.key, matchedError.settings));
    }
}
