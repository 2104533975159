import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Shared/Common/Button';
import Action from 'components/Shared/Common/Action';

const ThumbItemLayout = ({ children, id, isCurrent, onRemove, onClick, error }) => {
    const handleRemove = useCallback((e) => {
        e.preventDefault()
        onRemove(id);
        return false;
    }, [onRemove]);

    return (
        <Action 
            className={
                classNames(
                    {
                        error,
                        active: !error && isCurrent
                    }, 
                    'thumbs-item'
                )} 
            onClick={onClick}
        >
            {onRemove && (
                <div className="remove">
                    <Button size="xs" iconName="X" onClick={handleRemove} />
                </div>
            )}
            <span className="overlay" />
            {children}
            {/* {id && (
                <h3>{id.substr(0,6)}</h3>
            )} */}
        </Action>
    );
}

ThumbItemLayout.propTypes = {
    children: PropTypes.any.isRequired,
    id: PropTypes.string,
    onRemove: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    isCurrent: PropTypes.bool,
    error: PropTypes.bool
};

ThumbItemLayout.defaultProps = {
    isCurrent: false,
    onRemove: undefined,
    id: undefined,
    error: false,
};


export default memo(ThumbItemLayout);
