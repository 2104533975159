export const ESCAPE = 27;
export const SPACE = 32;
export const ENTER = 13;
export const UP_ARROW = 38;
export const LEFT_ARROW = 37;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 39;

export default {
    ESCAPE,
    SPACE,
    ENTER,
    UP_ARROW,
    LEFT_ARROW,
    RIGHT_ARROW,
    DOWN_ARROW
};
