import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_AVATAR } from 'const/user';
import { StyledAvatar, StyledVoicePulseAvatar } from 'components/Shared/style';
import classNames from 'classnames';
import Avatar from '../User/Avatar';

const PULSAR_DURATION_ANIMATION = 1400;

const AvatarProfile = ({
    src, firstname, lastname, className, children, size, strengthRate
}) => {

    const [isPulsarActive, setIsPulsarActive] = useState(false);

    useEffect(() => {
        let timeoutId;

        const startAnimation = () => {
            setIsPulsarActive(true);
            timeoutId = setTimeout(() => {
                setIsPulsarActive(false);
            }, PULSAR_DURATION_ANIMATION);
        };
    
        if (strengthRate >= 1 && !isPulsarActive) {
            startAnimation();
        }
    
        const interval = setInterval(() => {
            if (strengthRate >= 1 && !isPulsarActive) {
                startAnimation();
            }
        }, PULSAR_DURATION_ANIMATION);
    
        return () => {
            clearInterval(interval);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [strengthRate, isPulsarActive]);

    return (
        <StyledAvatar className={className}>
            <div className="avatar-container">
                <StyledVoicePulseAvatar
                    className={classNames({ 'start-pulsar': isPulsarActive })}
                    pulsarDuration={PULSAR_DURATION_ANIMATION}
                    strengthRate={strengthRate}
                />
                <Avatar
                    src={src}
                    alt={`${firstname} ${lastname}`}
                    className="avatar-animated"
                    hasStatus={false}
                    size={size}
                />
                {children}
            </div>
        </StyledAvatar>
    );
};

export const avatarProfileSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxxl', 'xxxxl'];
AvatarProfile.propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.oneOf(avatarProfileSizes),
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    strengthRate: PropTypes.number
};

AvatarProfile.defaultProps = {
    src: DEFAULT_AVATAR,
    size: undefined,
    firstname: '',
    lastname: '',
    className: undefined,
    children: '',
    strengthRate: 0
};

export default memo(AvatarProfile);
