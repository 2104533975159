import { constants } from "@amplement/backend-connector";

export const NOTIFICATION_CATEGORIES = {
    ...constants.notifications.VIRTUAL_CATEGORIES,
    ...constants.notifications.CATEGORIES
};

export const NOTIFICATION_ROOM_TYPES = {
    MISSEDBUTLIVE: 'missed-but-live',
    MISSED: 'missed',
    TAKEOFF: 'takeoff',
    HANGUP: 'hangup',
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
    FORWARDEDAUTOMATICALLY: 'forwarded-automatically',
    FORWARDEDMANUALLY: 'forwarded-manually',
};
