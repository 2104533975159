import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { getCurrentUserIdSelector } from 'selectors/user';
import { getStatusById } from 'selectors/presence';
import { getCompanyIsPublic } from 'selectors/company';

import Profile from './index';

const mapStateToProps = (state, { match: { params } }) => {
    const _me = getCurrentUserIdSelector(state);
    const _user = params._user || _me;
    const status = getStatusById(state, _user);

    return {
        _me,
        isPublic: getCompanyIsPublic(state),
        status,
        _user
    };
};

export default compose(
    connect(mapStateToProps, null),
    injectIntl
)(Profile);
