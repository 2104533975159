import { keyframes, css } from 'styled-components';

const backgroundLightColor = '#f6f7f8';
const backgroundDarkColor = '#edeef1';

const placeholderSkeleton = keyframes`
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
`;

const skeletonItem = css`
    -webkit-animation: ${placeholderSkeleton} 1s linear infinite forwards;
    animation: ${placeholderSkeleton} 1s linear infinite forwards;
    background: ${backgroundLightColor};
    background-image: -webkit-gradient(linear, left center, right center, from(${backgroundLightColor}), color-stop(.2, ${backgroundDarkColor}), color-stop(.4, ${backgroundLightColor}), to(${backgroundLightColor}));
    background-image: -webkit-linear-gradient(left, ${backgroundLightColor} 0%, ${backgroundDarkColor} 20%, ${backgroundLightColor} 40%, ${backgroundLightColor} 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    text-indent: -999999px;
`;

export default skeletonItem;
