export const authorizedLanguages = {
    fr: 'Français',
    en: 'English',
    de: 'Deutsch',
    es: 'Español',
    vi: 'tiếng Việt',
    ru: 'русский',
    th: 'ไทย',
    si: 'Slovenščina'
};
