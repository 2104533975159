import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFormState } from 'react-final-form';
import { feedItemCreateForm } from 'types/form';
import { LabelTextArea } from 'components/Shared/Forms';
import { actions, selectors } from '@amplement/backend-connector';
import Button from 'components/Shared/Common/Button';
import { Tooltip } from 'antd';

import { UP_ARROW, ESCAPE } from 'const/eventKey';
import Action from 'components/Shared/Common/Action';
import Icon from 'components/Shared/Common/Icon';
import classNames from 'classnames';
import isEqual from 'react-fast-compare';
import EmojiPicker from './EmojiPicker';

const setFocus = (ref, cursor = 'end') => {
    const focus = ref.current?.focus;

    if (focus) {
        focus({ cursor });
    }
};

const LabelFeedItemTextArea = (props) => {
    const {
        intl,
        input,
        onPressEnter,
        validate,
        setInput,
        onTyping,
        onChange,
        form,
        lastFeedItem,
        _feed,
        hasEmoji,
        replyComment,
        onReplyFeedItem,
        ...rest
    } = props;
    const { values: {  _feedItem } } = useFormState({ name: feedItemCreateForm });
    const inputRef = useRef();
    const [isEmojiPickerEnabled, setIsEmojiPickerEnabled] = useState();
    const { input: { value } = {}, meta } = props || {};

    const handleChange = useCallback(content => {
        setInput(content, _feedItem);
        if(content?.length > 0){
            onTyping();
        }
        input.onChange(content);
    }, [setInput, onTyping, input]);

    const handleStopReply = useCallback(() => onReplyFeedItem(null, ''), [onReplyFeedItem]);

    const setEdit = useCallback(() => {
        if (lastFeedItem && !lastFeedItem.specialType && !lastFeedItem.metasBot) {
            setInput(lastFeedItem.content, lastFeedItem.id);
            form.change('_feedItem', lastFeedItem.id);
            input.onChange(lastFeedItem.content);
            setTimeout(() => setFocus(inputRef), 10);
        }
    }, [setInput, lastFeedItem, form, inputRef]);

    const unsetEdit = useCallback(() => {
        setInput(undefined, undefined);
        form.change('_feedItem', undefined);
        input.onChange(undefined);
    }, [setInput, _feedItem, form]);

    const handleKeyDown = useCallback(({ keyCode }) => {
        if (keyCode === UP_ARROW && !value.length && !replyComment?._feedItem) {
            setEdit();
        } else if (keyCode === ESCAPE) {
            if (isEmojiPickerEnabled) {
                setIsEmojiPickerEnabled(false);
            } else {
                handleStopReply();
                unsetEdit();
            }
        }
    }, [unsetEdit, setEdit, value, isEmojiPickerEnabled, setIsEmojiPickerEnabled, handleStopReply, replyComment]);

    const handleEmojiPickerEnable = useCallback(() => setIsEmojiPickerEnabled(!isEmojiPickerEnabled),
        [setIsEmojiPickerEnabled, isEmojiPickerEnabled]);

    const handleEmojiSelect = useCallback((e) => {
        setIsEmojiPickerEnabled(false);
        const { selectionStart, selectionEnd } = inputRef.current.resizableTextArea.textArea;
        const start = value.slice(0, selectionStart);
        const end = value.slice(selectionEnd);
        const newVal = `${start}${e.native}${end}`;
        handleChange(newVal);

        const emojiLength = e.native.length;
        const newPosition = selectionStart + emojiLength;

        setTimeout(() => {
            inputRef.current.resizableTextArea.textArea.focus();
            inputRef.current.resizableTextArea.textArea.setSelectionRange(newPosition, newPosition);
        }, 0);
    }, [setIsEmojiPickerEnabled, value, inputRef, handleChange]);

    useEffect(() => setTimeout(() => setFocus(inputRef), 10), [_feed, inputRef]);

    useEffect(() => {
        if (replyComment) {
            unsetEdit();
            setFocus(inputRef);
        }
    }, [replyComment]);

    return (
        <>
            <div className={classNames('textarea', { error: meta?.invalid })}>
                <LabelTextArea
                    {...rest}
                    ref={inputRef}
                    className="feed-input"
                    input={{ ...input, onChange:handleChange, onKeyDown: handleKeyDown }}
                    onPressEnter={onPressEnter}
                    autoSize={{ minRows: 1, maxRows: 8 }}
                    // onResize={setFocus}
                    // allowClear
                />
                {hasEmoji && (
                    <div className="icons">
                        <Tooltip
                            trigger={['click']}
                            open={isEmojiPickerEnabled}
                            title={<EmojiPicker onSelect={handleEmojiSelect} />}
                            autoAdjustOverflow
                            placement="topRight"
                            arrow={false}
                            overlayStyle={{
                                maxWidth: 352
                            }}
                            overlayInnerStyle={{
                                marginBottom: 15,
                                padding: 0,
                                marginRight: -10,
                                boxShadow: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Action
                                className={classNames('item', { active: isEmojiPickerEnabled })}
                                onClick={handleEmojiPickerEnable}
                                onKeyDown={handleKeyDown} 
                            >
                                <Icon iconName="Smile" />
                            </Action>
                        </Tooltip>
                    </div>
                )}

            </div>
            {(_feedItem || replyComment?._feedItem) && (
                <Button
                    iconName="X"
                    onClick={_feedItem ? unsetEdit : handleStopReply}
                    color="transparency"
                    className="close-edit"
                    tooltipPosition="left"
                    tooltipText={_feedItem
                        ? intl.formatMessage({ id: 'feedItem.edit.cancel' })
                        : intl.formatMessage({ id: 'global.button.cancel' })
                    }
                />
            )}
        </>
    )
}

LabelFeedItemTextArea.propTypes = {
    onReplyFeedItem: PropTypes.func,
};

LabelFeedItemTextArea.defaultProps = {
    onReplyFeedItem: () => {},
};

const mapStateToProps = (state, { _feed }) => ({
    lastFeedItem: selectors.feedItems.getLastFeedItemPostedByCurrentUserByFeedSelector(state, _feed)
});

const mapDispatchToProps = (dispatch, { _feed }) => ({
    onTyping: () => dispatch(actions.feeds.typing(_feed)),
    setInput: (content, _feedItem) => dispatch(actions.feeds.setFeedInput(_feed, content, _feedItem)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
)(memo(LabelFeedItemTextArea, (prevProps, nextProps) => (
    prevProps.input?.value === nextProps.input?.value
    && prevProps._feed === nextProps._feed
    && prevProps._feedItem === nextProps._feedItem
    && prevProps.hasEmoji === nextProps.hasEmoji
    && isEqual(prevProps.lastFeedItem, nextProps.lastFeedItem)
    && prevProps?.replyComment?._feedItem === nextProps?.replyComment?._feedItem
)));
