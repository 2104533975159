import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import skeletonItem from 'common/styles/skeleton';
import Actions from 'components/Shared/Forms/Actions';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: 0;
    min-height: 0;
    width: 100%;
    flex: 1;
`;

const StyledActionsCSS = css`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn{
        margin-left: 20px;
    }
`;
export const StyledActions = styled.div`
    ${StyledActionsCSS
}`;
export const StyledActionsComponent = styled(Actions)`
    ${StyledActionsCSS}
`;

const StyledProfileContentCSS = css`
    margin-top: 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
`;

export const StyledProfileContent = styled.div`
    ${StyledProfileContentCSS}
`;

export const StyledProfileContentForm = styled.form`
    ${StyledProfileContentCSS};
`;

export const StyledProfileItem = styled.div`
    margin-bottom: 40px;
    >.text{
        font-size: 14px;
        color: ${({ theme }) => theme.black};
        white-space: pre-line;
        textarea{
            white-space: pre-line;
            min-height: 100px;
        }
        &.loading{
            div, p{
                position: relative;
                color: ${({ theme }) => theme.white};
                height: 6px;
                &:nth-child(2){
                    &::before{
                        width: 60%;
                    }
                }
                &:nth-child(3){
                    &::before{
                        width: 80%;
                    }
                }
                &:nth-child(4){
                    &::before{
                        width: 50%;
                    }
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0px;
                    margin: 0 5px;
                    ${skeletonItem}
                    z-index: 2;
                    height: 6px;
                    width: calc(~'100% - 10px');
                    border-radius: 30px;
                }
            }
        }
    }
}
`;

export const StyledProfileList = styled.div`
    display: flex;
    flex-direction: column;
    .item{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;
        .icon{
            width: 50px;
            height: 50px;
            background-color: ${({ theme }) => theme.secondaryActive};
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin-right: 20px;
            svg{
                width: 24px;
                height: 24px;
            }
        }
    }
`;

export const StyledProfileTitle = styled.div`
    font-size: 16px;
    position: relative;
    color: ${({ theme }) => theme.black};
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background-color: ${props => props.theme.mainColor2};
    }
`;

export const StyledProfileHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    >.avatar{
        .s-status{
            z-index: 1;
        }
    }
    &.loading{
        .profile-header-text{
            .name,
            .text{
                position: relative;
                color: ${({ theme }) => theme.white};
                &::before{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    ${skeletonItem}
                    z-index: 2;
                    height: 6px;
                    width: 40px;
                    border-radius: 30px;
                }
            }
            .text{
                &::before{
                    width: 80%;
                }
            }
        }
        .avatar{
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                ${skeletonItem}
                z-index: 2;
                border-radius: 50%;
                height: 100%;
                width: 100%;
            }
            .s-status,
            img{
                display: none;
            }
        }
    }
    .action{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        >div, >a{
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .wrapper{
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
    }
    .break{
        display: flex;
        flex-direction: column;
    }
    .text{
        white-space: nowrap;
        flex: 1;
        flex-shrink: 0;
        min-height: 0;
        min-width: 0;
        .name{
            font-size: 16px;
            color: ${({ theme }) => theme.black};
            font-weight: 700;
            white-space: nowrap;
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .firstname{
                margin-right: 5px;
            }
        }
        >.text{
            font-size: 16px;
            color: ${({ theme }) => rgba(theme.black, .6)};
            font-weight: normal;
            white-space: nowrap;
        }
    }
    .avatar{
        margin-right: 20px;
    }
}
`;
