import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FeedIcon from 'components/Shared/Common/FeedIcon';
import { StyledFeedHeaderText } from 'components/Shared/style';

const FeedHeaderMulticastIdentity = ({ name, color, description }) => (
    <>
        <FeedIcon name={name} size="md" color={color} />
        <StyledFeedHeaderText className="ui-text">
            <span className="name">{name}</span>
            <span className="subtitle">{description}</span>
        </StyledFeedHeaderText>
    </>
);

FeedHeaderMulticastIdentity.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default memo(FeedHeaderMulticastIdentity);
