import React, { PureComponent } from 'react';

import { injectIntl } from 'react-intl';
import Validators from 'utils/formValidator';

const injectValidators = ComponentToWrap => class extends PureComponent {
    constructor(props) {
        super(props);
        this.validators = { ...Validators(this.props.intl) };
    }

    render() {
        return (
            <ComponentToWrap
                {...this.props}
                validators={this.validators}
            />
        );
    }
};

export default ComponentToWrap => injectIntl(injectValidators(ComponentToWrap));
