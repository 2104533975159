import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import Icon from 'components/Shared/Common/Icon';

import fallbackImageSrc from 'common/images/pattern-file-link@2x.png';
import { getDomain } from 'utils/url';
import { injectIntl } from 'react-intl';
import Image from 'components/Shared/Common/Image';
import { Tooltip } from 'antd';
import Link from 'components/Shared/Common/Link';
import classNames from 'classnames';
import styled from 'styled-components';
import { rgba } from 'polished';

const StyledLinkWrapper = styled.div`
    max-width: 300px;
    width: 100%;
    border: 1px solid ${({theme}) => rgba(theme.black, 0.1)};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    transition: all .25s ease;
    background-color: ${({theme}) => theme.bg.global};
    .link{
        &-media{
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0 10px 10px;
            .label{
                color: ${({theme}) => rgba(theme.black, 0.4)};
                font-size: 12px;
            }
            .icon{
                margin-right: 10px;
                width: 14px;
                svg{
                    color: ${({theme}) => rgba(theme.black, 0.4)};
                    height: 14px;
                    width: 14px;
                }
            }
        }
        &-thumbnail{
            background-position: top;
            background-size: cover;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            height: 150px;
            margin-bottom: 15px;
            position: relative;
            width: 100%;
            background-color: ${props => props.theme.mainColor2};
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px 4px 0 0;
                display: flex;
                align-items: flex-start;
            }
            &.small-thumb{
                height: 5px;
            }
        }
        &-wrapper{
            align-items: flex-start;
            display: flex;
            flex: 1;
            flex-direction: row;
            margin-bottom: 10px;
            padding: 0 10px;
            word-break: break-word;
            .link-action{
                margin-left: 10px;
            }
            .link-content{
                align-items: flex-start;
                display: flex;
                flex: 1;
                flex-direction: column;
                font-size: 14px;
                .link-title{
                    color: ${({theme}) => theme.black};
                    font-weight: bold;
                }
                .link-subtitle{
                    color: ${({theme}) => rgba(theme.black, 0.6)};
                }
            }
        }
    }
`;

class LinkTemplate extends PureComponent {

    state = {
        domain: null,
        title: null
    };

    static getDerivedStateFromProps(props) {
        const title = (props.title || props.url || '');

        return {
            domain: !!props.url ? getDomain(props.url) : '',
            title: `${title.substr(0, 30)}${title.length <= 30 ? '': '...'}`
        }
    }

    handleCopyToClipboard = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.copyToClipboard(this.props.url);
    }

    render() {
        const {
            subtitle,
            src,
            url,
            intl
        } = this.props;
        const { domain, title } = this.state;

        return (
            <StyledLinkWrapper
                className="styled-link-wrapper"
            >
                <Link
                    href={url}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <div className={classNames( 'link-thumbnail', { 'small-thumb': !src } )}>
                        {src && (
                            <Image
                                src={src}
                                fallbackSrc={fallbackImageSrc}
                                alt={title}
                            />
                        )}
                    </div>
                    <div className="link-wrapper" style={{ wordBreak: 'break-word'}}>
                        <div className="link-content">
                            {title && (
                                <div className="link-title">
                                    {title}
                                </div>
                            )}
                            {subtitle && (
                                <div className="link-subtitle">
                                    {subtitle}
                                </div>
                            )}
                        </div>

                        <div className="link-action">
                            <Tooltip
                                title={intl.formatMessage({
                                    id: 'global.copyClipboard',
                                    defaultMessage: 'Copy to clipboard'
                                })}
                                placement="topRight"
                            >

                                <Button
                                    color="secondary"
                                    size="md"
                                    onClick={this.handleCopyToClipboard}
                                    iconName="Copy"
                                />
                            </Tooltip>
                        </div>
                    </div>
                    {domain && (
                        <div className="link-media">
                            <Icon iconName="Link2" />
                            <div className="label">
                                {domain}
                            </div>
                        </div>
                    )}
                </Link>
            </StyledLinkWrapper>
        );
    }
}

LinkTemplate.propTypes = {
    intl: PropTypes.object.isRequired,
    title: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    subtitle: PropTypes.string,
    src: PropTypes.string,
    url: PropTypes.string.isRequired,
    copyToClipboard: PropTypes.func.isRequired
};

LinkTemplate.defaultProps = {
    title: null,
    subtitle: null,
    src: null
};

export default injectIntl(LinkTemplate
);
