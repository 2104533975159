import { utils } from '@amplement/backend-connector';

const StreamManager = utils.streamManager;

const getStreams = (members, _room) => {
    const streams = [];
    members.forEach(({ _client }) => {
        if (_client) {
            // We recover the video stream in order to prepare the possibility of recording video streams also in the future
            // const videoStream = StreamManager.getVideoStream({
            //     _client,
            //     _entity: _room,
            //     checkEnabled: false,
            //     takeLast: true
            // });
            const audioStream = StreamManager.getAudioStream({
                _client,
                _entity: _room,
                checkEnabled: false
            });
            streams.push({
                // videoStream,
                audioStream
            });
        }
    });

    return streams;
};

export const downloadChunks = (chunks, mimeType, downloadName = `call.webm`) => {
    const blob = new Blob(chunks, { type: mimeType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = downloadName;
    a.click();
    // We need to revoke the object URL after the click, otherwise strange behaviors can happen
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 100);
};

export default getStreams;
