import React from 'react';
import PropTypes from 'prop-types';
import { GlobalError } from 'components/Shared/Errors/container';
import { Route } from 'react-router';
import PrivateRoute from 'screens/PrivateRoute';

const RedirectLayout = ({
    isPrivate,
    hasError,
    ...rest
}) => {
    const CustomRoute = isPrivate ? PrivateRoute : Route;

    if (hasError) {
        return <GlobalError />;
    }

    return <CustomRoute {...rest} />;
};

RedirectLayout.propTypes = {
    isPrivate: PropTypes.bool,
    hasError: PropTypes.bool
};

RedirectLayout.defaultProps = {
    hasError: null,
    isPrivate: false
};

export default RedirectLayout;
