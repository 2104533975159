import fr from './fr.json';
import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import de from './de.json';
import vi from './vi.json';
import th from './th.json';
import si from './si.json';

export default {
    fr,
    en,
    es,
    ru,
    de,
    th,
    vi,
    si
};
