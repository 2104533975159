import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrentUserIdSelector } from 'selectors/user';
import { closeAllDrawers as closeAllDrawersAction } from 'actions/drawer';
import { $getContact } from 'api/user';
import { getUrl } from 'utils/url';
import { CONTACT_STATUS } from 'const/user';
import Loader from 'components/Shared/Common/Loader';
import { selectors, actions, callbacks } from '@amplement/backend-connector';

const RedirectToFeed = (props) => {
    const {
        _feed,
        history,
        createAnchor,
        closeAllDrawers,
        _user
    } = props;

    const goToFeed = (feedId) => {
        history.replace(getUrl('feed', { _feed: feedId }));
    }

    const goToProfile = () => {
        history.replace(getUrl('profile', { _user }));
    }

    useEffect(() => {
        closeAllDrawers();

        $getContact(_user)
            .then((result) => {
                const relation = result.data;

                if (!relation || relation.contactStatus !== CONTACT_STATUS.ACCEPTED) {
                    goToProfile();
                } else if (_feed) {
                    goToFeed(_feed);
                } else {
                    callbacks.set('onUnicastFeedCreated', feedId => {
                        if (feedId) {
                            goToFeed(feedId);
                        }
                    });
                    createAnchor();
                }
            })
            .catch(goToProfile);
    }, []);

    return <Loader size="md" />;
}

/**
 * profile : https://app.dev.my-collaborate.com:8082/feed/user/fb8bc87c-de9a-4109-af60-f3e4560f84a8
 * anchor : https://app.dev.my-collaborate.com:8082/feed/user/35c13459-11fa-44ae-b216-217e9bf56fe6
 */

RedirectToFeed.propTypes = {
    _feed: PropTypes.string,
    _user: PropTypes.string.isRequired,
    history: PropTypes.any.isRequired,
    closeAllDrawers: PropTypes.func.isRequired,
    createAnchor: PropTypes.func.isRequired
};

RedirectToFeed.defaultProps = {
    _feed: null
};

const mapStateToProps = (state, { match: { params: { _user } } }) => ({
    _feed: selectors.feeds.getFeedIdByUserIdSelector(state, _user),
    _me: getCurrentUserIdSelector(state),
    _user
});

const mapDispatchToProps = (dispatch, { match: { params: { _user } } }) => ({
    createAnchor: () => dispatch(actions.feeds.requestPostUnicastFeed(_user)),
    closeAllDrawers: () => dispatch(closeAllDrawersAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToFeed,
    (prevProps, nextProps) => nextProps._feed === prevProps._feed);
