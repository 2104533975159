import React from 'react';
import PropTypes from 'prop-types';

import { WIDGET_COMPONENT_MAPPING } from 'const/company';

const CustomWidget = ({ name, _me, ...widget }) => {
    const Component = WIDGET_COMPONENT_MAPPING[name];
    if (!Component) return null;
    return <Component key={name} {...widget} _me={_me} />;
};

CustomWidget.propTypes = {
    name: PropTypes.string,
    _me: PropTypes.string
};

CustomWidget.defaultProps = {
    name: null,
    _me: null
};

const renderWidget = params => widget => CustomWidget({ ...params, ...widget });

const CustomWidgets = ({ widgets, ...rest }) => (widgets ? widgets.map(renderWidget(rest)) : null);

CustomWidgets.propTypes = {
    widget: PropTypes.string,
    _me: PropTypes.string
};

CustomWidgets.defaultProps = {
    widget: null,
    _me: null
};

export default CustomWidgets;
