import { connect } from 'react-redux';

import { toggleOwnScreen, toggleOwnAudio, toggleOwnVideo } from 'actions/room';
import { selectors } from '@amplement/backend-connector';
import { getRoomFeatures } from 'selectors/room';

import Controls from './index';

const mapStateToProps = (state, ownProps) => {
    const features = getRoomFeatures(state, ownProps._room);

    return {
        isMeAdmin: selectors.rooms.getUserIsAdminByCurrentUserIdSelector(state, ownProps._room),
        features
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onToggleOwnScreen: (isChecked) => dispatch(toggleOwnScreen(ownProps._room, isChecked)),
    onToggleOwnAudio: (isChecked) => dispatch(toggleOwnAudio(ownProps._room, isChecked)),
    onToggleOwnVideo: (isChecked) => dispatch(toggleOwnVideo(ownProps._room, isChecked)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Controls);
