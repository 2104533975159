export const CALL_STATE = {
    RINGING: 'RINGING_CALL_STATE',
    CALLING: 'ACTIVE_CALL_STATE',
    INACTIVE: 'INACTIVE_CALL_STATE',
    ANSWERED: 'ANSWERED',
    ENDED: 'ENDED',
    ERROR: 'ERROR',
    PENDING: 'WAITING_FOR_INIT'
};

export const SIP_ACCOUNT_STATUS = {
    PENDING: 0,
    REGISTERED: 1,
    NOT_REGISTERED: -1
};

export const MAX_USER_DISPLAYED_ON_INCOMING = 5;

export const FORWARD_TYPES = [
    'EXTENSION',
    'VOICEMAIL',
    'MOBILE',
    'NUMBER',
    'NONE'
];

export const FORWARD_TRIGGER_TYPES = [
    'busy',
    'unavailable',
    'unconditional',
    'unreachable'
];
