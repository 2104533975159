import React, { lazy, Suspense, useState, useCallback, useMemo } from 'react';
import Drawer from 'components/Shared/Drawers';
import { getParams } from 'utils/url';
import Menu, { items as debugMenuItems } from 'components/Shared/Debug/Menu';
import localStorage from 'services/storage/localStorage';
import LoginManager from './LoginManager';

const RoomDebugger = lazy(() => import('./WebrtcManager/container'));
const TokenManagerDebugger = lazy(() => import('./TokenManager'));
const EnvManagerDebugger = lazy(() => import('./EnvManager'));
const ReduxAnalyzerDebugger = lazy(() => import('./ReduxAnalyzer'));
const ProfileManagerDebugger = lazy(() => import('./ProfileManager'));
const ConfigManagerDebugger = lazy(() => import('./ConfigManager'));
const WebsocketManagerDebugger = lazy(() => import('./WebsocketManager'));
const HealthDebugger = lazy(() => import('./Health/container'));
const UIManagerDebugger = lazy(() => import('./UIManagerDebugger'));
const RoomWebrtcSignalingTest = lazy(() => import('./RoomWebrtcSignalingTest'));

const RtcDebugger = () => {
    const lsSelectedPage = localStorage.get('debugPage');
    const [selectedPage, setSelectedPage] = useState(lsSelectedPage?.key ? JSON.parse(lsSelectedPage)?.key : lsSelectedPage); // for retro compat ({key: 'token} => 'token')

    const handleClose = useCallback(() => {
        localStorage.remove('debugPage');
        setSelectedPage(undefined);
    }, [setSelectedPage]);

    const handleSelect = useCallback((e) => {
        localStorage.set('debugPage', e?.key);
        setSelectedPage(e.key);
    }, [setSelectedPage]);

    const selectedItem = useMemo(() => debugMenuItems.find(item => item.key === selectedPage), [debugMenuItems, selectedPage])

    return (
        <>
            <Menu onClick={handleSelect} />
            {selectedPage && (
                <Drawer
                    title={selectedItem.label}
                    placement={selectedItem?.placement || 'left'}
                    width={selectedItem?.width || 600}
                    closable
                    onClose={handleClose}
                    isOpen={!!selectedPage}
                >
                    <Suspense fallback='loading...'>

                        {selectedPage === 'env' && (
                            <EnvManagerDebugger />
                        )}

                        {selectedPage === 'config' && (
                            <ConfigManagerDebugger />
                        )}

                        {selectedPage === 'token' && (
                            <TokenManagerDebugger />
                        )}

                        {selectedPage === 'webrtc' && (
                            <RoomDebugger _room={getParams()(window.location.pathname)}/>
                        )}

                        {selectedPage === 'webrtcSignaling' && (
                            <RoomWebrtcSignalingTest />
                        )}

                        {selectedPage === 'profile' && (
                            <ProfileManagerDebugger />
                        )}

                        {selectedPage === 'reduxAnalyzer' && (
                            <ReduxAnalyzerDebugger />
                        )}

                        {selectedPage === 'health' && (
                            <HealthDebugger />
                        )}

                        {selectedPage === 'websocket' && (
                            <WebsocketManagerDebugger _room={getParams()(window.location.pathname)} />
                        )}

                        {selectedPage === 'ui' && (
                            <UIManagerDebugger />
                        )}

                        {selectedPage === 'login' && (
                            <LoginManager />
                        )}

                    </Suspense>
                </Drawer>
            )}
        </>
    );
};

export default RtcDebugger;
