import { OPEN_SMALL_DRAWER, CLOSE_SMALL_DRAWER, CLOSE_ALL_SMALL_DRAWERS } from 'types/smallDrawer';

export const openSmallDrawer = (smallDrawerType, props) => ({
    type: OPEN_SMALL_DRAWER,
    smallDrawerType,
    ...props
});

export const closeSmallDrawer = smallDrawerType => ({
    type: CLOSE_SMALL_DRAWER,
    smallDrawerType
});

export const closeAllSmallDrawers = () => ({
    type: CLOSE_ALL_SMALL_DRAWERS
});
