import previewReducer from 'reducers/preview';
import modalReducer from 'reducers/modal';
import searchReducer from 'reducers/search';
import sessionReducer from 'reducers/session';
import profileReducer from 'reducers/profile';
import entityReducer from 'reducers/entity';
import drawerReducer from 'reducers/drawer';
import smallDrawerReducer from 'reducers/smallDrawer';
import companyReducer from 'reducers/company';
import userReducer from 'reducers/user';
import roomSettingsReducer from 'reducers/roomSettings';
import { reducers } from '@amplement/backend-connector';

export default ({
    entities: entityReducer,
    network: reducers.network,
    roomSettings: roomSettingsReducer,
    preview: previewReducer,
    modal: modalReducer,
    search: searchReducer,
    activities: reducers.activities,
    feeds: reducers.feeds,
    members: reducers.members,
    feedItems: reducers.feedItems,
    session: sessionReducer,
    profile: profileReducer,
    notifications: reducers.notifications,
    voiceMails: reducers.voiceMails,
    sip: reducers.sip,
    drawer: drawerReducer,
    smallDrawer: smallDrawerReducer,
    rooms: reducers.rooms,
    webRTC: reducers.webRTC,
    company: companyReducer,
    user: userReducer
});
