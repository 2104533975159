import React, { memo } from 'react';
import FileIcon from 'components/Shared/Common/FileIcon';

const NoPreview = () => (
    <FileIcon fileExtension="default" isDisplayable />
);

NoPreview.propTypes = {
};

NoPreview.defaultProps = {
};

export default memo(NoPreview);
