import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';

let toasterInstance;
const DEFAULT_DURATION = 4.5;

/**
 * All this code is derived from https://github.com/react-component/notification
 */

const initToasterStack = () => {
    if (toasterInstance) {
        return null;
    }

    const div = document.createElement('div');
    div.setAttribute('id', 'modal-toaster');
    document.body.appendChild(div);

    let called = false;
    const ref = (toaster) => {
        if (called) {
            return;
        }

        called = true;
        const newToasterInstance = {
            addToast: (toastProps) => {
                toaster.add(toastProps);
            },
            removeToast: (key) => {
                toaster.remove(key);
            },
            component: toaster,
            destroy: () => {
                ReactDOM.unmountComponentAtNode(div);
                div.parentNode.removeChild(div);
            }
        };
        toasterInstance = newToasterInstance;
    };

    return ref;
};

const getToasterInstance = () => {
    if (toasterInstance) {
        return toasterInstance;
    }
    return null;
};

const open = (options = {}) => {
    const duration = options.duration === undefined
        ? DEFAULT_DURATION
        : options.duration;
    const key = options.key || uuid();
    const toaster = getToasterInstance();

    if (toaster) {
        toaster.addToast({
            ToastComponent: options.ToastComponent,
            duration,
            closable: true,
            onClose: options.onClose,
            key
        });
    }

    return key;
};

const close = (key) => {
    if (toasterInstance) {
        toasterInstance.removeToast(key);
    }
};

const destroy = () => {
    if (toasterInstance) {
        toasterInstance.destroy();
        toasterInstance = undefined;

    }
};

const api = {
    open,
    close,
    destroy,
    initToasterStack
};

export default api;
