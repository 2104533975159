import { OPEN_DRAWER, CLOSE_DRAWER, CLOSE_ALL_DRAWERS } from 'types/drawer';

export const openDrawer = (type, props = {}) => ({
    type: OPEN_DRAWER,
    drawerType: type,
    ...props
});

export const closeDrawer = type => ({
    type: CLOSE_DRAWER,
    drawerType: type
});

export const closeAllDrawers = () => ({
    type: CLOSE_ALL_DRAWERS
});
