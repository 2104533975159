import React from 'react';

import Preview from 'components/Shared/Previews/Preview/container';

export default props => (
    <Preview
        content={(file, name) => (
            <iframe
                title={name}
                src={file}
            />
        )}
        {...props}
    />
);
