import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const ResponderHold = memo(({ intl, name }) => (
    <div className="line">
        <div className="line-container" />
        <div className="line-container">
            <div className="name">
                {name ?
                    intl.formatMessage({ id: 'room.member.status.holdByUser' }, { name }) :
                    intl.formatMessage({ id: 'room.member.status.holdByUnknown' })}
            </div>
        </div>
    </div>
));

ResponderHold.propTypes = {
    name: PropTypes.string,
    intl: PropTypes.any.isRequired
};

ResponderHold.defaultProps = {
    name: ''
};

export default memo(injectIntl(ResponderHold));
