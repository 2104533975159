import { createBrowserHistory } from 'history';
import {
    applyMiddleware,
    compose,
    createStore,
    combineReducers
} from 'redux';
import { createReduxHistoryContext } from "redux-first-history";
import { enableBatching } from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';

import exceptionMiddleware from 'middlewares/config';
import notificationMiddleware from 'middlewares/notification';
import callApiMiddleware from 'middlewares/callApi';
import soundMiddleware from 'middlewares/sound';
import { apiClient, actions, utils } from '@amplement/backend-connector';
import rootSaga from 'sagas';

import rootReducer from 'reducers';
import config from 'config';
import { getToken } from 'services/token';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
    history: createBrowserHistory({ basename: '/' }),
});

const sagaMiddleware = createSagaMiddleware({
    onError: (e) => {
        utils.errorHandler.captureException(e, 'store:createSagaMiddleware');
    }
});

const middlewares = [
    routerMiddleware,
    callApiMiddleware,
    sagaMiddleware,
    notificationMiddleware,
    exceptionMiddleware,
    soundMiddleware
];

rootReducer.router = routerReducer;

const composeEnhancers = process.env.CLIENT_ENV !== 'production'
    && config.debug
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'Collaborate'
    }) : compose;

export const store = createStore(
    compose(enableBatching, combineReducers)(rootReducer),
    composeEnhancers(applyMiddleware(...middlewares))
);

if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
        const nextRootReducer = require('../reducers/index').default; // eslint-disable-line
        const { routerReducer: nextRouterReducer } = createReduxHistoryContext({ 
            history: createBrowserHistory({ basename: '/' }),
        });
        nextRootReducer.router = nextRouterReducer;
        store.replaceReducer(compose(enableBatching, combineReducers)(nextRootReducer));
        console.log('[HMR] Huss huss, reducers are up to date'); // eslint-disable-line
    }, (e) => {
        console.warn('[HMR] reducers failed', e); // eslint-disable-line
    });
}

const rootTask = sagaMiddleware.run(rootSaga);
if (!!config.debug) {
    rootTask.toPromise().catch((error) => {
        console.error('SAGA unhandled throw, you must handle it right now !', error); // eslint-disable-line no-console
    });
}

const checkToken = request => {
    const axiosToken = apiClient
        .getAxiosProvider().defaults.headers.common.Authorization;
    const cookieToken = getToken();

    if (axiosToken !== cookieToken) {
        store.dispatch(actions.session.updateToken(cookieToken));
    }

    return request;
};

apiClient.getAxiosProvider().interceptors.request.use(checkToken);

export const history = createReduxHistory(store);
