import React, { memo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import  { selectors, utils, i18n } from '@amplement/backend-connector';
import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

const reveal = keyframes`
    from { transform: scale(0.001); }
    to { transform: scale(1); }
`;

const slide = keyframes `
    to { transform: translateX(8px) }
`;

const Styled = styled.div`
    &.writting{
        color: ${({theme}) => rgba(theme.black, .4)};
        position: absolute;
        top: 3px;
        left: 70px;
        font-size: 10px;
        padding-left: 30px;
        transition: all 250ms ease;
        transform: translateY(6px);
        opacity: 0;
        visibility: hidden;
        &.active{
            transform: translateY(0px);
            opacity: 1;
            visibility: visible;
        }
        .loader {
            position: absolute;
            left: 0px;
            top: 30%;
            transform: translateY(-50%);
        }
        .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: ${({theme}) => rgba(theme.black, .4)};
            position: absolute;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
        }
        .dot1, .dot2 {
            left: 0;
        }
        .dot3 { left: 8px; }
        .dot4 { left: 16px; }
        .dot1 {
            animation-name: ${reveal};
        }
        .dot2, .dot3 {
            animation-name: ${slide};
        }
        .dot4 {
            animation-name: ${reveal};
            animation-direction: reverse;
        }
    }
`;

const TypingUsers = ({ typingUsers }) => {
    const formatTyping = users => {
        if (!users) return null;
        const txt = `${i18n.translate('users.typing.text', { count: users.length })}`;
        if (users.length > 2) {
            return `${i18n.translate('users.typing.multiplePrefix')} ${txt}`;
        }
        if (users.length === 1) {
            return `${utils.strings.getFullname(users[0], { initialize: 'lastname' })} ${txt}`;
        }

        const label = users.map(user =>
            utils.strings.getFullname(user, { initialize: 'lastname' }),
        );

        return `${label.join(', ')} ${txt}`;
    };

    if (!typingUsers) {
        return null;
    }

    return (
        <Styled className="writting active">
            <div className="loader">
                <span className="dot dot1">&nbsp;</span>
                <span className="dot dot2">&nbsp;</span>
                <span className="dot dot3">&nbsp;</span>
                <span className="dot dot4">&nbsp;</span>
            </div>
            {formatTyping(typingUsers)}
        </Styled>
    );
}

TypingUsers.propTypes = {
    typingUsers: PropTypes.array,

};

TypingUsers.defaultProps = {
    typingUsers: undefined
};

const mapStateToProps = (state) => {
    const _feed = selectors.feeds.selectedFeedSelector(state);
    const typingUsers = _feed ? selectors.feeds.getTypingUsers(state, _feed) : undefined;

    return ({
        typingUsers
    });
};

export default compose(
    connect(mapStateToProps, null),
    memo
)(TypingUsers);
