import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextArea from 'components/Shared/Forms/TextArea';

const PreviewItemLayout = ({ intl, children, id, isCurrent, content, onChange, onFocus }) => {
    const [value, setValue] = useState(content);

    const handleChange = (val) => {
        setValue(val);
        onChange(id, val);
    }

    useEffect(() => {
        setValue(content);
    }, [content]);

    return (
        <div className={`big-img${isCurrent ? ' active': ''}`}>
            <div className="title">{id}</div>
            {children}
            <div className="textarea">
                <div className="group-input">
                    <TextArea
                        autoFocus
                        placeholder={intl.formatMessage({ id: 'modal.uploadFile.message.placeholder' })}
                        onChange={handleChange}
                        value={value}
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onFocus={() => onFocus(true)}
                        onBlur={() => onFocus(false)}
                    />
                </div>
            </div>
        </div>
    );
}

PreviewItemLayout.propTypes = {
    intl: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
    content: PropTypes.string,
    onChange: PropTypes.func,
    isCurrent: PropTypes.bool,
    onFocus: PropTypes.func.isRequired
};

PreviewItemLayout.defaultProps = {
    isCurrent: false,
    content: '',
    onChange: undefined
};

export default injectIntl(memo(PreviewItemLayout));
