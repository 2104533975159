import { put, call, all, select } from 'redux-saga/effects';
import * as initTypes from 'types/init';
import * as sessionTypes from 'types/session';
import * as tokenService from 'services/token';
import { setUser, setClient, setFatalError } from 'actions/session';
import sessionStorage from 'services/storage/sessionStorage';
import localStorage from 'services/storage/localStorage';
import uuid from 'utils/state/uuid';
import { $getCompanyWhois, $getCompanyPlugins } from 'api/company';
import { setCompany } from 'actions/company';
import { getUrl, isGuestAllowedUrl, isAnonymousAllowedUrl, isCallUrl } from 'utils/url';
import Logger from 'services/debug/logger';
import { $getMe } from 'api/user';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import { isGuest, isUser, isAdmin } from 'utils/user';
import { actions, config as backendConfig, utils, callbacks, apis } from '@amplement/backend-connector';
import config from 'config';
import desktopNotificationManager from 'services/desktopNotificationManager';
import { getDevices, setDevices, getSkipCall, setSkipCall } from 'services/userSettings';
import { getCurrentClientIdSelector, getCurrentUserStatusSelector } from 'selectors/user';
import { handleFatalErrors } from './shared/errors';

const logger = new Logger('saga:init');

function* requestGetPlugins(_company) {
    let plugins;
    
    try {
        const { data } = yield call($getCompanyPlugins, _company);

        plugins = data?.plugins;
    } catch (e) {
        yield call(utils.errorHandler.captureException, e, 'saga:init:requestGetPlugins');
    }

    return plugins;
}

function* requestGetWhois(mode) {
    let whois;

    try {
        const { data } = yield call($getCompanyWhois, mode);

        whois = data;
    } catch (e) {
        // e.message si error de code (ou network error) / e.response si axios result
        yield* handleFatalErrors(e);
        return whois;
    }

    if (whois?.skin?.shortLogo) {
        desktopNotificationManager.setIcon(whois.skin.shortLogo);
    }
    if (whois?.skin?.brandLogo) {
        desktopNotificationManager.setImage(whois.skin.brandLogo);
    }

    return whois;
}

function* requestGetCompanyData(_company) {
    let company;

    try {
        const { data } = yield call(apis.session.$getCompany, _company);

        company = data;
    } catch (e) {
        // e.message si error de code (ou network error) / e.response si axios result
        yield* handleFatalErrors(e);
    }

    if (company?.limits?.uploadFileSize) {
        callbacks.set('getFileUploadSizeLimit', () => company?.limits?.uploadFileSize || 104857600); // 100MB default
    }

    return company;
}

function* getMe() {
    const { data } = yield call($getMe);
    return data;
}

function* handleInit() {
    const _oldClient = yield select(getCurrentClientIdSelector); // if HMR
    const _client = _oldClient ?? uuid();

    if (typeof window === 'object') {
        const debug = localStorage.get('mycdebug') === '1' || config.debug
        window.mycDebug(debug);
        if (backendConfig.debug !== debug) {
            backendConfig.setConfig({ debug });
        }
    }
    sessionStorage.set('_client', _client);
    if (config.isLocalEnv && localStorage.get('X-referer')) {
        // HACK, never do that !
        config['X-referer'] = localStorage.get('X-referer');
    }

    // LEGACY transformations
    const { skipCallSettings, ...devices } = getDevices() || {};
    if (skipCallSettings !== undefined) {
        // remove OLD variable into LS
        setDevices(devices);
        localStorage.remove('skipCallSettings');
        const skipNextTime = getSkipCall();
        // retreive OLD variable and set to NEW property if not already setted
        if (skipNextTime !== undefined) {
            setSkipCall(skipNextTime);
        }
    }

    yield put(setClient(_client));
    const tokenCookie = tokenService.getToken();
    // apiClient.setAxiosToken(tokenCookie);

    const { pathname } = window.location;
    const isGuestAllowed = isGuestAllowedUrl(pathname);
    const isAnonymousAllowed = isAnonymousAllowedUrl(pathname);
    logger.log('handleRootInit', _client, !!tokenCookie, isGuestAllowed, isAnonymousAllowed);

    const whois = yield* requestGetWhois(!tokenCookie || (!_oldClient && isAnonymousAllowed) ? undefined : 'minimal');

    yield put({ type: 'APP_STATE.FOREGROUND' });
    if (!whois) {
        yield put({ type: sessionTypes.APP_READY });
        return;
    }

    yield put(setCompany(whois));

    if (isAnonymousAllowed) {
        logger.log('Allow anonymous client for this page');
        if (tokenCookie) {
            yield put(actions.session.updateToken(tokenCookie));
        }
        yield put({ type: sessionTypes.APP_READY });
        return;
    }

    if (!tokenCookie) {
        logger.error('No token from cookies');
        yield put({ type: sessionTypes.APP_READY });
        return;
    }

    if (utils.token.isExpired(tokenCookie)) {
        logger.error('Token expired');
        yield put({ type: sessionTypes.APP_READY });
        return;
    }

    try {
        const user = yield* getMe(tokenCookie);

        if (user) {
            logger.log('Matched user from getMe :', user);
            const roles = user.roles || [];
            const isGuestUser = isGuest(roles);
            const isAppUser = isUser(roles);
            const isOnlyAdmin = !isGuestUser && !isAppUser && isAdmin(roles) === true;

            backendConfig.setConfig({ session: { isGuest: isGuestUser } });
            yield put(actions.session.updateToken(tokenCookie));

            if (isOnlyAdmin) {
                logger.error('This admin does not have user account', user);
                window.location.href = getUrl('admin'); // should be in privateroute.js
                return;
            }

            if (isAppUser) {
                logger.log('User is an APP user');
                const company = yield call(requestGetCompanyData, whois.id);
                let plugins;
                if (!isCallUrl()) {
                    plugins = yield call(requestGetPlugins, whois.id);
                }
                yield put(setCompany({ ...company, plugins }));

                logger.log('handleInit - getSipAccount');
                yield put(actions.sip.fetchSIPAccounts());
                logger.log('handleInit - getSipAccount done');
            }

            // get previous status (from WS_PUT_STATUS) in case of race condition with GET /me
            const previousStatus = yield select(getCurrentUserStatusSelector);
            yield put(setUser({ ...user, status: previousStatus ?? user.status}));
            yield put({ type: sessionTypes.APP_READY });
        } else {
            logger.error('GetMe timeout');
            yield put(
                setFatalError('error.timeout', { code: 504, buttonType: BUTTON_TYPE.RELOAD })
            );
        }
    } catch (e) {
        yield call(utils.errorHandler.captureException, e, 'saga:init:handleInit');
        yield* handleFatalErrors(e);
    }
}

export default function* root() {
    yield all([utils.sagas.takeLatest(initTypes.REQUEST_ROOT_INIT, handleInit)]);
}
