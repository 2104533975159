export class ParametizedError extends Error {
    extras = [];

    constructor(message, ...args) {
        super(message);
        this.extras = args;
        this.name = 'ParametizedError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ParametizedError);
        }
    }
}

export class ResourceError extends Error {
    id = '';

    defaultMessage = undefined;

    values = {};

    name = 'ResourceError';

    constructor(error) {
        let id;
        let defaultMessage;
        let values;

        if (typeof error === 'string') {
            id = error;
        } else if (typeof error === 'object' && error.id) {
            const { id: errorId, defaultMessage: errorDefaultMessage, values: errorValues } = error;
            id = errorId;
            defaultMessage = errorDefaultMessage;
            values = errorValues;
        } else {
            id = 'error.default';
        }
        super(id);
        this.id = id;
        this.defaultMessage = defaultMessage;
        this.values = values;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ResourceError);
        }
    }

    toResource = () => [
        {
            id: this.id,
            defaultMessage: this.defaultMessage
        },
        this.values
    ];
}
