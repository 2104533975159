import styled from 'styled-components';
import { rgba, lighten, darken } from 'polished';
import { isDarkTheme } from 'utils/colors';

const THUMB_SIZE = 50;

export const StyledZoneUpload = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: none;
    min-height: 0;
    min-width: 0;
    width: 100%;
    height: 500px;
    .big-img{
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 250ms ease;
        transform: translateX(-55%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        visibility: hidden;
        .img-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            min-height: 0;
            min-width: 0;
            height: 100%;
            width: 100%;
            &.file{
                img{
                    box-shadow: none;
                }
            }
        }
        .title{
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${({theme}) => rgba(theme.black, .15)};
            color: ${({theme}) => theme.black};
            font-size: 12px;
            font-weight: bold;
            padding: 2px 5px;
            border-radius: 6px;
            white-space: nowrap;
        }
        .textarea{
            margin-top: 20px;
            width: 100%;
            .group-input{
                width: 100%;
                textarea{
                    width: 100%;
                    margin: 0;
                    font-size: 18px;
                    font-weight: 400;
                    border-radius: 6px;
                    padding: 10px 15px;
                }
            }
        }
        &.active{
            opacity: 1;
            transform: translateX(-50%);
            visibility: visible;
        }
    }
    .arrow{
        margin-top: -30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid ${({theme}) => rgba(theme.black, .1)};
        box-shadow: 0 0 4px ${({theme}) => rgba(theme.black, .1)};
        background-color: ${({theme}) => theme.white};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        transition: all 250ms ease;
        &:hover{
            transform: translateY(-50%) scale(1.1);
        }
        &.next{
            right: 10px;
        }
        &.prev{
            left: 10px;
        }
        svg{
            width: 20px;
            height: 20px;
            color: ${({theme}) => theme.black};
        }
    }
    .thumbs{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        transition: all 250ms ease;
        border-radius: 6px;
        padding: 20px 0 0 0;
        margin-bottom: 20px;
        &-selected{
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            color: ${({theme}) => rgba(theme.white, .4)};
            font-size: 12px;
        }
        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            flex: 1;
            min-width: 0;
            min-height: 0;
            svg{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60%;
                width: 60%;
            }
        }
        .remove{
            position: absolute;
            top: -10px;
            right: -10px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            background-color: ${({theme}) => isDarkTheme(theme) ? darken(.9, theme.white) : lighten(.9, theme.black)};
            border: 1px solid ${({theme}) => theme.white};
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            transition: all 250ms ease;
            opacity: 0;
            visibility: hidden;
            &:hover{
                background-color: ${({theme}) => isDarkTheme(theme) ? darken(.8, theme.white) : lighten(.8, theme.black)};
            }
            svg{
                width: 12px;
                color: ${({theme}) => theme.black};
                height: 12px;
            }
        }
        &-container{
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
            overflow: auto;
            padding-top: 15px;
            width: 100%;
            margin-top: -15px;
        }
        &-item{
            width: 50px;
            height: 50px;
            border-radius: 6px;
            flex-shrink: 0;
            border: 2px solid ${({theme}) => rgba(theme.black, .1)};
            margin-right: 10px;
            position: relative;
            transition: all 250ms ease;
            cursor: pointer;
            display: flex;
            &.file{
                .icon{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    svg{
                        width: 16px;
                        height: 16px;
                        color: ${({theme}) => theme.white};
                    }
                }
            }
            &.upload{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-style: dashed;
                &:hover{
                    border-style: solid;
                    border-color: ${({theme}) => theme.infos};
                    border-width: 2px;
                }
                .icon{
                    svg{
                        width: 16px;
                        height: 16px;
                        color: ${({theme}) => theme.black};
                    }
                }
                .label{
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: ${({theme}) => theme.black};
                }
            }
            &.loading{
                .loader{
                    position: absolute;
                    width: 80%;
                    left: 50%;
                    top: 50%;
                    height: 3px;
                    border-radius: 3px;
                    overflow: hidden;
                    background-color: ${({theme}) => rgba(theme.white, .1)};
                    transform: translateX(-50%) translateY(-50%);
                    .bar{
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 20px;
                        background-color: ${({theme}) => theme.success};
                    }
                }
            }
            &.error{
                border: 3px solid ${({theme}) => theme.error};
                .remove{
                    opacity: 1;
                    visibility: visible;                    
                    &:hover{
                        svg{
                            color: ${({theme}) => darken(.1, theme.error)};
                        }
                    }
                    svg{                        
                        color: ${({theme}) => theme.error};                        
                    }
                }
            }
            .overlay{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 250ms ease;
                background-color: ${({theme}) => rgba(theme.white, .3)};
                border-radius: 8px;
            }
            &:hover{
                .overlay{
                    background-color: transparent;
                }
                .remove{
                    visibility: visible;
                    opacity: 1;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            &.active{
                border-color: ${({theme}) => rgba(theme.black, .2)};
                border-width: 2px;
                .overlay{
                    background-color: transparent;
                }
            }
            img,
            video{
                border-radius: 4px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .content{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 0;
        min-width: 0;
        position: relative;
        transition: all 250ms ease;
        video,
        img{
            max-width: 100%;
            max-height: 100%;
            box-shadow: 0 0 20px ${({theme}) => rgba(theme.white, .2)};
            object-fit: cover;
        }
    }
`;


export const StyledSliderContainer =  styled.div`
    .slider-container{
        &--big{
            display: flex;
            flex-direction: column;
            flex: 1;
            min-height: 0;
            min-width: 0;
            justify-content: center;
            .thumb-container{
                display: flex;
                flex-direction: column;
                flex: 1;
                min-height: 0;
                min-width: 0;
                justify-content: center;
                .big-thumb{
                    margin: 0 auto;
                    height: 400px;
                    width: 400px;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            .textarea{
                display: flex;
                justify-content: center;
                margin: 10px 0 0;
                textarea{
                    font-size: 16px;
                    resize: none;
                    border: 2px solid ${rgba('#000', .4)};
                    width: 75%;
                    transition: all 250ms ease;
                    &::placeholder{
                        color: ${rgba('#000', .4)};
                    }
                    &:focus{
                        box-shadow: none;
                        color: black;
                        border: 2px solid ${rgba('#000', .6)};
                    }
                }
            }
            h3{
                font-size: 12px;
                align-self: center;
                font-weight: bold;
                background-color: #F9F9F9;
                padding: 2px 5px;
                border-radius: 6px;
            }
        }
        &--thumb{
            margin: 20px 0;
            position: relative;
            .arrow{
                position: absolute;
                top: 50%;
                transform: translateY(-50%) scale(1);
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid rgba(29,28,29, .13);
                box-shadow: 0 0 4px rgba(29,28,29, .13);
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 2;
                transition: all 250ms ease;
                &:hover{
                    transform: translateY(-50%) scale(1.1);
                }
                &.next{
                    right: -15px;
                }
                &.prev{
                    left: -15px;
                }
            }
            .thumb-item{
                position: relative;
                border: 3px solid ${rgba('#000', .1)};
                border-radius: 6px;
                transition: all 250ms ease;
                flex-shrink: 0;
                display: flex;
                height: 100px;
                width: 100%;
                line-height: ${THUMB_SIZE}px;
                .remove-item{
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    width: 24px;
                    flex-shrink: 0;
                    height: 24px;
                    border-radius: 50%;
                    background-color: ${lighten(.9, '#000')};
                    border: 1px solid white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    transition: all 250ms ease;
                    opacity: 0;
                    &:hover{
                        background-color: ${lighten(.8, '#000')};
                    }
                    i{
                        font-size: 12px;
                    }
                }
                .overlay{
                    opacity: 0;
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 4px;
                    width: 100%;
                    height: 100%;
                    transition: all 250ms ease;
                    background-color: ${rgba('#000', .45)};
                }
                &:hover{
                    .overlay,
                    .remove-item,
                    h3{
                        opacity: 1;
                    }
                }
                .thumbs-img{
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    border-radius: 4px;
                    object-fit: cover;
                }
                video{
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    border-radius: 4px;
                }
                img{
                    width: 50%;
                    height: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
                h3{
                    position: absolute;
                    white-space: nowrap;
                    width: 100%;
                    line-height: 1;
                    z-index: 2;
                    top: 5px;
                    left: 5px;
                    color: white;
                    font-size: 12px;
                    opacity: 0;
                    transition: all 250ms ease;
                }
            }
        }
        &--actions{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .ant-upload{
                display: flex;
                flex-direction: row;
                align-items: center;
                .ant-upload-text{
                    margin-left: 8px;
                }
            }
        }
    }
`;
