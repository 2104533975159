import React from 'react';

import { Select } from 'antd';

const delayThreshold = [
    ...(new Array(15)).fill(0).map((_ , i) => ({
        value: (i + 1) * 4,
        label: `${(i + 1) * 4} secondes`,
    })),
    ...(new Array(4)).fill(0).map((_ , i) => ({
        value: (60 + (i + 1) * 30),
        label: `${(60 + (i + 1) * 30)} secondes`,
    })),
];

const ThresholdDelaySelector = (props) =>  <Select options={delayThreshold} {...props} />;

export default ThresholdDelaySelector;
