import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import ContentError from './ContentError';

function GlobalError(props) {
    const { actions, content, subtitle, code, details, id, intl } = props;
    
    return (
        <div className="interface-content">
            <div className="interface-content-global">
                <div className="interface-content-global-left">
                    <ContentError
                        actions={actions}
                        content={content || intl.formatMessage({ id })}
                        subtitle={subtitle}
                        code={code}
                        details={details} />
                </div>
            </div>
        </div>
    );
}

GlobalError.propTypes = {
    actions: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]),
    content: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    code: PropTypes.oneOf(['', 404, 422, 504, 500, 401, 403, 503]),
    details: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ])
};

GlobalError.defaultProps = {
    actions: null,
    subtitle: null,
    code: undefined,
    details: undefined
};

export default injectIntl(GlobalError);
