import React from 'react';
import ErrorFormatter from 'components/Shared/Errors/Formatter';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import ContentErrorComponent from 'components/Shared/Errors/ContentError';

const Page404Error = () => (
    <ErrorFormatter
        id="error.notFound"
        code={404}
        buttonType={BUTTON_TYPE.GOHOME}
        render={ContentErrorComponent}
    />
);

export default Page404Error;
