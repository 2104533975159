import * as types from 'types/company';
import { $getCompanyUsers } from 'api/company';

export const requestGetCompanyUsers = (_company, page, limit) => ({
    types: [
        types.GET_COMPANY_USERS_REQUEST,
        types.GET_COMPANY_USERS_SUCCESS,
        types.GET_COMPANY_USERS_FAILURE
    ],
    callAPI: () => $getCompanyUsers(_company, page, limit),
    forceCallApi: true,
    shouldCallAPI: state => state.ok || true // todo : mettre la bonne valeur
});

export const setCompany = company => ({
    type: types.SET_COMPANY,
    company
});
