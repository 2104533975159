import React, { memo, useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import PropTypes from 'prop-types';

import { PhoneOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

import { isWebRtcCompatible } from 'utils/webRTC';
import Participant from 'components/CallBuilder/types';

type ButtonProps = {
    _room?: string;
    inputValue: Participant[];
    onCall: () => void;
    maxReached?: boolean;
} & WrappedComponentProps;

const formatMessages = ({ intl, inputValue }) => ({
    tooltipTitle: intl.formatMessage({ id: 'feed.header.button.drawer.room.audio' }),
    startCall: intl.formatMessage({ id: 'call.callMultiple' }, { count: inputValue.length }),
    inviteToCall: intl.formatMessage({ id: 'call.inviteToCall' }, { count: inputValue.length }),
});

const CallButton = ({
    onCall,
    inputValue,
    intl,
    maxReached,
    _room,
}: ButtonProps): JSX.Element => {
    const i18n = useMemo(() => formatMessages({ intl, inputValue }), [intl, inputValue]);

    return (
        <Tooltip title={i18n.tooltipTitle}>
            <Button
                type="primary"
                onClick={onCall}
                target="blank"
                size="large"
                shape="round"
                icon={<PhoneOutlined />}
                disabled={!inputValue.length || !isWebRtcCompatible() || maxReached}
            >
                {_room ? i18n.inviteToCall : i18n.startCall}
            </Button>
        </Tooltip>
    );
};

CallButton.propTypes = {
    inputValue: PropTypes.array.isRequired,
    _room: PropTypes.string,
    maxReached: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    onCall: PropTypes.func.isRequired,
};

CallButton.defaultProps = {
    _room: '',
    maxReached: false,
};

export default memo(injectIntl(CallButton));
