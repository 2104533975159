import {
    put, all
} from 'redux-saga/effects';

import { addNotification } from 'actions/notification';
import { decreaseUserCount } from 'actions/user';
import * as types from 'types/user';
import { CONTACT_STATUS } from 'const/user';
import { utils } from '@amplement/backend-connector';

function* notifyPostRelation({ payload: user }) {
    if (user && user.isInitiator === false) {
        yield put(addNotification({
            severity: 'info',
            title: 'notification.relation.info.target',
            values: user
        }));
    }
}

function* notifyPutRelation({ payload: user }) {
    if (user && user.isInitiator === true && user.contactStatus === CONTACT_STATUS.ACCEPTED) {
        yield put(addNotification({
            severity: 'info',
            title: 'notification.relation.info.initiator',
            values: user
        }));
    }
}

function* decreaseCount() {
    yield put(decreaseUserCount('usersAccepted'));
}

export default function* root() {
    yield all([
        utils.sagas.takeLatest(types.WS_POST_CONTACT, notifyPostRelation),
        utils.sagas.takeLatest(types.WS_PUT_CONTACT, notifyPutRelation),
        utils.sagas.takeLatest(types.DELETE_CONTACT_SUCCESS, decreaseCount),
    ]);
}
