import React, { createContext, useContext, useEffect, useMemo, useState, memo } from 'react';
import { ConfigProvider, theme } from 'antd';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import merge from 'lodash/merge';
import isEqual from 'react-fast-compare';
import { getCompanySelector } from 'selectors/company';
import { isCallUrl } from 'utils/url';
import overrideAntdTheme, { getDefaultTheme, overrideAntdDarkTheme, overrideAntdLightTheme } from 'components/ThemeProvider/helpers';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const defaultAlgorithm = isCallUrl() ? 'darkAlgorithm' : 'defaultAlgorithm';

export default memo(({ children }) => {
    const { skin } = useSelector(getCompanySelector, (prev, next) => isEqual(prev?.skin, next?.skin));
    const [themeAlgorithm, setThemeAlgorithm] = useState(defaultAlgorithm);
    const [companyTheme, setCompanyTheme] = useState({ ...getDefaultTheme(themeAlgorithm), ...(skin || {}) });

    const antdTheme = useMemo(() => ({
        algorithm: theme[themeAlgorithm],
        ...merge(
            themeAlgorithm === 'darkAlgorithm' ? overrideAntdDarkTheme(companyTheme) : overrideAntdLightTheme(companyTheme),
            overrideAntdTheme(companyTheme)
        )
    }), [theme, companyTheme, themeAlgorithm, companyTheme]);

    const value = useMemo(() => ({
        setThemeAlgorithm,
        setCompanyTheme,
        companyTheme,
    }), [companyTheme]);

    useEffect(() => {
        const algorithm = themeAlgorithm || `${skin?.displayMode || 'default'}Algorithm`;

        setCompanyTheme({ ...getDefaultTheme(algorithm), ...(skin || {}) });
        setThemeAlgorithm(algorithm);
    }, [skin, themeAlgorithm]);

    return (
        <ThemeContext.Provider value={value}>
            <StyledProvider theme={companyTheme}>
                <ConfigProvider theme={antdTheme}>
                    {children}
                </ConfigProvider>
            </StyledProvider>
        </ThemeContext.Provider>
    )
});
