import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Checkbox } from 'antd';
import { rgba } from 'polished';

const StyleCheckbox = styled(Checkbox)`
    &.ant-checkbox-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            .label{
                color: ${({ theme }) => theme.white};
            }
        }
        &:hover{
            .ant-checkbox-inner{
                border-color: ${props => props.theme.mainColor2};
            }
        }
    }
    .ant-checkbox{
        .ant-checkbox-input:focus + .ant-checkbox-inner{
            border-color: ${props => props.theme.mainColor2};
        }
        &-checked{
            &::after{
                border-color: ${props => props.theme.mainColor2};
            }
            &.ant-checkbox-disabled{
                .ant-checkbox{
                    &-inner{
                        background-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)};
                        border-color: ${({ theme }) => rgba(theme.fixedBlack, 0.2)}!important;
                        &::after{
                            border-color: ${({ theme }) => rgba(theme.fixedWhite, 0.4)};
                        }
                    }
                }
            }
            .ant-checkbox{
                &-inner{
                    background-color: ${props => props.theme.mainColor2};;
                    border-color: ${props => props.theme.mainColor2};!important;
                    &::after{
                        border-color: ${({ theme }) => theme.fixedWhite};
                    }
                }
            }
        }
        &-inner{
            background-color: ${({ theme }) => rgba(theme.white, 0.2)};
            border-color: ${({ theme }) => rgba(theme.white, 0.2)};
        }
    }
`;

const CheckboxItem = ({ disabled, onChange, checked, ItemComponent, ...itemProps }) => (
    <div className="item">
        <StyleCheckbox
            disabled={disabled}
            onChange={onChange}
            checked={checked}
        >
            <ItemComponent {...itemProps} checked={checked} />
        </StyleCheckbox>
    </div>
);

CheckboxItem.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    ItemComponent: PropTypes.any.isRequired
};

CheckboxItem.defaultProps = {
    disabled: false,
    checked: false
};

export default memo(CheckboxItem);
