import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MEMBER_STATUS } from 'const/feed';

import SimpleItem from './SimpleItem';
import { StyledItem } from './styles';

const UserItem = ({
    _user,
    avatar,
    avatarUri,
    avatarSize,
    status,
    firstname,
    lastname,
    isInitiator,
    isAdmin,
    displayPendingBadge,
    title,
    size,
    isLoading,
    contactStatus,
    memberStatus,
    actionRender,
    className,
    noLink,
    isMe
}) => {
    const badges = [];

    if (memberStatus) {
        if (isInitiator && !isAdmin) {
            badges.push('creator');
        }
        if (isAdmin) {
            badges.push('admin');
        }
    }

    if (
        (memberStatus === MEMBER_STATUS.PENDING || memberStatus === MEMBER_STATUS.DECLINED) &&
        displayPendingBadge
    ) {
        badges.push('pending');
    }

    return (
        <StyledItem
            avatarSize={avatarSize}
            className={classNames(
                's-list-user-item',
                className,
                { [size]: size },
                { loading: isLoading }
            )}
        >
            <SimpleItem
                _user={_user}
                avatar={avatarUri || avatar}
                avatarSize={avatarSize}
                status={status}
                firstname={firstname}
                lastname={lastname}
                title={title}
                badges={badges}
                isLoading={isLoading}
                size="md"
                noLink={isMe || noLink}
            />
            {actionRender
                && actionRender({
                    _user,
                    isInitiator,
                    presenceStatus: status,
                    memberStatus,
                    contactStatus
                })}
        </StyledItem>
    );
};

UserItem.propTypes = {
    _user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    avatarUri: PropTypes.string,
    avatarSize: PropTypes.string,
    status: PropTypes.number,
    displayPendingBadge: PropTypes.bool,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    title: PropTypes.string,
    size: PropTypes.oneOf(['', 'xs']),
    isLoading: PropTypes.bool,
    contactStatus: PropTypes.number,
    memberStatus: PropTypes.number,
    isInitiator: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isMe: PropTypes.bool,
    className: PropTypes.string,
    actionRender: PropTypes.func
};

UserItem.defaultProps = {
    avatar: undefined,
    avatarUri: undefined,
    status: undefined,
    displayPendingBadge: true,
    title: undefined,
    size: '',
    className: undefined,
    contactStatus: undefined,
    memberStatus: undefined,
    isInitiator: false,
    isAdmin: false,
    isMe: false,
    isLoading: false,
    avatarSize: 'md',
    actionRender: undefined
};

const UserItemProxy = props => (props.firstname ? <UserItem {...props} /> : null);

export default UserItemProxy;
