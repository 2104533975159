export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const CLOSE_ALL_DRAWERS = 'CLOSE_ALL_DRAWERS';

export const DRAWER_FEED_EDITOR = 'DRAWER_FEED_EDITOR';
export const DRAWER_INVITE_GROUP = 'DRAWER_INVITE_GROUP';
export const DRAWER_INVITE_EXTERNAL_EMAILS = 'DRAWER_INVITE_EXTERNAL_EMAILS';
export const DRAWER_SEARCH = 'DRAWER_SEARCH';
export const DRAWER_CREATE_UNICAST = 'DRAWER_CREATE_UNICAST';
export const DRAWER_PHONE = 'DRAWER_PHONE';
export const DRAWER_SIP = 'DRAWER_SIP';
export const DRAWER_ADD_MEMBERS = 'DRAWER_ADD_MEMBERS';
export const DRAWER_EDIT_GROUP = 'DRAWER_EDIT_GROUP';
