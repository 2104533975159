import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import Button from 'components/Shared/Common/Button';
import { injectIntl } from 'react-intl';

const PopConfirmComponent = memo(injectIntl(({
    intl,
    title,
    placement,
    onConfirm,
    ...props
}) => (
    <Popconfirm
        placement={placement}
        title={title}
        onConfirm={onConfirm}
        okText={intl.formatMessage({ id: 'button.toggle.on' })}
        cancelText={intl.formatMessage({ id: 'button.toggle.off' })}
        {...props}
    />
)));

PopConfirmComponent.propTypes = {
    placement: PropTypes.string,
    intl: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired
};

PopConfirmComponent.defaultProps = {
    placement: 'right',
};

const PopConfirm = injectIntl(PopConfirmComponent);
export default PopConfirmComponent;

export const PopConfirmDeletion = memo(({
    title,
    placement,
    onConfirm,
    buttonColor,
    buttonIconName,
    buttonTitle,
    buttonSize,
    isTransparent,
    disabled,
    buttonTestId,
    ...props
}) => (
    <PopConfirm
        placement={placement}
        title={title}
        onConfirm={onConfirm}
        disabled={disabled}
        {...props}
    >
        <Button
            iconName={buttonIconName}
            color={buttonColor}
            isTransparent={isTransparent}
            size={buttonSize}
            disabled={disabled}
            data-testid={buttonTestId}
        >
            {buttonTitle}
        </Button>
    </PopConfirm>
));

PopConfirmDeletion.propTypes = {
    buttonTestId: PropTypes.string,
    placement: PropTypes.string,
    title: PropTypes.string.isRequired,
    buttonColor: PropTypes.string,
    buttonTitle: PropTypes.string,
    disabled: PropTypes.bool,
    isTransparent: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    buttonIconName: PropTypes.oneOf(['MinusCircle', 'XCircle', 'Trash2']),
    buttonSize: PropTypes.string
};

PopConfirmDeletion.defaultProps = {
    buttonTestId: undefined,
    buttonTitle: undefined,
    buttonColor: 'error',
    placement: undefined,
    disabled: false,
    isTransparent: true,
    buttonIconName: 'XCircle',
    buttonSize: 'xs'
};
