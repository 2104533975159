/* The antd tooltips are currently not optimized see: https://github.com/ant-design/ant-design/discussions/41583
This component aims to improve its use and performance.
The following component is complex, due to the fact that onMouseLeave is not triggered in Chrome when you move the mouse quickly over the Tooltip.
The onMouseEnter is indeed triggered, which creates slight rendering bugs.
See : https://github.com/facebook/react/issues/20483
*/
import React, { memo, useCallback, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import PropTypes from 'prop-types';

import uuid from 'utils/state/uuid';

const WAIT_BEFORE_SHOW = 100;
const CRON_CHECK = 200;

type WrapperProps = {
    children: React.ReactNode;
} & TooltipProps;

// Modified version of antd Tooltip, it only renders the component if it is hovered
const WrapperTooltip = ({ children, ...props }: WrapperProps): JSX.Element => {
    const [isShowingTooltip, setShowTooltip] = useState(false);
    const [visibile, setVisibile] = useState(false);

    const uid = useRef(uuid());
    const timer = useRef<undefined | NodeJS.Timeout>(undefined);

    const hide = useCallback(() => {
        setVisibile(false);
        clearInterval(timer.current);
    }, []);

    const handleMouseEnter = useCallback(() => {
        // Render the tooltip only if the user has hovered over the element
        if (!isShowingTooltip) setShowTooltip(true);

        if (!visibile) {
            // We wait a little in order to differentiate if the user wants to see the element or
            // if they just hovered their mouse over it.
            setTimeout(() => {
                const el = document.getElementById(`tooltip_${uid.current}`)?.matches(':hover');

                if (el) {
                    setVisibile(true);
                    // We check if the user is still hovering over the element every X milliseconds
                    timer.current = setInterval(() => {
                        const element = document.getElementById(`tooltip_${uid.current}`)?.matches(':hover');
                        if (!element) hide();
                    }, CRON_CHECK);
                }
            }, WAIT_BEFORE_SHOW);
        }
    }, [isShowingTooltip, visibile, hide]);

    const onTooltipChange = useCallback((opened) => {
        if (opened) {
            handleMouseEnter();
        } else {
            hide();
        }
    }, [handleMouseEnter]);

    return (
        <div id={`tooltip_${uid.current}`} onMouseEnter={handleMouseEnter}>
            {isShowingTooltip ? (
                <Tooltip
                    open={visibile}
                    onOpenChange={onTooltipChange}
                    {...props}
                >
                    {children}
                </Tooltip>
            ) : children}
        </div>
    );
};

WrapperTooltip.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(WrapperTooltip);
