import { connect } from 'react-redux';
import { postFeedItems } from 'actions/feedItem';

import FileUpload from './index';

const mapDispatchToProps = (dispatch, { _feed }) => ({
    onUpload: feedItems => dispatch(postFeedItems(_feed, feedItems)),
});

export default connect(null, mapDispatchToProps)(FileUpload);
