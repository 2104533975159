import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Portal from 'components/Layouts/Portal';

const DropZoneUI = lazy(() => import('./DropZoneUi'));

const DropZone = ({
    intl, onAddFileToUpload, containerId
}) => {
    const [drag, setVisible] = useState(false);
    let dragCounter = 0;

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setVisible(true);
        }
    }, [dragCounter, setVisible]);

    const handleDragOut = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter--
        if (dragCounter === 0) {
            setVisible(false);
        }
    }, [dragCounter, setVisible]);

    const handleStart = useCallback((data) => {
        setVisible(false);
        if (data.files && data.files.length > 0) {
            onAddFileToUpload([...data.files]);
            data.clearData();
            dragCounter = 0;
        }
    }, [dragCounter, setVisible, onAddFileToUpload]);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        handleStart(e.dataTransfer);
    }, []);

    const handlePaste = useCallback((e) => {
        handleStart(e.clipboardData);
    }, []);

    useEffect(() => {
        const el = window;
        el.addEventListener('dragenter', handleDragIn);
        el.addEventListener('dragleave', handleDragOut);
        el.addEventListener('dragover', handleDrag);
        el.addEventListener('drop', handleDrop);
        el.addEventListener('paste', handlePaste);

        return () => {
            el.removeEventListener('dragenter', handleDragIn);
            el.removeEventListener('dragleave', handleDragOut);
            el.removeEventListener('dragover', handleDrag);
            el.removeEventListener('drop', handleDrop);
            el.removeEventListener('paste', handlePaste);
        }
    }, []);

    if (!drag) return null;

    return (
        <Suspense fallback="">
            <Portal id={containerId}>
                <DropZoneUI title={intl.formatMessage({ id: 'dropZone.title' })} />
            </Portal>
        </Suspense>
    )
}

DropZone.propTypes = {
    intl: PropTypes.any.isRequired,
    containerId: PropTypes.string.isRequired,
    onAddFileToUpload: PropTypes.func.isRequired
};

export default injectIntl(DropZone);
