import styled from 'styled-components';
import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

export default styled.div`
    position: absolute;
    bottom: 110%;
    right: 0;
    background-color: ${colors.bgRoom};
    box-shadow: 0 2px 4px ${rgba(colors.black, 0.15)};
    padding: 10px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all 250ms ease;
    transform: translateY(-10px);
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &.infos{
        width: 200px;
    }
    &.more{
        width: 350px;
    }
    &:after{
        top: 100%;
        right: 15px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: ${colors.bgRoom};
        border-width: 5px;
        margin-left: -5px;
    }
    .list-line-options{
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: calc(100vh - 160px);
        margin-left: -10px;
        margin-right: -10px;
        padding: 0px 10px;
        .line{
            &:hover{
                >.label{
                    cursor: pointer;
                    color: ${colors.white};
                }
            }
            &-wrapper{
                display: flex;
                flex-direction: row;
            }
            &-item{
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid ${rgba(colors.white, 0.1)};
                &:last-child{
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border: none;
                }
            }
            &:first-child{
                margin-right: 10px;
            }
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            &.column{
                flex-direction: column;
            }
            input{
                border-radius: 4px;
                border: 2px solid transparent;
                background-color: ${rgba(colors.white, 0.1)};
                padding: 5px;
                color: ${colors.white};
                transition: all 250ms ease;
                width: 100%;
                font-size: 14px;
                margin-top: 5px;
                &:focus{
                    background-color: ${rgba(colors.white, 0.15)};
                    border-color: ${props => props.theme.mainColor2};
                }
            }
            .icon-hd{
                background-color: ${colors.white};
                font-size: 8px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                color: ${colors.black};
            }
            &.header{
                align-items: center;
            }
            >.label{
                font-size: 14px;
                font-weight: bold;
                color: ${rgba(colors.white, 0.9)};
                margin-bottom: 0px;
                text-align: left;
                transition: all 250ms ease;
            }
            >.desc{
                color: ${rgba(colors.white, 0.6)};
                font-size: 13px;
                font-weight: 400;
                margin-top: 3px;
                display: flex;
                text-align: left;
                b{
                    font-weight: bold;
                    color: ${colors.white};
                }
            }
            .copy{
                margin-left: 3px;
                cursor: pointer;
                .icon{
                    svg{
                        width: 17px;
                        height: 17px;
                    }
                }
            }
            .icon{
                display: flex;
                svg{
                    color: ${colors.white};
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }
`;
