import * as feedItemTypes from 'types/feedItem';
import * as callTypes from 'types/call';
import { addNotification } from 'actions/notification';
import { getCurrentUserIdSelector, getSoundsStatusSelector, getCurrentUserSelector } from 'selectors/user';
import { PRESENCE_STATUSES } from 'const/user';
import { getTabFocusSelector } from 'selectors/session';
import { types, selectors, utils } from '@amplement/backend-connector';

import soundManager from 'services/soundManager';

const play = name => (next, action, store) => {
    const isSoundEnabled = getSoundsStatusSelector(store.getState())?.global;
    const userStatus = getCurrentUserSelector(store.getState())?.status;
    const hasCallInProgress = !!selectors.rooms.getCurrentByUserRoomsSelector(store.getState())?.length;
    if (isSoundEnabled && !hasCallInProgress && !action?.payload?.silent && userStatus !== PRESENCE_STATUSES.BUSY) {
        soundManager.play(name);
    }
};

const hasTabFocus = (_, action, store) => getTabFocusSelector(store.getState());

const isInitiator = (_, action, store) => {
    const state = store.getState();
    const _currentUser = getCurrentUserIdSelector(state);

    return action?.payload?._user === _currentUser;
};

const isCurrentFeed = (_, action) => {
    const _currentFeed = window.location.pathname;
    const { payload } = action || {};
    const { _feed } = payload || {};

    return _currentFeed.indexOf(_feed) !== -1;
};

const isFeedMuted = (_, { payload: { _feed } = {} } = {}, store) => {
    const status = getSoundsStatusSelector(store.getState())?.feeds?.[_feed];

    if (status === undefined || status) {
        return false;
    }

    return true;
};

const onAddFeedItem = name => (...args) => {
    const isMuted = isInitiator(...args) || (isCurrentFeed(...args) && hasTabFocus(...args)) || isFeedMuted(...args);

    if (!isMuted) {
        play(name)(...args);
    }
};

const onRemoveFeedItem = name => (...args) => {
    const isMuted = !isInitiator(...args) || isFeedMuted(...args);

    if (!isMuted) {
        play(name)(...args);
    }
};

const dtmfSounds = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    '*': 'asterisk',
    '#': 'pound'
}

const onDtmfPad = (next, action, store) => {
    const snd = dtmfSounds[action.digit];

    if (snd) {
        play(snd)(next, action, store);
    }
};

const actionTypeHandlers = {
    [feedItemTypes.WS_POST_FEED_ITEM]: onAddFeedItem('chat'),
    [feedItemTypes.WS_DELETE_FEED_ITEM]: onRemoveFeedItem('delete'),

    // Dial pad sound
    [callTypes.DIAL_PAD_SOUND_0]: play('0'),
    [callTypes.DIAL_PAD_SOUND_1]: play('1'),
    [callTypes.DIAL_PAD_SOUND_2]: play('2'),
    [callTypes.DIAL_PAD_SOUND_3]: play('3'),
    [callTypes.DIAL_PAD_SOUND_4]: play('4'),
    [callTypes.DIAL_PAD_SOUND_5]: play('5'),
    [callTypes.DIAL_PAD_SOUND_6]: play('6'),
    [callTypes.DIAL_PAD_SOUND_7]: play('7'),
    [callTypes.DIAL_PAD_SOUND_8]: play('8'),
    [callTypes.DIAL_PAD_SOUND_9]: play('9'),
    [callTypes.DIAL_PAD_SOUND_ASTERISK]: play('#'),
    [callTypes.DIAL_PAD_SOUND_POUND]: play('*'),
    [types.rooms.ROOM_SEND_DTMF]: onDtmfPad,
};

export default store => next => (action) => {
    const handler = actionTypeHandlers[action.type];

    if (handler && typeof handler === 'function') {
        try {
            handler(next, action, store);
        } catch (error) {
            utils.errorHandler.captureException(error, 'middleware:sound');
            next(addNotification({
                title: 'error.default',
                referenceError: 'SND1'
            }));
        }
    }

    next(action);
};
