import React from 'react';
import PropTypes from 'prop-types';

import ImagePreview from 'components/Shared/Previews/Image';
import VideoPreview from 'components/Shared/Previews/Video';
import PdfPreview from 'components/Shared/Previews/Pdf';
import Logger from 'services/debug/logger';

const logger = new Logger('component:PreviewManager');

const PreviewManager = (props) => {
    const { feedItem } = props;
    let Preview = null;

    if (feedItem?.metasFile?.extension) {
        switch (feedItem.metasFile.extension.toLowerCase()) {
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.gif':
            case '.webp':
                Preview = ImagePreview;
                break;
            case '.mp4':
            case '.mov':
            case '.avi':
            case '.webm':
            case '.ogg':
                Preview = VideoPreview;
                break;
            case '.pdf':
                Preview = PdfPreview;
                break;
            default:
                logger.error(new Error('format not supported'), feedItem.metasFile.extension); //eslint-disable-line
                break;
        }
    }

    if (Preview) return <Preview {...props} />;

    return null;
};

PreviewManager.propTypes = {
    feedItem: PropTypes.object
};

PreviewManager.defaultProps = {
    feedItem: null
};

export default PreviewManager;
