export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const MODAL_DELETE_ACCOUNT = 'MODAL_DELETE_ACCOUNT';
export const MODAL_DELETE_RELATION = 'MODAL_DELETE_RELATION';
export const MODAL_DELETE_GROUP = 'MODAL_DELETE_GROUP';
export const MODAL_LEAVE_GROUP = 'MODAL_LEAVE_GROUP';
export const MODAL_DELETE_FEEDITEM = 'MODAL_DELETE_FEEDITEM';
export const MODAL_UPDATE_FEEDITEM = 'MODAL_UPDATE_FEEDITEM';
export const MODAL_ROOM_INCOMING = 'MODAL_ROOM_INCOMING';
export const MODAL_CALL_INCOMING = 'MODAL_CALL_INCOMING';
export const MODAL_DELETE_MEMBER = 'MODAL_DELETE_MEMBER';
export const MODAL_KICK_ROOM_USER = 'MODAL_KICK_ROOM_USER';
