import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import LinkTemplate from 'components/Shared/Feed/ItemTemplate/LinkTemplate';
import { copyTextToClipboard } from 'actions/session';

const FeedItemLink = ({ url, link, copyToClipboard }) => link ? (
    <div className="list-files">
        <LinkTemplate
            src={link.image}
            title={link.title}
            subtitle={link.description}
            url={url}
            copyToClipboard={copyToClipboard}
        />
    </div>
) : null;

FeedItemLink.propTypes = {
    url: PropTypes.string,
    link: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }).isRequired,
    copyToClipboard: PropTypes.func.isRequired,
};

FeedItemLink.defaultProps = {
    url: ''
};

const Component = memo(injectIntl(FeedItemLink));
export default Component;

const mapDispatchToProps = (dispatch) => ({
    copyToClipboard: text => dispatch(copyTextToClipboard(text))
});

export const container = connect(null, mapDispatchToProps)(Component);
