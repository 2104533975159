import { rgba } from "polished";

import { darkTheme, lightTheme } from "common/themes/Colors";

const defaultSkin = {
    mainColor1: '#333f52',
    mainColor2: '#0996c3',
    brandLogo: '',
    shortLogo: '',
    favicon: ''
};

export const getDefaultTheme = algorithm => ({ ...defaultSkin, ...(algorithm === 'darkAlgorithm' ? darkTheme : lightTheme) });

export const overrideAntdDarkTheme = ({
    bg
}) => ({
    token: { 
        colorBgElevated: bg.global, 
        colorBgContainer: bg.global 
    }
});

// eslint-disable-next-line
export const overrideAntdLightTheme = ({
}) => ({
});

const overrideAntdTheme = ({
    mainColor2,
    black,
    error,
    warning,
    success,
}) => ({
    token: {
        colorPrimary: mainColor2,
        colorLink: mainColor2,
        colorWarning: warning,
        colorSuccess: success,
        colorErrorActive: error,
        borderRadius: 4,
        fontFamily: "'Roboto','Helvetica Neue',Arial,'Noto Sans',sans-serif",
    },
    components: {
        Input: {
            colorPrimary: black,
            colorBorder: rgba(black, 0.1),
            colorPrimaryHover: 'transparent',
            colorBgContainer: 'transparent',
            colorPrimaryBg: 'transparent',
            colorTextPlaceholder: rgba(black, 0.6),
            borderRadius: 4,
        },
        InputNumber: {
            colorPrimary: black,
            colorTextPlaceholder: rgba(black, 0.6),
            colorBorder: rgba(black, 0.1),
            colorPrimaryHover: 'transparent',
            colorBgContainer: 'transparent',
            colorPrimaryBg: 'transparent',
            borderRadius: 4,
        }
    }
});

export default overrideAntdTheme;
