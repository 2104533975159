import RequestStateFormatter from 'utils/state/requestStateFormatter';

/** *
 * State updater, manage items into the state
 */
export const RequestHelper = (state, objectName) => {
    const update = (requestState) => {
        const newState = { ...state };
        let newStateEntityValue = null;
        const mountPoint = newState[objectName];

        if (mountPoint && Array.isArray(mountPoint)) {
            if (!mountPoint.some(request => request.hash === requestState.hash)) {
                newStateEntityValue = [...mountPoint, requestState];
            } else {
                newStateEntityValue = mountPoint.map((request) => {
                    if (request.hash === requestState.hash) {
                        return { ...request, ...requestState };
                    }

                    return request;
                });
            }
        } else {
            newStateEntityValue = { ...mountPoint, ...requestState };
        }

        newState[objectName] = newStateEntityValue;
        return newState;

    };

    const deleteById = (id) => {
        const newState = { ...state };
        let newStateEntityValue = null;
        const mountPoint = newState[objectName];

        const removeItem = (page) => {
            if (Array.isArray(page.data)) {
                page.data = [...page.data.filter(pageItem => pageItem !== id)]; // eslint-disable-line
            } else {
                page.data = page.data !== id ? page.data : null; // eslint-disable-line
            }
            return page;
        };
        if (mountPoint && Array.isArray(mountPoint)) {
            newStateEntityValue = mountPoint.map(removeItem)
                .filter(page => page.data);
        } else {
            newStateEntityValue = removeItem(mountPoint);

            if (!newStateEntityValue.data) {
                newStateEntityValue = {};
            }
        }

        newState[objectName] = newStateEntityValue;
        return newState;
    };

    const _reduceProperty = (state, objectName, propertyName) => { // eslint-disable-line
        const newState = { ...state };
        const mountPoint = newState[objectName];
        const hasPagination = Array.isArray(mountPoint);
        let result;

        if (mountPoint) {
            if (hasPagination) {
                if (mountPoint.length) {
                    mountPoint.forEach((page) => {
                        if (page[propertyName]) {
                            if (!result) result = [];
                            if (Array.isArray(page[propertyName])) {
                                result = [...result, ...page[propertyName]];
                            } else {
                                result.push(page[propertyName]);
                            }
                        }
                    });
                    if (result)
                        result = [...new Set(result)];
                }
            } else {
                result = mountPoint[propertyName];
            }
        }

        return result;
    };

    const getData = () => _reduceProperty(state, objectName, 'data');
    const getLoadings = () => _reduceProperty(state, objectName, 'isLoading');
    const getLoading = () => {
        const loadings = getLoadings();
        return !!(loadings && loadings.length && loadings[0]);
    };
    const getPagination = () => _reduceProperty(state, objectName, 'pagination');
    const getLastPagination = () => {
        const pagination = getPagination();
        return pagination ? pagination[pagination.length - 1] : undefined;
    };
    const getErrors = () => _reduceProperty(state, objectName, 'error');

    return {
        update,
        deleteById,
        getData,
        getLoadings,
        getLoading,
        getErrors,
        getPagination,
        getLastPagination,
        ...ReducerQueue(state, objectName) // eslint-disable-line
    };
};

/** *
 * Create a queue system to manage loading and errors for each items
 */
export const ReducerQueue = (state, objectName) => {
    const queueName = `${objectName}Queue`; // todo faire le changement la ou c'est deja utilisé

    state[queueName] = state[queueName] || []; // eslint-disable-line

    const addLoading = ({ payload }) => ({
        ...state,
        [queueName]: [
            ...state[queueName],
            {
                id: payload ? payload.id : null,
                createdAt: new Date(),
                isLoading: true
            }
        ]
    });

    const removeLoading = action => ({
        ...RequestHelper(state, 'items').update(RequestStateFormatter.entitySuccess(action)),
        [queueName]: (state[queueName] || [])
            .filter(item => item.id !== action.response.data.result)
    });

    return {
        addLoading,
        removeLoading,
        addError: () => console.error('addError not yet implemented'), // eslint-disable-line
        removeError: () => console.error('addError not yet implemented') // eslint-disable-line
    };
};

export const ReducerEntityBase = (state, objectName, action, isEntity) => {
    const type = action.type.match(/[a-zA-Z]+$/im);
    const request = RequestHelper(state, objectName);

    switch (type.length ? type[0] : '') {
        case 'REQUEST':
            return request.update(RequestStateFormatter.request(action));
        case 'SUCCESS':
            return request.update(RequestStateFormatter[isEntity ? 'entitySuccess' : 'success'](action));
        case 'FAILURE':
            return request.update(RequestStateFormatter.failure(action));
        default:
            throw new Error(`Bad action type, name should contains REQUEST, SUCCESS or FAILURE but found ${action.type}`);
    }
};
