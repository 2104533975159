import React from 'react';

export default props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g
                transform="translate(1.000000, 1.000000)"
                stroke="#ffffff"
                strokeWidth="2"
            >
                <g>
                    <path d="M0,0 L22,22" id="Path" />
                    <path d="M6,22 L14,22" id="Path" />
                    <path d="M10,18 L10,22" id="Path" />
                    <path
                        d="M20,9 L20,6 C20,4.8954305 19.1045695,4 18,4 L8,4 M18,18 C19.1045695,18 20,17.1045695 20,16 L20,8 M0,8 L0,16 C0,17.1045695 0.8954305,18 2,18 L14,18 M0,14 L0,6 C0,4.8954305 0.8954305,4 2,4 L4,4"
                        id="Shape"
                    />
                </g>
            </g>
        </g>
    </svg>
);
