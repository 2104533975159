import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, Space, Card, message, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form as FinalForm, Field } from 'react-final-form';
import { required as finalFormRequired } from 'final-form';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { CollapsedForm } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ForwardTypeSelector';
import ForwardSummaryLayout from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ForwardSummaryLayout';
import FORWARD_TRIGGERS_COMPONENTS from 'components/Shared/Drawers/Sip/Forwards/const';
import injectValidators from 'components/Shared/Forms/injectValidators';

import {
    LabelTimePicker,
    LabelMultiSelect,
} from 'components/Shared/Forms';
import { usePatchForwardRules, usePostForwardRules } from 'hooks/useForwardRules';
import useSipAccount from 'hooks/useSipAccount';

const cardStyle = { minWidth: 400 };
const spaceStyle = { marginTop: 30, display: 'flex' };
const spaceFloatStyle = { float: 'right' };
const cancelButtonStyle = { display: 'block', textAlign: 'end' };
const hiddenStyle = { display: 'none' };

const formatMessages = (intl) => ({
    confirm: intl.formatMessage({ id: 'global.button.confirm' }),
    monday: intl.formatMessage({ id: 'global.date.monday' }),
    tuesday: intl.formatMessage({ id: 'global.date.tuesday' }),
    wednesday: intl.formatMessage({ id: 'global.date.wednesday' }),
    thursday: intl.formatMessage({ id: 'global.date.thursday' }),
    friday: intl.formatMessage({ id: 'global.date.friday' }),
    saturday: intl.formatMessage({ id: 'global.date.saturday' }),
    sunday: intl.formatMessage({ id: 'global.date.sunday' }),
    selectDays: intl.formatMessage({ id: 'call.tabs.forwards.selectDays' }),
    selectType: intl.formatMessage({ id: 'call.tabs.forwards.selectType' }),
    from: intl.formatMessage({ id: 'call.tabs.forwards.from' }),
    to: intl.formatMessage({ id: 'call.tabs.forwards.to' }),
    backToTheList: intl.formatMessage({ id: 'call.tabs.forwards.backToTheList' }),
    saveSuccess: intl.formatMessage({ id: 'global.button.saveSuccess' }),
    saveFailure: intl.formatMessage({ id: 'call.tabs.forwards.saveFailure' }),
    error: intl.formatMessage({ id: 'error.default' }),
    conflictingRules: intl.formatMessage({ id: 'call.tabs.forwards.conflictingRules' }),
});

const { Text } = Typography;

const showTime = { format: 'HH:mm', minuteStep: 5 };

const TimeBasedForm = ({
    intl,
    item,
    validators: { required },
    toggleForm,
    _sipAccount
}) => {
    const [isEditting, setIsEditting] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const changeView = useCallback(() => {
        toggleForm(isShown => !isShown);
    }, [toggleForm]);
    const { data: account = {} } = useSipAccount({ _sipAccount });
    const {
        isLoading: postIsLoading,
        mutate: post,
        error: postError,
    } = usePostForwardRules({ _sipAccount, onSuccess: toggleForm });
    const {
        isLoading: patchIsLoading,
        mutate: update,
        error: patchError,
    } = usePatchForwardRules({ _sipAccount, onSuccess: toggleForm });
    const translations = useMemo(() => formatMessages(intl), [intl]);

    const weekdaysOptions = useMemo(() => [
        { label: translations.monday, value: 'mon' },
        { label: translations.tuesday, value: 'tue' },
        { label: translations.wednesday, value: 'wed' },
        { label: translations.thursday, value: 'thu' },
        { label: translations.friday, value: 'fri' },
        { label: translations.saturday, value: 'sat' },
        { label: translations.sunday, value: 'sun' },
    ], [translations]);

    const handleForwardTypeSubmit = useCallback(change => values => {
        change('type', values.type);
        change('context', values.context);
        change('to', values.to);
        setIsEditting(false);
    }, []);

    const toggleEdit = useCallback(() => {
        setIsEditting(!isEditting);
    }, [isEditting]);

    const validate = useCallback((values) => {
        const errors = {};
        if (!values.weekdays || values.weekdays.length === 0) {
            errors.weekdays = translations.selectDays;
        }
        if (!values.type) {
            errors.type = translations.type;
        }
        if (!values.fromTime) {
            errors.fromTime = translations.fromTime;
        }
        if (!values.toTime) {
            errors.toTime = translations.toTime;
        }
        return errors;
    }, [translations]);

    const initialForm = useMemo(() => ({
        ...item,
        context: item?.context || '',
        fromTime: item?.fromTime ? dayjs(item.fromTime, 'HH:mm') : null,
        toTime: item?.toTime ? dayjs(item.toTime, 'HH:mm') : null,
        weekdays: item?.weekdays || [],
        type: item?.type || '',
    }), [item]);

    const onSubmit = useCallback(async ({ weekdays, fromTime, toTime, context, to, type }) => {
        const payload = {
            weekdays,
            fromTime: dayjs(fromTime).format('HH:mm:ss'),
            toTime: dayjs(toTime).format('HH:mm:ss'),
            enabled: true,
            to: to?.replace(/^[+]/, '00'),
            type,
            context,
        };
        try {
            if (item) {
                await update({ _forwardRule: item.id, payload });
            } else {
                await post(payload);
            }
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: translations.saveFailure,
            });
        }
    }, [post, item, update, messageApi, translations, changeView]);

    const error = useMemo(() => {
        const err = postError || patchError;
        if (err) {
            if (err.response?.data?.data?.conflictingRules) {
                return translations.conflictingRules;
            }
            if (err.response?.data?.message) {
                return err.response?.data?.message;
            }
            if (err.message) {
                return err.message;
            }
        }

        return null;
    }, [postError, patchError, translations]);

    return (
        <>
            {contextHolder}
            <FinalForm onSubmit={onSubmit} initialValues={initialForm} validate={validate}>
                {({ handleSubmit, pristine, submitting, form, values, invalid }) => (
                    <Form onSubmit={handleSubmit} layout='vertical'>
                        <div style={cancelButtonStyle}>
                            <Button
                                type="primary"
                                onClick={changeView}
                                icon={<ArrowLeftOutlined />}
                            >
                                {translations.backToTheList}
                            </Button>
                        </div>
                        <Field
                            name="weekdays"
                            validate={required}
                            component={LabelMultiSelect}
                            label={translations.selectDays}
                            options={weekdaysOptions}
                            placeholder={translations.selectDays}
                        />
                        <Space>
                            <Card style={cardStyle}>
                                {isEditting ? (
                                    <CollapsedForm
                                        mobileNumber={account?.phone?.mobileNumber}
                                        onSubmit={handleForwardTypeSubmit(form.change)}
                                        value={values}
                                        onCancel={toggleEdit}
                                    />
                                ) : ( 
                                    <ForwardSummaryLayout
                                        data={values}
                                        ContentComponent={FORWARD_TRIGGERS_COMPONENTS[values.type]}
                                        onEdit={toggleEdit}
                                    />
                                )}
                            </Card>
                        </Space>
                        <Field name="type" validate={finalFormRequired}>
                            {({ input }) => (
                                <Form.Item style={hiddenStyle} name="type">
                                    <Input {...input} type="hidden" value={values.type} />
                                </Form.Item>
                            )}
                        </Field>
                        <Space style={spaceStyle}>
                            <Field
                                name="fromTime"
                                validate={required}
                                component={LabelTimePicker}
                                label={translations.from}
                                showTime={showTime}
                                customFormat="HH:mm"
                            />
                            <p> - </p>
                            <Field
                                name="toTime"
                                validate={required}
                                component={LabelTimePicker}
                                label={translations.to}
                                showTime={showTime}
                                customFormat="HH:mm"
                            />
                        </Space>
                        {error && <Text type="danger">{error}</Text>}
                        <Space style={spaceFloatStyle}>
                            <Button
                                type="primary"
                                loading={postIsLoading || patchIsLoading}
                                onClick={form.submit}
                                disabled={pristine || submitting || invalid}
                            >
                                {translations.confirm}
                            </Button>
                        </Space>
                    </Form>
                )}
            </FinalForm>
        </>
    );
};

TimeBasedForm.propTypes = {
    intl: PropTypes.object.isRequired,
    post: PropTypes.func,
    update: PropTypes.func,
    item: PropTypes.object,
    validators: PropTypes.object,
    toggleForm: PropTypes.func,
    _sipAccount: PropTypes.string,
};

TimeBasedForm.defaultProps = {
    post: () => {},
    update: () => {},
    toggleForm: () => {},
    _sipAccount: '',
    item: null,
    validators: {},
};

export default injectValidators(injectIntl(TimeBasedForm));
