import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { actions } from '@amplement/backend-connector';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import IncomingCall from 'components/Shared/Call/Notifications/RoomIncomingCall/container';
import { Modal } from 'antd';

import styled from 'styled-components';

const StyledModal = styled(Modal)`
    max-width: 700px;
    width: auto!important;
    .ant-modal{
        &-header{
            border: none;
        }
        &-content{
            flex: initial;
        }
    }
`;

const ModalWrapperComponent = ({ _room, intl }) => {
    const [visible, setVisible] = useState(true);
    const dispatch = useDispatch();
    const { rejectIncoming } = actions.rooms;

    const handleCancel = useCallback(() => dispatch(rejectIncoming(_room, true)), [dispatch, rejectIncoming, _room]);

    const handleClose = useCallback(() => setVisible(false), [setVisible]);

    return (
        <StyledModal
            title={intl.formatMessage({ id: 'call.text.calling' })}
            open={visible}
            onCancel={handleCancel}
            closable={false}
            destroyOnClose
            maskClosable={false}
            centered
            footer={null}
        >
            <IncomingCall _room={_room} onClose={handleClose} />
        </StyledModal>
    );
}

ModalWrapperComponent.propTypes = {
    intl: PropTypes.any.isRequired,
    _room: PropTypes.string.isRequired
};

ModalWrapperComponent.defaultProps = {
};

export default injectIntl(ModalWrapperComponent);
