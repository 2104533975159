import getUserMedia from 'get-user-media-promise';

import Logger from 'services/debug/logger';
import { ResourceError } from 'utils/state/error';
import { getBrowserName } from 'services/screenSharing';
import { utils, callbacks } from '@amplement/backend-connector';
import { getRoomSettings } from 'selectors/roomSettings';

const logger = new Logger('screen:room:helper:DeviceManager');

class DeviceManager {
    getPermissionStatus = (name) => {
        if (navigator && navigator.permissions && navigator.permissions.query) {
            return navigator.permissions.query({ name })
                .catch((e) => {
                    logger.warn('getPermissionStatus', e);
                    return Promise.reject(new ResourceError('rtc.error.notSupported'));
                });
        }
        return Promise.reject(new ResourceError('rtc.error.notSupported'));
    };

    enumerateDevices = kind => {
        // on safari, method return empty devices, it's not working
        if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            logger.warn('Func enumerateDevices not supported');
            return Promise.reject(new ResourceError('rtc.error.enumerateDevices.notSupported'));
        }

        return navigator.mediaDevices
            .enumerateDevices()
            // // to simulate empty device list on Mac
            // .then((devices) => {
            //     return devices.filter(x => x.label.indexOf('Mac') === -1 && x.label.indexOf('FaceTime') === -1);
            // })
            .then(devices => {
                const filtered = devices.filter(
                    device => !kind || kind === device.kind || kind.includes(device.kind)
                );
                //  if (!filtered.length) {
                //      throw new ResourceError('rtc.error.noDeviceAvailable');
                //  }
                return filtered;
            })
            .catch(e => {
                utils.errorHandler.captureException(e, 'DeviceManager:enumerateDevices');
                if (e && e.id) {
                    return Promise.reject(e);
                }
                return Promise.reject(new ResourceError('rtc.error.cannotListRTCDevices'));
            });
    };

    testDevices = constraints => {
        logger.log('testDevices', constraints);

        return this.getStream(constraints).then(stream => {
            logger.log('testDevices.success', constraints);
            stream.getTracks().forEach(track => track.stop());
        });
    };

    __formatError = (kind, [all, camera, micro, screen]) => {
        if (kind === 'video') {
            return camera;
        }
        if (kind === 'audio') {
            return micro;
        }
        if (kind === 'screen') {
            return screen;
        }
        return all;
    }

    __getStreamWithGUM = (constraints) => {
        if (!getUserMedia.isSupported) {
            logger.warn('Func getUserMedia not supported');
            return Promise.reject(new ResourceError('rtc.error.getUserMedia.notSupported'));
        }

        logger.log('__getStreamWithGUM:request', constraints);
        return getUserMedia(constraints)
            .then(stream => {
                logger.log('__getStreamWithGUM:complete', stream, stream && stream.getTracks().map(({ muted, enabled, readyState, kind, label, id }) => ({ muted, enabled, readyState, kind, label, id })));
                return stream;
            })
            .catch(e => {
                let key = 'rtc.error.devices.all.AbortError';

                if (e && e.name) {
                    let kind = 'allDevices';

                    if (constraints.mediaStreamSource || (constraints.video && constraints.video.mandatory && constraints.video.mandatory.chromeMediaSourceId)) {
                        kind = 'screen';
                    } else if (!constraints.video) {
                        kind = 'audio';
                    } else if (!constraints.audio) {
                        kind = 'video';
                    }

                    if (e.name === 'OverconstrainedError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.OverConstrainedError', 'rtc.error.devices.camera.OverConstrainedError', 'rtc.error.devices.microphone.OverConstrainedError', 'rtc.error.devices.screen.OverConstrainedError']);
                    } else if (e.name === 'AbortError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.AbortError', 'rtc.error.devices.camera.AbortError', 'rtc.error.devices.microphone.AbortError', 'rtc.error.devices.screen.AbortError']);
                    } else if (e.name === 'NotAllowedError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.NotAllowedError', 'rtc.error.devices.camera.NotAllowedError', 'rtc.error.devices.microphone.NotAllowedError', 'rtc.error.devices.screen.NotAllowedError']);
                    } else if (e.name === 'NotFoundError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.NotFoundError', 'rtc.error.devices.camera.NotFoundError', 'rtc.error.devices.microphone.NotFoundError', 'rtc.error.devices.screen.NotFoundError']);
                    } else if (e.name === 'NotReadableError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.NotReadableError', 'rtc.error.devices.camera.NotReadableError', 'rtc.error.devices.microphone.NotReadableError', 'rtc.error.devices.screen.NotReadableError']);
                    } else if (e.name === 'SecurityError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.SecurityError', 'rtc.error.devices.camera.SecurityError', 'rtc.error.devices.microphone.SecurityError', 'rtc.error.devices.screen.SecurityError']);
                    } else if (e.name === 'TypeError') {
                        key = this.__formatError(kind, ['rtc.error.devices.all.TypeError', 'rtc.error.devices.camera.TypeError', 'rtc.error.devices.microphone.TypeError', 'rtc.error.devices.screen.TypeError']);
                    }

                    if (e?.constraint !== 'deviceId') {
                        utils.errorHandler.captureException(e, 'DeviceManager:__getStreamWithGUM', constraints);
                    } else {
                        logger.error('DeviceManager:__getStreamWithGUM', e, constraints);
                    }
                }
                return Promise.reject(new ResourceError(key));
            });
    };

    /**
 *
 *
 * FF : refuser les perm :
message: "The request is not allowed by the user agent or the platform in the current context."
name: "NotAllowedError"

FF: si bloqué par l'OS:
message: "The object can not be found here."
name: "NotFoundError"

chrome cancel :
Permission denied undefined NotAllowedError

chrome denied by sys :
DOMException: Permission denied by system undefined NotAllowedError

safari :
InvalidAccessError: getDisplayMedia must be called from a user gesture handler. — DeviceManager.js:141
"InvalidAccessError"

-> faut qu'il soit trigger depuis un event
 */

    __getStreamWithGDM = (constraints) => {
        if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
            logger.warn('Func getDisplayMedia not supported');
            return this.__getStreamWithGUM(constraints);
        }

        logger.log('__getStreamWithGDM', constraints);
        return navigator.mediaDevices.getDisplayMedia(constraints)
            .catch(e => {
                let key = 'rtc.error.devices.all.AbortError';
                if (e && e.name) {
                    if (e.name === 'AbortError') {
                        key = 'rtc.error.devices.screen.AbortError';
                    } else if (e.name === 'NotAllowedError') {
                        if (e.message === 'Permission denied by system') {
                            key = 'rtc.error.devices.screen.NotAllowedErrorBySystem';
                        } else if (getBrowserName() === 'chrome' || getBrowserName() === 'opera') {
                            key = 'rtc.error.devices.screen.CancelledByUserError';
                        } else {
                            key = 'rtc.error.devices.screen.NotAllowedError';
                        }
                    } else if (e.name === 'NotFoundError') {
                        if (getBrowserName() === 'firefox') {
                            key = 'rtc.error.devices.screen.NotAllowedError';
                        } else {
                            key = 'rtc.error.devices.screen.NotFoundError';
                        }
                    } else if (e.name === 'NotReadableError') {
                        key = 'rtc.error.devices.screen.NotReadableError';
                    } else if (e.name === 'OverConstrainedError') {
                        key = 'rtc.error.devices.screen.OverConstrainedError';
                    } else if (e.name === 'SecurityError') {
                        key = 'rtc.error.devices.screen.SecurityError';
                    } else if (e.name === 'TypeError') {
                        key = 'rtc.error.devices.screen.TypeError';
                    }
                }

                utils.errorHandler.captureException(e, 'DeviceManager:getDisplayMedia');
                return Promise.reject(new ResourceError(key));
            });
    };

    _formatConstraints = (rawConstraints) => {
        const settings = getRoomSettings(callbacks.get('getState')());
        const constraints = rawConstraints;

        if (rawConstraints.audio === true) {
            constraints.audio = settings?.audioinput || true;
        }
        if (rawConstraints.video === true || rawConstraints.video === '') {
            constraints.video = settings?.videoinput || true;
        }

        logger.log('getStream:settings: ', settings, rawConstraints, constraints);

        if (!constraints || typeof constraints !== 'object' || !Object.keys(constraints).length) {
            logger.warn('_formatConstraints:missing constraints', constraints);
            throw new ResourceError('error.default');
        }

        let result = {};
        if (constraints.screen) {
            result = { video: true };
        } else {
            Object.keys(constraints).forEach((kind) => {
                const value = constraints[kind];

                if (value === false) {
                    result[kind] = false;
                }

                else if (value === null || value === true || value === 'default' || value === undefined) {
                    result[kind] = true;
                }

                else if (typeof value === 'string') {
                    if (value === 'front' || value === 'back') { // mobile device
                        result[kind] = { facingMode: (value === 'front' ? "user" : "environment") }
                    } else if (getBrowserName() === 'firefox') {
                        result[kind] = { deviceId: value };
                    } else { // if value is deviceId
                        result[kind] = { deviceId: { exact: value } };
                    }
                }

                else { // keep value
                    result[kind] = value;
                }
            });
        }

        logger.log('_formatConstraints in(%o) out(%o)', constraints, result);
        return result;
    };

    getStream = (rawConstraints) => {
        logger.log('getStream', rawConstraints);
        const isScreenStream = rawConstraints && rawConstraints.screen;
        const constraints = this._formatConstraints(rawConstraints);
        if (isScreenStream) {
            return this.__getStreamWithGDM(constraints);
        }

        return this.__getStreamWithGUM(constraints);
    };

    attachAudioOutput =  sinkId => (element, index = 1) => {
        if (!element) {
            return Promise.reject(new ResourceError('rtc.error.devices.speaker.cannotAttachSpeaker'));
        }
        if (element.muted) {
            logger.warn('Cannot attach: element is muted');
            return Promise.resolve();
        }
        // if (!element.audioTracks || !element.audioTracks.length) { // le soundtest n'a pas de audiotrack mais un src
        //     logger.warn('Cannot attach: no audio tracks on element');
        //     return Promise.resolve();
        // }
        if (element.setSinkId) {
            const deviceId = sinkId || 'default';
            logger.log(
                'element:srcObject',
                sinkId,
                deviceId,
                element.muted,
                element.srcObject,
                element.stream,
                Object.keys(element)
            );

            if ((element.sinkId || 0) === deviceId ) {
                logger.log(`Success, audio output device attached: ${deviceId}`);
                return undefined;
            }
            return element
                .setSinkId(deviceId)
                .then(() => {
                    logger.log(`Success, audio output device attached: ${deviceId}`);
                })
                .catch(e => {
                    logger.error(index, e);
                    return Promise.reject(new ResourceError('rtc.error.devices.speaker.cannotAttachSpeaker'));
                });
        }
        logger.warn('Cannot attach audio output, browser not compatible');
        return Promise.reject(new ResourceError('rtc.error.devices.speaker.notSupported'));
    };
}

export default new DeviceManager();
