import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from 'antd';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { EditOutlined, UserAddOutlined } from '@ant-design/icons';

import AntdModal from 'components/Shared/Modals';
import ContactForm from 'components/Contacts/Contacts/ContactForm';

const { Text } = Typography;

const StyledText = styled(Text)`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const StyledTextUnderline = styled(Text)`
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`;

const ButtonStyle = styled(Button)`
    max-width: 100%;
`;

type ContactButtonProps = {
    compact?: boolean;
    contactId?: string;
    phoneNumber?: string;
    isPrimary?: boolean;
    onSuccess?: (response) => void;
} & WrappedComponentProps;

const ContactButton = ({
    intl,
    compact,
    contactId,
    phoneNumber,
    onSuccess,
    isPrimary,
}) => {
    const [visible, setVisible] = useState(false);
    const handleOpenModal = useCallback(() => setVisible(true), []);
    const handleCancel = useCallback(() => setVisible(false), []);

    return (
        <>
            <ButtonStyle type={isPrimary ? 'primary': 'text'} onClick={handleOpenModal}>
                {contactId ? <EditOutlined /> : <UserAddOutlined />}
                {!compact && (
                    <StyledText>
                        {intl.formatMessage({ id: 'contacts.createContact' })}
                    </StyledText>
                )}
                {phoneNumber && <StyledTextUnderline>{phoneNumber}</StyledTextUnderline>}
            </ButtonStyle>
            <AntdModal
                title={intl.formatMessage({ id: 'contacts.createContact' })}
                isOpen={visible}
                onClose={handleCancel}
                destroyOnClose
                isAutoScroll
                width={650}
                footer={null}
            >
                <ContactForm
                    onSuccess={onSuccess}
                    contactId={contactId}
                    closeModal={handleCancel}
                    phoneNumber={phoneNumber}
                />
            </AntdModal>
        </>
    );
}

ContactButton.propTypes = {
    intl: PropTypes.object.isRequired,
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    compact: PropTypes.bool,
    isPrimary: PropTypes.bool,
    onSuccess: PropTypes.func,
};

ContactButton.defaultProps = {
    contactId: '',
    isPrimary: false,
    onSuccess: () => {},
    compact: false,
};

export default injectIntl(ContactButton as React.FunctionComponent<ContactButtonProps>);
