import { NETWORK_EXCEPTION } from 'types/exception';
import Logger from 'services/debug/logger';

const logger = new Logger('middleware:config');

export default () => next => (action) => {
    if (action.type === NETWORK_EXCEPTION) {
        logger.log('action:rawException', action.rawException);
    } else {
        next(action);
    }
};
