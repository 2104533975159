import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getQueryStringParameter, getRouteParams } from 'utils/url';
import { addNotification } from 'actions/notification';

import {
    getCurrentUserIdSelector,
    getCurrentClientIdSelector,
    getIsGuestSelector
} from 'selectors/user';
import { selectors, actions, constants } from '@amplement/backend-connector';
import { setDeviceSettings } from 'actions/roomSettings';
import { getRoomSettings } from 'selectors/roomSettings';
import RoomScreen from './index';

const mapStateToProps = (state, ownProps) => {
    const _room = getRouteParams('_room')(ownProps);
    const _user = getCurrentUserIdSelector(state);
    const _client = getCurrentClientIdSelector(state);
    const currentClientMember = _room ? selectors.rooms.getMyClientAsARoomMemberSelector(state, _room) : undefined;
    const endeds = selectors.rooms.getEndedRoomsSelector(state);
    const isWsOpen = selectors.network.getIsWsOpenSelector(state);
    const isGuest = getIsGuestSelector(state);
    const roomSettings = getRoomSettings(state);

    const isLoaded =  true; // selectors.rooms.getRoomsLoaded(state);

    const isEnded = _room && endeds?.some(r => r?.id === _room);
    const kind = getQueryStringParameter('kind')(ownProps.location.search) || constants.rooms.ROOM_TYPE.AUDIO;

    return {
        kind,
        _room,
        _user,
        _client,
        status: currentClientMember?._client ? currentClientMember?.status : undefined,
        isLoading: !!_room && !isLoaded && !isGuest,
        isEnded,
        isWsOpen,
        roomSettings
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const _room = getRouteParams('_room')(ownProps);

    return {
        setOwnDevicesSettings: (settings) => dispatch(setDeviceSettings(settings)),
        requestPostRoomJoin: (audio, video) => dispatch(actions.rooms.requestPostRoomJoin(
            _room,
            audio,
            video
        )),
        addNotification: settings => dispatch(addNotification(settings)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RoomScreen));
