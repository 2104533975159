import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import DashboardBlock from 'components/Dashboard/DashboardBlock';
import FeedItem from 'components/Dashboard/LastFeedItems/FeedItem';
import Placeholder from 'components/Shared/Common/Placeholder';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

const StyledPlaceholder = styled(Placeholder)`
    margin-top: 75px;
`;

const LastFeedItems = (props) => {
    const {
        intl,
        feeds: feedProps,
        isLoading
    } = props;
    // take the only 17 first element to be coherent with other blocks
    const feeds = useMemo(() => feedProps.filter(({ id, lastItemPostedAt }) => !!id && lastItemPostedAt !== null)?.slice(0, 17), [feedProps]);

    const renderItem = useCallback(({ lastItemPosted, id, user, name, color }) => lastItemPosted && (
        <FeedItem
            feedItem={lastItemPosted}
            key={id}
            hasStatus={false}
            user={user}
            name={name}
            color={color}
            _feed={lastItemPosted?._feed}
        />
    ), []);

    useWhyDidYouUpdate('components:dashboard:LastFeedItems', props);

    return (
        <DashboardBlock
            title={intl.formatMessage({
                id: 'dashboard.lastFeedItems.title',
                defaultMessage: 'Dernières conversations'
            })}
            iconName="MessageCircle"
            content={(
                <div className="dashboard">
                    {feeds.length ? feeds.map(renderItem) : null}
                </div>
            )}
            isLoading={isLoading}
            itemsLength={feeds.length}
            placeholder={(
                <StyledPlaceholder
                    title={intl.formatMessage({
                        id: 'dashboard.lastFeedItems.placeholder.title',
                        defaultMessage: 'Vos dernières échanges apparaissent ici'
                    })}
                    subTitle={intl.formatMessage({
                        id: 'dashboard.lastFeedItems.placeholder.subtitle',
                        defaultMessage: 'Vous n\'avez pas encore conversé'
                    })}
                    name="placeholderGroups"
                />
            )}
        />
    );
}

LastFeedItems.propTypes = {
    feeds: PropTypes.array,
    intl: PropTypes.any.isRequired,
    isLoading: PropTypes.bool
}

LastFeedItems.defaultProps = {
    feeds: [],
    isLoading: false
}

export default injectIntl(memo(LastFeedItems, isEqual));
