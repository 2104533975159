import React, { memo } from 'react';
import Icon from 'components/Shared/Common/Icon';

const FileSliderThumbItem = () => (
    <Icon iconName="ZipFile" />
);
FileSliderThumbItem.propTypes = {
};

FileSliderThumbItem.defaultProps = {
};

export default memo(FileSliderThumbItem);
