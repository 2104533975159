import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/Shared/User/Avatar';
import FeedIcon from 'components/Shared/Common/FeedIcon';
import Link from 'components/Shared/Common/Link';

import { getUrl } from 'utils/url';
import { injectIntl } from 'react-intl';
import { Card, Typography } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';

const { Meta } = Card;
const { Text } = Typography;

const shorten = (str, n) => {
    let result = str;
    if (str) {
        result = (str.match(RegExp(`.{${n}}\\S*`))||[str])[0]; // eslint-disable-line
        if (str.length > n) {
            result = `${result}...`;
        }
    }
    return result;
}

const StyledYou = styled(Text)`
    color: ${props => rgba(props.theme.black, .6)};
    font-size: 14px;
`;

const StyledFeedItemName = styled(StyledYou)`
    color: ${props => props.theme.black};
    font-weight: bold;
    position: relative;
    display: flex;
`;

const StyledDashboardItemWrapper = styled(Link)`
    background-color: ${props => props.theme.white};
    border-radius: 6px;
    box-shadow: 0 2px 10px ${props => rgba(props.theme.black, 0.1)};
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    z-index: 2;
    .ant-card-meta{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        &-avatar{
            margin-right: 10px;
        }
    }
`;

const FeedItemContent = injectIntl(({
    intl,
    you,
    content
}) => (
    <>
        {you && (<StyledYou>{intl.formatMessage({ id: 'dashboard.whatYouMissed.you' })}:&nbsp;</StyledYou>)}
        <Text>{content}</Text>
    </>
))

const FeedItemUnicast = ({
    avatarUri,
    firstname,
    lastname,
    hasStatus,
    content,
    you,
}) => (
    <Meta
        avatar={(
            <Avatar
                src={avatarUri}
                alt={`${firstname} ${lastname}`}
                hasStatus={hasStatus}
            />
        )}
        title={<StyledFeedItemName>{`${firstname} ${lastname}`}</StyledFeedItemName>}
        description={<FeedItemContent you={you} content={content} />}
    />
);

const FeedItemMulticast = ({
    name,
    color,
    firstname,
    lastname,
    content,
    you
}) => (
    <Meta
        avatar={(
            <FeedIcon
                name={name}
                color={color}
            />
        )}
        title={<StyledFeedItemName>{you ? `@${name}` : `${firstname} ${lastname} @${name}`}</StyledFeedItemName>}
        description={<FeedItemContent you={you} content={content} />}
    />
);

const FeedItem = (props) => {
    const {
        feedItem: {
            you,
            metasFile,
            content,
            firstname,
            lastname,
        },
        hasStatus,
        user: { avatarUri, avatar } = {},
        user,
        _feed,
        intl,
        name,
        color
    } = props;

    const username = useMemo(() => ({
        firstname: firstname || (lastname ? '' : intl.formatMessage({ id: 'dashboard.unknown' })),
        lastname: lastname || '',
    }), [intl]);

    const feedUrl = getUrl('feed', { _feed });
    let template;

    if (!metasFile?.name && !content) return null;

    if (user) {
        template = (
            <FeedItemUnicast
                avatarUri={avatarUri || avatar}
                firstname={user.firstname}
                lastname={user.lastname}
                hasStatus={hasStatus}
                content={shorten(metasFile?.name || content, 420)}
                you={you}
            />
        );
    } else {
        template = (
            <FeedItemMulticast
                firstname={username.firstname}
                lastname={username.lastname}
                name={name}
                color={color}
                content={shorten(metasFile?.name || content, 420)}
                you={you}
            />
        );
    }

    return (
        <StyledDashboardItemWrapper
            href={feedUrl}
            className="ui-dashboard-item cursor"
        >
            {template}
        </StyledDashboardItemWrapper>
    );
}

FeedItem.propTypes = {
    feedItem: PropTypes.object,
    intl: PropTypes.any.isRequired,
    hasStatus: PropTypes.bool,
    user: PropTypes.object,
};

FeedItem.defaultProps = {
    feedItem: undefined,
    user: undefined,
    hasStatus: true
}

export default injectIntl(FeedItem);
