import { useMemo } from 'react';
import { selectors, utils } from '@amplement/backend-connector';
import { useSelector } from 'react-redux';
import { getUrl } from 'utils/url';
import Logger from 'services/debug/logger';

const logger = new Logger('saga:rooms');

type PhonenumberConfig = {
    prefix?: boolean | string
} | undefined;

type FormattedNumber = string;

type InputNumber = string;

type FormattedProps = {
    href?: string | null,
    target?: string
};

export default function usePhonenumber({ prefix = true } : PhonenumberConfig = {} ) {
    const sipPrefix: string | null = useSelector(selectors.sip.getSIPAccountPrefix) || '';

    return useMemo(() => {
        function format(number: InputNumber): FormattedNumber {
            const phoneNumber = number?.replace(/[\s:.]/gim, '')
            if (!phoneNumber) {
                return '';
            }
            if (prefix === true) {
                return utils.sip.formatNumber(phoneNumber, sipPrefix);
            } 
            if (prefix) {
                return utils.sip.formatNumber(phoneNumber, prefix);;
            }
            return phoneNumber;
        }

        function formatProps(phoneNumber: InputNumber): FormattedProps {
            const formattedPhonenumber = format(phoneNumber);
            return ({
                href: getUrl('roomByNumber', {
                    destNumber: formattedPhonenumber
                }),
                target: `roomByNumber-${formattedPhonenumber}`
            });
        }

        function makeCall(phoneNumber: InputNumber): void {
            const numberProps = formatProps(phoneNumber);

            if (numberProps.href) {
                window.open(numberProps.href, numberProps.target);
            } else {
                logger.error('No href found for number', phoneNumber);
            }
        }

        return ({
            format,
            formatProps,
            makeCall
        });
    }, [sipPrefix, prefix]);
}
