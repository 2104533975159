import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MissedInfos from 'components/Dashboard/MissedInfos/container';
import LastFiles from 'components/Dashboard/LastFiles/container';
// import MyAvatar from 'components/Dashboard/MyAvatar';
import LastFeedItems from 'components/Dashboard/LastFeedItems/container';

import { $getCompanyPlugins } from 'api/company';
import CustomWidgets from 'components/Dashboard';
import { StyledPageLayout } from 'components/Shared/style';
import { Row, Col } from 'antd';
import { WIDGET_COMPONENT_MAPPING } from 'const/company';
import { useQuery } from 'react-query';

const containerStyle = { width: '100%' };
const gutter = [20, 20];
const Dashboard = ({ _company, _me }) => {
    const { data: widgets } = useQuery(
        ['companyWidgets', { _company }],
        async () => {
            const response = await $getCompanyPlugins(_company);

            return response.data?.plugins?.dashboardWidgets?.filter(({ name }) => !!WIDGET_COMPONENT_MAPPING[name]);
        },
        { 
            refetchOnWindowFocus: false, 
            refetchOnMount: false, 
            staleTime: 60000,
        }
    );

    return (
        <StyledPageLayout className="ui-dashboard-page">
            <Row gutter={gutter} style={containerStyle}>
                {!!widgets?.length && (
                    <Col xs={24} md={12}>
                        <CustomWidgets widgets={widgets} _me={_me} />
                    </Col>
                )}
                {/* <Col xs={24} lg={12}>
                    <MyAvatar />
                </Col> */}
                <Col xs={24} lg={12}>
                    <LastFeedItems />
                </Col>
                <Col xs={24} lg={12}>
                    <LastFiles />
                    <MissedInfos />
                </Col>
            </Row>
        </StyledPageLayout>
    );
}

Dashboard.propTypes = {
    _me: PropTypes.string.isRequired,
    _company: PropTypes.string.isRequired,
};

export default memo(Dashboard);
