import { compose } from 'redux';
import { FormSubmissionError } from 'final-form';
import { connect } from 'react-redux';
import { getCurrentUserSelector } from 'selectors/user';
import { requestPatchUserPassword } from 'actions/session';
import injectValidators from 'components/Shared/Forms/injectValidators';

import Form from './index';

const mapStateToProps = state => ({
    initialValues: getCurrentUserSelector(state).settings
});

const mapDispatchToProps = dispatch => ({
    onSubmit: ({ newPassword, confirmPassword, currentPassword }, form) => {
        if (newPassword !== confirmPassword) {
            throw new FormSubmissionError('settings.resetPassword.notification.fail');
        } else {
            dispatch(requestPatchUserPassword(currentPassword, newPassword));
            form.restart();
        }
    }
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectValidators
)(Form);
