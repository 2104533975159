import React, { createContext, useContext, useEffect, useMemo, useState, memo, useRef } from 'react';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import styled from 'styled-components';
import { selectors, callbacks, utils } from '@amplement/backend-connector';
import { message } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { applyTrack } from 'sagas/room';
import useRoomSelector from 'hooks/useRoomSelector';
import useMyStreamSelector from 'hooks/useMyStreamSelector';
import { getStream } from 'utils/stream';
import Logger from 'services/debug/logger';
import {
    cleanupWebGLResources,
    createTexture,
    drawSegmentationTextures,
    initBuffers,
    initFrameBuffer,
    prepareRenderShaders,
} from 'webgl/webgl-setup';

import { CLEAR_TIMEOUT, SET_TIMEOUT, TIMEOUT_TICK, timerWorkerScript } from './TimeWorker';
import { selfieSegmentationCdnUrl, framerate, initTimer } from './constants';
import {
    copyMediaStream,
    isWebGLSupported,
    stopVideoTracks,
    waitForVideoReady,
    removeSegmentation,
} from './utils';

const StreamManager = utils.streamManager;

const logger = new Logger('component:WebglProvider');

const WebGLContext = createContext({});
export const useWebgl = () => useContext(WebGLContext);

const StyledCanvas = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    z-index: -1;
    border-radius: 4px;
    object-fit: cover;
`;

const hiddenStyle = {
    display: 'none',
};

type WebglStatusType = 'normal' | 'blur' | 'image';
type WebglContextType = {
    children: React.ReactNode;
    _room: string;
} & WrappedComponentProps;

const duration = 10;
const maskFrameTimeWorker = new Worker(timerWorkerScript, { name: 'Blur effect worker' });
let lastSegmentationTime = 0;

const messageKeyWarn = 'blurNotificationWarn';
const webglInitializationErrorKey = 'webglInitializationError';

const resetSelfieSegmentation = (
    selfieSegmentation, gl, buffers,
    video, overlayType, blurStrength,
    textureNormal, blurTexture, maskTexture, resizeTexture,
    gaussBlurHorizontalTexture, gaussBlurVerticalTexture,
    shaderProgramNormal, shaderProgramBlur, shaderProgramFusionBlur,
    shaderProgramGaussianHorizontalBlur, shaderProgramGaussianVerticalBlur,
    blurFbo,
    gaussBlurHorizontalFbo, gaussBlurVerticalFbo,
    normalResizeFbo,
) => {
    if (selfieSegmentation) {
        selfieSegmentation.close();
    }

    removeSegmentation();
    // eslint-disable-next-line no-param-reassign
    selfieSegmentation = new SelfieSegmentation({
        locateFile: (file) => `${selfieSegmentationCdnUrl}${file}`
    });
    selfieSegmentation.setOptions({ modelSelection: 1 });
    selfieSegmentation.onResults((results) => {
        drawSegmentationTextures({
            gl,
            buffers,
            video,
            segmentationMask: results.segmentationMask,
            overlayType,
            blurStrength,
            textureNormal, blurTexture, maskTexture, resizeTexture,
            gaussBlurHorizontalTexture, gaussBlurVerticalTexture,
            shaderProgramNormal, shaderProgramBlur, shaderProgramFusionBlur,
            shaderProgramGaussianHorizontalBlur, shaderProgramGaussianVerticalBlur,
            blurFbo,
            gaussBlurHorizontalFbo, gaussBlurVerticalFbo,
            normalResizeFbo,
        });
    });
};

const initWebGL = async (
    gl,
    canvas,
    video,
    smallCanvas,
    stream,
    overlayType,
    blurStrength,
    hasTrack,
    _room,
    _client,
    setNewStream,
    setIsWebGLInitialized,
    isScreenShareActivated,
    api,
    i18n
) => {
    const smCanvasContext = smallCanvas.getContext('2d');

    await waitForVideoReady(video);

    const shaders = prepareRenderShaders(gl);
    if (!shaders) {
        logger.error("Failed to prepare shaders.");
        return null;
    }

    const {
        shaderProgramNormal,
        shaderProgramBlur,
        shaderProgramFusionBlur,
        shaderProgramLightWrapping,
        shaderProgramGaussianVerticalBlur,
        shaderProgramGaussianHorizontalBlur,
    } = shaders;

    const buffers = initBuffers(gl, shaderProgramNormal);

    const normalFbo = gl.createFramebuffer();
    const textureNormal = createTexture(gl);
    const normalResizeFbo = gl.createFramebuffer();
    const resizeTexture = createTexture(gl, true);
    const blurFbo = gl.createFramebuffer();
    const blurTexture = createTexture(gl, true);
    const gaussBlurHorizontalFbo = gl.createFramebuffer();
    const gaussBlurHorizontalTexture = createTexture(gl);
    const gaussBlurVerticalFbo = gl.createFramebuffer();
    const gaussBlurVerticalTexture = createTexture(gl);
    const maskFbo = gl.createFramebuffer();
    const maskTexture = createTexture(gl);
    const lightWrappingFbo = gl.createFramebuffer();
    const lightWrappingTexture = createTexture(gl);

    const framebuffers = [
        { fbo: normalFbo, texture: textureNormal, program: shaderProgramNormal, small: false },
        { fbo: normalResizeFbo, texture: resizeTexture, program: shaderProgramNormal, small: true },
        { fbo: blurFbo, texture: blurTexture, program: shaderProgramBlur, small: true },
        { fbo: gaussBlurHorizontalFbo, texture: gaussBlurHorizontalTexture, program: shaderProgramGaussianHorizontalBlur, small: false },
        { fbo: gaussBlurVerticalFbo, texture: gaussBlurVerticalTexture, program: shaderProgramGaussianVerticalBlur, small: false },
        { fbo: maskFbo, texture: maskTexture, program: shaderProgramFusionBlur, small: false },
        { fbo: lightWrappingFbo, texture: lightWrappingTexture, program: shaderProgramLightWrapping, small: false },
    ];

    framebuffers.forEach(({ fbo, texture, program, small }) => {
        gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
        const error = initFrameBuffer(gl, texture, program, buffers, video, small) as { error: string } | undefined;
        if (error) {
            logger.error('Error initializing framebuffer:', error);
        }
    });

    removeSegmentation();
    // eslint-disable-next-line
    let selfieSegmentation = new SelfieSegmentation({
        locateFile: (file) => `${selfieSegmentationCdnUrl}${file}`
    });
    selfieSegmentation.setOptions({ modelSelection: 1 });

    selfieSegmentation.onResults((results) => {
        drawSegmentationTextures({
            gl,
            buffers,
            video,
            segmentationMask: results.segmentationMask,
            overlayType,
            blurStrength,
            textureNormal, blurTexture, maskTexture, resizeTexture,
            gaussBlurHorizontalTexture, gaussBlurVerticalTexture,
            shaderProgramNormal, shaderProgramBlur, shaderProgramFusionBlur,
            shaderProgramGaussianHorizontalBlur, shaderProgramGaussianVerticalBlur,
            blurFbo,
            gaussBlurHorizontalFbo, gaussBlurVerticalFbo,
            normalResizeFbo,
        });
    });

    const processVideo = () => {
        if (video.readyState >= video.HAVE_ENOUGH_DATA && smCanvasContext && selfieSegmentation) {
            smCanvasContext.drawImage(video, 0, 0, smallCanvas.width, smallCanvas.height);
            if (overlayType !== 'normal' && hasTrack && !isScreenShareActivated) {
                if (smallCanvas.width > 0) {
                    selfieSegmentation.send({ image: smallCanvas }).then(() => {
                        maskFrameTimeWorker.postMessage({ id: SET_TIMEOUT, timeMs: framerate });
                    }).catch((error) => {
                        logger.error("Error during SelfieSegmentation processing:", error);
                        resetSelfieSegmentation(
                            selfieSegmentation, gl, buffers,
                            video, overlayType, blurStrength,
                            textureNormal, blurTexture, maskTexture, resizeTexture,
                            gaussBlurHorizontalTexture, gaussBlurVerticalTexture,
                            shaderProgramNormal, shaderProgramBlur, shaderProgramFusionBlur,
                            shaderProgramGaussianHorizontalBlur, shaderProgramGaussianVerticalBlur,
                            blurFbo,
                            gaussBlurHorizontalFbo, gaussBlurVerticalFbo,
                            normalResizeFbo
                        );
                        maskFrameTimeWorker.postMessage({ id: SET_TIMEOUT, timeMs: framerate });
                    });
                } else {
                    maskFrameTimeWorker.postMessage({ id: SET_TIMEOUT, timeMs: framerate });
                }
            } else {
                maskFrameTimeWorker.postMessage({ id: CLEAR_TIMEOUT });
                const copiedStream = copyMediaStream(stream);
                applyTrack(_room, _client, copiedStream.getVideoTracks()[0], true);
                cleanupWebGLResources(gl, {
                    textures: [
                        textureNormal,
                        resizeTexture,
                        blurTexture,
                        gaussBlurHorizontalTexture,
                        gaussBlurVerticalTexture,
                        maskTexture,
                        lightWrappingTexture,
                    ],
                    framebuffers: [
                        normalFbo,
                        normalResizeFbo,
                        blurFbo,
                        gaussBlurHorizontalFbo,
                        gaussBlurVerticalFbo,
                        maskFbo,
                        lightWrappingFbo,
                    ],
                    programShaders: [
                        shaderProgramNormal,
                        shaderProgramBlur,
                        shaderProgramFusionBlur,
                        shaderProgramGaussianHorizontalBlur,
                        shaderProgramGaussianVerticalBlur,
                    ]
                });
            }
        } else {
            logger.warn(
                'Video not ready or selfieSegmentation not initialized. video.readyState:',
                video.readyState,
                'smCanvasContext:',
                !!smCanvasContext,
                'selfieSegmentation:',
                !!selfieSegmentation
            );
            maskFrameTimeWorker.postMessage({ id: SET_TIMEOUT, timeMs: framerate });
        }
    };

    const handleTick = () => {
        const now = Date.now();
        if (now - lastSegmentationTime > framerate) {
            processVideo();
            lastSegmentationTime = now;
        }
    };

    maskFrameTimeWorker.onmessage = ({ data }) => {
        if (data.id === TIMEOUT_TICK) {
            handleTick();
        }
    };

    if (overlayType === 'blur' || overlayType === 'image') {
        api.open({
            type: 'warning',
            key: messageKeyWarn,
            content: i18n?.blurWarning,
            duration,
        });
        const capturedStream = canvas.captureStream(60);
        setNewStream(capturedStream);
        const copiedStream = copyMediaStream(capturedStream);
        applyTrack(_room, _client, copiedStream.getVideoTracks()[0], true);
        maskFrameTimeWorker.postMessage({ id: SET_TIMEOUT, timeMs: initTimer });
    }

    return {
        selfieSegmentation,
        cleanup: () => {
            maskFrameTimeWorker.postMessage({ id: CLEAR_TIMEOUT });
            cleanupWebGLResources(gl, {
                textures: [
                    textureNormal,
                    resizeTexture,
                    blurTexture,
                    gaussBlurHorizontalTexture,
                    gaussBlurVerticalTexture,
                    maskTexture,
                    lightWrappingTexture,
                ],
                framebuffers: [
                    normalFbo,
                    normalResizeFbo,
                    blurFbo,
                    gaussBlurHorizontalFbo,
                    gaussBlurVerticalFbo,
                    maskFbo,
                    lightWrappingFbo,
                ],
                programShaders: [
                    shaderProgramNormal,
                    shaderProgramBlur,
                    shaderProgramFusionBlur,
                    shaderProgramGaussianHorizontalBlur,
                    shaderProgramGaussianVerticalBlur,
                ]
            });
            setIsWebGLInitialized(false);
        }
    };
};

const WebGLProvider = ({ children, _room, intl }: WebglContextType) => {
    const { hasVideo: hasTrack, isSharingScreen: isScreenSharing, hasScreen } = useMyStreamSelector(_room) || {};
    const storageFilter = localStorage.getItem('videoFilter') as WebglStatusType | null;
    const [overlayType, setOverlayType] = useState<WebglStatusType>(storageFilter || 'normal');
    const [blurStrength, setBlurStrength] = useState<number>(150.0);
    const { _client } = useRoomSelector(selectors.rooms.getMyClientAsARoomMemberSelector, _room) || {};
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [newStream, setNewStream] = useState<MediaStream | null>(null);
    const [isScreenShare, setIsScreenShare] = useState<boolean>(false);
    const [hasVideo, setHasVideo] = useState<boolean>(false);
    const [isWebGLInitialized, setIsWebGLInitialized] = useState<boolean>(false);
    const [videoStream, setLocalStream] = useState<MediaStream | null>(null);
    const [api, contextHolder] = message.useMessage();

    const webglCanvas = useRef<HTMLCanvasElement>(null);
    const smallCanvas = useRef<HTMLCanvasElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const glRef = useRef<WebGLRenderingContext | null>(null);
    const selfieSegmentationRef = useRef<SelfieSegmentation>();

    const i18n = useMemo(() => ({
        blurWarning: intl.formatMessage({ id: 'room.actions.blurWarning' }),
        webglErrorMessage: intl.formatMessage({ id: 'room.actions.webglErrorMessage' }),
    }), [intl]);

    const isScreenShareActivated = useMemo(() =>
        isScreenSharing || hasScreen || isScreenShare,
    [isScreenSharing, hasScreen, isScreenShare]);

    const videoBCStream = StreamManager.getVideoStream({
        _client,
        _entity: _room,
        checkEnabled: false,
        takeLast: true
    });

    useEffect(() => {
        // set video stream from backend connector fallback
        if (videoBCStream && !videoStream) {
            setLocalStream(videoBCStream);
        }
    }, [videoBCStream]);

    useEffect(() => {
        callbacks.set('getStream', (kind) => getStream(kind, setLocalStream));
    }, []);

    useEffect(() => {
        const initializeStream = async () => {
            if (videoStream && (hasTrack || isScreenShareActivated)) {
                const copiedStream = copyMediaStream(videoStream);
                setStream(copiedStream);
            } else if (!hasTrack && !isScreenShareActivated && stream) {
                stopVideoTracks(stream);
                if (newStream) {
                    stopVideoTracks(newStream);
                    setNewStream(null);
                }
                setStream(null);
            }
        };

        if (_client && _room) {
            initializeStream();
        }

        return () => {
            if (stream) {
                stopVideoTracks(stream);
            }
            if (newStream) {
                stopVideoTracks(newStream);
            }
        };
    }, [videoStream?.id, hasTrack, isScreenShareActivated, _client, _room]);

    useEffect(() => {
        if (!videoRef.current || !stream) return;

        const handleLoadedMetadata = () => {
            if (!videoRef.current || !webglCanvas.current) return;

            if (!stream) {
                videoRef.current.srcObject = null;
                return;
            }

            const width = videoRef.current.videoWidth;
            const height = videoRef.current.videoHeight;
            webglCanvas.current.width = width;
            webglCanvas.current.height = height;

            if (smallCanvas.current) {
                smallCanvas.current.width = width / 8;
                smallCanvas.current.height = height / 8;
                setBlurStrength(width * 0.5);
            }

            if (!glRef.current) {
                const gl = webglCanvas.current.getContext('webgl');
                if (!gl) {
                    logger.error('Unable to initialize WebGL.');
                    return;
                }
                glRef.current = gl;
            }
        };

        videoRef.current.srcObject = stream;
        videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

        // eslint-disable-next-line consistent-return
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
        };
    }, [stream]);

    useEffect(() => {
        if (
            !webglCanvas.current ||
            !videoRef.current ||
            !smallCanvas.current ||
            !stream ||
            !hasTrack ||
            isScreenShareActivated
        ) return;

        const gl = glRef.current;
        if (!gl) return;

        const initializeWebGL = async () => {
            const webgl = await initWebGL(
                gl,
                webglCanvas.current,
                videoRef.current,
                smallCanvas.current,
                stream,
                overlayType,
                blurStrength,
                hasTrack,
                _room,
                _client,
                setNewStream,
                setIsWebGLInitialized,
                isScreenShareActivated,
                api,
                i18n
            ) as { selfieSegmentation: SelfieSegmentation, cleanup: () => void };

            if (!webgl) {
                api.open({
                    type: 'error',
                    key: webglInitializationErrorKey,
                    content: i18n?.webglErrorMessage,
                    duration,
                });
            }

            const { selfieSegmentation, cleanup } = webgl;

            selfieSegmentationRef.current = selfieSegmentation;

            setIsWebGLInitialized(true);

            return cleanup;
        };

        initializeWebGL().then(cleanup => {
            if (cleanup) {
                return cleanup;
            }
            return null;
        });
    }, [
        overlayType,
        hasTrack,
        blurStrength,
        stream,
        isScreenShareActivated,
        isWebGLInitialized
    ]);

    useEffect(() => {
        localStorage.setItem('videoFilter', overlayType);
        if (hasTrack && overlayType === 'blur' && !isScreenShareActivated) {
            api.open({
                type: 'warning',
                key: messageKeyWarn,
                content: i18n?.blurWarning,
                duration,
            });
        }
        if (videoStream && (overlayType === 'normal' || isScreenShareActivated)) {
            const copiedStream = copyMediaStream(videoStream);
            applyTrack(_room, _client, copiedStream.getVideoTracks()[0], true);
        }
    }, [
        hasTrack,
        overlayType,
        videoStream,
        isScreenShareActivated,
    ]);

    const webglSupported = useMemo(() => isWebGLSupported(), []);

    const value = useMemo(() => ({
        overlayType,
        initialVideoStream: videoStream,
        videoStream: webglCanvas.current && overlayType !== 'normal' && !isScreenShareActivated ? newStream : videoStream,
        blurStrength,
        isWebglSupported: webglSupported,
        isScreenShareActivated,
        hasVideo,
        setOverlayType,
        setIsScreenShare,
        setBlurStrength,
        setHasVideo,
    }), [
        overlayType,
        blurStrength,
        videoStream,
        newStream,
        isScreenShareActivated,
        webglCanvas.current,
        webglSupported,
        hasVideo
    ]);

    if (!webglSupported) {
        return children;
    }

    return (
        <WebGLContext.Provider value={value}>
            {contextHolder}
            {children}
            <video style={hiddenStyle} ref={videoRef} autoPlay />
            <canvas style={hiddenStyle} ref={smallCanvas} />
            <StyledCanvas ref={webglCanvas} />
        </WebGLContext.Provider>
    );
};

export default memo(injectIntl(WebGLProvider));
