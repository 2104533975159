import { OPEN_MODAL, CLOSE_MODAL } from 'types/modal';

export const openModal = (modalType, props) => ({
    type: OPEN_MODAL,
    modalType,
    ...props
});

export const closeModal = modalType => ({
    type: CLOSE_MODAL,
    modalType
});
