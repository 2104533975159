import React, { memo } from 'react';
import PropTypes from 'prop-types';

const VideoPreviewItem = ({ file }) => (
    <div className="img-container">
        <video src={(window.URL || window.webkitURL).createObjectURL(file)} controls />
    </div>
);

VideoPreviewItem.propTypes = {
    file: PropTypes.object.isRequired
};

VideoPreviewItem.defaultProps = {
};

export default memo(VideoPreviewItem);
