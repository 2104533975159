import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactImageFallback from 'react-image-fallback';
import styled from 'styled-components';
import { connect } from 'react-redux';
import  { selectors } from '@amplement/backend-connector';
import classNames from 'classnames';

const StyledImage = styled(ReactImageFallback)`
    &.circle{
        border-radius: 50%!important;
    }
`;

const Image = memo(({ src, fallbackSrc, srcSet, className, alt, isOnline, shape }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const spinner = <div className="loading" />;

    const handleSuccess = useCallback(() => setIsSuccess(true), [setIsSuccess]);

    useEffect(() => {
        if (isSuccess) setIsSuccess(false);
    }, [src, srcSet])

    if (!isSuccess && !isOnline) {
        return spinner;
    }

    return (
        <StyledImage
            src={src}
            srcSet={srcSet}
            fallbackImage={fallbackSrc}
            initialImage={spinner}
            alt={alt}
            className={classNames(
                className,
                shape
            )}    
            onLoad={handleSuccess}
            isOnline={isOnline}
        />
    );
});

Image.propTypes = {
    isOnline: PropTypes.bool.isRequired,
    className: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    fallbackSrc: PropTypes.string.isRequired,
    alt: PropTypes.string,
    shape: PropTypes.oneOf(['circle'])
};

Image.defaultProps = {
    className: '',
    src: null,
    srcSet: null,
    alt: '',
    shape: null
};

export const Component = Image;

const mapStateToProps = (state) => ({
    isOnline: selectors.network.getIsOnlineSelector(state)
});

export default connect(mapStateToProps, null)(Image);
