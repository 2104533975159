import React, { lazy, Suspense, memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/Shared/Common/Button';
import { defaultColors } from 'common/themes/Colors';
import Spinner from 'components/Shared/Common/Spinner';
import { StyledPickedColor, StyledPickerColorWrapper } from 'components/Shared/styles';

const TwitterPicker = lazy(() => import('./TwitterPicker'));

const _ColorSelector = ({
    onChange,
    colors,
    value,
}) => (
    <Suspense fallback={<Spinner />}>
        <TwitterPicker
            colors={colors}
            triangle="hide"
            color={value}
            onChange={onChange}
        />
    </Suspense>
);

_ColorSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    colors: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired
};

_ColorSelector.defaultProps = {
    colors: defaultColors,
};

export const ColorSelector = memo(_ColorSelector);

const ColorPicker = ({
    onChange,
    colors,
    value
}) => {
    const [isOpen, setIsOpen] = useState();
    const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
    const handleChange = useCallback((val) => {
        onChange(val);
        setIsOpen(false);
    }, [onChange]);

    const color = value?.hex ?? value;

    return (
        <>
            <StyledPickedColor
                className="ui-color"
                style={{
                    backgroundColor: color
                }}
            >
                <Button
                    onClick={handleClick}
                    style={{
                        backgroundColor: color
                    }}
                    iconName="Droplet"
                />
            </StyledPickedColor>
            <StyledPickerColorWrapper 
                className={classNames(
                    'ui-color-picker',
                    { active: isOpen }
                )}>
                <ColorSelector
                    colors={colors}
                    triangle="top-right"
                    onChange={handleChange}
                    isOpen={isOpen}
                    value={color}
                />
            </StyledPickerColorWrapper>
        </>
    );
}

ColorPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    colors: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired
};

ColorPicker.defaultProps = {
    colors: undefined,
};

export default memo(ColorPicker);
