import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import Upload from 'components/Shared/Common/Upload';

const UploadButton = ({ isDisabled, onAddFiles }) => (
    <Upload onAdd={onAddFiles} disabled={isDisabled}>
        <Button
            color="primary"
            iconName="Plus"
            isDisabled={isDisabled}
        />
    </Upload>
);

UploadButton.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    onAddFiles: PropTypes.func.isRequired
};

UploadButton.defaultProps = {
};

export default memo(UploadButton);
