import { connect } from 'react-redux';
import { selectors, constants } from '@amplement/backend-connector';

import Component from 'components/Room/Layout/ContentMaskManager';

const mapStateToProps = (state, ownProps) => {
    const members = selectors.rooms.getConnectedRoomMembersByRoomIdSelector(state, ownProps._room);
    const currentMember = selectors.rooms.getMyClientAsARoomMemberSelector(state, ownProps._room);
    let isHold = false;

    if (currentMember?.status === constants.rooms.USER_IN_ROOM_STATUS.HOLD) {
        isHold = true;
    } else if (members?.length === 2) {
        isHold = members.some(m => m?.status === constants.rooms.USER_IN_ROOM_STATUS.HOLD);
    }

    return {
        isHold
    };
};

export default connect(
    mapStateToProps,
    null
)(Component);
