import { createSelector } from 'reselect';
import { presenceEntitiesSelector } from 'selectors/entity';
import { getCurrentUserStatusSelector, getCurrentUserIdSelector } from 'selectors/user';

const _userParameter = (_, _user) => _user;

export const getPresenceSelector = state => state.entities.presences;

export const getStatusById = createSelector(
    _userParameter,
    presenceEntitiesSelector,
    getCurrentUserStatusSelector,
    getCurrentUserIdSelector,
    (_user, presences, currentStatus, _me) =>
        _user && _me === _user ? currentStatus : presences[_user]
);
