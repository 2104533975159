export const findBestAvailableLanguage = (supportedLanguages) => {
    const languageTag = (navigator.language || navigator.userLanguage)?.slice(0, 2)?.toLowerCase();

    if (supportedLanguages?.indexOf(languageTag) === -1)
        return undefined;

    return ({
        languageTag,
        isRTL: false
    });
}
