import React from 'react';
import PropTypes from 'prop-types';
import RoomCallNotificationModalItem from 'components/Shared/Call/Notifications/RoomCallNotificationModalItem';

const RoomCallNotificationModalList = ({
    _rooms
}) => _rooms?.length ?
    _rooms
        .filter(_room => window.location.pathname?.indexOf(_room) === -1)
        .map(id => (
            <RoomCallNotificationModalItem
                key={id}
                _room={id}
            />
        )) : null;

RoomCallNotificationModalList.propTypes = {
    _rooms: PropTypes.array
};

RoomCallNotificationModalList.defaultProps = {
    _rooms: undefined
};

export default RoomCallNotificationModalList;
