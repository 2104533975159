import { useState, useEffect } from 'react';
import useInterval from 'hooks/useInterval';

const DELAY_SECONDS = 1000;
const DELAY_MINUTES = 60 * DELAY_SECONDS;
const DELAY_HOURS = 60 * DELAY_MINUTES;
const DELAY_DAYS = 24 * DELAY_HOURS;

const getDelay = (createdAt) => {
    let from = createdAt;
    if (!(from instanceof Date)) {
        from = new Date(createdAt);
    }
    const diff = Date.now() - from;

    if (diff < DELAY_SECONDS * 44) {
        return DELAY_SECONDS * 5;
    }

    if (diff < DELAY_MINUTES) {
        return DELAY_SECONDS * 15;
    }

    if (diff < DELAY_HOURS) {
        return DELAY_MINUTES;
    }

    if (diff< DELAY_DAYS) {
        return DELAY_HOURS;
    }

    return DELAY_DAYS;
}

const useDateInterval = (startDate) => {
    const [delay, setDelay] = useState(getDelay(startDate));
    const [state, setState] = useState({});

    useInterval(() => {
        setState({});
    }, delay);

    useEffect(() => {
        const newDelay = getDelay(startDate);
        if (delay !== newDelay) {
            setDelay(newDelay);
        }
    }, [state]);
};

export default useDateInterval;
