import styled, { css, keyframes } from 'styled-components';
import { rgba, lighten } from 'polished';
import { colors } from 'common/themes/Colors';
import Link from 'components/Shared/Common/Link';
import { AVATAR_SIZES } from 'const/user';

const placeHolderShimmer = keyframes`
    0% {background-position: -468px 0;}
    100% {background-position: 468px 0;}
`;

const animatedBackground = css`
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: ${lighten(0.95, colors.black)};
    background: linear-gradient(to right, #F6F6F6 8%, ${lighten(0.70, colors.black)} 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
`;

export const Styled =  styled(Link)`

    position: relative;
    width: ${AVATAR_SIZES.default}px;
    height: ${AVATAR_SIZES.default}px;
    display: flex;
    flex-shrink: 0;

    &.loading{
        flex-shrink: 0;
        img,
        .s-status,
        .s-status-icon,
        .s-status-label{
            display: none;
        }
        &::before{
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            ${animatedBackground};
            z-index: 2;
            height: 100%;
            width: 100%;
            flex-shrink: 0;
            border-radius: 50%;
        }
    }
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        flex-shrink: 0;
    }
    &:hover{
        .edition-container{
            opacity: 1;
            visibility: visible;
        }
    }
    &.xs,
    &.sm,
    &.md{
        .s-status-label{
            padding: 1px 3px 0;
        }
        .s-status-icon{
            padding: 5px;
            svg{
                width: 7px;
                height: 7px;
            }
        }
    }
    &.xxxl,
    &.xxxxl{
        .s-status-label{
            font-size: 12px;
            padding: 5px 10px;
        }
        .s-status-icon{
            padding: 15px;
            top: 75%;
            svg{
                width: 18px;
                height: 18px;
            }
        }
    }
    .edition-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => rgba(props.theme.mainColor2, 0.6)};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 250ms ease;
        opacity: 0;
        visibility: hidden;
        input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            opacity: 0;

        }
        .icon{
            svg{
                width: 30px;
                height: 30px;
                color: ${colors.white};
            }
        }
    }
    &.xxxxl{
        width: ${AVATAR_SIZES.xxxxl}px;
        height: ${AVATAR_SIZES.xxxxl}px;
        .s-status{
            border-width: 4px;
            width: 30px;
            height: 30px;
            bottom: 0;
            right: 15px;
        }
    }
    &.xxxl{
        width: ${AVATAR_SIZES.xxxl}px;
        height: ${AVATAR_SIZES.xxxl}px;
        .s-status{
            border-width: 4px;
            width: 30px;
            height: 30px;
            bottom: 0;
            right: 15px;
        }
    }
    &.md{
        width: ${AVATAR_SIZES.md}px;
        height: ${AVATAR_SIZES.md}px;
    }
    &.xl{
        width: ${AVATAR_SIZES.xl}px;
        height: ${AVATAR_SIZES.xl}px;
        .s-status{
            width: 15px;
            height: 15px;
            right: 2px;
        }
    }
    &.lg{
        width: ${AVATAR_SIZES.lg}px;
        height: ${AVATAR_SIZES.lg}px;
        .s-status{
            width: 14px;
            height: 14px;
        }
    }
    &.sm{
        width: ${AVATAR_SIZES.sm}px;
        height: ${AVATAR_SIZES.sm}px;
    }
    &.xs{
        width: ${AVATAR_SIZES.xs}px;
        height: ${AVATAR_SIZES.xs}px;
        .s-status{
            width: 10px;
            height: 10px;
        }
    }


`;

export const StyledStatus = styled.div`
    &.s-status{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.white};
        position: absolute;
        background-color: ${({ theme }) => theme.success};
        bottom: -3px;
        right: -2px;
        &-icon,
        &-label{
            position: absolute;
            background-color: ${({ theme }) => theme.success};
            top: 80%;
            right: -2px;
        }
        &-icon{
            padding: 10px;
            border-radius: 50%;
            border: 2px solid ${({ theme }) => theme.white};
            display: flex;
            align-items: center;
            justify-content: center;
            top: 70%;
            svg{
                width: 10px;
                height: 10px;
                color: ${({ theme }) => theme.white};
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
        &-label{
            padding: 3px 3px 2px;
            border-radius: 30px;
            border: 2px solid ${({ theme }) => theme.white};
            color: ${({ theme }) => theme.white};
            font-size: 8px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
        }
    
        &.online{
            background-color: ${({ theme }) => theme.status.success}!important;
        }
        &.busy{
            background-color: ${({ theme }) => theme.status.busy}!important;
        }
        &.away{
            background-color: ${({ theme }) => theme.status.away}!important;
        }
        &.offline{
            background-color: ${({ theme }) => theme.status.offline}!important;
        }
    }
`;
