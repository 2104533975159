import { createCachedSelector } from 're-reselect';

export const getAvailableDevices = state => state.roomSettings.devices;
export const getEnumerateDevicesError = state => state.roomSettings.enumerateDeviceError;

export const getPermissionState = state => state.roomSettings.permissionState;

export const getSpeakerId = state => state.roomSettings.settings?.audiooutput;
export const getMicrophoneId = state => state.roomSettings.settings?.audioinput;
export const getCameraId = state => state.roomSettings.settings?.videoinput;
export const getRoomSettings = state => state.roomSettings.settings;

const _getRoomParameter = (_, kind) => kind;

export const getAvailableDeviceIdByKind = createCachedSelector(
    getRoomSettings, _getRoomParameter,
    (settings, kind) => settings?.[kind]
)((state, kind) => kind || 'all');

export const getAvailableDevicesByKind = createCachedSelector(
    getAvailableDevices, _getRoomParameter,
    (devices, kind) => devices?.filter(d => !kind || d.kind === kind)
)((state, kind) => kind || 'all');

