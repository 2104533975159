import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import Icon from 'components/Shared/Common/Icon';
import SchedulerWrapper from 'components/Shared/Forms/Scheduler/SchedulerWrapper';
import Action from 'components/Shared/Common/Action';
import AntdModal from 'components/Shared/Modals';

const SendEmailInvitation = memo((props) => {
    const {
        intl,
        onClose
    } = props;
    const [isModalOpened, setIsModalOpened] = useState();

    const handleToggleModal = useCallback(() => {
        setIsModalOpened(!isModalOpened);
        onClose();
    }, [onClose, setIsModalOpened, isModalOpened]);

    const handleCloseModal = useCallback(() => setIsModalOpened(false), [setIsModalOpened]);

    useWhyDidYouUpdate('components:room:Footer:MoreButton:SendEmailInvitation', props);

    return (
        <>
            {isModalOpened && (
                <AntdModal
                    title={(
                        <>
                            {/* todo */}
                            <Icon iconName="Mail" />
                            {intl.formatMessage({ id: 'room.scheduleForm.title' })}
                        </>
                    )}
                    isOpen={isModalOpened}
                    onClose={handleCloseModal}
                    destroyOnClose
                    // maskClosable={false}
                    // closable={false}
                    width={600}
                    footer={null}
                    isAutoScroll
                >
                    <SchedulerWrapper onClose={handleCloseModal} />
                </AntdModal>
            )}
            <Action
                className="line-item"
                onClick={handleToggleModal}
            >
                <div className="line">
                    <Icon iconName="Mail" />
                </div>
                <div className="line-wrapper">
                    <div className="line column">
                        <div className="label">{intl.formatMessage({ id: 'room.scheduleForm.title' })}</div>
                    </div>
                </div>
            </Action>
        </>
    );
})

SendEmailInvitation.propTypes = {
    intl: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired
};

SendEmailInvitation.defaultProps = {
};

export default injectIntl(SendEmailInvitation);
