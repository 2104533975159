import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Input } from "antd";
import { UserAddOutlined } from '@ant-design/icons';
import { Field } from 'react-final-form';
import Button from 'components/Shared/Common/Button';
import { StyledListManager } from 'components/Shared/Forms/styles';
import Text from 'components/Shared/Forms/Text';

const { Search } = Input;

const LabelText =({ input:  { value } = {}, ...rest }) => <Text value={value} {...rest} />;

const RemovableItem = (({ items, name, index, validators, ...rest }) => {
    const handleRemove = useCallback(() => items.remove(index), [index]);

    return (
        <>
            <div className="content">
                <Field
                    name={name}
                    component={LabelText}
                    {...rest}
                />
            </div>
            <Button
                type="button"
                color="danger"
                size="md"
                iconName="X"
                onClick={handleRemove}
            />
        </>
    );
});

const List = ({ items, error, ...rest }) => (
    <ul className="styled-list">
        {items?.map((item, index) => (
            <li key={item}>
                <RemovableItem items={items} name={item} index={index} {...rest} />
            </li>
        ))}
        {error && <li className="error">{error}</li>}
    </ul>
);

const ListManager = ({ fields, placeholder, ...rest }) => {
    const [currentValue, setCurrentValue] = useState();

    const handleChange = (e) => {
        e.preventDefault();
        setCurrentValue(e.target.value);
        return false;
    };
    const handleAdd = () => {
        if (rest?.validators?.length) {
            const hasError = rest.validators.some(rule => rule(currentValue));
            if (hasError) {
                return;
            }
        }
        fields.push(currentValue);
        setCurrentValue('');
    };

    return (
        <StyledListManager>
            <Search
                enterButton={<UserAddOutlined />}
                onSearch={handleAdd}
                onChange={handleChange}
                value={currentValue}
                autoComplete="off"
                placeholder={placeholder}
            />
            <List items={fields} {...rest} />
        </StyledListManager>
    );
}

ListManager.propTypes = {
    fields: PropTypes.object,
};

ListManager.defaultProps = {
    fields: []
};

export default ListManager;
