import { useQuery } from "react-query"
import { apis } from '@amplement/backend-connector';

const useCallForwards = ({ _sipAccount, trigger }) => {
    const result = useQuery(
        ['callForward',  { _sipAccount, trigger } ],
        async () => {

            // return [{"trigger":"busy","type":"VOICEMAIL","to":"","context":"500100903101"},{"trigger":"unavailable","type":"VOICEMAIL","to":"","context":"500100903101","delay":40},{"trigger":"unconditional","type":"NONE","to":"","context":""}];

            const response = await apis.callForward.$getAllForwardsSettings(_sipAccount, trigger);

            return response?.data || [];
        },
        {
            enabled: !!_sipAccount,
            refetchOnWindowFocus: false,
            staleTime: 60000
        }
    )

    return result;
}

export default useCallForwards;
