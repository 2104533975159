import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';

import { getUrl } from 'utils/url';

const ConnectButton = ({ returnUrl, intl }) => (
    <Button
        color="primary"
        href={getUrl('logout', { returnUrl })}
    >
        {intl.formatMessage({ id: 'room.reception.existingAccount' })}
    </Button>
);

ConnectButton.propTypes = {
    intl: PropTypes.any.isRequired,
    returnUrl: PropTypes.string
};

ConnectButton.defaultProps = {
    returnUrl: undefined
};

export default memo(injectIntl(ConnectButton));
