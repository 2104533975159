import React, { useCallback, useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import FeedItemList from 'components/Feed/FeedItemList';

import Button from 'components/Shared/Common/Button';
import { Col, Alert, Affix } from 'antd';
import CopyRoomLinkButton from 'components/Room/Footer/MoreButton/DropDown/CopyRoomLinkButton';
import FeedItemCreator from 'components/Feed/Creator/container';
import styled from 'styled-components';
import { rgba } from 'polished';
import FileUploadManager from 'components/Feed/Creator/FileUploadManager/container';
import Acknowledgement from 'components/Feed/Acknowledgement/container';
import ReplyAreaFeedItem from 'components/Feed/ReplyArea';
import InviteUserDrawer from '../InviteUserButton';

export const StyledShared = styled(Alert)`
    background-color: ${({ theme }) => theme.lighten};
    border: none;
    margin: 10px auto;
    width: 100%;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text{
        margin: 10px 0;
        display: flex;
        justify-content: center;
        color: ${({ theme }) => theme.black};
    }
    .actions{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .link{
        display: flex;
        flex-direction: row;
        padding: 5px;
        border-radius: 30px;
        align-items: center;
        background-color: ${({ theme }) => theme.bg.global};
        span{
            color: ${({ theme }) => theme.black};
        }
        .btn{
            margin-left: 5px;
        }
        >.icon{
            display: flex;
            align-items: center;
            margin-right: 5px;
            svg{
                width: 14px;
                height: 14px;
                color: ${({ theme }) => rgba(theme.black, .4)};
            }
        }
    }
`;

const InviteUserButton = injectIntl(memo(({ intl, onClick }) => (
    <div className="actions">
        <Button
            color="primary"
            onClick={onClick}
        >
            {intl.formatMessage({ id: 'room.actions.addMembers' })}
        </Button>
    </div>
)));

const RoomSharingBlock = injectIntl(memo(({ intl, isInitiator, _room }) => (
    <StyledShared
        type="info"
        message={(
            <>
                <CopyRoomLinkButton>
                    <Button
                        color="darken-faded"
                        iconName="Link"
                    >
                        {intl.formatMessage({ id: 'global.copyClipboard' })}
                    </Button>
                </CopyRoomLinkButton>
                {isInitiator && (
                    <InviteUserDrawer
                        _room={_room}
                        Button={InviteUserButton}
                    />
                )}
            </>
        )}
    />
)));

const StyledContent = styled(Col)`
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: auto;
    .scroll-list{
        overflow: auto;
        padding: 0;
    }
    .styled-action-area{
        padding: 20px 5px 20px;
    }
`;

const StyledAlert = styled(Alert)`
    margin-bottom: 10px;
    border-radius: 4px;
`;

const StyledWrapperFeedItemCreator = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
`;

const RoomFeedVisibilityBlock = injectIntl(memo(({ intl, isPrivate }) => (
    <StyledAlert
        type={isPrivate ? 'success' : 'warning'}
        showIcon
        message={intl.formatMessage({
            id: isPrivate ? 'room.visibility.private' : 'room.visibility.public'
        })}
    />
)));

const RoomFeedInfoBlock = injectIntl(memo(({ intl, isPersistent }) => (
    <StyledAlert
        type={isPersistent ? 'info' : 'warning'}
        showIcon
        message={intl.formatMessage({
            id: isPersistent ? 'room.info.chatPreserved' : 'room.info.chatRemoveOnDelete'
        })}
    />
)));

const StaticMessages = (isPersistent, isPrivate, isMeAdmin, _room) => memo(({ containerRef }) => (
    <>
        <RoomSharingBlock
            _room={_room}
            isInitiator={isMeAdmin}
        />
        <RoomFeedInfoBlock isPersistent={isPersistent} />
        <Affix target={() => containerRef}>
            <RoomFeedVisibilityBlock isPrivate={isPrivate} />
        </Affix>
    </>
));


const FeedItemDrawer = ({ intl, ...props}) => {
    const {
        _feed,
        setSelectedFeed,
        isPrivate,
        isMeAdmin,
        isJoined,
        isPersistent,
        visible,
        _room
    } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [feedItemFiles, setFeedItemFiles] = useState([]);
    const [replyComment, setReplyComment] = useState({ content: '', _feedItem: null });

    const closeUploadManager = useCallback(() => {
        setFeedItemFiles([]);
        setIsUploading(false);
    }, [setIsUploading]);

    const handleSetFeedItemFiles = useCallback((fList) => {
        setFeedItemFiles(fList);
        setIsUploading(true);
    }, []);
    
    const handleReplyFeedItem = useCallback((_feedItem, content) => {
        setReplyComment({ _feedItem, content });
    }, []);

    useEffect(() => {
        if (replyComment._feedItem) {
            setReplyComment({ content: '', _feedItem: null });
        }
    }, [_feed]);

    useEffect(() => closeUploadManager, [_feed]);

    useEffect(() => {
        if (isJoined && visible) {
            setSelectedFeed(true);
        }
        return () => setSelectedFeed(false);
    }, [_feed, isJoined, visible]);

    const prefix = useMemo(() =>
        StaticMessages(isPersistent, isPrivate, isMeAdmin, _room)
    , [isPersistent, isPrivate, isMeAdmin, _room]);

    return (
        <>
            <Acknowledgement _feed={_feed} />
            <FileUploadManager
                visible={isUploading}
                feedItemFiles={feedItemFiles}
                _feed={_feed}
                onClose={closeUploadManager}
                setFeedItemFiles={handleSetFeedItemFiles}
            />
            <StyledContent flex={1} className="content">
                <FeedItemList
                    {...props}
                    isAvatarClickable={false}
                    hasStatus={false}
                    prefix={prefix}
                    onReplyFeedItem={handleReplyFeedItem}
                />
            </StyledContent>
            <StyledWrapperFeedItemCreator>
                {replyComment.content && (
                    <ReplyAreaFeedItem
                        content={replyComment.content}
                        onReplyFeedItem={handleReplyFeedItem}
                    />
                )}
                <FeedItemCreator
                    id={_feed}
                    setFeedItemFiles={handleSetFeedItemFiles}
                    onReplyFeedItem={handleReplyFeedItem}
                    replyComment={replyComment}
                />
            </StyledWrapperFeedItemCreator>
        </>
    );
}

FeedItemDrawer.propTypes = {
    intl: PropTypes.any.isRequired,
    isPrivate: PropTypes.bool.isRequired,
    isMeAdmin: PropTypes.bool,
    isJoined: PropTypes.bool,
    isPersistent: PropTypes.bool.isRequired,
};

FeedItemDrawer.defaultProps = {
    isMeAdmin: false,
    isJoined: false
};

export default injectIntl(FeedItemDrawer);
