import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { StyledSelect } from 'components/Shared/Forms/styles';

// onBlurChange is used for the Callbuilder component.
// To avoid breaking changes onBlur must stay unused.
const MultiSelect = ({ value, onBlur, onBlurChange, autoFocus, ...props }) => {
    const inputRef = useRef(null);

    // As long as the issue https://github.com/ant-design/ant-design/issues/41239 is not fixed, the autoFocus is set manually using a setTimeout.
    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => inputRef?.current?.focus(), 0);
        }
    }, [autoFocus]);

    return (
        <StyledSelect
            ref={inputRef}
            mode="tags"
            style={{ width: '100%' }} 
            value={value || []}
            onBlur={onBlurChange}
            {...props}
        />
    );
}

MultiSelect.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    onBlurChange: PropTypes.func,
};

MultiSelect.defaultProps = {
    value: [],
    onBlurChange: undefined,
}

export default memo(MultiSelect);
