import { createSelector } from 'reselect';
import { userEntitiesSelector } from 'selectors/entity';
import { isGuest } from 'utils/user';

export const getCurrentClientIdSelector = state => state.session._client;
export const getCurrentUserSelector = state => state.session.user;
export const getCurrentUserIdSelector = state => state.session.user.id;
export const getCurrentUserStatusSelector = state => state.session.user.status;
export const getCurrentUserRolesSelector = state => state.session.user.roles;
export const getCurrentUserFeaturesSelector = state => state.session.user.features;
export const getUserProfileSelector = state => state.profile.user.entity;
export const getRequestedUserActionsSelector = state => state.session.user.requestedActions;
const getUserSelector = state => state.user.items.data;
const _getUserParameter = (_, _feed) => _feed;

export const getUserSettingsSelector = createSelector(
    getCurrentUserSelector, user => (user || {})?.settings
);

export const getIsGuestSelector = createSelector(
    getCurrentUserSelector, (user = {}) => isGuest(user.roles)
);

export const getSoundsStatusSelector = createSelector(
    getUserSettingsSelector, settings => (settings || {})?.sounds
);

export const getDesktopNotificationStatusSelector = createSelector(
    getUserSettingsSelector, settings => (settings || {})?.desktopNotifications
);

export const getUserByIdSelector = createSelector(
    [_getUserParameter, userEntitiesSelector],
    (_user, userEntities) => userEntities[_user]
);

export const getUsersByIds = ids => createSelector(
    [userEntitiesSelector], userEntities => ids
        .map(_user => userEntities[_user])
        .filter(user => !!user)
);

export const getContactStatusSelector = _user => createSelector(
    [getUserSelector], (users) => users?.entities?.users?.[_user]?.contactStatus
);
