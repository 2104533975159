import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import FileItem from 'components/Shared/Common/FileItem';
import { openPreview } from 'actions/preview';

const FeedItemFile = ({ file, intl, onPreview, subtitle }) => (
    <div className="list-files">
        <FileItem
            intl={intl}
            name={file.name}
            extension={file.extension}
            size={file.size}
            width={file.thumbnailWidth || file.width}
            height={file.thumbnailHeight || file.height}
            src={file.uri}
            thumb={file.thumbnailUri}
            isDisplayable={false} // todo ! get image path via rapi first
            onPreview={onPreview}
            title={file.name}
            subtitle={subtitle}
        />
    </div>
);

FeedItemFile.propTypes = {
    intl: PropTypes.object.isRequired,
    file: PropTypes.shape({
        name: PropTypes.string.isRequired,
        extension: PropTypes.string.isRequired,
        size: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        thumbnailWidth: PropTypes.number,
        thumbnailHeight: PropTypes.number,
        uri: PropTypes.string,
        thumbnailUri: PropTypes.string
    }).isRequired,
    onPreview: PropTypes.func.isRequired,
    subtitle: PropTypes.string
};

FeedItemFile.defaultProps = {    
    subtitle: ''
};

const Component = memo(injectIntl(FeedItemFile));

export default Component;

const mapDispatchToProps = (dispatch, { _feedItem }) => ({
    onPreview: () => dispatch(openPreview(_feedItem)),
});

export const container = connect(null, mapDispatchToProps)(Component);
