import * as initTypes from 'types/init';
import * as entityTypes from 'types/entity';

export const requestRootInit = () => ({
    type: initTypes.REQUEST_ROOT_INIT
});

export const mergeEntities = entities => ({
    type: entityTypes.MERGE_ENTITIES,
    entities
});
