import { connect } from 'react-redux';

import { selectors } from '@amplement/backend-connector';
import { getCurrentUserIdSelector } from 'selectors/user';
import Component from './index';

const mapStateToProps = (state, { _room }) => {
    const rooms = selectors.rooms.getForwardableRoomsSelector(state, _room);
    const _user = getCurrentUserIdSelector(state);

    return {
        rooms,
        _user
    }
};

export default connect(
    mapStateToProps,
    null
)(Component);
