import { connect } from 'react-redux';
import BrandLogo from 'components/Shared/Company/BrandLogo';
import { getCompanySelector } from 'selectors/company';

const mapStateToProps = (state) => ({
    name: getCompanySelector(state)?.name
});

export default connect(
    mapStateToProps,
    null
)(BrandLogo);
