import React, { memo } from 'react';
import { Card, Tag, Image, Row, Col, Typography, Tooltip } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Gutter } from 'antd/es/grid/row';
import { v4 as uuidv4 } from 'uuid';

import { FileResult, MetasFile, SearchResultsData } from 'hooks/useSearch';
import useUser from 'hooks/useUser';

import Avatar from 'components/Shared/User/Avatar';
import Actions from 'components/FileList/Actions';
import { getIconPath } from 'components/Shared/Common/FileIcon';
import PreviewButton from 'components/FileList/PreviewButton';

import { getFileSource } from 'utils/file';
import { FULL_DATE_FORMAT_CONFIG } from 'utils/date';

const { Meta } = Card;
const { Text } = Typography;

export const AntdImage = styled(Image)`
    object-fit: cover;
    width: 100%;
`;

const Tile = styled(Card)`
    height: 280px!important;
    margin: 10px;
    display: flex;
    flex-direction: column;
    .ant-tag{
        text-transform: uppercase;
        font-size: 10px;
    }
    .ant-card{
        &-body{
            padding: 10px;
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        &-meta{
            flex: 1;
            &.meta-bottom{
                flex: 0;
                background-color: ${props => props.theme.feedItem.bgFeedAction};
                margin: 0 -10px -10px;
                padding: 10px;
                border-radius: 0 0 4px 4px;
                .ant-card-meta-detail{
                    display: flex;
                    align-items: center;
                }
                .ant-card-meta-avatar{
                    padding-inline-end: 8px;
                }
                .ant-card-meta-title{
                    font-weight: normal;
                    font-size: 13px;
                }
            }
            &-title{
                white-space: normal
            }
        }
    }
    .title-wrapper{
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 44px;
    }
    .ant-btn {
        display: none;
    }

    &:hover{
        .ant-btn {
            display: block;
        }
    }
`;

const Audio = styled.audio`
    width: 100%;
    padding: 10px;
`;

const StyledRow = styled(Row)`
    width: 100%;
`;

const Cover = ({
    id,
    metasFile,
    updatedAt,
}: {
    metasFile: MetasFile,
    id: string,
    updatedAt: string,
}): JSX.Element => {
    const {
        uri,
        thumbnailUri,
        extension: dbExtension,
        isDisplayable,
    } = metasFile;
    const extension = dbExtension?.toLowerCase();
    const fallback = getIconPath(extension);
    const thumbSrc = thumbnailUri ? getFileSource(thumbnailUri) : fallback;
    const src = uri ? getFileSource(uri) : fallback;

    switch (extension) {
        case '.aac':
        case '.mp3':
        case '.wav':
        case '.ogg':
        case '.flac':
            return <Audio src={src} controls preload="metadata" />;
        default:
            break;
    }

    if (isDisplayable && extension !== '.heic') {
        return <AntdImage width="100%" src={thumbSrc} preview={{ src }} />;
    }

    return (
        <PreviewButton
            id={id}
            extension={extension}
            src={extension !== '.heic' ? thumbSrc : fallback}
            metasFile={metasFile}
            updatedAt={updatedAt}
        />
    );
};

const CoverWrapper = styled.div`
    height: 130px;
    display: flex!important;
    align-items: center;
    position: relative;
    background-color: ${props => props.theme.secondaryActive};
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    .ant-image{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &-img,
        &-mask{
            height: 100%;
        }
    }
    img{
        display: block;
        width: 100%;
        height: 100%;
        object-position: top;
    }
`;

const StyledWrapperDesc = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const StyledTag = styled(Tag)`
    margin-right: 5px;
`;

const File = ({
    id,
    metasFile: { name, extension },
    metasFile,
    updatedAt,
    _user,
    intl,
}: FileResult & WrappedComponentProps) => {
    const {
        data: {
            avatarUri,
            firstname,
            lastname,
            profileUrl,
        } = {},
    } = useUser({ _user });
    const fullname = `${firstname} ${lastname}`;

    return (
        <Col 
            xs={24} 
            sm={24}
            md={12} 
            lg={8}
            xl={6}
            xxl={4}
        >
            <Tile
                key={id}
                cover={(
                    <CoverWrapper>
                        <Cover metasFile={metasFile} id={id} updatedAt={updatedAt} />
                    </CoverWrapper>
                )}
            >
                <Meta 
                    title={
                        <Row wrap={false} align="top">
                            <Tooltip
                                placement="bottom"
                                title={name}
                            >
                                <div className="title-wrapper">{name}</div>
                            </Tooltip>
                        </Row>
                    }
                    description={
                        <StyledWrapperDesc>
                            <StyledTag bordered={false}>{extension}</StyledTag>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <Text>{intl.formatDate?.(updatedAt, FULL_DATE_FORMAT_CONFIG)}</Text>
                        </StyledWrapperDesc>
                    }
                />
                <Meta
                    className="meta-bottom"
                    avatar={
                        <Avatar
                            src={avatarUri}
                            size="sm"
                            href={profileUrl}
                            alt={fullname}
                            content={fullname}
                            hasStatus={false}
                        />
                    }
                    title={fullname}
                />
                <Actions {...metasFile} />
            </Tile>
        </Col>
    );
};

File.propTypes = {
    id: PropTypes.string.isRequired,
    metasFile: PropTypes.shape({
        thumbnailUri: PropTypes.string,
        name: PropTypes.string.isRequired,
        extension: PropTypes.string.isRequired,
        uri: PropTypes.string,
        isDisplayable: PropTypes.bool.isRequired,
        width: PropTypes.number,
    }).isRequired,
    intl: PropTypes.object.isRequired,
    updatedAt: PropTypes.string.isRequired,
    _user: PropTypes.string.isRequired,
};

const FileItem = memo(injectIntl(File as React.FunctionComponent<FileResult & WrappedComponentProps>));

const gutter: [Gutter, Gutter] = [8, 8];

const FileList = ({ feedItems, intl }: SearchResultsData & WrappedComponentProps) => {
    const files = feedItems?.results as FileResult[];

    if (!files || !files.length) {
        return <Text>{intl.formatMessage({ id: 'callbuilder.emptyList' })}</Text>;
    }

    return (
        <StyledRow align="top" gutter={gutter}>
            {files.map(file => <FileItem key={`${file.id}-${uuidv4()}`} {...file} />)}
        </StyledRow>
    );
}

FileList.propTypes = {
    feedItems: PropTypes.object,
    intl: PropTypes.object.isRequired,
};

FileList.defaultProps = {
    feedItems: {
        results: [],
        paging: {
            total: 0,
            from: 0,
            to: 0,
        },
    },
};

export default memo(injectIntl(FileList as React.FunctionComponent<SearchResultsData & WrappedComponentProps>));
