import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { rgba } from 'polished';
import { Input } from 'antd';
import { StyledInput } from 'components/Shared/Forms/styles';

export const Style = styled.div`
    background-color: ${({ theme }) => rgba(theme.black, 0.05)};
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    border-radius: 30px;
    align-items: center;
    transition: all 250ms ease;
    border: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
    color: ${({ theme }) => theme.black};
    flex-shrink: 0;
    &:focus-within{
        border-color: ${({ theme }) => rgba(theme.black, 0.2)};
        box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.black, 0.15)};
        .icon{
            svg{
                color: ${({ theme }) => rgba(theme.black, 0.8)};
            }
        }
    }
    input{
        border: none;
        width: 100%;
        font-size: 14px;
        margin-left: 5px;
        background-color: transparent;
        transition: all 250ms ease;
        appearance: none;
        -webkit-appearance: none;
    }
    .icon{
        display: flex;
        svg{
            width: 14px;
            height: 14px;
            color: ${({ theme }) => rgba(theme.black, 0.4)};
            transition: all 250ms ease;
        }
    }
`;

const MyInput = forwardRef(({ className, icon, rounded, ...props}, ref) =>
    rounded ? (
        <Style className={className} ref={ref}>
            {icon}
            <Input
                autoComplete="off"
                {...props}
            />
        </Style>
    ) : <StyledInput className={className} ref={ref} {...props} />
);

MyInput.propTypes = {
    icon: PropTypes.any,
    className: PropTypes.string,
};

MyInput.defaultProps = {
    className: '',
    icon: null
};

export default MyInput;
