import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Word = ({ color, withBorder }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-word" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero" id="Shape">
                {withBorder && <Border />}
                <path
                    d="M64.7,97 L56.4,97 L51.8,79 C51.6,78.4 51.3,77 50.9,75 C50.5,73 50.2,71.6 50.2,71 C50.1,71.8 49.9,73.2 49.5,75.1 C49.1,77 48.8,78.3 48.6,79.1 L44,97 L35.7,97 L27,62.7 L34.1,62.7 L38.5,81.4 C39.3,84.9 39.8,87.8 40.2,90.4 C40.3,89.5 40.5,88.1 40.8,86.3 C41.1,84.5 41.5,83 41.7,82 L46.7,62.8 L53.6,62.8 L58.6,82 C58.8,82.9 59.1,84.2 59.4,85.9 C59.7,87.6 60,89.2 60.2,90.4 C60.4,89.2 60.6,87.7 61,85.8 C61.4,83.9 61.7,82.5 61.9,81.4 L66.3,62.7 L73.4,62.7 L64.7,97 Z"
                />
            </g>
        </g>
    </svg>
);

Word.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

Word.defaultProps = {
    color: '#2372BA',
    withBorder: true
};

export default Word;
