import * as types from 'types/company';
import { registerRoute } from 'utils/url';
import { authorizedLanguages as langs } from 'const/language';

const initialState = {
    id: undefined,
    name: undefined,
    skin: undefined,
    config: undefined,
    plugins: undefined,
    availablelanguages: undefined
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_COMPANY: {
            if (action.company.plugins) {
                const { tabs } = action.company.plugins;
                if (tabs && Array.isArray(tabs)) {
                    tabs.forEach(tab => tab.name && registerRoute(tab.name));
                }
            }
            const { i18n } = action.company;
            let availablelanguages = state.availablelanguages || [];
            if (i18n) {
                availablelanguages = Object.keys(i18n).filter(x => i18n[x].isActive).map(key => ({ key, value: langs[key] }))
            }

            return { ...state, ...action.company, availablelanguages };
        }

        default:
            return state;
    }
};

export default companyReducer;
