import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Space } from 'antd';
import Spinner2 from 'components/Shared/Common/Spinner2';
import { injectIntl } from 'react-intl';

import useSipAccount from 'hooks/useSipAccount';
import SipStatus from 'components/Shared/Call/SipStatus';
import { colors } from 'common/themes/Colors';
import useSipAccounts from 'hooks/useSipAccounts';

const { Text } = Typography;

const Informations = (props) => {
    const { intl } = props;
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { data: account, isLoading, error, isError } = useSipAccount({ _sipAccount });
    const { status, phone: { shortNumber, mobileNumber, voicemailNumber, externalNumber } = {} } = account || {};

    if (isLoading) {
        return <Spinner2 />;
    }

    if (isError) {
        return <Text type="danger">{error?.message|| intl.formatMessage({ id: 'error.default' })}</Text>;
    }

    return (
        <Space 
            direction="vertical" 
            size="middle" 
            style={{ display: 'flex', background: colors.lighten, padding: '40px' }}
        >
            <SipStatus status={status} />
            <Space direction="horizontal" size="large" style={{ display: 'flex' }}>
                <Text type="secondary">{intl.formatMessage({ id: 'call.forward.infos.externalNumber' })}:</Text><Text strong>{externalNumber || 'N/A'}</Text>
            </Space>
            <Space direction="horizontal" size="large" style={{ display: 'flex' }}>
                <Text type="secondary">{intl.formatMessage({ id: 'call.forward.infos.extension' })}:</Text><Text strong>{shortNumber || 'N/A'}</Text>
            </Space>
            <Space direction="horizontal" size="large" style={{ display: 'flex' }}>
                <Text type="secondary">{intl.formatMessage({ id: 'call.forward.infos.mobile' })}:</Text><Text strong>{mobileNumber || 'N/A'}</Text>
            </Space>
            <Space direction="horizontal" size="large" style={{ display: 'flex' }}>
                <Text type="secondary">{intl.formatMessage({ id: 'call.forward.infos.voicemailNumber' })}:</Text><Text strong>{voicemailNumber || 'N/A'}</Text>
            </Space>
        </Space>
    );
}

Informations.propTypes = {
    intl: PropTypes.any.isRequired
};

Informations.defaultProps = {
};

export default memo(injectIntl(Informations));
