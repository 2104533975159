import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import VoiceMailItem from 'components/Shared/Drawers/Sip/VoiceMailItem';
import { Collapse, Result } from 'antd';
import Spinner2 from 'components/Shared/Common/Spinner2';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { injectIntl } from 'react-intl';
import Button from 'components/Shared/Common/Button';
import styled from 'styled-components';
import { PopConfirmDeletion } from 'components/Shared/Common/PopConfirm';
import classNames from 'classnames';

const PlaceholderContainer = memo(injectIntl(({ intl }) => (
    <Result
        status="info"
        title={intl.formatMessage({ id: 'voicemail.title' })}
        subTitle={intl.formatMessage({ id: 'voicemail.noItem' })}
    />
)));

const ErrorContainer = memo(injectIntl(({ intl }) => (
    <Result
        status="error"
        title={intl.formatMessage({ id: 'voicemail.title' })}
        subTitle={intl.formatMessage({ id: 'voicemail.error' })}
    />
)));

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex: 1;
    min-height: 0;
    min-width: 0;
    height: 100%;
    .content{
        display: flex;
        flex-direction: column;
        /* flex: 1; */
        min-height: 0;
        min-width: 0;
        overflow: auto;
    }
    .action{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-top: 10px;
        &.first{
            margin-bottom: 20px;
            border: none;
            padding-top: 0;
            margin-top: 0;
            .btn{
                padding: 5px 10px;
                margin: 0 10px;
            }
        }
    }
`;

const StyledCollapse = styled(Collapse)`
    background-color: transparent;
`;

const VoiceMails = (props) => {
    const {
        _voiceMails,
        VoiceMailItemComponent,
        deleteVoiceMails,
        loading,
        error,
        intl
    } = props;
    const [selectable, setSelectable] = useState();
    const [checkedList, setCheckedList] = useState([]);

    useWhyDidYouUpdate('components:VoiceMails', props);

    const handleSelectable = useCallback(
        () => setSelectable(!selectable), [setSelectable, selectable]);

    const handleChange = useCallback((id, value) => {
        if (value) {
            setCheckedList([...checkedList, id]);
        } else {
            setCheckedList(checkedList.filter(x => x !== id));
        }
    }, [setCheckedList, checkedList]);

    const handleSelectAll = useCallback(() => {
        if (checkedList?.length === _voiceMails?.length) {
            setCheckedList([]);
        } else {
            setCheckedList(_voiceMails);
        }
    }, [setCheckedList, checkedList]);

    const handleDeleteSelected = useCallback(() =>
        deleteVoiceMails(checkedList), [deleteVoiceMails, checkedList]);

    useEffect(() => {
        setCheckedList(checkedList.filter(x => _voiceMails.indexOf(x) !== -1));
    }, [_voiceMails]);

    if (loading) {
        return <Spinner2 />;
    }

    if (error) {
        return <ErrorContainer />;
    }

    if (_voiceMails?.length === 0) {
        return <PlaceholderContainer />;
    }

    return (
        <Styled>
            <div className={classNames(
                'action',
                'first',
            )}>
                {selectable && (
                    <Button
                        onClick={handleSelectAll}
                        color="primary"
                    >
                        {intl.formatMessage({
                            id: checkedList?.length === _voiceMails?.length ? 'global.button.unselectAll' : 'global.button.selectAll'
                        })}
                    </Button>
                )}
                <Button
                    onClick={handleSelectable}
                    color="secondary"
                >
                    {intl.formatMessage({
                        id: selectable ? 'global.button.cancel' : 'global.select'
                    })}
                </Button>
            </div>
            <div className="content">
                <StyledCollapse accordion bordered={false}>
                    {_voiceMails.map(
                        _voiceMail => (
                            <VoiceMailItemComponent
                                _voiceMail={_voiceMail}
                                selectable={selectable}
                                key={_voiceMail}
                                onChange={handleChange}
                                checked={checkedList.some(x => x === _voiceMail)}
                            />
                        )

                    )}
                </StyledCollapse>
            </div>
            {selectable && (
                <div className="action">
                    <div className="spacer">
                        <PopConfirmDeletion
                            title={intl.formatMessage({ id: 'voicemail.confirmAskDeleteSelected' })}
                            onConfirm={handleDeleteSelected}
                            buttonIconName="Trash2"
                            buttonColor="danger"
                            buttonTitle={intl.formatMessage({ id: 'voicemail.askDeleteSelected' })}
                            disabled={!checkedList?.length}
                            buttonSize="md"
                        />
                    </div>
                </div>
            )}
        </Styled>
    );
}

VoiceMails.propTypes = {
    _voiceMails: PropTypes.array,
    VoiceMailItemComponent: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.bool,
};

VoiceMails.defaultProps = {
    _voiceMails: [],
    VoiceMailItemComponent: VoiceMailItem,
    loading: false,
    error: false
};

export default memo(injectIntl(VoiceMails));
